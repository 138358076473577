import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../now/user/auth.service';
import {UserModel} from '../../now/user/user.model';
import {Router} from '@angular/router';
import {AppService} from '../../app/app.service';
import {TaskService} from '../../services/task.service';
import {TypeaheadComponent} from '../typeahead/typeahead.component';
import {Api} from '../../now/api/api';
import {UserService} from '../../services/user.service';
import {SwalService} from '../../services/swal.service';
import {TaskModel} from '../../models/task.model';
import {ModalService} from '../../services/modal.service';
import {PusherService} from '../../services/pusher.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {LeaveModalComponent} from '../../pages/database/user/leave/leaveModal.component';

@Component({
    selector: 'app-navbar-component',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('leaveModalComponent', { static: true }) leaveModalComponent: LeaveModalComponent;
    @ViewChild('searchEveryWhere', { static: true }) searchEveryWhere: TypeaheadComponent;
    @Output('onSelect') on_select_handler: EventEmitter<any> = new EventEmitter<any>();
    @Output('onKeydown') on_keydown_handler: EventEmitter<any> = new EventEmitter<any>();

    @Input('allTasks')
    set allTasks(val: any[]) {
        //
    }

    public navShow: boolean;
    public all_tasks: any[];
    public is_online: boolean;
    public user: UserModel;
    public userLevel: number;

    public tmp_job: string;
    public search: string;

    public personal_tasks: TaskModel[];
    public request_tasks: any[];

    constructor(
        private ngxSmartModal: NgxSmartModalService,
        private authService: AuthService,
        private router: Router,
        private api: Api,
        public modal: ModalService,
        private ngZone: NgZone,
        private pusherService: PusherService,
        private swal: SwalService,
        private userService: UserService,
        private taskService: TaskService,
        public appService: AppService
    ) {
        //
        this.navShow = false;
        this.is_online = true;
        this.user = this.authService.user;
        this.userLevel = 3;
        this.tmp_job = '';
        this.personal_tasks = [];
        this.request_tasks = [];
        this.search = '';
        this.all_tasks = [];
    }

    ngOnInit(): void {
        // this.pusherService.subscribe('erpst.personal_task');
        // this.pusherService.bind('erpst.personal_task', 'tasks.update', data => {
        //     this.userService.getNotifications()
        //         .then(() => {
        //             this.userLevel = this.user.level;
        //         });
        // });
    }

    ngOnDestroy(): void {
        // this.pusherService.unsubscribe('erpst.personal_task');
    }

    ngAfterViewInit (): void {
        this.userService.getNotifications()
            .then(() => {
                this.userLevel = this.user.level;
            });
    }

    public getRequestTasks(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('users/tasks/request')
                .subscribe((res: any) => {
                    if (res && res.data) {
                        if (this.request_tasks) {
                            this.request_tasks.splice(0, this.request_tasks.length);
                        } else {
                            this.request_tasks = [];
                        }
                        for (let i = 0; i < res.data.length; i++) {
                            const dat: any = res.data[i];
                            if (dat) {
                                let task: TaskModel;
                                task = new TaskModel();
                                task.clone(dat);
                                this.request_tasks.push(task);
                            }
                        }
                        resolve(this.request_tasks);
                    } else {
                        resolve([]);
                    }
                }, error => {
                    resolve([]);
                });
        });
        return promise;
    }

    public getPersonalTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            // this.userService.num_notifications = 0;
            this.api.request('users/tasks')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.render(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private render(response: any): void {
        this.ngZone.run(() => {
            this.renderTasks(response);
        });
    }

    private renderTasks(respnose: any): void {
        if (this.personal_tasks) {
            this.personal_tasks.splice(0, this.personal_tasks.length);
        } else {
            this.personal_tasks = [];
        }
        for (let i = 0; i < respnose.data.length; i++) {
            const dat: any = respnose.data[i];
            if (dat) {
                let task: TaskModel;
                task = new TaskModel();
                task.clone(dat);
                this.personal_tasks.push(task);
            }
        }
    }

    public viewPersonalTasks(): void {
        //
    }

    public onSelect(e: any): void {
        setTimeout(() => {
            this.on_select_handler.emit(e);
            this.tmp_job = '';
        }, 0);
    }

    gotoTaskView(task: TaskModel): void {
        this.taskService.gotoTask(task);
    }

    public onKeywordInput(e: any): void {
        this.appService.resetTasks();
        setTimeout(() => {
            this.on_keydown_handler.emit(this.tmp_job);
        }, 0);
    }

    public profile(): void {
        //
    }

    public doCRDRequest(): void {
        //
    }

    public doLeave(): void {
        this.leaveModalComponent.reset();
        this.ngxSmartModal.open('leaveModal');
        // this.modal.show(LeaveModalComponent, {
        //     user: this.user
        // }).then((content: any): void => {
        //     //
        // });
    }

    public doTask(): void {
        this.api.request('users/task')
            .subscribe((response: any): void => {
                if (response && response.data && response.data.id) {
                    this.userService.current_role = response.data.current_role;
                    this.taskService.gotoTask(response.data);
                } else {
                    this.swal.danger('ไม่มีรายการงานที่ค้างอยู่ในระบบ');
                }
            }, error => {
                this.swal.danger(error);
            });
    }

    public logout(): void {
        this.authService.logout()
            .then(response => {
                if (this.authService.onLogoutHandler) {
                    this.authService.onLogoutHandler();
                }
                //
            }, error => {
                //
            });
    }

    public get num_notifications(): number {
        return this.userService.num_notifications;
    }

    public get num_request_notifications(): number {
        return this.userService.num_request_notifications;
    }

}
