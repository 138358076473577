import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProductModel} from '../../../../models/product.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {TaskModel} from '../../../../models/task.model';
import {TaskService} from '../../../../services/task.service';

@Component({
    selector: 'app-add-product-component',
    templateUrl: 'addProduct.component.html',
    styleUrls: ['addProduct.component.scss']
})
export class AddProductComponent implements OnInit {

    public tmp_product: any;
    public product: ProductModel;
    public customer_id: string;
    public task: TaskModel;

    public parent_product_id: string;
    public is_part_product: boolean;

    private is_api: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private swal: SwalService,
        private taskService: TaskService
    ) {
        //
        this.product = new ProductModel();
        this.is_part_product = false;
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef.content && this.bsModalRef.content.customer_id) {
                this.customer_id = this.bsModalRef.content.customer_id;
            }
            if (this.bsModalRef.content && this.bsModalRef.content.is_part_product === true) {
                this.is_part_product = true;
            } else {
                this.is_part_product = false;
            }
            if (this.bsModalRef.content && this.bsModalRef.content.parent_product_id) {
                this.parent_product_id = this.bsModalRef.content.parent_product_id;
            }
            if (this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            this.is_api = false;
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid && !this.is_api) {
            this.is_api = true;
            const task: TaskModel = new TaskModel();
            task.parent_id = this.task.id;
            task.process_slug = 'product';
            task.current_role = 'product';
            task.action = 'create_product';
            task.modelable_type = 'App\\Product';
            task.modelable_data = {
                parent_id: this.parent_product_id,
                name: this.product.name
            };
            task.hidden = true;
            this.taskService.createNewTask({
                parent_id: this.task.id,
                process_slug: 'product',
                current_role: 'product',
                action: 'create_product',
                hidden: true,
                modelable_type: 'App\\Product',
                modelable_data: {
                    parent_id: this.parent_product_id,
                    name: this.product.name
                }
            }).then((new_task: TaskModel) => {
                if (new_task && new_task.modelable_type && new_task.modelable_id) {
                    this.bsModalRef.content.product_id = new_task.modelable_id;
                    this.bsModalRef.hide();
                } else {
                    //
                }
            });
        } else {
            //
        }
    }

}
