import {Directive, HostListener, Input} from '@angular/core';
import {DrawingModel} from '../models/drawing.model';
import {Viewer} from '../services/viewer';

@Directive({
    selector: '[drawing]',
    exportAs: 'drawingDirective'
})
export class DrawingDirective {

    public _drawing: DrawingModel;

    @Input()
    public set drawing(_: DrawingModel) {
        this._drawing = _;
    }

    constructor(
        private viewer: Viewer,
    ) {
        //
    }

    @HostListener('window:keydown', ['$event'])
    onKeydown(e: any): void {
        if (e && e.key) {
            switch (e.key) {
                case 'F1' : this.drawing_view(e);
                    break;
                case 'Escape': this.closeViewer(e);
                    break;
            }
        } else {
            //
        }
    }

    @HostListener('click', ['$event'])
    onClick(e: any): void {
        this.drawing_view(e);
    }

    public drawing_view(e: any): void {
        e.stopPropagation();
        e.preventDefault();
        this.viewer.drawing(this._drawing)
            .then(() => {
                //
            });
    }

    public closeViewer(e: any): void {
        //
    }

}
