import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {PurchaseOrderModel} from '../models/purchaseOrder.model';

@Injectable()
export class PurchaseService {

    constructor(private api: Api) {
        //
    }

    public prepare(purchase_order: PurchaseOrderModel, items: any[], task_id?: any): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('purchases/prepare', 'POST', {}, {
                id: purchase_order.id,
                items: items,
                task_id: task_id
            }).subscribe((response: any): void => {
                if (response && response.success) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

}
