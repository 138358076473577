import {FormModel} from '../now/formModel';
import {ProductModel} from './product.model';
import {CarrierModel} from './carrier.model';
import {CustomerModel} from './customer.model';
import {UserModel} from '../now/user/user.model';
import {Model} from '../now/model';
import {TaxInvoiceModel} from './taxInvoice.model';
import {PackingSlipModel} from './packingSlip.model';
import {CustomerAddressModel} from './customerAddress.model';
import {RequirementModel} from './requirement.model';
import {DocumentModel} from './document.model';
import {PaymentModel} from './payment.model';
import {DepositModel} from './deposit.model';
import {ContactModel} from './contact.model';
import {BillModel} from './bill.model';
import {InvoiceModel} from './invoice.model';
import {ReceiptModel} from './receipt.model';
import {DeliveryNoteModel} from './deliveryNote.model';

export class QuotationModel extends FormModel {

    public processing_times: number;
    public price_validity: number;
    public quotation_no: string;
    public contact_ref: string;
    public requirement_id: string;
    public document: DocumentModel;
    public type: number;
    public products: ProductModel[];
    public carrier: CarrierModel;
    public customer: CustomerModel;
    public sale: UserModel;
    public deposits: DepositModel[];
    public tax_invoices: TaxInvoiceModel[];
    public invoices: InvoiceModel[];
    public contact: ContactModel;
    public documents: DocumentModel[];
    public packing_lists: PackingSlipModel[];
    public discount_type: number;

    public bill_id: string;
    public bill: BillModel;

    public ref_no: string;
    public rfq: string;

    public children: QuotationModel[];

    public contact_id: string;
    public task_id: string;
    public customer_id: string;
    public product_ids: any;

    public total_price: number;

    public customer_address_id: string;
    public sale_id: string;
    public shipping_id: string;
    public currency: string;
    public currency_symbol: string;
    public currency_rate: number;
    public currency_rated_at: string;
    public signature_date: string;
    public delivery_date: string;
    public delivery_time: string;

    public vat_percent: number;
    public vat_price: number;
    public discount_percent: number;
    public discount_price: number;
    public material_price: number;
    public frt_sh: number;
    public misc_chgs: number;
    public sub_total: number;
    public gst_tax: number;
    public grand_total: number;

    public is_include_vat: boolean;

    public required_at: string;

    public requirement: RequirementModel;
    // public purchase_orders: PurchaseOrderModel[];
    public payments: PaymentModel[];
    public tax_invoice: TaxInvoiceModel;
    public packing_slip: PackingSlipModel;
    public receipts: ReceiptModel[];
    public delivery_notes: DeliveryNoteModel[];

    public shipping: any;
    public incoterm_code: string;
    public carrier_id: string;
    public customer_address: CustomerAddressModel;

    public sent_date: string;
    public sent_user: UserModel;

    public product_amount: number;
    public product_price: number;

    constructor() {
        super('quotations', 'App\\Quotation');
        //
        this.sale = new UserModel();
        this.incoterm_code = '';
        this.product_ids = {};
        this.products = [];
        this.children = [];
        this.payments = [];
        this.receipts = [];
        this.delivery_notes = [];
        this.deposits = [];
        this.packing_lists = [];
        this.tax_invoices = [];
        this.invoices = [];
        this.tax_invoice = new TaxInvoiceModel();
        this.packing_slip = new PackingSlipModel();
        this.document = new DocumentModel();
        this.sent_user = new UserModel();
        this.documents = [];
        this.discount_type = 1;
    }

    public clone(data: any): Model {
        if (data && data.products) {
            for (let i = 0; i < data.products.length; i++) {
                const dat: any = data.products[i];
                if (dat) {
                    let product: ProductModel;
                    product = new ProductModel();
                    product.clone(dat);
                    this.products.push(product);
                }
            }
            delete data.products;
        }
        if (data && data.payments) {
            for (let i = 0; i < data.payments.length; i++) {
                const dat: any = data.payments[i];
                if (dat) {
                    let payment: PaymentModel;
                    payment = new PaymentModel();
                    payment.clone(dat);
                    this.payments.push(payment);
                }
            }
            delete data.payments;
        }
        if (data && data.documents) {
            for (let i = 0; i < data.documents.length; i++) {
                const dat: any = data.documents[i];
                if (dat) {
                    let document: DocumentModel;
                    document = new DocumentModel();
                    document.clone(dat);
                    this.documents.push(document);
                }
            }
            delete data.documents;
        }
        if (data && data.deposits) {
            for (let i = 0; i < data.deposits.length; i++) {
                const dat: any = data.deposits[i];
                if (dat) {
                    let deposit: DepositModel;
                    deposit = new DepositModel();
                    deposit.clone(dat);
                    this.deposits.push(deposit);
                }
            }
            delete data.deposits;
        }
        if (data && data.receipts) {
            for (let i = 0; i < data.receipts.length; i++) {
                const dat: any = data.receipts[i];
                if (dat) {
                    let receipt: ReceiptModel;
                    receipt = new ReceiptModel();
                    receipt.clone(dat);
                    this.receipts.push(receipt);
                }
            }
            delete data.receipts;
        }
        if (data && data.invoices) {
            for (let i = 0; i < data.invoices.length; i++) {
                const dat: any = data.invoices[i];
                if (dat) {
                    let invoice: InvoiceModel;
                    invoice = new InvoiceModel();
                    invoice.clone(dat);
                    this.invoices.push(invoice);
                }
            }
            delete data.invoices;
        }
        if (data && data.tax_invoices) {
            for (let i = 0; i < data.tax_invoices.length; i++) {
                const dat: any = data.tax_invoices[i];
                if (dat) {
                    let tax_invoice: TaxInvoiceModel;
                    tax_invoice = new TaxInvoiceModel();
                    tax_invoice.clone(dat);
                    this.tax_invoices.push(tax_invoice);
                }
            }
            delete data.tax_invoices;
        }
        if (data && data.customer) {
            this.customer = new CustomerModel();
            this.customer.clone(data.customer);
            delete data.customer;
        }
        if (data && data.contact) {
            this.contact = new ContactModel();
            this.contact.clone(data.contact);
            delete data.contact;
        }
        if (data && data.customer_address) {
            this.customer_address = new CustomerAddressModel();
            this.customer_address.clone(data.customer_address);
            delete data.customer_address;
        }
        if (data && data.bill) {
            this.bill = new BillModel();
            this.bill.clone(data.bill);
            delete data.bill;
        }
        if (data && data.carrier) {
            this.carrier = new CarrierModel();
            this.carrier.clone(data.carrier);
            delete data.carrier;
        }
        if (data && data.requirement) {
            this.requirement = new RequirementModel();
            this.requirement.clone(data.requirement);
            delete data.requirement;
        }
        super.clone(data);
        return this;
    }

    public validPayment(): boolean {
        if (this.payments && this.payments.length > 0) {
            for (let i = 0; i < this.payments.length; i++) {
                if (this.payments[i] && this.payments[i].verified === true) {
                    return true;
                }
            }
        }
        return false;
    }

    public cal_total_price(): number {
        const _vat_percent = (this.vat_percent) ? this.vat_percent : 0;

        let material_price_with_vat: number;
        material_price_with_vat = 0;
        this.material_price = 0;
        for (let i = 0; i < this.products.length; i++) {
            const product: ProductModel = this.products[i];
            if (product && product.price_per_unit && product.customer_product_amount) {
                if (this.is_include_vat === false) {
                    this.material_price += product.price_per_unit * product.customer_product_amount;
                } else {
                    // this.material_price += (Math.round(((product.price_per_unit * product.customer_product_amount) / 1.07) * 100) / 100);
                    this.material_price += (product.price_per_unit * product.customer_product_amount) / 1.07;
                    material_price_with_vat += product.price_per_unit * product.customer_product_amount;
                }
                // this.material_price += product.price_per_unit * product.customer_product_amount;
            }
        }

        const material_price: number = (this.material_price > 0) ? this.material_price : 0;
        const frt_sh: number = (this.frt_sh > 0) ? (this.is_include_vat === false) ? this.frt_sh : this.frt_sh / (1 + (this.vat_percent / 100)) : 0;
        const misc_chgs: number = (this.misc_chgs > 0) ? (this.is_include_vat === false) ? this.misc_chgs : this.misc_chgs / (1 + (this.vat_percent / 100)) : 0;
        // const _discount_price = (this.discount_price) ? this.discount_price : 0;
        const _discount_percent = (this.discount_percent) ? this.discount_percent : 0;
        const sub_total = material_price + frt_sh + misc_chgs;

        if (this.discount_type === 1) {
            // this.discount_price = (this.is_include_vat === false)
            //     ? Math.round(((sub_total * _discount_percent) / 100) * 100) / 100 : Math.round((((sub_total * _discount_percent) / 100) / 1.07) * 100) / 100;
            this.discount_price = Math.round(((material_price * _discount_percent) / 100) * 100) / 100;
        } else {
            //
        }

        const discount_price_with_vat = Math.round(((material_price_with_vat * _discount_percent) / 100) * 100) / 100;

        if (this.is_include_vat === false) {
            this.sub_total = sub_total - this.discount_price;
            this.vat_price = Math.round(((this.sub_total * _vat_percent) / 100) * 100) / 100;
        } else {
            this.sub_total = Math.round((((material_price_with_vat + ((this.frt_sh) ? this.frt_sh : 0) + ((this.misc_chgs) ? this.misc_chgs : 0)) - discount_price_with_vat) / 1.07) * 100) / 100;
            this.vat_price = ((material_price_with_vat + ((this.frt_sh) ? this.frt_sh : 0) + ((this.misc_chgs) ? this.misc_chgs : 0)) - discount_price_with_vat) - this.sub_total;
        }
        this.grand_total = this.vat_price + this.sub_total;

        return this.grand_total;
    }

}
