import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskModel} from '../../../../models/task.model';
import {TaskService} from '../../../../services/task.service';
import {PusherService} from '../../../../services/pusher.service';
import {DivApiDirective} from '../../../../now/divApi';
import {UserService} from '../../../../services/user.service';
import {SwalService} from '../../../../services/swal.service';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {ModalService} from '../../../../services/modal.service';
import {FindProductComponent} from '../../../../modals/findProduct/findProduct.component';
import {FindQuotationComponent} from '../../../../modals/findQuotation/findQuotation.component';
import {FindRequirementComponent} from '../../../../modals/findRequirement/findRequirement.component';
import {Api} from '../../../../now/api/api';
import {AppService} from '../../../../app/app.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ViewRemarkComponent} from '../../view/viewRemark/viewRemark.component';
import {environment} from '../../../../environments/environment';
import {FindPOComponent} from '../../../../modals/findPO/findPO.component';
import {LoaderService} from '../../../../components/loader/loader.service';
import {Viewer} from '../../../../services/viewer';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {CustomerListModal} from './customerListModal/customerList.modal';
import {ReceiveTaskListModal} from './receiveTaskList/receiveTaskList.modal';

@Component({
    selector: 'app-receive-task-list-component',
    templateUrl: 'receiveTaskList.component.html',
    styleUrls: ['receiveTaskList.component.scss']
})
export class ReceiveTaskListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChildren(DivApiDirective) divApi: QueryList<DivApiDirective>;
    @ViewChild('customerListModal', { static: false }) customerListModal: CustomerListModal;
    @ViewChild('receiveTaskListModal', { static: false }) receiveTaskListModal: ReceiveTaskListModal;

    public tmp_keyword: string;
    public taskList: ReceiveTaskListComponent;
    public keyword: string;
    public all_tasks: TaskModel[];
    public room_slug: string;
    public current_job_document_path: string;
    public current_task: TaskModel;
    public timeout: any;
    public customers: any;
    public assemblies: any;
    public keyword_subject: any;
    public role: string;
    public channel_name: string;
    public pageWidth: string;
    public config: PerfectScrollbarConfigInterface;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modal: ModalService,
        private taskService: TaskService,
        private userService: UserService,
        private modelApi: ModelApi,
        private swal: SwalService,
        private ngZone: NgZone,
        private pusherService: PusherService,
        private api: Api,
        private viewer: Viewer,
        private appService: AppService,
        private loader: LoaderService
    ) {
        //
        this.assemblies = [];
        this.initRoom();
        this.pageWidth = 'calc(100vw - 16px)';
        this.config = {
            useBothWheelAxes: false,
            suppressScrollX: false,
            suppressScrollY: true
        };
        this.customers = [];
        this.taskList = this;
        this.keyword_subject = new Subject();
        this.keyword_subject
            .pipe(
                debounceTime(750)
            )
            .subscribe(text => {
                this.keyword = text;
            });
    }

    private initRoom(): void {
        this.all_tasks = [];
        this.route.params
            .subscribe(params => {
                this.room_slug = 'finish';
                const channel_name: string = 'erpst.task.' + this.room_slug;
                this.channel_name = channel_name;
                console.log(this.room_slug);
                // this.pusherService.subscribe(channel_name);
                // this.pusherService.bind(channel_name, 'tasks.update', data => {
                //     this.refresh();
                // });
            });
    }

    ngAfterViewInit(): void {
        this.appService.isTaskListPage = true;
    }

    ngOnInit(): void {
        //
    }

    ngOnDestroy(): void {
        // this.pusherService.unsubscribe(this.channel_name);
    }

    public onCustomerModalSubmit(customer: any): void {
        if (customer && customer.id) {
            this.customerListModal.close();
            this.receiveTaskListModal.open(customer);
        }
    }

    public findCustomers(): void {
        this.customerListModal.open();
    }

    public createInvoice(): void {
        //
    }

    /*public createShippingDocuments(): void {
        let task_ids: string[];
        task_ids = [];
        let current_customer_id: number;
        if (this.all_tasks && this.all_tasks.length) {
            for (let i = 0; i < this.all_tasks.length; i++) {
                const task: any = this.all_tasks[i];
                if (task && task.id && task._checked) {
                    if (!current_customer_id) {
                        current_customer_id = task.customer_id;
                    } else if (current_customer_id !== task.customer_id) {
                        this.swal.danger('ไม่สามารถออกเอกสารหลากลูกค้าได้');
                        return;
                    }
                    task_ids.push(task.id);
                }
            }
        } else {
            //
        }
        if (task_ids && task_ids.length) {
            this.swal.confirm('ยืนยันการออกเอกสารตามงานที่ถูกเลือกทั้งหมดนี้ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.loader.show();
                        this.api.request('shipping/documents', 'PUT', {}, {
                            task_ids : task_ids
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.loader.hide();
                                this.swal.success('ออกเอกสารแผนก Shipping สำเร็จ');
                                this.refresh();
                            } else {
                                this.loader.hide();
                            }
                        }, error => {
                            this.loader.hide();
                        });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('กรุณาเลือกงานในการออกเอกสาร');
        }
    }*/

    public onAssemblyCheck(tasks: any[], e?: any): void {
        //
    }

    public onShippingDocumentSubmit(e: any): void {
        if (e && e.tasks && e.tasks.length) {
            let task_ids: any[];
            task_ids = [];
            for (const child of e.tasks) {
                if (child && child.id) {
                    task_ids.push(child.id);
                }
            }
            if (task_ids.length) {
                this.loader.show();
                this.api.request('shipping/documents', 'PUT', {}, {
                    task_ids : task_ids
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.loader.hide();
                        this.swal.success('ออกเอกสารแผนก Shipping สำเร็จ');
                        this.refresh();
                    } else {
                        this.loader.hide();
                    }
                }, error => {
                    this.loader.hide();
                });
            } else {
                this.swal.danger('ไม่พบรายการงานของลูกค้า "' + e.customer.customer_name + '"');
            }
        } else {
            //
        }
    }

    public onCustomerCheck(customer: any, e?: any): void {
        if (customer && customer.children) {
            for (let i = 0; i < customer.children.length; i++) {
                const task: any = customer.children[i];
                if (task && task.id) {
                    task._checked = e;
                }
            }
        }
    }

    public createPackingList(): void {
        //
    }

    public refresh(): void {
        this.timeout = setTimeout(() => {
            this.clear_timeout();
            this.divApi.forEach((divApi: DivApiDirective) => {
                divApi.refresh()
                    .subscribe(() => {
                        //
                    }, error => {
                        //
                    }, () => {
                        //
                    });
            });
        }, 300);
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        if (response && response.data) {
            this.render(response.data);
        }
    }

    public onError(e: any): void {
        //
    }

    public onModalSubmit(e: any): void {
        //
    }

    gotoTaskView(task: TaskModel | any): void {
        this.taskService.gotoTask(task);
    }

    private render(data: any): void {
        /*this.ngZone.run(() => {
            this.clear_tasks();
            if (data && data.customers) {
                for (const dat of data.customers) {
                    if (dat && dat.id) { // dat is customer
                        // this.all_tasks.push(dat);
                        this.customers.push(dat);
                    }
                }
            }
        });*/
        this.ngZone.run(() => {
            this.clear_tasks();
            for (const dat of data) {
                if (dat && dat.id) { // dat is task model
                    this.all_tasks.push(dat);
                    dat.customer_name = (dat && dat.modelable && dat.modelable.customer) ? dat.modelable.customer.name : '';
                    this.customers.push(dat);
                }
            }
        });
    }

    public onClickHireHandler(e: any, task: TaskModel): void {
        e.stopPropagation();
        this.taskService.hire(task)
            .then(() => {
                // Go Go!
            });
    }

    public viewRemark(task: TaskModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        this.current_task = task;
        if (task) {
            this.modal.show(ViewRemarkComponent, {
                task: task
            }, { class: 'modal-lg' }).then(() => {
                //
            });
        } else {
            //
        }
    }

    public viewQuotation(task: TaskModel, quotation_no?: string, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.quotation(quotation_no)
            .then(() => {
                //
            });
    }

    public viewJob(task: TaskModel, job_no?: string, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (job_no && task.modelable_type === 'App\\Job') {
            this.viewer.manufacture(job_no, true, true)
                .then(path => {
                    //
                });
        }
    }

    public viewPayment(purchase_order_no: string): void {
        this.viewer.payment(purchase_order_no)
            .then(() => {
                //
            });
    }

    public viewTaxInvoicePreview(tax_invoice_no: string): void {
        this.viewer.taxInvoice(tax_invoice_no)
            .then(() => {
                //
            });
    }

    public viewInvoicePreview(invoice_no: string): void {
        this.viewer.invoice(invoice_no)
            .then(() => {
                //
            });
    }

    public viewReceiptPreview(receipt_no: string): void {
        this.viewer.receipt(receipt_no)
            .then(() => {
                //
            });
    }

    public viewDepositPreview(deposit_no: string): void {
        this.viewer.deposit(deposit_no)
            .then(() => {
                //
            });
    }

    public viewPackingSlipPreview(packing_slip_no: string): void {
        this.viewer.packingSlip(packing_slip_no)
            .then(() => {
                //
            });
    }

    public viewDrawingPreview(drawing_no: string, task: TaskModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.drawing(drawing_no);
    }

    private clear_tasks(): void {
        if (this.all_tasks) {
            this.all_tasks.splice(0, this.all_tasks.length);
        } else {
            this.all_tasks = [];
        }
        if (this.customers) {
            this.customers.splice(0, this.customers.length);
        } else {
            this.customers = [];
        }
        if (this.assemblies) {
            this.assemblies.splice(0, this.assemblies.length);
        } else {
            this.assemblies = [];
        }
    }

    public createItem(): void {
        this.router.navigateByUrl('/store');
    }

    public gotoCustomerList(): void {
        this.router.navigateByUrl('/database/customer');
    }

    public gotoQuotationList(): void {
        this.router.navigateByUrl('/database/quotation');
    }

    public findProduct(): void {
        this.modal.show(FindProductComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.task && content.task.id) {
                        this.taskService.gotoTask(content.task);
                    } else if (content.product_id) {
                        this.createProduct(content.product_id);
                    }
                } else {
                    //
                }
            });
    }

    public findQuotation(): void {
        this.modal.show(FindQuotationComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    this.taskService.gotoTask(content.task);
                } else {
                    //
                }
            });
    }

    public findPO(): void {
        this.modal.show(FindPOComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.task && content.task.id) {
                        this.taskService.gotoTask(content.task);
                    }
                } else {
                    //
                }
            });
    }

    public createPurchase(): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'purchase';
        task.current_role = 'purchase';
        task.action = 'create_purchase';
        this.taskService.hire(task, null, false, task.action)
            .then(() => {
                //
            });
    }

    public findRequirement(): void {
        this.modal.show(FindRequirementComponent)
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.task && content.task.id) {
                        this.taskService.gotoTask(content.task);
                    } else {
                        //
                    }
                } else {
                    //
                }
            });
    }

    public createRequirement(): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'requirement';
        task.current_role = 'contact';
        task.action = 'create_requirement';
        task.modelable_type = 'App\\Requirement';
        this.taskService.hire(task, null, false, 'create_requirement')
            .then(() => {
                //
            });
    }

    public createProduct(product_id?: string, force?: boolean): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'product';
        task.current_role = 'product';
        task.action = 'create_product';
        task.modelable_type = 'App\\Product';
        task.next_role = 'planning';
        if (product_id) {
            task.modelable_id = product_id;
        } else {
            //
        }
        this.taskService.hire(task, null, ((!force) ? false : true), task.action, task.action)
            .then(() => {
                // Go Go!
            });
    }

    public createQuotation(): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'quotation';
        task.current_role = 'quotation';
        task.action = 'create_quotation';
        task.modelable_type = 'App\\Quotation';
        this.taskService.hire(task, null, false, 'create_quotation')
            .then(() => {
                // Go Go!
            });
    }

    public onKeywordInput(e: any): void {
        this.keyword_subject.next(e);
    }

}
