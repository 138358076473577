import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {CustomerModel} from '../../../../models/customer.model';
import {JobModel} from '../../../../models/job.model';
import {ProductModel} from '../../../../models/product.model';
import {PackingSlipModel} from '../../../../models/packingSlip.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'app-packing-list-detail-component',
    templateUrl: 'packingListDetail.component.html',
    styleUrls: ['packingListDetail.component.scss']
})
export class PackingListDetailComponent implements OnInit {

    public packingList: PackingSlipModel;
    public tmp_packingList: PackingSlipModel;
    public credit_terms: number;
    public percent: number;
    public task: TaskModel;
    public quotation: QuotationModel;
    public model: any;
    public products: ProductModel[];
    public customer: CustomerModel;
    public job: JobModel;
    public is_new: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        public viewer: Viewer,
        private modelApi: ModelApi,
        private swal: SwalService
    ) {
        //
        this.packingList = new PackingSlipModel();
        this.tmp_packingList = new PackingSlipModel();
        this.quotation = new QuotationModel();
        this.products = [];
        // if (this.model && this.model.customer) {
        //     this.customer = this.model.customer;
        // }
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                this.products = this.bsModalRef.content.products;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.packing_list) {
                // if (!this.bsModalRef.content.packing_list.started_at) {
                //     this.bsModalRef.content.packing_list.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                // }
                this.packingList = this.bsModalRef.content.packing_list;
                this.tmp_packingList.started_at = this.packingList.started_at;
                this.tmp_packingList.delivery_date = this.packingList.delivery_date;
                // this.tmp_packingList.ended_at = this.packingList.ended_at;
                this.tmp_packingList.id = this.packingList.id;
                this.tmp_packingList.packing_slip_no = this.packingList.packing_slip_no;
                // this.tmp_packingList.required_at = this.packingList.required_at;
                this.tmp_packingList.remark = this.packingList.remark;
                this.tmp_packingList.status = (this.packingList.status) ? this.packingList.status : 2;
                this.tmp_packingList.ref_no = this.packingList.ref_no;

                if (!this.tmp_packingList.started_at) {
                    this.tmp_packingList.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                if (!this.tmp_packingList.delivery_date) {
                    this.tmp_packingList.delivery_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                // if (!this.tmp_packingList.ended_at && this.tmp_packingList.started_at && this.credit_terms > 0) {
                //     this.tmp_packingList.ended_at = moment(this.tmp_packingList.started_at, 'YYYY-MM-DD HH:mm:ss')
                //         .add(this.credit_terms, 'days')
                //         .format('YYYY-MM-DD HH:mm:ss');
                // }

                this.is_new = this.packingList.isNew;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
        }, 0);
    }

    public onStartedChanged(e: any): void {
        /*if (e) {
            const started_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
            const ended_moment_object: any = moment(this.tmp_packingList.ended_at, 'YYYY-MM-DD HH:mm:ss');
            this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
        }*/
    }

    public onDeliveryChange(e: any): void {
        //
    }

    public delete(): void {
        if (this.packingList.status === 1) {
            return;
        }
        const name: string = (this.tmp_packingList.packing_slip_no)
            ? this.tmp_packingList.packing_slip_no : 'ใบวางเงินมัดจำ';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_packingList)
                        .subscribe((response: any): void => {
                            const index: number = this.model.packing_lists.findIndex(i => i.id === this.tmp_packingList.id);
                            if (index > -1) {
                                this.model.packing_lists.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewPackingSlip(): void {
        this.viewer.packingSlip(this.tmp_packingList);
    }

    public update(): void {
        if (this.tmp_packingList && !this.tmp_packingList.id) {
            this.swal.confirm('ยืนยันการออกเอกสารแพ็กสินค้าใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('packing/slips', 'PUT', {}, {
                            customer_id: this.model.customer_id,
                            delivery_date: this.tmp_packingList.delivery_date,
                            model_id: this.model.id,
                            // tax_invoice_id: this.model.id,
                            bill_id: this.model.bill_id,
                            customer_address_id: this.model.customer_address_id,
                            contact_id: this.model.contact_id,
                            incoterm_code: this.model.incoterm_code,
                            status: this.tmp_packingList.status,
                            ref_no: this.tmp_packingList.ref_no,
                            started_at: this.tmp_packingList.started_at,
                            total_price: this.tmp_packingList.total_price,
                            remark: this.tmp_packingList.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกเอกสารแพ็คสินค้าสำเร็จ');
                            this.bsModalRef.content.packing_list = response.data;
                            this.bsModalRef.content.is_create = true;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขรายละเอียดเอกสารใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('packing/slips/status', 'POST', {}, {
                            id: this.tmp_packingList.id,
                            status: this.tmp_packingList.status
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.packing_list.status = this.tmp_packingList.status;
                            this.bsModalRef.content.is_submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        }
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const product_ids: any[] = this.get_product_ids();
            let request_data: any;
            request_data = {
                product_ids     : product_ids,
                customer_id     : this.model.customer_id,
                credit_terms    : this.tmp_packingList.credit_terms,
                total_price     : this.tmp_packingList.total_price,
                started_at      : this.tmp_packingList.started_at,
                remark          : this.tmp_packingList.remark,
                status          : this.tmp_packingList.status
            };
            if (this.model && this.model.model_name === 'App\\Quotation') {
                request_data['quotation_id'] = this.model.id;
            } else if (this.model && this.model.model_name === 'App\\TaxInvoice') {
                request_data['tax_invoice_id'] = this.model.id;
            }
            this.api.request('packing/slips', 'PUT', {}, request_data)
                .subscribe((response: any): void => {
                this.packingList.clone(response.data);
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

    public onSubmit(form: NgForm): void {
        this.save()
            .then(() => {
                this.swal.success('บันทักข้อมูลใบวางเงินมัดจำสำเร็จ');
                this.bsModalRef.content.is_submit = this.is_new;
                this.bsModalRef.content.packing_list.status = this.tmp_packingList.status;
                this.bsModalRef.hide();
            });
    }

}
