import {Inject, Injectable} from '@angular/core';
import {DashboardComponent} from '../pages/dashboard/dashboard.component';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class AppService {

    public isTaskListPage: boolean;
    public navbar_is_shown: boolean;
    public full_page_screen: boolean;
    public pages: any[];
    public screen_is_full: boolean;
    public tasks_handle: any;
    public tasks_idle: any;
    public dashboardComponent: DashboardComponent;
    public job_id: string;
    public task_id: string;
    public target: string;
    public job_no: string;

    constructor(
        @Inject(DOCUMENT) private document: any
    ) {
        //
        this.navbar_is_shown = true;
        this.screen_is_full = false;
        this.full_page_screen = false;
        this.isTaskListPage = false;
        this.tasks_handle = {};
        this.tasks_idle = {};
    }

    public fixPage(): void {
        // this.renderer.addClass(this.document.body, 'fixed-page');
        this.document.body.classList.remove('fixed-page');
        this.document.body.classList.add('fixed-page');
    }

    public unfixPage(): void {
        // this.renderer.removeClass(this.document.body, 'fixed-page');
        this.document.body.classList.remove('fixed-page');
    }

    public dashboardFilter(filter: string): void {
        if (this.dashboardComponent) {
            this.dashboardComponent.filter_text = filter;
            this.dashboardComponent.findHighLight();
        }
    }

    public dashboardSearch(job_id: string, task_id: string, target: string, job_no?: string, force?: boolean): void {
        this.job_no = job_no;
        this.job_id = job_id;
        this.task_id = task_id;
        this.target = target;
        // this.dashboardComponent.filter_text = this.job_no;
        /*if (this.dashboardComponent && force === true) {
            setTimeout(() => {
                this.dashboardComponent.start_search(this.job_id, this.task_id, this.target);
            }, 300);
        }*/
    }

    public resetDashboardSearch(): void {
        this.job_id = null;
        this.task_id = null;
        this.target = null;
    }

    public resetTasks(): void {
        if (this.tasks_handle) {
            for (const key in this.tasks_handle) {
                if (key) {
                    const value = this.tasks_handle[key];
                    for (let i = 0; i < value.length; i++) {
                        const val = value[i];
                        val.found = false;
                    }
                }
            }
        }
        if (this.tasks_idle) {
            for (const key in this.tasks_idle) {
                if (key) {
                    const value = this.tasks_idle[key];
                    for (let i = 0; i < value.length; i++) {
                        const val = value[i];
                        val.found = false;
                    }
                }
            }
        }
    }

    public hideNavbar(): void {
        this.navbar_is_shown = false;
    }

    public showNavbar(): void {
        this.navbar_is_shown = true;
    }

}
