import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import {TaskService} from '../../../services/task.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {VendorModel} from '../../../models/vendor.model';
import {PurchaseModel} from '../../../models/purchase.model';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {JobModel} from '../../../models/job.model';
import {StoreService} from '../../../services/store.service';
import {PricingInquiryModel} from '../../../models/pricingInquiry.model';
import {PricingInquiryService} from '../../../services/pricingInquiry.service';
import {DocumentModel} from '../../../models/document.model';
import {PricingInquiryDocument} from '../../../app/api/pricingInquiryDocument';
import {PricingInquiryDocumentDetailComponent} from '../purchase/pricingInquiryDocumentDetail/pricingInquiryDocumentDetail.component';
import {FVD} from '../../../app/api/fvd';
import {FVDService} from '../../../services/fvd.service';
import {JobService} from '../../../services/job.service';
// import {CompleteModalComponent} from '../prod/complete/completeModal.component';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'fvd-task-component',
    templateUrl: 'fvdTask.component.html',
    styleUrls: ['fvdTask.component.scss'],
    providers: [DecimalPipe]
})
export class FVDTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    public pricing_inquiry: PricingInquiryModel;
    public fvd: FVD;
    public job: JobModel;
    public purchase_order: PurchaseOrderModel;
    public sale: UserModel;
    public customer: CustomerModel;
    public current_tab: string;
    public incoterms: IncotermModel[];
    public carriers: CarrierModel[];
    public vendor: VendorModel;
    public new_tasks: TaskModel[];
    public items: ProductModel[];
    public job_no: string;
    public product_no: string;
    public product: ProductModel;
    public ncr_pdf_path: string;
    public product_processes: ProductProcessModel[];
    public request_man: UserModel;

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private swal: SwalService,
        private pricingInquiryService: PricingInquiryService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        private storeService: StoreService,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        private route: ActivatedRoute,
        public location: Location,
        private jobService: JobService,
        private fvdService: FVDService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.product_processes = [];
        this.current_tab = '#information';
        this.vendor = new VendorModel();
        this.sale = new UserModel();
        this.items = [];
        this.job = new JobModel();
        this.fvd = {};
        this.product = new ProductModel();

        this.new_tasks = [];
        this.job_no = '';
        this.product_no = '';

        this.getNewTasks()
            .then(() => {});
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        // this.purchase_order.task_id = this.task_id;
                        this.getTaskDetail();
                    } else {
                        //
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public completeTask(): void {
        /*this.modal.show(CompleteModalComponent, {
            task                : this.task,
            product             : this.product,
            job                 : this.job,
            product_processes   : this.product_processes
        }).then((content: any): void => {
            if (content && content.completed === true) {
                this.swal.success('เสร็จสิ้นกระบวนการ');
                this.leave(true);
            } else {
                //
            }
        });*/
    }

    public getNewTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks', 'GET', {
                process_slug: 'product',
                status: 9
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat = response.data[i];
                        if (dat) {
                            let task: TaskModel;
                            task = new TaskModel();
                            task.clone(dat);
                            this.new_tasks.push(task);
                        }
                    }
                }
                resolve(this.new_tasks);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.data) {
                    this.task.clone(response.data);
                    this.current_tab = '#product';
                    this.job.id = this.task.modelable_id;
                    this.getJobDetail(this.job.id)
                        .then(() => {
                            this.product.id = this.job.product_id;
                            this.ncr_pdf_path = environment.api_host + 'view/' + this.job.id + '/job/ncrs';
                            if (this.job.current_product_process && this.job.current_product_process.fvd_id) {
                                this.getFVD(this.job.current_product_process.fvd_id)
                                    .then(() => {});
                            } else {
                                // TODO: error ?
                            }
                            this.getProductDetail()
                                .then(() => {
                                    this.init_product_processes();
                                });
                        });

                    /*if (response.data.modelable_type === 'App\\Job') {
                        this.getJob(response.data.modelable_id)
                            .then(() => {
                                if (this.job.current_pricing_inquiry_id) {
                                    this.getPricingInquiry(this.job.current_pricing_inquiry_id)
                                        .then(() => {
                                            this.initPricingInquiry();
                                        });
                                } else {
                                    //
                                }
                            });
                    } else if (response.data.modelable_type === 'App\\PricingInquiry') {
                        this.getPricingInquiry(response.data.modelable_id)
                            .then(() => {
                                this.initPricingInquiry();
                            });
                    }*/
                }
            }, error => {
                //
            });
    }

    private init_product_processes(): void {
        if (this.product.product_processes && this.product.product_processes.length > 0) {
            for (let i = 0; i < this.product.product_processes.length; i++) {
                const product_process: ProductProcessModel = this.product.product_processes[i];
                this.product_processes.push(product_process);
                /*this.product_process_update(product_process);
                if (product_process.latest_child && product_process.latest_child.checked) {
                    this.schedulers.push(product_process.latest_child.scheduler);
                } else if (product_process && product_process.checked) {
                    this.schedulers.push(product_process.scheduler);
                }*/
            }
        }
        /*if (this.fullCalendarComponent) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(this.schedulers);
            this.fullCalendarComponent.refetchResources();
        }*/
    }

    public getFVD(fvd_id: string): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            this.fvdService.sync(fvd_id)
                .then(fvd => {
                    this.fvd = fvd;
                    resolve(this.fvd);
                }, error => {
                    resolve(this.fvd);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product.id) {
                this.modelApi.sync(this.product, this.product.id, null, {
                    job_id: this.job.id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onDocumentVendorChange(doc: PricingInquiryDocument, e: any): void {
        setTimeout(() => {
            this.api.request('pricing/inquiries/document', 'POST', {}, {
                id: doc.id,
                vendor_id: doc.vendor_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    //
                } else {
                    //
                }
            });
        }, 300);
    }

    public viewDocumentDetail(doc: PricingInquiryDocument, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PricingInquiryDocumentDetailComponent, {
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((data: any): void => {
                if (data && data.deleted === true) {
                    this.pricing_inquiry.documents.splice(index, 1);
                    this.swal.success('ลบเอกสารสำเร็จ');
                } else if (data && data.submit === true) {
                    doc.name = data.name;
                    this.swal.success('แก้ไขเอกสารสำเร็จ');
                }
            });
    }

    public onRemarkModalSubmit(e: any): void {
        // TODO: change job toggle history to back-end process
        this.jobService.reportJob(this.job, e.remark_message)
            .then(() => {
                this.reportJobSetTaskStatus(this.task.status, 'creating_ncr', 'dummy', 'dummy', false)
                    .then(() => {
                        this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                            .then(() => {
                                super.leave(true);
                            });
                    });
            });
        /*this.api.request('jobs/history', 'POST', {}, {
            action              : 'leave',
            id                  : this.job.id,
            product_process_id  : this.job.current_product_process.id,
            // amount              : content.product_push_amount,
            remark              : remark_message
        }).subscribe((response: any): void => {
            this.jobService.reportJob(this.job, remark_message)
                .then(() => {
                    this.reportJobSetTaskStatus(this.task.status, 'creating_ncr', 'dummy', 'dummy', false)
                        .then(() => {
                            this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                                .then(() => {
                                    super.leave(true);
                                });
                        });
                });
        }, error => {
            //
        });*/
    }

    private reportJobSetTaskStatus(task_status: number,
                                   action?: string,
                                   to_role?: string,
                                   to_process?: string,
                                   completed?: boolean,
                                   task_id?: string): Promise<Object> {
        //
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.taskService.setStatus((task_id) ? task_id : this.task, task_status, action, to_role, to_process, completed)
                .then((task: TaskModel): void => {
                    if (this.task) {
                        this.task.status = task_status;
                    } else {
                        //
                    }
                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    public initPricingInquiry(): void {
        if (this.pricing_inquiry && this.pricing_inquiry.items) {
            for (let i = 0; i < this.pricing_inquiry.items.length; i++) {
                this.items.push(this.pricing_inquiry.items[i]);
            }
        } else {
            //
        }
        if (this.pricing_inquiry && this.pricing_inquiry.job) {
            this.job_no = this.pricing_inquiry.job.job_no;
        }
        if (this.pricing_inquiry && this.pricing_inquiry.product) {
            this.product_no = this.pricing_inquiry.product.product_no;
        }
        this.request_man = this.pricing_inquiry.request_man;
        if (!this.pricing_inquiry.purchase_user_id) {
            this.pricing_inquiry.purchase_user_id = this.userService.getID();
            this.pricing_inquiry.purchase_user = this.authService.user;
        }
    }

    public getJobDetail(job_id: string): Promise<JobModel> {
        let promise: Promise<JobModel>;
        promise = new Promise<JobModel>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        if (this.job && this.job.job_no) {
                            this.job_no = this.job.job_no;
                        }
                        if (this.job && this.job.product && this.job.product.product_no) {
                            this.product_no = this.job.product.product_no;
                        }
                        resolve(this.job);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getPricingInquiry(id: string): Promise<PricingInquiryModel> {
        let promise: Promise<PricingInquiryModel>;
        promise = new Promise<PricingInquiryModel>((resolve, reject) => {
            this.pricingInquiryService.get(id)
                .then(pricing_inquiry => {
                    this.pricing_inquiry = pricing_inquiry;
                    resolve(this.pricing_inquiry);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private task_purchase_order_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {
                action: 'clone_purchase_order',
                id: this.purchase_order.task_id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onCustomerSelect(data: any): void {
        //
    }

    public onVendorRadioChange(event: any, item: any, num: number): void {
        if (num === 1) {
            item.price = item.price1;
        } else if (num === 2) {
            item.price = item.price2;
        } else if (num === 3) {
            item.price = item.price3;
        }
    }

    public onStartUploader(data: any): void {
        //
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('pricing/inquiries/document', 'PUT', {}, {
            document: data,
            id: this.pricing_inquiry.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                if (!this.pricing_inquiry.documents) {
                    this.pricing_inquiry.documents = [];
                }
                this.pricing_inquiry.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    public addRemark(purchase: PurchaseModel): void {
        this.modal.show(RemarkModalComponent, {
            description: purchase.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                purchase.description = content.description;
                this._save();
            } else {
                //
            }
        });
    }

    public save(): void {
        if (this.task) {
            this._save()
                .then(() => {
                    this.swal.success('บันทึกข้อมูลสำเร็จ');
                });
        }
    }

    private get_item_ids(): any[] {
        let item_ids: any[];
        item_ids = [];
        for (let i = 0; i < this.items.length; i++) {
            const item: any = this.items[i];
            if (item && item.modelable && item.modelable_id) {
                item_ids.push({
                    // purchase_id     : (item.id) ? item.id : '',
                    id              : item.id,
                    modelable_id    : item.modelable_id,
                    modelable_type  : item.modelable_type,
                    dateTime        : item.dateTime,
                    amount          : item.amount,
                    description     : item.description,
                    price1          : item.price1,
                    price2          : item.price2,
                    price3          : item.price3,
                    prepared_status : 1
                });
            }
        }
        return item_ids;
    }

    private _save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.pricing_inquiry.item_ids = this.get_item_ids();
            this.modelApi.update(this.pricing_inquiry, [
                'vendor1_id', 'vendor2_id', 'vendor3_id', 'item_ids', 'request_man_id',
                'place_name', 'customer_id', 'objective', 'purchase_user_id', 'quotation_ref'
            ], null, null, null, false)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public approveTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.approveTask()
                    .then(() => {
                        this.swal.success('ผ่านการอนุมัติสำเร็จ');
                        this.leave(true, true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public rejectTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true, true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

}
