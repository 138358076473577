import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {DocumentModel} from '../../models/document.model';
import {Api} from '../../now/api/api';
import {UploaderComponent} from '../uploader/uploader.component';
import {SwalService} from '../../services/swal.service';
import {Viewer} from '../../services/viewer';
import {CreditNote} from '../../app/api/creditNote';
import {CreditNoteDetailComponent} from '../../pages/task/view/creditNoteDetail/creditNoteDetail.component';

@Component({
    selector: 'credit-note-table-component',
    templateUrl: 'creditNoteTable.component.html',
    styleUrls: ['creditNoteTable.component.scss']
})
export class CreditNoteTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() creditNotes: CreditNote[];
    @Input() model: any;
    @Input() task: TaskModel;

    public current_creditNote: CreditNote;

    constructor(
        public viewer: Viewer,
        private modal: ModalService,
        private swal: SwalService,
        private api: Api
    ) {
        //
        this.creditNotes = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewCreditNoteDetail(creditNote: CreditNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(CreditNoteDetailComponent, {
            creditNote   : creditNote,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.creditNote) {
                    creditNote.status = content.creditNote.status;
                }
            });
    }

    public viewCreditNotePreview(creditNote: CreditNote): void {
        this.viewer.creditNote(creditNote);
    }

    public createCreditNote(): void {
        let creditNote: CreditNote;
        creditNote = {
            material_price: this.model.material_price,
            discount_type: this.model.discount_type,
            discount_percent: this.model.discount_percent,
            discount_price: this.model.discount_price,
            is_include_vat: this.model.is_include_vat,
            frt_sh: this.model.frt_sh,
            misc_chgs: this.model.misc_chgs,
            grand_total: this.model.grand_total,
        };
        this.modal.show(CreditNoteDetailComponent, {
            creditNote     : creditNote,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.is_create === true) {
                        creditNote = Object.assign({}, content.creditNote);
                        this.model.credit_notes.push(creditNote);
                        this.viewCreditNotePreview(creditNote);
                    }
                } else {
                    //
                }
            });
    }

    public attachFile(creditNote: CreditNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_creditNote = creditNote;
        this.uploader.trigger();
    }

    public viewFile(creditNote: CreditNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(creditNote.document_id);
    }

    public removeFile(creditNote: CreditNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('credit/notes/document/delete', 'POST', {}, {
                        id: creditNote.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        creditNote.document_id = null;
                        creditNote.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_creditNote && this.current_creditNote.id) {
            this.api.request('credit/notes/document', 'PUT', {}, {
                document: data,
                id: this.current_creditNote.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_creditNote.document_id = new_document.id;
                    this.current_creditNote.document = new_document;
                    this.viewFile(this.current_creditNote);
                }
                this.current_creditNote = null;
            }, error => {
                this.current_creditNote = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
