import {Model} from '../now/model';

export class CustomerAddressModel extends Model {

    public name: string;
    public customer_id: string;
    public note: string;
    public incoterm_code: string;
    public ranking: number;
    public tax_no: string;
    public branch: string;
    public telephone: string;
    public telephone2: string;
    public fax: string;
    public email: string;
    public email2: string;
    public website: string;
    public address: string;
    public city: string;
    public state: string;
    public country: string;
    public postal_code: string;

    constructor() {
        super('customer/addresses');
    }

    public get full_address(): string {
        let address: string;
        let city: string;
        let state: string;
        let postal_code: string;
        let country: string;

        address     = (this.address) ? this.address : '';
        city        = (this.city) ? this.city : '';
        state       = (this.state) ? this.state : '';
        postal_code = (this.postal_code) ? this.postal_code : '';
        country     = (this.country) ? this.country : '';

        return address + ' ' + city + ' ' + state + ' ' + postal_code + ' ' + country;
    }

}
