import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {ShippingModel} from '../../../../models/shipping.model';
import {Location} from '@angular/common';

@Component({
    selector: 'shipping-view-component',
    templateUrl: 'shippingView.component.html',
    styleUrls: ['shippingView.component.scss']
})
export class ShippingViewComponent implements OnInit {

    public shipping: ShippingModel;
    public shipping_id: string;
    public current_tab: string;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private location: Location,
                private appService: AppService,
                private modelApi: ModelApi,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.shipping = new ShippingModel();
        this.route.params
            .subscribe(params => {
                this.shipping_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getShipping()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getShipping(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.shipping_id) {
                this.api.request('shippinges/' + this.shipping_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.shipping.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.shipping);
            }
        });
        return promise;
    }

    public save(): void {
        if (!this.shipping.name) {
            this.swal.danger('กรุณากรอกชื่อการขนส่ง');
        } else {
            this.modelApi.createOrUpdate(this.shipping, [
                'name', 'slug'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/shipping/' + this.shipping.id + '/detail');
                this.swal.success('บันทึกข้อมูลการขนส่งสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createShipping(): void {
        this.save();
    }

}
