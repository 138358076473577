import {Model} from '../now/model';
import {WarehouseModel} from './warehouse.model';
import {ShelfModel} from './shelf.model';
import {VendorModel} from './vendor.model';
import {FormModel} from '../now/formModel';
import {DocumentModel} from './document.model';

export class ItemModel extends FormModel {

    public state: string;
    public description: string;
    public modelable: any;
    public ref_no: string;
    public price: number;
    public type: string;
    public ref_item_no: string;
    public item_no: string;
    public name: string;
    public name_en: string;
    public amount: number;
    public reserved_amount: number;
    public unit: string;
    public width: number;
    public height: number;
    public length: number;
    public weight: number;
    public status: number;
    public thickness: number;
    public outer_diameter: number;
    public inner_diameter: number;
    public material: string;
    public material_type: string;
    public grade: string;
    public surface: string;
    public modelable_type: string;
    public modelable_id: string;
    public lower_amount: number;
    public cost: string;
    public remark: string;

    public size: string;
    public tooth: string;

    public vendor_id: string;
    public vendor: VendorModel;

    public search_value: string;

    public warehouse_ids: any[];

    public documents: DocumentModel[];
    public data: any;

    // public warehouse_id: string;
    // public shelf_id: string;
    public warehouses: WarehouseModel[];
    public shelves: ShelfModel[];

    public pivot_amount: number;

    constructor() {
        super('items', 'App\\Item');
        // this.warehouse = new WarehouseModel();
        // this.shelf = new ShelfModel();
        this.warehouses = [];
        this.shelves = [];
        this.modelable = {};
        this.documents = [];
        this.vendor = new VendorModel();
    }

}
