import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CALENDAR_MAX_TIME, CALENDAR_MIN_TIME} from '../../consts/calendar.const';
import {SchedulerModel} from '../../models/scheduler.model';
import {ProductProcessModel} from '../../models/productProcess.model';

import * as moment from 'moment';

import {environment} from '../../environments/environment';
import {ModalService} from '../../services/modal.service';

declare var $: any;

@Component({
    selector: 'full-calendar-component',
    templateUrl: 'fullCalendar.component.html',
    styleUrls: ['fullCalendar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FullCalendarComponent),
        }
    ]
})
export class FullCalendarComponent implements ControlValueAccessor, OnInit, AfterViewInit {

    @ViewChild('fullCalendarElement', { static: true }) fullCalendarElement: any;

    @Output() onChangedDate: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() onCreateSchduler: EventEmitter<SchedulerModel> = new EventEmitter<SchedulerModel>();
    @Output() onChangeView: EventEmitter<any> = new EventEmitter<any>();
    @Output() dayClickHandler: EventEmitter<any> = new EventEmitter<any>();
    @Output() eventClickHandler: EventEmitter<any> = new EventEmitter<any>();

    @Input() filter: any;
    @Input() trash: any;
    @Input('options') _options: any;

    @Input('date') set date(value: any) {
        this.current_date = value;
        this.gotoDate(this.current_date, false);
    }
    @Output('date') onChangeDate: EventEmitter<any> = new EventEmitter<any>();
    @Input() set busy(value: any) { this.busyDates = value; }

    public filter_type: string;
    public match_full: boolean;
    public busyDates: any;
    public options: any;
    public fullcalendar: any;
    public current_date: any;
    public is_emit: boolean;

    private schedulers: SchedulerModel[];
    // private the_model: any[];
    private propagateChange: any = (_: any): void => {};

    constructor(private modal: ModalService) {
        this.schedulers = [];
        this.filter = [];
        this.match_full = true;
        this.is_emit = true;
    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const fullCalendarComponent = this;
            this.options = Object.assign({
                schedulerLicenseKey: environment.schedulerLicenseKey,
                resourceLabelText: 'รายการ',
                resourceAreaWidth: 200,
                editable: false,
                eventOrder: 'index',
                slotLabelFormat: 'HH(:mm)',
                resourceColumns: [
                    {
                        labelText: 'Job',
                        field: 'job_no'
                    },
                    {
                        labelText: 'Man',
                        field: 'man_title',
                    },
                    {
                        labelText: 'Machine',
                        field: 'machine_title',
                    }
                ],
                eventOverlap: true,
                minTime: CALENDAR_MIN_TIME,
                maxTime: CALENDAR_MAX_TIME,
                dragRevertDuration: 0,
                forceEventDuration: true,
                views: {
                    timelineDay: {
                        type: 'timeline',
                        slotDuration: '00:10:00'
                    }
                },
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    // right: 'timelineDay,month'
                },
                buttonText: {
                    today: 'วันนี้',
                    month: 'เดือน',
                    week: 'สัปดาห์',
                    // day: 'วัน'
                },
                displayEventEnd: true,
                timeFormat: 'HH:mm',
                locale: 'th',
                viewRender: e => {
                    this.is_emit = false;
                    this.current_date = this.fullcalendar.fullCalendar('getDate').format('YYYY-MM-DD HH:mm:ss');
                    this.onChangeView.emit(this.current_date);
                    // .fullCalendar( ‘getDate’ )
                },
                // eventRender: function(event, element, view) {
                //     let found: boolean;
                //     found = false;
                //     if (view.name === 'month' || view.name === 'timelineDay') {
                //         if (view.name === 'month' && event.calendar_title) {
                //             event.title = event.calendar_title;
                //             found = true;
                //         } else if (view.name === 'timelineDay' && event.timeline_title) {
                //             event.title = event.timeline_title;
                //             found = true;
                //         }
                //         if ((Array.isArray(fullCalendarComponent.filter) && fullCalendarComponent.filter.length)
                //             || (!Array.isArray(fullCalendarComponent.filter) && fullCalendarComponent.filter)) {
                //             event.title = event.filter_title;
                //             found = true;
                //         } else if (view.name === 'timelineDay'
                //             && ((Array.isArray(fullCalendarComponent.filter) && !fullCalendarComponent.filter.length)
                //             || (!Array.isArray(fullCalendarComponent.filter) && !fullCalendarComponent.filter))) {
                //             event.title = event.filter_title;
                //             found = true;
                //         }
                //         if (found === true) {
                //             element.find('.fc-title').html(event.title);
                //         }
                //     }
                //     if (!fullCalendarComponent.filter || fullCalendarComponent.filter === '' ||
                //         (Array.isArray(fullCalendarComponent.filter) && fullCalendarComponent.filter.length === 0)) {
                //         if (!fullCalendarComponent.filter_type
                //             || (!Array.isArray(fullCalendarComponent.filter_type) && fullCalendarComponent.filter_type === event.type)) {
                //             return true;
                //         } else {
                //             return false;
                //         }
                //     } else if (Array.isArray(fullCalendarComponent.filter)) {
                //         for (let i = 0; i < event.filter_value.length; i++) {
                //             const filter_value: string = event.filter_value[i];
                //             if (filter_value) {
                //                 if (fullCalendarComponent.match_full === true) {
                //                     const index: number = fullCalendarComponent.filter.indexOf(filter_value);
                //                     if (index > -1) {
                //                         if (!fullCalendarComponent.filter_type
                //                             || (fullCalendarComponent.filter_type && fullCalendarComponent.filter_type === event.type)) {
                //                             return true;
                //                         } else {
                //                             return false;
                //                         }
                //                     }
                //                 } else {
                //                     for (let j = 0; j < fullCalendarComponent.filter.length; j++) {
                //                         if (filter_value.search(fullCalendarComponent.filter[j]) > -1) {
                //                             if (!fullCalendarComponent.filter_type
                //                                 || (fullCalendarComponent.filter_type && fullCalendarComponent.filter_type === event.type)) {
                //                                 return true;
                //                             } else {
                //                                 return false;
                //                             }
                //                         }
                //                     }
                //                 }
                //             }
                //         }
                //     } else if (typeof fullCalendarComponent.filter === 'string') {
                //         if (fullCalendarComponent.match_full === true && event && event.filter_value) {
                //             const index: number = event.filter_value.findIndex(i => i === fullCalendarComponent.filter);
                //             if (index > -1) {
                //                 if (!fullCalendarComponent.filter_type
                //                     || (fullCalendarComponent.filter_type && fullCalendarComponent.filter_type === event.type)) {
                //                     return true;
                //                 } else {
                //                     return false;
                //                 }
                //             }
                //         } else if (event && event.filter_value) {
                //             for (let j = 0; j < event.filter_value.length; j++) {
                //                 const filter_value: string = event.filter_value[j];
                //                 if (filter_value && filter_value.search(fullCalendarComponent.filter) > -1) {
                //                     if (!fullCalendarComponent.filter_type
                //                         || (fullCalendarComponent.filter_type && fullCalendarComponent.filter_type === event.type)) {
                //                         return true;
                //                     } else {
                //                         return false;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                //     return false;
                // },
                eventClick: function(calEvent, jsEvent, view) {
                    if (fullCalendarComponent.eventClickHandler) {
                        fullCalendarComponent.eventClickHandler.emit({
                            calEvent    : calEvent,
                            jsEvent     : jsEvent,
                            view        : view
                        });
                    }
                },
                dayClick: function(date) {
                    if (fullCalendarComponent.dayClickHandler) {
                        fullCalendarComponent.dayClickHandler.emit(date);
                    }
                }
            }, this._options);
            //
            this.fullcalendar = $('#fullcalendar');
            this.fullcalendar.fullCalendar(this.options);
            if (this.schedulers && this.schedulers.length > 0) {
                this.fullcalendar.fullCalendar('addEventSource', this.schedulers);
                this.fullcalendar.fullCalendar('rerenderEvents');
            }
        }, 0);
    }

    public doFilter(filter: string, match_full?: boolean): void {
        this.match_full = (match_full === false) ? false : true;
        this.rerenderEvents();
    }

    public gotoDate(dateTime: string, emit?: boolean): void {
        if (dateTime && this.fullcalendar && this.fullcalendar.fullCalendar) {
            // const _: string = dateTime.split(' ')[0];
            this.is_emit = (emit) ? true : false;
            this.fullcalendar.fullCalendar('gotoDate', dateTime);
        } else {
            console.warn('dont have any datetime');
        }
    }

    public changeResourceColumns(cols: any): void {
        if (cols) {
            this.fullcalendar.fullCalendar('option', {
                resourceColumns: cols,
                resourceAreaWidth: (cols && cols.length) ? (12 * cols.length) + '%' : '12%'
            });
        } else {
            //
        }
    }

    public changeResources(resources: any[]): void {
        this.fullcalendar.fullCalendar('option', {
            resources: resources
        });
    }

    public is_overlap(start1: any, start2: any, end1?: any, end2?: any): boolean {
        if (start1 && start2 && moment.isMoment(start1) && moment.isMoment(start2)) {
            const _start1: number = +start1.format('YYYYMMDDHHmm');
            const _end1: number = (end1) ? +end1.format('YYYYMMDDHHmm') : +start1.add(1, 'hours').format('YYYYMMDDHHmm');
            const _start2: number = +start2.format('YYYYMMDDHHmm');
            const _end2: number = (end2) ? +end2.format('YYYYMMDDHHmm') : +start2.add(1, 'hours').format('YYYYMMDDHHmm');
            if (Math.max(_start1, _start2) < Math.min(_end1, _end2)) {
                return true;
            }
        }
        return false;
    }

    public toEvent(data: any, calEvent?: any): any {
        let event: any;
        if (calEvent) {
            event = calEvent;
        } else {
            event = {};
        }
        event['model_id'] = data.model_id;
        event['resourceId'] = data.resourceId;
        event['index'] = 1;
        event['scheduler'] = data;
        event['guid'] = data.guid;
        event['start'] = (data.started_at) ? data.started_at : data.start;
        event['end'] = (data.ended_at) ? data.ended_at : data.end;
        event['process_id'] = (data.process) ? data.process.id : (event.process) ? event.process.id : null;
        event['title'] = (data.process) ? data.process.name : (event.process) ? event.process.name : null;
        event['user_full_name'] = (data.user) ? data.user.full_name : (event.user) ? event.user.full_name : null;
        event['user_id'] = (data.user) ? data.user.id : (event.user) ? event.user.id : null;
        event['machine_id'] = (data.machine) ? data.machine.id : (event.machine) ? event.machine.id : null;
        event['user_time'] = (data.user_time > 0) ? data.user_time : null;
        event['machine_name'] = (data.machine) ? data.machine.machine_name : null;
        event['machine_time'] = (data.machine_time > 0) ? data.machine_time : null;
        // event['color'] = data.color;
        event['ranking'] = data.ranking;
        event['minutes'] = data.minutes;
        event['displayEventTime'] = true;
        event['overlap'] = true;
        event['checked'] = true;
        //
        return event;
    }

    /*private showCalendarModal(calEvent: any, jsEvent?: any, view?: any): void {
        let modalContent: BsModalRef;
        modalContent = this.modalService.show(CalendarViewComponent, { backdrop: true, ignoreBackdropClick: true });
        modalContent.content.product_process_id = calEvent.product_process_id;
        modalContent.content.product_process = calEvent.product_process;
        modalContent.content.calEvent = calEvent;

        this.modalSubscription = this.modalService.onHide
            .subscribe(() => {
                if (modalContent && modalContent.content && modalContent.content.product_process_id) {
                    this.calendar_render(modalContent.content.calEvent);
                } else {
                    //
                }
                this.modalSubscription.unsubscribe();
            });
    }*/

    private createScheduler(date: any, product_process: ProductProcessModel, guid?: string): SchedulerModel {
        let scheduler: SchedulerModel;
        let started_at: any;
        scheduler = new SchedulerModel();
        started_at = date;
        if (started_at && started_at.format('00:00:00')) {
            started_at = started_at.format('YYYY-MM-DD') + ' ' + CALENDAR_MIN_TIME;
        }
        scheduler.started_at = moment(started_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        scheduler.ended_at = moment(started_at, 'YYYY-MM-DD HH:mm:ss').add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
        scheduler.product_process_id = product_process.id;
        scheduler.model_id = product_process.model_id;
        scheduler.model_name = product_process.model_name;
        scheduler.model = product_process.model;
        scheduler.guid = guid;

        // scheduler.product_process = product_process;
        product_process.addScheduler(scheduler);

        this.addScheduler(scheduler);
        this.onCreateSchduler.emit(scheduler);

        return scheduler;
    }

    private addScheduler(scheduler: SchedulerModel): void {
        if ((this.schedulers && !Array.isArray(this.schedulers)) || !this.schedulers) {
            this.schedulers = [];
        }
        this.schedulers.push(scheduler);
        this.propagateChange(this.schedulers);
    }

    private calendar_update_event(event: any): void {
        this.fullcalendar.fullCalendar('updateEvent', event);
    }

    public refetchResources(): void {
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar('refetchResources');
        }
    }

    public addResource(resource: any): void {
        this.fullcalendar.fullCalendar('addResource', resource);
    }

    public removeEvents(): void {
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar('removeEvents');
        }
    }

    public addEventSource(events: any[]): void {
        this.schedulers = events;
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar('addEventSource', this.schedulers);
        } else {
            //
        }
    }

    public render(): void {
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar('render');
        }
    }

    public rerenderEvents(): void {
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar('rerenderEvents');
        }
    }

    public disable(): void {
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar({editable: false});
        }
    }

    public enable(): void {
        if (this.fullcalendar) {
            this.fullcalendar.fullCalendar({editable: true});
        }
    }

    public get events(): any[] {
        if (this.fullcalendar) {
            return this.fullcalendar.fullCalendar('clientEvents');
        }
        return [];
    }

    writeValue(obj: any): void {
        if (obj) {
            // this.the_model = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

    public getView(): string {
        return this.fullcalendar.fullCalendar('getView');
    }

}
