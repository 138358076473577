import {AfterViewInit, Component, Input} from '@angular/core';
import {TaskModel} from '../../models/task.model';
import {SchedulerModel} from '../../models/scheduler.model';
import {SchedulerHistoryModel} from '../../models/schedulerHistory.model';
import {Api} from '../../now/api/api';

@Component({
    selector: 'task-history-component',
    templateUrl: 'taskHistory.component.html',
    styleUrls: ['taskHistory.component.scss']
})
export class TaskHistoryComponent implements AfterViewInit {

    @Input() task: TaskModel;
    @Input() schedulers: SchedulerModel[];
    @Input() schedulerHistories: SchedulerHistoryModel[];

    public histories: any[];

    constructor(
        private api: Api
    ) {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getTaskHistories()
                .then(() => {
                    //
                });
        });
    }

    public getTaskHistories(): Promise<any[]> {
        this.histories = [];
        const promise = new Promise<any[]>(resolve => {
            this.api.request('tasks/' + this.task.id + '/histories', 'GET', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.histories = ([]).concat(response.data);
                    } else {
                        console.error(response);
                        resolve([]);
                    }
                }, error => {
                    console.error(error);
                    resolve([]);
                });
        });
        return promise;
    }

}
