import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DocumentModel} from '../../../../models/document.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {TaskModel} from '../../../../models/task.model';
import {SwalService} from '../../../../services/swal.service';
import {UserModel} from '../../../../now/user/user.model';
import {ProductModel} from '../../../../models/product.model';
import {JobModel} from '../../../../models/job.model';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-document-detail-component',
    templateUrl: 'documentDetail.component.html',
    styleUrls: ['documentDetail.component.scss']
})
export class DocumentDetailComponent implements OnInit {

    public tmp_document: DocumentModel;
    public current_document: DocumentModel;
    public task: TaskModel;
    public user: UserModel;
    public document_index: number;
    public product: ProductModel;
    public job: JobModel;
    public deletable: boolean;

    constructor(public bsModalRef: BsModalRef, private modelApi: ModelApi, private swal: SwalService) {
        this.tmp_document = new DocumentModel();
        this.deletable = false;
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document_index > -1) {
                this.document_index = this.bsModalRef.content.document_index;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document) {
                this.tmp_document.clone(this.bsModalRef.content.document);
                this.current_document = this.bsModalRef.content.document;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.user) {
                this.user = this.bsModalRef.content.user;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
              this.job = this.bsModalRef.content.job;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.deletable) {
                this.deletable = (this.bsModalRef.content.deletable) ? true : false;
            } else {
                this.deletable = false;
            }
        }, 0);
    }

    public delete(): void {
        this.swal.confirm('คุณต้องการลบเอกสาร "' + this.tmp_document.name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    if (this.task) {
                        this.modelApi.delete(this.tmp_document)
                            .subscribe((response: any): void => {
                                if (this.task) {
                                    this.task.deleteDocument(this.tmp_document);
                                }
                                if (this.product && this.document_index > -1) {
                                    this.product.documents.splice(this.document_index, 1);
                                }
                                this.bsModalRef.content.delete = this.tmp_document;
                                this.bsModalRef.hide();
                            }, error => {
                                //
                            });
                    } else if (this.user) {
                        this.modelApi.delete(this.tmp_document, 'users/document')
                            .subscribe((response: any): void => {
                                if (this.user) {
                                    this.user.deleteDocument(this.tmp_document);
                                }
                                if (this.product && this.document_index > -1) {
                                    this.product.documents.splice(this.document_index, 1);
                                }
                                this.bsModalRef.content.delete = this.tmp_document;
                                this.bsModalRef.hide();
                            }, error => {
                                //
                            });
                    } else if (this.job) {
                        this.modelApi.delete(this.tmp_document, 'jobs/document/delete')
                            .subscribe((response: any): void => {
                                this.bsModalRef.content.submit = true;
                                this.bsModalRef.hide();
                            }, error => {
                                //
                            });
                    } else {
                        this.bsModalRef.content.deleted = true;
                        this.bsModalRef.hide();
                    }
                } else {
                    //
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            if (this.task) {
                this.modelApi.update(this.tmp_document, ['name'])
                    .subscribe((response: any): void => {
                        if (this.current_document) {
                            this.current_document.clone(this.tmp_document);
                        }
                        // this.bsModalRef.content.document = this.current_document;
                        this.bsModalRef.content.is_submit = true;
                        this.bsModalRef.hide();
                    }, error => {
                        //
                    });
            } else if (this.user) {
                this.modelApi.update(this.tmp_document, ['name'], 'users/document')
                    .subscribe((response: any): void => {
                        // this.bsModalRef.content.document.clone(this.tmp_document);
                        if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document) {
                            this.bsModalRef.content.document.name = this.tmp_document.name;
                        }
                        // this.bsModalRef.content.document = this.current_document;
                        this.bsModalRef.content.is_submit = true;
                        this.bsModalRef.hide();
                    }, error => {
                        //
                    });
            } else if (this.job) {
                this.modelApi.update(this.tmp_document, ['name'], 'jobs/document')
                    .subscribe((response: any): void => {
                        if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document) {
                            this.bsModalRef.content.document.name = this.tmp_document.name;
                        }
                        // this.bsModalRef.content.document = this.current_document;
                        this.bsModalRef.content.is_submit = true;
                        this.bsModalRef.hide();
                    });
            } else {
                this.modelApi.update(this.tmp_document, ['name'], 'documents')
                    .subscribe((response: any): void => {
                        if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document) {
                            this.bsModalRef.content.document.name = this.tmp_document.name;
                        }
                        this.bsModalRef.content.is_submit = true;
                        this.bsModalRef.hide();
                    });
            }
        } else {
            //
        }
    }

}
