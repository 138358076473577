import {Routes} from '@angular/router';
import {DashboardComponent} from '../pages/dashboard/dashboard.component';
import {AuthComponent} from '../pages/auth/auth.component';
import {TaskComponent} from '../pages/task/task.component';
import {ProductTaskComponent} from '../pages/task/view/product/productTask.component';
import {HomeComponent} from '../pages/home/home.component';
import {DatabaseComponent} from '../pages/database/database.component';
import {MachineListComponent} from '../pages/database/machine/machineList.component';
import {DatabaseNavComponent} from '../pages/database/nav/databaseNav.component';
import {MachineViewComponent} from '../pages/database/machine/view/machineView.component';
import {CustomerListComponent} from '../pages/database/customer/customerList.component';
import {CustomerViewComponent} from '../pages/database/customer/view/customerView.component';
import {InvoiceListComponent} from '../pages/database/invoice/invoiceList.component';
import {QuotationListComponent} from '../pages/database/quotation/quotationList.component';
import {PurchaseOrderListComponent} from '../pages/database/purchaseOrder/purchaseOrderList.component';
import {VendorListComponent} from '../pages/database/vendor/vendorList.component';
import {VendorViewComponent} from '../pages/database/vendor/view/vendorView.component';
import {UserListComponent} from '../pages/database/user/userList.component';
import {UserViewComponent} from '../pages/database/user/view/userView.component';
import {HardeningListComponent} from '../pages/database/hardening/hardeningList.component';
import {HardeningViewComponent} from '../pages/database/hardening/view/hardeningView.component';
import {CriticalPointListComponent} from '../pages/database/criticalPoint/criticalPointList.component';
import {CriticalPointViewComponent} from '../pages/database/criticalPoint/view/criticalPointView.component';
import {PackagingListComponent} from '../pages/database/packaging/packagingList.component';
import {PackagingViewComponent} from '../pages/database/packaging/view/packagingView.component';
import {ProcessViewComponent} from '../pages/database/process/view/processView.component';
import {ProcessListComponent} from '../pages/database/process/processList.component';
import {ShippingListComponent} from '../pages/database/shipping/shippingList.component';
import {TaskListComponent} from '../pages/task/list/taskList.component';
import {RootNavComponent} from '../pages/rootNav/rootNav.component';
import {QuotationTaskComponent} from '../pages/task/quotation/quotationTask.component';
import {PlanningTaskComponent} from '../pages/task/planning/planningTask.component';
import {CalendarModelViewComponent} from '../pages/calendarModel/calendarModelView.component';
import {PageErrorComponent} from '../pages/pageError/pageError.component';
import {ProdTaskComponent} from '../pages/task/prod/prodTask.component';
import {RequirementTaskComponent} from '../pages/task/requirement/requirementTask.component';
import {PurchaseTaskComponent} from '../pages/task/purchase/purchaseTask.component';
import {RequestTaskComponent} from '../pages/request/requestTask.component';
import {RequestEngTaskComponent} from '../pages/request/eng/requestEngTask.component';
import {RequestFMTTaskComponent} from '../pages/request/fmt/requestFMTTask.component';
import {RequestStoreTaskComponent} from '../pages/request/store/requestStoreTask.component';
import {RequestCRDTaskComponent} from '../pages/request/crd/requestCRDTask.component';
import {DeliveryTaskComponent} from '../pages/task/delivery/deliveryTask.component';
import {NCRTaskComponent} from '../pages/task/ncr/ncrTask.component';
import {PrepareTaskComponent} from '../pages/task/prepare/prepareTaskComponent';
import {PrepareStoreTaskComponent} from '../pages/task/prepareStore/prepareStoreTask.component';
import {TaxInvoiceTaskComponent} from '../pages/task/taxInvoice/taxInvoiceTask.component';
import {CarrierListComponent} from '../pages/database/carrier/carrierList.component';
import {CarrierViewComponent} from '../pages/database/carrier/view/carrierView.component';
import {ShippingViewComponent} from '../pages/database/shipping/view/shippingView.component';
import {IncotermListComponent} from '../pages/database/incoterm/incotermList.component';
import {IncotermViewComponent} from '../pages/database/incoterm/view/incotermView.component';
import {AccountingTaskComponent} from '../pages/task/accounting/accountingTask.component';
import {StoreComponent} from '../pages/store/store.component';
import {StoreTypeComponent} from '../pages/store/storeType/storeType.component';
import {RejectedTaskComponent} from '../pages/task/rejected/rejectedTask.component';
import {PurchaseOrderTaskComponent} from '../pages/task/purchaseOrder/purchaseOrderTask.component';
import {CosmeticListComponent} from '../pages/database/cosmetic/cosmeticList.component';
import {CosmeticViewComponent} from '../pages/database/cosmetic/view/cosmeticView.component';
import {FVDTaskComponent} from '../pages/task/fvd/fvdTask.component';
import {RequestFVDTaskComponent} from '../pages/task/fvd/request/requestFVDTask.component';
import {ReceiptTaskComponent} from '../pages/task/receipt/receiptTask.component';
import {PackingSlipTaskComponent} from '../pages/task/packingSlip/packingSlipTask.component';
import {InvoiceTaskComponent} from '../pages/task/invoice/invoiceTask.component';
import {DepositTaskComponent} from '../pages/task/deposit/depositTask.component';
import {FinishTaskComponent} from '../pages/task/finish/finishTask.component';
import {PaymentTaskComponent} from '../pages/task/payment/paymentTask.component';
import {SupplierListComponent} from '../pages/database/supplier/supplierList.component';
import {SupplierViewComponent} from '../pages/database/supplier/view/supplierView.component';
import {POFVDTaskComponent} from '../pages/task/poFVD/poFVDTask.component';
import {NoticeTaskComponent} from '../pages/task/notice/noticeTask.component';
import {NoticeShippingTaskComponent} from '../pages/task/noticeShipping/noticeShippingTask.component';
import {JobListComponent} from '../pages/database/job/jobList.component';
import {JobDetailTaskComponent} from '../pages/task/jobDetail/jobDetailTask.component';
import {NCRListComponent} from '../pages/database/ncr/ncrList.component';
import {RequestTaskListComponent} from '../pages/task/request/requestTaskList.component';
import {RequisitionStoreComponent} from '../pages/task/request/requisition/requisitionStore.component';
import {PreparedStoreComponent} from '../pages/task/request/prepared/preparedStore.component';
import {ReturnStoreTaskListComponent} from '../pages/task/request/returnStore/returnStoreTaskList.component';
import {ReturnStoreComponent} from '../pages/task/request/return/returnStore.component';
import {RequestPurchaseTaskComponent} from '../pages/request/purchase/requestPurchaseTask.component';
import {RequestPOTaskListComponent} from '../pages/task/request/po/requestPOTaskList.component';
import {PurchaseOrderStoreComponent} from '../pages/task/request/purchaseOrderStore/purchaseOrderStore.component';
import {TrialTaskComponent} from '../pages/task/trial/trialTask.component';
import {ArchiveTaskComponent} from '../pages/task/archive/archiveTask.component';
import {ReportPage} from '../pages/report/report.page';
import {ContactLogListComponent} from '../pages/database/contactLog/contactLogList.component';
import {FVDListComponent} from '../pages/database/fvd/fvdList.component';
import {CalendarAllModelViewComponent} from '../pages/calendarModel/all/calendarAllModelView.component';
import {Request2TaskListComponent} from '../pages/task/request/purchase/request2TaskList.component';
import {Purchase2TaskComponent} from '../pages/task/purchase2/purchase2Task.component';
import {ProdReportPage} from '../pages/report/prod/prodReport.page';
import {SaleReportPage} from '../pages/report/sale/saleReport.page';
import {ProductListComponent} from '../pages/database/product/productList.component';
import {TaxInvoiceListComponent} from '../pages/database/taxInvoice/taxInvoiceList.component';
import {PlanningReportPage} from '../pages/report/planning/planningReport.page';
import {ControlReportPage} from '../pages/report/control/controlReport.page';
import {StoreReportPage} from '../pages/report/store/storeReport.page';
import {OverallReportPage} from '../pages/report/overall/overallReport.page';
import {FMTTaskComponent} from '../pages/task/fmt/FMTTask.component';
import {RequestTaskFMTListComponent} from '../pages/task/request/fmt/requestTaskFMTList.component';
import {TaskProgressFMTListComponent} from '../pages/task/request/fmt/progress/taskProgressFMTList.component';
import {TaskCompleteFMTListComponent} from '../pages/task/request/fmt/complete/taskCompleteFMTList.component';
import {FMTListComponent} from '../pages/database/fmt/fmtList.component';
import {PO2TaskListComponent} from '../pages/task/request/po2/po2TaskList.component';
import {ProformaListComponent} from '../pages/database/proforma/proformaList.component';
import {TaskApplyFMTListComponent} from '../pages/task/request/fmt/apply/taskApplyFMTList.component';
import {HRTaskComponent} from '../pages/task/hr/HRTask.component';
import {RequestTaskHRListComponent} from '../pages/task/request/hr/requestTaskHRList.component';
import {HRReportPage} from '../pages/report/hr/hrReport.page';
import {CustomerTaskListComponent} from '../pages/task/list/receive/customer/customerTaskList.component';
import {SaleOrderTaskComponent} from '../saleAdmin/saleOrderTask/saleOrderTask.component';
import {DeliveryNoteListComponent} from '../pages/database/deliveryNote/deliveryNoteList.component';
import {PackingSlipListComponent} from '../pages/database/packingSlip/packingSlipList.component';
import {ManufactureListComponent} from '../pages/database/manufacture/manufactureList.component';
import {SaleOrderListComponent} from '../pages/database/saleOrder/saleOrderList.component';

export const appRoutes: Routes = [
    {
        path            : 'auth',
        component       : AuthComponent
    },
    {
        path                : '',
        component           : RootNavComponent,
        // canActivateChild    : [AuthGuard],
        children    : [
            {
                path        : 'dashboard',
                component   : DashboardComponent
            },
            {
                path        : 'store',
                component   : StoreComponent
            },
            {
                path        : 'store/view/:type',
                component   : StoreTypeComponent
            },
            {
                path        : 'report',
                component   : ReportPage
            },
            {
                path        : 'report/overall',
                component   : OverallReportPage
            },
            {
                path        : 'report/prod',
                component   : ProdReportPage,
            },
            {
                path        : 'report/store',
                component   : StoreReportPage,
            },
            {
                path        : 'report/sale',
                component   : SaleReportPage,
            },
            {
                path        : 'report/planning',
                component   : PlanningReportPage
            },
            {
                path        : 'report/control',
                component   : ControlReportPage,
            },
            {
                path        : 'report/hr',
                component   : HRReportPage,
            },
            {
                path        : 'database',
                component   : DatabaseComponent,
                children    : [
                    {
                        path        : '',
                        component   : DatabaseNavComponent
                    },
                    {
                        path        : 'sale_order',
                        component   : SaleOrderListComponent
                    },
                    {
                        path        : 'fmt',
                        component   : FMTListComponent
                    },
                    {
                        path        : 'quotation',
                        component   : QuotationListComponent
                    },
                    {
                        path        : 'proforma',
                        component   : ProformaListComponent
                    },
                    {
                        path        : 'contact/log',
                        component   : ContactLogListComponent
                    },
                    {
                        path        : 'product',
                        component   : ProductListComponent
                    },
                    {
                        path        : 'carrier',
                        component   : CarrierListComponent
                    },
                    {
                        path        : 'carrier/create',
                        component   : CarrierViewComponent
                    },
                    {
                        path        : 'carrier/:id/detail',
                        component   : CarrierViewComponent
                    },
                    {
                        path        : 'machine',
                        component   : MachineListComponent
                    },
                    {
                        path        : 'machine/create',
                        component   : MachineViewComponent
                    },
                    {
                        path        : 'machine/:id/detail',
                        component   : MachineViewComponent
                    },
                    {
                        path        : 'customer',
                        component   : CustomerListComponent
                    },
                    {
                        path        : 'customer/:id/detail',
                        component   : CustomerViewComponent
                    },
                    {
                        path        : 'invoice',
                        component   : InvoiceListComponent
                    },
                    {
                        path        : 'tax/invoice',
                        component   : TaxInvoiceListComponent
                    },
                    {
                        path        : 'job',
                        component   : JobListComponent,
                    },
                    {
                        path        : 'purchase/order',
                        component   : PurchaseOrderListComponent
                    },
                    {
                        path        : 'ncr',
                        component   : NCRListComponent
                    },
                    {
                        path        : 'fvd',
                        component   : FVDListComponent
                    },
                    {
                        path        : 'vendor',
                        component   : VendorListComponent
                    },
                    {
                        path        : 'vendor/create',
                        component   : VendorViewComponent
                    },
                    {
                        path        : 'vendor/:id/detail',
                        component   : VendorViewComponent
                    },
                    {
                        path        : 'supplier',
                        component   : SupplierListComponent
                    },
                    {
                        path        : 'supplier/create',
                        component   : SupplierViewComponent
                    },
                    {
                        path        : 'supplier/:id/detail',
                        component   : SupplierViewComponent
                    },
                    {
                        path        : 'user',
                        component   : UserListComponent
                    },
                    {
                        path        : 'user/create',
                        component   : UserViewComponent
                    },
                    {
                        path        : 'user/:id/detail',
                        component   : UserViewComponent
                    },
                    {
                        path        : 'hardening',
                        component   : HardeningListComponent
                    },
                    {
                        path        : 'hardening/create',
                        component   : HardeningViewComponent
                    },
                    {
                        path        : 'hardening/:id/detail',
                        component   : HardeningViewComponent
                    },
                    {
                        path        : 'cosmetic',
                        component   : CosmeticListComponent
                    },
                    {
                        path        : 'cosmetic/create',
                        component   : CosmeticViewComponent
                    },
                    {
                        path        : 'cosmetic/:id/detail',
                        component   : CosmeticViewComponent
                    },
                    {
                        path        : 'criticalpoint',
                        component   : CriticalPointListComponent
                    },
                    {
                        path        : 'criticalpoint/create',
                        component   : CriticalPointViewComponent
                    },
                    {
                        path        : 'criticalpoint/:id/detail',
                        component   : CriticalPointViewComponent
                    },
                    {
                        path        : 'packaging',
                        component   : PackagingListComponent
                    },
                    {
                        path        : 'packaging/create',
                        component   : PackagingViewComponent
                    },
                    {
                        path        : 'packaging/:id/detail',
                        component   : PackagingViewComponent
                    },
                    {
                        path        : 'process',
                        component   : ProcessListComponent
                    },
                    {
                        path        : 'process/create',
                        component   : ProcessViewComponent
                    },
                    {
                        path        : 'process/:id/detail',
                        component   : ProcessViewComponent
                    },
                    {
                        path        : 'shipping',
                        component   : ShippingListComponent
                    },
                    {
                        path        : 'shipping/create',
                        component   : ShippingViewComponent
                    },
                    {
                        path        : 'shipping/:id/detail',
                        component   : ShippingViewComponent
                    },
                    {
                        path        : 'incoterm',
                        component   : IncotermListComponent
                    },
                    {
                        path        : 'incoterm/create',
                        component   : IncotermViewComponent
                    },
                    {
                        path        : 'incoterm/:id/detail',
                        component   : IncotermViewComponent
                    },
                    {
                        path        : 'delivery/note',
                        component   : DeliveryNoteListComponent
                    },
                    {
                        path        : 'packing/slip',
                        component   : PackingSlipListComponent
                    },
                    {
                        path        : 'manufacture',
                        component   : ManufactureListComponent
                    }
                ]
            },
            {
                path        : 'job/:id',
                component   : JobDetailTaskComponent
            },
            {
                path        : 'calendar',
                component   : CalendarModelViewComponent
            },
            {
                path        : 'calendar/all',
                component   : CalendarAllModelViewComponent
            },
            {
                path        : 'engineering',
                component   : RequestEngTaskComponent
            },
            {
                path        : 'fmt',
                component   : RequestFMTTaskComponent,
            },
            {
                path        : 'request',
                component   : RequestTaskComponent,
                children    : [
                    {
                        path        : 'store',
                        component   : RequestStoreTaskComponent
                    },
                    {
                        path        : 'purchase',
                        component   : RequestPurchaseTaskComponent
                    },
                    {
                        path        : 'store/:id',
                        component   : RequestStoreTaskComponent
                    },
                    {
                        path        : 'crd',
                        component   : RequestCRDTaskComponent
                    }
                ]
            },
            {
                path        : 'purchase/order',
                component   : PurchaseTaskComponent
            },
            {
                path      : 'task/approved',
                component : TaskComponent,
                children  : [
                    {
                        path      : 'quotation/:id',
                        component : QuotationTaskComponent
                    },
                    {
                        path      : 'request2/:id',
                        component : Purchase2TaskComponent
                    }
                ]
            },
            {
                path        : 'invoice/create',
                component   : InvoiceTaskComponent
            },
            {
                path        : 'invoice/:id',
                component   : InvoiceTaskComponent,
            },
            {
                path        : 'task/requisition',
                component   : RequestTaskListComponent
            },
            {
                path        : 'task/fmt',
                component   : RequestTaskFMTListComponent
            },
            {
                path        : 'task/hr',
                component   : RequestTaskHRListComponent,
            },
            {
                path        : 'task/finish',
                component   : CustomerTaskListComponent,
            },
            {
                path        : 'task/fmt-progress',
                component   : TaskProgressFMTListComponent
            },
            {
                path        : 'task/fmt-complete',
                component   : TaskCompleteFMTListComponent
            },
            {
                path        : 'task/fmt-apply',
                component   : TaskApplyFMTListComponent,
            },
            {
                path        : 'task/request-purchase-2',
                component   : Request2TaskListComponent
            },
            {
                path        : 'task/po-purchase2',
                component   : PO2TaskListComponent
            },
            {
                path        : 'task/request-purchase-order',
                component   : RequestPOTaskListComponent
            },
            {
                path        : 'task/return-store',
                component   : ReturnStoreTaskListComponent
            },
            {
                path        : 'task/reject/fmt/:id',
                component   : RequestFMTTaskComponent
            },
            {
                path        : 'task/:role',
                component   : TaskComponent,
                children    : [
                    {
                        path        : 'man/:id',
                        component   : HRTaskComponent
                    },
                    {
                        path        : 'fmt/:id',
                        component   : FMTTaskComponent
                    },
                    {
                        path        : 'return/:id',
                        component   : ReturnStoreComponent,
                    },
                    {
                        path        : 'archive/:id',
                        component   : ArchiveTaskComponent
                    },
                    {
                        path        : 'requisition/:id',
                        component   : RequisitionStoreComponent,
                    },
                    {
                        path        : 'request-purchase-order/:id',
                        component   : PurchaseOrderStoreComponent,
                    },
                    {
                        path        : 'purchase/:id',
                        component   : PrepareTaskComponent
                    },
                    {
                        path        : 'create/:guid',
                        component   : ProductTaskComponent
                    },
                    {
                        path        : 'requirement/:id',
                        component   : RequirementTaskComponent
                    },
                    {
                        path        : 'prod/:id',
                        component   : ProdTaskComponent
                    },
                    // {
                    //     path        : 'deposit/:id',
                    //     component   : AccountingTaskComponent
                    // },
                    {
                        path        : 'sale_order/:id',
                        component   : SaleOrderTaskComponent,
                    },
                    {
                        path        : 'quotation/:id',
                        component   : QuotationTaskComponent
                    },
                    {
                        path        : 'tax/invoice/:id',
                        component   : TaxInvoiceTaskComponent
                    },
                    {
                        path        : 'receipt/:id',
                        component   : ReceiptTaskComponent
                    },
                    {
                        path        : 'packing/slip/:id',
                        component   : PackingSlipTaskComponent
                    },
                    {
                        path        : 'invoice/:id',
                        component   : InvoiceTaskComponent
                    },
                    {
                        path        : 'deposit/:id',
                        component   : DepositTaskComponent
                    },
                    {
                        path        : 'payment/:id',
                        component   : PaymentTaskComponent
                    },
                    {
                        path        : 'finish/:id',
                        component   : FinishTaskComponent
                    },
                    {
                        path        : 'fvd/:id',
                        component   : FVDTaskComponent
                    },
                    {
                        path        : 'planning/:id',
                        component   : PlanningTaskComponent
                    },
                    {
                        path        : 'planning/:id/:job_id',
                        component   : PlanningTaskComponent
                    },
                    {
                        path        : 'detail/:id',
                        component   : ProductTaskComponent
                    },
                    {
                        path        : 'delivery/:id',
                        component   : DeliveryTaskComponent
                    },
                    {
                        path        : 'detail/:id/:product_id',
                        component   : ProductTaskComponent
                    },
                    {
                        path        : 'control/:id',
                        component   : PlanningTaskComponent
                    },
                    {
                        path        : 'control/:id/:job_id',
                        component   : PlanningTaskComponent
                    },
                    {
                        path        : 'rejected/:id',
                        component   : RejectedTaskComponent
                    },
                    {
                        path        : 'prepared/:id',
                        component   : PreparedStoreComponent
                    },
                    {
                        path        : 'request/:id',
                        component   : PurchaseTaskComponent
                    },
                    {
                        path        : 'request2/:id',
                        component   : RequestPurchaseTaskComponent
                    },
                    {
                        path        : 'request3/:id',
                        component   : Purchase2TaskComponent
                    },
                    {
                        path        : 'request/:id/:job_id',
                        component   : PurchaseTaskComponent
                    },
                    {
                        path        : 'request-fvd/:id',
                        component   : RequestFVDTaskComponent
                    },
                    {
                        path        : 'po/:id',
                        component   : PurchaseOrderTaskComponent
                    },
                    {
                        path        : 'store/:id',
                        component   : PrepareTaskComponent
                    },
                    {
                        path        : 'pr/:id',
                        component   : RequestPurchaseTaskComponent
                    },
                    {
                        path        : 'store/:id/:job_id',
                        component   : PrepareTaskComponent
                    },
                    {
                        path        : 'prepare/:id',
                        component   : PrepareTaskComponent
                    },
                    {
                        path        : 'notice/:id',
                        component   : NoticeTaskComponent
                    },
                    {
                        path        : 'trial/:id',
                        component   : TrialTaskComponent
                    },
                    {
                        path        : 'material/:id',
                        component   : PrepareStoreTaskComponent
                    },
                    {
                        path        : 'po-store/:id',
                        component   : POFVDTaskComponent
                    },
                    {
                        path        : 'notice-shipping/:id',
                        component   : NoticeShippingTaskComponent
                    },
                    {
                        path        : 'accounting/:id',
                        component   : AccountingTaskComponent
                    },
                    {
                        path        : ':prod',
                        component   : TaskListComponent
                    },
                    {
                        path        : 'ncr/:id',
                        component   : NCRTaskComponent
                    },
                    {
                        path        : '',
                        component   : TaskListComponent
                    }
                ]
            },
            {
                path        : '',
                redirectTo  : 'room/',
                pathMatch   : 'full'
            },
            {
                path        : 'room/child/:slug',
                component   : HomeComponent
            },
            {
                path        : 'room/:slug',
                component   : HomeComponent
            },
            {
                path        : 'room',
                component   : HomeComponent
            }
        ]
    },
    {
        path        : '**',
        component   : PageErrorComponent
    }
];
