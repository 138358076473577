import {Model} from '../now/model';
import {ShelfModel} from './shelf.model';

export class WarehouseModel extends Model {

    public name: string;
    public shelves: ShelfModel[];

    public shelf_id: string;
    public shelf: ShelfModel;
    public amount: number;

    constructor() {
        super('warehouses', 'App\\Warehouse');
        this.shelves = [];
        this.shelf = new ShelfModel();
    }

}
