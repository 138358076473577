import {FormModel} from '../now/formModel';

export class DocumentModel extends FormModel {

    public name: string;
    public note: string;
    public thumbnail: string;
    public preview: string;
    public path: string;
    public mime_type: string;

    public user_id: string;
    public modelable_type: string;
    public modelable_id: string;

    public role: string;

    public user: any;

    constructor(table_name?: string, model_name?: string) {
        super('documents', 'App\\Document');
    }

    public get valid(): boolean {
        if (this.name) {
            return true;
        }
        return false;
    }

}
