import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ProductProcessModel} from '../../../../models/productProcess.model';
import {TaskModel} from '../../../../models/task.model';
import {ProcessModel} from '../../../../models/process.model';
import {ProcessService} from '../../../../services/process.service';
import {HardeningModel} from '../../../../models/hardening.model';
import {CosmeticModel} from '../../../../models/cosmetic.model';
import {PackagingModel} from '../../../../models/packaging.model';
import {ShippingModel} from '../../../../models/shipping.model';
import {MachineModel} from '../../../../models/machine.model';
import {UserModel} from '../../../../now/user/user.model';
import {JobModel} from '../../../../models/job.model';
import {HardeningService} from '../../../../services/hardening.service';
import {CosmeticService} from '../../../../services/cosmetic.service';
import {PackagingService} from '../../../../services/packaging.service';
import {IncotermModel} from '../../../../models/incoterm.model';
import {IncotermService} from '../../../../services/incoterm.service';
import {SchedulerModel} from '../../../../models/scheduler.model';
import {SwalService} from '../../../../services/swal.service';
import {Api} from '../../../../now/api/api';
import {ProductModel} from '../../../../models/product.model';
import * as moment from 'moment';
import {SchedulerOrderByPipe} from '../../../../pipes/schedulerOrderBy.pipe';
import {MachineService} from '../../../../services/machine.service';
import {UserService} from '../../../../services/user.service';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Subscription} from 'rxjs/internal/Subscription';
import {DragulaService} from 'ng2-dragula';
import {DatepickerComponent} from '../../../../components/datepicker/datepicker.component';
import {FVD} from '../../../../app/api/fvd';
import {MaterialModel} from '../../../../models/material.model';
import {SupplierModel} from '../../../../models/supplier.model';
import {TimepickerComponent} from '../../../../components/timepicker/timepicker.component';
import {RemarkModal} from '../../../../modals/remark/remark.modal';
import * as uuid4 from 'uuid/v4';
import {LoaderService} from '../../../../components/loader/loader.service';

const MAX_MATERIALS = 5;
const MAX_SUPPLIERS = 5;

@Component({
    selector: 'planner-component',
    templateUrl: 'planner.component.html',
    styleUrls: ['planner.component.scss']
})
export class PlannerComponent implements AfterViewInit, OnDestroy {

    @ViewChild('plannerRemark', { static: true }) plannerRemark: RemarkModal;

    @Output('onFVDSubmit') onFVDModalSubmit: EventEmitter<FVD> = new EventEmitter<FVD>();
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren(DatepickerComponent) datepickers: QueryList<DatepickerComponent>;
    @ViewChildren(TimepickerComponent) timepickers: QueryList<TimepickerComponent>;

    @Input() disabled: boolean;
    @Input() product: ProductModel;
    @Input() job: JobModel;
    @Input() task: TaskModel;
    @Input() job_ids: string[];
    @Input() enable: boolean;

    public highLightDates: any[];
    public current_fvd: FVD;
    public job_id: string;

    @Input() public processes: ProcessModel[];
    @Input() public hardenings: HardeningModel[];
    @Input() public cosmetics: CosmeticModel[];
    @Input() public packagings: PackagingModel[];
    @Input() public incoterms: IncotermModel[];

    public shipping_process: ProcessModel;
    public qc_final_process: ProcessModel;
    public store_process: ProcessModel;
    public qc_process_process: ProcessModel;
    public finished_good_process: ProcessModel;
    public packing_process: ProcessModel;
    public assembly_process: ProcessModel;

    public current_product_process: ProductProcessModel;
    private subs: Subscription;

    private onchange_timeout: any;

    constructor(
        private processService: ProcessService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private machineService: MachineService,
        private userService: UserService,
        private swal: SwalService,
        private ngZone: NgZone,
        private api: Api,
        public loader: LoaderService,
        private modelApi: ModelApi,
        private smartModalService: NgxSmartModalService,
        private dragulaService: DragulaService,
        private schedulerOrderBy: SchedulerOrderByPipe,
        private smartModal: NgxSmartModalService
    ) {
        //
        this.processes = [];
        this.hardenings = [];
        this.cosmetics = [];
        this.packagings = [];
        this.incoterms = [];
        this.job_ids = [];

        this.highLightDates = [];
        this.subs = new Subscription();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.job_id = this.job.id;
            this.init_product_processes();
            this.init_dragula();
            this.init();
        }, 300);
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.dragulaService.destroy('PRODUCT_PROCESSES');
    }

    public init(): void {
        setTimeout(() => {
            this.initProcesses();
            this.timepickers.forEach((timepicker: TimepickerComponent): void => {
                timepicker.setDisableTime();
            });
        }, 1000);
    }

    public initProcesses(): void {
        this.store_process = this.processes.find(i => i.slug  === 'prepare-store');
        this.qc_final_process = this.processes.find(i => i.slug  === 'qc-final');
        this.qc_process_process = this.processes.find(i => i.slug  === 'qc-process');
        this.finished_good_process = this.processes.find(i => i.slug  === 'finish-good');
        this.packing_process = this.processes.find(i => i.slug  === 'packing');
        this.shipping_process = this.processes.find(i => i.slug === 'shipping');
        this.assembly_process = this.processes.find(i => i.slug === 'assembly');
    }

    public init_dragula(): void {
        setTimeout(() => {
            this.subs.add(
                this.dragulaService.dropModel('PRODUCT_PROCESSES')
                    .subscribe((args: any) => {
                        this.check_scheduler_date();
                        this.onChangeTimeout();
                    })
            );
        }, 1000);
    }

    public toggleProductProcess(parent_product_process: ProductProcessModel,
                                parent_index: number,
                                child_product_process?: ProductProcessModel,
                                child_index?: number): void {
        if (this.disabled) {
            return;
        }
        //
        let checked: boolean;
        if (child_product_process && child_index > -1) {
            checked = !child_product_process.checked;
            if (checked === true) {
                if (parent_product_process.children[child_index + 1] && parent_product_process.revision > -1) {
                    this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                parent_product_process.children[child_index + 1].checked = false;
                                const child_pop: ProductProcessModel = parent_product_process.children.pop();
                                parent_product_process.latest_child.checked = true;
                                this.loader.show();
                                this.delete_scheduler(child_pop)
                                    .then(() => {
                                        this.onChangeTimeout();
                                        setTimeout(() => {
                                            this.product_process_and_scheduler_update(parent_product_process);
                                            if (child_product_process) {
                                                this.product_process_and_scheduler_update(child_product_process);
                                            }
                                            this.loader.hide();
                                        }, 300);
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            } else {
                                //
                            }
                        });
                } else {
                    if (child_index - 1 > -1) {
                        parent_product_process.children[child_index - 1].checked = false;
                    } else {
                        parent_product_process.checked = false;
                    }
                    child_product_process.checked = true;
                    this.onChangeTimeout();
                    setTimeout(() => {
                        this.product_process_and_scheduler_update(parent_product_process);
                        if (child_product_process) {
                            this.product_process_and_scheduler_update(child_product_process);
                        }
                    }, 300);
                }
            } else {
                if (child_index === 0 && child_product_process.current_role === this.job.current_role && (!child_product_process.revision && child_product_process.revision !== 0)) {
                    this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                if (child_index - 1 > -1) {
                                    parent_product_process.children[child_index - 1].checked = true;
                                } else {
                                    parent_product_process.checked = true;
                                }
                                child_product_process.checked = false;
                                parent_product_process.children.pop();
                                this.loader.show();
                                this.delete_scheduler(child_product_process)
                                    .then(() => {
                                        this.onChangeTimeout();
                                        setTimeout(() => {
                                            this.product_process_and_scheduler_update(parent_product_process);
                                            if (child_product_process) {
                                                this.product_process_and_scheduler_update(child_product_process);
                                            }
                                            this.loader.hide();
                                        }, 300);
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            }
                        });
                } else if (child_index === 0) {
                    let tmp_child_product_process: ProductProcessModel;
                    tmp_child_product_process = new ProductProcessModel();
                    tmp_child_product_process.process = new ProcessModel();
                    tmp_child_product_process.machine = new MachineModel();
                    tmp_child_product_process.user = new UserModel();
                    tmp_child_product_process.id = null;
                    tmp_child_product_process.guid = uuid4();
                    tmp_child_product_process.checked = true;
                    tmp_child_product_process.current_role = this.job.current_role;
                    tmp_child_product_process.parent_id = parent_product_process.id;
                    tmp_child_product_process.parent_guid = parent_product_process.guid;
                    tmp_child_product_process.supplier_id = parent_product_process.supplier_id;

                    child_product_process.checked = false;
                    parent_product_process.checked = false;

                    const _scheduler: SchedulerModel = new SchedulerModel();
                    _scheduler.current_role = this.job.current_role;
                    _scheduler.process = tmp_child_product_process.process;
                    _scheduler.machine = tmp_child_product_process.machine;
                    _scheduler.user = tmp_child_product_process.user;
                    _scheduler.checked = true;
                    tmp_child_product_process.addScheduler(_scheduler);
                    parent_product_process.children.push(tmp_child_product_process);
                    this.onChangeTimeout();
                    setTimeout(() => {
                        this.product_process_and_scheduler_update(parent_product_process);
                        if (child_product_process) {
                            this.product_process_and_scheduler_update(child_product_process);
                        }
                    }, 300);
                } else if (!child_product_process.revision && child_product_process.revision !== 0) {
                    this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                parent_product_process.children.pop();
                                parent_product_process.latest_child.checked = true;
                                this.loader.show();
                                this.delete_scheduler(child_product_process)
                                    .then(() => {
                                        this.onChangeTimeout();
                                        setTimeout(() => {
                                            this.product_process_and_scheduler_update(parent_product_process);
                                            if (child_product_process) {
                                                this.product_process_and_scheduler_update(child_product_process);
                                            }
                                            this.loader.hide();
                                        }, 300);
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            } else {
                                //
                            }
                        });
                } else {
                    child_product_process.checked = false;
                    parent_product_process.checked = false;
                    let tmp_child_product_process: ProductProcessModel;
                    tmp_child_product_process = new ProductProcessModel();
                    tmp_child_product_process.id = null;
                    tmp_child_product_process.guid = uuid4();
                    tmp_child_product_process.checked = true;
                    tmp_child_product_process.current_role = this.job.current_role;
                    tmp_child_product_process.parent_id = parent_product_process.id;
                    tmp_child_product_process.parent_guid = parent_product_process.guid;
                    tmp_child_product_process.supplier_id = parent_product_process.supplier_id;
                    const _scheduler: SchedulerModel = new SchedulerModel();
                    _scheduler.current_role = this.job.current_role;
                    _scheduler.process = tmp_child_product_process.process;
                    _scheduler.machine = tmp_child_product_process.machine;
                    _scheduler.user = tmp_child_product_process.user;
                    _scheduler.checked = true;
                    tmp_child_product_process.addScheduler(_scheduler);
                    parent_product_process.children.push(tmp_child_product_process);
                    this.onChangeTimeout();
                    setTimeout(() => {
                        this.product_process_and_scheduler_update(parent_product_process);
                        if (child_product_process) {
                            this.product_process_and_scheduler_update(child_product_process);
                        }
                    }, 300);
                }
            }
        } else {
            checked = !parent_product_process.checked;
            if (checked === false) {
                if ((!parent_product_process.revision && parent_product_process.revision !== 0) && parent_product_process.current_role === this.job.current_role) {
                    this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                parent_product_process.checked = false;
                                this.product.product_processes.splice(parent_index, 1);
                                this.loader.show();
                                this.delete_scheduler(parent_product_process)
                                    .then(() => {
                                        this.onChangeTimeout();
                                        setTimeout(() => {
                                            this.product_process_and_scheduler_update(parent_product_process);
                                            if (child_product_process) {
                                                this.product_process_and_scheduler_update(child_product_process);
                                            }
                                            this.loader.hide();
                                        }, 300);
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            } else {
                                //
                            }
                        });
                } else {
                    parent_product_process.checked = false;
                    let tmp_child_product_process: ProductProcessModel;
                    tmp_child_product_process = new ProductProcessModel();
                    tmp_child_product_process.id = null;
                    tmp_child_product_process.guid = uuid4();
                    tmp_child_product_process.checked = true;
                    tmp_child_product_process.current_role = this.job.current_role;
                    tmp_child_product_process.parent_id = parent_product_process.id;
                    tmp_child_product_process.parent_guid = parent_product_process.guid;
                    tmp_child_product_process.supplier_id = parent_product_process.supplier_id;
                    const _scheduler: SchedulerModel = new SchedulerModel();
                    _scheduler.current_role = this.job.current_role;
                    _scheduler.process = tmp_child_product_process.process;
                    _scheduler.machine = tmp_child_product_process.machine;
                    _scheduler.user = tmp_child_product_process.user;
                    _scheduler.checked = true;
                    tmp_child_product_process.addScheduler(_scheduler);
                    parent_product_process.children.push(tmp_child_product_process);
                    this.onChangeTimeout();
                    setTimeout(() => {
                        this.product_process_and_scheduler_update(parent_product_process);
                        if (child_product_process) {
                            this.product_process_and_scheduler_update(child_product_process);
                        }
                    }, 300);
                }
            } else {
                this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            parent_product_process.checked = true;
                            const child_pop: ProductProcessModel = parent_product_process.children.pop();
                            this.loader.show();
                            this.delete_scheduler(child_pop)
                                .then(() => {
                                    this.onChangeTimeout();
                                    setTimeout(() => {
                                        this.product_process_and_scheduler_update(parent_product_process);
                                        if (child_product_process) {
                                            this.product_process_and_scheduler_update(child_product_process);
                                        }
                                        this.loader.hide();
                                    }, 300);
                                }, error => {
                                    this.swal.danger(error);
                                    this.loader.hide();
                                });
                        } else {
                            //
                        }
                    });
            }
        }
    }

    private delete_scheduler(product_process: ProductProcessModel): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            if (product_process && product_process.id) {
                this.api.request('product/processes/delete', 'POST', {
                    product_process_id: product_process.id
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public uncheckScheduler(product_process: ProductProcessModel): void {
        if (this.disabled) {
            return;
        }
        product_process.checked = false;
        this.onChangeTimeout();
    }

    public onTimeChange(product_process: ProductProcessModel, scheduler: SchedulerModel, dat: any, e?: any): void {
        setTimeout(() => {
            this.product_process_and_scheduler_update(product_process);
            this.onChangeTimeout();
        }, 100);
    }

    public check_scheduler_date(job_ids?: any[]): boolean {
        let error_scheduler: SchedulerModel;
        error_scheduler = null;
        let tmp_schedulers: SchedulerModel[];
        tmp_schedulers = [];
        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process: ProductProcessModel = this.product.product_processes[i];
            if (product_process && product_process.children && product_process.children.length > 0) {
                for (let n = 0; n < product_process.children.length; n++) {
                    const child: ProductProcessModel = product_process.children[n];
                    if (child && child.checked) {
                        for (let j = 0; j < child.schedulers.length; j++) {
                            const _: SchedulerModel = child.schedulers[j];
                            if (_ && _.checked) {
                                _.process_slug = product_process.process.slug;
                                tmp_schedulers.push(_);
                            }
                        }
                    } else {
                        //
                    }
                }
            } else if (product_process && product_process.checked) {
                for (let j = 0; j < product_process.schedulers.length; j++) {
                    const _: SchedulerModel = product_process.schedulers[j];
                    if (_ && _.checked) {
                        _.process_slug = product_process.process.slug;
                        tmp_schedulers.push(_);
                    }
                }
            }
        }
        const rearrange_schedulers: SchedulerModel[] = this.schedulerOrderBy.transform(tmp_schedulers.concat());
        for (let i = 0; i < tmp_schedulers.length; i++) {
            const scheduler1: SchedulerModel = tmp_schedulers[i];
            const scheduler2: SchedulerModel = rearrange_schedulers[i];
            if (scheduler1.guid !== scheduler2.guid && scheduler1.checked === true && scheduler2.checked === true) {
                scheduler1.date_error = true;
                error_scheduler = scheduler1;
            } else {
                scheduler1.date_error = false;
            }
        }
        for (let i = 0; i < rearrange_schedulers.length; i++) {
            for (let j = 0; j < rearrange_schedulers.length; j++) {
                const scheduler1 = rearrange_schedulers[i];
                const scheduler2 = rearrange_schedulers[j];
                if (
                    scheduler1.process_slug
                    && scheduler2.process_slug
                    && scheduler1.process_slug !== 'prepare-store'
                    && scheduler1.process_slug !== 'shipping'
                    && scheduler1.process_slug !== 'qc-final'
                    && scheduler1.process_slug !== 'finish-good'
                    && scheduler1.process_slug !== 'packing'
                    && scheduler2.process_slug !== 'prepare-store'
                    && scheduler2.process_slug !== 'shipping'
                    && scheduler2.process_slug !== 'qc-final'
                    && scheduler2.process_slug !== 'finish-good'
                    && scheduler2.process_slug !== 'packing'
                ) {
                    if (scheduler1.guid !== scheduler2.guid && scheduler1.checked === true && scheduler2.checked === true) {
                        const start1: any = moment(scheduler1.start, 'YYYY-MM-DD HH:mm:ss');
                        const start2: any = moment(scheduler2.start, 'YYYY-MM-DD HH:mm:ss');
                        const end1: any = moment(scheduler1.end, 'YYYY-MM-DD HH:mm:ss');
                        const end2: any = moment(scheduler2.end, 'YYYY-MM-DD HH:mm:ss');
                        const is_overlap: boolean = this.is_overlap(start1, start2, end1, end2);
                        if (is_overlap) {
                            scheduler1.date_error = scheduler2.date_error = true;
                            error_scheduler = scheduler1;
                        }
                    } else {
                        //
                    }
                }
            }
        }
        for (let i = 0; i < rearrange_schedulers.length; i++) {
            const scheduler: SchedulerModel = rearrange_schedulers[i];
            if (scheduler.checked === true) {
                const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                if (start.diff(end) > 0) {
                    scheduler.date_error = scheduler.date_error = true;
                    error_scheduler = scheduler;
                }
            }
        }
        if (error_scheduler) {
            return true;
        } else {
            return false;
        }
    }

    public getMachineMaintainPlans(machine: MachineModel): Promise<any> {
        const promise = new Promise(resolve => {
            if (this.job_id) {
                this.machineService.machineMaintainPlans(machine)
                    .then(schedulers => {
                        resolve(schedulers);
                    }, error => {
                        resolve([]);
                    });
            } else {
                resolve([]);
            }
        });
        return promise;
    }

    public getFMTs(machine): Promise<any[]> {
        const promise = new Promise<any[]>(resolve => {
            this.machineService.getFMTs(machine)
                .then(fmts => {
                    resolve((fmts) ? fmts : []);
                }, error => {
                    console.error(error);
                    resolve([]);
                });
        });
        return promise;
    }

    public getMachineSchedulers(machine: MachineModel): Promise<SchedulerModel[]> {
        let promise: Promise<SchedulerModel[]>;
        promise = new Promise<SchedulerModel[]>((resolve, reject) => {
            if (this.job_id) {
                this.machineService.machineSchedulers(machine, this.job_id)
                    .then(schedulers => {
                        resolve(schedulers);
                    }, error => {
                        resolve([]);
                    });
            } else {
                resolve([]);
            }
        });
        return promise;
    }

    public getUserSchedulers(user: UserModel): Promise<SchedulerModel[]> {
        let promise: Promise<SchedulerModel[]>;
        promise = new Promise<SchedulerModel[]>((resolve, reject) => {
            if (this.job_id) {
                this.userService.userSchedulers(user, this.job_id)
                    .then(schedulers => {
                        resolve(schedulers);
                    }, error => {
                        resolve([]);
                    });
            } else {
                resolve([]);
            }
        });
        return promise;
    }

    public getLeaveHistories(user_id): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('users/' + user_id + '/leaves', 'GET')
                .subscribe((res: any): void => {
                    const leaveHistories = [];
                    if (res && res.data) {
                        for (const dat of res.data) {
                            if (dat && dat.approved_3_user_id) {
                                leaveHistories.push(dat);
                            }
                        }
                    }
                    resolve(leaveHistories);
                });
        });
        return promise;
    }

    public _check_machine_date(machine: MachineModel, product_process: ProductProcessModel, schedulers: SchedulerModel[], job_ids?: any[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.enable !== true) {
                resolve({});
                return;
            }
            for (let i = 0; i < schedulers.length; i++) {
                schedulers[i].busy_date_error = false;
            }
            if (machine && machine.id && product_process.checked) {
                this.getMachineSchedulers(machine)
                    .then(_ => {
                        this.getMachineMaintainPlans(machine)
                            .then(maintain_plans => {
                                this.getFMTs(machine)
                                    .then(fmts => {
                                        const fmt_schedulers = [];
                                        const maintain_schedulers = [];
                                        const busy_schedulers = [];
                                        const returned_schedulers = [];
                                        for (let i = 0; i < fmts.length; i++) {
                                            for (let j = 0; j < schedulers.length; j++) {
                                                const scheduler1 = fmts[i];
                                                const scheduler2 = schedulers[j];
                                                if (scheduler1 && scheduler2 && scheduler2.checked) {
                                                    const start1: any = moment(scheduler1.issued_date, 'YYYY-MM-DD HH:mm:ss');
                                                    const start2: any = moment(scheduler2.start, 'YYYY-MM-DD HH:mm:ss');
                                                    const end1: any = moment(scheduler1.estimated_repaired_at, 'YYYY-MM-DD HH:mm:ss');
                                                    const end2: any = moment(scheduler2.end, 'YYYY-MM-DD HH:mm:ss');
                                                    const is_overlap: boolean = this.is_overlap(start1, start2, end1, end2);
                                                    if (is_overlap) {
                                                        fmt_schedulers.push(scheduler1);
                                                        busy_schedulers.push(scheduler2);
                                                    } else {
                                                        //
                                                    }
                                                }
                                            }
                                        }
                                        for (let i = 0; i < maintain_plans.length; i++) {
                                            for (let j = 0; j < schedulers.length; j++) {
                                                const scheduler1: SchedulerModel = maintain_plans[i];
                                                const scheduler2: SchedulerModel = schedulers[j];
                                                if (scheduler1 && scheduler2 && scheduler2.checked) {
                                                    const start1: any = moment(scheduler1.start, 'YYYY-MM-DD HH:mm:ss');
                                                    const start2: any = moment(scheduler2.start, 'YYYY-MM-DD HH:mm:ss');
                                                    const end1: any = moment(scheduler1.end, 'YYYY-MM-DD HH:mm:ss');
                                                    const end2: any = moment(scheduler2.end, 'YYYY-MM-DD HH:mm:ss');
                                                    const is_overlap: boolean = this.is_overlap(start1, start2, end1, end2);
                                                    if (is_overlap) {
                                                        maintain_schedulers.push(scheduler1);
                                                        busy_schedulers.push(scheduler2);
                                                    } else {
                                                        //
                                                    }
                                                }
                                            }
                                        }
                                        for (let i = 0; i < _.length; i++) {
                                            for (let j = 0; j < schedulers.length; j++) {
                                                const scheduler1: SchedulerModel = _[i];
                                                const scheduler2: SchedulerModel = schedulers[j];
                                                if (scheduler1 && scheduler2
                                                    && scheduler1.job_id !== scheduler2.job_id
                                                    && scheduler1.id !== scheduler2.id
                                                    && scheduler1.checked && scheduler2.checked) {
                                                    //
                                                    const start1: any = moment(scheduler1.start, 'YYYY-MM-DD HH:mm:ss');
                                                    const start2: any = moment(scheduler2.start, 'YYYY-MM-DD HH:mm:ss');
                                                    const end1: any = moment(scheduler1.end, 'YYYY-MM-DD HH:mm:ss');
                                                    const end2: any = moment(scheduler2.end, 'YYYY-MM-DD HH:mm:ss');
                                                    const is_overlap: boolean = this.is_overlap(start1, start2, end1, end2);
                                                    if (is_overlap) {
                                                        busy_schedulers.push(scheduler2);
                                                    } else {
                                                        //
                                                    }
                                                }
                                            }
                                            if (_[i].product_process_id === product_process.id) {
                                                //
                                            } else {
                                                returned_schedulers.push(_[i]);
                                            }
                                        }
                                        resolve({
                                            success         : true,
                                            product_process : product_process,
                                            fmt             : fmt_schedulers,
                                            maintain        : maintain_schedulers,
                                            schedulers      : returned_schedulers,
                                            busy            : busy_schedulers
                                        });
                                    });
                            });
                    });
            } else {
                resolve({
                    success : false
                });
            }
        });
        return promise;
    }

    public _check_man_date(user: UserModel, product_process: ProductProcessModel, schedulers: SchedulerModel[], job_ids?: any[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.enable !== true) {
                resolve({});
                return;
            }
            for (let i = 0; i < schedulers.length; i++) {
                schedulers[i].busy_date_error = false;
            }
            if (user && user.id && product_process.checked) {
                this.getUserSchedulers(user)
                    .then(_ => {
                        this.getLeaveHistories(user.id)
                            .then(leaveHistories => {
                                let busy_schedulers: SchedulerModel[];
                                busy_schedulers = [];
                                let returned_schedulers: SchedulerModel[];
                                returned_schedulers = [];
                                const leaveSchedulers = [];
                                for (let i = 0; i < leaveHistories.length; i++) {
                                    for (let j = 0; j < schedulers.length; j++) {
                                        const scheduler1 = leaveHistories[i];
                                        const scheduler2 = schedulers[j];
                                        if (scheduler1 && scheduler2 && scheduler2.checked) {
                                            const start1: any = moment(scheduler1.start, 'YYYY-MM-DD HH:mm:ss');
                                            const start2: any = moment(scheduler2.start, 'YYYY-MM-DD HH:mm:ss');
                                            const end1: any = moment(scheduler1.end, 'YYYY-MM-DD HH:mm:ss');
                                            const end2: any = moment(scheduler2.end, 'YYYY-MM-DD HH:mm:ss');
                                            const is_overlap: boolean = this.is_overlap(start1, start2, end1, end2);
                                            if (is_overlap) {
                                                leaveSchedulers.push(scheduler1);
                                                busy_schedulers.push(scheduler2);
                                            } else {
                                                //
                                            }
                                        }
                                    }
                                }
                                for (let i = 0; i < _.length; i++) {
                                    for (let j = 0; j < schedulers.length; j++) {
                                        const scheduler1 = _[i];
                                        const scheduler2 = schedulers[j];
                                        if (scheduler1 && scheduler2
                                            && scheduler1.job_id !== scheduler2.job_id
                                            && scheduler1.id !== scheduler2.id
                                            && scheduler1.checked && scheduler2.checked) {
                                            //
                                            const start1: any = moment(scheduler1.start, 'YYYY-MM-DD HH:mm:ss');
                                            const start2: any = moment(scheduler2.start, 'YYYY-MM-DD HH:mm:ss');
                                            const end1: any = moment(scheduler1.end, 'YYYY-MM-DD HH:mm:ss');
                                            const end2: any = moment(scheduler2.end, 'YYYY-MM-DD HH:mm:ss');
                                            const is_overlap: boolean = this.is_overlap(start1, start2, end1, end2);
                                            if (is_overlap) {
                                                console.log(scheduler2);
                                                busy_schedulers.push(scheduler2);
                                            } else {
                                                //
                                            }
                                        }
                                    }
                                    if (_[i].product_process_id === product_process.id) {
                                        //
                                    } else {
                                        returned_schedulers.push(_[i]);
                                    }
                                }
                                resolve({
                                    success: true,
                                    leaveSchedulers,
                                    product_process: product_process,
                                    schedulers: returned_schedulers,
                                    busy: busy_schedulers
                                });
                            });
                    });
            } else {
                resolve({
                    success : false
                });
            }
        });
        return promise;
    }

    public check_machine_date(machine: MachineModel, product_process: ProductProcessModel, schedulers: SchedulerModel[]): void {
        this._check_machine_date(product_process.machine, product_process, product_process.schedulers)
            .then((result: any): void => {
                if (result && result.success === true) {
                    if (!result.busy.length && !result.maintain.length && !result.fmt.length) {
                        //
                    } else if (result.maintain.length) {
                        let dateTime = '';
                        for (let i = 0; i < result.busy.length; i++) {
                            result.busy[i].busy_date_error = true;
                        }
                        for (let i = 0; i < result.maintain.length; i++) {
                            dateTime += moment(result.maintain[i].start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY HH:mm');
                            dateTime += ' ~ ' + moment(result.maintain[i].end, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY HH:mm') + '<br>';
                        }
                        const text: string = 'เครื่องจักร <strong class=\"text-primary\">"' + product_process.machine.machine_name + '"</strong>' +
                            ' อยู่ในแผนซ่อมบำรุง<br>' + dateTime + '';

                        this.swal.danger(text, true);
                    } else if (result.fmt.length) {
                        let dateTime = '';
                        for (let i = 0; i < result.busy.length; i++) {
                            result.busy[i].busy_date_error = true;
                        }
                        for (let i = 0; i < result.fmt.length; i++) {
                            dateTime += moment(result.fmt[i].issued_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY');
                            dateTime += ' ~ ' + moment(result.fmt[i].estimated_repaired_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY') + ' ' + result.fmt[i].document_no + '<br>';
                        }
                        const text: string = 'เครื่องจักร <strong class=\"text-primary\">"' + product_process.machine.machine_name + '"</strong>' +
                            ' แจ้งซ่อม <br>' + dateTime + '';

                        this.swal.danger(text, true);
                    } else if (result.busy.length) {
                        const process_slug: string = product_process.process.slug;
                        if (process_slug !== 'prepare-store' && process_slug !== 'shipping' && process_slug !== 'packing' && process_slug !== 'finish-good') {
                            for (let i = 0; i < result.busy.length; i++) {
                                result.busy[i].busy_date_error = true;
                            }
                            let dateTime: string;
                            dateTime = '';
                            for (let i = 0; i < result.schedulers.length; i++) {
                                // dateTime += '(' + result.schedulers[i].id + ', ' + result.schedulers[i].job_id + ') '
                                dateTime += moment(result.schedulers[i].start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY HH:mm');
                                dateTime += ' ~ ' + moment(result.schedulers[i].end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') + '<br>';
                            }
                            const text: string = 'เครื่องจักร <strong class=\"text-primary\">"' + product_process.machine.machine_name + '"</strong>' +
                                ' ไม่ว่างช่วงเวลาดังนี้<br>' + dateTime + '';

                            this.swal.danger(text, true);
                        }
                    }
                }
            });
    }

    public check_man_date(user: UserModel, product_process: ProductProcessModel, schedulers: SchedulerModel[]): void {
        this._check_man_date(product_process.user, product_process, product_process.schedulers)
            .then((result: any): void => {
                if (result && result.success === true) {
                    if (!result.busy.length) {
                        //
                    } else if (result.leaveSchedulers.length) {
                        let dateTime = '';
                        for (let i = 0; i < result.busy.length; i++) {
                            result.busy[i].busy_date_error = true;
                        }
                        for (let i = 0; i < result.leaveSchedulers.length; i++) {
                            let title = 'ลา';
                            const subject = result.leaveSchedulers[i].subject;
                            if (subject === 'VACATION') {
                                title = 'ลาพักร้อน';
                            } else if (subject === 'PERSONAL') {
                                title = 'ลากิจ';
                            } else if (subject === 'SICK') {
                                title = 'ลาป่วย';
                            } else if (subject === 'MATERNITY') {
                                title = 'ลาคลอด';
                            } else if (subject === 'FORCE') {
                                title = 'ลาขาด';
                            } else if (subject === 'OTHER') {
                                title = 'ลา (อื่นๆ)';
                            }
                            const start = result.leaveSchedulers[i].start;
                            const end = result.leaveSchedulers[i].end;
                            dateTime += title + ' ';
                            dateTime += (start) ? moment(start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY') : '';
                            if (end && end !== start) {
                                dateTime += ' ~ ' + moment(end, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY') + '<br>';
                            }
                        }
                        const text: string = 'ผู้ดำเนินการ <strong class=\"text-primary\">"' + product_process.user.full_name + '"</strong>' +
                            '<br>"' + dateTime + '"';

                        this.swal.danger(text, true);
                    } else {
                        const process_slug: string = product_process.process.slug;
                        if (process_slug !== 'prepare-store' && process_slug !== 'shipping' && process_slug !== 'packing' && process_slug !== 'finish-good') {
                            for (let i = 0; i < result.busy.length; i++) {
                                result.busy[i].busy_date_error = true;
                            }
                            let dateTime: string;
                            dateTime = '';
                            for (let i = 0; i < result.schedulers.length; i++) {
                                // dateTime += '(' + result.schedulers[i].id + ', ' + result.schedulers[i].job_id + ') '
                                dateTime += moment(result.schedulers[i].start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY HH:mm');
                                dateTime += ' ~ ' + moment(result.schedulers[i].end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') + '<br>';
                            }
                            const text: string = 'ผู้ดำเนินการ <strong class=\"text-primary\">"' + product_process.user.full_name + '"</strong>' +
                                ' ไม่ว่างช่วงเวลา<br>"' + dateTime + '"';

                            this.swal.danger(text, true);
                        }
                    }
                } else {
                    //
                }
            });
    }

    private is_overlap(start1: any, start2: any, end1?: any, end2?: any): boolean {
        if (start1 && start2 && moment.isMoment(start1) && moment.isMoment(start2)) {
            const _start1: number = +start1.format('YYYYMMDDHHmmss');
            const _end1: number = (end1) ? +end1.format('YYYYMMDDHHmmss') : +start1.add(1, 'hours')
                .format('YYYYMMDDHHmmss');
            const _start2: number = +start2.format('YYYYMMDDHHmmss');
            const _end2: number = (end2) ? +end2.format('YYYYMMDDHHmmss') : +start2.add(1, 'hours')
                .format('YYYYMMDDHHmmss');
            if (Math.max(_start1, _start2) < Math.min(_end1, _end2)) {
                return true;
            }
        }
        return false;
    }

    public product_process_and_scheduler_update(product_process: ProductProcessModel): void {
        if (product_process && product_process.schedulers) {
            for (let i = 0; i < product_process.schedulers.length; i++) {
                product_process.schedulers[i].busy_date_error = false;
                this.product_process_update(product_process, product_process.schedulers[i]);
            }
        }
        this.check_scheduler_date();
        if (product_process && product_process.checked && product_process.machine && parseInt(product_process.machine_id) > 0) {
            this.check_machine_date(product_process.machine, product_process, product_process.schedulers);
        } else {
            //
        }
        if (product_process && product_process.checked && product_process.user && parseInt(product_process.user_id) > 0) {
            this.check_man_date(product_process.user, product_process, product_process.schedulers);
        } else {
            //
        }
        this.datepicker_update();
    }

    private push_high_light_date(highLightDates: any[], all_schedulers: any[], scheduler: SchedulerModel): void {
        if (scheduler && scheduler.checked) {
            all_schedulers.push(scheduler);
            const start = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
            highLightDates.push({
                date: start.toDate(),
                classes: 'already-exists-day'
            });
            const c_start = moment(scheduler.start, 'YYYY-MM-DD');
            const c_end = moment(scheduler.end, 'YYYY-MM-DD');
            if (c_start !== c_end) {
                const end = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                highLightDates.push({
                    date: end.toDate(),
                    classes: 'already-exists-day'
                });
            }
        }
    }

    private datepicker_update(): void {
        let highLightDates: any[];
        highLightDates = [];

        let all_schedulers: SchedulerModel[];
        all_schedulers = [];

        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process: ProductProcessModel = this.product.product_processes[i];
            if (product_process) {
                if (product_process.checked) {
                    for (let k = 0; k < product_process.schedulers.length; k++) {
                        const scheduler: SchedulerModel = product_process.schedulers[k];
                        this.push_high_light_date(highLightDates, all_schedulers, scheduler);
                    }
                }
                if (product_process.children && product_process.children.length) {
                    for (let j = 0; j < product_process.children.length; j++) {
                        const child: ProductProcessModel = product_process.children[j];
                        if (child && child.checked) {
                            for (let k = 0; k < child.schedulers.length; k++) {
                                const scheduler: SchedulerModel = child.schedulers[k];
                                this.push_high_light_date(highLightDates, all_schedulers, scheduler);
                            }
                        }
                    }
                }
            }
        }
        this.highLightDates = highLightDates;
    }

    private isValidaDate(date: string): boolean {
        return moment(date).isValid();
    }

    public product_process_update(product_process: ProductProcessModel, scheduler: SchedulerModel): void {
        this.ngZone.run(() => {
            if (scheduler.start && !scheduler.end) {
                const start_array = scheduler.start.split(' ');
                const start = start_array[0];
                // const start_time = start_array[1];
                scheduler.end = start + ' 17:00:00';
            } else if (scheduler.start && scheduler.end && !product_process.is_fvd) {
                const start_array = scheduler.start.split(' ');
                const start = start_array[0];
                const start_time = start_array[1];
                const end_array = scheduler.end.split(' ');
                const end = end_array[0];
                const end_time = end_array[1];
                if (product_process && product_process.process && product_process.process.slug) {
                    if (
                        product_process.process.slug === 'finish-good' ||
                        product_process.process.slug === 'packing' ||
                        product_process.process.slug === 'shipping' ||
                        product_process.process.slug === 'prepare-store'
                    ) {
                        // scheduler.start = start + ' ' + ' 08:00:00';
                        scheduler.end = start + ' ' + start_time;
                    } else {
                        scheduler.end = start + ' ' + end_time;
                    }
                } else {
                    scheduler.end = start + ' ' + end_time;
                }
            }
            scheduler.update();
            scheduler.calMinutes();
            product_process.update();
        });
    }

    public onDateChange(product_process: ProductProcessModel, scheduler: SchedulerModel, dat: any, e?: any): void {
        setTimeout(() => {
            if (!this.job.planning_started_at) {
                if (product_process && product_process.process && product_process.process.slug === 'shipping') {
                    if (scheduler.start) {
                        const start: string = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss')
                            .format('YYYY-MM-DD ') + '08:00:00';
                        this.job.delivery_date = start;
                    } else {
                        //
                    }
                } else {
                    //
                }
            } else {
                //
            }
            this.product_process_and_scheduler_update(product_process);
            this.onChangeTimeout();
        }, 100);
    }

    public addMoreScheduler(product_process: ProductProcessModel): void {
        let scheduler: SchedulerModel;
        scheduler = new SchedulerModel();
        scheduler.current_role = (this.job.current_role) ? this.job.current_role : product_process.current_role;
        scheduler.checked = true;
        scheduler.product_process_id = (product_process) ? product_process.id : null;
        if (!product_process.schedulers) {
            product_process.schedulers = [];
        }
        const length = product_process.schedulers.push(scheduler);
        scheduler.order_index = length - 1;
        this.product_process_update(product_process, scheduler);
        this.datepicker_update();
        this.onChangeTimeout();
    }

    public removeScheduler(product_process: ProductProcessModel, index: number, scheduler: SchedulerModel, alert?: boolean): void {
        if (product_process.schedulers && product_process.schedulers.length > 0 && index > -1) {
            if (alert === false) {
                if (scheduler && scheduler.id) {
                    this.loader.show();
                    this.modelApi.delete(scheduler)
                        .subscribe((response: any): void => {
                            product_process.schedulers.splice(index, 1);
                            this.check_scheduler_date();
                            this.datepicker_update();
                            this.onChangeTimeout();
                            this.loader.hide();
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                } else {
                    product_process.schedulers.splice(index, 1);
                    this.check_scheduler_date();
                    this.datepicker_update();
                    this.onChangeTimeout();
                }
            } else {
                this.swal.confirm('ยืนยันการยกเลิกช่วงเวลานี้ใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            if (scheduler && scheduler.id) {
                                this.loader.show();
                                this.modelApi.delete(scheduler)
                                    .subscribe((response: any): void => {
                                        product_process.schedulers.splice(index, 1);
                                        this.check_scheduler_date();
                                        this.datepicker_update();
                                        this.onChangeTimeout();
                                        this.loader.hide();
                                    }, error => {
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            } else {
                                product_process.schedulers.splice(index, 1);
                                this.check_scheduler_date();
                                this.datepicker_update();
                                this.onChangeTimeout();
                            }
                        } else {
                            //
                        }
                    });
            }
        }
    }

    public addMoreProductProcess(
        process?: ProcessModel,
        editable?: boolean
    ): void {
        //
        if (this.disabled) {
            return;
        }

        if (this.product.product_processes && this.product.product_processes.length > 0) {
            this.swal.input('ลำดับการแทรกกระบวนการ', 'ลำดับ')
                .then((result: any): void => {
                    this.insert_product_process(process, editable, result);
                });
        } else {
            //
            this.insert_product_process(process, editable);
        }
    }

    private insert_product_process(
        process?: ProcessModel,
        editable?: boolean,
        index?: number
    ): void {
        let product_process: ProductProcessModel;
        product_process = new ProductProcessModel();
        product_process.checked = true;
        product_process.current_role = this.job.current_role;
        if (process) {
            product_process.process = process;
        }
        product_process.editable = (editable === true || editable === null || editable === undefined) ? true : false;
        if (index > -1) {
            this.product.product_processes.splice((index - 1), 0, product_process);
        } else {
            const prepare_store_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'prepare-store');
            const finished_good_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'finish-good');
            const packing_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'packing');
            const shipping_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'shipping');
            const assembly_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'assembly');
            const qc_final_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'qc-final');
            const qc_process_index: number = this.product.product_processes.findIndex(i => i.process.slug === 'qc-process');
            if (prepare_store_index > -1) {
                this.product.product_processes.splice(prepare_store_index, 0, product_process);
            } else if (qc_process_index > -1) {
                this.product.product_processes.splice(qc_process_index, 0, product_process);
            } else if (assembly_index > -1) {
                this.product.product_processes.splice(assembly_index, 0, product_process);
            } else if (qc_final_index > -1) {
                this.product.product_processes.splice(qc_final_index, 0, product_process);
            } else if (finished_good_index > -1) {
                this.product.product_processes.splice(finished_good_index, 0, product_process);
            } else if (packing_index > -1) {
                this.product.product_processes.splice(packing_index, 0, product_process);
            } else if (shipping_index > -1) {
                this.product.product_processes.splice(shipping_index, 0, product_process);
            } else {
                this.product.product_processes.push(product_process);
            }
        }

        if (product_process.checked) {
            this.addCalendarScheduler(product_process, product_process.scheduler);
        }

        this.addMoreScheduler(product_process);

        product_process.adding = true;
        setTimeout(() => {
            product_process.adding = false;
        }, 500);

        this.onChangeTimeout();
    }

    public viewFVD(product_process: ProductProcessModel): void {
        this.current_product_process = product_process;
        this.current_fvd = (product_process && product_process.fvd && product_process.fvd.id)
            ? product_process.fvd : this.initFVD(product_process);
        setTimeout(() => {
            this.smartModal.open('fvdModal');
        }, 300);
    }

    public initFVD(product_process: ProductProcessModel): FVD {
        let fvd: FVD;
        fvd = {
            details: '',
            materials: [],
            suppliers: [],
            documents: []
        };
        for (let i = 0; i < MAX_MATERIALS; i++) {
            if (this.product.materials[i] && this.product.materials[i].id) {
                fvd.materials.push(this.product.materials[i]);
            } else {
                fvd.materials.push(new MaterialModel());
            }
        }

        for (let i = 0; i < MAX_SUPPLIERS; i++) {
            let supplier: SupplierModel;
            supplier = new SupplierModel();
            fvd.suppliers.push(supplier);
        }

        if (!fvd.documents) {
            fvd.documents = [];
        }

        if (product_process && product_process.process && product_process.process.id) {
            // fvd.process.clone(product_process.process);
            fvd.process = product_process.process;
            fvd.process_id = product_process.process.id;

            fvd.modelable = product_process.modelable;
            fvd.modelable_id = product_process.modelable_id;
            fvd.modelable_type = product_process.modelable_type;
            console.log(fvd.modelable_type, fvd.modelable_id);
        } else {
            fvd.process = new ProcessModel();
        }

        fvd.job_id = (this.job) ? this.job.id : (product_process && product_process.job_id) ? product_process.job_id : null;

        return fvd;
    }

    /*public onFVDChange(product_process: ProductProcessModel, e?: any): void {
        setTimeout(() => {
            if (product_process && product_process.is_fvd === true) {
                this.current_product_process = product_process;
                this.smartModalService.open('fvdModal');
            } else {
                //
            }
        }, 0);
    }*/

    public addCalendarScheduler(product_process: ProductProcessModel, scheduler: SchedulerModel): void {
        // scheduler.editable = true;
        // scheduler.update(product_process);
        // this.calendar_schedulers.push(scheduler);
        // this.calendarViewer.addScheduler(scheduler);
    }

    public addRemark(product_process: ProductProcessModel): void {
        this.current_product_process = product_process;
        this.plannerRemark.open(this.current_product_process.description);
        // this.smartModalService.open('remarkModal');
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.current_product_process) {
            this.current_product_process.description = e.remark_message;
            this.onChangeTimeout();
        }
    }

    public onFVDSubmit(e?: any): void {
        this.onFVDModalSubmit.emit(e);
    }

    public toggleScheduler(product_process: ProductProcessModel, i: number, scheduler: SchedulerModel): void {
        scheduler.checked = !scheduler.checked;
        this.check_scheduler_date();
        this.onChangeTimeout();
        setTimeout(() => {
            this.product_process_and_scheduler_update(product_process);
        }, 300);
    }

    public onSelectIncotermCode(product_process: ProductProcessModel, e: any): void {
        product_process.modelable_id = (e && e.id) ? e.id : null;
        product_process.modelable_type = (e && e.id) ? 'App\\Incoterm' : null;
        product_process.update();
        this.onChangeTimeout();
    }

    public onMachineChange(product_process: ProductProcessModel, e: any): void {
        product_process.machine_id = (e && e.id) ? e.id : null;
        this.onChangeTimeout();
    }

    public onManChange(product_process: ProductProcessModel, e: any): void {
        product_process.user_id = (e && e.id) ? e.id : null;
        this.onChangeTimeout();
    }

    public onNgModelChange(e?: any): void {
        this.onChangeTimeout();
    }

    public onModelableChange(modelable: any, e: any): void {
        if (modelable) {
            modelable.modelable_id = (e && e.id) ? e.id : null;
        }
    }

    public onPackagingChange(product_process: ProductProcessModel, e: any): void {
        product_process.modelable_id = (e && e.id) ? e.id : null;
        product_process.modelable_type = (e && e.id) ? 'App\\Packaging' : null;
        product_process.update();
        this.onChangeTimeout();
    }

    private onChangeTimeout(): void {
        this.clearOnChangeTimeout();
        this.onchange_timeout = setTimeout(() => {
            this.onChange.emit();
            this.clearOnChangeTimeout();
        }, 500);
    }

    private clearOnChangeTimeout(): void {
        if (this.onchange_timeout) {
            clearTimeout(this.onchange_timeout);
            this.onchange_timeout = null;
        }
    }

    public onProductProcessSelect(product_process: ProductProcessModel, i: number, e: any): void {
        product_process.process_id = (e && e.id) ? e.id : null;
        product_process.process_slug = (e && e.slug) ? e.slug : null;
        if (product_process && product_process.process_slug) {
            switch (product_process.process_slug) {
                case 'hardening':
                    product_process.modelable = new HardeningModel();
                    break;
                case 'cosmetic':
                    product_process.modelable = new CosmeticModel();
                    break;
                case 'packing':
                    product_process.modelable = new PackagingModel();
                    break;
                case 'shipping':
                    product_process.modelable = new ShippingModel();
                    break;
            }
            if (product_process.modelable && product_process.modelable.model_name) {
                product_process.modelable_type = product_process.modelable.model_name;
            } else {
                //
            }
        } else {
            product_process.modelable = {};
            product_process.modelable_type = null;
        }
        if (product_process.process_id) {
            product_process.machine = new MachineModel();
            product_process.user = new UserModel();
        }
        product_process.update();
        this.product_process_and_scheduler_update(product_process);
        this.onChangeTimeout();
    }

    public onClearTypeahead(model: any, model_field_name: string, field_name: string): void {
        if (model && field_name) {
            delete model[field_name];
        }
        if (model_field_name === 'process' || model_field_name === 'machine' || model_field_name === 'user') {
            this.product_process_and_scheduler_update(model);
        } else {
            //
        }
    }

    public onSchedulerChange(product_process: ProductProcessModel, e?: any): void {
        if (product_process) {
            this.product_process_and_scheduler_update(product_process);
            // this.calendarViewer.renderEvents();
        } else {
            //
        }
        this.onChangeTimeout();
    }

    public onDeleteTypeahead(product_process: ProductProcessModel): void {
        if (product_process) {
            this.product_process_and_scheduler_update(product_process);
        }
    }

    private init_product_processes(): void {
        if (this.product.product_processes && this.product.product_processes.length > 0) {
            for (let i = 0; i < this.product.product_processes.length; i++) {
                const product_process: ProductProcessModel = this.product.product_processes[i];
                if (product_process && product_process.user_id === -1) {
                    product_process.user = new UserModel();
                    product_process.user.id = -1;
                    product_process.user.full_name = 'N/A';
                }
                if (product_process && product_process.machine_id === -1) {
                    product_process.machine = new MachineModel();
                    product_process.machine.id = -1;
                    product_process.machine.machine_name = 'N/A';
                }
                if (!product_process.schedulers || product_process.schedulers.length === 0) {
                    this.addMoreScheduler(product_process);
                }
                this.product_process_and_scheduler_update(product_process);
            }
        }
    }

}
