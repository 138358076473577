import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CustomerModel} from '../../../../models/customer.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {ConstService} from '../../../../services/const.service';
import {SwalService} from '../../../../services/swal.service';

@Component({
    selector: 'app-create-customer-component',
    templateUrl: 'createCustomer.component.html',
    styleUrls: ['createCustomer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

    public customer: CustomerModel;
    public is_new: boolean;

    constructor(private modelApi: ModelApi, private swal: SwalService, public bsModalRef: BsModalRef, private constService: ConstService) {
        this.customer = new CustomerModel();
        this.customer.branch = 'สำนักงานใหญ่';
        this.is_new = true;
    }

    ngOnInit(): void {
        setTimeout(() => {
            /*this.constService.getStateList()
                .then((state_list: any): void => {
                    this.state_list = state_list;
                }, error => {
                    //
                });*/

            if (this.bsModalRef.content && this.bsModalRef.content.customer) {
                this.customer = this.bsModalRef.content.customer;
                this.customer.ranking = 5;
                this.is_new = this.customer.isNew;
            }

            /*this.constService.getCountryList()
                .then((country_list: any): void => {
                    this.country_list = country_list;
                }, error => {
                    //
                });*/
        }, 0);
    }

    private onUploadedSuccess(data: any): void {
        if (data && data.preview) {
            this.customer.avatar = data.preview;
        }
    }

    private onUploadError(data: any): void {
        //
    }

    private removeAvatar(): void {
        this.customer.avatar = '';
    }

    public delete(): void {
        if (this.customer) {
            this.swal.confirm('คุณต้องการลบรายการลูกค้า "' + this.customer.name + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.modelApi.delete(this.customer)
                            .subscribe(() => {
                                this.bsModalRef.hide();
                                this.swal.success('ลบรายการลูกค้า "' + this.customer.name + '" สำเร็จแล้ว');
                            }, error => {
                                if (error && error.message) {
                                    this.swal.danger(error.message);
                                } else {
                                    this.swal.danger(JSON.stringify(error));
                                }
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            this.modelApi.create(this.customer, [
                'name', 'tax_no', 'branch', 'ranking', 'credit_terms', 'type_note'
            ]).subscribe((response: any): void => {
                this.bsModalRef.hide();
            }, error => {
                if (error && error.message) {
                    this.swal.danger(error.message);
                } else {
                    this.swal.danger(JSON.stringify(error));
                }
            });
        } else {
            //
        }
    }

}
