import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {DivApiDirective} from '../../../now/divApi';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {map} from 'rxjs/operators';
import {AppService} from '../../../app/app.service';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);
import HC_solidgauge from 'highcharts/modules/solid-gauge';
import {DecimalPipe} from '@angular/common';
import {CustomerModel} from '../../../models/customer.model';
HC_solidgauge(Highcharts);

@Component({
    selector: 'sale-report-page',
    templateUrl: 'saleReport.page.html',
    styleUrls: ['saleReport.page.scss'],
    providers: [DecimalPipe]
})
export class SaleReportPage implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;
    public divApi: DivApiDirective;

    public date1: any;
    public date2: any;
    public customer_id: any;
    public getting: boolean;

    public rowData1: any;
    public rowData2: any;
    public rowData3: any;
    public rowData4: any;
    public sum_data1: number;
    public sum_data2: number;
    public sum_data3: number;
    public sum_data4: number;
    public sum_data5: number;
    public sum_data6: number;
    public chartData2: any[];
    public tmp_customer: CustomerModel;

    public keyword: string;
    public date: any;
    public current_view: string;
    public ready: boolean;
    public current_zoom: number;
    public columnDefs: any;
    public columnDefs3: any;
    public columnDefs4: any;
    public jobs: any[];
    public cellStyle: any;
    public expected_percentage: number;
    public ng_percentage: number;
    public num_expected: number;
    public num_unexpected: number;
    public unexpected: number;
    public current_date: any;
    public chartOptions: any;
    public chartOptions2: any;
    public item_data: any[];
    public Highcharts: typeof Highcharts = Highcharts;

    constructor(
        private loader: LoaderService,
        private api: Api,
        private appService: AppService,
        private decimalPipe: DecimalPipe
    ) {
        //
        this.current_view = 'MONTHLY';
        this.date1 = moment(new Date());
        this.date2 = moment(new Date()).add(-1, 'year');

        this.current_zoom = 100;
        this.rowData1 = [];
        this.rowData2 = [];
        this.rowData3 = [];
        this.rowData4 = [];
        this.columnDefs = [];
        this.jobs = [];

        this.tmp_customer = new CustomerModel();

        this.cellStyle = (params) => {
            if (params && params.data && params.data.unexpected === true) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else {
                return {
                    'background-color': '#ffffff'
                };
            }
        };

        this.item_data = [];
        this.columnDefs = [
            {
                editable: false,
                headerName: '#',
                field: 'index',
                width: 40
            },
            {
                editable: false,
                headerName: 'TAX INVOICE NO',
                field: 'tax_invoice_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Date',
                field: 'datetime',
                width: 120
            },
            {
                editable: false,
                headerName: 'Customer',
                field: 'customer_name',
                minWidth: 240,
                suppressSizeToFit: true,
                cellStyle: {
                    // 'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Grand Total',
                field: 'grand_total',
                width: 120,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            }
        ];
        this.columnDefs3 = [
            {
                editable: false,
                headerName: '#',
                field: 'index',
                width: 40
            },
            {
                editable: false,
                headerName: 'S/N',
                field: 'product_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Product Name',
                field: 'name',
                minWidth: 240,
                suppressSizeToFit: true,
                cellStyle: {
                    // 'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'QT NO',
                field: 'quotation_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Sent Date',
                field: 'sent_date',
                width: 120
            },
            {
                editable: false,
                headerName: 'PO NO',
                field: 'purchase_order_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Date',
                field: 'started_at',
                width: 120
            }
        ];
        this.columnDefs4 = [
            {
                editable: false,
                headerName: '#',
                field: 'index',
                width: 40
            },
            {
                editable: false,
                headerName: 'S/N',
                field: 'product_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'JOB NO',
                field: 'job_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Purchase Order NO',
                field: 'purchase_order_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Loaded Date',
                field: 'created_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'Sale Estimated Price',
                field: 'sale_estimated_price_text',
                width: 120,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Planning Estimated Price',
                field: 'planning_estimated_price_text',
                width: 120,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Diff Price',
                field: 'diff_price',
                width: 120,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            }
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
            // this.refresh();
        });
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public getData(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.rowData1 = [];
            this.rowData2 = [];
            this.rowData3 = [];
            this.rowData4 = [];
            this.sum_data1 = this.sum_data2 = this.sum_data3 = this.sum_data4 = this.sum_data5 = this.sum_data6 = 0;
            this.jobs = [];
            this.ready = false;
            this.api.request('report/sale', 'POST', {}, {
                date1       : (moment.isMoment(this.date1)) ? this.date1.format('YYYY-MM-DD') : this.date1,
                date2       : (moment.isMoment(this.date2)) ? this.date2.format('YYYY-MM-DD') : this.date2,
                view        : this.current_view,
                customer_id : (this.customer_id) ? this.customer_id : '',
            })
                .pipe(
                    map((response: any): void => {
                        if (response && response.success === true) {
                            return response.data;
                        }
                        return null;
                    })
                )
                .toPromise()
                .then((data: any): void => {
                    if (data) {
                        if (data && data[0] && data[0].data1 && data[0].data1.items) {
                            for (const item of data[0].data1.items) { // kpi1
                                const started_at = (item.started_at) ? item.started_at.split(' ')[0] : '';
                                this.rowData1.push({
                                    index               : this.rowData1.length + 1,
                                    tax_invoice_no      : item.tax_invoice_no,
                                    customer_name       : (item.customer) ? item.customer.name : 'UNKNOW',
                                    grand_total         : this.decimalPipe.transform(item.grand_total, '.2-4'),
                                    datetime            : started_at,
                                });
                            }
                            this.sum_data1 = data[0].data1.grand_total;
                        }
                        if (data && data[0] && data[0].data2 && data[0].data2.items) {
                            for (const item of data[0].data2.items) { // kpi1
                                const started_at = (item.started_at) ? item.started_at.split(' ')[0] : '';
                                this.rowData2.push({
                                    index               : this.rowData2.length + 1,
                                    'tax_invoice_no'    : item.tax_invoice_no,
                                    'customer_name'     : (item.customer) ? item.customer.name : 'UNKNOW',
                                    'grand_total'       : this.decimalPipe.transform(item.grand_total, '.2-4'),
                                    'datetime'          : started_at,
                                });
                            }
                            this.sum_data2 = data[0].data2.grand_total;
                        }
                        this.sum_data3 = 0;
                        this.sum_data4 = 0;
                        if (data && data[1] && data[1].data) {
                            for (const value of data[1].data) {
                                this.rowData3.push({
                                    index               : this.rowData3.length + 1,
                                    product_no        : value.product_no,
                                    name              : value.name,
                                    quotation_no      : value.quotation_no,
                                    sent_date         : value.sent_date,
                                    purchase_order_no : (value.purchase_order_no) ? value.purchase_order_no : '',
                                    started_at        : value.started_at,
                                });
                                this.sum_data3++;
                                if (value.purchase_order_no) {
                                    this.sum_data4++;
                                }
                            }
                        }
                        this.sum_data5 = 0;
                        this.sum_data6 = 0;
                        if (data && data[2] && data[2].data) {
                            for (const dat of data[2].data) { // kpi3
                                if (dat) {
                                    const created_at = (dat.created_at) ? dat.created_at.split(' ')[0] : '';
                                    this.rowData4.push({
                                        index                           : this.rowData4.length + 1,
                                        'created_at'                    : created_at,
                                        'product_no'                    : dat.product_no,
                                        'job_no'                        : dat.job_no,
                                        'purchase_order_no'             : dat.purchase_order_no,
                                        'sale_estimated_price'          : dat.sale_estimated_price,
                                        'planning_estimated_price'      : dat.planning_estimated_price,
                                        'sale_estimated_price_text'     : this.decimalPipe.transform(dat.sale_estimated_price, '.2-4'),
                                        'planning_estimated_price_text' : this.decimalPipe.transform(dat.planning_estimated_price, '.2-4'),
                                        'diff_price'                    : this.decimalPipe.transform(Math.abs(dat.planning_estimated_price - dat.sale_estimated_price), '.2-4')
                                    });
                                    this.sum_data5++;
                                    if (dat.sale_estimated_price > 0) {
                                        if (dat.planning_estimated_price - dat.sale_estimated_price > 0) {
                                            this.sum_data6++;
                                        }
                                    }
                                }
                            }
                        }
                        this.chartData2 = [
                            {
                                name: 'ORDER',
                                data: []
                            },
                            {
                                name: 'INVOICE',
                                data: []
                            }
                        ];
                        if (data && data[3] && data[3].data1 && data[3].data2) {
                            for (let i = 0; i < 12; i++) {
                                if (!this.chartData2[0].data[i]) {
                                    this.chartData2[0].data.push(0);
                                }
                                if (data[3].data1 && data[3].data1[i + 1]) {
                                    const value = data[3].data1[i + 1];
                                    if (value) {
                                        for (const val of value) {
                                            if (val) {
                                                const grand_total = val.grand_total;
                                                this.chartData2[0].data[i] += grand_total;
                                            }
                                        }
                                    }
                                }
                                if (!this.chartData2[1].data[i]) {
                                    this.chartData2[1].data.push(0);
                                }
                                if (data[3].data2 && data[3].data2[i + 1]) {
                                    const value = data[3].data2[i + 1];
                                    if (value) {
                                        for (const val of value) {
                                            if (val) {
                                                const grand_total = val.grand_total;
                                                this.chartData2[1].data[i] += grand_total;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    this.chartOptions2 = {
                        chart: {
                            type: 'column',
                            backgroundColor: '#ffffff',
                            width: window.innerHeight
                        },
                        xAxis: {
                            categories: [
                                'มกราคม',
                                'กุมภาพันธ์',
                                'มีนาคม',
                                'เมษายน',
                                'พฤษภาคม',
                                'มิถุนายน',
                                'กรกฎาคม',
                                'สิงหาคม',
                                'กันยายน',
                                'ตุลาคม',
                                'พฤศจิกายน',
                                'ธันวาคม'
                            ],
                            crosshair: true
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: null
                            }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                '<td style="width:24px;">&nbsp;</td>' +
                                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                        series: this.chartData2
                    };

                    this.chartOptions = {
                        chart: {
                            type: 'solidgauge',
                            backgroundColor: '#ffffff',
                            width: '320'
                        },
                        tooltip: {
                            enabled: false,
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        pane: {
                            startAngle: 0,
                            endAngle: 360,
                            background: [{ // Track for Move
                                outerRadius: '100%',
                                innerRadius: '78%',
                                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0
                            }]
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        plotOptions: {
                            solidgauge: {
                                dataLabels: {
                                    enabled: false
                                },
                                linecap: 'round',
                                stickyTracking: false,
                                rounded: true
                            }
                        },
                        series: [{
                            name: '% ที่ทำได้',
                            data: [{
                                dataLabels: {
                                    useHTML: true,
                                    enabled: true,
                                    inside: false,
                                    overflow: 'none',
                                    crop: true,
                                    color: Highcharts.getOptions().colors[0],
                                    borderWidth: 0,
                                    y: -60,
                                    style: {
                                        fontFamily: 'Helvetica Neue, Roboto, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'normal'
                                    },
                                    formatter: function() {
                                        return '<table><tr><td style="text-align: center;color:' + this.color + ';">' + this.series.name + '</td></tr><tr><td style="text-align: center;font-size:2em;color:' + this.color + ';font-weight: bold;">' + Highcharts.numberFormat(this.y,2) + '%</td></tr></table>';
                                    }
                                },
                                color: Highcharts.getOptions().colors[0],
                                radius: '100%',
                                innerRadius: '78%',
                                y: (this.sum_data1 * 100) / (this.sum_data2 * 1.05)
                            }]
                        }]
                    };
                    this.ready = true;
                    resolve();
                }, error => {
                    //
                });
        });
        return promise;
    }

    public get_date(): string {
        if (this.current_view === 'YEARLY') {
            return this.date.format('YYYY');
        } else if (this.current_view === 'MONTHLY') {
            return this.date.format('MM/YYYY');
        } else if (this.current_view === 'DAILY') {
            return this.date.format('DD/MM/YYYY');
        }
        return '';
    }

    public onSuccess(e: any): void {
        this.ready = true;
    }

    public onError(e: any): void {
        //
    }

    public onViewChange(e: any): void {
        if (e === 'DAILY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'day');
        } else if (e === 'MONTHLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'month');
        } else if (e === 'QUARTERLY') {
            // this.date1 = moment(new Date());
            // this.date2 = moment(new Date()).add(-1, 'year');
        } else if (e === 'YEARLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'year');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public onDateChange(e: any, date: any): void {
        if (this.current_view === 'DAILY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        } else if (this.current_view === 'MONTHLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
        } else if (this.current_view === 'YEARLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh(): void {
        if (this.getting) {
            //
        } else {
            this.loader.show();
            this.getting = true;
            this.getData()
                .then(() => {
                    this.loader.hide();
                    this.getting = false;
                });
        }
    }

    public onCustomerChange(e: any): void {
        this.customer_id = (e && e.id) ? e.id : '';
        this.refresh();
    }

    public get contentHeight(): string {
        const window_height = window.innerHeight;
        const _height = '' + (((window_height * (100 / this.current_zoom)) - 170 - 24) * .5) + 'px';
        return _height;
    }

}
