import {Model} from '../now/model';
import {Bank} from '../app/api/bank';
import {ContactModel} from './contact.model';
import {DocumentModel} from './document.model';

export class SupplierModel extends Model {

    public name: string;
    public avatar: string;
    public type: number;
    public ranking: number;
    public tax_no: string;
    public branch: string;
    public telephone: string;
    public telephone2: string;
    public fax: string;
    public email: string;
    public website: string;
    public address: string;
    public city: string;
    public state: string;
    public country: string;
    public postal_code: string;
    public credit_terms: number;
    public banks: Bank[];
    public contacts: ContactModel[];
    public documents: DocumentModel[];

    public remark: string;
    public price: number;
    public days: number;
    public _checked: boolean;
    public checked: boolean;

    constructor() {
        super('suppliers', 'App\\Supplier');
    }

}
