import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ReceiptModel} from '../../models/receipt.model';
import {ReceiptDetailComponent} from '../../pages/task/view/receiptDetail/receiptDetail.component';
import {environment} from '../../environments/environment';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {DocumentModel} from '../../models/document.model';
import {SwalService} from '../../services/swal.service';
import {Api} from '../../now/api/api';
import {UploaderComponent} from '../uploader/uploader.component';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'receipt-table-component',
    templateUrl: 'receiptTable.component.html',
    styleUrls: ['receiptTable.component.scss']
})
export class ReceiptTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() receipts: ReceiptModel[];
    @Input() model: any;
    @Input() task: TaskModel;
    
    public current_receipt: ReceiptModel;

    constructor(
        public viewer: Viewer,
        private modal: ModalService,
        private swal: SwalService,
        private api: Api,
    ) {
        this.receipts = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewReceiptDetail(receipt: ReceiptModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(ReceiptDetailComponent, {
            receipt     : receipt,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.receipt) {
                    receipt.status = content.receipt.status;
                    // receipt.started_at = content.receipt.started_at;
                    // receipt.grand_total = content.receipt.grand_total;
                    // receipt.remark = content.receipt.remark;
                }
            });
    }

    public viewReceiptPreview(receipt: ReceiptModel, e?: any): void {
        this.viewer.receipt(receipt);
    }

    public createReceipt(): void {
        const receipt: ReceiptModel = new ReceiptModel();
        receipt.grand_total = this.model.grand_total;
        this.modal.show(ReceiptDetailComponent, {
            receipt     : receipt,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.is_create === true) {
                        receipt.clone(content.receipt);
                        // this.receipts.push(receipt);
                        this.model.receipts.push(receipt);
                        this.viewReceiptPreview(receipt);
                    }
                } else {
                    //
                }
            });
    }

    public attachFile(receipt: ReceiptModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_receipt = receipt;
        this.uploader.trigger();
    }

    public viewFile(receipt: ReceiptModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(receipt.document_id);
    }

    public removeFile(receipt: ReceiptModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('receipts/document/delete', 'POST', {}, {
                        id: receipt.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        receipt.document_id = null;
                        receipt.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_receipt && this.current_receipt.id) {
            this.api.request('receipts/document', 'PUT', {}, {
                document: data,
                id: this.current_receipt.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_receipt.document_id = new_document.id;
                    this.current_receipt.document = new_document;
                    this.viewFile(this.current_receipt);
                }
                this.current_receipt = null;
            }, error => {
                this.current_receipt = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
