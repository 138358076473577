import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ShippingModel} from '../../../models/shipping.model';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';

@Component({
    selector: 'shipping-list-component',
    templateUrl: 'shippingList.component.html',
    styleUrls: ['shippingList.component.scss']
})
export class ShippingListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sub: any;
    public timeout: any;
    public shippings: ShippingModel[];
    public keyword: string;

    constructor(private router: Router) {
        super();
        this.shippings = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: e
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_shippings();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let shipping: ShippingModel;
                    shipping = new ShippingModel();
                    shipping.clone(dat);
                    this.shippings.push(shipping);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoShippingView(shipping: ShippingModel): void {
        this.router.navigateByUrl('/database/shipping/' + shipping.id + '/detail');
    }

    private clear_shippings(): void {
        if (this.shippings) {
            this.shippings.splice(0, this.shippings.length);
        } else {
            this.shippings = [];
        }
    }

    public createShipping(): void {
        this.router.navigateByUrl('/database/shipping/create');
    }

}
