import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {TaskModel} from '../../../../models/task.model';
import {SwalService} from '../../../../services/swal.service';
import {Api} from '../../../../now/api/api';
import {JobModel} from '../../../../models/job.model';

@Component({
    selector: 'addRemark-component',
    templateUrl: 'addRemark.component.html',
    styleUrls: ['./addRemark.component.scss']
})
export class AddRemarkComponent implements OnInit {

    public task: TaskModel;
    public job: JobModel;
    public remark_message: string;
    public tag: string;

    public form_role: string;
    public to_role: string;
    public form_process_slug: string;
    public to_process_slug: string;

    constructor(
        public bsModalRef: BsModalRef,
        private swal: SwalService,
        private api: Api
    ) {
        //
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.report) {
                //
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.tag) {
                this.tag = this.bsModalRef.content.tag;
            }
            if (this.bsModalRef && this.bsModalRef.content) {
                this.bsModalRef.content.sent = false;
            }
            if (this.bsModalRef && this.bsModalRef.content) {
                this.form_role = (this.bsModalRef.content.form_role) ? this.bsModalRef.content.form_role : null;
                this.to_role = (this.bsModalRef.content.to_role) ? this.bsModalRef.content.to_role : null;
                this.form_process_slug = (this.bsModalRef.content.form_process_slug) ? this.bsModalRef.content.form_process_slug : null;
                this.to_process_slug = (this.bsModalRef.content.to_process_slug) ? this.bsModalRef.content.to_process_slug : null;
            }
        }, 0);
    }

    public submit(ngForm: NgForm): void {
        if (!this.remark_message) {
            this.swal.confirm('ยืนยันโดยไม่มีหมายเหตุ ใช่หรือไม่?', null, null,
                'ยืนยันโดยไม่มีหมายเหตุ',  'แก้ไข')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.bsModalRef.content.remark_message = this.remark_message;
                        this.bsModalRef.content.sent = true;
                        this.bsModalRef.hide();
                    } else {
                        //
                    }
                });
        } else {
            this.send_report();
        }
    }

    public hide(): void {
        this.bsModalRef.hide();
    }

    private send_report(): void {
        this.api.request('tasks/remark', 'PUT', null, {
            task_id             : this.task.id,
            job_id              : (this.job) ? this.job.id : null,
            remark_message      : this.remark_message,
            form_role           : this.form_role,
            to_role             : this.to_role,
            form_process_slug   : this.form_process_slug,
            to_process_slug     : this.to_process_slug,
            tag                 : this.tag
        }).subscribe((response: any): void => {
            this.bsModalRef.content.remark_message = this.remark_message;
            this.bsModalRef.content.sent = true;
            this.bsModalRef.hide();
        }, error => {
            //
        });
    }

}
