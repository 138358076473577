import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProcessModel} from '../../../../models/process.model';
import {ProcessService} from '../../../../services/process.service';
import {ProductModel} from '../../../../models/product.model';
import {NgForm} from '@angular/forms';
import {TaskModel} from '../../../../models/task.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';
import {NcrModel} from '../../../../models/ncr.model';

@Component({
    selector: 'ncr-modal-component',
    templateUrl: 'ncrModal.component.html',
    styleUrls: ['ncrModal.component.scss']
})
export class NcrModalComponent implements AfterViewInit {

    // public processes: ProcessModel[];
    // public process: ProcessModel;
    public product: ProductModel;
    public task: TaskModel;
    public remark: string;
    public job: JobModel;

    public next_process: ProcessModel;

    public product_amount: number;
    public nc_amount: number;

    public is_complete: boolean;
    public ncr: NcrModel;

    constructor(public bsModalRef: BsModalRef, private processService: ProcessService, private api: Api, private swal: SwalService) {
        this.product = new ProductModel();
        this.task = new TaskModel();
        this.next_process = new ProcessModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.ncr) {
                this.ncr = this.bsModalRef.content.ncr;
                this.product_amount = this.ncr.amount;
                this.nc_amount = this.ncr.amount;
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            const total_amount: number = this.nc_amount + this.good_amount;
            if (!this.nc_amount && !this.good_amount) {
                this.swal.danger('กรุณากรอกข้อมูลให้สำเร็จ');
            } else if (total_amount > this.product_amount) {
                this.swal.danger('ข้อมูลของจำนวนงานดี, จำนวนงานเสีย มีค่ามากกว่าจำนวนงานทั้งหมด');
            } else {
                this.swal.confirm('คุณต้องการสั่งวางแผนงานใหม่ใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.api.request('jobs/revision', 'PUT', {}, {
                                parent_id       : this.ncr.job_id,
                                product_id      : this.product.id,
                                remark          : this.remark,
                                amount          : this.nc_amount
                            }).subscribe((response: any): void => {
                                if (this.good_amount > 0) {
                                    //
                                } else {
                                    this.bsModalRef.content.is_submit = true;
                                    this.bsModalRef.hide();
                                }
                            }, error => {
                                //
                            });
                        } else {
                            //
                        }
                    });
            }
        } else {
            //
        }
    }

    public get good_amount(): number {
        return this.product_amount - this.nc_amount;
    }

}
