import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

    transform(date: any, format?: any, initFormat?: any): any {
        let _initFormat: string;
        let _format: string;
        _initFormat = (initFormat) ? initFormat : 'YYYY-MM-DD HH:mm:ss';
        _format = (format) ? format : 'D MMMM YYYY HH:mmน.';
        if (date) {
            if (date.date) {
                date = date.date;
            }
            let moment_object: any;
            if (moment.isMoment(date)) {
                moment_object = date.clone();
            } else {
                moment_object = moment(date, _initFormat).clone();
            }
            return moment_object.format(_format);
        } else {
            return date;
        }
    }

}
