import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'materialType'
})
export class MaterialTypePipe implements PipeTransform {

    transform(material: string): string {
        if (material === 'ROUND') {
            return 'ตันทรงกลม';
        } else if (material === 'SQUARE') {
            return 'ตันทรงเหลี่ยม';
        } else if (material === 'ANGLE') {
            return 'ตัวแอล';
        } else if (material === 'HEXAGONAL') {
            return 'ตันหกเหลี่ยม';
        } else if (material === 'OCTAGONAL') {
            return 'ตันแปดเหลี่ยม';
        } else if (material === 'CHANNEL') {
            return 'ช่อง';
        } else if (material === 'SHEET') {
            return 'แผ่น';
        } else if (material === 'FLATBAR') {
            return 'แผ่นหนา';
        } else if (material === 'SQUARE_TUBE') {
            return 'กล่อง';
        } else if (material === 'ROUND_TUBE') {
            return 'ท่อ';
        } else if (material === 'HIGH_SPEED') {
            return 'High Speed';
        } else if (material === 'CARBIDE') {
            return 'Carbide';
        }
        return material;
    }

}
