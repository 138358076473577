import {AfterContentInit, Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
    selector: '[upper-case]',
    exportAs: 'upperCaseDirective',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UpperCaseDirective),
            multi: true
        }
    ]
})
export class UpperCaseDirective implements AfterContentInit, ControlValueAccessor {

    onChangeCallback = (_: any) => {};
    onTouchedCallback = () => {};

    constructor(private elementRef: ElementRef, private render: Renderer2) {
        //
    }

    ngAfterContentInit(): void {
        //
    }

    private transform(value: string): string {
        if (value && value !== '' && typeof value === 'string') {
            const _value: string = value.toUpperCase();
            return _value;
        } else if (value === undefined || value === null) {
            return '';
        }
        return value;
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: any): void {
        const _value: string = this.transform(value);
        this.render.setProperty(this.elementRef.nativeElement, 'value', _value);
        this.onChangeCallback(_value);
    }

    @HostListener('blur', [])
    onBlur(): void {
        this.onTouchedCallback();
    }

    writeValue(value: any): void {
        const _value: string = this.transform(value);
        this.render.setProperty(this.elementRef.nativeElement, 'value', _value);
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

}
