// erp modules is here....
import {ERPModalModule} from '../modals/erpModal.module';
import {ERPServiceModule} from '../services/erpService.module';
import {ERPComponentModule} from '../components/erpComponent.module';
import {ERPPipeModule} from '../pipes/erpPipe.module';
// import {ERPFormModule} from '../forms/erpForm.module';

import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {environment} from '../environments/environment';
import { AppComponent } from './app.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import {NavbarComponent} from '../components/navbar/navbar.component';
import {ApiModule} from '../now/api';
import {DivApiModule} from '../now/divApi';
import {AuthComponent} from '../pages/auth/auth.component';
import {RouterModule} from '@angular/router';
import {appRoutes} from './app.routes';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { thLocale } from 'ngx-bootstrap/locale';
import {TaskDisplayComponent} from '../pages/dashboard/taskDisplay/taskDisplay.component';
import {ModelApi} from '../now/modelApi/modelApi';
import {TaskComponent} from '../pages/task/task.component';
import {ProductTaskComponent} from '../pages/task/view/product/productTask.component';
import {FileUploadModule} from 'ng2-file-upload';
import {FormsModule} from '@angular/forms';
import {AddProductComponent} from '../pages/task/view/addProduct/addProduct.component';
import {HomeComponent} from '../pages/home/home.component';
import {AppService} from './app.service';
import {DatabaseComponent} from '../pages/database/database.component';
import {TimerComponent} from '../components/timer/timer.component';
import {DocumentDetailComponent} from '../pages/task/view/documentDetail/documentDetail.component';
import {CadDetailComponent} from '../pages/task/view/cadDetail/cadDetail.component';
import {DrawingDetailComponent} from '../pages/task/view/drawingDetail/drawingDetail.component';
import {FullCalendarComponent} from '../components/fullCalendar/fullCalendar.component';
import {PurchaseOrderDetailComponent} from '../pages/task/view/purchaseOrderDetail/purchaseOrderDetail.component';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {MachineListComponent} from '../pages/database/machine/machineList.component';
import {DatabaseNavComponent} from '../pages/database/nav/databaseNav.component';
import {MachineViewComponent} from '../pages/database/machine/view/machineView.component';
import {CreateCustomerComponent} from '../pages/database/customer/create/createCustomer.component';
import {CustomerViewComponent} from '../pages/database/customer/view/customerView.component';
import {CustomerListComponent} from '../pages/database/customer/customerList.component';
import {InvoiceListComponent} from '../pages/database/invoice/invoiceList.component';
import {PurchaseOrderListComponent} from '../pages/database/purchaseOrder/purchaseOrderList.component';
import {QuotationListComponent} from '../pages/database/quotation/quotationList.component';
import {VendorListComponent} from '../pages/database/vendor/vendorList.component';
import {VendorViewComponent} from '../pages/database/vendor/view/vendorView.component';
import {CreateUserComponent} from '../pages/database/user/create/createUser.component';
import {UserListComponent} from '../pages/database/user/userList.component';
import {UserViewComponent} from '../pages/database/user/view/userView.component';
import {HardeningListComponent} from '../pages/database/hardening/hardeningList.component';
import {HardeningViewComponent} from '../pages/database/hardening/view/hardeningView.component';
import {CriticalPointListComponent} from '../pages/database/criticalPoint/criticalPointList.component';
import {CriticalPointViewComponent} from '../pages/database/criticalPoint/view/criticalPointView.component';
import {PackagingListComponent} from '../pages/database/packaging/packagingList.component';
import {PackagingViewComponent} from '../pages/database/packaging/view/packagingView.component';
import {ProcessListComponent} from '../pages/database/process/processList.component';
import {ProcessViewComponent} from '../pages/database/process/view/processView.component';
import {ShippingListComponent} from '../pages/database/shipping/shippingList.component';
import {AuthService} from '../now/user/auth.service';
import {TaskListComponent} from '../pages/task/list/taskList.component';
import {QuotationSelectorComponent} from '../pages/task/view/quotationSelector/quotationSelector.component';
import {RootNavComponent} from '../pages/rootNav/rootNav.component';
import {QuotationTaskComponent} from '../pages/task/quotation/quotationTask.component';
import {PlanningTaskComponent} from '../pages/task/planning/planningTask.component';
import {ModalService} from '../services/modal.service';
import {AddRemarkComponent} from '../pages/task/view/addRemark/addRemark.component';
import {ViewRemarkComponent} from '../pages/task/view/viewRemark/viewRemark.component';
import {CalendarModelViewComponent} from '../pages/calendarModel/calendarModelView.component';
import {AbsenceModalComponent} from '../pages/database/user/absence/absenceModal.component';
import {LeaveModalComponent} from '../pages/database/user/leave/leaveModal.component';
import {LateModalComponent} from '../pages/database/user/late/lateModal.component';
import {FireModalComponent} from '../pages/database/user/fire/fireModal.component';
import {TypeaheadModalComponent} from '../components/typeahead/modal/typeaheadModal.component';
import {RepairModalComponent} from '../modals/repair/repairModal.component';
import {PageErrorComponent} from '../pages/pageError/pageError.component';
import {ProdTaskComponent} from '../pages/task/prod/prodTask.component';
import {CompleteModalComponent} from '../pages/task/prod/complete/completeModal.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {RequirementTaskComponent} from '../pages/task/requirement/requirementTask.component';
import {DocumentComponent} from '../modals/document/document.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {DragulaModule} from 'ng2-dragula';
import {AxisModalComponent} from '../pages/database/machine/axisModal/axisModal.component';
import {MethodModalComponent} from '../pages/database/machine/methodModal/methodModal.component';
import {PurchaseTaskComponent} from '../pages/task/purchase/purchaseTask.component';
import {SupportBarComponent} from '../components/supportBar/supportBar.component';
import {RequestTaskComponent} from '../pages/request/requestTask.component';
import {RequestEngTaskComponent} from '../pages/request/eng/requestEngTask.component';
import {RequestFMTTaskComponent} from '../pages/request/fmt/requestFMTTask.component';
import {RequestStoreTaskComponent} from '../pages/request/store/requestStoreTask.component';
import {RequestCRDTaskComponent} from '../pages/request/crd/requestCRDTask.component';
import {DeliveryTaskComponent} from '../pages/task/delivery/deliveryTask.component';
import {NCRTaskComponent} from '../pages/task/ncr/ncrTask.component';
import {PrepareTaskComponent} from '../pages/task/prepare/prepareTaskComponent';
import {PrepareStoreTaskComponent} from '../pages/task/prepareStore/prepareStoreTask.component';
import {RemarkModalComponent} from '../modals/remarkModal/remarkModal.component';
import {ReportModalComponent} from '../modals/reportModal/reportModal.component';
import {TaxInvoiceTaskComponent} from '../pages/task/taxInvoice/taxInvoiceTask.component';
import {CarrierListComponent} from '../pages/database/carrier/carrierList.component';
import {CarrierViewComponent} from '../pages/database/carrier/view/carrierView.component';
import {ShippingViewComponent} from '../pages/database/shipping/view/shippingView.component';
import {IncotermListComponent} from '../pages/database/incoterm/incotermList.component';
import {IncotermViewComponent} from '../pages/database/incoterm/view/incotermView.component';
import {AccountingTaskComponent} from '../pages/task/accounting/accountingTask.component';
import {TaskJobsComponent} from '../pages/task/view/taskJobs/taskJobs.component';
import {NcrModalComponent} from '../pages/task/ncr/ncrModal/ncrModal.component';
import {StoreComponent} from '../pages/store/store.component';
import {StoreTypeComponent} from '../pages/store/storeType/storeType.component';
import {FindProductComponent} from '../modals/findProduct/findProduct.component';
import {FindQuotationComponent} from '../modals/findQuotation/findQuotation.component';
import {ApprovedListComponent} from '../modals/approvedList/approvedList.component';
import {DepositDetailComponent} from '../pages/task/view/depositDetail/depositDetail.component';
import {MaterialCreateModal} from '../pages/task/view/product/materialCreate/materialCreate.modal';
import {PrepareModalComponent} from '../pages/task/prepare/prepareModal/prepareModal.component';
import {FindRequirementComponent} from '../modals/findRequirement/findRequirement.component';
import {DocumentTypeModal} from '../pages/task/requirement/documentType/documentType.modal';
import {NgSelectModule} from '@ng-select/ng-select';
import {InvoiceDetailComponent} from '../pages/task/view/invoiceDetail/invoiceDetail.component';
import {TaxInvoiceDetailComponent} from '../pages/task/view/taxInvoiceDetail/taxInvoiceDetail.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ReceiptDetailComponent} from '../pages/task/view/receiptDetail/receiptDetail.component';
import {RejectedTaskComponent} from '../pages/task/rejected/rejectedTask.component';
import {StartModalComponent} from '../pages/task/prod/start/startModal.component';
import {EndModalComponent} from '../pages/task/prod/end/endModal.component';
import {PurchaseOrderTaskComponent} from '../pages/task/purchaseOrder/purchaseOrderTask.component';
import {PricingInquiryDocumentDetailComponent} from '../pages/task/purchase/pricingInquiryDocumentDetail/pricingInquiryDocumentDetail.component';
import {CosmeticListComponent} from '../pages/database/cosmetic/cosmeticList.component';
import {CosmeticViewComponent} from '../pages/database/cosmetic/view/cosmeticView.component';
import {PlannerComponent} from '../pages/task/planning/planner/planner.component';
import {FVDTaskComponent} from '../pages/task/fvd/fvdTask.component';
import {RequestFVDTaskComponent} from '../pages/task/fvd/request/requestFVDTask.component';
import {ShippingTaskComponent} from '../pages/task/view/shippingTask/shippingTask.component';
import {AgGridModule} from 'ag-grid-angular';
import {StoreFormComponent} from '../components/storeForm/storeForm.component';
import {StoreListComponent} from '../components/storeList/storeList.component';
import {MaterialTableComponent} from '../components/materialTable/materialTable.component';
import {RemarkModal} from '../modals/remark/remark.modal';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {PackingSlipTaskComponent} from '../pages/task/packingSlip/packingSlipTask.component';
import {InvoiceTaskComponent} from '../pages/task/invoice/invoiceTask.component';
import {ReceiptTaskComponent} from '../pages/task/receipt/receiptTask.component';
import {DepositTaskComponent} from '../pages/task/deposit/depositTask.component';
import {FVDForm} from '../forms/fvd/fvd.form';
import {FVDModal} from '../modals/fvd/fvd.modal';
import {FinishTaskComponent} from '../pages/task/finish/finishTask.component';
import {PaymentDetailComponent} from '../pages/task/view/paymentDetail/paymentDetail.component';
import {DeliveryNoteDetailComponent} from '../pages/task/view/deliveryNoteDetail/deliveryNoteDetail.component';
import {PackingListDetailComponent} from '../pages/task/view/packingListDetail/packingListDetail.component';
import {PaymentTaskComponent} from '../pages/task/payment/paymentTask.component';
import {SupplierListComponent} from '../pages/database/supplier/supplierList.component';
import {SupplierViewComponent} from '../pages/database/supplier/view/supplierView.component';
import {POFVDTaskComponent} from '../pages/task/poFVD/poFVDTask.component';
import {NoticeTaskComponent} from '../pages/task/notice/noticeTask.component';
import {FindPOComponent} from '../modals/findPO/findPO.component';
import {NoticeShippingTaskComponent} from '../pages/task/noticeShipping/noticeShippingTask.component';
import {JobListComponent} from '../pages/database/job/jobList.component';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NZ_I18N, th_TH} from 'ng-zorro-antd';
import th from '@angular/common/locales/th';
import {JobDetailTaskComponent} from '../pages/task/jobDetail/jobDetailTask.component';
import {NgPipesModule} from 'ngx-pipes';
import {ShippingDocumentModal} from '../modals/shippingDocument/shippingDocument.modal';
import {NCRListComponent} from '../pages/database/ncr/ncrList.component';
import {RequestTaskListComponent} from '../pages/task/request/requestTaskList.component';
import {RequisitionStoreComponent} from '../pages/task/request/requisition/requisitionStore.component';
import {PreparedStoreComponent} from '../pages/task/request/prepared/preparedStore.component';
import {ReturnStoreTaskListComponent} from '../pages/task/request/returnStore/returnStoreTaskList.component';
import {ReturnStoreComponent} from '../pages/task/request/return/returnStore.component';
import {RequestPurchaseTaskComponent} from '../pages/request/purchase/requestPurchaseTask.component';
import {RequestPOTaskListComponent} from '../pages/task/request/po/requestPOTaskList.component';
import {PurchaseOrderStoreComponent} from '../pages/task/request/purchaseOrderStore/purchaseOrderStore.component';
import {TrialTaskComponent} from '../pages/task/trial/trialTask.component';
import {ArchiveTaskComponent} from '../pages/task/archive/archiveTask.component';
import {PaymentFormPage} from '../pages/task/paymentForm/paymentForm.page';
import {ReportPage} from '../pages/report/report.page';
import {Viewer} from '../services/viewer';
import {ContactLogListComponent} from '../pages/database/contactLog/contactLogList.component';
import {FVDListComponent} from '../pages/database/fvd/fvdList.component';
import {CalendarAllModelViewComponent} from '../pages/calendarModel/all/calendarAllModelView.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {CreditNoteDetailComponent} from '../pages/task/view/creditNoteDetail/creditNoteDetail.component';
import {DebitNoteDetailComponent} from '../pages/task/view/debitNoteDetail/debitNoteDetail.component';
import {Request2TaskListComponent} from '../pages/task/request/purchase/request2TaskList.component';
import {Purchase2TaskComponent} from '../pages/task/purchase2/purchase2Task.component';
import {ProdReportPage} from '../pages/report/prod/prodReport.page';
import {SaleReportPage} from '../pages/report/sale/saleReport.page';
import {ProductListComponent} from '../pages/database/product/productList.component';
import {TaxInvoiceListComponent} from '../pages/database/taxInvoice/taxInvoiceList.component';
import {PlanningReportPage} from '../pages/report/planning/planningReport.page';
import {ControlReportPage} from '../pages/report/control/controlReport.page';
import {StoreReportPage} from '../pages/report/store/storeReport.page';
import {StoreWarehouseComponent} from '../pages/task/request/return/warehouse/storeWarehouse.component';
import {OverallReportPage} from '../pages/report/overall/overallReport.page';
import {ColumnListModal} from '../pages/report/overall/columnList/columnList.modal';
import {FMTTaskComponent} from '../pages/task/fmt/FMTTask.component';
import {RequestTaskFMTListComponent} from '../pages/task/request/fmt/requestTaskFMTList.component';
import {TaskProgressFMTListComponent} from '../pages/task/request/fmt/progress/taskProgressFMTList.component';
import {TaskCompleteFMTListComponent} from '../pages/task/request/fmt/complete/taskCompleteFMTList.component';
import {FMTListComponent} from '../pages/database/fmt/fmtList.component';
import {ReceiveTaskListComponent} from '../pages/task/list/receive/receiveTaskList.component';
import {ReceiveTaskListModal} from '../pages/task/list/receive/receiveTaskList/receiveTaskList.modal';
import {CustomerListModal} from '../pages/task/list/receive/customerListModal/customerList.modal';
import {PO2TaskListComponent} from '../pages/task/request/po2/po2TaskList.component';
import {AuthGuard} from './guards/auth/auth.guard';
import { TextMaskModule } from 'angular2-text-mask';
import {ProformaListComponent} from '../pages/database/proforma/proformaList.component';
import {MachineTypeModal} from '../modals/machineType/machineType.modal';
import {MachineListModal} from '../modals/machineType/machineList/machineList.modal';
import {TaskApplyFMTListComponent} from '../pages/task/request/fmt/apply/taskApplyFMTList.component';
import {HRTaskComponent} from '../pages/task/hr/HRTask.component';
import {RequestTaskHRListComponent} from '../pages/task/request/hr/requestTaskHRList.component';
import {HRReportPage} from '../pages/report/hr/hrReport.page';
import {CustomerTaskListComponent} from '../pages/task/list/receive/customer/customerTaskList.component';
import {SaleOrderModal} from '../modals/saleOrder/saleOrder.modal';
import {SaleAdminModule} from '../saleAdmin/saleAdmin.module';
import {ERPDirectiveModule} from '../directives/erpDirective.module';
import {DeliveryNoteListComponent} from '../pages/database/deliveryNote/deliveryNoteList.component';
import {PackingSlipListComponent} from '../pages/database/packingSlip/packingSlipList.component';
import {ManufactureListComponent} from '../pages/database/manufacture/manufactureList.component';
import {SaleOrderListComponent} from '../pages/database/saleOrder/saleOrderList.component';
import {FindSaleOrderComponent} from '../modals/findSaleOrder/findSaleOrder.component';

registerLocaleData(th);
defineLocale('th', thLocale);

export function easingLogic(t: number, b: number, c: number, d: number): number {
    // easeInOutExpo easing
    if (t === 0) {
        return b;
    }
    if (t === d) {
        return b + c;
    }
    if ((t /= d / 2) < 1) {
        return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
    }
    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
        AppComponent,

        AuthComponent,
        RootNavComponent,
        PageErrorComponent,
        HomeComponent,
        DashboardComponent,
        TaskDisplayComponent,

        TaskComponent,
        ProductTaskComponent,
        PrepareTaskComponent,
        NoticeTaskComponent,
        PrepareStoreTaskComponent,
        POFVDTaskComponent,
        NoticeShippingTaskComponent,
        TrialTaskComponent,
        AccountingTaskComponent,
        JobDetailTaskComponent,
        TaskListComponent,
        RequestTaskHRListComponent,
        RequestTaskFMTListComponent,
        ReceiveTaskListComponent,
        CustomerTaskListComponent,
        RequestTaskListComponent,
        TaskProgressFMTListComponent,
        TaskCompleteFMTListComponent,
        TaskApplyFMTListComponent,
        Request2TaskListComponent,
        PO2TaskListComponent,
        RequestPOTaskListComponent,
        ReturnStoreTaskListComponent,
        FMTTaskComponent,
        HRTaskComponent,
        ReturnStoreComponent,
        ArchiveTaskComponent,
        RequisitionStoreComponent,
        PurchaseOrderStoreComponent,
        PreparedStoreComponent,
        NCRTaskComponent,
        DeliveryTaskComponent,
        AddProductComponent,
        DocumentDetailComponent,
        PricingInquiryDocumentDetailComponent,
        CadDetailComponent,
        DrawingDetailComponent,
        PurchaseOrderDetailComponent,
        PaymentDetailComponent,
        DepositDetailComponent,
        DeliveryNoteDetailComponent,
        CreditNoteDetailComponent,
        DebitNoteDetailComponent,
        InvoiceDetailComponent,
        TaxInvoiceDetailComponent,
        PackingListDetailComponent,
        ReceiptDetailComponent,
        RejectedTaskComponent,
        RequestTaskComponent,
        RequestEngTaskComponent,
        RequestFMTTaskComponent,
        RequestStoreTaskComponent,
        RequestPurchaseTaskComponent,
        RequestCRDTaskComponent,
        SupportBarComponent,
        CalendarModelViewComponent,
        CalendarAllModelViewComponent,
        CompleteModalComponent,
        StartModalComponent,
        EndModalComponent,
        AbsenceModalComponent,
        ApprovedListComponent,
        TaskJobsComponent,
        ShippingTaskComponent,
        LateModalComponent,
        LeaveModalComponent,
        FireModalComponent,
        CreateCustomerComponent,
        NavbarComponent,
        TypeaheadModalComponent,
        RepairModalComponent,
        DatabaseComponent,
        DatabaseNavComponent,
        MachineListComponent,
        MachineViewComponent,
        CustomerListComponent,
        CustomerViewComponent,
        CarrierListComponent,
        CarrierViewComponent,
        InvoiceListComponent,
        TaxInvoiceListComponent,
        DeliveryNoteListComponent,
        PackingSlipListComponent,
        ManufactureListComponent,
        TimerComponent,
        FullCalendarComponent,
        DocumentTypeModal,
        MaterialCreateModal,
        FVDModal,
        MethodModalComponent,
        AxisModalComponent,
        NcrModalComponent,
        DocumentComponent,
        ReportModalComponent,
        PrepareModalComponent,
        RequirementTaskComponent,

        SaleOrderListComponent,
        QuotationListComponent,
        ProformaListComponent,
        ContactLogListComponent,
        ProductListComponent,
        NCRListComponent,
        FVDListComponent,
        FMTListComponent,
        JobListComponent,

        VendorListComponent,
        VendorViewComponent,

        SupplierListComponent,
        SupplierViewComponent,

        CreateUserComponent,
        UserListComponent,
        UserViewComponent,

        HardeningListComponent,
        HardeningViewComponent,

        CosmeticListComponent,
        CosmeticViewComponent,

        CriticalPointListComponent,
        CriticalPointViewComponent,

        PurchaseOrderListComponent,

        PackagingListComponent,
        PackagingViewComponent,

        ShippingListComponent,
        ShippingViewComponent,

        IncotermListComponent,
        IncotermViewComponent,
        ProcessListComponent,
        ProcessViewComponent,
        QuotationSelectorComponent,

        QuotationTaskComponent,
        PaymentFormPage,
        PaymentTaskComponent,
        TaxInvoiceTaskComponent,
        FinishTaskComponent,
        PackingSlipTaskComponent,
        InvoiceTaskComponent,
        ReceiptTaskComponent,
        DepositTaskComponent,
        PurchaseTaskComponent,
        Purchase2TaskComponent,
        PurchaseOrderTaskComponent,
        PlanningTaskComponent,
        PlannerComponent,
        ProdTaskComponent,
        AddRemarkComponent,
        RemarkModalComponent,
        ShippingDocumentModal,
        SaleOrderModal,
        MachineTypeModal,
        ReceiveTaskListModal,
        CustomerListModal,
        ViewRemarkComponent,
        FVDTaskComponent,
        RequestFVDTaskComponent,

        StoreComponent,
        StoreTypeComponent,
        FindProductComponent,
        FindPOComponent,
        FindQuotationComponent,
        FindSaleOrderComponent,
        FindRequirementComponent,
        StoreFormComponent,
        StoreListComponent,
        MachineListModal,
        MaterialTableComponent,
        RemarkModal,
        StoreWarehouseComponent,

        FVDForm,

        ColumnListModal,

        ReportPage,
        OverallReportPage,
        SaleReportPage,
        ProdReportPage,
        PlanningReportPage,
        ControlReportPage,
        HRReportPage,
        StoreReportPage,
    ],
    entryComponents: [
        PlannerComponent,
        TaskDisplayComponent,
        CreateCustomerComponent,
        SupplierListComponent,
        SupplierViewComponent,
        CreateUserComponent,
        AddProductComponent,
        DocumentDetailComponent,
        PricingInquiryDocumentDetailComponent,
        CadDetailComponent,
        DrawingDetailComponent,
        PurchaseOrderDetailComponent,
        PaymentDetailComponent,
        DepositDetailComponent,
        DeliveryNoteDetailComponent,
        CreditNoteDetailComponent,
        DebitNoteDetailComponent,
        InvoiceDetailComponent,
        TaxInvoiceDetailComponent,
        PackingListDetailComponent,
        ReceiptDetailComponent,
        RejectedTaskComponent,
        RequestTaskComponent,
        RequestEngTaskComponent,
        RequestFMTTaskComponent,
        RequestStoreTaskComponent,
        RequestPurchaseTaskComponent,
        RequestCRDTaskComponent,
        SupportBarComponent,
        DocumentTypeModal,
        MaterialCreateModal,
        FVDModal,
        MethodModalComponent,
        AxisModalComponent,
        NcrModalComponent,
        DocumentComponent,
        ReportModalComponent,
        PrepareModalComponent,
        TypeaheadModalComponent,
        RepairModalComponent,
        AbsenceModalComponent,
        ApprovedListComponent,
        CompleteModalComponent,
        StartModalComponent,
        EndModalComponent,
        TaskJobsComponent,
        ShippingTaskComponent,
        LateModalComponent,
        LeaveModalComponent,
        FireModalComponent,
        QuotationSelectorComponent,
        AddRemarkComponent,
        RemarkModalComponent,
        ShippingDocumentModal,
        SaleOrderModal,
        MachineTypeModal,
        ReceiveTaskListModal,
        CustomerListModal,
        ViewRemarkComponent,
        StoreComponent,
        StoreTypeComponent,
        StoreFormComponent,
        StoreListComponent,
        MachineListModal,
        MaterialTableComponent,
        StoreWarehouseComponent,
        RemarkModal,
        FindProductComponent,
        FindPOComponent,
        FindQuotationComponent,
        FindSaleOrderComponent,
        FindRequirementComponent,

        ColumnListModal,

        FVDForm
    ],
    imports: [
        SaleAdminModule,
        HighchartsChartModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        AgGridModule.withComponents([]),
        PdfViewerModule,
        PerfectScrollbarModule,
        BrowserModule,
        FormsModule,
        TextMaskModule,
        RouterModule.forRoot(appRoutes),
        ApiModule.forRoot({
            apiConfig: {
                host: environment.api_host,
                prefix: environment.prefix
            }
        }),
        DivApiModule,
        FileUploadModule,
        DragulaModule.forRoot(),
        NgxPaginationModule,
        NgPipesModule,
        NgxPageScrollCoreModule.forRoot({
            duration: 250,
            scrollOffset: 200,
            easingLogic: easingLogic
        }),
        NgxPageScrollModule,
        NgSelectModule,
        // Ng2Webstorage.forRoot({
        //     prefix: 'erpsiamtool',
        //     separator: '|',
        //     caseSensitive: true
        // }),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),

        //
        ERPDirectiveModule,
        ERPModalModule,
        ERPServiceModule,
        ERPComponentModule,
        ERPPipeModule
    ],
    exports: [
        //
    ],
    providers: [
        AuthGuard,

        AppService,
        AuthService,
        ModelApi,
        ModalService,
        Viewer,
        {
            provide: APP_BASE_HREF,
            useValue: '/'
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: NZ_I18N,
            useValue: th_TH
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    //
}
