import {Injectable} from '@angular/core';
import {PricingInquiryModel} from '../models/pricingInquiry.model';
import {Api} from '../now/api/api';

@Injectable()
export class PricingInquiryService {

    constructor(private api: Api) {
        //
    }

    public update(pricing_inquiry: PricingInquiryModel, data: any): Promise<PricingInquiryModel> {
        let promise: Promise<PricingInquiryModel>;
        promise = new Promise<PricingInquiryModel>((resolve, reject) => {
            data['id'] = pricing_inquiry.id;
            this.api.request('pricing/inquiries', 'POST', {}, data)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(pricing_inquiry);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public get(id: string): Promise<PricingInquiryModel> {
        let promise: Promise<PricingInquiryModel>;
        promise = new Promise<PricingInquiryModel>((resolve, reject) => {
            this.api.request('pricing/inquiries/' + id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        let pricing_inquiry: PricingInquiryModel;
                        pricing_inquiry = new PricingInquiryModel();
                        pricing_inquiry.clone(response.data);
                        resolve(pricing_inquiry);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

}
