import {Injectable} from '@angular/core';
import * as Pusher from 'pusher-js/dist/web/pusher.js';
import {environment} from '../environments/environment';

@Injectable()
export class PusherService {

    private pusher: any;
    private channels: {};

    constructor() {
        //
    }

    public init(key: string, options?: any): void {
        if (environment.production === true) {
            this.pusher = new Pusher(key, options);
            this.channels = {};
        }
    }

    public trigger(channel_name: string, event_name: string, data: any): any {
        if (environment.production === true) {
            console.log(this.channels[channel_name]);
            return this.channels[channel_name].trigger(event_name, data);
        }
    }

    public subscribe(channel_name: string): any {
        if (environment.production === true) {
            let channel: any;
            channel = this.pusher.subscribe(channel_name);
            // channel.bind(event_name, (data: any): void => method(data));
            this.channels[channel_name] = channel;
        }
    }

    public bind(channel_name: string, event_name: string, method: Function): void {
        if (environment.production === true) {
            this.channels[channel_name].bind(event_name, (data: any): void => method(data));
        }
    }

    public unsubscribe(channal_name: string): void {
        if (environment.production === true) {
            this.pusher.unsubscribe(channal_name);
            delete this.channels[channal_name];
        }
    }

}
