import {Pipe, PipeTransform} from '@angular/core';
import {SchedulerModel} from '../models/scheduler.model';
import * as moment from 'moment';

@Pipe({
    name: 'schedulerOrderBy',
    pure: true
})
export class SchedulerOrderByPipe implements PipeTransform {

    transform(schedulers: SchedulerModel[]): SchedulerModel[] {
        if (schedulers) {
            return schedulers.sort((a: SchedulerModel, b: SchedulerModel) => {
                const tmp_scheduler_a = a;
                const tmp_scheduler_b = b;
                let m1: number;
                let m2: number;
                if (tmp_scheduler_a && tmp_scheduler_a.process &&
                    (
                        tmp_scheduler_a.process.slug === 'shipping'
                        || tmp_scheduler_a.process.slug === 'qc-final'
                        || tmp_scheduler_a.process.slug === 'finish-good'
                        || tmp_scheduler_a.process.slug === 'packing'
                    )
                ) {
                    m1 = +moment(tmp_scheduler_a.end, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD235959');
                } else {
                    m1 = +moment(tmp_scheduler_a.end, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDDHHmmss');
                }
                if (tmp_scheduler_b && tmp_scheduler_b.process &&
                    (
                        tmp_scheduler_b.process.slug === 'shipping'
                        || tmp_scheduler_b.process.slug === 'qc-final'
                        || tmp_scheduler_b.process.slug === 'finish-good'
                        || tmp_scheduler_b.process.slug === 'packing'
                    )
                ) {
                    m2 = +moment(tmp_scheduler_b.end, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD235959');
                } else {
                    m2 = +moment(tmp_scheduler_b.end, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDDHHmmss');
                }
                if (m1 > m2) {
                    return 1;
                } else if (m1 < m2) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        return schedulers;
    }

}
