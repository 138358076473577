import {Model} from '../now/model';

export class BankModel extends Model {

    id: string;
    user_id: string;
    modelable_type: string;
    modelable_id: string;
    bank_name: string;
    bank_address: string;
    bank_code: string;
    swift_code: string;
    bank_account_name: string;
    bank_account_no: string;

    constructor() {
        super('bank', 'App\\Bank');
    }

}
