import {FormModel} from '../now/formModel';
import {ItemModel} from './item.model';
import {Model} from '../now/model';

export class MaterialModel extends FormModel {

    public _checked?: boolean;
    public parent_id: string;
    public item_type: string;
    public modelable_id: string;
    public modelable_type: string;
    public modelable: any;
    public shipping_price: number;
    public profit_percentage: number;
    public children: MaterialModel[];
    public parent: MaterialModel;

    public job_id: string;

    public dateTime: string;

    public item: ItemModel;
    public remark: string;
    public prepared_status: number;

    public material_id: string;
    public search_value: string;
    public replacement_id: string;
    public replacement: MaterialModel;

    public unit: string;

    public material_no: string;
    public name: string;
    public width: number;
    public height: number;
    public length: number;
    public weight: number;
    public amount: number;

    public s_width: number;
    public s_height: number;
    public s_length: number;
    public s_weight: number;
    public s_depth: number;
    public s_diameter: number;
    public s_inner_diameter: number;
    public s_outer_diameter: number;

    public depth: number;

    public status: number;
    public price: number;
    public pivot: any;

    public purchase_status: number;
    public required_at: string;

    public material_type: string;

    public outer_diameter: number;
    public inner_diameter: number;

    constructor(table_name?: string, model_name?: string) {
        super((table_name) ? table_name : 'materials', (model_name) ? model_name : 'App\\Material');
        this.pivot = {};
        this.modelable = {};
        this.item = new ItemModel();
        this.children = [];
    }

    public clone(data: any): Model {
        if (data && data.item) {
            this.item.clone(data.item);
            delete data.item;
        }
        return super.clone(data);
    }

    public get total_price(): number {
        const amount = (this.amount) ? this.amount : 0;
        const price = (this.price) ? this.price : 0;
        const shipping_price = (this.shipping_price) ? this.shipping_price : 0;
        const profit_percentage = (this.profit_percentage) ? this.profit_percentage : 0;
        const val = (price * amount) + shipping_price;
        const finalVal = (val > 0) ? val : 0;
        return finalVal + (finalVal * profit_percentage / 100);
    }

}
