import {Injectable} from '@angular/core';
import {CriticalPointModel} from '../models/criticalPoint.model';
import {Api} from '../now/api/api';

@Injectable()
export class CriticalPointService {

    private critical_points: CriticalPointModel[];

    constructor(private api: Api) {
        this.reset_critical_point_list();
    }

    public set(list: CriticalPointModel[]): void {
        this.reset_critical_point_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const critical_point: CriticalPointModel = new CriticalPointModel();
                    critical_point.clone(dat);
                    this.critical_points.push(critical_point);
                }
            }
        }
    }

    public getCriticalPoints(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.critical_points && this.critical_points.length > 0) {
                for (let i = 0; i < this.critical_points.length; i++) {
                    this.critical_points[i].checked = false;
                }
                resolve(this.critical_points);
            } else {
                this.api.request('criticalpoints', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_critical_point_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.critical_points.push(dat);
                                }
                            }
                            resolve(this.critical_points);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_critical_point_list(): void {
        if (this.critical_points) {
            this.critical_points.splice(0, this.critical_points.length);
        } else {
            this.critical_points = [];
        }
    }

}
