import {AfterViewInit, Component, Input} from '@angular/core';
import {DocumentModel} from '../../../models/document.model';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'remark-table-component',
    templateUrl: 'remarkTable.component.html',
    styleUrls: ['remarkTable.component.scss']
})
export class RemarkTableComponent implements AfterViewInit {

    @Input('remarks') remarks: any[];

    constructor(
        private viewer: Viewer,
    ) {
        this.remarks = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewDocument(doc: DocumentModel): void {
        this.viewer.document(doc);
    }


}
