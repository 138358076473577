import {Injectable} from '@angular/core';
import {JobModel} from '../models/job.model';
import {NCR} from '../app/api/ncr';
import {Api} from '../now/api/api';
import {TaskModel} from '../models/task.model';

@Injectable()
export class JobService {

    constructor(private api: Api) {
        //
    }

    public loadJobBySaleOrder(id, task_id, payment_id) {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/loads/sale_order', 'POST', {}, {
                saleOrderId: id,
                task_id, payment_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public pull(job: JobModel, product_pull_amount?: number, remainder?: number, remark?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/pull', 'POST', {}, {
                id                  : job.id,
                amount              : product_pull_amount,
                remainder           : remainder,
                remark              : remark
            }).subscribe((_response: any): void => {
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public push(job: JobModel, product_push_amount?: number, remainder?: number, remark?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/push', 'POST', {}, {
                id                  : job.id,
                amount              : product_push_amount,
                remainder           : remainder,
                remark              : remark
            }).subscribe((_response: any): void => {
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public productProcessCheck(job_ids: string[]): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/product/process', 'POST', {}, {
                job_ids: job_ids
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public manAndMachineCheck(job_ids: string[], role: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/man/machine', 'POST', {}, {
                job_ids: job_ids,
                role: role
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public materialCheck(job_ids: string[]): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/material', 'POST', {}, {
                job_ids: job_ids
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public changePlan(task: TaskModel, job: JobModel): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            task.current_role = 'control';
            task.process_slug = 'control';
            this.api.request('jobs/plan', 'POST', {
                task_id: task.id,
                job_id: job.id,
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public cancel(job: JobModel, task?: TaskModel, remark?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/cancel', 'POST', {
                job_id  : job.id,
                task_id : (task && task.id) ? task.id : '',
                remark  : remark
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public schedulerComplete(params: any): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/scheduler/complete', 'POST', {}, params)
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public startJobScheduler(job_id: string, task_id?: any): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/scheduler/start', 'POST', {}, {
                id: job_id,
                task_id: task_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public startJob(job_id: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/start', 'POST', {}, {
                id: job_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public loadJobs(product_ids: any[], task_id: string, payment_id: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/loads', 'POST', {}, {
                product_ids: product_ids,
                payment_id: payment_id,
                task_id: task_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public ncrJob(job: JobModel): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            const job_id: string = job.id;
            this.api.request('jobs/ncr', 'PUT', {}, {
                id: job_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public reworkJob(job: JobModel, message?: string, solve_message?: string, ncr?: NCR, task_id?: any): Promise<any> {
        let promise: Promise<NCR[]>;
        promise = new Promise<NCR[]>((resolve, reject) => {
            const job_id: string = job.id;
            this.api.request('jobs/rework', 'POST', {}, {
                id: job_id,
                task_id: task_id,
                ncr_id: (ncr) ? ncr.id : '',
                message: message
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public reportJob(job: JobModel, message?: string): Promise<any> {
        let promise: Promise<NCR[]>;
        promise = new Promise<NCR[]>((resolve, reject) => {
            const job_id: string = job.id;
            this.api.request('jobs/report', 'POST', {}, {
                id      : job_id,
                message : message
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public getNCRs(job: JobModel): Promise<NCR[]> {
        let promise: Promise<NCR[]>;
        promise = new Promise<NCR[]>((resolve, reject) => {
            const job_id: string = job.id;
            this.api.request('jobs/' + job_id + '/ncrs', 'GET', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getNCR(ncr_id: string): Promise<NCR> {
        let promise: Promise<NCR>;
        promise = new Promise<NCR>((resolve, reject) => {
            this.api.request('ncrs/' + ncr_id, 'GET', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    // TODO: change job toggle history to back-end process
    /*public history(job: JobModel, product_pull_amount?: number, remainder?: number, remark?: string, toggle_process_slug?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/history', 'POST', {}, {
                action              : 'hire',
                id                  : job.id,
                product_process_id  : job.current_product_process.id,
                amount              : product_pull_amount,
                remainder           : remainder,
                remark              : remark,
                toggle_process_slug : toggle_process_slug
            }).subscribe((_response: any): void => {
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }*/

}
