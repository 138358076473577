import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {CosmeticModel} from '../../../../models/cosmetic.model';
import {Location} from '@angular/common';

@Component({
    selector: 'cosmetic-view-component',
    templateUrl: 'cosmeticView.component.html',
    styleUrls: ['cosmeticView.component.scss']
})
export class CosmeticViewComponent implements OnInit {

    public cosmetic: CosmeticModel;
    public cosmetic_id: string;
    public current_tab: string;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private location: Location,
                private appService: AppService,
                private modelApi: ModelApi,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.cosmetic = new CosmeticModel();
        this.route.params
            .subscribe(params => {
                this.cosmetic_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getCosmetic()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getCosmetic(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.cosmetic_id) {
                this.api.request('cosmetics/' + this.cosmetic_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.cosmetic.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.cosmetic);
            }
        });
        return promise;
    }

    public save(): void {
        if (!this.cosmetic.name) {
            this.swal.danger('กรุณากรอกชื่อ Cosmetic ');
        } else if (!this.cosmetic.price) {
            this.swal.danger('กรุณากรอกราคา Cosmetic สำหรับการประเมินการผลิต');
        } else if (!this.cosmetic.ranking) {
            this.swal.danger('กรุณาระบุRANK');
        } else {
            this.modelApi.createOrUpdate(this.cosmetic, [
                // profile
                'name', 'price', 'ranking'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/cosmetic/' + this.cosmetic.id + '/detail');
                this.swal.success('บันทึกข้อมูล Cosmetic สำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createCosmetic(): void {
        this.save();
    }

}
