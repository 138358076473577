import {Api} from '../now/api/api';
import {Injectable} from '@angular/core';
import {FVD} from '../app/api/fvd';

@Injectable()
export class FVDService {

    constructor(private api: Api) {
        //
    }

    public complete(fvd: FVD): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('fvds/complete', 'POST', {}, {
                id: fvd.id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public prepare(fvd: FVD): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('fvds/prepare', 'POST', {}, {
                id: fvd.id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public approve1(fvd: FVD, remark: string, task_id?: any): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('fvd/approve1', 'POST', {}, {
                id: fvd.id,
                task_id: task_id,
                remark: remark
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public approve2(fvd: FVD, remark: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('fvd/approve2', 'POST', {}, {
                id: fvd.id,
                remark: remark
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public approve3(fvd: FVD, remark: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('fvd/approve3', 'POST', {}, {
                id: fvd.id,
                remark: remark
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private get_material_ids(fvd: FVD): any[] {
        let ids: any[];
        ids = [];
        if (fvd && fvd.materials) {
            for (let i = 0; i < fvd.materials.length; i++) {
                const mat: any = fvd.materials[i];
                if (mat && mat._checked) {
                    ids.push({
                        id: mat.id,
                        amount: mat.amount
                    });
                }
            }
        }
        return ids;
    }

    private get_supplier_ids(fvd: FVD): any[] {
        let ids: any[];
        ids = [];
        if (fvd && fvd.suppliers) {
            for (let i = 0; i < fvd.suppliers.length; i++) {
                const supplier: any = fvd.suppliers[i];
                if (supplier && supplier.id && supplier._checked) {
                    ids.push({
                        id: supplier.id,
                        price: supplier.price,
                        days: supplier.days,
                        remark: supplier.remark
                    });
                }
            }
        }
        return ids;
    }

    public sync(fvd_id: string): Promise<FVD> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('fvds/' + fvd_id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public removeFVD(fvd: FVD): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('fvds/delete', 'POST', {}, {
                id: fvd.id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public createOrUpdateFVD(fvd: FVD): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            console.log(fvd.modelable_id);
            this.api.request('fvds', 'POST', {}, {
                id                  : (fvd.id) ? fvd.id : '',
                customer_id         : fvd.customer_id,
                process_id          : fvd.process_id,
                job_id              : fvd.job_id,
                product_id          : fvd.product_id,
                product_process_id  : fvd.product_process_id,
                details             : fvd.details,
                amount              : fvd.amount,
                modelable_id        : fvd.modelable_id,
                modelable_type      : fvd.modelable_type,
                material_support    : fvd.material_support,
                required_at         : fvd.required_at,
                description         : fvd.description,
                supplier1_id        : fvd.supplier1_id,
                supplier1_checked   : fvd.supplier1_checked,
                purchase_user_id    : fvd.purchase_user_id,
                approved1_user_id   : fvd.approved1_user_id, // is purchase user
                supplier_document1_id   : fvd.supplier_document1_id,
                price1              : fvd.price1,
                days1               : fvd.days1,
                remark1             : fvd.remark1,
                supplier2_id        : fvd.supplier2_id,
                supplier2_checked   : fvd.supplier2_checked,
                supplier_document2_id   : fvd.supplier_document2_id,
                price2              : fvd.price2,
                days2               : fvd.days2,
                remark2             : fvd.remark2,
                supplier3_id        : fvd.supplier3_id,
                supplier3_checked   : fvd.supplier3_checked,
                supplier_document3_id   : fvd.supplier_document3_id,
                price3              : fvd.price3,
                days3               : fvd.days3,
                remark3             : fvd.remark3,
                warning_description : fvd.warning_description,
                condition           : fvd.condition,
                material_ids        : this.get_material_ids(fvd),
                supplier_ids        : this.get_supplier_ids(fvd),
                supplier_id         : fvd.supplier_id,
                test_report         : fvd.test_report,
                data_inspection     : fvd.data_inspection,
                remark              : fvd.remark,
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

}
