import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ranking'
})
export class RankingPipe implements PipeTransform {

    transform(ranking_number: number): string {
        let tmp_ranking_number: number;
        tmp_ranking_number = +ranking_number;
        if (tmp_ranking_number === 1) {
            return 'S';
        } else if (tmp_ranking_number === 2) {
            return 'A';
        } else if (tmp_ranking_number === 3) {
            return 'B';
        } else if (tmp_ranking_number === 4) {
            return 'C';
        } else if (tmp_ranking_number === 5) {
            return 'D';
        }
        return '';
    }

}
