import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../now/api/api';
import {ProductModel} from '../../../models/product.model';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {SwalService} from '../../../services/swal.service';
import {TaskService} from '../../../services/task.service';
import {TaskModel} from '../../../models/task.model';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'job-detail-task-component',
    templateUrl: 'jobDetailTask.component.html',
    styleUrls: ['jobDetailTask.component.scss']
})
export class JobDetailTaskComponent implements AfterViewInit, OnDestroy {

    public current_tab: string;
    public job: JobModel;
    public job_id: string;
    public product_id: string;
    public product: ProductModel;
    public scheduler_histories: SchedulerHistoryModel[];
    public task: TaskModel

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        private modelApi: ModelApi,
        private viewer: Viewer,
        private swal: SwalService,
        private taskService: TaskService,
    ) {
        //
        this.current_tab = 'info';
        this.task = new TaskModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.init();
                    this.job_id = params['id'];
                    this.getJob(this.job_id)
                        .then(() => {
                            this.task.id = this.job.task_id;
                            /*this.getTask()
                                .then(() => {
                                    //
                                });*/
                        });
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    private init(): void {
        this.job = new JobModel();
    }

    public getTask(): Promise<any> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + this.task.id + '/detail', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.task.clone(response.data);
                        resolve(this.task);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public gotoTask(): void {
        this.api.request('tasks/' + this.job_id + '/job', 'POST', {}, {})
            .subscribe((response: any): void => {
                if (response && response.success === true) {
                    let task: TaskModel;
                    task = new TaskModel();
                    task.clone(response.data);
                    this.taskService.gotoTask(task);
                } else {
                    console.error(response);
                    this.swal.danger('เกิดข้อผิดพลาด');
                }
            }, error => {
                console.error(error);
            });
    }

    public printSchedulers(): void {
        this.viewer.manufacture(this.job, true, true)
            .then(() => {
                //
            });
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve(this.job);
                    } else {
                        resolve(this.job);
                    }
                }, error => {
                    resolve(this.job);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

}
