import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ItemModel} from '../../../../../models/item.model';
import {ModelApi} from '../../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../../services/swal.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {WarehouseModel} from '../../../../../models/warehouse.model';
import {StoreListComponent} from '../../../../../components/storeList/storeList.component';
import {StoreFormComponent} from '../../../../../components/storeForm/storeForm.component';
import {Api} from '../../../../../now/api/api';

@Component({
    selector: 'material-create-modal',
    templateUrl: 'materialCreate.modal.html',
    styleUrls: ['materialCreate.modal.scss']
})
export class MaterialCreateModal implements OnInit {

    @ViewChild(StoreListComponent, { static: false }) storeListComponent: StoreListComponent;
    @ViewChild(StoreFormComponent, { static: false }) storeFormComponent: StoreFormComponent;
    @Input() submit_text: string;
    @Input() type: string;

    public is_getting: boolean;
    public is_got: boolean;

    public canBack: boolean;
    public editable: boolean;
    public viewable: boolean;
    public warehouse: boolean;
    public current_item: ItemModel;
    public search_items: ItemModel[];
    public is_searching: boolean;

    public material: ItemModel;
    public material_model: ItemModel;
    public tmp_amount: number;
    public editabled: boolean;
    public item_id: string;

    public pages = [
        {
            key         : 'atl',
            display     : 'วัสดุอุปกรณ์โรงงาน'
        },
        {
            key         : 'frw',
            display     : 'วัตถุดิบ'
        },
        {
            key         : 'sn',
            display     : 'สินค้าสำเร็จรูป'
        },
        {
            key         : 'fes',
            display     : 'อุปกรณ์สิ้นเปลืองโรงงาน'
        },
        {
            key         : 'fct',
            display     : 'Cutting Tools'
        },
        {
            key         : 'fas',
            display     : 'วัสดุประกอบ'
        },
        {
            key         : 'ost',
            display     : 'ค่าอุปกรณ์ เครื่องเขียน แบบพิมพ์'
        }
    ];

    constructor(
        private modelApi: ModelApi,
        private swal: SwalService,
        private api: Api,
        public bsModalRef: BsModalRef
    ) {
        //
        this.current_item = new ItemModel();
        this.search_items = [];
        this.is_searching = false;
        this.canBack = false;
        this.tmp_amount = 1;
    }

    ngOnInit(): void {
        this.material = new ItemModel();
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.item_id) {
                this.item_id = this.bsModalRef.content.item_id;
                // this.getItem();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.item_name) {
                this.material.name = this.bsModalRef.content.item_name;
            } else {
                //
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.material_model) {
                this.material_model = this.bsModalRef.content.material_model;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.editabled === false) {
                this.editabled = false;
            } else {
                this.editabled = true;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.current_item) {
                this.current_item = this.bsModalRef.content.current_item;
            } else {
                this.current_item = new ItemModel();
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.type) {
                this.type = this.bsModalRef.content.type;
                this.current_item.type = this.type;
            } else {
                this.type = 'sn';
                this.current_item.type = 'sn';
            }
            if (this.bsModalRef && this.bsModalRef.content
                && (this.bsModalRef.content.viewable === true || this.bsModalRef.content.viewable === false)) {
                //
                this.viewable = this.bsModalRef.content.viewable;
            } else {
                this.viewable = false;
            }
            if (this.bsModalRef && this.bsModalRef.content
                && (this.bsModalRef.content.editable === true || this.bsModalRef.content.editable === false)) {
                //
                this.editable = this.bsModalRef.content.editable;
            } else {
                this.editable = false;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.submit_text) {
                this.submit_text = this.bsModalRef.content.submit_text;
            }
            if (this.current_item && this.current_item.modelable_type && this.current_item.modelable_id && !this.current_item.modelable) {
                this.getItem(this.current_item.id)
                    .then((item: any): void => {
                        this.current_item.modelable = item.modelable;
                    }, error => {
                        //
                    });
            }
        }, 0);
    }

    public onCancelSearching(e?: any): void {
        //
    }

    public back() {
        this.type = undefined;
    }

    public chooseStore(page) {
        this.canBack = true;
        this.type = page.key;
    }

    public getItem(id: string | number): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('store/items/view/' + id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSelectedItem(e?: any): void {
        this.current_item = e;
        if (this.current_item && this.current_item.id) {
            this.getItem(this.current_item.id)
                .then(data => {
                    this.current_item = new ItemModel();
                    this.current_item.clone(data);
                });
        } else {
            this.current_item = new ItemModel();
            if (this.current_item && (!this.current_item.warehouses || this.current_item.warehouses.length === 0)) {
                this.current_item.warehouses = [];
                this.current_item.warehouses.push(new WarehouseModel());
            }
            //
            if (this.type) {
                this.current_item.type = this.type;
            }
        }
    }

    public onItemFormSubmit(e: any): void {
        const is_new: boolean = e.isNew;
        let text: string;
        text = 'ยืนยันการเพิ่มวัสดุใหม่เข้าคลังใช่หรือไม่?';
        if (!is_new) {
            text = 'ยืนยันการ' + this.submit_text + 'ใช่หรือไม่?';
        }
        this.swal.confirm(text)
            .then((result: boolean): void => {
                if (result === true) {
                    if (!is_new) {
                        this.select_item(e);
                    } else {
                        e.search_value = e.name;
                        this.createOrUpdate(e, is_new)
                            .then(material => {
                                if (this.storeListComponent) {
                                    this.storeListComponent.refresh();
                                }
                                this.select_item(material);
                            });
                    }
                } else {
                    //
                }
            });
    }

    public select_item(item: any): void {
        this.bsModalRef.content.submit = true;
        this.bsModalRef.content.amount = this.tmp_amount;
        this.bsModalRef.content.item = item;
        this.bsModalRef.hide();
    }

    public createOrUpdate(e: any, is_new?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            let tmp_documents: any[];
            if (is_new) {
                if (e && e.modelable && e.modelable.documents) {
                    tmp_documents = e.modelable.documents;
                    delete e.modelable.documents;
                } else {
                    tmp_documents = [];
                }
            } else {
                tmp_documents = [];
            }
            e.table_name = 'store/items';
            this.modelApi.createOrUpdate(e, [
                'modelable', 'type', 'name', 'name_en', 'ref_no', 'type', 'amount', 'width', 'height', 'unit', 'width', 'ref_item_no',
                'length', 'weight', 'warehouse_ids', 'material_type', 'tooth', 'size', 'vendor_id',
                'price', 'remark', 'grade', 'surface', 'material', 'thickness', 'outer_diameter', 'inner_diameter'
            ], 'store/items', null, null, false)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (is_new === true) {
                            this.swal.success('เพิ่มรายการสำเร็จ');
                            this.current_item = new ItemModel();
                            this.current_item.clone(response.data);
                            this.current_item.modelable = response.data.modelable;
                            this.current_item.modelable_id = response.data.modelable.id;
                            this.current_item.modelable_type = response.data.modelable.type;
                        } else {
                            this.swal.success('แก้ไขรายการสำเร็จ');
                            this.current_item = new ItemModel();
                            this.current_item.clone(e);
                            this.current_item.search_value = response.data.search_value;
                        }
                    }
                    if (e.data && is_new) {
                        this.storeFormComponent.onDocumentUploadedSuccess(e.data)
                            .then(() => {
                                // delete this.current_item.data;
                                resolve(this.current_item);
                            }, error => {
                                resolve({});
                            });
                    } else {
                        resolve(this.current_item);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onGetting(e: any): void {
        this.is_got = false;
        this.is_getting = true;
    }

    public onGot(e: any): void {
        this.is_got = true;
        this.is_getting = false;
    }

    public onSearching(e?: any): void {
        this.is_searching = (e === true) ? true : false;
    }

    public onFilterItems(e?: any): void {
        this.search_items = e;
    }

    /*public getItem(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('store/items/view/' + this.item_id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.material.clone(response.data);
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public submit(form: NgForm): void {
        form.ngSubmit.emit();
    }

    public materialFormSubmit(form: NgForm): void {
        if (this.editabled === true) {
            if (form.valid) {
                this.modelApi.createOrUpdate(this.material, [
                    'modelable', 'type', 'name', 'name_en', 'ref_no', 'type', 'width', 'height', 'unit', 'width', 'ref_item_no',
                    'length', 'weight', 'warehouse_ids', 'material_type', 'tooth', 'size', 'vendor_id',
                    'price', 'remark', 'grade', 'surface', 'material', 'thickness', 'outer_diameter', 'inner_diameter'
                ], 'store/items', null, null, false)
                    .subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.swal.success('เพิ่มรายการวัสดุ, สินค้าสำเร็จรูป หรือวัสดุประกอบสำเร็จ');
                            // if (this.material_model) {
                            //     this.material_model.clone(this.material);
                            // }
                            this.material.id = response.data.id;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.content.amount = this.tmp_amount;
                            this.bsModalRef.content.item = response.data;
                            this.bsModalRef.content.material = this.material;
                            this.bsModalRef.content.search_value = response.data.search_value;
                            this.bsModalRef.content.modelable_type = response.data.modelable_type;
                            this.bsModalRef.content.modelable_id = response.data.modelable_id;
                            this.bsModalRef.hide();
                        }
                    });
            } else {
                this.swal.danger('กรุณากรอกชื่อและประเภทของรายการ');
            }
        } else {
            this.bsModalRef.hide();
        }
    }*/

}
