import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Observable} from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Api} from '../../now/api/api';
import {ModalService} from '../../services/modal.service';
import {Model} from '../../now/model';
import {SwalService} from '../../services/swal.service';
import {ModelApi} from '../../now/modelApi/modelApi';
import {DOCUMENT} from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as uuid4 from 'uuid/v4';
import {of} from 'rxjs/internal/observable/of';
import {TypeaheadModalComponent} from './modal/typeaheadModal.component';

export const STATE_IDLE = 1;
export const STATE_LOADING = 2;
export const STATE_SELECTED = 3;
export const STATE_SAVE = 4;

@Component({
    selector: 'typeahead-component',
    templateUrl: 'typeahead.component.html',
    styleUrls: ['typeahead.component.scss'],
    providers: [
        {
            provide     : NG_VALUE_ACCESSOR,
            multi       : true,
            useExisting : forwardRef(() => TypeaheadComponent),
        }
    ],
})
export class TypeaheadComponent implements ControlValueAccessor, AfterViewInit, OnChanges {

    @ViewChild(TypeaheadDirective, { static: true }) input: TypeaheadDirective;

    @Input('field')
    set setField(val: string) {
        this.field = val;
        this.tmp = this.defaultInput;
        this.update();
    }
    public field: string;
    public typeaheadOptionfield: string;
    @Input() create: boolean;
    @Input() placeholder: string;
    @Input() modalFields: any;
    @Input() none: boolean;
    @Input() more: boolean;
    @Input() display_not_found: string;
    @Input() servicePath: string;
    @Input() createPath: string;
    @Input() params: any;
    @Input() modalOpts: any;
    @Input() sources: any[];
    @Input() itemTemplate: any;
    @Input() placeholderNotFound: string;

    public disabled: boolean;
    @Input('disabled')
    public set setDisabled(value: any) {
        this.disabled = (value) ? true : false;
    }

    @Output() onCreating: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMore: EventEmitter<any> = new EventEmitter<any>();

    @Input() className: string;
    @Input() filterFields: string;
    @Input() noResultTooltip: boolean;
    @Input() tabindex: number;
    @Input() modelId: Model;
    @Input() fieldId: string;
    @Input() clone: boolean;
    @Input() display: string;
    @Input() deleteDisable: boolean;
    @Input() existList: any[];
    @Input() existField: string;

    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNew: EventEmitter<any> = new EventEmitter<any>();

    public tmp: string;
    public state: number;
    public the_model: ModelInterface | any;

    public ready: boolean;
    public guid: string;

    public typeaheadAsync: boolean;
    public dataSource: any;
    public is_new: boolean;
    public is_selected: boolean;
    private typeaheadLoading: boolean;
    public noResult: boolean;
    private data: any[];
    private timeout: any;
    private subscription: any;
    private keyup_timeout: any;

    private propagateChange: any = (_: any): void => {};

    constructor(
        private api: Api,
        private elementRef: ElementRef,
        private modal: ModalService,
        private swal: SwalService,
        private modelApi: ModelApi,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        this.state = STATE_IDLE;
        this.noResultTooltip = false;
        this.modalOpts = {};
        this.is_new = false;
        this.clone = true;
        this.is_selected = false;
    }

    writeValue(obj: ModelInterface): void {
        this.guid = uuid4();
        this.the_model = obj;
        this.tmp = this.defaultInput;
        this.is_selected = ((this.field && this.the_model && this.the_model[this.field] && this.the_model.id) || (this.the_model && typeof this.the_model === 'string')) ? true : false;
        this.update();
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

    ngOnChanges(changes: SimpleChanges): void {
        // this.tmp = this.defaultInput;
        // console.log(this.the_model);
        // console.log(this.field, this.tmp);
        // this.update();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.params = (this.params) ? this.params : {};
            this.placeholder = (this.placeholder) ? this.placeholder : '';
            this.typeaheadOptionfield = (this.field) ? this.field : 'search_value';
            this.modalOpts = (this.modalOpts) ? this.modalOpts : {};
            this.create = (this.create === false) ? false : true;
            this.more = (this.more === false) ? false : true;
            this.init();
        }, 0);
    }

    public remove(force?: boolean): void {
        if (!force) {
            this.swal.confirm('ยกเลิกรายการ "' + this.tmp + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.remove(true);
                    }
                });
        } else {
            this.tmp = '';
            // if (this.the_model) {
            //     this.the_model.reset();
            // }
            this.the_model = (this.field) ? Object.assign({}) : '';
            this.noResult = false;
            this.is_selected = false;
            this.typeaheadLoading = false;
            this.onDelete.emit({});
            this.update();
            this.propagateChange((this.field) ? Object.assign({}) : '');
        }
    }

    public save(force?: boolean): void {
        if (!force) {
            this.swal.confirm('บันทึกฐานข้อมูลข้อมูลใหม่ "' + this.tmp + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.save(true);
                    } else {
                        // this.remove(true);
                    }
                });
        } else {
            // this.modelObject[this.field] = this.asyncSelected;
            let paramKeys: any;
            paramKeys = Object.assign({}, this.params);
            if (this.field) {
                paramKeys[this.field] = this.tmp;
            } else {
                paramKeys['data'] = this.tmp;
            }
            this.api.request((this.createPath) ? this.createPath : this.servicePath, 'PUT', {}, paramKeys)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.onCreating.emit({
                            event: this,
                            data: response.data
                        });
                        this.is_new = false;
                        this.select(response.data);
                        // this.onCreate.emit(this.model);
                    } else {
                        this.swal.danger(response.message);
                    }
                    this.update();
                }, error => {
                    if (error && error.message) {
                        this.swal.danger(error.message);
                    } else {
                        this.swal.danger(error);
                    }
                    this.update();
                });
        }
    }

    public select(item: any): void {
        if (this.field && item[this.field]) {
            this.tmp = item[this.field];
        } else if (typeof item === 'string') {
            this.tmp = item;
        } else {
            this.tmp = JSON.stringify(item);
        }
        this.is_selected = true;
        this.noResult = false;
        // this.propagateChange(this.the_model.clone(item, null, null));
        if (typeof item === 'string') {
            this.propagateChange(item);
        } else {
            const selected_item = Object.assign({}, item);
            // if (this.the_model && this.the_model.clone) {
            //     this.the_model.clone(selected_item, []);
            // }
            this.propagateChange(selected_item);
        }
        this.update();
    }

    private init(): void {
        this.typeaheadAsync = (this.servicePath) ? true : false;
        this.data = (this.data) ? this.data : [];
        if (this.servicePath) {
            this.dataSource = Observable.create((observer: any) => {
                // Runs on every search
                this._clearTimeout();
                this.getData()
                    .then((response: any): void => {
                        observer.next(this.tmp);
                    }, error => {
                        //
                    });
            }).pipe(mergeMap((token: string) => this.getDataAsObservable(token)));
        } else {
            this.typeaheadOptionfield = this.field;
            if (this.none === true) {
                this.initData();
            }
            this.dataSource = this.data.concat(this.sources);
        }
        /*if (this.modelObject) {
            if ((typeof this.modelObject !== 'string' && this.modelObject && this.modelObject.id !== '') ||
                (typeof this.modelObject === 'string' && this.modelObject !== '' && this.modelObject !== undefined)) {
                this.is_new = false;
                this.is_selected = true;
            }
            if (this.none === true) {
                if (this.modelId && this.fieldId && this.modelId[this.fieldId] === -1) {
                    this.is_new = false;
                    this.is_selected = true;
                    // this.the_model = 'N/A';
                }
            }
        }*/
    }

    /*private autoComplete(): any {
        return Observable.create((observer: any) => {
            // Runs on every search
            this.getData()
                .then((response: any): void => {
                    observer.next(this.asyncSelected);
                }, error => {
                    //
                });
        }).pipe(mergeMap((token: string) => this.getDataAsObservable(token)));
    }*/

    private getData(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.timeout = setTimeout(() => {
                this.params['find'] = this.tmp;
                this.subscription = this.api.request(this.servicePath, 'GET', this.params)
                    .subscribe((response: any): void => {
                        this.clearData();
                        if (response && response.data) {
                            this.initData();
                            this.data = this.data.concat(response.data);
                        }
                        // this.noResult = (!this.data || this.data.length === 0) ? true : false;
                        // this.setupItems(this.data);
                        this._clearTimeout();
                        if (!this.data || !this.data.length) {
                            if (this.tmp) {
                                this.noResult = true;
                            }
                        } else {
                            setTimeout(() => {
                                if (this.input && this.data && this.data.length && this.tmp) {
                                    this.input.show();
                                }
                            }, 500);
                        }
                        resolve(this.data);
                    }, error => {
                        this._clearTimeout();
                        reject(error);
                    });
            }, 300);
        });
        return promise;
    }

    private clearData(): void {
        if (this.data) {
            this.data.splice(0, this.data.length);
        } else {
            this.data = [];
        }
    }

    public initData(): void {
        if (this.none === true) {
            let dat: any;
            dat = { id: -1 };
            if (this.field) {
                dat[this.field] = 'N/A';
            }
            this.data.push(dat);
            return dat;
        } else {
            this.data = [];
        }
    }

    public typeaheadNoResults(event: any): void {
        this.noResult = event;
        this.update();
    }

    public onValueChange(e: any): void {
        this.update();
    }

    /*private setupItems(data: any[]): void {
        if (data && data.length > 0 && this.existList && this.existList.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const item: any = data[i];
                if (item) {
                    let exist_item: any;
                    if (item && item.id) {
                        if (this.existField) {
                            exist_item = this.existList.find(j => j[this.existField].id === item.id);
                        } else {
                            exist_item = this.existList.find(j => j.id === item.id);
                        }
                    } else if (item) {
                        if (this.existField) {
                            exist_item = this.existList.find(j => j[this.existField] === item);
                        } else {
                            exist_item = this.existList.find(j => j === item);
                        }
                    }
                    if (exist_item) {
                        data[i]['exist'] = true;
                    } else {
                        data[i]['exist'] = false;
                    }
                }
            }
        }
    }*/

    private getDataAsObservable(token: string): Observable<any> {
        return of(
            this.data.filter((dat: any) => {
                if (this.field && dat[this.field]) {
                    this.typeaheadOptionfield = this.field;
                    const _token: string = (token + '').toLowerCase();
                    const _finding: string = ((dat[this.field]).toString()).toLowerCase();
                    if (_token && _finding) {
                        if (_finding.indexOf(_token) > -1) {
                            return true;
                        }
                    } else {
                        //
                    }
                }
                for (const [key, value] of Object.entries(dat)) {
                    if (key && value) {
                        const _token: string = (token + '').toLowerCase();
                        const _finding: string = value.toString().toLowerCase();
                        if (_finding.indexOf(_token) > -1) {
                            this.typeaheadOptionfield = key;
                            return true;
                        }
                    }
                }
                return false;
            })
        );
    }

    public buttonHandler(): void {
        if (this.disabled === true) {
            return;
        }
        if (this.state === STATE_IDLE) {
            this.onMore.emit({
                event: this
            });
            if (this.more === true) {
                this.openModalTypeahead();
            }
        } else if (this.state === STATE_LOADING) {
            //
        } else if (this.state === STATE_SELECTED) {
            if (this.deleteDisable === true) {
                //
            } else {
                this.remove();
            }
        } else if (this.state === STATE_SAVE) {
            this.save();
        }
    }

    public openModalTypeahead(): void {
        let contentOpts: any;
        contentOpts = {
            servicePath : null,
            dataSource  : null,
            modalFields : this.modalFields,
            params      : this.params,
            none        : this.none
        };
        if (this.typeaheadAsync === true) {
            contentOpts.servicePath = this.servicePath;
        } else if (this.dataSource) {
            contentOpts.dataSource = this.dataSource;
        }
        this.modal.show(TypeaheadModalComponent, contentOpts, Object.assign({
            class: 'modal-lg'
        }, this.modalOpts))
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.data && content.data.id === -1) { // is none
                        this.select(this.none_object());
                    } else {
                        this.select(content.data);
                    }
                } else {
                    //
                }
                this.update();
            });
    }

    private none_object(): any {
        let obj: any;
        obj = {
            id: -1,
        };
        obj[this.field] = 'N/A';
        return obj;
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
        this.update();
    }

    public update(): void {
        if (!this.noResult && !this.is_selected && !this.typeaheadLoading) {
            this.state = STATE_IDLE;
        } else if (this.typeaheadLoading === true) {
            this.state = STATE_LOADING;
        } else if (this.create === true && !this.is_selected && !this.typeaheadLoading && this.noResult) {
            this.state = STATE_SAVE;
        } else if (this.is_selected && !this.typeaheadLoading) {
            this.state = STATE_SELECTED;
        } else {
            this.state = STATE_IDLE;
        }
        if (!this.tmp) {
            this.noResult = false;
        }
    }

    public typeaheadOnSelect(e: TypeaheadMatch | any): void {
        this.select(e.item);
        this.update();
    }

    public nextFocusElement(): void {
        //
    }

    private _clearTimeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public get defaultInput(): string {
        if (this.field && this.the_model && this.the_model[this.field]) {
            return this.the_model[this.field];
        } else if (typeof this.the_model === 'string') {
            return this.the_model;
        }
        return '';
    }

}
