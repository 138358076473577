import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {SwalService} from '../../../../../services/swal.service';
import {Api} from '../../../../../now/api/api';
import {CustomerModel} from '../../../../../models/customer.model';
import {DivApiDirective} from '../../../../../now/divApi';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'customer-list-modal',
    templateUrl: 'customerList.modal.html',
    styleUrls: ['customerList.modal.scss']
})
export class CustomerListModal implements AfterViewInit {

    @ViewChild('modal', { static: true }) smartModal: NgxSmartModalComponent;
    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;

    @Output('submitting') onSubmitting: EventEmitter<any> = new EventEmitter<any>();
    @Output('submit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output('uploaded') onUploaded: EventEmitter<any> = new EventEmitter<any>();

    public keyword_sub: any;
    public total_items: number;
    public current_page: number;
    public item_per_page: number;
    public tmp_keyword: string;
    public customers: any[];
    public keyword_subject: any;
    public keyword: string;
    public ready: boolean;

    constructor(
        private swal: SwalService,
        private api: Api
    ) {
        //
        this.ready = false;
        this.current_page = 1;
        this.item_per_page = 100;
        this.customers = [];
        this.tmp_keyword = '';
        this.keyword_subject = new Subject();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
        }, 0);
    }

    public gotoCustomerView(customer: any): void {
        this.onSubmit.emit(customer);
    }

    public onClose(e?: any): void {
        // this.removeRemark();
        this.ready = false;
    }

    public onDismiss(e?: any): void {
        this.ready = false;
    }

    public onOpen(e?: any): void {
        //
    }

    public onAllChange(e: any): void {
        //
    }

    public open(): void {
        this.smartModal.open();
        this.ready = true;
        if (this.keyword_subject) {
            if (this.keyword_sub) {
                this.keyword_sub.unsubscribe();
            }
            this.keyword_sub = this.keyword_subject
                .pipe(
                    debounceTime(750)
                )
                .subscribe(text => {
                    this.keyword = text;
                    this.current_page = 1;
                    console.log(this.divApi);
                    if (this.divApi) {
                        this.divApi.refresh({
                            find: this.keyword
                        }).subscribe(() => {
                            //
                        }, error => {
                            //
                        }, () => {
                            //
                        });
                    }
                });
        }
    }

    public close(): void {
        this.smartModal.close();
    }

    public submit(): void {
        //
    }

    public onKeywordInput(e: any): void {
        this.keyword_subject.next(e);
    }

    public pageChange(page: number): void {
        this.current_page = page;
        if (this.divApi) {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        } else {
            //
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_customers();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let customer: CustomerModel;
                    customer = new CustomerModel();
                    customer.clone(dat);
                    this.customers.push(customer);
                }
            }
        }
    }

    private clear_customers(): void {
        if (this.customers) {
            this.customers.splice(0, this.customers.length);
        } else {
            this.customers = [];
        }
    }

    public onError(e: any): void {
        //
    }

}
