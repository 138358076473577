import {TaskService} from '../../../services/task.service';
import {TaskModel} from '../../../models/task.model';
import {Api} from '../../../now/api/api';
import {UserModel} from '../../../now/user/user.model';
import {AddRemarkComponent} from './addRemark/addRemark.component';
import {ModalService} from '../../../services/modal.service';
import {Location} from '@angular/common';
import {QueryList, ViewChildren} from '@angular/core';
import {ViewRemarkComponent} from './viewRemark/viewRemark.component';
import {Model} from '../../../now/model';
import {ApprovedListComponent} from '../../../modals/approvedList/approvedList.component';
import {NgxPageScrollDirective} from 'ngx-page-scroll';
import {Viewer} from '../../../services/viewer';

declare var $: any;

export const DEFAULT_DELAY = 3000;
export const PAGE_SCROLL_OFFSET = 200;

export class ViewTask {

    @ViewChildren(NgxPageScrollDirective) pageScrolls: QueryList<NgxPageScrollDirective>;

    public viewer: Viewer;

    public taskService: TaskService;
    public task_id: string;
    public task: TaskModel;
    public api: Api;
    public modal: ModalService;
    public location: Location;
    public current_tab: string;
    public current_tab_index: number;
    public pageHeight: string;
    public navigate_stack: any;
    public ready: boolean;

    constructor(
        injectable: {
            viewer?: Viewer,
            taskService?: TaskService,
            api?: Api,
            modal?: ModalService,
            location?: Location
        }
    ) {
        //
        this.viewer = (injectable.viewer) ? injectable.viewer : null;
        this.taskService = (injectable.taskService) ? injectable.taskService : null;
        this.api = (injectable.api) ? injectable.api : null;
        this.modal = (injectable.modal) ? injectable.modal : null;
        this.location = (injectable.location) ? injectable.location : null;

        this.current_tab = '#information';
        this.current_tab_index = 0;
        this.ready = false;
        this.pageHeight = 'calc(100vh - 170px - 12px - 12px)';
    }

    public viewManufacture(job: any, revision_all_draft?: any, e?: any): void { this.stopPropagation(e); this.viewer.manufacture(job, true, revision_all_draft).then(() => {}); }
    public viewLabel(job: any, e?: any): void { this.stopPropagation(e); this.viewer.label(job, true).then(() => {}); }
    public viewPurchaseOrder(purchase_order: any, e?: any): void { this.stopPropagation(e); this.viewer.purchaseOrder(purchase_order, true).then(() => {}); }
    public viewDocument(document: any, e?: any): void { this.stopPropagation(e); this.viewer.document(document, true).then(() => {}); }
    public viewProductDetail(product: any, e?: any): void { this.stopPropagation(e); this.viewer.product(product, true).then(() => {}); }
    public viewPacking(job: any, e?: any): void { this.stopPropagation(e); this.viewer.packing(job, true).then(() => {}); }
    public viewCoverSheet(job: any, e?: any): void { this.stopPropagation(e); this.viewer.coverSheet(job, true).then(() => {}); }
    public viewContactLog(contactLog: any, e?: any): void { this.stopPropagation(e); this.viewer.contactLog(contactLog, true).then(() => {}); }
    public viewQuotation(quotation: any, e?: any): void { this.stopPropagation(e); this.viewer.quotation(quotation, true).then(() => {}); }
    public viewSaleOrder(saleOrder: any, e?: any): void { this.stopPropagation(e); this.viewer.saleOrder(saleOrder, true).then(() => {}); }
    public viewDrawing(drawing: any, e?: any): void { this.stopPropagation(e); this.viewer.drawing(drawing, true).then(() => {}); }
    public viewCAD(cad: any, e?: any): void { this.stopPropagation(e); this.viewer.cad(cad, true).then(() => {}); }
    public viewReceipt(receipt: any, e?: any): void { this.stopPropagation(e); this.viewer.receipt(receipt, true).then(() => {}); }
    public viewDeposit(deposit: any, e?: any): void { this.stopPropagation(e); this.viewer.deposit(deposit, true).then(() => {}); }
    public viewPackingSlip(packing_slip: any, e?: any): void { this.stopPropagation(e); this.viewer.packingSlip(packing_slip, true).then(() => {}); }
    public viewDeliveryNote(delivery_note: any, e?: any): void { this.stopPropagation(e); this.viewer.deliveryNote(delivery_note, true).then(() => {}); }
    public viewTaxInvoice(tax_invoice: any, e?: any): void { this.stopPropagation(e); this.viewer.taxInvoice(tax_invoice, true).then(() => {}); }
    public viewInvoice(invoice: any, e?: any): void { this.stopPropagation(e); this.viewer.invoice(invoice, true).then(() => {}); }
    public viewFVD(fvd: any, e?: any): void { this.stopPropagation(e); this.viewer.fvd(fvd, true).then(() => {}); }
    // public viewShipping(shipping: any): void { this.viewer.shipping(shipping).then(() => {}); }
    public viewPricingInquiryByFVD(fvd: any, e?: any): void { this.stopPropagation(e); this.viewer.pricingInquiryByFVD(fvd).then(() => {}); }
    public viewPricingInquiry(pricing_inquiry: any, e?: any): void { this.stopPropagation(e); this.viewer.pricingInquiry(pricing_inquiry, true).then(() => {}); }
    public viewPayment(payment: any, e?: any): void { this.stopPropagation(e); this.viewer.payment(payment, true).then(() => {}); }
    public viewWaiver(ncr: any, e?: any): void { this.stopPropagation(e); this.viewer.waiver(ncr, true).then(() => {}); }
    public viewNCR(ncr: any, e?: any): void { this.stopPropagation(e); this.viewer.ncr(ncr, true).then(() => {}); }
    public viewNCRs(job: any, e?: any): void { this.stopPropagation(e); this.viewer.ncrs(job).then(() => {} ); }
    public viewFMT(fmt: any, e?: any): void { this.stopPropagation(e); this.viewer.fmt(fmt, true).then(() => {}); }

    private stopPropagation(e?: any): void {
        if (e) {
            e.stopPropagation();
        }
    }

    public viewTaskInit(): void {
        this.ready = false;
    }

    public doReady(delay?: number): void {
        setTimeout(() => {
            this.ready = true;
            this.setupScroll();
        }, (!delay) ? DEFAULT_DELAY : delay);
    }

    public setupScroll(): void {
        this.current_tab = '#information';
        this.pageScrolls.forEach((item: NgxPageScrollDirective): void => {
            item.pageScrollFinish.subscribe(() => {
                const id = item.href;
                this.current_tab = id;
            });
        });
        $(window).scroll(() => {
            const $height = $(window).scrollTop();
            let i: number;
            i = 0;
            this.pageScrolls.forEach((item: NgxPageScrollDirective): void => {
                const id = item.href;
                const obj = $(id);
                if (obj && obj.offset() && obj.offset().top) {
                    if ($height >= $(id).offset().top - PAGE_SCROLL_OFFSET) {
                        this.current_tab = id;
                        this.current_tab_index = i;
                        return;
                    }
                }
                i++;
            });
        });
    }

    public showApprovedList(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.modal) {
                this.modal.show(ApprovedListComponent, {
                    task: this.task
                }, {
                    class: 'modal-lg'
                }).then((content: any): void => {
                    if (content && content.submit === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
            } else {
                reject();
            }
        });
        return promise;
    }

    public getTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.task_id) {
                this.api.request('tasks/' + this.task_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        if (response && response.data) {
                            this.task.clone(response.data);
                            resolve(this.task);
                        }
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.task);
            }
        });
        return promise;
    }

    public issueTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.showApprovedList()
                .then(() => {
                    resolve();
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public checkTask(approved_role: string, user?: UserModel, model?: Model, post_data?: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.modal) {
                this.modal.show(AddRemarkComponent, {
                    task: this.task
                }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
                    .then((content: any): void => {
                        if (content && content.sent === true) {
                            this.taskService.check(this.task, approved_role, user)
                                .then(() => {
                                    resolve(content.remark_message);
                                }, error => {
                                    reject(error);
                                });
                        } else {
                            reject();
                        }
                    });
            } else {
                reject();
            }
        });
        return promise;
    }

    public approveTask(user?: UserModel, skip_remark?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (skip_remark === true) {
                this.taskService.approve(this.task, user)
                    .then(() => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
            } else if (this.modal) {
                this.modal.show(AddRemarkComponent, {
                    task: this.task
                }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
                    .then((content: any): void => {
                        if (content && content.sent === true) {
                            this.taskService.approve(this.task, user)
                                .then(() => {
                                    resolve(content);
                                }, error => {
                                    reject(error);
                                });
                        } else {
                            reject('');
                        }
                    });
            } else {
                reject('');
            }
        });
        return promise;
    }

    public rejectTask(user?: UserModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.modal) {
                this.modal.show(AddRemarkComponent, {
                    task: this.task
                }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
                    .then((content: any): void => {
                        if (content && content.sent === true) {
                            this.taskService.reject(this.task, user)
                                .then(() => {
                                    resolve();
                                }, error => {
                                    reject(error);
                                });
                            return;
                        }
                        reject();
                    });
            } else {
                reject();
            }
        });
        return promise;
    }

    public navBack(): void {
        if (this.location) {
            this.location.back();
        }
    }

    public archive(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public deleteTask(): void {
        this.taskService.delete(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public leave(force?: boolean, back?: boolean, remark?: string): void {
        this.taskService.leave(this.task, force, back, remark)
            .then(response => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public hire(): void {
        if (this.task) {
            this.taskService.hire(this.task)
                .then((response: any): void => {
                    // Go Go!
                }, error => {
                    //
                });
        } else {
            //
        }
    }

    public viewRemark(): void {
        this.modal.show(ViewRemarkComponent, {
            task: this.task
        }, { class: 'modal-lg' }).then(() => {
            //
        });
    }

}
