import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import {CustomerModel} from '../../../../models/customer.model';
import {TaxInvoiceModel} from '../../../../models/taxInvoice.model';
import {JobModel} from '../../../../models/job.model';
import * as moment from 'moment';
import {ProductModel} from '../../../../models/product.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'app-tax-invoice-detail-component',
    templateUrl: 'taxInvoiceDetail.component.html',
    styleUrls: ['taxInvoiceDetail.component.scss']
})
export class TaxInvoiceDetailComponent implements OnInit {

    public job: JobModel;
    public tax_invoice: TaxInvoiceModel;
    public tmp_tax_invoice: TaxInvoiceModel;
    public credit_terms: number;
    public products: ProductModel[];

    public percent: number;

    public task: TaskModel;
    public quotation: QuotationModel;
    public search_filter: string;
    public customer: CustomerModel;
    public model: any;
    public is_new: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        private viewer: Viewer
    ) {
        //
        this.tax_invoice = new TaxInvoiceModel();
        this.tmp_tax_invoice = new TaxInvoiceModel();
        this.quotation = new QuotationModel();
        this.products = [];
        this.customer = this.quotation.customer;
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.quotation) {
                this.quotation = this.bsModalRef.content.quotation;
                if (this.customer.credit_terms > 0) {
                    this.credit_terms = this.customer.credit_terms;
                } else {
                    this.credit_terms = 30;
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.tax_invoice) {
                if (!this.bsModalRef.content.tax_invoice.started_at) {
                    this.bsModalRef.content.tax_invoice.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }
                this.tax_invoice = this.bsModalRef.content.tax_invoice;
                this.tmp_tax_invoice.started_at = this.tax_invoice.started_at;
                this.tmp_tax_invoice.id = this.tax_invoice.id;
                this.tmp_tax_invoice.tax_invoice_no = this.tax_invoice.tax_invoice_no;
                this.tmp_tax_invoice.remark = this.tax_invoice.remark;
                this.tmp_tax_invoice.status = (this.tax_invoice.status) ? this.tax_invoice.status : 2;

                if (!this.tmp_tax_invoice.started_at) {
                    this.tmp_tax_invoice.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                if (!this.tmp_tax_invoice.total_price) {
                    this.tmp_tax_invoice.total_price = this.quotation.grand_total;
                }

                this.is_new = this.tax_invoice.isNew;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.products) {
                this.products = this.bsModalRef.content.products;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
        }, 0);
    }

    public delete(): void {
        if (this.tax_invoice.status === 1) {
            return;
        }
        const name: string = (this.tmp_tax_invoice.tax_invoice_no)
            ? this.tmp_tax_invoice.tax_invoice_no : 'ใบกำกับภาษี';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_tax_invoice)
                        .subscribe((response: any): void => {
                            const index: number = this.quotation.tax_invoices.findIndex(i => i.id === this.tmp_tax_invoice.id);
                            if (index > -1) {
                                this.quotation.tax_invoices.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewTaxInvoice(): void {
        this.viewer.taxInvoice(this.tmp_tax_invoice);
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public update(): void {
        if (this.tmp_tax_invoice && !this.tmp_tax_invoice.id) {
            this.swal.confirm('ยืนยันการออกใบกำกับภาษีใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('tax/invoices', 'PUT', {}, {
                            customer_id: this.model.customer_id,
                            model_id: this.model.id,
                            // tax_invoice_id: this.model.id,
                            grand_total: this.tmp_tax_invoice.grand_total,
                            bill_id: this.model.bill_id,
                            customer_address_id: this.model.customer_address_id,
                            contact_id: this.model.contact_id,
                            incoterm_code: this.model.incoterm_code,
                            status: this.tmp_tax_invoice.status,
                            started_at: this.tmp_tax_invoice.started_at,
                            total_price: this.tmp_tax_invoice.total_price,
                            remark: this.tmp_tax_invoice.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกใบกำกับภาษีสำเร็จ');
                            this.bsModalRef.content.tax_invoice = response.data;
                            this.bsModalRef.content.is_create = true;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขเอกสารใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('tax/invoices/status', 'POST', {}, {
                            status: this.tmp_tax_invoice.status,
                            id: this.tmp_tax_invoice.id
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.bsModalRef.content.tax_invoice.status = this.tmp_tax_invoice.status;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        }
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const product_ids: any[] = this.get_product_ids();
            this.tmp_tax_invoice.product_ids = product_ids;
            this.tmp_tax_invoice.quotation_id = this.quotation.id;
            this.modelApi.createOrUpdate(this.tmp_tax_invoice, [
                'product_ids', 'job_id', 'total_price', 'started_at', 'ended_at', 'verified', 'quotation_id', 'credit_terms',
                'currency_rate', 'currency', 'remark', 'status'
            ]).subscribe((response: any): void => {
                this.tax_invoice.id = this.tmp_tax_invoice.id;
                this.tax_invoice.total_price = this.tmp_tax_invoice.total_price;
                this.tax_invoice.tax_invoice_no = this.tmp_tax_invoice.tax_invoice_no;
                this.tax_invoice.started_at = this.tmp_tax_invoice.started_at;
                this.tax_invoice.ended_at = this.tmp_tax_invoice.ended_at;
                this.tax_invoice.verified = this.tmp_tax_invoice.verified;
                this.tax_invoice.status = this.tmp_tax_invoice.status;
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

    public onSubmit(form: NgForm): void {
        /*if (form.valid) {
            this.save()
                .then(() => {
                    this.viewTaxInvoice();
                    this.swal.success('บันทักข้อมูลใบกำกับภาษีสำเร็จ');
                    this.bsModalRef.content.is_submit = this.is_new;
                    this.bsModalRef.content.tax_invoice = this.tax_invoice;
                    this.bsModalRef.hide();
                });
        } else {
            //
        }*/
    }

}
