import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {ProductModel} from '../../models/product.model';
import {TaskService} from '../../services/task.service';
import {Api} from '../../now/api/api';
import {TaskModel} from '../../models/task.model';
import {SwalService} from '../../services/swal.service';
import {PurchaseOrderModel} from '../../models/purchaseOrder.model';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'find-po-component',
    templateUrl: 'findPO.component.html',
    styleUrls: ['findPO.component.scss']
})
export class FindPOComponent implements AfterViewInit {

    public purchase_order: PurchaseOrderModel;

    constructor(
        private viewer: Viewer,
        public bsModalRef: BsModalRef,
        public taskService: TaskService,
        private api: Api,
        private swal: SwalService
    ) {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    public getTasksByPurchaseOrder(purchase_order_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + purchase_order_id + '/purchase/order', 'POST', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data && response.data[0]) {
                    let task: TaskModel;
                    task = new TaskModel();
                    if (response.data[0].parent && response.data[0].parent.id && !response.data[0].parent.hidden) {
                        task.clone(response.data[0].parent);
                    } else {
                        task.clone(response.data[0]);
                    }
                    resolve(task);
                } else {
                    resolve();
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSelect(e: any): void {
        if (e && e.id) {
            this.viewer.payment(e);
        } else {
            console.log(e);
        }
        // this.getTasksByPurchaseOrder(e.id)
        //     .then((task: TaskModel): void => {
        //         if (task) {
        //             this.bsModalRef.content.task = task;
        //             this.bsModalRef.content.submit = true;
        //         } else {
        //             this.bsModalRef.content.task = null;
        //             this.bsModalRef.content.purchase_order_id = e.id;
        //             this.bsModalRef.content.submit = true;
        //         }
        //         this.bsModalRef.hide();
        //     }, error => {
        //         this.swal.danger(error);
        //     });
    }

    public onSubmit(form: NgForm): void {
        //
    }

}
