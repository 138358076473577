import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';

@Directive({
    selector: '[nowDivApiZero]'
})
export class DivApiZeroDirective implements OnInit, OnDestroy {

    constructor(public el: ElementRef) {
        //
    }

    ngOnInit(): void {
        if (this.el.nativeElement) {
            this.el.nativeElement.remove();
        }
    }

    ngOnDestroy(): void {

    }

}