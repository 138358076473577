import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {RoleModel} from '../models/role.model';

@Injectable()
export class RoleService {

    public roles: RoleModel[];

    constructor(private api: Api) {
        this.reset_roles_list();
    }

    public getRoles(): Promise<RoleModel[]> {
        let promise: Promise<RoleModel[]>;
        promise = new Promise<RoleModel[]>((resolve, reject) => {
            if (this.roles && this.roles.length > 0 && false) {
                for (let i = 0; i < this.roles.length; i++) {
                    this.roles[i].checked = false;
                }
                resolve(this.roles);
            } else {
                this.api.request('roles', 'GET', {})
                    .subscribe((response: any): void => {
                        this.reset_roles_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                const dat: any = response.data[i];
                                if (dat) {
                                    let role: RoleModel;
                                    role = new RoleModel();
                                    role.clone(dat);
                                    this.roles.push(role);
                                }
                            }
                            resolve(this.roles);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_roles_list(): void {
        if (this.roles) {
            this.roles.splice(0, this.roles.length);
        } else {
            this.roles = [];
        }
    }

}