import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppService} from '../../app/app.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../now/user/auth.service';
import {UserModel} from '../../now/user/user.model';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-database-component',
    templateUrl: 'database.component.html',
    styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit, AfterViewInit {

    public pages: any[];
    public is_ready: boolean;
    public role_error: boolean;

    constructor(
        private route: ActivatedRoute,
        private appService: AppService,
        private authService: AuthService,
        private userService: UserService,
    ) {
        //
        this.is_ready = false;
        this.role_error = false;
        this.appService.pages = this.pages = [
            {
                display: 'เอกสาร',
                children: [
                    {
                        key         : 'quotation',
                        display     : 'ใบเสนอราคา',
                        link        : '/database/quotation',
                        level       : 1
                    },
                    {
                        key         : 'payment',
                        display     : 'ใบสั่งซื้อสินค้า',
                        link        : '/database/purchase/order',
                        level       : 1
                    },
                    {
                        key         : 'delivery_note',
                        display     : 'ใบส่งสินค้า',
                        link        : '/database/delivery/note',
                        level       : 1
                    },
                    // {
                    //     key         : 'packing_slip',
                    //     display     : 'ใบแพ็กสินค้า',
                    //     link        : '/database/packing/slip',
                    //     level       : 1
                    // },
                    // {
                    //     key         : 'manufacture',
                    //     display     : 'ใบคำสั่งผลิต',
                    //     link        : '/database/manufacture',
                    //     level       : 1
                    // },
                    {
                        key         : 'product',
                        display     : 'ใบรายละเอียดสินค้า',
                        link        : '/database/product',
                        level       : 1
                    },
                    {
                        key         : 'sale_order',
                        display     : 'ใบสั่งขาย',
                        link        : '/database/sale_order',
                        level       : 1
                    },
                    {
                        key         : 'fvd',
                        display     : 'FVD',
                        link        : '/database/fvd',
                        level       : 1
                    }
                ]
            },
            {
                display: 'ฐานข้อมูล',
                children: [
                    {
                        key         : 'job',
                        display     : 'JOB',
                        link        : '/database/job',
                        level       : 1
                    },
                    {
                        key         : 'customer',
                        display     : 'ลูกค้า',
                        link        : '/database/customer',
                        level       : 2
                    },
                    {
                        key         : 'vendor',
                        display     : 'ผู้ขาย',
                        link        : '/database/vendor',
                        level       : 2
                    },
                    {
                        key         : 'carrier',
                        display     : 'ผู้ขนส่ง',
                        link        : '/database/carrier',
                        level       : 2
                    },
                    {
                        key         : 'user',
                        display     : 'พนักงาน',
                        link        : '/database/user',
                        level       : 2
                    },
                    {
                        key         : 'process',
                        display     : 'กระบวนการ',
                        link        : '/database/process',
                        level       : 2
                    },
                ]
            }
        ];
    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.is_ready = false;
            this.role_error = false;
            this.authService.profile()
                .then(user => {
                    this.is_ready = true;
                    if (user.level < 4) {
                        this.role_error = false;
                    } else {
                        this.role_error = true;
                    }
                    this.userService.setUser(user);
                }, error => {
                    this.is_ready = true;
                });
        }, 0);
    }

}
