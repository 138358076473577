import {Injectable} from '@angular/core';
import {UserModel} from './user.model';
import {Api} from '../api/api';
import {LocalStorageService} from '../../services/localStorageService';

@Injectable()
export class AuthService {

    private user_instance: UserModel;
    private is_logged: boolean;

    public onAuthHandler: any;
    public onLogoutHandler: any;

    constructor(private api: Api, private storage: LocalStorageService) {
        this.is_logged = false;
    }

    public init(): Promise<Object> {
        console.log('== USER SERVICE INIT ==');
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject): void => {
            const token = this.storage.retrieve('token');
            console.log('current user token : ' + token);
            if (token) {
                this.logged(token);
                resolve(token);
            } else {
                reject();
            }
        });

        return promise;
    }

    public check(): Promise<UserModel> {
        let promise: Promise<UserModel>;
        promise  = new Promise<UserModel>((resolve, reject): void => {
            this.api.request('users/check', 'POST', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    this.user.reset();
                    this.user.clone(response.data);
                }
                this.is_logged = true;
                resolve(this.user);
            }, error => {
                this.user.error(error);
                reject(this.user);
            });
        });

        return promise;
    }

    public login(username?: string, password?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise  = new Promise<Object>((resolve, reject): void => {
            // this.user.process('login');
            this.api.request('users/login', 'POST', {}, {
                username    : (username) ? username : this.user.username,
                password    : (password) ? password : this.user.password
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.user.reset();
                    this.user.clone(response.data);
                    this.logged(response.data.token);
                    resolve(true);
                } else {
                    resolve(false);
                }
            }, error => {
                if (error) {
                    this.user.error(error);
                }
                reject(error);
            });
        });

        return promise;
    }

    public logout(): Promise<UserModel> {
        let promise: Promise<UserModel>;
        promise = new Promise<UserModel>((resolve, reject): void => {
            // this.user.process('logout');
            this.api.request('users/logout', 'POST', {} ,{
                //
            }).subscribe((response: any): void => {
                this.user.reset();
                this.user.clear();

                this.storage.clear('token');
                this.api.setAuthorization('');
                this.is_logged = false;

                resolve(this.user);
            }, error => {
                this.user.error(error);
                reject(this.user);
            });
        });

        return promise;
    }

    public profile(clone?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise  = new Promise<any>((resolve, reject): void => {
            this.api.request('users/profile', 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    if (clone === false) {
                        //
                    } else {
                        this.user.reset();
                        this.user.clone(response.data);
                    }
                }
                resolve(response.data);
            }, error => {
                this.user.error(error);
                reject({});
            }, () => {
                //
            });
        });

        return promise;
    }

    private logged(token: string): void {
        console.log('== LOGGED ==');
        this.storage.store('token', token);
        this.api.setAuthorization(token);
    }

    public get user(): any {
        if (!this.user_instance) {
            this.user_instance = new UserModel();
        }
        return this.user_instance;
    }

    public get isLogged(): boolean {
        return this.is_logged;
    }

}
