import {Model} from '../now/model';
import {UserModel} from '../now/user/user.model';
import {CustomerModel} from './customer.model';
import {VendorModel} from './vendor.model';
import {JobModel} from './job.model';
import {ProductModel} from './product.model';
import {DocumentModel} from './document.model';
import {PricingInquiryDocument} from '../app/api/pricingInquiryDocument';
import {SchedulerModel} from './scheduler.model';
import {ProcessModel} from './process.model';
import {Remark} from '../app/api/remark';

export class PricingInquiryModel extends Model {

    public job_id: string;
    public pricing_inquiry_no: string;
    public ref_no: string;
    public job: JobModel;
    public objective_other: string;
    public product: ProductModel;
    public product_id: string;
    public vendor1_id: string;
    public vendor2_id: string;
    public vendor3_id: string;
    public request_man_id: string;
    public purchase_user_id: string;
    public customer_id: string;
    public items: any[];
    public request_man: UserModel;
    public customer: CustomerModel;
    public purchase_user: UserModel;
    public vendor_id: string;
    public objective: string;
    public documents: PricingInquiryDocument[];
    public started_at: string;
    public required_at: string;
    public remarks: Remark[];
    public item_ids: any[];
    public vendor1: VendorModel;
    public vendor2: VendorModel;
    public vendor3: VendorModel;

    constructor() {
        super('pricing_inquiries', 'App\\PricingInquiry');
        //
        this.item_ids = [];
        this.items = [];
        this.documents = [];
        this.vendor1 = new VendorModel();
        this.vendor2 = new VendorModel();
        this.vendor3 = new VendorModel();
    }

    public clone(data: any): Model {
        if (data) {
            if (data.job) {
                this.job = new JobModel();
                this.job.clone(data.job);
                delete data.job;
            }
            if (data.product) {
                this.product = new ProductModel();
                this.product.clone(data.product);
                delete data.product;
            }
            if (data.customer) {
                this.customer = new CustomerModel();
                this.customer.clone(data.customer);
                delete data.customer;
            }
            if (data.request_man) {
                this.request_man = new UserModel();
                this.request_man.clone(data.request_man);
                delete data.request_man;
            }
            if (data.purchase_user) {
                this.purchase_user = new UserModel();
                this.purchase_user.clone(data.purchase_user);
                delete data.purchase_user;
            }
        }
        super.clone(data);
        return this;
    }

}
