import {AfterViewInit, Component, Input} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserModel} from '../../../../now/user/user.model';
import {SwalService} from '../../../../services/swal.service';
import {Api} from '../../../../now/api/api';
import {NgxSmartModalService} from 'ngx-smart-modal';
import * as moment from 'moment';
import {TaskService} from '../../../../services/task.service';
import {ApprovedListComponent} from '../../../../modals/approvedList/approvedList.component';
import {ModalService} from '../../../../services/modal.service';

@Component({
    selector: 'leave-modal-component',
    templateUrl: 'leaveModal.component.html',
    styleUrls: ['leaveModal.component.scss']
})
export class LeaveModalComponent implements AfterViewInit {

    @Input() user: UserModel;

    public start_time;
    public end_time;
    public userId;
    public last_vacation;
    public last_personal;
    public last_sick;
    public last_maternity;
    public last_force;
    public last_other;
    public sum_vacation = 0;
    public sum_personal = 0;
    public sum_sick = 0;
    public sum_maternity = 0;
    public sum_force = 0;
    public sum_other = 0;
    public config;
    public leave_date: string;
    public leave_time: string;
    public current_date: string;
    public cause: string;
    public case_description: string;
    public start_date: string;
    public end_date: string;
    public urgent_telephone: string;
    public activities;
    public leaveHistories;
    public isTime;
    public min_time;
    public disabledHours;

    constructor(
        private modal: ModalService,
        private swal: SwalService,
        private api: Api,
        private taskServie: TaskService,
        private ngxSmartModalService: NgxSmartModalService,
    ) {
        this.isTime = false;
        this.leaveHistories = [];
        this.leave_time = 'ALLDAY';
        this.current_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.disabledHours = () => [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23];
    }

    range(start: number, end: number): number[] {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    ngAfterViewInit(): void {
        this.getProfile()
            .then(() => {
                this.getUserActivities()
                    .then(() => {
                        //
                    });
            });
    }

    public reset(): void {
        console.log('== leave modal reset ==');
        this.case_description = '';
        this.cause = '';
        this.start_date = undefined;
        this.end_date = undefined;
    }

    public getProfile(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            console.log('get profile');
            this.api.request('users/profile')
                .subscribe((response: any): void => {
                    console.log(response);
                    if (response && response.data) {
                        this.userId = response.data.id;
                        this.config = response.data.config;
                        resolve();
                    } else {
                        resolve({});
                    }
                }, error => {
                    resolve({});
                });
        });
        return promise;
    }

    public getUserActivities(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('users/' + this.userId + '/leaves', 'GET')
                .subscribe((res: any): void => {
                    this.leaveHistories = [];
                    if (res && res.data) {
                        for (const dat of res.data) {
                            this.leaveHistories.push(dat);
                        }
                    }
                    if (this.leaveHistories && this.leaveHistories.length) {
                        for (const activity of this.leaveHistories) {
                            console.log('activity.approved_3_at : ' + activity.approved_3_at);
                            if (activity && activity.approved_3_at) {
                                const subject = activity.subject;
                                if (subject === 'VACATION') {
                                    this.sum_vacation += (activity.days) ? activity.days : 0;
                                    this.last_vacation = (activity.end) ? activity.end : activity.start;
                                } else if (subject === 'PERSONAL') {
                                    this.sum_personal += (activity.days) ? activity.days : 0;
                                    this.last_personal = (activity.end) ? activity.end : activity.start;
                                } else if (subject === 'SICK') {
                                    this.sum_sick += (activity.days) ? activity.days : 0;
                                    this.last_sick = (activity.end) ? activity.end : activity.start;
                                } else if (subject === 'MATERNITY') {
                                    this.sum_maternity += (activity.days) ? activity.days : 0;
                                    this.last_maternity = (activity.end) ? activity.end : activity.start;
                                } else if (subject === 'FORCE') {
                                    this.sum_force += (activity.days) ? activity.days : 0;
                                    this.last_force = (activity.end) ? activity.end : activity.start;
                                } else if (subject === 'OTHER') {
                                    this.sum_other += (activity.days) ? activity.days : 0;
                                    this.last_other = (activity.end) ? activity.end : activity.start;
                                }
                            }
                        }
                    }
                    resolve([]);
                });
        });
        return promise;
    }

    public showApprovedList(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.modal) {
                this.modal.show(ApprovedListComponent, {
                    // task: this.task
                }, {
                    class: 'modal-lg'
                }).then((content: any): void => {
                    if (content && content.submit === true) {
                        resolve(content);
                    } else {
                        reject();
                    }
                });
            } else {
                reject();
            }
        });
        return promise;
    }

    public onSubmit(form: NgForm): void {
        if (this.start_date && this.cause && this.case_description) {
            if (this.isTime === true && (!this.end_time || !this.start_time)) {
                this.swal.danger('โปรดระบุเวลาที่ต้องการลา');
                return;
            }
            const start_leave_date_string = moment(this.start_date).format((this.isTime) ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
            const end_leave_date_string = (this.end_date) ? moment(this.end_date).format((this.isTime) ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY') : undefined;
            const start_date: string = (this.isTime) ? this.start_date : moment(this.start_date).format('YYYY-MM-DD 08:00:00');
            const end_date: string = (this.isTime) ? this.end_date : (this.end_date) ? moment(this.end_date).format('YYYY-MM-DD 17:00:00') : undefined;
            const days = this.leave_days;
            if (days < 0) {
                this.swal.danger('โปรดตรวจสอบวันลาให้ถูกต้อง');
            } else {
                const displayDate = (end_leave_date_string) ? start_leave_date_string + ' - ' + end_leave_date_string : start_leave_date_string;
                this.swal.confirm('พนักงาน ' + this.user.full_name + ' แจ้งลางานเมื่อวันที่ ' + displayDate + ' ใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.showApprovedList()
                                .then((resultApproved: any): void => {
                                    this.api.request('users/leave', 'POST', {}, {
                                        id                  : this.user.id,
                                        start               : start_date,
                                        end                 : end_date,
                                        isTime              : (this.isTime) ? true : false,
                                        days                : days,
                                        cause               : this.cause,
                                        case_description    : this.case_description,
                                        // urgent_telephone    : this.urgent_telephone
                                    }).subscribe((response: any): void => {
                                        if (response && response.data) {
                                            this.createTask(response.data.id, resultApproved.man.id)
                                                .then(() => {
                                                    this.swal.success('รอการอนุมัติจากหัวหน้างาน');
                                                    this.ngxSmartModalService.close('leaveModal');
                                                }, error => {
                                                    this.swal.danger(error);
                                                });
                                        } else {
                                            this.swal.danger(response);
                                        }
                                    }, error => {
                                        this.swal.danger(error);
                                    });
                                });
                        }
                    });
            }
        } else {
            this.swal.danger('โปรดกรอกข้อมูลให้ครบถ้วน');
        }
    }

    public createTask(man_scheduler_id, to_user_id): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.taskServie.createNewTask({
                process_slug    : 'request',
                current_role    : 'hr',
                action          : 'sent_leave_request',
                modelable_type  : 'App\\Leave',
                modelable_id    : man_scheduler_id,
                to_user_id      : to_user_id,
                type            : 2
            }).then(() => {
                resolve();
            }, error => {
                reject();
            });
        });
        return promise;
    }

    public update() {
        if (this.isTime === true) {
            setTimeout(() => {
                let start, end;
                if (this.start_date && this.start_time) {
                    const startDate = moment(this.start_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                    const startTime = this.start_time.slice(11, 13) + ':00:00';
                    start = startDate + ' ' + startTime;
                }
                if (start && this.end_time) {
                    this.end_date = start;
                    const endDate = moment(this.end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                    const endTime = this.end_time.slice(11, 13) + ':00:00';
                    end = endDate + ' ' + endTime;
                }
                if (start && end) {
                    this.start_date = start;
                    this.end_date = end;
                }
            });
        }
    }

    public onTimeChange(event) {
        setTimeout(() => {
            this.start_date = '';
            this.end_date = '';
            this.start_time = '';
            this.end_time = '';
        });
    }

    public get leave_days(): number {
        if (this.start_date && this.end_date) {
            let start_date_moment = moment(this.start_date, 'YYYY-MM-DD HH:mm:ss');
            let end_date_moment = moment(this.end_date, 'YYYY-MM-DD HH:mm:ss');
            let subfix = 0;
            if (this.isTime) {
                const start_time_moment = +start_date_moment.clone().format('HHmm');
                const end_time_moment = +end_date_moment.clone().format('HHmm');
                if (start_time_moment >= 800 && end_time_moment <= 1200) {
                    subfix = 0;
                } else if (start_time_moment >= 800 && end_time_moment <= 1230) {
                    subfix = .5;
                } else if (start_time_moment >= 800 && end_time_moment <= 1300) {
                    subfix = 1;
                } else if (start_time_moment >= 1200 && end_time_moment <= 1230) {
                    subfix = .5;
                } else if (start_time_moment >= 1200 && end_time_moment <= 1300) {
                    subfix = 1;
                } else if (start_time_moment >= 1230 && end_time_moment <= 1300) {
                    subfix = .5;
                } else if (start_time_moment >= 1300 && end_time_moment <= 1700) {
                    subfix = 0;
                } else if (start_time_moment >= 1230 && end_time_moment <= 1700) {
                    subfix = .5;
                } else if (start_time_moment >= 1200 && end_time_moment <= 1700) {
                    subfix = 1;
                } else if (start_time_moment >= 800 && end_time_moment <= 1700) {
                    subfix = 1;
                }
                // console.log(start_time_moment, end_time_moment, subfix);
            }
            const momentAsHours = moment.duration(end_date_moment.diff(start_date_moment)).asHours();
            return +((momentAsHours - subfix) / 8).toFixed(3);
        } else if (this.start_date) {
            return 1;
        }
        return 0;
    }

    public get right_leave_num() {
        if (this.config) {
            if (this.cause === 'VACATION') {
                return this.config.max_vacation;
            } else if (this.cause === 'PERSONAL') {
                return this.config.max_personal_leave;
            } else if (this.cause === 'SICK') {
                return this.config.max_sick_leave;
            } else if (this.cause === 'MATERNITY') {
                return this.config.max_maternity_leave;
            } else if (this.cause === 'FORCE') {
                return '';
            } else if (this.cause === 'OTHER') {
                return '';
            }
        }
        return '';
    }

    public get updated_at() {
        if (this.cause === 'VACATION') {
            return this.last_vacation;
        } else if (this.cause === 'PERSONAL') {
            return this.last_personal;
        } else if (this.cause === 'SICK') {
            return this.last_sick;
        } else if (this.cause === 'MATERNITY') {
            return this.last_maternity;
        } else if (this.cause === 'FORCE') {
            return this.last_force;
        } else if (this.cause === 'OTHER') {
            return this.last_other;
        }
        return '';
    }

    public get leave_num() {
        if (this.cause === 'VACATION') {
            return this.sum_vacation;
        } else if (this.cause === 'PERSONAL') {
            return this.sum_personal;
        } else if (this.cause === 'SICK') {
            return this.sum_sick;
        } else if (this.cause === 'MATERNITY') {
            return this.sum_maternity;
        } else if (this.cause === 'FORCE') {
            return this.sum_force;
        } else if (this.cause === 'OTHER') {
            return this.sum_other;
        }
        return '';
    }

}
