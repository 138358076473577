import {AfterViewInit, Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'remark-modal-component',
    templateUrl: 'remarkModal.component.html',
    styleUrls: ['remarkModal.component.scss']
})
export class RemarkModalComponent implements AfterViewInit {

    public model: any;
    public disable: boolean;
    public description: string;

    constructor(public bsModalRef: BsModalRef) {
        this.model = {};
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.description) {
                // this.model = this.bsModalRef.content.model;
                this.description = this.bsModalRef.content.description;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.disable) {
                this.disable = (this.bsModalRef.content.disable === true) ? true : null;
            }
            this.description = (this.description) ? this.description : '';
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        this.bsModalRef.hide();
        this.bsModalRef.content.submit = true;
        this.bsModalRef.content.description = this.description;
    }

}
