import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {TaskService} from '../../services/task.service';
import {Api} from '../../now/api/api';
import {TaskModel} from '../../models/task.model';
import {SwalService} from '../../services/swal.service';
import {RequirementModel} from '../../models/requirement.model';

@Component({
    selector: 'find-requirement-component',
    templateUrl: 'findRequirement.component.html',
    styleUrls: ['findRequirement.component.scss']
})
export class FindRequirementComponent implements AfterViewInit {

    public requirement: RequirementModel;

    constructor(public bsModalRef: BsModalRef, public taskService: TaskService, private api: Api, private swal: SwalService) {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    public getTasksByRequirement(requirement_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + requirement_id + '/requirement', 'POST', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data && response.data[0]) {
                    let task: TaskModel;
                    task = new TaskModel();
                    if (response.data[0].parent_id && !response.data[0].parent.hidden) {
                        task.clone(response.data[0].parent);
                    } else {
                        task.clone(response.data[0]);
                    }
                    resolve(task);
                } else {
                    resolve();
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSelect(e: any): void {
        this.getTasksByRequirement(e.id)
            .then((task: TaskModel): void => {
                if (task) {
                    this.bsModalRef.content.task = task;
                    this.bsModalRef.content.submit = true;
                } else {
                    this.bsModalRef.content.task = null;
                    this.bsModalRef.content.requirement_id = e.id;
                    this.bsModalRef.content.submit = true;
                }
                this.bsModalRef.hide();
            }, error => {
                this.swal.danger(error);
            });
    }

    public onSubmit(form: NgForm): void {
        //
    }

}
