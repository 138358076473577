import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CriticalPointModel} from '../../../models/criticalPoint.model';
import {DivApiDirective} from '../../../now/divApi';
import {ViewList} from '../list/viewList';

@Component({
    selector: 'critical-point-list-component',
    templateUrl: 'criticalPointList.component.html',
    styleUrls: ['criticalPointList.component.scss']
})
export class CriticalPointListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sub: any;
    public timeout: any;
    public critical_points: CriticalPointModel[];
    public keyword: string;

    constructor(private router: Router) {
        super();
        this.critical_points = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_critical_points();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let critical_point: CriticalPointModel;
                    critical_point = new CriticalPointModel();
                    critical_point.clone(dat);
                    this.critical_points.push(critical_point);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoCriticalPointView(critical_point: CriticalPointModel, index?: number): void {
        this.router.navigateByUrl('/database/criticalpoint/' + critical_point.id + '/detail');
    }

    private clear_critical_points(): void {
        if (this.critical_points) {
            this.critical_points.splice(0, this.critical_points.length);
        } else {
            this.critical_points = [];
        }
    }

    public createCriticalPoint(): void {
        this.router.navigateByUrl('/database/criticalpoint/create');
    }

}
