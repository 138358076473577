import {Injectable} from '@angular/core';
import {ProcessModel} from '../models/process.model';
import {Api} from '../now/api/api';

@Injectable()
export class ProcessService {

    private processes: ProcessModel[];

    constructor(private api: Api) {
        this.reset_process_list();
    }

    public set(list: ProcessModel[]): void {
        this.reset_process_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const process: ProcessModel = new ProcessModel();
                    process.clone(dat);
                    this.processes.push(process);
                }
            }
        }
    }

    public getProcesses(): Promise<ProcessModel[]> {
        let promise: Promise<ProcessModel[]>;
        promise = new Promise<ProcessModel[]>((resolve, reject) => {
            if (this.processes && this.processes.length > 0) {
                for (let i = 0; i < this.processes.length; i++) {
                    this.processes[i].checked = false;
                }
                resolve(this.processes);
            } else {
                this.api.request('processes', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_process_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    let process: ProcessModel;
                                    process = new ProcessModel();
                                    process.clone(dat);
                                    this.processes.push(process);
                                }
                            }
                            resolve(this.processes);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_process_list(): void {
        if (this.processes) {
            this.processes.splice(0, this.processes.length);
        } else {
            this.processes = [];
        }
    }

}
