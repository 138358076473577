import {Directive, HostListener} from '@angular/core';
import {Location} from '@angular/common';

@Directive({
    selector: '[nav-back]'
})
export class NavBackDirective {

    constructor(private location: Location) {
        //
    }

    @HostListener('click')
    private onClick(e?: any): void {
        this.location.back();
    }

}
