import {AfterViewInit, Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TaskModel} from '../../models/task.model';
import {SwalService} from '../../services/swal.service';
import {Api} from '../../now/api/api';

@Component({
    selector: 'report-modal-component',
    templateUrl: 'reportModal.component.html',
    styleUrls: ['reportModal.component.scss']
})
export class ReportModalComponent implements AfterViewInit {

    public task: TaskModel;
    public message: string;

    constructor(public bsModalRef: BsModalRef, private swal: SwalService, private api: Api) {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            this.swal.confirm('คุณต้องการแจ้งปัญหากับลูกค้าใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('mail/report', 'POST', null, {
                            task_id: this.task.id,
                            message: this.message
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.bsModalRef.content.message = this.message;
                                this.bsModalRef.content.is_submit = true;
                                this.bsModalRef.hide();
                            } else {
                                //
                            }
                        }, error => {
                            //
                        });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

}
