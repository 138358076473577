import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {QuotationModel} from '../../models/quotation.model';
import {TaskService} from '../../services/task.service';
import {Api} from '../../now/api/api';
import {TaskModel} from '../../models/task.model';
import {SwalService} from '../../services/swal.service';

@Component({
    selector: 'find-quotation-component',
    templateUrl: 'findQuotation.component.html',
    styleUrls: ['findQuotation.component.scss']
})
export class FindQuotationComponent implements AfterViewInit {

    public quotation: QuotationModel;
    public task: TaskModel;

    constructor(public bsModalRef: BsModalRef, public taskService: TaskService, private api: Api, private swal: SwalService) {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
        }, 0);
    }

    public getTasksByQuotation(quotation_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + quotation_id + '/quotation', 'POST', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data && response.data[0]) {
                    let task: TaskModel;
                    task = new TaskModel();
                    task.clone(response.data[0]);
                    resolve(task);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSelect(e: any): void {
        this.getTasksByQuotation(e.id)
            .then((task: TaskModel): void => {
                if (task) {
                    this.bsModalRef.content.task = task;
                    this.bsModalRef.content.submit = true;
                } else {
                    //
                }
                this.bsModalRef.hide();
            }, error => {
                this.swal.danger(error);
            });
    }

    public onSubmit(form: NgForm): void {
        //
    }

}
