import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {SwalService} from '../../../../services/swal.service';
import * as moment from 'moment';

@Component({
    selector: 'prepare-modal-component',
    templateUrl: 'prepareModal.component.html',
    styleUrls: ['prepareModal.component.scss']
})
export class PrepareModalComponent implements AfterViewInit {

    public tmp_item: any;
    public item: any;

    constructor(public bsModalRef: BsModalRef, private swal: SwalService) {
        this.tmp_item = {
            pivot: {
                remark: '',
                // prepared_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            }
        };
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.item) {
                this.item = this.bsModalRef.content.item;
                this.tmp_item.remark = this.item.remark;
            } else {
                //
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            /*this.swal.confirm('ยืนยันการเตรียมความพร้อมของวัสดุใช่หรือไม่')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.bsModalRef.content.submit = true;
                        this.item.pivot.prepared_at = this.tmp_item.pivot.prepared_at;
                        this.item.pivot.remark = this.tmp_item.pivot.remark;
                        this.bsModalRef.hide();
                    } else {
                        //
                    }
                });*/
            this.bsModalRef.content.submit = true;
            // this.item.pivot.prepared_at = this.tmp_item.pivot.prepared_at;
            this.item.remark = this.tmp_item.remark;
            this.bsModalRef.hide();
        } else {
            //
        }
    }

}
