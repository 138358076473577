import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

    private getItemByField(item: any, field_name: string): any {
        let field_names: string[];
        field_names = field_name.split('.');
        if (field_names && field_names.length === 1) {
            const to_lowercase = (item[field_name]) ? item[field_name].toLowerCase() : item[field_name];
            return to_lowercase;
        }
        // TODO: fix
        return item[field_name];
    }

    transform(items: any[], keyword: string, fields_string?: string): any[] {
        let fields: string[];
        if (fields_string) {
            fields = fields_string.split(',');
        } else {
            fields = [];
        }
        if (keyword) {
            const keyword_to_lowercase = keyword.toLowerCase();
            return items.filter(item => {
                if (fields && fields.length > 0) {
                    for (let i = 0; i < fields.length; i++) {
                        let field: string;
                        field = fields[i];
                        if (this.getItemByField(item, field) && this.getItemByField(item, field).indexOf(keyword_to_lowercase) > -1) {
                            return true;
                        }
                    }
                } else {
                    for (const key in item) {
                        if (key) {
                            let value: any;
                            value = item[key];
                            if (value) {
                                if (Array.isArray(value)) {
                                    for (let i = 0; i < value.length; i++) {
                                        const _value: string = value[i] + '';
                                        if (_value) {
                                            const value_to_lowercase = _value.toLowerCase();
                                            if (value_to_lowercase.indexOf(keyword_to_lowercase) > -1) {
                                                return true;
                                            }
                                        } else {
                                            //
                                        }
                                    }
                                } else {
                                    value = value + '';
                                    const value_to_lowercase = value.toLowerCase();
                                    if (value_to_lowercase.indexOf(keyword_to_lowercase) > -1) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
                return false;
            });
        } else {
            return items;
        }
    }

}
