import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Api} from '../../../now/api/api';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {SwalService} from '../../../services/swal.service';
import {TaskService} from '../../../services/task.service';
import {AuthService} from '../../../now/user/auth.service';
import {ModalService} from '../../../services/modal.service';
import {TaskModel} from '../../../models/task.model';
import {ActivatedRoute} from '@angular/router';
import {ViewTask} from '../view/viewTask';
import {JobModel} from '../../../models/job.model';
import {ProductModel} from '../../../models/product.model';
import {NcrModel} from '../../../models/ncr.model';
import {Viewer} from '../../../services/viewer';
import {Location} from '@angular/common';

@Component({
    selector: 'archive-task-component',
    templateUrl: 'archiveTask.component.html',
    styleUrls: ['archiveTask.component.scss']
})
export class ArchiveTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    public job: JobModel;
    public job_id: any;
    public product_id: any;
    public product: ProductModel;
    public ncr_id: any;
    public ncr: NcrModel;
    public current_job_document_path: string;

    constructor(
        public viewer: Viewer,
        public location: Location,
        public api: Api,
        public modelApi: ModelApi,
        public swal: SwalService,
        public taskService: TaskService,
        private route: ActivatedRoute,
        public authService: AuthService,
        public modal: ModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.ncr = new NcrModel();
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.onSuccess(this.task);
                        });
                }
            });
    }

    ngOnDestroy() {
        //
    }

    public archiveTask(): void {
        this.swal.confirm('ยืนยันการจัดเก็บงานใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('jobs/archive', 'POST', {}, {
                        id: this.job.id,
                        amount: (this.ncr && this.ncr.id) ? this.ncr.amount : this.job.product_amount
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.taskService.complete(this.task)
                                .then(() => {
                                    this.swal.success('จัดเก็บงานสำเร็จ');
                                    this.leave(true);
                                });
                        } else {
                            console.error(response);
                            this.swal.danger(response);
                        }
                    }, error => {
                        console.error(error);
                        this.swal.danger('เกิดข้อผิดพลาด โปรดแจ้งทางผู้ดูแลระบบ');
                    });
                }
            });
    }

    public onSuccess(task: TaskModel): void {
        this.job_id = this.task.modelable_id;
        this.getJob(this.job_id)
            .then(() => {
                if (this.job && this.job.product_id) {
                    this.product_id = this.job.product_id;
                    this.getProductDetail()
                        .then(() => {
                            this.getNCRDetail()
                                .then(() => {
                                    this.viewer.manufacture(this.job, false, this.job.revision)
                                        .then(path => {
                                            this.current_job_document_path = path;
                                            this.doReady();
                                        });
                                });
                        });
                } else {
                    //
                }
            });
    }

    private getNCRDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('ncrs/' + this.ncr_id)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.ncr.clone(response.data);
                        resolve();
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

}
