import {Injectable} from '@angular/core';
import {ProductModel} from '../models/product.model';
import {Api} from '../now/api/api';

@Injectable()
export class ProductService {

    constructor(private api: Api) {
        //
    }

    public assemblies(product_id: string, job_id?: string): Promise<ProductModel[]> {
        let promise: Promise<ProductModel[]>;
        promise = new Promise<ProductModel[]>((resolve, reject) => {
            let assemblies: ProductModel[];
            assemblies = [];
            let params: any;
            if (job_id) {
                params = {
                    job_id: job_id
                };
            } else {
                params = {};
            }
            this.api.request('products/' + product_id + '/assemblies', 'GET', params)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            let assembly: ProductModel;
                            assembly = new ProductModel();
                            assembly.clone(dat);
                            assemblies.push(assembly);
                        }
                        console.log(assemblies);
                        resolve(assemblies);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

}
