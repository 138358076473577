import {Model} from './model';

export class FormModel extends Model {

    index?: number;
    checked?: boolean;
    pivot: any;
    current_role?: string;
    prepare?: boolean;

    constructor(table_name?: string, model_name?: string) {
        super(table_name, model_name);
        this.pivot = {};
    }

    public clone(data: any, without: string[] = [], _delete?: boolean): Model {
        if (data && data.pivot && data.pivot.current_role) {
            this.current_role = data.pivot.current_role;
        }
        return super.clone(data, without, _delete);
    }

}
