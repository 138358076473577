import {AfterViewInit, Component} from '@angular/core';
import {Api} from '../../../../now/api/api';
import {TaskModel} from '../../../../models/task.model';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';

@Component({
    selector: 'shipping-task-component',
    templateUrl: 'shippingTask.component.html',
    styleUrls: ['shippingTask.component.scss']
})
export class ShippingTaskComponent implements AfterViewInit {

    public customers: any[];
    public tasks: TaskModel[];
    public customer_id: string;
    public process_slug: string;
    public task_status: number;
    public job: JobModel;

    constructor(
        private api: Api,
        public bsModalRef: BsModalRef,
        private swal: SwalService
    ) {
        //
        this.tasks = [];
        this.customers = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.process_slug   = 'accounting';
            this.task_status    = 10;
            if (this.bsModalRef && this.bsModalRef.content) {
                if (this.bsModalRef.content.process_slug) {
                    this.process_slug = this.bsModalRef.content.process_slug;
                } else {
                    //
                }
            }
            this.getShippingTasks()
                .then(() => {
                    //
                });
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        this.swal.confirm('ยืนยันการออกใบแจ้งหนี้ตามงานที่ถูกเลือกทั้งหมดนี้ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    let _tasks: TaskModel[];
                    _tasks = [];
                    for (let i = 0; i < this.tasks.length; i++) {
                        if (this.tasks[i]._checked) {
                            _tasks.push(this.tasks[i]);
                        }
                    }
                    if (_tasks.length > 0) {
                        this.bsModalRef.content.tasks = _tasks;
                        this.bsModalRef.hide();
                    }
                } else {
                    //
                }
            });
    }

    public getShippingTasks(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('tasks/customer', 'GET', {
                process_slug    : 'shipping'
            }).subscribe((response: any): void => {
                this.tasks = [];
                this.customers = [];
                for (const key in response.data) {
                    if (key && response.data[key]) {
                        this.customers.push({
                            name: key,
                            tasks: response.data[key]
                        });
                    }
                }
                console.log(this.customers);
                resolve(this.customers);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public get num_tasks(): number {
        let total: number;
        total = 0;
        for (let i = 0; i < this.tasks.length; i ++) {
            if (this.tasks[i]._checked) {
                total++;
            }
        }
        return total;
    }

}
