import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {DivApiDirective} from '../../../now/divApi';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {map} from 'rxjs/operators';
import {AppService} from '../../../app/app.service';
import * as Highcharts from 'highcharts';
import {CustomerModel} from '../../../models/customer.model';
import {DecimalPipe} from '@angular/common';

@Component({
    selector: 'prod-report-page',
    templateUrl: 'prodReport.page.html',
    styleUrls: ['prodReport.page.scss'],
    providers: [DecimalPipe]
})
export class ProdReportPage implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;
    public divApi: DivApiDirective;

    public tmp_customer: CustomerModel;
    public date1: any;
    public date2: any;
    public customer_id: any;
    public getting: boolean;
    public num_ng: number;
    public num_non_ng: number;
    public num_jobs: number;
    public updateFlag: boolean;

    public keyword: string;
    public date: any;
    public current_view: string;
    public ready: boolean;
    public current_zoom: number;
    public rowData: any;
    public columnDefs: any;
    public jobs: any[];
    public cellStyle1: any;
    public cellStyle2: any;
    public expected_percentage: number;
    public ng_percentage: number;
    public num_expected: number;
    public num_unexpected: number;
    public unexpected: number;
    public current_date: any;
    public chartOptions: any;
    public item_data: any[];
    public Highcharts: typeof Highcharts = Highcharts;
    public rowClassRules: any;

    constructor(
        private loader: LoaderService,
        private api: Api,
        private decimalPipe: DecimalPipe,
        private appService: AppService
    ) {
        //
        this.current_view = 'YEARLY';
        this.date1 = moment(new Date());
        this.date2 = moment(new Date()).add(-1, 'year');
        this.current_zoom = 100;
        this.rowData = [];
        this.columnDefs = [];
        this.jobs = [];
        this.updateFlag = false;

        this.cellStyle1 = (params) => {
            if (params && params.data && params.data.expected !== true) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else if (params && params.data && params.data.expected === true) {
                return {
                    'background-color': '#81C784',
                    'color': '#ffffff'
                };
            }
        };

        this.rowClassRules = {
            'border-top-2': function(params) {
                return params.data.job_no;
            }
        };

        this.item_data = [];
        this.columnDefs.push(
            {
                editable: false,
                headerName: 'NO',
                field: 'no',
                width: 40
            },
            {
                editable: false,
                headerName: 'JOB NO',
                field: 'job_no',
                width: 140
            },
            {
                editable: false,
                headerName: 'ลูกค้า',
                field: 'customer_name',
                width: 140
            },
            {
                editable: false,
                headerName: 'Planned Date',
                field: 'planning_started_at',
                width: 140
            },
            /*{
                editable: false,
                headerName: 'NCR NO',
                field: 'ncr_no',
                width: 140
            },
            {
                headerName: 'กระบวนการที่เกิด NCR',
                field: 'process_slug',
                width: 100,
            },*/
            {
                headerName: 'Prod Process',
                field: 'prod_process_slug',
                width: 100,
            },
            /*{
                editable: false,
                headerName: 'งานทั้งหมด',
                field: 'product_amount',
                width: 60,
            },
            {
                editable: false,
                headerName: 'งานดี',
                field: 'success_amount',
                width: 60,
            },
            {
                editable: false,
                headerName: 'งานเสีย',
                field: 'fail_amount',
                width: 60,
            },
            {
                editable: false,
                headerName: 'NCR STATUS',
                field: 'status',
                width: 60,
                cellStyle: this.cellStyle1
            },
            {
                editable: false,
                headerName: 'วันที่คาดว่าจะเสร็จ',
                field: 'delivery_date',
                width: 100,
            },
            {
                editable: false,
                headerName: 'วันที่งานเสร็จ',
                field: 'completed_at',
                width: 100,
            },*/
            {
                editable: false,
                headerName: 'เวลาเริ่มที่วางแผน',
                field: 'action_plan_start',
                width: 140,
            },
            {
                editable: false,
                headerName: 'เวลาสิ้นสุดที่วางแผน',
                field: 'action_plan_end',
                width: 140,
            },
            {
                editable: false,
                headerName: 'ควรใช้เวลา',
                field: 'action_plan_minutes',
                width: 60,
            },
            {
                editable: false,
                headerName: 'เริ่มทำงาน',
                field: 'start',
                width: 140,
            },
            {
                editable: false,
                headerName: 'สิ้นสุดทำงาน',
                field: 'end',
                width: 140,
            },
            {
                editable: false,
                headerName: 'ใช้เวลา',
                field: 'minutes',
                width: 60,
            },
            {
                editable: false,
                headerName: 'GAP TIME',
                field: 'gap_time_minutes',
                width: 60,
            },
            {
                editable: false,
                headerName: 'STATUS',
                field: 'control_status',
                width: 60,
                cellStyle: this.cellStyle1
            }
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
        });
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public getData(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            if (this.jobs) {
                this.jobs.splice(0, this.jobs.length);
            } else {
                this.jobs = [];
            }
            this.rowData = [];
            this.ready = false;
            this.api.request('report/prod/1', 'POST', {}, {
                date1       : (moment.isMoment(this.date1)) ? this.date1.format('YYYY-MM-DD') : this.date1,
                date2       : (moment.isMoment(this.date2)) ? this.date2.format('YYYY-MM-DD') : this.date2,
                view        : this.current_view,
                customer_id : (this.customer_id) ? this.customer_id : '',
            })
                .pipe(
                    map((response: any): void => {
                        if (response && response.success === true) {
                            return response.data;
                        }
                        return null;
                    })
                )
                .toPromise()
                .then((data: any): void => {
                    if (data) {
                        this.item_data = [
                            {
                                name: 'NON-NG',
                                y: 0,
                            },
                            {
                                name: 'NG',
                                y: 0,
                                sliced: true,
                                selected: true,
                            }
                        ];

                        let i: number;
                        i = 0;
                        this.num_expected = 0;
                        this.num_unexpected = 0;
                        this.ng_percentage = 0;

                        this.num_jobs = 0;
                        this.num_ng = 0;
                        this.num_non_ng = 0;

                        for (const key in data) {
                            let last_action_plan_end, last_end;
                            last_action_plan_end = last_end = null;
                            let total_minutes, total_scheduler_minutes;
                            total_minutes = total_scheduler_minutes = 0;
                            let index_expected: number[];
                            index_expected = [];
                            const value = data[key];
                            let expected: boolean;
                            expected = true;
                            if (value && key) {
                                i++;
                                this.jobs.push(value);
                                const planning_started_at = (value.planning_started_at) ? value.planning_started_at.split(' ')[0] : '';
                                index_expected.push(this.rowData.push({
                                    no                  : i,
                                    job_no              : key,
                                    customer_name       : value.customer_name,
                                    planning_started_at : planning_started_at,
                                    ncr_no              : value.ncr_no,
                                    prod_process_slug   : (value.action_plans && value.action_plans[0]) ? value.action_plans[0].name : '-',
                                    action_plan_start   : (value.action_plans && value.action_plans[0]) ? value.action_plans[0].start : '-',
                                    action_plan_end     : (value.action_plans && value.action_plans[0]) ? value.action_plans[0].end : '-',
                                    action_plan_minutes : (value.action_plans && value.action_plans[0]) ? value.action_plans[0].minutes : '-',
                                    start               : (value.schedulers && value.schedulers[0]) ? value.schedulers[0].start : '-',
                                    end                 : (value.schedulers && value.schedulers[0]) ? value.schedulers[0].end : '-',
                                    gap_time_minutes    : (value.schedulers && value.schedulers[0]) ? this.decimalPipe.transform(value.schedulers[0].minutes, '.0-2') : '-',
                                    minutes             : (value.schedulers && value.schedulers[0]) ? this.decimalPipe.transform(value.schedulers[0].minutes, '.0-2') : '-',
                                    expected            : true,
                                    control_status      : 'PASS'
                                }) - 1);
                                last_action_plan_end = (value.action_plans && value.action_plans[0]) ? value.action_plans[0].end : null;
                                total_minutes += (value.action_plans && value.action_plans[0]) ? value.action_plans[0].minutes : 0;
                                last_end = (value.schedulers && value.schedulers[0]) ? value.schedulers[0].end : null;
                                total_scheduler_minutes += (value.schedulers && value.schedulers[0] && value.schedulers[0].minutes > 0) ? value.schedulers[0].minutes : 0;
                                if (value.action_plans) {
                                    let j: number;
                                    j = 1;
                                    for (j = 1; j < value.action_plans.length; j++) {
                                        const action_plan = value.action_plans[j];
                                        if (action_plan) {
                                            index_expected.push(this.rowData.push({
                                                prod_process_slug   : (action_plan.name) ? action_plan.name : '-',
                                                action_plan_start   : action_plan.start,
                                                action_plan_end     : action_plan.end,
                                                action_plan_minutes : action_plan.minutes,
                                                start               : (value.schedulers && value.schedulers[j]) ? value.schedulers[j].start : '-',
                                                end                 : (value.schedulers && value.schedulers[j]) ? value.schedulers[j].end : '-',
                                                gap_time_minutes    : (value.schedulers && value.schedulers[j]) ? this.decimalPipe.transform(value.schedulers[j].gap_time_minutes, '.0-2')  : '-',
                                                minutes             : (value.schedulers && value.schedulers[j]) ? this.decimalPipe.transform(value.schedulers[j].minutes, '.0-2') : '-',
                                                expected            : true,
                                                control_status      : 'PASS'
                                            }) - 1);
                                            last_action_plan_end = action_plan.end;
                                            total_minutes = action_plan.minutes;
                                            if (value.schedulers && value.schedulers[j] && value.schedulers && value.schedulers[j].end) {
                                                last_end = value.schedulers[j].end;
                                            }
                                            total_scheduler_minutes += (value.schedulers && value.schedulers[j] && value.schedulers[j].minutes > 0) ? value.schedulers[j].minutes : 0;
                                        }
                                    }
                                    if (value.schedulers && j < value.schedulers.length) {
                                        for (let n = j; n < value.schedulers.length; n++) {
                                            const scheduler = value.schedulers[n];
                                            if (scheduler) {
                                                index_expected.push(this.rowData.push({
                                                    start               : scheduler.start,
                                                    end                 : scheduler.end,
                                                    gap_time_minutes    : this.decimalPipe.transform(scheduler.gap_time_minutes, '.0-2'),
                                                    minutes             : this.decimalPipe.transform(scheduler.minutes, '.0-2'),
                                                    expected            : true,
                                                    control_status      : 'PASS'
                                                }) - 1);
                                            }
                                        }
                                    }
                                }
                                // checking the expected status
                                // console.log(total_scheduler_minutes, total_minutes, last_action_plan_end, last_end);
                                if (total_scheduler_minutes && total_minutes && last_action_plan_end && last_end) {
                                    const moment_last_action_plan_end = +moment(last_action_plan_end, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDDHHmm');
                                    const moment_last_end = +moment(last_end, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDDHHmm');
                                    if (moment_last_end - moment_last_action_plan_end > 0 || total_scheduler_minutes > total_minutes) {
                                        expected = false;
                                        if (index_expected && index_expected.length) {
                                            for (let n = 0; n < index_expected.length; n++) {
                                                this.rowData[index_expected[n]].expected = expected;
                                                this.rowData[index_expected[n]].control_status = 'FAIL';
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        /*for (const dat of data) {
                            if (dat) {
                                i++;
                                this.jobs.push(dat);
                                const delivery_date = moment(dat.delivery_date, 'YYYY-MM-DD HH:mm:ss');
                                const completed_at = moment(dat.completed_at, 'YYYY-MM-DD HH:mm:ss');
                                const expected = (dat.ncr_no) ? false : true;
                                const scheduler_history_start = (dat && dat.scheduler_history && dat.scheduler_history.start) ? dat.scheduler_history.start : '';
                                const planning_started_at = (dat && dat.planning_started_at) ? dat.planning_started_at.split(' ')[0] : '';

                                // const expected = (dat.delivery_date && delivery_date && dat.completed_at && completed_at) ? (+delivery_date.format('YYYYMMDD') - +completed_at.format('YYYYMMDD') >= 0) ? true : false : null;
                                // const success_percentage = Math.floor(dat.total_success_amount / dat.total_amount * 100 * 100) / 100;

                                this.rowData.push({
                                    no: i,
                                    id: dat.id,
                                    customer_name: (dat && dat.customer_name) ? dat.customer_name : '-',
                                    job_no: dat.job_no,
                                    ncr_no: dat.ncr_no,
                                    planning_started_at: planning_started_at,
                                    process_slug: ((dat.process_slug) ? dat.process_slug : '-').toUpperCase(),
                                    prod_date: (dat && dat.scheduler_history) ? scheduler_history_start : '-',
                                    prod_process_slug: (dat && dat.scheduler_history) ? ((dat.scheduler_history.process_slug) ? dat.scheduler_history.process_slug : '-').toUpperCase() : '-',
                                    product_amount: +dat.product_amount,
                                    fail_amount: +dat.amount,
                                    success_amount: +dat.product_amount - +dat.amount,
                                    status: (expected === true) ? 'PASS' : 'FAIL',
                                    expected: expected
                                });
                                this.num_jobs++;
                                if (expected === true) {
                                    this.num_non_ng++;
                                } else {
                                    this.num_ng++;
                                }

                                // this.rowData.push({
                                //     no: i,
                                //     id: dat.id,
                                //     job_no: dat.job_no,
                                //     total_amount: dat.total_amount,
                                //     total_fail_amount: dat.total_fail_amount,
                                //     total_success_amount: dat.total_success_amount,
                                //     summary_amount: success_percentage,
                                //     delivery_date: (dat.delivery_date && delivery_date) ? delivery_date.format('DD/MM/YYYY') : '-',
                                //     completed_at: (dat.completed_at && completed_at) ? completed_at.format('DD/MM/YYYY') : '-',
                                //     summary_date : (expected === true) ? 'PASS' : (expected === false) ? 'FAIL' : '-',
                                //     unexpected: (expected === false) ? true : false
                                // });
                                // this.ng_percentage += success_percentage;
                                // if (expected === true) {
                                //     this.num_expected++;
                                // } else if (expected === false) {
                                //     this.num_unexpected++;
                                // }
                            }
                        }*/
                    }

                    // console.log(this.num_jobs, this.num_non_ng, this.num_ng);

                    this.item_data[0].y = (this.num_non_ng * 100) / this.num_jobs;
                    this.item_data[1].y = (this.num_ng * 100) / this.num_jobs;

                    // this.ng_percentage = 100 - ((this.ng_percentage / (this.jobs.length * 100)) * 100);
                    // this.expected_percentage = (this.num_expected / this.jobs.length) * 100;
                    // this.item_data.push(
                    //     {
                    //         name: 'JOB',
                    //         data: [
                    //             {
                    //                 name: 'ตามแผน',
                    //                 y: this.num_expected
                    //             },
                    //             {
                    //                 name: 'หลุดแผน',
                    //                 y: this.num_unexpected
                    //             },
                    //             {
                    //                 name: 'กำลังดำเนินการ',
                    //                 y: this.jobs.length - this.num_expected - this.num_unexpected
                    //             }
                    //         ]
                    //     }
                    // );

                    this.chartOptions = {
                        mapNavigation: {
                            enableMouseWheelZoom: true
                        },
                        time: {
                            timezone: 'Asia/Bangkok',
                            useUTC: false,
                        },
                        chart: {
                            type: 'pie',
                            backgroundColor: '#ffffff'
                        },
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                        },
                        accessibility: {
                            point: {
                                valueSuffix: '%'
                            }
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                }
                            }
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        series: [{
                            name: 'Job',
                            data: this.item_data
                        }]
                    };

                    this.ready = true;
                    setTimeout(() => {
                        this.updateFlag = true;
                    }, 150);
                    resolve();
                }, error => {
                    //
                });
        });
        return promise;
    }

    public get_date(): string {
        if (this.current_view === 'YEARLY') {
            return this.date.format('YYYY');
        } else if (this.current_view === 'MONTHLY') {
            return this.date.format('MM/YYYY');
        } else if (this.current_view === 'DAILY') {
            return this.date.format('DD/MM/YYYY');
        }
        return '';
    }

    public onSuccess(e: any): void {
        this.ready = true;
    }

    public onError(e: any): void {
        //
    }

    public onViewChange(e: any): void {
        if (e === 'DAILY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'day');
        } else if (e === 'MONTHLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'month');
        } else if (e === 'QUARTERLY') {
            // this.date1 = moment(new Date());
            // this.date2 = moment(new Date()).add(-1, 'year');
        } else if (e === 'YEARLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'year');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public onDateChange(e: any, date: any): void {
        if (this.current_view === 'DAILY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        } else if (this.current_view === 'MONTHLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
        } else if (this.current_view === 'YEARLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh(): void {
        if (this.getting) {
            //
        } else {
            this.loader.show();
            this.getting = true;
            this.getData()
                .then(() => {
                    this.loader.hide();
                    this.getting = false;
                });
        }
    }

    public onCustomerChange(e: any): void {
        this.customer_id = (e && e.id) ? e.id : '';
        this.refresh();
    }

    public get contentHeight(): string {
        const window_height = window.innerHeight;
        const _height = '' + (((window_height * (100 / this.current_zoom)) - 0 - 0) * .5) + 'px';
        return _height;
    }

}
