import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {CustomerModel} from '../../../models/customer.model';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {AppService} from '../../../app/app.service';
import {DecimalPipe} from '@angular/common';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {ColumnListModal} from './columnList/columnList.modal';
import {LocalStorageService} from '../../../services/localStorageService';
import {ProcessService} from '../../../services/process.service';
import {Viewer} from '../../../services/viewer';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'overall-report-page',
    templateUrl: 'overallReport.page.html',
    styleUrls: ['overallReport.page.scss'],
    providers: [DecimalPipe]
})
export class OverallReportPage implements AfterViewInit, OnDestroy {

    @ViewChild('gridAngular', { static: false }) gridAngular;
    @ViewChild('columnListModal', { static: false }) columnListModal: ColumnListModal;
    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;
    public divApi: DivApiDirective;

    public filterText: string;
    public process_slug: string;
    public tmp_process: any;

    public date1: any;
    public date2: any;
    public customer_id: any;
    public getting: boolean;
    public process_type: string;

    public rowData1: any;
    public rowData2: any;
    public rowData3: any;
    public rowData4: any;
    public tmp_customer: CustomerModel;
    public processes: any[];
    public keyword: string;
    public date: any;
    public current_view: string;
    public ready: boolean;
    public current_zoom: number;
    public columnDefs: any;
    public jobs: any[];
    public unexpected: number;
    public current_date: any;
    public chartOptions: any;
    public item_data: any[];

    constructor(
        private loader: LoaderService,
        private api: Api,
        private viewer: Viewer,
        private processService: ProcessService,
        private storage: LocalStorageService,
        private appService: AppService,
        private decimalPipe: DecimalPipe
    ) {
        //
        this.current_view = 'MONTHLY';
        // this.current_view = 'YEARLY';
        this.date1 = moment(new Date());
        this.date2 = moment(new Date()).add(-1, 'year');
        this.process_type = '';

        this.current_zoom = 100;
        this.rowData1 = [];
        this.rowData2 = [];
        this.rowData3 = [];
        this.rowData4 = [];
        this.columnDefs = [];
        this.jobs = [];
        this.processes = [];
        this.process_slug = '';

        this.tmp_customer = new CustomerModel();
        this.item_data = [];
        this.columnDefs = [
            {
                editable: false,
                headerName: '#',
                field: 'index',
                width: 40
            },
            {
                editable: false,
                headerName: 'สถานะปัจจุบัน',
                field: 'current_role',
                filter: 'agSetColumnFilter',
                width: 160,
                cellStyle: (params) => {
                    if (params && params.data && (params.data.current_role === 'SHIPPING' || params.data.current_role === 'SHIPPING-DOCUMENT')) {
                        return {
                            'background-color': '#4caf50',
                            'color': '#ffffff'
                        };
                    } else {
                        return {
                            'background-color': '#ffffff'
                        };
                    }
                }
            },
            {
                editable: false,
                headerName: 'JOB NO',
                field: 'job_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Customer',
                field: 'customer_name',
                minWidth: 240,
                suppressSizeToFit: true,
            },
            {
                editable: false,
                headerName: 'P/O No',
                field: 'purchase_order_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'QT No',
                field: 'quotation_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Contact Log No',
                field: 'contact_ref',
                width: 120
            },
            {
                editable: false,
                headerName: 'Name',
                field: 'product_name',
                width: 160
            },
            {
                editable: false,
                headerName: 'S/N No',
                field: 'product_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'BOI',
                field: 'product_boi',
                width: 60
            },
            {
                editable: false,
                headerName: 'DWG No',
                field: 'drawing_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Description',
                field: 'loaded_remark',
                minWidth: 120,
                suppressSizeToFit: true,
            },
            {
                editable: false,
                headerName: 'วันที่รับงาน',
                field: 'purchase_order_date',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่ลูกค้าต้องการ',
                field: 'customer_required_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่ Sale Admin ต้องการ',
                field: 'required_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่ขนส่งจาก Shipping',
                field: 'shipping_date',
                width: 120,
                cellStyle: (params) => {
                    if (params && params.data) {
                        const shipping_date_calculate = params.data.shipping_date_calculate;
                        const required_at_calculate = params.data.required_at_calculate;
                        if (shipping_date_calculate && required_at_calculate && shipping_date_calculate <=  required_at_calculate) {
                            return {
                                'background-color': '#4caf50',
                                'color': '#ffffff'
                            };
                        } else if (shipping_date_calculate && required_at_calculate && shipping_date_calculate >  required_at_calculate) {
                            return {
                                'background-color': '#ff0000',
                                'color': '#ffffff'
                            };
                        }
                    }
                    return {
                        'background-color': '#ffffff'
                    };
                }
            },
            {
                editable: false,
                headerName: 'กำหนดส่งจาก Sale Admin',
                field: 'confirmed_delivery_date',
                width: 120
            },
            {
                editable: false,
                headerName: 'กำหนดส่งมอบจาก PN',
                field: 'delivery_date',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่เข้า FG',
                field: 'finished_goods_at',
                width: 120,
                cellStyle: (params) => {
                    if (params && params.data) {
                        const delivery_date_calculate = params.data.delivery_date_calculate;
                        const finished_goods_at_calculate = params.data.finished_goods_at_calculate;
                        if (delivery_date_calculate && finished_goods_at_calculate && finished_goods_at_calculate <=  delivery_date_calculate) {
                            return {
                                'background-color': '#4caf50',
                                'color': '#ffffff'
                            };
                        } else if (delivery_date_calculate && finished_goods_at_calculate && finished_goods_at_calculate >  delivery_date_calculate) {
                            return {
                                'background-color': '#ff0000',
                                'color': '#ffffff'
                            };
                        }
                    }
                    return {
                        'background-color': '#ffffff'
                    };
                }
            },
            {
                editable: false,
                headerName: 'ORDER ลูกค้า',
                field: 'customer_product_amount',
                width: 60
            },
            {
                editable: false,
                headerName: 'สั่งผลิต',
                field: 'sale_product_amount',
                width: 60
            },
            {
                editable: false,
                headerName: 'ผลิตจริง',
                field: 'product_amount',
                width: 60
            },
            {
                editable: false,
                headerName: 'Unit',
                field: 'unit',
                width: 60
            },
            /*{
                editable: false,
                headerName: 'Price/Unit',
                field: 'price_per_unit',
                width: 60
            },*/
            {
                editable: false,
                headerName: 'Delivery Note No',
                field: 'delivery_note_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Invoice No',
                field: 'invoice_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Receipt No',
                field: 'receipt_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'Tax Invoice No',
                field: 'tax_invoice_no',
                width: 120
            },
            {
                editable: false,
                headerName: 'NCRs',
                field: 'ncr_code',
                width: 60
            },
            {
                editable: false,
                headerName: 'CARs',
                field: 'num_cars',
                width: 60
            },
            {
                editable: false,
                headerName: 'Revision',
                field: 'revision',
                width: 60
            },
            {
                editable: false,
                headerName: 'Processing Time',
                field: 'sum_minutes',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'GAP Time',
                field: 'sum_gap_time_minutes',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Total Time',
                field: 'sum_total_minutes',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'ยอดสุทธิ',
                field: 'payment_grand_total',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            // {
            //     editable: false,
            //     headerName: 'VAT 7%',
            //     field: 'payment_vat',
            //     width: 80,
            //     cellStyle: {
            //         'justify-content': 'flex-end'
            //     }
            // },
            {
                editable: false,
                headerName: 'Sale ประเมิน',
                field: 'sale_estimated_price',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Planning ประเมิน',
                field: 'planning_estimated_price',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'ผลการทำงานจริง',
                field: 'action_price',
                width: 80,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'สรุปผล',
                field: 'summary_price_text',
                width: 80,
                cellStyle: (params) => {
                    if (params && params.data && params.data.summary_price < 0) {
                        return {
                            'justify-content': 'flex-end',
                            'background-color': '#f44336',
                            'color': '#ffffff'
                        };
                    } else {
                        return {
                            'justify-content': 'flex-end',
                            'background-color': '#ffffff'
                        };
                    }
                }
            },
        ];
    }

    ngAfterViewInit(): void {
        this.appService.fixPage();
        this.getAllData()
            .then(() => {
                //
            });
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public onFilterChange(event) {
        // console.log(this.gridAngular);
        // gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    public onProcessTypeChange(event: any): void {
        this.refresh();
    }

    private getAllData(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('database/consts')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        if (response.data.processes) {
                            this.processes = response.data.processes;
                        }
                    }
                    resolve();
                });
        });
        return promise;
    }

    public columnList(): void {
        this.columnListModal.open();
    }

    public exportList(): void {
        const getParams = (_: any): string => {
            const query_array = [];
            const params_object = _;
            for (const key in params_object) {
                if (key) {
                    const value = params_object[key];
                    query_array.push(key + '=' + value);
                }
            }
            if (query_array && query_array.length > 0) {
                const query_string = '?' + query_array.join('&');
                return query_string;
            }
            return '';
        };
        this.loader.show();
        this.viewer.readRequest()
            .then((data: any) => {
                this.loader.hide();
                window.open(environment.api_host + 'export/report/overall' + getParams({
                    token       : data.token,
                    date1       : (moment.isMoment(this.date1)) ? this.date1.format('YYYY-MM-DD') : this.date1,
                    date2       : (moment.isMoment(this.date2)) ? this.date2.format('YYYY-MM-DD') : this.date2,
                    view        : this.current_view,
                    customer_id : (this.customer_id) ? this.customer_id : '',
                    process_slug: (this.process_slug) ? this.process_slug : '',
                    process_type: (this.process_type) ? this.process_type : ''
                }), '_blank');
            }, error => {
                this.loader.hide();
            });
    }

    public onSubmitForm(e: any): void {
        this.ready = false;
        this.refreshColumn();
        setTimeout(() => {
            this.ready = true;
        }, 1000);
    }

    public refreshColumn(): void {
        let column_list: any;
        column_list = this.storage.retrieve('columnList');
        column_list = (column_list && column_list !== 'null') ? column_list.split(',') : [];
        if (column_list && Array.isArray(column_list) && column_list.length) {
            for (const column of this.columnDefs) {
                const exist_column = column_list.find(i => i === column.field);
                if (column && exist_column) {
                    column.hide = false;
                } else if (column) {
                    column.hide = true;
                }
            }
        } else {
            for (const column of this.columnDefs) {
                if (column) {
                    column.hide = false;
                }
            }
        }
    }

    public onProcessSelect(e: any): void {
        this.process_slug = (e && e.id) ? e.slug : '';
        this.refresh();
    }

    private calculate_action_price(dat: any): any {
        let total: number;
        total = 0;
        if (dat && dat.scheduler_histories) {
            for (const scheduler_history of dat.scheduler_histories) {
                if (scheduler_history) {
                    const minutes = (scheduler_history.minutes > 0) ? scheduler_history.minutes : 0;
                    const user_price = (scheduler_history.user && scheduler_history.user.price > 0) ? scheduler_history.user.price : 0;
                    const machine_price = (scheduler_history.product_process && scheduler_history.product_process.machine && scheduler_history.product_process.machine.price > 0) ? scheduler_history.product_process.machine.price : 0;
                    total += minutes * (user_price + machine_price);
                }
            }
        }
        return total;
    }

    private cal_sum_gap_time_minutes(dat: any): number {
        let cal_sum: number;
        cal_sum = 0;
        if (dat && dat.scheduler_histories) {
            for (const scheduler_history of dat.scheduler_histories) {
                if (scheduler_history && scheduler_history.gap_time_minutes > 0) {
                    cal_sum += scheduler_history.gap_time_minutes;
                }
            }
        }
        return cal_sum;
    }

    private cal_sum_minutes(dat: any): number {
        let cal_sum: number;
        cal_sum = 0;
        if (dat && dat.scheduler_histories) {
            for (const scheduler_history of dat.scheduler_histories) {
                if (scheduler_history && scheduler_history.minutes > 0) {
                    cal_sum += scheduler_history.minutes;
                }
            }
        }
        return cal_sum;
    }

    private cal_sum_total_minutes(dat: any): number {
        let cal_sum: number;
        cal_sum = 0;
        if (dat && dat.scheduler_histories) {
            for (const scheduler_history of dat.scheduler_histories) {
                if (scheduler_history && scheduler_history.gap_time_minutes) {
                    cal_sum += scheduler_history.gap_time_minutes;
                }
            }
        }
        return cal_sum;
    }

    private getPaymentVAT(dat: any): number {
        let vat_price = null;
        if (dat && dat.tax_invoice_products && dat.tax_invoice_products[0]) {
            const product = dat.tax_invoice_products[0];
            if (product.tax_invoice) {
                const tax_invoice = product.tax_invoice;
                if (tax_invoice.vat_percent > 0) {
                    if (!tax_invoice.is_include_vat) {
                        vat_price = product.price * tax_invoice.vat_percent / 100;
                    } else {
                        // this.discount_price = Math.round(((sub_total * _discount_percent) / 100) * 100) / 100;
                        vat_price = product.price - (product.price / (1 + (tax_invoice.vat_percent / 100)));
                    }
                } else {
                    vat_price = 0;
                }
            }
        } else if (dat && dat.quotation) {
            const quotation = dat.quotation;
            if (quotation && quotation.vat_percent > 0) {
                const products = quotation.products;
                const product = this.findProductInQuotation(dat.product_id, products);
                if (product) {
                    if (!quotation.is_include_vat) {
                        vat_price = product.price * quotation.vat_percent / 100;
                    } else {
                        vat_price = product.price - (product.price / (1 + (quotation.vat_percent / 100)));
                    }
                }
            } else {
                vat_price = 0;
            }
        }
        vat_price = (vat_price > 0) ? vat_price.toFixed(2) : vat_price;
        // console.log(vat_price);
        return vat_price;
    }

    private findProductInQuotation(product_id, products) {
        if (products && products.length) {
            for (const product of products) {
                if (product && product.id === product_id) {
                    return product;
                }
            }
        }
        return null;
    }

    public getData(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.rowData1 = [];
            this.jobs = [];
            this.api.request('report/overall', 'POST', {}, {
                date1       : (moment.isMoment(this.date1)) ? this.date1.format('YYYY-MM-DD') : this.date1,
                date2       : (moment.isMoment(this.date2)) ? this.date2.format('YYYY-MM-DD') : this.date2,
                view        : this.current_view,
                customer_id : (this.customer_id) ? this.customer_id : '',
                process_slug: (this.process_slug) ? this.process_slug : '',
                process_type: (this.process_type) ? this.process_type : ''
            })
                .pipe(
                    map((response: any): void => {
                        if (response && response.success === true) {
                            return response.data;
                        }
                        return null;
                    })
                )
                .toPromise()
                .then((data: any): void => {
                    this.rowData1 = [];
                    if (data) {
                        let n: number;
                        n = 1;
                        for (const dat of data) { // kpi1
                            const sale_estimated_price = dat.sale_estimated_price;
                            const action_price = this.calculate_action_price(dat);
                            const cal_sum_gap_time_minutes = this.cal_sum_gap_time_minutes(dat);
                            const cal_sum_minutes = this.cal_sum_minutes(dat);
                            dat.sale_estimated_price = (sale_estimated_price) ? this.decimalPipe.transform(sale_estimated_price, '.2-4') : '';
                            dat.planning_estimated_price = (dat.planning_estimated_price) ? this.decimalPipe.transform(dat.planning_estimated_price, '.2-4') : '';
                            dat.action_price = this.decimalPipe.transform(action_price, '.2-4');
                            dat.summary_price = sale_estimated_price - action_price;
                            dat.current_role = (dat.current_role) ? dat.current_role.toUpperCase() : '-';
                            dat.summary_price_text = this.decimalPipe.transform(dat.summary_price, '.2-4');
                            dat.ncr_code = (dat.ncr_code > 0) ? dat.ncr_code : '-';
                            const _dat = Object.assign({
                                index: n,
                                customer_name: (dat.customer) ? dat.customer.name : '',
                                product_name: (dat.product_without_pp) ? dat.product_without_pp.name : '',
                                contact_ref: (dat.quotation) ? dat.quotation.contact_ref : '',
                                product_boi: (dat.product_without_pp) ? (dat.product_without_pp.boi === true) ? (dat.product_without_pp.material_type === 1) ? 'SNS' : (dat.product_without_pp.material_type === 2) ? 'SNP' : 'BOI' : '-' : '-',
                                customer_required_at: (dat.payment) ? dat.payment.customer_required_at : '',
                                payment_grand_total: (dat.payment && dat.payment.grand_total > 0) ? this.decimalPipe.transform(dat.payment.grand_total, '.2-4') : '',
                                tax_invoice_no: this.getTaxInvoiceNo(dat),
                                delivery_note_no: this.getDeliveryNoteNo(dat),
                                invoice_no: this.getInvoiceNo(dat),
                                receipt_no: this.getReceiptNo(dat),
                                // payment_vat: this.getPaymentVAT(dat),
                                unit: (dat.product_without_pp) ? dat.product_without_pp.unit : '',
                                sum_gap_time_minutes: (cal_sum_gap_time_minutes) ? this.decimalPipe.transform(Math.floor(cal_sum_gap_time_minutes * 100) / 100, '.2-4') : '',
                                sum_minutes: (cal_sum_minutes) ? this.decimalPipe.transform(Math.floor(cal_sum_minutes * 100) / 100, '.2-4') : '',
                                sum_total_minutes: (cal_sum_gap_time_minutes || cal_sum_minutes) ? this.decimalPipe.transform(Math.floor((cal_sum_gap_time_minutes + cal_sum_minutes) * 100) / 100, '.2-4') : '',
                                shipping_date_calculate: (dat.shipping_date) ? +moment(dat.shipping_date).format('YYYYMMDD') : null,
                                required_at_calculate: (dat.required_at) ? +moment(dat.required_at).format('YYYYMMDD') : null,
                                delivery_date_calculate: (dat.delivery_date) ? +moment(dat.delivery_date).format('YYYYMMDD') : null,
                                finished_goods_at_calculate: (dat.finished_goods_at) ? +moment(dat.finished_goods_at).format('YYYYMMDD') : null,
                            }, dat);
                            this.rowData1.push(_dat);
                            n++;
                        }
                    }
                    this.ready = true;
                    resolve();
                }, error => {
                    //
                });
        });
        return promise;
    }

    public onCellClicked(e?: any): void {
        const field = (e && e.colDef) ? e.colDef.field : '';
        if (field) {
            if (field === 'purchase_order_no') {
                this.viewer.payment(e.value);
            } else if (field === 'job_no') {
                this.viewer.manufacture(e.value, true, true);
            } else if (field === 'quotation_no') {
                this.viewer.quotation(e.value);
            } else if (field === 'contact_ref') {
                this.viewer.contactLog(e.value);
            } else if (field === 'product_no') {
                this.viewer.product(e.value);
            } else if (field === 'drawing_no') {
                this.viewer.drawing(e.value);
            }
        }
    }

    private getTaxInvoiceNo(dat) {
        const data = [];
        if (dat && dat.tax_invoices && dat.tax_invoices.length) {
            for (const tax_invoice of dat.tax_invoices) {
                if (tax_invoice && tax_invoice.tax_invoice_no) {
                    data.push(tax_invoice.tax_invoice_no);
                    break;
                }
            }
        }
        return data.join(', ');
    }

    private getDeliveryNoteNo(dat) {
        const data = [];
        if (dat && dat.delivery_notes && dat.delivery_notes.length) {
            for (const delivery_note of dat.delivery_notes) {
                if (delivery_note && delivery_note.delivery_note_no) {
                    data.push(delivery_note.delivery_note_no);
                    break;
                }
            }
        }
        return data.join(', ');
    }

    private getInvoiceNo(dat) {
        const data = [];
        if (dat && dat.invoices && dat.invoices.length) {
            for (const invoice of dat.invoices) {
                if (invoice && invoice.invoice_no) {
                    data.push(invoice.invoice_no);
                    break;
                }
            }
        }
        return data.join(', ');
    }

    private getReceiptNo(dat) {
        const data = [];
        if (dat && dat.receipts && dat.receipts.length) {
            for (const receipt of dat.receipts) {
                if (receipt && receipt.receipt_no) {
                    data.push(receipt.receipt_no);
                    break;
                }
            }
        }
        return data.join(', ');
    }

    public onSuccess(e: any): void {
        this.ready = true;
    }

    public onError(e: any): void {
        //
    }

    public onViewChange(e: any): void {
        if (e === 'DAILY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'day');
        } else if (e === 'MONTHLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'month');
        } else if (e === 'QUARTERLY') {
            // this.date1 = moment(new Date());
            // this.date2 = moment(new Date()).add(-1, 'year');
        } else if (e === 'YEARLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'year');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public onDateChange(e: any, date: any): void {
        if (this.current_view === 'DAILY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        } else if (this.current_view === 'MONTHLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
        } else if (this.current_view === 'YEARLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh(): void {
        if (this.getting) {
            //
        } else {
            this.loader.show();
            this.getting = true;
            this.refreshColumn();
            this.getData()
                .then(() => {
                    this.loader.hide();
                    this.getting = false;
                });
        }
    }

    public onCustomerChange(e: any): void {
        this.customer_id = (e && e.id) ? e.id : '';
        this.refresh();
    }

    public get contentHeight(): string {
        const window_height = window.innerHeight - 80;
        const _height = '' + ((window_height * (100 / this.current_zoom)) * .75) + 'px';
        return _height;
    }

}
