import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TaskModel} from '../../../models/task.model';
import {Api} from '../../../now/api/api';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../components/loader/loader.service';
import {ViewTask} from '../view/viewTask';
import {TaskService} from '../../../services/task.service';
import {ModalService} from '../../../services/modal.service';
import {Viewer} from '../../../services/viewer';
import {Location} from '@angular/common';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../now/user/auth.service';
import {SwalService} from '../../../services/swal.service';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {UserModel} from '../../../now/user/user.model';
import {ApprovedListComponent} from '../../../modals/approvedList/approvedList.component';
import * as moment from 'moment';

@Component({
    selector: 'hr-task-component',
    templateUrl: 'HRTask.component.html',
    styleUrls: ['HRTask.component.scss']
})
export class HRTaskComponent extends ViewTask implements AfterViewInit {

    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;
    @ViewChild('remark2Modal', { static: true }) remark2Modal: RemarkModal;

    public config;
    public leaveHistories = [];
    public leaveId;
    public leave;
    public remarks;
    public currentStep;
    public currentYear;
    public currentDate;
    public activities;
    public sum_vacation = 0;
    public sum_personal = 0;
    public sum_sick = 0;
    public sum_maternity = 0;
    public sum_force = 0;
    public sum_other = 0;

    constructor(
        public userService: UserService,
        public taskService: TaskService,
        public modal: ModalService,
        public location: Location,
        public viewer: Viewer,
        public api: Api,
        public swal: SwalService,
        public auth: AuthService,
        public route: ActivatedRoute,
        public loader: LoaderService,
    ) {
        //
        super({ taskService, api, modal, location, viewer });
        this.currentStep = 1;
        this.activities = [];
        this.remarks = [];
        this.task = new TaskModel();
        this.ready = false;
        this.currentDate = moment(new Date());
        this.currentYear = this.currentDate.format('YYYY');
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.loader.show();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.leaveId = this.task.modelable_id;
                            this.getLeave()
                                .then(() => {
                                    this.getLeaveHistories()
                                        .then(() => {
                                            //
                                        });
                                    this.ready = true;
                                    this.loader.hide();
                                }, error => {
                                    console.error(error);
                                    this.loader.hide();
                                });
                        }, error => {
                            console.error(error);
                            this.loader.hide();
                        });
                } else {
                    this.ready = true;
                    this.loader.hide();
                }
            });
    }

    public reject2(): void {
        // this.swal.confirm('ยืนยันปฏิเสธการร้องขอแจ้งซ่อม/สร้างเครื่องจักรและอุปกรณ์เลขที่ "' + this.leave.document_no + '" ใช่หรือไม่?')
        this.currentStep = 2;
        this.swal.confirm('ยืนยันปฏิเสธการร้องขอการลาใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.remarkModal.open();
                }
            });
    }

    public reject(): void {
        // this.swal.confirm('ยืนยันปฏิเสธการร้องขอแจ้งซ่อม/สร้างเครื่องจักรและอุปกรณ์เลขที่ "' + this.leave.document_no + '" ใช่หรือไม่?')
        this.swal.confirm('ยืนยันปฏิเสธการร้องขอการลาใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.remarkModal.open();
                }
            });
    }

    public sendRequest() {
        this.remarkModal.open();
    }

    public archiveTask() {
        if (this.leave && this.leave.document_no) {
            this.swal.confirm('ยืนยันการจัดเก็บเอกสาร "' + this.leave.document_no + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('hr/archive', 'POST', {}, {
                            id: this.leave.id,
                            task_id: this.task_id
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.taskService.complete(this.task)
                                    .then(() => {
                                        this.swal.success('จัดเก็บเอกสารสำเร็จ');
                                        super.leave(true);
                                    });
                            }
                        });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public approveHRTask2() {
        this.currentStep = 2;
        this.remark2Modal.open();
    }

    public approveHRTask3() {
        this.currentStep = 3;
        this.remark2Modal.open();
    }

    public reject3() {
        this.currentStep = 3;
        this.swal.confirm('ยืนยันปฏิเสธการร้องขอการลาใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.remarkModal.open();
                }
            });
    }

    public approveHRTask() {
        this.remark2Modal.open();
    }

    public onRemark2ModalSubmit(event) {
        const remark = event.remark_message;
        if (this.currentStep === 2) {
            this.loader.show();
            this.api.request('hr/approve', 'POST', {}, {
                id: this.leaveId,
                task_id: this.task_id,
                remark,
                step: this.currentStep,
            }).subscribe(() => {
                this.taskService.setStatus(this.task, this.task.status, 'hr', 'hr', 'request_hr', false, false)
                    .then(() => {
                        this.loader.hide();
                        this.swal.success('ส่งคำร้องขอเพื่ออนุมัติสำเร็จ');
                        super.leave(true);
                    });
            });
        } else if (this.currentStep === 3) {
            this.api.request('hr/approve', 'POST', {}, {
                id: this.leaveId,
                task_id: this.task_id,
                remark,
                step: this.currentStep,
            }).subscribe(() => {
                this.taskService.setStatus(this.task, this.task.status, 'approved_hr', 'approved_hr', 'approved_hr', false, this.leave.user_id)
                    .then(() => {
                        this.loader.hide();
                        this.swal.success('อนุมัติสำเร็จ');
                        super.leave(true);
                    });
            });
        } else {
            this.modal.show(ApprovedListComponent, {
                skipMessage: true
                // task: this.task
            }, {
                class: 'modal-lg'
            }).then((content: any): void => {
                if (content && content.submit === true) {
                    this.loader.show();
                    this.api.request('hr/approve', 'POST', {}, {
                        id: this.leaveId,
                        task_id: this.task_id,
                        remark,
                        step: this.currentStep,
                    }).subscribe(() => {
                        this.taskService.setStatus(this.task, this.task.status, 'approve_hr', 'approve_hr', 'approve_hr', false, content.man.id)
                            .then(() => {
                                this.loader.hide();
                                this.swal.success('ส่งคำร้องขอเพื่ออนุมัติสำเร็จ');
                                super.leave(true);
                            });
                    }, error => {
                        console.log(error);
                        this.swal.danger('เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง');
                        this.loader.hide();
                    });
                }
            });
        }
    }

    public onRemarkModalSubmit($event) {
        if (this.task.current_role === 'reject_hr') {
            this.swal.confirm('ยืนยันการส่งคำร้องขอีกครั้งใช่หรือไม่?')
                .then((result: boolean): void => {
                    // this.loader.show();
                    if (result === true) {
                        if (this.currentStep === 3) {
                            this.loader.show();
                            this.taskService.setStatus(this.task, this.task.status, 'hr', 'hr', 'hr', false, false, $event.remark_message)
                                .then(() => {
                                    this.loader.hide();
                                    this.swal.success('ส่งคำร้องขออีกครั้งสำเร็จ');
                                    super.leave(true);
                                });
                        } else {
                            this.modal.show(ApprovedListComponent, {
                                // task: this.task
                            }, {
                                class: 'modal-lg'
                            }).then((content: any): void => {
                                if (content && content.submit === true) {
                                    this.loader.show();
                                    this.taskService.setStatus(this.task, this.task.status, 'hr', 'hr', 'hr', false, content.man.id, $event.remark_message)
                                        .then(() => {
                                            this.loader.hide();
                                            this.swal.success('ส่งคำร้องขออีกครั้งสำเร็จ');
                                            super.leave(true);
                                        });
                                }
                            });
                        }
                    } else {
                        // this.loader.hide();
                    }
                });
        } else {
            this.api.request('hr/reject', 'POST', {}, {
                task_id: this.task_id,
                id: this.leaveId,
                remark: $event.remark_message,
                step: this.currentStep,
            }).subscribe((res: any): void => {
                if (res && res.success === true) {
                    this.taskService.setStatus(this.task, this.task.status, 'reject_leave', 'reject_hr', 'reject_hr', false, this.leave.user_id)
                        .then(() => {
                            this.swal.success('ปฏิเสธการร้องขอสำเร็จ');
                            super.leave(true);
                        });
                } else {
                    this.swal.danger('เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง');
                }
            });
        }
    }

    public getUserActivities(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('users/activities')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.activities = response.data;
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getLeaveHistories(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('users/' + this.leave.user_id + '/leaves', 'GET')
                .subscribe((res: any): void => {
                    this.leaveHistories = [];
                    if (res && res.data) {
                        for (const dat of res.data) {
                            if (dat && dat.id === this.leaveId) {
                                //
                            } else {
                                this.leaveHistories.push(dat);
                            }
                        }
                    }
                    this.config = res.config;
                    if (this.leaveHistories && this.leaveHistories.length) {
                        for (const activity of this.leaveHistories) {
                            if (activity && activity.approved_3_at) {
                                const subject = activity.subject;
                                if (subject === 'VACATION') {
                                    this.sum_vacation += (activity.days) ? activity.days : 0;
                                } else if (subject === 'PERSONAL') {
                                    this.sum_personal += (activity.days) ? activity.days : 0;
                                } else if (subject === 'SICK') {
                                    this.sum_sick += (activity.days) ? activity.days : 0;
                                } else if (subject === 'MATERNITY') {
                                    this.sum_maternity += (activity.days) ? activity.days : 0;
                                } else if (subject === 'FORCE') {
                                    this.sum_force += (activity.days) ? activity.days : 0;
                                } else if (subject === 'OTHER') {
                                    this.sum_other += (activity.days) ? activity.days : 0;
                                }
                            }
                        }
                    }
                });
        });
        return promise;
    }

    public getLeave(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            this.api.request('hr/leaves/' + this.leaveId, 'GET')
                .subscribe((res: any): void => {
                    if (res && res.success === true && res.data) {
                        this.remarks = res.data.remarks;

                        const data = res.data;
                        this.leave = Object.assign({}, data);

                        const user = new UserModel();
                        user.clone(data.user);
                        this.leave.user = user;

                        const approved1User = new UserModel();
                        approved1User.clone(data.approved1_user);
                        this.leave.approved1User = approved1User;

                        const approved2User = new UserModel();
                        approved2User.clone(data.approved2_user);
                        this.leave.approved2User = approved2User;

                        const approved3User = new UserModel();
                        approved3User.clone(data.approved3_user);
                        this.leave.approved3User = approved3User;

                        const rejected1User = new UserModel();
                        rejected1User.clone(data.rejected1_user);
                        this.leave.rejected1User = rejected1User;

                        const rejected2User = new UserModel();
                        rejected2User.clone(data.rejected2_user);
                        this.leave.rejected2User = rejected2User;

                        const rejected3User = new UserModel();
                        rejected3User.clone(data.rejected3_user);
                        this.leave.rejected3User = rejected3User;

                        if (!this.leave.approved_1_at) {
                            this.currentStep = 1;
                        } else if (!this.leave.approved_2_at) {
                            this.currentStep = 2;
                        } else if (!this.leave.approved_3_at) {
                            this.currentStep = 3;
                        }
                        console.log('currentStep : ' + this.currentStep);

                        resolve(this.leave);
                    }
                });
        });
        return promise;
    }

    public getTask(): Promise<TaskModel> {
        const promise = new Promise<TaskModel>((resolve, reject) => {
            this.api.request('tasks/' + this.task_id + '/detail', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.task.clone(response.data);
                        resolve(this.task);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

}
