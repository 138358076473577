import {AfterViewInit, Component} from '@angular/core';
import {ViewRequest} from '../view/viewRequest';
import {Location} from '@angular/common';
import {SwalService} from '../../../services/swal.service';
import {ModalService} from '../../../services/modal.service';
import {TaskModel} from '../../../models/task.model';
import {EngModel} from '../../../models/eng.model';
import {RoleModel} from '../../../models/role.model';
import {DocumentModel} from '../../../models/document.model';
import {DocumentDetailComponent} from '../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../environments/environment';
import {Api} from '../../../now/api/api';
import {UserService} from '../../../services/user.service';
import {RoleService} from '../../../services/role.service';
import {AuthService} from '../../../now/user/auth.service';
import {Viewer} from '../../../services/viewer';
import {TaskService} from '../../../services/task.service';

@Component({
    selector: 'request-eng-task-component',
    templateUrl: 'requestEngTask.component.html',
    styleUrls: ['requestEngTask.component.scss']
})
export class RequestEngTaskComponent extends ViewRequest implements AfterViewInit {

    public eng: EngModel;
    public roles: RoleModel[];

    constructor(
        public viewer: Viewer,
        public taskService: TaskService,
        public location: Location,
        private swal: SwalService,
        public modal: ModalService,
        public api: Api,
        private auth: AuthService,
        private roleService: RoleService,
        public userService: UserService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.task = new TaskModel();
        this.eng = new EngModel();
        this.roles = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getRoles()
                .then(() => {
                    //
                });

            if (!this.eng.user_id) {
                this.eng.user = this.auth.user;
                this.eng.user_id = this.eng.user.id;
            }
        }, 0);
    }

    private getRoles(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.roles = [];
            this.roleService.getRoles()
                .then((roles: RoleModel[]): void => {
                    for (let i = 0; i < roles.length; i++) {
                        const role: RoleModel = roles[i];
                        this.roles.push(role);
                        if (role && role.children) {
                            for (let j = 0; j < role.children.length; j++) {
                                const role_child: RoleModel = role.children[j];
                                if (role_child) {
                                    this.roles.push(role_child);
                                }
                            }
                        }
                    }
                    resolve(this.roles);
                });
        });
        return promise;
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('products/document', 'PUT', {}, {
            document: data,
            // id: this.current_product.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    public onStartUploader(data: any): void {
        if (data && data.uploader) {
            data.uploader.cancel();
        }
        this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            task: this.task
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public sendRequest(): void {
        //
    }

    public save(): void {
        //
    }

}
