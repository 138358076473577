import {Component, NgZone, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';

@Component({
    selector: 'app-quotation-selector-component',
    templateUrl: 'quotationSelector.component.html',
    styleUrls: ['quotationSelector.component.scss']
})
export class QuotationSelectorComponent implements OnInit {

    public task: TaskModel;
    public tmp_quotation: any;
    public quotations: QuotationModel[];

    public options: any[];

    constructor(public bsModalRef: BsModalRef, private api: Api, private modelApi: ModelApi, private swal: SwalService, private ngZone: NgZone) {
        this.quotations = [];
        this.options = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.quotations) {
                this.quotations = this.bsModalRef.content.quotations;
                // this.initOptions();
            }
        }, 0);
    }

    private initOptions(): void {
        this.ngZone.run(() => {
            for (let i = 0; i < this.quotations.length; i++) {
                const quotation: QuotationModel = this.quotations[i];
                if (quotation) {
                    this.options.push({
                        display: quotation.quotation_no,
                        line: 'สรุปยอดราคา ' + quotation.total_price + ' บาท',
                        value: quotation.id
                    });
                }
            }
        });
    }

    public check(quotation: QuotationModel): void {
        // quotation.checked = !quotation.checked;
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            const quotation: QuotationModel = this.tmp_quotation;
            if (quotation) {
                this.swal.confirm('คุณเลือกใบเสนอราคา "' + quotation.quotation_no + '" ใช่หรือไม่?')
                    .then(result => {
                        if (result === true) {
                            this.bsModalRef.content.quotation = quotation;
                            this.bsModalRef.hide();
                        } else {
                            //
                        }
                    });
            } else {
                //
            }
        } else {
            //
        }
    }

    private get_checked_quotation(): QuotationModel {
        for (let i = 0; i < this.quotations.length; i++) {
            console.log(this.quotations[i].checked);
            /*if (this.quotations[i].checked === true) {
                return this.quotations[i];
            }*/
        }
        return null;
    }

}
