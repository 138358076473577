import {FormModel} from '../now/formModel';
import {RCriticalPointModel} from './rCriticalPoint.model';

export class CriticalPointModel extends FormModel {

    public name: string;
    public price: number;
    public ranking: number;
    public amount: number;
    public job_id: string;
    public critical_point: any;

    constructor() {
        super('critical/points');
    }

    public get total_price(): number {
        return this.price * this.amount;
    }

}
