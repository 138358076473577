import {FormModel} from '../now/formModel';

export class DrawingModel extends FormModel {

    public drawing_no: string;
    public product_no: string;
    public ranking: number;
    public document: any;
    public stamp_index: number;

    constructor() {
        super('drawings', 'App\\Drawing');
    }

}
