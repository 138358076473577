import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {DrawingModel} from '../../../../models/drawing.model';
import {ProductModel} from '../../../../models/product.model';
import {DocumentModel} from '../../../../models/document.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';

@Component({
    selector: 'app-drawing-detail-component',
    templateUrl: 'drawingDetail.component.html',
    styleUrls: ['drawingDetail.component.scss']
})
export class DrawingDetailComponent implements OnInit {

    public drawing: DrawingModel;
    public tmp_drawing: DrawingModel;
    public product: ProductModel;
    public drawing_index: number;

    constructor(public bsModalRef: BsModalRef, private api: Api, private modelApi: ModelApi, private swal: SwalService) {
        this.tmp_drawing = new DrawingModel();
        this.tmp_drawing.document = new DocumentModel();
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                //
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.drawing_index > -1) {
                this.drawing_index = this.bsModalRef.content.drawing_index;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.drawing) {
                this.tmp_drawing.clone(this.bsModalRef.content.drawing);
                this.drawing = this.bsModalRef.content.drawing;
                if (!this.tmp_drawing.ranking) {
                    this.tmp_drawing.ranking = this.product.ranking;
                }
            }
        }, 0);
    }

    public delete(): void {
        let text: string;
        if (this.tmp_drawing.drawing_no) {
            text = 'คุณต้องการลบเอกสาร "' + this.tmp_drawing.drawing_no + '" ใช่หรือไม่?';
        } else {
            text = 'คุณต้องการลบเอกสารนี้ใช่หรือไม่?';
        }
        this.swal.confirm(text)
            .then(result => {
                if (result === true) {
                    this.api.request('products/drawing/delete', 'POST', {}, {
                        product_id  : this.product.id,
                        id          : this.tmp_drawing.id
                    }).subscribe((response: any): void => {
                        if (this.drawing_index > -1) {
                            this.product.drawings.splice(this.drawing_index, 1);
                        }
                        this.bsModalRef.hide();
                    }, error => {
                        //
                    });
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            this.tmp_drawing.product_no = this.product.product_no;
            this.modelApi.update(this.tmp_drawing, ['drawing_no', 'product_no', 'ranking', 'stamp_index'])
                .subscribe((response: any): void => {
                    if (this.drawing) {
                        this.drawing.clone(this.tmp_drawing);
                    }
                    this.bsModalRef.hide();
                }, error => {
                    //
                });
        } else {
            //
        }
    }

}
