import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {IncotermModel} from '../../../models/incoterm.model';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';

@Component({
    selector: 'incoterm-list-component',
    templateUrl: 'incotermList.component.html',
    styleUrls: ['incotermList.component.scss']
})
export class IncotermListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public timeout: any;
    public incoterms: IncotermModel[];
    public keyword: string;

    constructor(private router: Router) {
        super();
        this.incoterms = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: e
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }, 1000);
    }

    public clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_incoterms();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let incoterm: IncotermModel;
                    incoterm = new IncotermModel();
                    incoterm.clone(dat);
                    this.incoterms.push(incoterm);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoIncotermView(incoterm: IncotermModel): void {
        this.router.navigateByUrl('/database/incoterm/' + incoterm.id + '/detail');
    }

    private clear_incoterms(): void {
        if (this.incoterms) {
            this.incoterms.splice(0, this.incoterms.length);
        } else {
            this.incoterms = [];
        }
    }

    public createIncoterm(): void {
        this.router.navigateByUrl('/database/incoterm/create');
    }

}
