import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {Api} from '../../../now/api/api';
import {ActivatedRoute} from '@angular/router';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {SchedulerModel} from '../../../models/scheduler.model';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {AppService} from '../../../app/app.service';
import * as moment from 'moment';
import {TaskService} from '../../../services/task.service';
import {MachineService} from '../../../services/machine.service';
import {UserService} from '../../../services/user.service';
import {ProcessService} from '../../../services/process.service';
import {UserModel} from '../../../now/user/user.model';
import {PusherService} from '../../../services/pusher.service';
import {AuthService} from '../../../now/user/auth.service';
import {Viewer} from '../../../services/viewer';
import {JobModel} from '../../../models/job.model';
import {SwalService} from '../../../services/swal.service';
import {PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {LoaderService} from '../../../components/loader/loader.service';
import {TypeaheadComponent} from '../../../components/typeahead/typeahead.component';

@Component({
    selector: 'calendar-all-model-view-component',
    templateUrl: 'calendarAllModelView.component.html',
    styleUrls: ['calendarAllModelView.component.scss']
})
export class CalendarAllModelViewComponent implements AfterViewInit, OnDestroy {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) perfectScroll: PerfectScrollbarDirective;
    @ViewChild('searchCalendarJob', { static: false }) searchCalendarJob: TypeaheadComponent;

    public keyword: string;
    public getting: boolean;
    public pdf_viewer_path: string;
    public pdf_viewer_title: string;
    public current_task: any;
    public tmp_job: string;

    public resources: any[];
    public machine_resources: any[];
    public man_resources: any[];
    public process_resources: any[];
    public tmp_model: any;
    public ready: boolean;
    public role: string;
    public model: string;
    public title: string;
    public data: any;
    public job_list: any[];
    public machine_list: any[];
    public man_list: any[];
    public process_list: any[];
    public schedulers: SchedulerModel[];
    public product_processes: ProductProcessModel[];
    public current_date: any;
    private filter: string[];
    public options: any;
    public current_filter: any;
    public events: any[];
    public current_show: string;
    public current_view_name: string;
    public is_shipping: boolean;
    public confirmed_added: boolean;

    constructor(
        private api: Api,
        private route: ActivatedRoute,
        private location: Location,
        private appService: AppService,
        private taskService: TaskService,
        private machineService: MachineService,
        private userService: UserService,
        private processService: ProcessService,
        private pusherService: PusherService,
        private authService: AuthService,
        private swal: SwalService,
        private viewer: Viewer,
        private loader: LoaderService,
    ) {
        //
        this.is_shipping = false;
        this.tmp_job = '';
        this.current_view_name = 'month';
        this.current_show = 'job';
        this.ready = false;
        this.title = 'ตารางงาน';
        this.schedulers = [];
        this.resources = [];
        this.machine_resources = [];
        this.man_resources = [];
        this.process_resources = [];
        this.data = {};
        this.job_list = [];
        this.machine_list = [];
        this.man_list = [];
        this.process_list = [];
        this.tmp_model = {};
        this.product_processes = [];
        this.current_filter = null;
        this.events = [];
        this.options = {
            height: 'parent',
            // contentHeight: 500,
            aspectRatio: 1,
            editable: false,
            resources: [],
            resourceAreaWidth: '12%',
            resourceLabelText: null,
        };
        this.filter = [];
        const moment_now = moment(new Date());
        this.current_date = moment_now.format('YYYY-MM-DD HH:mm:ss');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.appService.fixPage();
            this.appService.full_page_screen = true;
            const _user: UserModel = this.authService.user;
            const shipping_role_index: number = _user.roles.findIndex(i => i === 'product');
            if (shipping_role_index > -1) {
                this.is_shipping = true;
            } else {
                //
            }
            this.ready = true;
        }, 0);
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
        // this.pusherService.unsubscribe('erpst.calendar');
    }

    public refreshEventSources(events: any): void {
        if (this.fullCalendarComponent) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(events);
            this.fullCalendarComponent.rerenderEvents();
        }
        if (events && events[0] && events[0].start) {
            // this.current_date = events[0].start;
        }
    }

    private add_event(scheduler: any, title: string): void {
        if (scheduler && !scheduler.completed_at) {
            const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
            const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
            // const title = process.name;
            const find_job_no = this.find_job_no(scheduler.job_id);
            const find_job_revision = this.find_job_revision(scheduler.job_id);
            const event_scheduler = {
                job_id          : scheduler.job_id,
                job_no          : find_job_no,
                job_revision    : find_job_revision,
                title           : title,
                start           : start,
                end             : end,
                calendar_title  : title,
                timeline_title  : title
            };
            this.events.push(event_scheduler);
        }
    }

    public clear_events(): void {
        this.confirmed_added = false;
        if (this.events) {
            this.events.splice(0, this.events.length);
        } else {
            this.events = [];
        }
    }

    private find_job_no(job_id: string): string {
        if (this.job_list) {
            const find = this.job_list.find(i => i.job_id === job_id);
            if (find && find.job_no) {
                return find.job_no;
            }
        }
        return 'UNKNOW';
    }

    private find_job_revision(job_id: string): any {
        const find = this.job_list.find(i => i.job_id === job_id);
        if (find && find.job_revision) {
            return find.job_revision;
        }
        return 0;
    }

    public addConfirmedDeliveryDate(date: string, job_id?: any, job_revision?: any, job_no?: string, title?: string): any {
        const date_moment_obj: any = moment(date, 'YYYY-MM-DD HH:mm:ss');
        const scheduler_obj = {
            title           : (title) ? title : 'ส่งมอบสินค้า',
            job_id          : job_id,
            job_no          : job_no,
            job_revision    : job_revision,
            start           : date_moment_obj,
            end             : date_moment_obj,
            className       : 'event-solid'
        };
        this.events.push(scheduler_obj);
        this.confirmed_added = true;
        return scheduler_obj;
    }

    private find_process_name(dat: any): string {
        if (dat && dat.name) {
            return dat.name;
        } else if (dat.process_id && this.process_list) {
            const process = this.process_list.find(i => i.process_id === dat.process_id);
            if (process) {
                return process.title;
            }
        }
        if (dat && dat.process_slug) {
            return dat.process_slug.toUpperCase();
        }
        return 'UNKNOW';
    }

    public getAllJobs(): Promise<JobModel[]> {
        const promise = new Promise<JobModel[]>((resolve, reject) => {
            this.loader.show();
            this.clear_events();
            this.api.request('calendar/jobs/all', 'POST', {}, {
                date: this.current_date
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    for (const dat of response.data) {
                        if (dat) {
                            const process_name = this.find_process_name(dat);
                            const fvd = (dat.is_fvd) ? ' (FVD)' : '';
                            this.add_event(dat, dat.job_no + ' ' + ((dat.revision > 0) ? 'R' + dat.revision : '') + '');
                            if (this.is_shipping === true && dat.confirmed_delivery_date && this.confirmed_added === false) {
                                this.addConfirmedDeliveryDate(dat.confirmed_delivery_date, dat.job_id, dat.revision, dat.job_no);
                            }
                        }
                    }
                    this.loader.hide();
                    this.refreshEventSources(this.events);
                    resolve(this.job_list);
                } else {
                    this.loader.hide();
                    reject({});
                }
            }, error => {
                console.log(error);
                this.loader.hide();
                reject(error);
            });
        });
        return promise;
    }

    public onDatepickerChange(e: any): void {
        this.onChangedDate();
    }

    public onChangedDate(date_time?: any): void {
        if (date_time) {
            this.current_date = date_time;
        }
        if (this.getting) {
            return;
        }
        if (this.current_date) {
            this.getting = true;
            this.events = [];
            this.options.resources = [];
            this.getAllJobs()
                .then(() => {
                    this.ready = true;
                    setTimeout(() => {
                        this.getting = false;
                    }, 2000);
                }, error => {
                    this.swal.danger(JSON.stringify(error));
                    this.getting = false;
                });
        }
    }

    public eventClickHandler(e?: any): void {
        const calEvent: any = e.calEvent;
        if (calEvent) {
            const job_id = calEvent.job_id;
            const job_revision = calEvent.job_revision;
            this.viewer.manufacture(job_id, true, job_revision)
                .then(() => {});
        } else {
            //
        }
    }

    public dayClickHandler(e?: any): void {
        //
    }

    public onChangeView(e: any): void {
        this.current_date = e;
    }

    public clear_product_processes(): void {
        if (this.product_processes) {
            this.product_processes.splice(0, this.product_processes.length);
        } else {
            this.product_processes = [];
        }
        if (this.schedulers) {
            this.schedulers.splice(0, this.schedulers.length);
        } else {
            this.schedulers = [];
        }
    }

    public createEventConfirmedDeliveryDate(dat: any, type: string): any {
        const confirmed_delivery_date: string = dat.confirmed_delivery_date;
        const event_scheduler: any = {
            title: dat.job_no,
            start: confirmed_delivery_date,
            end: confirmed_delivery_date,
            filter_value: [dat.job_no, 'process_shipping'],
            type: type,
            filter_title: 'ส่งมอบ ' + dat.job_no,
            calendar_title: 'ส่งมอบ ' + dat.job_no,
            timeline_title: 'ส่งมอบ ' + dat.job_no,
            job_id: dat.id,
            job_no: 'ส่งมอบ ' + dat.job_no,
            job_revision: dat.revision,
            className: 'event-solid'
        };
        return event_scheduler;
    }

    public onChange(dat: any, e: any): void {
        if (dat) {
            const index: number = this.filter.indexOf(dat.id);
            if (dat.checked) {
                if (index === -1) {
                    this.filter.push(dat.id);
                } else {
                    //
                }
            } else if (index > -1) {
                this.filter.splice(index, 1);
            }
            this.fullCalendarComponent.rerenderEvents();
        }
    }

    public printCalendar(): void {
        window.print();
    }

    public onKeywordInput(e: any): void {
        //
    }

}
