import {Injectable} from '@angular/core';
import {UserModel} from '../now/user/user.model';
import {Api} from '../now/api/api';
import {SchedulerModel} from '../models/scheduler.model';

@Injectable()
export class UserService {

    private users_by_ranking_slug: any;
    private users_by_slug: any;

    private user: UserModel;
    public roles: string[];

    private users: UserModel[];
    public current_role: string;

    public num_request_notifications: number;
    public num_notifications: number;

    constructor(private api: Api) {
        this.users = [];
        this.reset_users_by_ranking_slug_list();
        this.reset_users_by_slug_list();
        this.num_request_notifications = 0;
        this.num_notifications = 0;
    }

    public userSchedulers(user: UserModel, job_id?: string): Promise<SchedulerModel[]> {
        let promise: Promise<SchedulerModel[]>;
        promise = new Promise<SchedulerModel[]>((resolve, reject) => {
            let schedulers: SchedulerModel[];
            schedulers = [];
            this.api.request('users/schedulers', 'POST', {
                job_id: job_id
            }, {
                id: user.id
            }, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat) {
                                if (!job_id || dat.job_id !== job_id) {
                                    let scheduler: SchedulerModel;
                                    scheduler = new SchedulerModel();
                                    scheduler.clone(dat);
                                    schedulers.push(scheduler);
                                } else {
                                    //
                                }
                            }
                        }
                        resolve(schedulers);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getNotifications(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/notifications')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.num_notifications = (response.data.num_notifications > 0) ? response.data.num_notifications : 0;
                        this.num_request_notifications = (response.data.num_request_notifications > 0) ? response.data.num_request_notifications : 0;
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getRoles(): string[] {
        return this.roles;
    }

    public getID(): string {
        if (this.user && this.user.id) {
            return this.user.id;
        }
        return '';
    }

    public setUser(user: UserModel): void {
        this.user = user;
        this.roles = this.user.roles;
        this.current_role = this.user.current_role;
    }

    public getLowUsers(): Promise<UserModel[]> {
        let promise: Promise<UserModel[]>;
        promise = new Promise<UserModel[]>((resolve, reject) => {
            this.api.request('users', 'GET', {
                level: 4
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat: any = response.data[i];
                        if (dat) {
                            let user: UserModel;
                            user = new UserModel();
                            user.clone(dat);
                            this.users.push(user);
                        }
                    }
                }
                resolve(this.users);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public getUsers(level?: number): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.users && this.users.length > 0) {
                resolve(this.users);
            } else {
                this.api.request('users', 'GET', {
                    level: (level) ? level : 5
                }).subscribe((response: any): void => {
                    if (response && response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat) {
                                let user: UserModel;
                                user = new UserModel();
                                user.clone(dat);
                                this.users.push(user);
                            }
                        }
                    }
                    resolve(this.users);
                }, error => {
                    reject(error);
                });
            }
        });
        return promise;
    }

    public getWorkers(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('users/workers')
                .subscribe((response: any): void => {
                    this.reset_man_list();
                    if (response && response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat) {
                                let user: UserModel;
                                user = new UserModel();
                                user.clone(dat);
                                this.users.push(user);
                            }
                        }
                    }
                    resolve(this.users);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getUsersBySlug(slug: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (slug) {
                let users_by_slug: UserModel[];
                users_by_slug = this.users_by_slug[slug];
                if (users_by_slug && Array.isArray(users_by_slug) && users_by_slug.length > 0) {
                    resolve(users_by_slug);
                } else {
                    this.users_by_slug[slug] = [];
                    users_by_slug = this.users_by_slug[slug];
                    this.api.request('users/' + slug + '/process')
                        .subscribe((response: any): void => {
                            if (response && response.data) {
                                for (let i = 0; i < response.data.length; i++) {
                                    let dat: any;
                                    dat = response.data[i];
                                    if (dat) {
                                        let user: UserModel;
                                        user = new UserModel();
                                        user.clone(dat);
                                        users_by_slug.push(user);
                                    }
                                }
                            }
                            resolve(users_by_slug);
                        }, (error: any): void => {
                            reject(error);
                        });
                }
            }
        });
        return promise;
    }

    public getCurrentTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('users/task')
                .subscribe((response: any): void => {
                    if (response && response.data && response.data.id) {
                        resolve(response.data);
                    } else {
                        resolve();
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getUsersByRankingAndSlug(ranking: string, slug: string, custom_key?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (ranking) {
                if (custom_key) {
                    custom_key = custom_key + '-';
                }
                let key: string;
                key = custom_key + ranking + '-' + slug;
                let users_by_ranking_slug: UserModel[];
                users_by_ranking_slug = this.users_by_ranking_slug[key];
                if (users_by_ranking_slug && Array.isArray(users_by_ranking_slug) && users_by_ranking_slug.length > 0) {
                    resolve(users_by_ranking_slug);
                } else {
                    this.users_by_ranking_slug[key] = [];
                    users_by_ranking_slug = this.users_by_ranking_slug[key];
                    this.api.request('users/' + ranking + '/ranking/' + slug + '/process', 'GET', {
                        n: 1
                    }).subscribe((response: any): void => {
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    let user: UserModel;
                                    user = new UserModel();
                                    user.clone(dat);
                                    users_by_ranking_slug.push(user);
                                }
                            }
                        }
                        resolve(users_by_ranking_slug);
                    }, (error: any): void => {
                        //
                    });
                }
            } else {
                reject();
            }
        });
        return promise;
    }

    private reset_man_list(): void {
        if (this.users) {
            this.users.splice(0, this.users.length);
        } else {
            this.users = [];
        }
    }

    private reset_users_by_slug_list(): void {
        this.users_by_slug = {};
    }

    private reset_users_by_ranking_slug_list(): void {
        this.users_by_ranking_slug = {};
    }

}
