import {Model} from '../now/model';
import {DocumentModel} from './document.model';

export class DeliveryOrderModel extends Model {

    public job_id: string;
    public document: DocumentModel;
    public verified: boolean;
    public role: string;

    constructor() {
        super('delivery/orders', 'App\\DeliveryOrder');
        this.document = new DocumentModel();
    }

}
