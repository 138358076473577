import {AfterViewInit, Component} from '@angular/core';
import {AppService} from '../../../app/app.service';
import {Api} from '../../../now/api/api';
import {AuthService} from '../../../now/user/auth.service';

@Component({
    selector: 'app-database-nav-component',
    templateUrl: 'databaseNav.component.html',
    styleUrls: ['./databaseNav.component.scss']
})
export class DatabaseNavComponent implements AfterViewInit {

    public pages: any[];
    public database: any;
    public user_level: number;
    public databases: string[];
    public is_error: boolean;
    public is_ready: boolean;

    constructor(
        private appService: AppService,
        private api: Api,
        private authService: AuthService
    ) {
        //
        this.pages = [];
        this.database = [];
        this.user_level = 3;
        this.is_error = true;
        this.is_ready = false;
    }

    ngAfterViewInit(): void {
        this.is_ready = false;
        setTimeout(() => {
            this.databases = [];
            this.getUserDatabases()
                .then(databases => {
                    if (databases) {
                        this.databases = databases;
                        this.getDatabase();
                        this.user_level = this.authService.user.level;
                        for (let i = 0; i < this.appService.pages.length; i++) {
                            const p = this.appService.pages[i];
                            this.pages.push({
                                display: p.display,
                                children: []
                            });
                            if (p && p.children) {
                                for (let j = 0; j < p.children.length; j++) {
                                    const index_exists: number = this.databases.indexOf(p.children[j].key);
                                    if (index_exists > -1) {
                                        this.pages[i].children.push(p.children[j]);
                                    } else {
                                        //
                                    }
                                }
                            }
                        }
                        this.is_error = false;
                        this.is_ready = true;
                    } else {
                        this.is_error = true;
                        this.is_ready = true;
                    }
                });
        }, 0);
    }

    public getUserDatabases(): Promise<string[]> {
        let promise: Promise<string[]>;
        promise = new Promise<string[]>((resolve, reject) => {
            this.api.request('users/databases', 'GET', {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    resolve([]);
                }
            }, error => {
                resolve([]);
            });
        });
        return promise;
    }

    private getDatabase(): void {
        this.api.request('database', 'GET')
            .subscribe((response: any): void => {
                if (response && response.data) {
                    this.database = response.data;
                }
            }, error => {
                //
            });
    }

}
