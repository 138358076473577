import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {SpecialToolingModel} from '../models/specialTooling.model';

@Injectable()
export class SpecialToolingService {

    private special_toolings: SpecialToolingModel[];

    constructor(private api: Api) {
        this.reset_special_tooling_list();
    }

    public getSpecialToolings(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.special_toolings && this.special_toolings.length > 0) {
                for (let i = 0; i < this.special_toolings.length; i++) {
                    this.special_toolings[i].checked = false;
                }
                resolve(this.special_toolings);
            } else {
                this.api.request('specialtoolings', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_special_tooling_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.special_toolings.push(dat);
                                }
                            }
                            resolve(this.special_toolings);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_special_tooling_list(): void {
        if (this.special_toolings) {
            this.special_toolings.splice(0, this.special_toolings.length);
        } else {
            this.special_toolings = [];
        }
    }

}
