import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CustomerModel} from '../../../models/customer.model';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CreateCustomerComponent} from './create/createCustomer.component';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import {ModalService} from '../../../services/modal.service';

@Component({
    selector: 'customer-list-component',
    templateUrl: 'customerList.component.html',
    styleUrls: ['customerList.component.scss']
})
export class CustomerListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public customers: CustomerModel[];
    public keyword: string;
    public timeout: any;

    constructor(private router: Router, private modalService: BsModalService, private modal: ModalService) {
        super();
        this.customers = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: e
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }, 1000);
    }

    private clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_customers();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let customer: CustomerModel;
                    customer = new CustomerModel();
                    customer.clone(dat);
                    this.customers.push(customer);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoCustomerView(customer: CustomerModel): void {
        this.router.navigateByUrl('/database/customer/' + customer.id + '/detail');
    }

    public createCustomer(): void {
        let new_customer: CustomerModel;
        new_customer = new CustomerModel();
        this.modal.show(CreateCustomerComponent, {
            customer: new_customer
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((content: any): void => {
                if (content && content.customer && content.customer.id) {
                    this.gotoCustomerView(content.customer);
                } else {
                    //
                }
            });
    }

    private clear_customers(): void {
        if (this.customers) {
            this.customers.splice(0, this.customers.length);
        } else {
            this.customers = [];
        }
    }

}
