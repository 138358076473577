import {Directive, ElementRef, OnInit, OnDestroy} from '@angular/core';

@Directive({
    selector: '[nowDivApiError]',
})
export class DivApiErrorDirective implements OnInit, OnDestroy {

    constructor(public el: ElementRef) {
        //
    }

    ngOnInit(): void {
        if (this.el.nativeElement) {
            this.el.nativeElement.remove();
        }
    }

    ngOnDestroy(): void {
        //
    }

}
