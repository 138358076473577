import {Model} from '../now/model';

export class CADModel extends Model {

    public drawing_no: string;
    public document: any;

    constructor() {
        super('cads', 'App\\Cad');
    }

}
