import {AfterViewInit, Component, OnDestroy, ViewChild, ViewChildren} from '@angular/core';
import * as moment from 'moment';
import {DivApiDirective} from '../../../now/divApi';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {map} from 'rxjs/operators';
import {AppService} from '../../../app/app.service';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);
import HC_solidgauge from 'highcharts/modules/solid-gauge';
import {DecimalPipe} from '@angular/common';
import {CustomerModel} from '../../../models/customer.model';
HC_solidgauge(Highcharts);

@Component({
    selector: 'planning-report-page',
    templateUrl: 'planningReport.page.html',
    styleUrls: ['planningReport.page.scss'],
    providers: [DecimalPipe]
})
export class PlanningReportPage implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;
    public divApi: DivApiDirective;

    private gridApi: any;
    public date1: any;
    public date2: any;
    public customer_id: any;
    public getting: boolean;

    public rowData1: any;
    public rowData2: any;
    public rowData3: any;
    public rowData4: any;
    public sum_data1: number;
    public sum_data2: number;
    public sum_data3: number;
    public sum_data4: number;
    public sum_data5: number;
    public sum_data6: number;
    public chartData2: any[];
    public tmp_customer: CustomerModel;

    public keyword: string;
    public date: any;
    public current_view: string;
    public ready: boolean;
    public current_zoom: number;
    public columnDefs: any;
    public jobs: any[];
    public cellStyle_keep_promise: any;
    public cellStyle_keep_request: any;
    public cellStyle_within_3days: any;
    public cellStyle_diff_estimated_price: any;
    public num_jobs: number;
    public num_keep_request: number;
    public num_keep_promise: number;
    public num_within_3_days: number;
    public num_jobs_within_3_days: number;
    public num_jobs_diff_price: number;
    public sum_diff_price: number;
    public num_expected: number;
    public num_expected2: number;
    public num_unexpected: number;
    public num_unexpected2: number;
    public unexpected: number;
    public current_date: any;
    public chartOptions: any;
    public chartOptions2: any;
    public chartOptions3: any;
    public chartOptions4: any;
    public item_data: any[];
    public Highcharts: typeof Highcharts = Highcharts;

    constructor(
        private loader: LoaderService,
        private api: Api,
        private appService: AppService,
        private decimalPipe: DecimalPipe
    ) {
        //
        this.current_view = 'MONTHLY';
        this.date1 = moment(new Date());
        this.date2 = moment(new Date()).add(-1, 'year');

        this.current_zoom = 100;
        this.rowData1 = [];
        this.rowData2 = [];
        this.rowData3 = [];
        this.rowData4 = [];
        this.columnDefs = [];
        this.jobs = [];

        this.tmp_customer = new CustomerModel();

        this.cellStyle_keep_promise = (params) => {
            if (params && params.data && params.data.keep_promise === false) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else {
                return {
                    'background-color': '#ffffff'
                };
            }
        };
        this.cellStyle_keep_request = (params) => {
            if (params && params.data && params.data.keep_request === false) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else {
                return {
                    'background-color': '#ffffff'
                };
            }
        };
        this.cellStyle_within_3days = (params) => {
            if (params && params.data && params.data.within_3_days > 3) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else {
                return {
                    'background-color': '#ffffff'
                };
            }
        };
        this.cellStyle_diff_estimated_price = (params) => {
            if (params && params.data && params.data.diff_estimated_price_check) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff',
                    'justify-content': 'flex-end'
                };
            } else {
                return {
                    'background-color': '#ffffff',
                    'justify-content': 'flex-end'
                };
            }
        };

        this.item_data = [];
        this.columnDefs = [
            {
                editable: false,
                headerName: '#',
                field: 'index',
                width: 40
            },
            {
                editable: false,
                headerName: 'JOB NO',
                field: 'job_no',
                width: 120,
                /*cellRenderer: (value) => {
                    const job_no = value.data.job_no;
                    // return '<a href="">' + job_no + '</a>';
                    return job_no;
                }*/
            },
            {
                editable: false,
                headerName: 'Customer',
                field: 'customer_name',
                minWidth: 240,
                suppressSizeToFit: true,
                cellStyle: {
                    // 'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Loaded Date',
                field: 'created_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'Planned Date',
                field: 'planning_started_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'Within 3Days',
                field: 'within_3_days',
                width: 75,
                cellStyle: this.cellStyle_within_3days
            },
            {
                editable: false,
                headerName: 'วันที่ลูกค้าร้องขอ',
                field: 'required_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่วางแผนส่ง',
                field: 'delivery_date',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่เข้า FG',
                field: 'finished_goods_at',
                width: 120,
                cellStyle: (params) => {
                    if (params && params.data) {
                        const delivery_date_calculate = params.data.delivery_date_calculate;
                        const finished_goods_at_calculate = params.data.finished_goods_at_calculate;
                        if (delivery_date_calculate && finished_goods_at_calculate && finished_goods_at_calculate <=  delivery_date_calculate) {
                            return {
                                'background-color': '#4caf50',
                                'color': '#ffffff'
                            };
                        } else if (delivery_date_calculate && finished_goods_at_calculate && finished_goods_at_calculate >  delivery_date_calculate) {
                            return {
                                'background-color': '#ff0000',
                                'color': '#ffffff'
                            };
                        }
                    }
                    return {
                        'background-color': '#ffffff'
                    };
                }
            },
            {
                editable: false,
                headerName: 'Keep Request',
                field: 'keep_request_text',
                width: 75,
                cellStyle: this.cellStyle_keep_request
            },
            {
                editable: false,
                headerName: 'Keep Promise',
                field: 'keep_promise_text',
                width: 75,
                cellStyle: this.cellStyle_keep_promise
            },
            {
                editable: false,
                headerName: 'Sale Estimated Price',
                field: 'sale_estimated_price',
                width: 120,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
                // cellStyle: this.cellStyle_keep_promise
            },
            {
                editable: false,
                headerName: 'Planning Estimated Price',
                field: 'planning_estimated_price',
                width: 120,
                cellStyle: {
                    'justify-content': 'flex-end'
                }
                // cellStyle: this.cellStyle_keep_promise
            },
            {
                editable: false,
                headerName: 'Diff Price',
                field: 'diff_estimated_price',
                width: 80,
                cellStyle: this.cellStyle_diff_estimated_price
            },
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
            // this.refresh();
        });
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public onGridReady(e: any): void {
        this.gridApi = e.api;
    }

    public getData(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.rowData1 = [];
            this.rowData2 = [];
            this.rowData3 = [];
            this.rowData4 = [];
            this.jobs = [];
            this.ready = false;
            this.num_jobs = this.num_expected = this.num_unexpected = this.num_unexpected2 = this.num_expected2 = this.num_keep_request = this.num_keep_promise = 0;
            this.sum_data1 = this.sum_data2 = this.sum_data3 = this.sum_data4 = this.sum_data5 = this.sum_data6 = this.num_within_3_days = this.num_jobs_within_3_days = this.sum_diff_price = this.num_jobs_diff_price = 0;
            this.api.request('report/planning', 'POST', {}, {
                date1       : (moment.isMoment(this.date1)) ? this.date1.format('YYYY-MM-DD') : this.date1,
                date2       : (moment.isMoment(this.date2)) ? this.date2.format('YYYY-MM-DD') : this.date2,
                view        : this.current_view,
                customer_id : (this.customer_id) ? this.customer_id : '',
            })
                .pipe(
                    map((response: any): void => {
                        if (response && response.success === true) {
                            return response.data;
                        }
                        return null;
                    })
                )
                .toPromise()
                .then((data: any): void => {
                    if (data) {
                        if (data && data[0]) {
                            let n: number;
                            n = 1;
                            for (const dat of data[0]) { // kpi1
                                const delivery_date_moment = moment(dat.delivery_date, 'YYYY-MM-DD HH:mm:ss');
                                const required_at_moment = moment(dat.required_at, 'YYYY-MM-DD HH:mm:ss');
                                const shipping_date_moment = moment(dat.shipping_date, 'YYYY-MM-DD HH:mm:ss');
                                const created_at_moment = moment(dat.created_at, 'YYYY-MM-DD HH:mm:ss');
                                const planning_started_at_moment = moment(dat.planning_started_at, 'YYYY-MM-DD HH:mm:ss');
                                const finished_goods_at_moment = moment(dat.finished_goods_at, 'YYYY-MM-DD HH:mm:ss');

                                const created_at = (dat.created_at) ? dat.created_at.split(' ')[0] : '';
                                const required_at = (dat.required_at) ? dat.required_at.split(' ')[0] : '';
                                const delivery_date = (dat.delivery_date) ? dat.delivery_date.split(' ')[0] : '';
                                const shipping_date = (dat.shipping_date) ? dat.shipping_date.split(' ')[0] : '';
                                const planning_started_at = (dat.planning_started_at) ? dat.planning_started_at.split(' ')[0] : '';
                                const finished_goods_at = (dat.finished_goods_at) ? dat.finished_goods_at.split(' ')[0] : '';

                                const keep_promise = (dat.delivery_date && delivery_date && dat.finished_goods_at && finished_goods_at) ? (+delivery_date_moment.format('YYYYMMDD') - +finished_goods_at_moment.format('YYYYMMDD') >= 0) ? true : false : null;
                                const keep_request = (dat.delivery_date && delivery_date && dat.required_at && required_at) ? (+required_at_moment.format('YYYYMMDD') - +delivery_date_moment.format('YYYYMMDD') >= 0) ? true : false : null;
                                const within_3_days = Math.floor(moment.duration(planning_started_at_moment.diff(created_at_moment)).asDays() * 100) / 100;
                                if (dat.planning_started_at && dat.created_at) {
                                    this.num_jobs_within_3_days++;
                                    if (within_3_days < 3) {
                                        this.num_within_3_days++;
                                    }
                                }

                                const diff_estimated_price = dat.planning_estimated_price - dat.sale_estimated_price;
                                this.rowData1.push({
                                    index           : n,
                                    job_no          : dat.job_no,
                                    revision        : dat.revision,
                                    created_at      : created_at,
                                    required_at     : required_at,
                                    delivery_date   : delivery_date,
                                    // shipping_date   : shipping_date,
                                    finished_goods_at   : finished_goods_at,
                                    planning_started_at : planning_started_at,
                                    customer_name       : (dat && dat.customer) ? dat.customer.name : 'UNKNOW',
                                    keep_request_text   : (keep_request === true && keep_promise === true) ? 'PASS' : (keep_request !== null && keep_promise !== null) ? 'FAIL' : '-',
                                    keep_promise_text   : (keep_promise === true) ? 'PASS' : (keep_promise === false) ? 'FAIL' : '-',
                                    keep_request        : (keep_request === true && keep_promise === true) ? true : (keep_request !== null && keep_promise !== null) ? false : null,
                                    keep_promise        : (keep_promise === true) ? true : (keep_promise === false) ? false : null,
                                    within_3_days       : (within_3_days || within_3_days === 0) ? within_3_days : '-',
                                    sale_estimated_price        : this.decimalPipe.transform(dat.sale_estimated_price, '.2-4'),
                                    planning_estimated_price    : this.decimalPipe.transform(dat.planning_estimated_price, '.2-4'),
                                    diff_estimated_price        : this.decimalPipe.transform(Math.abs(diff_estimated_price), '.2-4'),
                                    diff_estimated_price_check  : (diff_estimated_price > 0 && dat.sale_estimated_price > 0),
                                    delivery_date_calculate     : (dat.delivery_date) ? +moment(dat.delivery_date).format('YYYYMMDD') : null,
                                    finished_goods_at_calculate : (dat.finished_goods_at) ? +moment(dat.finished_goods_at).format('YYYYMMDD') : null,
                                });
                                if (dat.delivery_date && dat.required_at && dat.finished_goods_at) {
                                    this.num_jobs++;
                                    if (keep_request === true && keep_promise === true) {
                                        this.num_keep_request++;
                                    }
                                    if (keep_promise === true) {
                                        this.num_keep_promise++;
                                    }
                                }
                                if (dat.sale_estimated_price > 0) {
                                    this.num_jobs_diff_price++;
                                    if (diff_estimated_price > 0) {
                                        this.sum_diff_price++;
                                    }
                                }
                                n++;
                            }
                        }
                    }

                    this.chartOptions = {
                        chart: {
                            type: 'solidgauge',
                            backgroundColor: '#ffffff',
                            width: '280'
                        },
                        tooltip: {
                            enabled: false,
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        pane: {
                            startAngle: 0,
                            endAngle: 360,
                            background: [{ // Track for Move
                                outerRadius: '112%',
                                innerRadius: '100%',
                                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0
                            }]
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        plotOptions: {
                            solidgauge: {
                                dataLabels: {
                                    enabled: false
                                },
                                linecap: 'round',
                                stickyTracking: false,
                                rounded: true
                            }
                        },
                        series: [{
                            name: '% ที่ทำได้',
                            data: [{
                                dataLabels: {
                                    useHTML: true,
                                    enabled: true,
                                    inside: false,
                                    overflow: 'none',
                                    crop: true,
                                    color: Highcharts.getOptions().colors[0],
                                    borderWidth: 0,
                                    y: -60,
                                    style: {
                                        fontFamily: 'Helvetica Neue, Roboto, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'normal'
                                    },
                                    formatter: function() {
                                        return '<table><tr><td style="text-align: center;color:' + this.color + ';">' + this.series.name + '</td></tr><tr><td style="text-align: center;font-size:2em;color:' + this.color + ';font-weight: bold;">' + Highcharts.numberFormat(this.y,2) + '%</td></tr></table>';
                                    }
                                },
                                color: Highcharts.getOptions().colors[0],
                                radius: '112%',
                                innerRadius: '100%',
                                y: (this.num_keep_request * 100) / this.num_jobs
                            }]
                        }]
                    };

                    this.chartOptions2 = {
                        chart: {
                            type: 'solidgauge',
                            backgroundColor: '#ffffff',
                            width: '280'
                        },
                        tooltip: {
                            enabled: false,
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        pane: {
                            startAngle: 0,
                            endAngle: 360,
                            background: [{ // Track for Move
                                outerRadius: '112%',
                                innerRadius: '100%',
                                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0
                            }]
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        plotOptions: {
                            solidgauge: {
                                dataLabels: {
                                    enabled: false
                                },
                                linecap: 'round',
                                stickyTracking: false,
                                rounded: true
                            }
                        },
                        series: [{
                            name: '% ที่ทำได้',
                            data: [{
                                dataLabels: {
                                    useHTML: true,
                                    enabled: true,
                                    inside: false,
                                    overflow: 'none',
                                    crop: true,
                                    color: Highcharts.getOptions().colors[0],
                                    borderWidth: 0,
                                    y: -60,
                                    style: {
                                        fontFamily: 'Helvetica Neue, Roboto, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'normal'
                                    },
                                    formatter: function() {
                                        return '<table><tr><td style="text-align: center;color:' + this.color + ';">' + this.series.name + '</td></tr><tr><td style="text-align: center;font-size:2em;color:' + this.color + ';font-weight: bold;">' + Highcharts.numberFormat(this.y,2) + '%</td></tr></table>';
                                    }
                                },
                                color: Highcharts.getOptions().colors[0],
                                radius: '112%',
                                innerRadius: '100%',
                                y: (this.num_keep_promise * 100) / this.num_jobs
                            }]
                        }]
                    };

                    this.chartOptions3 = {
                        chart: {
                            type: 'solidgauge',
                            backgroundColor: '#ffffff',
                            width: '280'
                        },
                        tooltip: {
                            enabled: false,
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        pane: {
                            startAngle: 0,
                            endAngle: 360,
                            background: [{ // Track for Move
                                outerRadius: '112%',
                                innerRadius: '100%',
                                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0
                            }]
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        plotOptions: {
                            solidgauge: {
                                dataLabels: {
                                    enabled: false
                                },
                                linecap: 'round',
                                stickyTracking: false,
                                rounded: true
                            }
                        },
                        series: [{
                            name: '% ที่ทำได้',
                            data: [{
                                dataLabels: {
                                    useHTML: true,
                                    enabled: true,
                                    inside: false,
                                    overflow: 'none',
                                    crop: true,
                                    color: Highcharts.getOptions().colors[0],
                                    borderWidth: 0,
                                    y: -60,
                                    style: {
                                        fontFamily: 'Helvetica Neue, Roboto, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'normal'
                                    },
                                    formatter: function() {
                                        return '<table><tr><td style="text-align: center;color:' + this.color + ';">' + this.series.name + '</td></tr><tr><td style="text-align: center;font-size:2em;color:' + this.color + ';font-weight: bold;">' + Highcharts.numberFormat(this.y,2) + '%</td></tr></table>';
                                    }
                                },
                                color: Highcharts.getOptions().colors[0],
                                radius: '112%',
                                innerRadius: '100%',
                                y: (this.num_within_3_days * 100) / this.num_jobs_within_3_days
                            }]
                        }]
                    };

                    this.chartOptions4 = {
                        chart: {
                            type: 'solidgauge',
                            backgroundColor: '#ffffff',
                            width: '280'
                        },
                        tooltip: {
                            enabled: false,
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        pane: {
                            startAngle: 0,
                            endAngle: 360,
                            background: [{ // Track for Move
                                outerRadius: '112%',
                                innerRadius: '100%',
                                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0
                            }]
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        plotOptions: {
                            solidgauge: {
                                dataLabels: {
                                    enabled: false
                                },
                                linecap: 'round',
                                stickyTracking: false,
                                rounded: true
                            }
                        },
                        series: [{
                            name: '% ที่ทำได้',
                            data: [{
                                dataLabels: {
                                    useHTML: true,
                                    enabled: true,
                                    inside: false,
                                    overflow: 'none',
                                    crop: true,
                                    color: Highcharts.getOptions().colors[0],
                                    borderWidth: 0,
                                    y: -60,
                                    style: {
                                        fontFamily: 'Helvetica Neue, Roboto, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'normal'
                                    },
                                    formatter: function() {
                                        return '<table><tr><td style="text-align: center;color:' + this.color + ';">' + this.series.name + '</td></tr><tr><td style="text-align: center;font-size:2em;color:' + this.color + ';font-weight: bold;">' + Highcharts.numberFormat(this.y,2) + '%</td></tr></table>';
                                    }
                                },
                                color: Highcharts.getOptions().colors[0],
                                radius: '112%',
                                innerRadius: '100%',
                                y: (this.sum_diff_price * 100) / this.num_jobs_diff_price
                            }]
                        }]
                    };
                    this.ready = true;
                    resolve();
                }, error => {
                    //
                });
        });
        return promise;
    }

    public get_date(): string {
        if (this.current_view === 'YEARLY') {
            return this.date.format('YYYY');
        } else if (this.current_view === 'MONTHLY') {
            return this.date.format('MM/YYYY');
        } else if (this.current_view === 'DAILY') {
            return this.date.format('DD/MM/YYYY');
        }
        return '';
    }

    public onSuccess(e: any): void {
        this.ready = true;
    }

    public onError(e: any): void {
        //
    }

    public onViewChange(e: any): void {
        if (e === 'DAILY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'day');
        } else if (e === 'MONTHLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'month');
        } else if (e === 'QUARTERLY') {
            // this.date1 = moment(new Date());
            // this.date2 = moment(new Date()).add(-1, 'year');
        } else if (e === 'YEARLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'year');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public onDateChange(e: any, date: any): void {
        if (this.current_view === 'DAILY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        } else if (this.current_view === 'MONTHLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
        } else if (this.current_view === 'YEARLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh(): void {
        if (this.getting) {
            //
        } else {
            this.loader.show();
            this.getting = true;
            this.getData()
                .then(() => {
                    this.loader.hide();
                    this.getting = false;
                });
        }
    }

    public onCustomerChange(e: any): void {
        this.customer_id = (e && e.id) ? e.id : '';
        this.refresh();
    }

    public get contentHeight(): string {
        const window_height = window.innerHeight;
        const _height = '' + (((window_height * (100 / this.current_zoom)) - 170 - 24) * .5) + 'px';
        return _height;
    }

}
