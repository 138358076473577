import {Model} from '../now/model';

export class ShelfModel extends Model {

    public name: string;
    public warehouse_id: string;

    constructor() {
        super('shelves', 'App\\Shelf');
    }

}
