import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {MachineModel} from '../../../../models/machine.model';

@Component({
    selector: 'axis-modal-component',
    templateUrl: 'axisModal.component.html',
    styleUrls: ['axisModal.component.scss']
})
export class AxisModalComponent implements AfterViewInit {

    public machine: MachineModel;
    public axis_name: string;
    public axis_value: string;

    constructor(public bsModalRef: BsModalRef) {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.machine) {
                this.axis_name = null;
                this.machine = this.bsModalRef.content.machine;
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid && this.axis_name) {
            this.axis_name = this.axis_name.toLowerCase();
            this.machine.axis['_' + this.axis_name] = this.axis_value;
            this.bsModalRef.hide();
        } else {
            //
        }
    }

}
