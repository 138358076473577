import {Injectable} from '@angular/core';
import {CustomerModel} from '../models/customer.model';
import {Api} from '../now/api/api';

@Injectable()
export class CustomerService {

    constructor(private api: Api) {
        //
    }

    public getDefaultCustomer(): Promise<CustomerModel> {
        let promise: Promise<CustomerModel>;
        promise = new Promise<CustomerModel>((resolve, reject) => {
            this.api.request('customers/default', 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let customer: CustomerModel;
                    customer = new CustomerModel();
                    customer.clone(response.data);
                    resolve(customer);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

}
