import {FormModel} from '../now/formModel';

export class HardeningModel extends FormModel {

    public name: string;
    public price: number;
    public ranking: number;

    constructor() {
        super('hardenings', 'App\\Hardening');
    }

}