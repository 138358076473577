import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {SwalService} from '../../../../services/swal.service';
import {PricingInquiryDocument} from '../../../../app/api/pricingInquiryDocument';
import {Api} from '../../../../now/api/api';

@Component({
    selector: 'app-pricing-inquiry-document-detail-component',
    templateUrl: 'pricingInquiryDocumentDetail.component.html',
    styleUrls: ['pricingInquiryDocumentDetail.component.scss']
})
export class PricingInquiryDocumentDetailComponent implements OnInit, OnDestroy {

    public tmp_document: PricingInquiryDocument;
    public current_document: PricingInquiryDocument;
    public document_index: number;

    constructor(public bsModalRef: BsModalRef, private api: Api, private swal: SwalService) {
        this.tmp_document = {
            name: ''
        };
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document_index > -1) {
                this.document_index = this.bsModalRef.content.document_index;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.document) {
                this.tmp_document.id = this.bsModalRef.content.document.id;
                this.tmp_document.name = this.bsModalRef.content.document.name;
                this.tmp_document.vendor_id = this.bsModalRef.content.document.vendor_id;
                this.current_document = this.bsModalRef.content.document;
            }
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public delete(): void {
        this.swal.confirm('คุณต้องการลบเอกสาร "' + this.tmp_document.name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.api.request('pricing/inquiries/document/delete', 'POST', {}, {
                        id: this.tmp_document.id
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.bsModalRef.content.deleted = true;
                            this.bsModalRef.hide();
                        } else {
                            //
                        }
                    });
                } else {
                    //
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            if (this.tmp_document && this.tmp_document.id) {
                this.api.request('pricing/inquiries/document', 'POST', {}, {
                    id: this.tmp_document.id,
                    name: this.tmp_document.name,
                    // vendor_id: this.tmp_document.vendor_id
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.bsModalRef.content.submit = true;
                        this.bsModalRef.content.name = this.tmp_document.name;
                        this.bsModalRef.hide();
                    } else {
                        //
                    }
                }, error => {
                    //
                });
            }
        } else {
            //
        }
    }

}
