import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {StoreModel} from '../../../../models/store.model';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {TaskService} from '../../../../services/task.service';
import {AuthService} from '../../../../now/user/auth.service';
import {ModalService} from '../../../../services/modal.service';
import {TaskModel} from '../../../../models/task.model';
import {ViewTask} from '../../view/viewTask';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../../now/user/user.model';
import {RemarkModal} from '../../../../modals/remark/remark.modal';
import {PurchaseOrderModel} from '../../../../models/purchaseOrder.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'purchase-order-store-component',
    templateUrl: 'purchaseOrderStore.component.html',
    styleUrls: ['purchaseOrderStore.component.scss']
})
export class PurchaseOrderStoreComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;
    @ViewChild('prepareRemarkModal', { static: true }) prepareRemarkModal: RemarkModal;

    public store: StoreModel;
    public purchase_order: PurchaseOrderModel;

    constructor(
        public location: Location,
        public viewer: Viewer,
        public api: Api,
        public modelApi: ModelApi,
        public swal: SwalService,
        public taskService: TaskService,
        private route: ActivatedRoute,
        public authService: AuthService,
        public modal: ModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.task = new TaskModel();
        this.purchase_order = new PurchaseOrderModel();
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.onSuccess(this.task);
                        });
                }
            });
    }

    ngOnDestroy() {
        //
    }

    public accept(): void {
        for (let i = 0; i < this.purchase_order.purchases.length; i++) {
            const material: any = this.purchase_order.purchases[i];
            if (material && material.id && material.pivot.status !== 2) {
                this.swal.danger('กรุณาเตรียมการวัสดุให้สำเร็จก่อนส่งดำเนินการต่อไป');
                return;
            }
        }
        this.prepareRemarkModal.open();
    }

    public reject(): void {
        this.swal.confirm('ยืนยันปฏิเสธการร้องขอเบิกวัตถุดิบ/วัสดุเลขที่ "' + this.purchase_order.purchase_order_no + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.remarkModal.open();
                }
            });
    }

    public onPrepareRemarkModalSubmit(e: any): void {
        this.api.request('purchase_orders/prepare', 'POST', {}, {
            id: this.purchase_order.id,
            task_id: this.task_id,
            remark: e.remark_message,
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                this.taskService.setStatus(
                    this.task,
                    this.task.status,
                    'prepared_request_purchase_order',
                    'prepared-purchase_order',
                    'prepared-purchase_order',
                    false,
                    this.purchase_order.man.id,
                    e.remark_message
                ).then(() => {
                    this.swal.success('ดำเนินการสำเร็จ');
                    this.leave(true);
                });
            }
        });
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.purchase_order.man && this.purchase_order.man.id) {
            this.api.request('purchase_orders/reject', 'POST', {}, {
                id: this.purchase_order.id,
                remark: e.remark_message,
                task_id: this.task_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.taskService.setStatus(
                        this.task,
                        this.task.status,
                        'reject_request_purchase_order',
                        'request-purchase_order',
                        'request-purchase_order',
                        false,
                        this.purchase_order.man.id,
                        e.remark_message
                    ).then(() => {
                        this.swal.success('ปฏิเสธสำเร็จ');
                        this.leave(true);
                    });
                }
            });
        } else {
            this.swal.danger('เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ');
        }
    }

    public onSuccess(task: TaskModel): void {
        this.purchase_order.id = task.modelable_id;
        this.getPurchaseOrder()
            .then(purchase_order => {
                this.purchase_order = purchase_order;
            });
    }

    public getPurchaseOrder(): Promise<PurchaseOrderModel> {
        let promise: Promise<PurchaseOrderModel>;
        promise = new Promise<PurchaseOrderModel>((resolve, reject) => {
            this.api.request('purchase/orders/' + this.purchase_order.id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let purchase_order: PurchaseOrderModel;
                    purchase_order = new PurchaseOrderModel();
                    purchase_order.clone(response.data);
                    if (response.data.request_man && response.data.request_man.id) {
                        let man: UserModel;
                        man = new UserModel();
                        man.clone(response.data.request_man);
                        purchase_order.request_man = man;
                    } else {
                        purchase_order.request_man = new UserModel();
                    }
                    resolve(purchase_order);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

}
