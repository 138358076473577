import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {JobModel} from '../../models/job.model';
import {ProductModel} from '../../models/product.model';
import {ProcessModel} from '../../models/process.model';
import {FVD} from '../../app/api/fvd';
import {DocumentModel} from '../../models/document.model';
import {Api} from '../../now/api/api';
import {ProcessService} from '../../services/process.service';
import {HardeningModel} from '../../models/hardening.model';
import {HardeningService} from '../../services/hardening.service';
import {CosmeticService} from '../../services/cosmetic.service';
import {CosmeticModel} from '../../models/cosmetic.model';
import {FVDService} from '../../services/fvd.service';
import {SwalService} from '../../services/swal.service';
import {MaterialModel} from '../../models/material.model';
import {TaskModel} from '../../models/task.model';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'fvd-form',
    templateUrl: 'fvd.form.html',
    styleUrls: ['fvd.form.scss']
})
export class FVDForm implements AfterViewInit {

    @Output('onSubmit') onFVDSubmit: EventEmitter<FVD> = new EventEmitter<FVD>();
    @Output('onCancel') onFVDCancel: EventEmitter<any> = new EventEmitter<any>();

    @Input('job') job: JobModel;
    @Input('product') product: ProductModel;
    @Input() fvd: FVD;
    @Input('task') task: TaskModel;

    @Input() editable: boolean;

    public job_id: string;
    public ready: boolean;

    public processes: ProcessModel[];
    public hardenings: HardeningModel[];
    public cosmetics: CosmeticModel[];
    public materials: MaterialModel[];

    constructor(
        private api: Api,
        private processService: ProcessService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private fvdService: FVDService,
        private swal: SwalService,
        private viewer: Viewer
    ) {
        //
        this.editable = true;
        this.ready = false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.processes = [];
            this.hardenings = [];
            this.cosmetics = [];
            this.materials = [];

            if (this.job) {
                this.job_id = this.job.id;
            }
            const promise_list: any[] = [
                this.getProcesses(),
                this.getHardenings(),
                this.getCosmetics(),
            ];
            Promise.all(promise_list)
                .then(() => {
                    this.initMaterials();
                    this.ready = true;
                });
        }, 0);
    }

    public initMaterials(): void {
        if (this.product && this.product.materials) {
            for (let i = 0; i < this.product.materials.length; i++) {
                const mat: MaterialModel = this.product.materials[i];
                if (mat) {
                    if (mat.checked) {
                        this.materials.push(mat);
                    }
                    if (mat.children && mat.children.length) {
                        for (let j = 0; j < mat.children.length; j++) {
                            const child_mat: MaterialModel = mat.children[j];
                            if (child_mat.checked) {
                                this.materials.push(child_mat);
                            }
                        }
                    }
                }
            }
        }
    }

    public onHardeningChange(e: any): void {
        this.fvd.modelable_type = (e && e.id) ? 'App\\Hardening' : null;
        this.fvd.modelable_id = (e && e.id) ? e.id : null;
    }

    public onCosmeticChange(e: any): void {
        this.fvd.modelable_type = (e && e.id) ? 'App\\Cosmetic' : null;
        this.fvd.modelable_id = (e && e.id) ? e.id : null;
    }

    public onProcessChange(e: any): void {
        this.fvd.process_id = (e && e.id) ? e.id : null;
    }

    public onVendorChange(e: any): void {
        this.fvd.supplier1_id = (e && e.id) ? e.id : null;
    }

    public onTypeaheadSelect(e: any, fvd: FVD, modelable_type: string): void {
        if (modelable_type) {
            fvd.modelable_type = modelable_type;
        }
    }

    public getCosmetics(): Promise<CosmeticModel[]> {
        let promise: Promise<CosmeticModel[]>;
        promise = new Promise<CosmeticModel[]>((resolve, reject) => {
            this.cosmeticService.getCosmetics()
                .then(cosmetics => {
                    this.cosmetics = ([]).concat(cosmetics);
                    resolve(this.cosmetics);
                }, error => {
                    this.cosmetics = [];
                    resolve([]);
                });
        });
        return promise;
    }

    public getHardenings(): Promise<HardeningModel[]> {
        let promise: Promise<HardeningModel[]>;
        promise = new Promise<HardeningModel[]>((resolve, reject) => {
            this.hardeningService.getHardenings()
                .then(hardenings => {
                    this.hardenings = ([]).concat(hardenings);
                    resolve(this.hardenings);
                }, error => {
                    this.hardenings = [];
                    resolve([]);
                });
        });
        return promise;
    }

    public getProcesses(): Promise<ProcessModel[]> {
        let promise: Promise<ProcessModel[]>;
        promise = new Promise<ProcessModel[]>((resolve, reject) => {
            this.processService.getProcesses()
                .then(processes => {
                    this.processes = ([]).concat(processes);
                    resolve(this.processes);
                }, error => {
                    this.processes = [];
                    resolve([]);
                });
        });
        return promise;
    }

    public viewFVD(): void {
        this.viewer.fvd(this.fvd);
    }

    public doCancel(): void {
        this.swal.confirm('ยกเลิกการออกเอกสาร FVD ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.removeFVD()
                        .then(() => {
                            this.onFVDCancel.emit(this.fvd);
                            this.swal.success('ยกเลิกเอกสาร FVD สำเร็จ');
                        });
                }
            });
    }

    public doSubmit(): void {
        if (this.fvd && this.fvd.id) {
            this.createOrUpdateFVD()
                .then(() => {
                    this.onFVDSubmit.emit(this.fvd);
                    this.swal.success('ยืนยันข้อมูล FVD สำเร็จ');
                });
        } else {
            this.swal.confirm('ยืนยันการออกเอกสาร FVD ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.createOrUpdateFVD()
                            .then(() => {
                                this.onFVDSubmit.emit(this.fvd);
                                this.swal.success('ออกเอกสาร FVD สำเร็จ');
                            });
                    } else {
                        //
                    }
                });
        }
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.fvd && this.fvd.id) {
            this.addDocument(this.fvd, data);
        } else {
            this.createOrUpdateFVD()
                .then(fvd => {
                    this.addDocument(this.fvd, data);
                }, error => {
                    //
                });
        }
    }

    public addDocument(fvd: FVD, data: any): void {
        this.api.request('fvds/document', 'PUT', {}, {
            document: data,
            id: fvd.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                if (!this.fvd.documents) {
                    this.fvd.documents = [];
                }
                this.fvd.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public removeFVD(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.fvdService.removeFVD(this.fvd)
                .then(() => {
                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    public createOrUpdateFVD(): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            this.fvdService.createOrUpdateFVD(this.fvd)
                .then(fvd => {
                    this.fvd.id = fvd.id;
                    resolve(this.fvd);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
