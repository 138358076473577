import {AfterViewInit, Component} from '@angular/core';

@Component({
    selector: 'root-nav',
    templateUrl: './rootNav.component.html'
})
export class RootNavComponent implements AfterViewInit {

    public is_ready: boolean;

    constructor() {
        this.is_ready = false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.is_ready = true;
        }, 0);
    }

}
