import {FormModel} from '../now/formModel';
import {UserModel} from '../now/user/user.model';
import {VendorModel} from './vendor.model';
import {Model} from '../now/model';
import {SupplierModel} from './supplier.model';
import {DocumentModel} from './document.model';

export class InstrumentModel extends FormModel {

    public supplier_id: string;
    public account_approved_id: string;
    public fmt_approved_id: string;
    public pd_approved_id: string;

    public processes: string[];

    public brand: string;
    public power: number;
    public round: number;
    public manual: number;
    public purchase_date: string;
    public remark: string;

    public axis_x: number;
    public axis_y: number;
    public axis_z: number;
    public axis_a: number;
    public axis_e: number;

    public instrument_no: string;
    public name: string;
    public model_no: string;
    public serial_no: string;
    public process_id: string;
    public width: number;
    public height: number;
    public length: number;
    public weight: number;
    public voltage: number;
    public electric_current: number;
    public type: number;
    public type_note: string;
    public bought_date: string;
    public maintained_date: string;
    public status: number;
    public price: number;
    public time: number;
    public ranking: number;

    public users: UserModel[];
    public vendor: VendorModel;
    public supplier: SupplierModel;
    public documents: DocumentModel[];
    public photos: any;
    public histories: any[];

    constructor() {
        super('instruments', 'App\\Instrument');
        this.users = [];
        this.documents = [];
        this.histories = [];
        this.vendor = new VendorModel();
        this.supplier = new SupplierModel();
        this.photos = {
            front: {},
            back: {}
        }
    }

    public clone(data: any): Model {
        if (data && data.vendor) {
            this.vendor.clone(data.vendor);
            delete data.vendor;
        }
        if (data && data.supplier) {
            this.supplier.clone(data.supplier);
            delete data.supplier;
        }
        super.clone(data);
        return this;
    }

    public addUser(user: UserModel | UserModel[]): void {
        if (!this.users) {
            this.users = [];
        }
        let index: number;
        if (Array.isArray(user)) {
            for (let i = 0; i < user.length; i++) {
                index = this.users.findIndex(item => item.id === user[i].id);
                if (index < 0) {
                    this.users.push(user[i]);
                }
            }
        } else {
            index = this.users.findIndex(i => i.id === user.id);
            if (index < 0) {
                this.users.push(user);
            } else {
                this.users[index] = user;
            }
        }
    }

    public removeUserByUserId(user_id: string): void {
        let index: number;
        index = this.users.findIndex(i => i.id === user_id);
        if (index > -1) {
            this.users.splice(index, 1);
        } else {
            //
        }
    }

    public get avatar(): string {
        if (this.photos && this.photos.front && this.photos.front.preview) {
            return this.photos.front.preview;
        }
        return null;
    }

    public get total_instrument_price(): number {
        // let total: number;
        // total = 0;
        // for (let i = 0; i < this.users.length; i++) {
        //     let user: UserModel;
        //     user = this.users[i];
        //     total += this.price * user.time;
        // }
        // return total;
        if (this.time > 0) {
            return this.price * this.time;
        }
        return 0;
    }

    public get total_price(): number {
        let total: number;
        total = this.total_instrument_price;
        /*for (let i = 0; i < this.users.length; i++) {
            let user: UserModel;
            user = this.users[i];
            total += user.total_price;
        }*/
        return total;
    }

}
