import {Model} from '../now/model';
import {ItemRequirementDocumentModel} from './itemRequirementDocument.model';

export class ItemRequirementModel extends Model {

    public product_name: string;
    public description: string;
    public todo: string[];
    public item_requirement_documents: ItemRequirementDocumentModel[];
    public requirement_id: string;
    public product_amount: number;
    public todo_other: string;

    constructor() {
        super('item/requirements', 'App\\ItemRequirement');
        this.item_requirement_documents = [];
        this.todo = [];
    }

    public clone(data: any): Model {
        if (data && data.item_requirement_documents) {
            for (let i = 0; i < data.item_requirement_documents.length; i++) {
                const dat: any = data.item_requirement_documents[i];
                if (dat) {
                    let item_requirement_document: ItemRequirementDocumentModel;
                    item_requirement_document = new ItemRequirementDocumentModel();
                    item_requirement_document.clone(dat);
                    this.item_requirement_documents.push(item_requirement_document);
                }
            }
            delete data.item_requirement_documents;
        }
        super.clone(data);
        return this;
    }

}
