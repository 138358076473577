import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {CarrierModel} from '../models/carrier.model';

@Injectable()
export class CarrierService {

    private carriers: CarrierModel[];

    constructor(private api: Api) {
        this.reset_carrier_list();
    }

    public set(list: CarrierModel[]): void {
        this.reset_carrier_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const carrier: CarrierModel = new CarrierModel();
                    carrier.clone(dat);
                    this.carriers.push(carrier);
                }
            }
        }
    }

    public getCarriers(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.carriers && this.carriers.length > 0) {
                for (let i = 0; i < this.carriers.length; i++) {
                    this.carriers[i].checked = false;
                }
                resolve(this.carriers);
            } else {
                this.api.request('carriers', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_carrier_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.carriers.push(dat);
                                }
                            }
                            resolve(this.carriers);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_carrier_list(): void {
        if (this.carriers) {
            this.carriers.splice(0, this.carriers.length);
        } else {
            this.carriers = [];
        }
    }

}
