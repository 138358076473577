import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TaskModel} from '../../../models/task.model';
import {CustomerModel} from '../../../models/customer.model';
import {ContactModel} from '../../../models/contact.model';
import {ProductModel} from '../../../models/product.model';
import {DocumentModel} from '../../../models/document.model';
import {DivApiDirective} from '../../../now/divApi';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {UserModel} from '../../../now/user/user.model';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../../now/user/auth.service';
import {QuotationModel} from '../../../models/quotation.model';
import {SwalService} from '../../../services/swal.service';
import {UserService} from '../../../services/user.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {PusherService} from '../../../services/pusher.service';
import {ModalService} from '../../../services/modal.service';
import {CarrierModel} from '../../../models/carrier.model';
import {CarrierService} from '../../../services/carrier.service';
import {JobModel} from '../../../models/job.model';
import {SchedulerModel} from '../../../models/scheduler.model';
import * as moment from 'moment';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {ViewTask} from '../view/viewTask';
import {DOCUMENT, Location} from '@angular/common';
import {DocumentDetailComponent} from '../view/documentDetail/documentDetail.component';
import {NcrModel} from '../../../models/ncr.model';
import {ReportModalComponent} from '../../../modals/reportModal/reportModal.component';
import {AddRemarkComponent} from '../view/addRemark/addRemark.component';
import {HardeningModel} from '../../../models/hardening.model';
import {PageScrollService} from 'ngx-page-scroll-core';
import {CosmeticModel} from '../../../models/cosmetic.model';
import {JobService} from '../../../services/job.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {PROBLEM_DETAILS, PROBLEM_TYPES, TOOLS} from '../../../consts/ncr.const';
import {NgxPageScrollDirective} from 'ngx-page-scroll';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'ncr-task-component',
    templateUrl: 'ncrTask.component.html',
    styleUrls: ['ncrTask.component.scss']
})
export class NCRTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('qaResult', { static: false }) qaResult: RemarkModal;
    @ViewChild('remarkModal', { static: false }) remarkModal: RemarkModal;
    @ViewChild('pageScrollProblem', { static: false }) pageScrollProblem: NgxPageScrollDirective;

    public ncr: NcrModel;
    public processes: any[];

    public ncr_id: string;
    public job_id: string;
    public task_id: string;
    public customer: CustomerModel;
    public contact: ContactModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public documents: DocumentModel[];
    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public proforma_invoices: any[];
    public tax_invoices: any[];
    public packing_slips: any[];
    public carriers: CarrierModel[];
    public current_role: string;
    public schedulers: SchedulerModel[];
    public hardening: HardeningModel;
    public cosmetic: CosmeticModel;
    public is_manager: boolean;

    public children: any[];
    public parent_job: any;

    public pdfInfo: any;
    public numPages: number[];
    public scheduler_histories: SchedulerHistoryModel[];
    public job: JobModel;
    public planning_events: any[];

    public current_tab: string;
    public current_product: ProductModel;
    public current_product_index: number;
    public current_part_product_index: number;
    public is_ready: boolean;

    public ready: boolean;
    public options: any;

    public today_start: any;
    public today_end: any;

    public total_gap_time_minutes: number;
    public total_minutes: number;

    public document_pdf_path: string;
    public current_step_index: number;
    public current_process: string;

    public problem_details: string[] = PROBLEM_DETAILS;
    public problem_types: string[] = PROBLEM_TYPES;
    public tools: string[] = TOOLS;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        public api: Api,
        private pageScrollService: PageScrollService,
        public modal: ModalService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any,
        private jobService: JobService,
        private smartModalService: NgxSmartModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.today_start = moment(new Date()).set('hours', 8).set('minutes', 0);
        this.today_end = moment(new Date()).set('hours', 20).set('minutes', 0);
        this.scheduler_histories = [];
        this.schedulers = [];
        this.is_manager = false;
        this.processes = [];
        this.total_gap_time_minutes = 0;
        this.total_minutes = 0;
        this.is_ready = false;

        // this.current_page_number = 1;
    }

    private init(): void {
        this.ncr = new NcrModel();
        this.task = new TaskModel();
        this.task.next_role = 'eng';

        this.contact = new ContactModel();
        this.current_product = new ProductModel();
        this.current_product_index = -1;
        this.current_part_product_index = -1;

        this.job = new JobModel();
        this.ready = false;
        this.planning_events = [];
        this.documents = [];
        this.options = {
            resources: []
        };

        this.children = [];
        this.numPages = [];

        this.processService.getProcesses()
            .then(processes => {
                this.processes = [];
                for (const process of processes) {
                    if (process && process.slug) {
                        this.processes.push({
                            id: process.id,
                            name: process.name,
                            slug: process.slug
                        })
                    }
                }
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTaskDetail();
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public reject(state?: number): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.modal) {
                this.modal.show(AddRemarkComponent, {
                    task: this.task,
                    job: this.job,
                    tag: 'reject_ncr',
                }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
                    .then((content: any): void => {
                        if (content && content.sent === true) {
                            if (state === 2 && this.ncr.man && this.ncr.man.id) {
                                this.taskService.setStatus(this.task, this.task.status, 'notice_ncr_1', 'ncr', 'ncr', false, this.ncr.man.id)
                                    .then(() => {
                                        this.swal.success('ปฏิเสธสำเร็จ');
                                        this.leave(true);
                                    });
                            } else if (state === 3 && this.ncr.issued_user && this.ncr.issued_user.id) {
                                this.taskService.setStatus(this.task, this.task.status, 'notice_ncr_2', 'ncr', 'ncr', false, this.ncr.issued_user.id)
                                    .then(() => {
                                        this.swal.success('ปฏิเสธสำเร็จ');
                                        this.leave(true);
                                    });
                            } else {
                                this.taskService.setStatus(this.task, this.task.status, 'reject_ncr_1', 'dummy', 'ncr', false, false)
                                    .then(() => {
                                        this.swal.success('ปฏิเสธสำเร็จ');
                                        this.leave(true);
                                    });
                            }
                            /*this.api.request('jobs/ncr/reject', 'POST', {}, {
                                remark_message: content.remark_message,
                                task_id: this.task.id,
                                id: this.job_id
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    this.taskService.setStatus(this.task, this.task.status, 'reject_ncr')
                                        .then(() => {
                                            this.swal.success('ปฏิเสธสำเร็จ');
                                            this.leave(true);
                                            resolve(response);
                                        });
                                } else {
                                    reject(response);
                                }
                            }, error => {
                                reject(error);
                            });*/
                        } else {
                            reject();
                        }
                    });
            } else {
                reject();
            }
        });
        return promise;
    }

    public checked(): void {
        if (!this.ncr.cause_description) {
            this.swal.danger('รายละเอียดของสาเหตุไม่ได้กรอกข้อมูล');
        } else if (!this.ncr.cause_preventive) {
            this.swal.danger('รายละเอียดการแก้ไข หรือการป้องกัน ไม่ได้กรอกข้อมูล');
        } else {
            this.swal.confirm('ยืนยันการตรวจสอบสำเร็จแล้ว ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this._save()
                            .then(() => {
                                this.api.request('ncrs/checked', 'POST', {}, {
                                    job_id: this.job_id,
                                    // issued_description: remark_message
                                }).subscribe((response: any): void => {
                                    this.taskService.setStatus(this.task, this.task.status, 'ncr_qa', 'qa', 'ncr', false, false)
                                        .then(() => {
                                            this.swal.success('แจ้งเรื่องรายงานข้อผิดพลาดสำเร็จ');
                                            this.leave(true);
                                        });
                                }, error => {
                                    //
                                });
                            });
                    } else {
                        //
                    }
                });
        }
    }

    public approveCheck(): void {
        if (!this.ncr.cause_description) {
            this.swal.danger('รายละเอียดของสาเหตุไม่ได้กรอกข้อมูล');
        } else if (!this.ncr.cause_preventive) {
            this.swal.danger('รายละเอียดการแก้ไข หรือการป้องกัน ไม่ได้กรอกข้อมูล');
        } else {
            super.approveTask()
                .then(() => {
                    this.taskService.setStatus(this.task, this.task.status, 'ncr_qa', 'qa', 'ncr')
                        .then(() => {
                            this.swal.success('ผ่านการอนุมัติสำเร็จ');
                            this.leave(true, true);
                        });
                }, error => {
                    //
                });
        }
    }

    public rejectCheck(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ยืนยันรายการสำเร็จ');
                        this.leave(true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public accept(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (!this.ncr.cause_description || !this.ncr.cause_preventive) {
                this.swal.danger('กรุณาระบุสาเหตุ, วิธีการแก้ไขหรือการป้องกัน');
            } else {
                this.swal.confirm('ยืนยันการส่งตรวจสอบใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this._save()
                                .then(() => {
                                    this.api.request('jobs/ncr/accept', 'POST', {}, {
                                        id: this.job_id
                                    }).subscribe((response: any): void => {
                                        this.showApprovedList()
                                            .then(() => {
                                                this.swal.success('รอการตรวจสอบการเกิด NCR');
                                                this.leave(true);
                                            });
                                    }, error => {
                                        reject(error);
                                    });
                                });
                        } else {
                            //
                        }
                    });
            }
        });
        return promise;
    }

    public save(): void {
        this._save()
            .then(() => {
                this.swal.success('บันทึกข้อมูลสำเร็จ');
            });
    }

    public reworkJob(message?: string, solve_message?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.jobService.reworkJob(this.job, message, solve_message, this.ncr, this.task_id)
                .then(result => {
                    resolve({});
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public continue(): void {
        this.current_step_index = 4;
        // this.smartModalService.open('remarkModal');
        this.remarkModal.open();
    }

    public openRemarkModal_reworkJob(): void {
        this.current_step_index = 0;
        // this.smartModalService.open('remarkModal');
        this.remarkModal.open();
    }

    public onResultRemarkModalSubmit(e: any): void {
        this.api.request('ncrs/approved', 'POST', {}, {
            job_id: this.job_id,
            next_process: this.current_process,
            approved_description: e.remark_message,
            // solve_message: e.solve_message
        }).subscribe((response: any): void => {
            this.api.request('jobs/ncr/process', 'POST', {}, {
                id: this.job_id
            }).subscribe((_response: any): void => {
                if (_response && _response.success === true) {
                    if (this.current_process === 'ASK') {
                        this.leave(true);
                        this.swal.success('รอการตอบกลับจากลูกค้า');
                    } else if (this.current_process === 'TRIAL') {
                        this.leave(true);
                        this.swal.success('รอการตอบกลับจากลูกค้า');
                    } else if (this.current_process === 'ARCHIVE') {
                        this.leave(true);
                        this.swal.success('ยืนยันการดำเนินการตามแผนสำเร็จ');
                    } else {
                        this.leave(true);
                        this.swal.success('ยืนยันการดำเนินการตามแผนสำเร็จ');
                    }
                } else {
                    //
                }
            });
        }, error => {
            //
        });
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.current_step_index === 0) {
            this.reworkJob(e.remark_message, e.solve_message)
                .then(() => {
                    this.taskService.setStatus(this.task, this.task.status, 'rework', 'control', 'control')
                        .then(() => {
                            this.leave(true);
                            this.swal.success('ยืนยันการดำเนินการปรับแผนใหม่สำเร็จ');
                        });
                }, error => {
                    //
                });
        } else if (this.current_step_index === 1) {
            this.showApprovedList()
                .then(() => {
                    this.api.request('ncrs/issued', 'POST', {}, {
                        job_id: this.job_id,
                        issued_description: e.remark_message,
                        solve_message: e.solve_message
                    }).subscribe((response: any): void => {
                        this.swal.success('ส่งตรวจสอบสำเร็จ');
                        this.leave(true);
                    }, error => {
                        //
                    });
                }, error => {
                    //
                });
        } else if (this.current_step_index === 2) {
            this.api.request('ncrs/checked', 'POST', {}, {
                job_id: this.job_id,
                checked_description: e.remark_message,
                solve_message: e.solve_message
            }).subscribe((response: any): void => {
                this.taskService.setStatus(this.task, this.task.status, 'ncr_qa', 'qa', 'ncr', false, false)
                    .then(() => {
                        this.leave(true);
                        this.swal.success('ส่งตรวจสอบกับ QA สำเร็จ');
                    });
            }, error => {
                //
            });
        } else if (this.current_step_index === 3) {
            this.api.request('ncrs/approved', 'POST', {}, {
                job_id: this.job_id,
                next_process: this.current_process,
                approved_description: e.remark_message,
                solve_message: e.solve_message
            }).subscribe((response: any): void => {
                this.api.request('jobs/ncr/process', 'POST', {}, {
                    id: this.job_id
                }).subscribe((_response: any): void => {
                    if (_response && _response.success === true) {
                        if (this.current_process === 'ASK') {
                            this.leave(true);
                            this.swal.success('รอการตอบกลับจากลูกค้า');
                        } else if (this.current_process === 'TRIAL') {
                            this.leave(true);
                            this.swal.success('รอการตอบกลับจากลูกค้า');
                        } else if (this.current_process === 'ARCHIVE') {
                            this.leave(true);
                            this.swal.success('ยืนยันการดำเนินการตามแผนสำเร็จ');
                        } else {
                            this.leave(true);
                            this.swal.success('ยืนยันการดำเนินการตามแผนสำเร็จ');
                        }
                    } else {
                        //
                    }
                });
            }, error => {
                //
            });
        } else if (this.current_step_index === 4) {
            this.api.request('ncrs/archive', 'POST', {}, {
                id: this.ncr.id,
                task_id: this.task_id,
                job_id: this.job_id,
                remark: e.remark_message,
                solve_message: e.solve_message
            }).subscribe((_response: any): void => {
                if (_response && _response.success === true) {
                    this.leave(true);
                    this.swal.success('ดำเนินการตามแผนเดิมสำเร็จ');
                } else {
                    //
                }
            });
        }
    }

    private _save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.ncr.job_id = this.job.id;
            this.ncr.product_id = this.job.product_id;
            this.api.request('jobs/ncr', 'POST', {}, {
                id                      : this.ncr.id,
                product_id              : this.ncr.product_id,
                problem_description     : this.ncr.problem_description,
                problem_type            : this.ncr.problem_type,
                problem_details         : this.ncr.problem_details,
                equipments              : this.ncr.equipments,
                cause_description       : this.ncr.cause_description,
                cause_preventive        : this.ncr.cause_preventive,
                next_process_amount : this.ncr.next_process_amount,
                is_man              : this.ncr.is_man,
                is_machine          : this.ncr.is_machine,
                is_material         : this.ncr.is_material,
                is_method           : this.ncr.is_method,
                man_id              : this.ncr.man_id,
                machine_id          : this.ncr.machine_id,
                material_id         : this.ncr.material_id,
                method_id           : this.ncr.method_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response);
                    // this.taskService.setStatus(this.task, this.task.status, 'create_ncr', 'dummy', 'ncr')
                    //     .then(() => {
                    //         resolve(response);
                    //     });
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public sendNCRCustomer(): void {
        this.swal.confirm('ยืนยันการแจ้งปัญหาการผลิตกับลูกค้าอีกครั้ง ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('mail/ncr', 'POST', {}, {
                        task_id: this.task.id
                    }).subscribe((): void => {
                        this.swal.success('แจ้งปัญหาการผลิตกับลูกค้าสำเร็จ');
                        this.leave(true);
                    });
                }
            });
    }

    public getJobChildren(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id + '/children')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (response.data.parent) {
                            const parent_job: any = response.data.parent;
                            this.children.push({
                                task: parent_job.task,
                                task_id: (parent_job.task) ? parent_job.task.id : null,
                                job_id: parent_job.id,
                                job_current_role: parent_job.current_role,
                                job_process_slug: parent_job.process_slug,
                                task_process_slug: parent_job.task.process_slug,
                                product_name: (parent_job.product) ? parent_job.product.name : null
                            });
                            this.parent_job = parent_job;
                        }
                        if (response.data.children) {
                            for (let i = 0; i < response.data.children.length; i++) {
                                const dat: any = response.data.children[i];
                                if (dat) {
                                    this.children.push({
                                        task: dat.task,
                                        task_id: (dat.task) ? dat.task.id : null,
                                        job_id: dat.id,
                                        job_current_role: dat.current_role,
                                        job_process_slug: dat.process_slug,
                                        task_process_slug: dat.task.process_slug,
                                        product_name: (dat.product) ? dat.product.name : null
                                    });
                                }
                            }
                        }
                        resolve(this.children);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public sendBack(): void {
        this.swal.confirm('ยืนยันการดำเนินการตามแผนเดิมต่อไป ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('jobs/ncr/reject', 'POST', {}, {
                        task_id: this.task.id,
                        id: this.job_id,
                        clear_ncr: true
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.taskService.setStatus(this.task, this.task.status, 'control', 'control', 'planning')
                                .then(() => {
                                    this.swal.success('ยืนยันการดำเนินการตามแผนเดิมสำเร็จ');
                                    this.leave(true);
                                });
                        } else {
                            //
                        }
                    }, error => {
                        //
                    });
                    /*super.rejectTask()
                        .then(() => {
                            let promise_list: Promise<Object>[];
                            promise_list = [];
                            if (this.children && this.children.length > 0) {
                                for (let i = 0; i < this.children.length; i++) {
                                    const job: JobModel = this.children[i];
                                    if (job && job.task_id) {
                                        promise_list.push(this.taskService.reject({id: job.task_id}));
                                    }
                                }
                                Promise.all(promise_list)
                                    .then(() => {
                                        this.swal.success('ยืนยันการดำเนินการตามแผนเดิมสำเร็จ');
                                        this.leave(true);
                                    }, error => {
                                        console.log(error);
                                    });
                            } else {
                                this.swal.success('ยืนยันการดำเนินการตามแผนเดิมสำเร็จ');
                                this.leave(true);
                            }
                        }, error => {
                            this.swal.danger(error)
                                .then(() => {});
                        });*/
                } else {
                    //
                }
            });
    }

    public goBack(): void {
        this.location.back();
    }

    public cancelJob(): void {
        this.swal.confirm('ยืนยันการยกเลิกงานใช่หรือไม่?', false, 'ยกเลิกงาน')
            .then((result: boolean): void => {
                if (result === true) {
                    this.jobService.cancel(this.job, this.task)
                        .then(() => {
                            this.swal.success('ยกเลิกงานสำเร็จ');
                            this.leave(true);
                        });
                }
            });
    }

    public doNCR(): void {
        this.swal.confirm('ยืนยันการออกเอกสาร NCR ใช่หรือไม่?', false, 'ออกเอกสาร NCR')
            .then((result: boolean): void => {
                if (result === true) {
                    this.ncrJob()
                        .then(() => {
                            this.taskService.setStatus(this.task, this.task.status, 'create_ncr', 'dummy', 'ncr')
                                .then(() => {
                                    this.swal.success('ออกเอกสาร NCR')
                                        .then(() => {
                                            this.task.current_role = 'dummy';
                                            this.task.process_slug = 'ncr';
                                            setTimeout(() => {
                                                this.pageScrollService.scroll({
                                                    document: this.document,
                                                    scrollTarget: '#problem'
                                                });
                                            }, 300);
                                        });
                                });
                        });
                } else {
                    //
                }
            });
    }

    public ncrJob(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            if (this.task.modelable_type === 'App\\Job') {
                this.jobService.ncrJob(this.job)
                    .then(ncr => {
                        this.ncr = new NcrModel();
                        this.ncr.clone(ncr);
                        this.document_pdf_path = environment.api_host + 'view/' + this.ncr.id + '/ncr';
                        resolve({});
                    }, error => {
                        reject(error);
                    });
            } else if (this.task.modelable_type === 'App\\NCR') {
                this.api.request('ncrs/start', 'POST', {}, {
                    id: this.ncr_id,
                    task_id: this.task_id
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.document_pdf_path = environment.api_host + 'view/' + this.ncr.id + '/ncr';
                        // if (response.data.ncr) {
                        //     this.ncr.clone(response.data.ncr);
                        // }
                        this.getNCRDetail()
                            .then(() => {
                                this.getJob(this.job_id)
                                    .then(() => {
                                        resolve({});
                                    });
                            });
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            }
        });
        return promise;
    }


    public sendNCR(): void {
        if (this.ncr && this.ncr.man && this.ncr.man.id) {
            this.swal.confirm('ยืนยันการแจ้งผู้กระทำผิดพลาด ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this._save()
                            .then(() => {
                                console.log(this.task.status, this.task.action, this.task.current_role, this.task.process_slug);
                                this.taskService.setStatus(this.task, this.task.status, 'notice_ncr', 'ncr', 'ncr', false, this.ncr.man.id)
                                    .then(() => {
                                        this.swal.success('แจ้งเรื่องรายงานข้อผิดพลาดสำเร็จ');
                                        this.leave(true);
                                    });
                            });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('ไม่ได้ระบุผู้กระทำผิดพลาด');
        }
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                // this.disabledRoleService.set(this.task, ['planning', 'sale-manager', 'qa'], ['product']);
                this.onSuccess(response.data);
            }, error => {
                this.onError(error);
            });
    }

    public archiveTask(): void {
        //
    }

    public onSuccess(data: any): void {
        if (data) {
            this.task.clone(data);
        }
        if (this.task.modelable_type === 'App\\Job') {
            this.job_id = this.task.modelable_id;
            Promise.all([this.getJob(this.task.modelable_id), this.getNCRDetailByJobId()])
                .then(() => {
                    this.getProductDetail(this.job.product_id)
                        .then(() => {
                            this.getJobChildren(this.job_id)
                                .then((children_response: any): void => {
                                    this.doReady();
                                }, error => {
                                    this.swal.danger(error);
                                    console.error(error);
                                });
                        });
                    //
                    const user: UserModel = this.authService.user;
                    if (user && user.roles && user.level < 4) {
                        this.is_manager = true;
                    } else {
                        this.is_manager = false;
                    }
                }, error => {
                    //
                });
        } else {
            this.ncr_id = this.task.modelable_id;
            this.getNCRDetail()
                .then(() => {
                    this.getJob(this.ncr.job_id)
                        .then(() => {
                            this.getProductDetail(this.job.product_id)
                                .then(() => {
                                    this.doReady();
                                });
                            const user: UserModel = this.authService.user;
                            if (user && user.roles && user.level < 4) {
                                this.is_manager = true;
                            } else {
                                this.is_manager = false;
                            }
                        });
                });
        }
    }

    public onManChange(e: any): void {
        this.ncr.man_id = (e && e.id) ? e.id : null;
    }

    public onMachineChange(e: any): void {
        this.ncr.machine_id = (e && e.id) ? e.id : null;
    }

    public onMaterialChange(e: any): void {
        this.ncr.material_id = (e && e.id) ? e.id : null;
    }

    public onMethodChange(e: any): void {
        this.ncr.method_id = (e && e.id) ? e.id : null;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        if (this.job.ncr_equipments) {
                            //
                        } else {
                            this.job.ncr_equipments = [
                                '', '', '', '', ''
                            ];
                        }
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getNCRDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('ncrs/' + this.ncr_id)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.ncr.clone(response.data);
                        if (this.ncr && this.ncr.equipments && Array.isArray(this.ncr.equipments)) {
                            for (let i = 0; i < this.ncr.equipments.length; i++) {
                                if (this.ncr.equipments[i]) {
                                    //
                                } else {
                                    this.ncr.equipments[i] = '';
                                }
                            }
                        } else {
                            this.ncr.equipments = ['', '', '', '', ''];
                        }
                        this.document_pdf_path = environment.api_host + 'view/' + this.ncr.id + '/ncr';
                        if (response.data.job_id) {
                            this.job_id = response.data.job_id;
                            // this.job.clone(response.data.job);
                        }
                        resolve();
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getNCRDetailByJobId(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + this.job_id + '/ncr')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.ncr.clone(response.data);
                        if (this.ncr && this.ncr.equipments && Array.isArray(this.ncr.equipments)) {
                            for (let i = 0; i < this.ncr.equipments.length; i++) {
                                if (this.ncr.equipments[i]) {
                                    //
                                } else {
                                    this.ncr.equipments[i] = '';
                                }
                            }
                        } else {
                            this.ncr.equipments = ['', '', '', '', ''];
                        }
                        this.document_pdf_path = environment.api_host + 'view/' + this.ncr.id + '/ncr';
                        resolve();
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onError(e: any): void {
        //
    }

    private getProductDetail(product_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (product_id) {
                this.modelApi.sync(this.current_product, product_id)
                    .subscribe((response: any): void => {
                        this.hardening = null;
                        this.cosmetic = null;
                        if (this.current_product && this.current_product.product_processes) {
                            const product_process_hardening = this.current_product.product_processes
                                .find(i => i.modelable_type === 'App\\Hardening');
                            if (product_process_hardening && product_process_hardening.id && product_process_hardening.modelable) {
                                this.hardening = product_process_hardening.modelable;
                            }
                            const product_process_cosmetic = this.current_product.product_processes
                                .find(i => i.modelable_type === 'App\\Cosmetic');
                            if (product_process_cosmetic && product_process_cosmetic.id && product_process_cosmetic.modelable) {
                                this.cosmetic = product_process_cosmetic.modelable;
                            }
                        } else {
                            //
                        }
                        resolve(this.current_product);
                    }, error => {
                        reject();
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private reset_products(): void {
        if (this.products) {
            this.products.splice(0, this.products.length);
        } else {
            this.products = [];
        }
    }

    public viewDocumentDetail(doc: DocumentModel, i: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((data: any): void => {
                if (data.deleted === true) {
                    this.modelApi.delete(doc, 'documents/delete')
                        .subscribe((response: any): void => {
                            this.ncr.documents.splice(i, 1);
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('ncrs/document', 'PUT', {}, {
            document: data,
            id: this.job_id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.ncr.documents.push(new_document);
            } else {
                //
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public issuedTask(): void {
        this.current_step_index = 1;
        // this.smartModalService.open('remarkModal');
        this.remarkModal.open();
    }

    public checkedTask(): void {
        this.current_step_index = 2;
        // this.smartModalService.open('remarkModal');
        this.remarkModal.open();
    }

    public approvedTask(process: string): void {
        this.current_step_index = 3;
        this.current_process = process;
        // this.smartModalService.open('remarkModal');
        this.qaResult.open();
    }

    public nextFinal(): void {
        this.swal.confirm('ยืนยันส่งต่อการออก NCR ตามผลสรุป ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('jobs/ncr/process', 'POST', {}, {
                        id: this.job_id
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.swal.success('ยืนยันการดำเนินการตามแผนสำเร็จ');
                            this.leave(true);
                        } else {
                            //
                        }
                    });
                } else {
                    //
                }
            });
    }

    public sendToPdControl(type: number): void {
        this.swal.confirm('ยืนยันการส่งมอบงานต่อให้กับ PD Control เพื่อทำการแก้ไข/ปรับแผน ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('ncrs/approved', 'POST', {}, {
                        job_id: this.job_id,
                        type: type
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.taskService.setStatus(this.task, this.task.status, 'control', 'control', 'planning')
                                .then(() => {
                                    this.swal.success('ยืนยันการดำเนินการตามแผนสำเร็จ');
                                    this.leave(true);
                                });
                        } else {
                            //
                        }
                    });
                } else {
                    //
                }
            });
    }

    // แก้ไข/ปรับแผน (อันเก่า)
    /*public createJob(): void {
        this.modal.show(NcrModalComponent, {
            task: this.task,
            ncr: this.ncr,
            product: this.ncr.product
        }).then((content: any): void => {
            if (content && content.is_submit === true) {
                //
            } else {
                //
            }
        });
    }*/

    public warning(): void {
        this.modal.show(ReportModalComponent, {
            task: this.task
        }).then((content: any): void => {
            if (content && content.is_submit === true) {
                this.api.request('ncrs/report', 'POST', {}, {
                    // id: this.ncr.id,
                    job_id: this.job_id,
                    report_message: content.message
                }).subscribe((response: any): void => {
                    this.taskService.setStatus(this.task, this.task.status, 'feedback_process')
                        .then(() => {
                            this.swal.success('รอการตอบกลับจากลูกค้าภายหลัง');
                            this.leave(true);
                        }, error => {
                            this.swal.danger(error);
                        });
                }, error => {
                    //
                });
            } else {
                //
            }
        });
    }

    public onSaveApiCallingBack(e: any): void {
        this._save()
            .then(() => {
                //
            });
    }

}
