import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {ProductModel} from '../../../../models/product.model';
import {TaskModel} from '../../../../models/task.model';
import {UserService} from '../../../../services/user.service';
import {CustomerModel} from '../../../../models/customer.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../../now/user/user.model';
import {IncotermService} from '../../../../services/incoterm.service';
import {CarrierService} from '../../../../services/carrier.service';
import {TaskService} from '../../../../services/task.service';
import {ModalService} from '../../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../../now/user/auth.service';
import {ViewTask} from '../../view/viewTask';
import {JobModel} from '../../../../models/job.model';
import {StoreService} from '../../../../services/store.service';
import {PricingInquiryService} from '../../../../services/pricingInquiry.service';
import {FVD} from '../../../../app/api/fvd';
import {FVDService} from '../../../../services/fvd.service';
import {DocumentModel} from '../../../../models/document.model';
import {MaterialModel} from '../../../../models/material.model';
import {ProcessModel} from '../../../../models/process.model';
import {HardeningModel} from '../../../../models/hardening.model';
import {CosmeticModel} from '../../../../models/cosmetic.model';
import {ProcessService} from '../../../../services/process.service';
import {HardeningService} from '../../../../services/hardening.service';
import {CosmeticService} from '../../../../services/cosmetic.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {RemarkModal} from '../../../../modals/remark/remark.modal';
import {environment} from '../../../../environments/environment';
import {AddRemarkComponent} from '../../view/addRemark/addRemark.component';
import {PricingInquiryModel} from '../../../../models/pricingInquiry.model';
import {VendorModel} from '../../../../models/vendor.model';
import {UploaderComponent} from '../../../../components/uploader/uploader.component';
import {DocumentDetailComponent} from '../../view/documentDetail/documentDetail.component';
import * as moment from 'moment';
import {SchedulerModel} from '../../../../models/scheduler.model';
import {FullCalendarComponent} from '../../../../components/fullCalendar/fullCalendar.component';
import {ProductMaterial} from '../../../../models/productMaterial';
import {MaterialCreateModal} from '../../view/product/materialCreate/materialCreate.modal';
import {SupplierModel} from '../../../../models/supplier.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'request-fvd-task-component',
    templateUrl: 'requestFVDTask.component.html',
    styleUrls: ['requestFVDTask.component.scss'],
    providers: [DecimalPipe]
})
export class RequestFVDTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild('supplierRemarkModal', { static: true }) supplierRemarkModal: RemarkModal;
    @ViewChild('approvedRemarkModal', { static: true }) approvedRemarkModal: RemarkModal;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('uploader', { static: true }) uploader: UploaderComponent;

    public current_supplier_index: number;
    public job: JobModel;
    public product: ProductModel;
    public fvd: FVD;
    public sale: UserModel;
    public customer: CustomerModel;
    public current_tab: string;
    public items: ProductModel[];
    public job_no: string;
    public product_no: string;
    public materials: MaterialModel[];
    public remarks: any[];

    public processes: ProcessModel[];
    public hardenings: HardeningModel[];
    public cosmetics: CosmeticModel[];

    public schedulers: SchedulerModel[];
    public current_supplier: VendorModel;
    public job_id: string;
    public product_id: any;

    public ready: boolean;
    public options: any;

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private processService: ProcessService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private swal: SwalService,
        private pricingInquiryService: PricingInquiryService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        private storeService: StoreService,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        private route: ActivatedRoute,
        public location: Location,
        private fvdService: FVDService,
        private smartModal: NgxSmartModalService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.remarks = [];
        this.processes = [];
        this.hardenings = [];
        this.cosmetics = [];

        this.schedulers = [];

        this.current_tab = '#information';
        this.items = [];
        this.job = new JobModel();
        this.fvd = {
            documents: []
        };
        this.materials = [];
        this.job_no = '';
        this.product_no = '';
        this.product = new ProductModel();
        this.options = {
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month'
            },
            events: []
        };
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.getProcesses().then(() => { });
                    this.getHardenings().then(() => { });
                    this.getCosmetics().then(() => { });

                    this.task = new TaskModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTaskDetail();
                        this.getRemarks()
                            .then(() => {});
                    } else {
                        //
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public refreshCalendar(): void {
        if (this.schedulers) {
            this.schedulers.splice(0, this.schedulers.length);
        } else {
            this.schedulers = [];
        }
        if (this.options.events) {
            this.options.events.splice(0, this.options.events.length);
        } else {
            this.options.events = [];
        }
        for (let i = 0; i < this.product.product_processes.length; i++) {
            const product_process = this.product.product_processes[i];
            if (product_process && product_process.checked && product_process.revision === this.job.revision) {
                for (let j = 0; j < product_process.schedulers.length; j++) {
                    const scheduler = product_process.schedulers[j];
                    if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                        const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                        const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                        const event_scheduler: any = {
                            title: ((product_process.is_fvd) ? '(FVD)' : '') + product_process.process.name,
                            start: start,
                            end: end,
                        };
                        this.schedulers.push(event_scheduler);
                        this.options.events.push(event_scheduler);
                    }
                }
            }
            if (product_process && product_process.children && product_process.children.length) {
                for (let j = 0; j < product_process.children.length; j++) {
                    const child: any = product_process.children[j];
                    if (child && child.checked && child.schedulers && child.revision === this.job.revision) {
                        for (let n = 0; n < child.schedulers.length; n++) {
                            const scheduler = child.schedulers[n];
                            if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                                const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                                const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                                const event_scheduler: any = {
                                    title: child.process.name + ((child.is_fvd) ? '(FVD)' : ''),
                                    start: start,
                                    end: end,
                                };
                                this.schedulers.push(event_scheduler);
                                this.options.events.push(event_scheduler);
                            }
                        }
                    }
                }
            }
        }
        console.log(this.options.events);
        this.options.events.push(this.addConfirmedDeliveryDate(this.job.confirmed_delivery_date));
        if (this.ready === true) {
            this.fullCalendarComponent.removeEvents();
            this.fullCalendarComponent.addEventSource(this.options.events);
            this.fullCalendarComponent.rerenderEvents();
        }
    }

    public addConfirmedDeliveryDate(date: string): any {
        const date_moment_obj: any = moment(date, 'YYYY-MM-DD HH:mm:ss');
        return {
            title: 'ส่งมอบสินค้า',
            start: date_moment_obj,
            end: date_moment_obj,
            className: 'event-solid'
        };
    }

    public getCosmetics(): Promise<CosmeticModel[]> {
        let promise: Promise<CosmeticModel[]>;
        promise = new Promise<CosmeticModel[]>((resolve, reject) => {
            this.cosmeticService.getCosmetics()
                .then(cosmetics => {
                    this.cosmetics = ([]).concat(cosmetics);
                }, error => {
                    this.cosmetics = [];
                    resolve([]);
                });
        });
        return promise;
    }

    public onTypeaheadSelect(e: any, fvd: FVD, modelable_type: string): void {
        if (modelable_type) {
            fvd.modelable_type = modelable_type;
        }
    }

    public getHardenings(): Promise<HardeningModel[]> {
        let promise: Promise<HardeningModel[]>;
        promise = new Promise<HardeningModel[]>((resolve, reject) => {
            this.hardeningService.getHardenings()
                .then(hardenings => {
                    this.hardenings = ([]).concat(hardenings);
                }, error => {
                    this.hardenings = [];
                    resolve([]);
                });
        });
        return promise;
    }

    public getProcesses(): Promise<ProcessModel[]> {
        let promise: Promise<ProcessModel[]>;
        promise = new Promise<ProcessModel[]>((resolve, reject) => {
            this.processService.getProcesses()
                .then(processes => {
                    this.processes = ([]).concat(processes);
                }, error => {
                    this.processes = [];
                    resolve([]);
                });
        });
        return promise;
    }

    public addRemark(supplier: VendorModel): void {
        this.current_supplier = supplier;
        this.supplierRemarkModal.open(supplier.remark);
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.current_supplier && this.current_supplier.id) {
            this.current_supplier.remark = e.remark_message;
            this.save();
        } else {
            //
        }
    }

    public onApprovedRemarkModalSubmit(e: any): void {
        this.fvdService.approve1(this.fvd, e.remark_message, this.task_id)
            .then(() => {
                super.issueTask()
                    .then(() => {
                        this.leave(true);
                    }, error => {
                        //
                    });
            });
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.data) {
                    this.task.clone(response.data);
                    this.getFVD(this.task.modelable_id)
                        .then(() => {
                            if (this.fvd && (!this.fvd.suppliers || !this.fvd.suppliers.length)) {
                                const supplier1: VendorModel = new VendorModel();
                                supplier1.clone(this.fvd.supplier1);
                                supplier1.price = this.fvd.price1;
                                supplier1.days = this.fvd.days1;
                                supplier1.remark = this.fvd.remark1;
                                supplier1.checked = (this.fvd.supplier1_checked) ? true : false;
                                supplier1.document_id = this.fvd.supplier_document1_id;
                                supplier1.document_name = (this.fvd.supplier_document1) ? this.fvd.supplier_document1.name : '';
                                supplier1.document = (this.fvd.supplier_document1) ? this.fvd.supplier_document1 : null;
                                // supplier1.checked = (this.fvd.supplier_id === this.fvd.supplier1_id) ? true : false;

                                const supplier2: VendorModel = new VendorModel();
                                supplier2.clone(this.fvd.supplier2);
                                supplier2.price = this.fvd.price2;
                                supplier2.days = this.fvd.days2;
                                supplier2.remark = this.fvd.remark2;
                                supplier2.checked = (this.fvd.supplier2_checked) ? true : false;
                                supplier2.document_id = this.fvd.supplier_document2_id;
                                supplier2.document_name = (this.fvd.supplier_document2) ? this.fvd.supplier_document2.name : '';
                                supplier2.document = (this.fvd.supplier_document2) ? this.fvd.supplier_document2 : null;
                                // supplier2.checked = (this.fvd.supplier_id === this.fvd.supplier2_id) ? true : false;

                                const supplier3: VendorModel = new VendorModel();
                                supplier3.clone(this.fvd.supplier3);
                                supplier3.price = this.fvd.price3;
                                supplier3.days = this.fvd.days3;
                                supplier3.remark = this.fvd.remark3;
                                supplier3.checked = (this.fvd.supplier3_checked) ? true : false;
                                supplier3.document_id = this.fvd.supplier_document3_id;
                                supplier3.document_name = (this.fvd.supplier_document3) ? this.fvd.supplier_document3.name : '';
                                supplier3.document = (this.fvd.supplier_document3) ? this.fvd.supplier_document3 : null;
                                // supplier3.checked = (this.fvd.supplier_id === this.fvd.supplier3_id) ? true : false;

                                this.fvd.suppliers = [supplier1, supplier2, supplier3];
                            } else {
                                //
                            }

                            if (!this.fvd.approved1_user) {
                                this.fvd.approved1_user = this.authService.user;
                                this.fvd.approved1_user_id = this.fvd.approved1_user.id;
                            }

                            if (!this.fvd.documents) {
                                this.fvd.documents = [];
                            }

                            // this.job_id = this.task.modelable_id;
                            this.getJob(this.job_id)
                                .then(() => {
                                    if (this.job && this.job.product) {
                                        if (this.job && !this.job.confirmed_delivery_date) {
                                            if (moment.isMoment(this.job.delivery_date)) {
                                                this.job.confirmed_delivery_date = this.job.delivery_date.add(3, 'days')
                                                    .format('YYYY-MM-DD HH:mm:ss');
                                            } else if (this.job.delivery_date) {
                                                const delivery_date: any = this.job.delivery_date;
                                                this.job.confirmed_delivery_date = moment((delivery_date.date) ? delivery_date.date : delivery_date
                                                    , 'YYYY-MM-DD HH:mm:ss')
                                                    .add(3, 'days')
                                                    .format('YYYY-MM-DD HH:mm:ss');
                                            }
                                        }
                                        this.product_id = this.job.product_id;
                                        this.getProductDetail()
                                            .then(() => {
                                                for (let i = 0; i < this.product.materials.length; i++) {
                                                    if (this.product.materials[i] && this.product.materials[i].checked) {
                                                        this.materials.push(this.product.materials[i]);
                                                    }
                                                }
                                                this.refreshCalendar();
                                                this.doReady();
                                            });
                                    } else {
                                        //
                                    }
                                });
                        }, error => {
                            //
                        });
                }
            }, error => {
                //
            });
    }

    public onCustomerSelect(e: any): void {
        this.fvd.customer_id = (e && e.id) ? e.id : null;
    }

    public onPurchaseUserChange(e: any): void {
        this.fvd.approved1_user_id = (e && e.id) ? e.id : null;
    }

    public onProcessChange(e: any): void {
        this.fvd.process_id = (e && e.id) ? e.id : null;
    }

    public onHardeningChange(e: any): void {
        this.fvd.modelable_type = (e && e.id) ? 'App\\Hardening' : null;
        this.fvd.modelable_id = (e && e.id) ? e.id : null;
    }

    public onCosmeticChange(e: any): void {
        this.fvd.modelable_type = (e && e.id) ? 'App\\Cosmetic' : null;
        this.fvd.modelable_id = (e && e.id) ? e.id : null;
    }

    public onVendorChange(supplier: SupplierModel, i: number, e: any): void {
        if (e && e.id) {
            //
        } else {
            //
        }
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job = new JobModel();
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.fvd.product_id) {
                this.product = new ProductModel();
                console.log('=== JOB ID : ' + this.job_id);
                // this.job_id = this.job.id;
                this.modelApi.sync(this.product, this.fvd.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public showMaterialDetail(product_material: ProductMaterial): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : true,
            editable        : false,
            type            : 'frw',
            item_id         : product_material.item.id,
            item_name       : product_material.item.search_value,
            material_model  : product_material.item,
            current_item    : product_material.item
        }, {
            //
        }).then((data: any): void => {
            //
        });
    }

    public rejectFVD(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, { class: 'modal-md', backdrop: true, ignoreBackdropClick: true })
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.taskService.reject(this.task)
                        .then(() => {
                            if (this.task.relate_task_id) {
                                //
                                this.taskService.setStatus(this.task.relate_task_id, 11)
                                    .then(() => {
                                        this.swal.success('แจ้งผลการตรวจสอบการจัดซื้อจัดจ้างสำเร็จ');
                                        this.leave(true);
                                    });
                            } else {
                                this.swal.success('แจ้งผลการตรวจสอบการจัดซื้อจัดจ้างสำเร็จ');
                                this.leave(true);
                            }
                        }, error => {
                            //
                        });
                    return;
                }
                //
            });
    }

    public approveFVD(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.fvdService.approve2(this.fvd, content.remark_message)
                        .then(() => {
                            this.taskService.approve(this.task)
                                .then(() => {
                                    this.swal.success('แจ้งผลการตรวจสอบการจัดซื้อจัดจ้างสำเร็จ');
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                        });
                } else {
                    //
                }
            });
    }

    public getRemarks(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('tasks/remarks', 'POST', {}, {
                id: this.task_id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    this.remarks = response.data;
                    resolve(this.remarks);
                }
            });
        });
        return promise;
    }

    private getFVD(fvd_id: string): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            this.fvdService.sync(fvd_id)
                .then(fvd => {
                    this.fvd = fvd;
                    this.job = this.fvd.job;
                    this.job_id = this.job.id;
                    resolve(this.fvd);
                }, error => {
                    reject(error);
                    // this.fvd = {};
                    // resolve({});
                });
        });
        return promise;
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('fvds/document', 'PUT', {}, {
            document    : data,
            id          : this.fvd.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.fvd.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public save(): Promise<FVD> {
        let promise: Promise<FVD>;
        promise = new Promise<FVD>((resolve, reject) => {
            for (let i = 0; i < this.fvd.suppliers.length; i++) {
                const supplier = this.fvd.suppliers[i];
                if (supplier && supplier.id) {
                    this.fvd['supplier' + (i + 1) + '_id'] = supplier.id;
                    this.fvd['price' + (i + 1)] = supplier.price;
                    this.fvd['days' + (i + 1)] = supplier.days;
                    this.fvd['remark' + (i + 1)] = supplier.remark;
                    this.fvd['supplier' + (i + 1) + '_checked'] = (supplier.checked) ? true : false;
                    this.fvd['supplier_document' + (i + 1) + '_id'] = supplier.document_id;
                } else {
                    this.fvd['supplier' + (i + 1) + '_id'] = null;
                    this.fvd['price' + (i + 1)] = null;
                    this.fvd['days' + (i + 1)] = null;
                    this.fvd['remark' + (i + 1)] = null;
                    this.fvd['supplier' + (i + 1) + '_checked'] = false;
                    this.fvd['supplier_document' + (i + 1) + '_id'] = null;
                }
            }
            this.fvdService.createOrUpdateFVD(this.fvd)
                .then(fvd => {
                    this.fvd.id = fvd.id;
                    resolve(this.fvd);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private get_checked_suppliers(): any[] {
        let ids: any[];
        ids = [];
        for (let i = 0 ; i < this.fvd.suppliers.length; i++) {
            const supplier: any = this.fvd.suppliers[i];
            if (supplier && supplier.id && supplier.checked) {
                ids.push({
                    id: supplier.id,
                    type: supplier.model_name,
                    document_id: supplier.document_id,
                    quotation_no: supplier.document_name
                });
            }
        }
        return ids;
    }

    public onVendorDelete(e: any, vendor: VendorModel, i: number): void {
        if (i > -1) {
            this.fvd.suppliers[i] = new VendorModel();
            this.fvd['supplier' + (i + 1) + '_id'] = null;
        }
    }

    public archiveTask(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public createPurchaseOrder(): void {
        const items: any[] = this.get_checked_items();
        const modelables: any[] = this.get_checked_suppliers();
        let names: string[];
        names = [];
        for (let i = 0 ; i < this.fvd.suppliers.length; i++) {
            const supplier: any = this.fvd.suppliers[i];
            if (supplier && supplier.id && supplier.checked) {
                names.push(supplier.name);
            }
        }
        if (names.length) {
            this.swal.confirm('ยืนยันการออกใบจัดซื้อจัดจ้างผู้รับจ้าง "' + names.join(', ') + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.storeService.purchase(
                            null,
                            this.job_id,
                            this.job.product_id,
                            this.fvd.id,
                            items,
                            this.job.customer_id,
                            modelables,
                            this.get_pricing_inquiry_remark(),
                            true,
                            this.fvd.required_at,
                            this.task.id
                        ).then(response => {
                            this.taskService.complete(this.task)
                                .then(() => {
                                    this.swal.success('ออกใบจัดซื้อจัดจ้างสำเร็จ');
                                    this.leave(true);
                                });
                        }, error => {
                            this.swal.danger(error);
                        });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('ไม่ได้ระบุ/เลือก ผู้รับจ้าง');
        }
    }

    private get_mat_description(): string {
        let remark: string;
        if (this.fvd.process && this.fvd.process.slug) {
            remark = this.fvd.fvd_no + ' ' + this.fvd.process.name;
            if (this.fvd.process.slug === 'hardening' && this.fvd.modelable && this.fvd.modelable.name) {
                remark += ' (' + this.fvd.modelable.name + ') ';
                remark += '\n';
            } else if (this.fvd.process.slug === 'cosmetic' && this.fvd.modelable && this.fvd.modelable.name) {
                remark += ' (' + this.fvd.modelable.name + ') ';
                remark += '\n';
            }
        } else {
            remark = '';
        }
        remark += '\n';
        // remark += (this.fvd.material_support) ? 'สนับสนุนวัตถุดิบ' : 'ไม่สนับสนุนวัตถุดิบ';
        remark += (this.fvd.details) ? '\n' + this.fvd.details : '';
        remark += (this.fvd.description) ? '\n' + this.fvd.description : '';
        return remark;
    }

    private get_pricing_inquiry_remark(): string {
        let remark: string;
        remark = (this.fvd.remark) ? this.fvd.remark : '';
        if (this.fvd.test_report || this.fvd.data_inspection) {
            remark += '\nเอกสารที่ต้องการหลังการจัดจ้าง:\n';
            if (this.fvd.test_report) {
                remark += ' TEST REPORT ';
            }
            if (this.fvd.data_inspection) {
                if (this.fvd.test_report) {
                    remark += ',\n';
                }
                remark += ' DATA INSPECTION ';
            }
            remark += '\n';
        }
        return remark;
    }

    public issueFVDTask(): void {
        let found: boolean;
        found = false;
        for (let i = 0; i < this.fvd.suppliers.length; i++) {
            const supplier: VendorModel = this.fvd.suppliers[i];
            if (supplier && supplier.id) {
                found = true;
                if (!supplier.price) {
                    this.swal.danger('ข้อมูลราคาจ้างที่เสนอของผู้รับจ้าง "' + supplier.name + '" ไม่ครบถ้วน');
                    return;
                } else if (!supplier.days) {
                    this.swal.danger('ข้อมูลระยะเวลาในการผลิตของผู้รับจ้าง "' + supplier.name + '" ไม่ครบถ้วน');
                    return;
                }
            }
        }
        if (!found) {
            this.swal.danger('ไม่ได้ระบุผู้รับจ้างอย่างน้อย 1 ราย');
        } else {
            this.approvedRemarkModal.open();
        }
    }

    public createOrViewPricingInquiry(): void {
        if (this.fvd.pricing_inquiries && this.fvd.pricing_inquiries.length && this.fvd.pricing_inquiries.length >= 3) {
            this.viewPricingInquiryByFVD(this.fvd);
        } else {
            let found: boolean;
            found = false;
            for (let i = 0; i < this.fvd.suppliers.length; i++) {
                const supplier: VendorModel = this.fvd.suppliers[i];
                if (supplier && supplier.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.swal.danger('ไม่ได้ระบุผู้รับจ้างอย่างน้อย 1 ราย');
                return;
            }
            if (!this.fvd.required_at) {
                this.swal.danger('ไม่ได้ระบุวันส่งมอบ');
            } else if (!this.fvd.condition) {
                this.swal.danger('ไม่ได้ระบุเงื่อนไขการชำระเงิน');
            } else if (!this.fvd.description) {
                this.swal.danger('ไม่ได้ระบุเหตุผลในการจ้าง');
            } else {
                this.swal.confirm('ยืนยันการออกใบสอบราคาใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            const items: any[] = this.get_checked_items();
                            this.storeService.pricingInquiry(
                                this.task,
                                this.job_id,
                                this.job.product_id,
                                this.fvd.id,
                                items,
                                this.job.customer_id,
                                this.fvd.suppliers,
                                this.fvd.condition,
                                this.fvd.required_at,
                                this.get_pricing_inquiry_remark()
                            ).then((pricing_inquiries: PricingInquiryModel[]): void => {
                                this.fvd.pricing_inquiries = pricing_inquiries;
                                this.swal.success('ออกใบสอบราคาสำเร็จ')
                                    .then(() => {
                                        this.viewPricingInquiryByFVD(this.fvd);
                                    });
                            }, error => {
                                //
                            });
                        }
                    });
            }
        }
    }

    private get_checked_items(): any[] {
        let items: any[];
        items = [];
        const item_description: string = this.get_mat_description();
        items.push({
            modelable_type: 'App\\Process',
            modelable_id: this.fvd.process_id,
            // item_id: material.item.id,
            amount: this.fvd.amount,
            // dateTime: material.dateTime,
            // prepared_status: material.prepared_status,
            // price: material.price,
            // vendor_id: material.vendor_id,
            remark: item_description,
            is_modelable: false,
            item_description: item_description
        });
        /*for (let i = 0; i < this.materials.length; i++) {
            const material: any = this.materials[i];
            if (material && material.id && material.checked && material.job_id === this.job_id) {
                const item_description: string = this.get_mat_description();
                items.push({
                    // id: material.id,
                    // amount: material.amount,
                    modelable_type: material.model_name,
                    modelable_id: material.id,
                    item_id: material.item.id,
                    amount: material.amount,
                    dateTime: material.dateTime,
                    prepared_status: material.prepared_status,
                    price: material.price,
                    vendor_id: material.vendor_id,
                    remark: material.mat_name + '\n' + item_description,
                    is_modelable: false,
                    item_description: material.mat_name + '\n' + item_description
                });
            }
            if (material.children) {
                for (let j = 0; j < material.children.length; j++) {
                    const mat_child: any = material.children[j];
                    if (mat_child && mat_child.id && mat_child.checked && material.job_id === this.job_id) {
                        const item_child_description: string = this.get_mat_description();
                        items.push({
                            // id: mat_child.id,
                            // amount: material.amount,
                            modelable_type: mat_child.model_name,
                            modelable_id: mat_child.id,
                            item_id: mat_child.item.id,
                            amount: mat_child.amount,
                            dateTime: mat_child.dateTime,
                            prepared_status: mat_child.prepared_status,
                            price: mat_child.price,
                            vendor_id: mat_child.vendor_id,
                            remark: mat_child.mat_name + '\n' + item_child_description,
                            is_modelable: false,
                            item_description: mat_child.mat_name + '\n' + item_child_description
                        });
                    }
                }
            }
        }*/
        return items;
    }

    public onQuotationStartUploader(data: any): void {
        //
    }

    public onQuotationUploadedSuccess(event?: any): void {
        this.api.request('documents', 'PUT', {}, event)
            .subscribe((response: any): void => {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.modelable_id = this.fvd.id;
                new_document.modelable_type = 'App\\FVD';
                new_document.clone(response.data);
                this.modal.show(DocumentDetailComponent, {
                    document    : new_document
                }, { backdrop: true, ignoreBackdropClick: true })
                    .then((content: any): void => {
                        if (content && content.is_submit === true) {
                            if (this.current_supplier_index > -1) {
                                this.fvd['supplier_document' + (this.current_supplier_index + 1) + '_id'] = new_document.id;
                                this.current_supplier.document_id = new_document.id;
                                this.current_supplier.document_name = new_document.name;
                            }
                            this.save();
                        }
                        this.current_supplier = null;
                        this.current_supplier_index = null;
                    });
            });
    }

    public onQuotationUploadedError(event?: any): void {
        this.current_supplier = null;
        this.current_supplier_index = null;
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public attachQuotation(supplier: any, index: number): void {
        this.uploader.trigger();
        this.current_supplier = supplier;
        this.current_supplier_index = index;
    }

    public viewQuotationDetail(supplier: any): void {
        this.modal.show(DocumentDetailComponent, {
            document    : supplier.document
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    supplier.document_name = supplier.document.name;
                    this.save();
                }
            });
    }

    public removeQuotation(supplier: any, i: number): void {
        if (supplier && supplier.document_id) {
            this.swal.confirm('ยืนยันการยกเลิกใบเสนอราคา "' + supplier.document_name + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.modelApi.delete(supplier.document, 'documents/delete')
                            .subscribe((response: any): void => {
                                supplier.document = null;
                                supplier.document_id = null;
                                supplier.document_name = '';
                                this.fvd['supplier_document' + (i + 1) + '_id'] = null;
                                this.save();
                            }, error => {
                                //
                            });
                    }
                });
        } else {
            //
        }
    }

    public onSaveApiCallingBack(e?: any): void {
        this.save();
    }


}
