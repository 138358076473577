import {AfterViewInit, Component, Input, NgZone} from '@angular/core';

@Component({
    selector: 'typeahead-input-component',
    templateUrl: 'typeaheadInput.component.html',
    styleUrls: ['typeaheadInput.component.scss']
})
export class TypeaheadInputComponent implements AfterViewInit {

    public model: ModelInterface;
    public found: boolean;

    @Input('field') field: string;
    @Input('model')
    set setModel(val: ModelInterface) {
        this.model = val;
        this.found = (this.model && this.field && this.model[this.field]) ? true : false;
    }

    constructor(private ngZone: NgZone) {
        this.found = false;
    }

    ngAfterViewInit(): void {
        //
    }

}
