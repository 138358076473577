import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {HardeningModel} from '../models/hardening.model';

@Injectable()
export class HardeningService {

    private hardenings: HardeningModel[];

    constructor(private api: Api) {
        this.reset_hardening_list();
    }

    public set(list: HardeningModel[]): void {
        this.reset_hardening_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const hardening: HardeningModel = new HardeningModel();
                    hardening.clone(dat);
                    this.hardenings.push(hardening);
                }
            }
        }
    }

    public getHardenings(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('hardenings', 'GET', {}, {})
                .subscribe((response: any): void => {
                    this.reset_hardening_list();
                    if (response && response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            let dat: any;
                            dat = response.data[i];
                            if (dat) {
                                this.hardenings.push(dat);
                            }
                        }
                        resolve(this.hardenings);
                    } else {
                        reject([]);
                    }
                }, error => {
                    reject([]);
                });
        });
        return promise;
    }

    private reset_hardening_list(): void {
        if (this.hardenings) {
            this.hardenings.splice(0, this.hardenings.length);
        } else {
            this.hardenings = [];
        }
    }

}
