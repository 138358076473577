import {Injectable} from '@angular/core';
import {Api} from '../api/api';
import {Model} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class ModelApi {

    constructor(protected api: Api) {
        //
    }

    public buildPostOrPutData(model: Model, put_or_post_data: string[]): any {
        let data: any;
        data = {
            id: model.id
        };

        for (let i = 0; i < put_or_post_data.length; i++) {
            const key: string = this.parseKeys(model, put_or_post_data[i]);
            const value: any = (<any>model)[key];
            (<any>data)[key] = value;
            // this.parseKeysToValue(model, data, put_or_post_data[i]);
        }

        return data;
    }

    private parseKeysToValue(model: any, data: any, keys: string): any {
        let key_array: string[];
        key_array = keys.split('.');
        if (key_array && key_array.length === 1) {
            if (Array.isArray(model)) {
                /*for (let i = 0; i < model.length; i++) {
                    if (!data[i][key_array[0]]) {
                        data[i][key_array[0]] = model[i][key_array[0]];
                    }
                }*/
            } else {
                data[key_array[0]] = (<any>model)[key_array[0]];
            }
            return key_array[0];
        } else if (key_array) {
            if (!data[key_array[0]]) {
                if (Array.isArray(model[key_array[0]])) {
                    // TODO: array does not support.
                    // data[key_array[0]] = Array(model[key_array[0]].length).fill({});
                } else {
                    data[key_array[0]] = {};
                }
            }
            return this.parseKeysToValue(model[key_array[0]], data[key_array[0]], key_array[1]);
        }
        data[keys] = (<any>model)[keys];
        return keys;
    }

    private parseKeys(model: any, keys: string): any {
        let key_array: string[];
        key_array = keys.split('.');
        if (key_array && key_array.length === 1) {
            return key_array[0];
        } else if (key_array) {
            // TODO: ?
            return key_array[0];
        }
        return keys;
    }

    public sync(model: Model, id?: any, path?: string, params?: any): Observable<Object> {
        let observable: Observable<Object>;
        observable = new Observable((observer: any): void => {
            let model_name: string = model.table_name.toLowerCase();
            model_name = model_name.replace('_', '/');
            this.api.request(model_name + '/' + ((id) ? id : model.id), 'GET', params)
                .subscribe((response: any): void => {
                    if (response.data) {
                        if (response.data.id) {
                            this.clone(model, response.data);
                        }
                    }
                    observer.next(response);
                }, (error: any): void => {
                    this.error(model, error);
                    observer.error(error);
                });
        });

        return observable;
    }

    public createOrUpdate(model: Model, put_or_post_data: string[], url?: string, parent_param?: string, request_data?: any, clone?: boolean, dobule?: boolean): Observable<Object> {
        if (model.isNew === true) {
            return this.create(model, put_or_post_data, url, clone, dobule);
        } else {
            return this.update(model, put_or_post_data, url, parent_param, request_data, clone, dobule);
        }
    }

    public create(model: Model | Model[], put_data: string[], url?: string, clone?: boolean, dobule?: any): Observable<Object> {
        let model_name: string;
        let data: any;
        if (model instanceof Array) {
            model_name = model[0].table_name.toLowerCase();
            data = [];
            for (let i = 0; i < model.length; i++) {
                // this.process(model[i], 'Create Model ' + model_name);
                data.push(this.buildPostOrPutData(model[i], put_data));
            }
        } else {
            model_name = model.table_name.toLowerCase();
            // this.process(model, 'Create Model ' + model_name);
            data = this.buildPostOrPutData(model, put_data);
        }

        let observable: Observable<Object>;
        observable  = new Observable((observer: any): void => {
            model_name = model_name.replace('_', '/');
            let servicePath: string;
            servicePath = (url) ? url : model_name;
            this.api.request(servicePath, 'PUT', {}, data, null, null, null, null, dobule)
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        if (model instanceof Array) {
                            for (let i = 0; i < response.data.length; i++) {
                                const dat: any = response.data[i];
                                if (dat) {
                                    if (clone !== false) {
                                        this.clone(model[i], dat);
                                    }
                                }
                            }
                        } else {
                            if (clone !== false) {
                                this.clone(model, response.data);
                            }
                        }
                    }

                    observer.next(response);
                }, (error: any): void => {
                    if (model instanceof Array) {
                        for (let i = 0; i < model.length; i++) {
                            this.error(model[i], error);
                        }
                    } else {
                        this.error(model, error);
                    }

                    observer.error(error);
                });
        });

        return observable;
    }

    public update(model: Model | Model[], post_data: string[], url?: string, parent_param?: string, request_data?: any, clone?: boolean, dobule?: boolean, rev?: number): Observable<Object> {
        let model_name: string;
        let data: any;
        if (model instanceof Array) {
            model_name = model[0].table_name.toLowerCase();
            if (parent_param) {
                if (request_data) {
                    data = request_data;
                }
                data[parent_param] = [];
                for (let i = 0; i < model.length; i++) {
                    // this.process(model[i], 'Create Model ' + model_name);
                    data[parent_param].push(this.buildPostOrPutData(model[i], post_data));
                }
            } else {
                data = [];
                for (let i = 0; i < model.length; i++) {
                    // this.process(model[i], 'Create Model ' + model_name);
                    data.push(this.buildPostOrPutData(model[i], post_data));
                }
            }
        } else {
            model_name = model.table_name.toLowerCase();
            // this.process(model, 'Create Model ' + model_name);
            if (parent_param) {
                if (request_data) {
                    data = request_data;
                }
                data[parent_param] = this.buildPostOrPutData(model, post_data);
            } else {
                data = this.buildPostOrPutData(model, post_data);
            }
        }

        let observable: Observable<Object>;
        observable = new Observable((observer: any): void => {
            model_name = model_name.replace('_', '/');
            let servicePath: string;
            servicePath = (url) ? url : model_name;
            this.api.request(servicePath, 'POST', {
                rev: (rev) ? rev : ''
            }, data, null, null, null, null, dobule)
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        if (model instanceof Array) {
                            for (let i = 0; i < response.data.length; i++) {
                                const dat: any = response.data[i];
                                if (dat && dat.id) {
                                    if (clone !== false) {
                                        this.clone(model[i], dat);
                                    }
                                }
                            }
                        } else {
                            if (clone !== false) {
                                this.clone(model, response.data);
                            }
                            // this.reset(model);
                        }
                    }
                    observer.next(response);
                }, (error: any): void => {
                    if (model instanceof Array) {
                        for (let i = 0; i < model.length; i++) {
                            this.error(model[i], error);
                        }
                    } else {
                        this.error(model, error);
                    }
                    observer.error(error);
                }, () => {
                    observer.complete();
                });
        });

        return observable;
    }

    public delete(model: Model | any, url?: string): Observable<Object> {
        let model_name: string;
        if (model && model.table_name) {
            model_name = model.table_name.toLowerCase();
            model_name = model_name.replace('_', '/');
        } else {
            model_name = '';
        }
        // this.process(model, 'Delete Model ' + model_name);
        let observable: Observable<Object>;
        observable = new Observable((observer: any): void => {
            let servicePath: string;
            servicePath = (url) ? url : model_name + '/delete';
            this.api.request(servicePath, 'POST', {}, { id: model.id, task_id: model.task_id })
                .subscribe((response: any): void => {
                    if (response && response.data && response.data.id) {
                        this.clone(model, response.data);
                    }
                    observer.next(response);
                }, (error: any): void => {
                    this.error(model, error);
                    observer.error(error);
                });
        });

        return observable;
    }

    public restore(model: Model, url?: string): Observable<Object> {
        let model_name: string = model.table_name.toLowerCase();
        model_name = model_name.replace('_', '/');
        // this.process(model, 'Restore Model ' + model_name);
        let observable: Observable<Object>;
        observable = new Observable((observer: any): void => {
            let servicePath: string;
            servicePath = (url) ? url : model_name + '/restore';
            this.api.request(servicePath, 'POST', {}, { id: model.id })
                .subscribe((response: any): void => {
                    if (response.data) {
                        if (response.data.id) {
                            this.clone(model, response.data);
                        }
                    }
                    observer.next(response);
                }, (error: any): void => {
                    this.error(model, error);
                    observer.error(error);
                });
        });

        return observable;
    }

    public error(model: Model, error: any): void {
        model.error(error);
    }

    // public process(model: Model, message: string = ''): void {
    //     model.process(message);
    // }

    public reset(model: Model): void {
        model.reset();
    }

    public clone(model: Model, data: any, without: string[] = []): Model {
        model.reset();
        model.clone(data, without);
        return model;
    }

}
