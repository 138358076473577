import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskModel} from '../../../../../models/task.model';
import {TaskService} from '../../../../../services/task.service';
import {PusherService} from '../../../../../services/pusher.service';
import {DivApiDirective} from '../../../../../now/divApi';
import {UserService} from '../../../../../services/user.service';
import {SwalService} from '../../../../../services/swal.service';
import {ModelApi} from '../../../../../now/modelApi/modelApi';
import {ModalService} from '../../../../../services/modal.service';
import {Api} from '../../../../../now/api/api';
import {AppService} from '../../../../../app/app.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ViewRemarkComponent} from '../../../view/viewRemark/viewRemark.component';
import {LoaderService} from '../../../../../components/loader/loader.service';
import {Viewer} from '../../../../../services/viewer';

@Component({
    selector: 'app-complete-task-fmt-list-component',
    templateUrl: 'taskCompleteFMTList.component.html',
    styleUrls: ['taskCompleteFMTList.component.scss']
})
export class TaskCompleteFMTListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;

    public taskList: TaskCompleteFMTListComponent;
    public keyword: string;
    public all_tasks: TaskModel[];
    public room_slug: string;
    public current_job_document_path: string;
    public current_task: TaskModel;
    public timeout: any;
    public customers: any;
    public assemblies: any;
    public pdf_viewer_path: string;
    public pdf_viewer_title: string;
    public role: string;
    public channel_name: string;
    public pageWidth: string;
    public pageHeight: string;
    public config: PerfectScrollbarConfigInterface;

    constructor(
        public viewer: Viewer,
        private router: Router,
        private route: ActivatedRoute,
        private modal: ModalService,
        private taskService: TaskService,
        private userService: UserService,
        private modelApi: ModelApi,
        private swal: SwalService,
        private ngZone: NgZone,
        private pusherService: PusherService,
        private api: Api,
        private appService: AppService,
        private loader: LoaderService
    ) {
        //
        this.assemblies = [];
        this.initRoom();
        this.pageWidth = 'calc(100vw - 16px)';
        this.config = {
            useBothWheelAxes: false,
            suppressScrollX: false,
            suppressScrollY: true
        };
        this.taskList = this;
    }

    private initRoom(): void {
        this.all_tasks = [];
        this.route.params
            .subscribe(params => {
                this.room_slug = 'fmt-complete';
                const channel_name: string = 'erpst.task.' + this.room_slug;
                this.channel_name = channel_name;
                // this.pusherService.subscribe(channel_name);
                // this.pusherService.bind(channel_name, 'tasks.update', data => {
                //     this.refresh();
                // });
                setTimeout(() => {
                    this.divApi.refresh();
                }, 300);
            });
    }

    ngAfterViewInit(): void {
        this.appService.isTaskListPage = true;
    }

    ngOnInit(): void {
        //
    }

    ngOnDestroy(): void {
        // this.pusherService.unsubscribe(this.channel_name);
    }

    /*public createShippingDocuments(): void {
        let task_ids: string[];
        task_ids = [];
        let current_customer_id: number;
        if (this.all_tasks && this.all_tasks.length) {
            for (let i = 0; i < this.all_tasks.length; i++) {
                const task: any = this.all_tasks[i];
                if (task && task.id && task._checked) {
                    if (!current_customer_id) {
                        current_customer_id = task.customer_id;
                    } else if (current_customer_id !== task.customer_id) {
                        this.swal.danger('ไม่สามารถออกเอกสารหลากลูกค้าได้');
                        return;
                    }
                    task_ids.push(task.id);
                }
            }
        } else {
            //
        }
        if (task_ids && task_ids.length) {
            this.swal.confirm('ยืนยันการออกเอกสารตามงานที่ถูกเลือกทั้งหมดนี้ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.loader.show();
                        this.api.request('shipping/documents', 'PUT', {}, {
                            task_ids : task_ids
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.loader.hide();
                                this.swal.success('ออกเอกสารแผนก Shipping สำเร็จ');
                                this.refresh();
                            } else {
                                this.loader.hide();
                            }
                        }, error => {
                            this.loader.hide();
                        });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('กรุณาเลือกงานในการออกเอกสาร');
        }
    }*/

    public refresh(): void {
        this.timeout = setTimeout(() => {
            this.clear_timeout();
            this.divApi.refresh();
        }, 300);
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        if (response && response.data) {
            this.render(response.data);
        }
    }

    public onError(e: any): void {
        //
    }

    gotoTaskView(task: TaskModel | any): void {
        this.taskService.gotoTask(task);
    }

    private render(data: any): void {
        this.ngZone.run(() => {
            if (this.room_slug !== 'shipping' && this.room_slug !== 'assembly' && this.room_slug !== 'shipping-document' && this.room_slug !== 'finish') {
                this.clear_tasks();
                for (let i = 0; i < data.length; i++) {
                    let dat: any;
                    dat = data[i];
                    if (dat) {
                        let task: TaskModel;
                        task = new TaskModel();
                        task.clone(dat);
                        this.all_tasks.push(task);
                    }
                }
            } else if (this.room_slug === 'shipping') {
                this.clear_tasks();
                for (const key in data) {
                    if (key && data[key]) {
                        let children: any[];
                        children = [];
                        for (const job of data[key]) {
                            if (job && job.id) {
                                this.all_tasks.push(job.task);
                                children.push(job.task);
                            }
                        }
                        this.customers.push({
                            customer_name: key,
                            children: children
                        });
                    }
                }
            } else if (this.room_slug === 'shipping-document' || this.room_slug === 'finish') {
                this.clear_tasks();
                for (const dat of data) {
                    if (dat && dat.id) { // dat is task model
                        this.all_tasks.push(dat);
                        this.customers.push(dat);
                    }
                }
            } else if (this.room_slug === 'assembly') {
                this.clear_tasks();
                for (const dat of data) {
                    if (dat && dat.id) { // dat is job model
                        this.all_tasks.push(dat.task);
                        const index = this.assemblies.push(dat.task) - 1;
                        const assembly = this.assemblies[index];
                        assembly.children = (dat.children && dat.children.length) ? dat.children : [];
                    }
                }
                /*if (data[''] && data[''].length) {
                    for (let i = 0; i < data[''].length; i++) {
                        const dat = data[''][i];
                        this.assemblies.push(dat);
                    }
                } else {
                    // ?
                }
                for (const key in data) {
                    if (!key) {
                        //
                    } else if (key && data[key]) {
                        let _tasks: any[];
                        _tasks = [];
                        for (let i = 0; i < data[key].length; i++) {
                            const dat: any = data[key][i];
                            if (dat) {
                                let task: TaskModel;
                                task = new TaskModel();
                                task.clone(dat);
                                this.all_tasks.push(task);
                                _tasks.push(task);
                            }
                        }
                        const find_index = this.assemblies.findIndex(i => +i.job_id === +key);
                        if (find_index > -1) {
                            this.assemblies[find_index].tasks = _tasks;
                        }
                    }
                }*/
            }
        });
    }

    public onClickHireHandler(e: any, task: TaskModel): void {
        e.stopPropagation();
        this.taskService.hire(task)
            .then(() => {
                // Go Go!
            });
    }

    public viewRemark(task: TaskModel, event?: any): void {
        if (event) {
            event.stopPropagation();
        }
        this.current_task = task;
        if (task) {
            this.modal.show(ViewRemarkComponent, {
                task: task
            }, { class: 'modal-lg' }).then(() => {
                //
            });
        } else {
            //
        }
    }

    public viewFMT(task: TaskModel, document_no?: string, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        if (document_no && task.modelable_type === 'App\\FMT') {
            this.viewer.fmt(document_no, true)
                .then(path => {
                    //
                });
        }
    }

    private clear_tasks(): void {
        if (this.all_tasks) {
            this.all_tasks.splice(0, this.all_tasks.length);
        } else {
            this.all_tasks = [];
        }
        if (this.customers) {
            this.customers.splice(0, this.customers.length);
        } else {
            this.customers = [];
        }
        if (this.assemblies) {
            this.assemblies.splice(0, this.assemblies.length);
        } else {
            this.assemblies = [];
        }
    }

    public onKeywordInput(e: any): void {
        //
    }

}
