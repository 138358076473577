import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {CreateUserComponent} from './create/createUser.component';
import {TaskModel} from '../../../models/task.model';
import {ModalService} from '../../../services/modal.service';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';

@Component({
    selector: 'user-list-component',
    templateUrl: 'userList.component.html',
    styleUrls: ['userList.component.scss']
})
export class UserListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public timeout: any;
    public sub: any;
    public users: UserModel[];
    public keyword: string;

    constructor(private router: Router,
                private modal: ModalService) {
        //
        super();
        this.users = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_users();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let user: UserModel;
                    user = new UserModel();
                    user.clone(dat);
                    this.users.push(user);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoUserView(user: UserModel): void {
        this.router.navigateByUrl('/database/user/' + user.id + '/detail');
    }

    private clear_users(): void {
        if (this.users) {
            this.users.splice(0, this.users.length);
        } else {
            this.users = [];
        }
    }

    public createUser(): void {
        let new_user: UserModel;
        new_user = new UserModel();
        this.modal.show(CreateUserComponent, {
            user: new_user
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((content: any): void => {
                if (content && content.user && content.user.id) {
                    this.gotoUserView(content.user);
                } else {
                    //
                }
            });
    }

    public gotoTaskView(task: TaskModel): void {
        //
    }

}
