import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {NzTimePickerComponent} from 'ng-zorro-antd';

@Component({
    selector: 'timepicker-component',
    templateUrl: 'timepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TimepickerComponent),
        }
    ]
})
export class TimepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {

    @Input('left') left: boolean;
    @Input('icon') icon_text: string;
    @Input('display') display_text: string;
    @Input('placeholder') placeholder: string;
    @Input() disabledHours;
    @Input() nzMinuteStep;
    @Input() format;

    public min: string;
    @Input('min')
    set minTime(val: string) {
        this.min = (val) ? val : undefined;
    }

    @ViewChild(NzTimePickerComponent, { static: true }) timepicker: NzTimePickerComponent;
    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();
    // @Input('disableTime') disable_time: any;

    public disable_time: any;

    @Input()
    set disableTime(val: any) {
        // console.log(val);
        // this.disable_time = val;
        // this.setDisableTime(this.disable_time);
    }

    // public tmp_timepicker: any;

    public _disabled: boolean;
    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        if (this._disabled === true) {
            this.disable();
        } else {
            this.enable();
        }
    }

    private tmp_h_min: number;
    private tmp_m_min: number;
    public mask: any;

    public the_model: any;
    private picker: any;
    public propagateChange: any = (_: any): void => {};

    // public timeout: any;

    constructor(
        // private localeService: BsLocaleService,
        private el: ElementRef)
    {
        //
        // this.localeService.use('th');
        this.enable(true);
        /*this.mask = {
            guide: true,
            showMask: true,
            mask: [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/],
            prefix: '',
            suffix: ''
        };*/
    }

    ngOnInit() {
        this.nzMinuteStep = (this.nzMinuteStep) ? this.nzMinuteStep : 5;
        this.format = (this.format) ? this.format : 'HH:mm';
        this.disabledHours = (this.disabledHours) ? this.disabledHours : () => {
            return [];
        };
    }

    /*public disabledHours(): number[] {
        /!*let hours: number[];
        hours = [];
        console.log(this, this.min);
        if (this.min) {
            console.log(this.min);
            const moment_obj = moment(this.min, 'YYYY-MM-DD HH:mm:ss');
            const HH = moment_obj.format('HH');
            console.log(HH, +HH);
            for (let i = 0; i < +HH; i++) {
                hours.push(i);
            }
        }
        // console.log(hours);
        return hours;*!/
        return [];
    }*/

    public disabledMintues(): number[] {
        /*let minutes: number[];
        minutes = [];
        if (this.min) {
            const moment_obj = moment(this.min, 'YYYY-MM-DD HH:mm:ss');
            const mm = moment_obj.format('mm');
            for (let i = 0; i < +mm; i++) {
                minutes.push(i);
            }
        }
        // console.log(minutes);
        return minutes;*/
        return [];
    }

    @Input('h-min')
    set hMin(value: number) {
        this.tmp_h_min = value;
        if (this.picker) {
            // const nativeElement_timepicker: any = this.timepicker.nativeElement;
            // $(nativeElement_timepicker).pickatime('picker').set('min', [this.tmp_h_min, this.tmp_m_min]);
        }
    }

    @Input('m-min')
    set mMin(value: number) {
        this.tmp_m_min = value;
        if (this.picker) {
            // const nativeElement_timepicker: any = this.timepicker.nativeElement;
            // $(nativeElement_timepicker).pickatime('picker').set('min', [this.tmp_h_min, this.tmp_m_min]);
        }
    }

    @Input('date')
    set date(value: string) {
        /*if (value) {
            this.tmp_date = moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD ');
        } else {
            this.tmp_date = '';
        }*/
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.placeholder = (this.placeholder || this.placeholder === '') ? this.placeholder : 'เลือกวัน';
            /*const nativeElement_timepicker: any = this.timepicker.nativeElement;
            const timepickerComponent: TimepickerComponent = this;
            const h_min: number = (this.tmp_h_min) ? this.tmp_h_min : 8;
            const m_min: number = (this.tmp_m_min) ? this.tmp_m_min : 0;*/
            /*this.disable_time = [
              { from: [12, 5], to: [12, 55] },
              { from: [17, 5], to: [17, 25] },
              { from: [0, 5], to: [0, 55] },
              { from: [5, 5], to: [5, 25] }
            ];*/
            /*this.picker = $(nativeElement_timepicker).pickatime({
                // min: [h_min, m_min],
                // max: [20, 0],
                // disable: this.disable_time,
                editable: false,
                format: 'HH:i',
                formatSubmit: 'HH:i',
                interval: 5,
                onSet: function(context) {
                    if (context && (context.select || context.select === 0)) {
                        const value: string = this.get('select', 'HH:i');
                        timepickerComponent.onValueChange(value);
                    } else if (context && context.clear === null) {
                        timepickerComponent.propagateChange(null);
                        timepickerComponent.onChange.emit(null);
                    }
                },
                clear: 'ลบ',
                close: 'ปิด'
            });*/
            /*setTimeout(() => {
                if (this._disabled) {
                    this.disable(true);
                } else {
                    this.enable(true);
                }
            }, 500);*/
        }, 0);
    }

    public onTimeChange(e?: any): void {
        if (e && moment.isDate(e)) {
            const moment_object = moment(e).clone();
            const time_string = moment_object.format('YYYY-MM-DD HH:mm:ss');
            this.propagateChange(time_string);
        } else {
            //
        }
    }

    public trigger(e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.timepicker.open();
    }

    public setDisableTime(time?: any): void {
        if (time) {
            //
        } else {
            time = this.disable_time;
        }
        /*if (time && this.timepicker.nativeElement && $(this.timepicker.nativeElement) && $(this.timepicker.nativeElement).pickatime('picker')) {
            const time_array: string[] = time.split(':');
            const h: number = parseInt(time_array[0]);
            const m: number = parseInt(time_array[1]);
            const disable_time: any = [{ from: [0, 0], to: [h, m] }];
            $(this.timepicker.nativeElement).pickatime('picker').set('disable', disable_time);
        }*/
    }

    public disable(force?: boolean): void {
        /*if ((force || (this._disabled === false || this._disabled === null)) && this.timepicker && $(this.timepicker.nativeElement).pickatime('picker')) {
            $(this.timepicker.nativeElement).pickatime('picker').set('disable', true);
            this._disabled = true;
        }*/
    }

    public enable(force?: boolean): void {
        /*if ((force || this._disabled === true) && this.timepicker && $(this.timepicker.nativeElement).pickatime('picker')) {
            $(this.timepicker.nativeElement).pickatime('picker').set('disable', false);
            this._disabled = false;
        }*/
    }

    public get nativeElement(): any {
        return this.el.nativeElement;
    }

    public onValueChange(e: any): void {
        /*let time: string;
        if (this.tmp_date) {
            time = this.tmp_date + '' + e;
        } else {
            time = e;
        }
        this.writeValue(time);
        this.propagateChange(time);
        this.onChange.emit(time);*/
    }

    writeValue(obj: any): void {
        if (moment.isDate(obj)) {
            this.the_model = moment(obj).clone().toDate();
        } else if (moment.isMoment(obj)) {
            this.the_model = obj.clone().toDate();
        } else if (obj) {
            this.the_model = moment(obj, 'YYYY-MM-DD HH:mm:ss').clone().toDate();
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

}
