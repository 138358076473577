import { Injectable } from '@angular/core';
import {Router, CanActivateChild} from '@angular/router';
import {AuthService} from '../../../now/user/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

    constructor(
        private auth: AuthService,
        private router: Router
    ) {
    }

    canActivateChild() {
        const authUser = this.auth.user;
        if (!authUser || !authUser.id) {
            this.router.navigate(['/auth']);
            return false;
        }
        return true;
    }
}
