import {Component, Input} from '@angular/core';
import {TaskModel} from '../../models/task.model';
import {Router} from '@angular/router';

@Component({
    selector: 'support-bar-component',
    templateUrl: 'supportBar.component.html',
    styleUrls: ['supportBar.component.scss']
})
export class SupportBarComponent {

    public _task: TaskModel;

    @Input()
    set task(val: TaskModel) {
        this._task = val;
    }

    constructor(private router: Router) {
        //
    }

    public purchaseTask(): void {
        this.router.navigateByUrl('/request/purchase');
    }

    public purchaseFVDTask(): void {
        this.router.navigateByUrl('/request/fvd');
    }

    public fvdTask(): void {
        this.router.navigateByUrl('/request/fvd');
    }

    public storeTask(): void {
        this.router.navigateByUrl('/request/store');
    }

    public gotoStore(): void {
        this.router.navigateByUrl('/store/view/sn');
    }

    public viewCalendar(): void {
        this.router.navigateByUrl('/calendar');
    }

}
