import {Model} from '../now/model';
import {SchedulerModel} from './scheduler.model';
import {UserModel} from '../now/user/user.model';
import {JobModel} from './job.model';
import {ProcessModel} from './process.model';
import {ProductProcessModel} from './productProcess.model';

export class SchedulerHistoryModel extends Model {

    public scheduler_id: string;
    public scheduler: SchedulerModel;
    public user: UserModel;
    public to_user: UserModel;
    public job: JobModel;
    public to_process: ProcessModel;
    public start: string;
    public end: string;
    public minutes: number;
    public gap_time_minutes: number;
    public product_process: ProductProcessModel;

    constructor() {
        super('scheduler/histories', 'App\\SchedulerHistory');
        // this.job = new JobModel();
        // this.to_process = new ProcessModel();
        // this.product_process = new ProductProcessModel();
    }

    public clone(data: any, without: string[] = []): Model {
        if (data && data.scheduler) {
            this.scheduler = new SchedulerModel();
            this.scheduler.clone(data.scheduler);
            delete data.scheduler;
        }
        if (data && data.user) {
            this.user = new UserModel();
            this.user.clone(data.user);
            delete data.user;
        }
        if (data && data.to_user) {
            this.to_user = new UserModel();
            this.to_user.clone(data.to_user);
            delete data.to_user;
        }
        super.clone(data);
        return this;
    }

}
