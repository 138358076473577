import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import {TaskService} from '../../../services/task.service';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {VendorModel} from '../../../models/vendor.model';
import {PurchaseModel} from '../../../models/purchase.model';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {JobModel} from '../../../models/job.model';
import {StoreService} from '../../../services/store.service';
import {PricingInquiryModel} from '../../../models/pricingInquiry.model';
import {PricingInquiryService} from '../../../services/pricingInquiry.service';
import {DocumentModel} from '../../../models/document.model';
import {PricingInquiryDocument} from '../../../app/api/pricingInquiryDocument';
import {ProductMaterial} from '../../../models/productMaterial';
import {MaterialCreateModal} from '../view/product/materialCreate/materialCreate.modal';
import {SchedulerModel} from '../../../models/scheduler.model';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {Viewer} from '../../../services/viewer';
import {LoaderService} from '../../../components/loader/loader.service';
import {PricingInquiryDocumentDetailComponent} from '../purchase/pricingInquiryDocumentDetail/pricingInquiryDocumentDetail.component';
import {PurchaseRequisitionModel} from '../../../models/purchaseRequisition.model';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {Remark} from '../../../app/api/remark';

@Component({
    selector: 'purchase2-task-component',
    templateUrl: 'purchase2Task.component.html',
    styleUrls: ['purchase2Task.component.scss'],
    providers: [DecimalPipe]
})
export class Purchase2TaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('rejectRemarkModal', { static: true }) rejectRemarkModal: RemarkModal;
    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;

    public remarks: Remark[];
    public pricing_inquiry: PricingInquiryModel;
    public job: JobModel;
    public purchase_requisition: PurchaseRequisitionModel;
    public sale: UserModel;
    public customer: CustomerModel;
    public current_tab: string;
    public incoterms: IncotermModel[];
    public carriers: CarrierModel[];
    public vendor: VendorModel;
    public new_tasks: TaskModel[];
    public items: ProductModel[];
    public job_no: string;
    public product_no: string;
    public product_id: string;
    public request_man: UserModel;
    public job_id: string;
    public schedulers: SchedulerModel[];
    public materials: ProductMaterial[];
    public children: any[];
    public product: ProductModel;
    public options: any;
    public purchase_requisition_id: any;
    public documents: any[] = [];

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private swal: SwalService,
        private pricingInquiryService: PricingInquiryService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        private storeService: StoreService,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        private route: ActivatedRoute,
        public location: Location,
        public taskService: TaskService,
        private loader: LoaderService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.materials = [];

        this.current_tab = '#information';
        this.vendor = new VendorModel();
        this.sale = new UserModel();
        this.items = [];
        this.pricing_inquiry = new PricingInquiryModel();
        this.purchase_requisition = new PurchaseRequisitionModel();
        this.remarks = [];
        this.new_tasks = [];
        this.job_no = '';
        this.product_no = '';

        this.options = {
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month'
            },
            events: []
        };

        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.task = new TaskModel();
                this.task_id = params['id'];
                this.job_id = params['job_id'];
                if (this.task_id) {
                    this.task.id = this.task_id;
                    this.getTaskDetail();
                } else {
                    //
                }
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // console.log(this.task);
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public showMaterialDetail(product_material: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : true,
            editable        : false,
            type            : 'frw',
            // submit_text     : this.submit_text,
            item_id         : product_material.item.id,
            item_name       : product_material.item.search_value,
            material_model  : product_material.item,
            current_item    : product_material.item
        }, {
            //
        }).then((data: any): void => {
            //
        });
    }

    private getTaskDetail(): void {
        this.api.request('tasks/' + this.task_id + '/detail')
            .subscribe((response: any): void => {
                if (response && response.data) {
                    this.task.clone(response.data);
                    this.purchase_requisition_id = this.task.modelable_id;
                    this.getPurchaseOrder()
                        .then(() => {
                            this.doReady();
                        });
                }
            }, error => {
                //
            });
    }

    public getPurchaseOrder(): Promise<PurchaseRequisitionModel> {
        let promise: Promise<PurchaseRequisitionModel>;
        promise = new Promise<PurchaseRequisitionModel>((resolve, reject) => {
            this.api.request('purchase/requisitions/' + this.purchase_requisition_id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let purchase_requisition: PurchaseRequisitionModel;
                    purchase_requisition = new PurchaseRequisitionModel();
                    purchase_requisition.clone(response.data);
                    if (response.data.job && response.data.job.id) {
                        const job = new JobModel();
                        job.clone(response.data.job);
                        purchase_requisition.job = job;
                    } else {
                        purchase_requisition.job = new JobModel();
                    }
                    this.purchase_requisition = purchase_requisition;
                    this.remarks = this.purchase_requisition.remarks;
                    if (!this.remarks) {
                        this.remarks = [];
                    }
                    this.purchase_requisition = purchase_requisition;

                    if (this.purchase_requisition.documents && this.purchase_requisition.documents.length) {
                        for (const doc of this.purchase_requisition.documents) {
                            this.documents.push(doc);
                        }
                    }

                    resolve(purchase_requisition);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public viewDocumentDetail(doc: PricingInquiryDocument, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PricingInquiryDocumentDetailComponent, {
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((data: any): void => {
                if (data && data.deleted === true) {
                    this.pricing_inquiry.documents.splice(index, 1);
                    this.swal.success('ลบเอกสารสำเร็จ');
                } else if (data && data.submit === true) {
                    doc.name = data.name;
                    this.swal.success('แก้ไขเอกสารสำเร็จ');
                }
            });
    }

    public getJob(job_id: string): Promise<JobModel> {
        let promise: Promise<JobModel>;
        promise = new Promise<JobModel>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        if (this.job && this.job.job_no) {
                            this.job_no = this.job.job_no;
                        }
                        if (this.job && this.job.product && this.job.product.product_no) {
                            this.product_no = this.job.product.product_no;
                        }
                        resolve(this.job);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onCustomerSelect(e: any): void {
        this.pricing_inquiry.customer_id = (e && e.id) ? e.id : null;
    }

    public onStartUploader(data: any): void {
        //
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('pricing/inquiries/document', 'PUT', {}, {
            document: data,
            id: this.pricing_inquiry.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                if (!this.pricing_inquiry.documents) {
                    this.pricing_inquiry.documents = [];
                }
                this.pricing_inquiry.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    public viewPurchaseRequisition() {
        this.viewer.purchaseRequisition(this.purchase_requisition);
    }

    public accept() {
        this.approveTask()
            .then((content: any) => {
                this.api.request('purchase/requisitions/approve', 'POST', {}, {
                    id: this.purchase_requisition.id,
                    items: this.vendorItems()
                }).subscribe(() => {
                    if (this.purchase_requisition.approved_at) {
                        this.taskService.setStatus(this.task, this.task.status, 'request-purchase-3', 'request-purchase-2', 'request-purchase-3', false, false, content.remark_message)
                            .then(() => {
                                this.leave(true);
                                this.swal.success('ตรวจสอบคำร้องขอสำเร็จ');
                                this.navBack();
                            });
                    } else {
                        this.taskService.setStatus(this.task, this.task.status, 'request-purchase-2', 'request-purchase-2', 'request-purchase-2', false, null, content.remark_message)
                            .then(() => {
                                this.leave(true);
                                this.swal.success('ส่งคำร้องขอสำเร็จ');
                                this.navBack();
                            });
                    }
                });
            });
    }

    public reject() {
        this.rejectRemarkModal.open();
    }

    public onRejectRemarkModalSubmit(e: any) {
        const remark_message = (e && e.remark_message) ? e.remark_message : null;
        this.api.request('purchase/requisitions/reject', 'POST', {}, {
            id: this.purchase_requisition.id,
            task_id: this.task.id,
            remark: remark_message
        }).subscribe(() => {
            if (this.purchase_requisition.approved_at) {
                this.taskService.setStatus(this.task, this.task.status, 'request-purchase-2', 'request-purchase-2', 'request-purchase-2', false, false)
                    .then(() => {
                        this.swal.success('ปฏิเสธสำเร็จ');
                        this.leave(true);
                    });
            } else {
                this.taskService.setStatus(this.task, this.task.status, 'reject_pr', 'request_pr', 'request_pr', false, this.purchase_requisition.request_man_id)
                    .then(() => {
                        this.swal.success('ปฏิเสธสำเร็จ');
                        this.leave(true);
                    });
            }
        });
    }

    public onVendorChange(item, index, event) {
        item.pivot.vendor_id = (event && event.id) ? event.id : null;
    }

    public openRemarkModal() {
        for (const item of this.purchase_requisition.items) {
            if (item && item.id && !item.pivot.vendor_id) {
                this.swal.danger('โปรดระบุผู้ผลิตจากรายการสินค้า "' + item.name + '"');
                return;
            }
        }
        this.remarkModal.open();
    }

    public vendorItems(): any {
        const dat = [];
        for (const item of this.purchase_requisition.items) {
            if (item && item.id && item.pivot && item.pivot.vendor_id) {
                dat.push({
                    item_id: item.id,
                    remark: item.pivot.remark,
                    amount: item.pivot.amount,
                    days: this.findDays(item),
                    price: this.findPrice(item),
                    vendor_id: item.pivot.vendor_id
                });
            }
        }
        return dat;
    }

    private findDays(item) {
        if (item.pivot.vendor_id) {
            if (item.pivot.vendor_id === item.pivot.vendor1_id) {
                return item.pivot.days1;
            } else if (item.pivot.vendor_id === item.pivot.vendor2_id) {
                return item.pivot.days2;
            } else if (item.pivot.vendor_id === item.pivot.vendor3_id) {
                return item.pivot.days3;
            }
        }
        return null;
    }

    private findPrice(item) {
        if (item.pivot.vendor_id) {
            if (item.pivot.vendor_id === item.pivot.vendor1_id) {
                return item.pivot.price1;
            } else if (item.pivot.vendor_id === item.pivot.vendor2_id) {
                return item.pivot.price2;
            } else if (item.pivot.vendor_id === item.pivot.vendor3_id) {
                return item.pivot.price3;
            }
        }
        return null;
    }

    public onVendor1Change(event?) {
        // setTimeout(() => {
        //     this.save(true);
        // });
        // item.pivot.vendor1_id = (event && event.id) ? event.id : null;
    }

    public onVendor2Change(event?) {
        // setTimeout(() => {
        //     this.save(true);
        // });
        // item.pivot.vendor2_id = (event && event.id) ? event.id : null;
    }

    public onVendor3Change(event?) {
        // setTimeout(() => {
        //     this.save(true);
        // });
        // item.pivot.vendor3_id = (event && event.id) ? event.id : null;
    }

    public save(force?: boolean) {
        // this.api.request('purchase/requisitions', {}, {
        //     id: this.purchase_requisition.id,
        //     items: this.vendorItems()
        // })
    }

    public onRemarkModalSubmit(e: any): void {
        const remark_message = (e && e.remark_message) ? e.remark_message : null;
        this.loader.show();
        this.api.request('purchase/requisitions/po', 'POST', {}, {
            id: this.purchase_requisition.id,
            items: this.vendorItems()
        }).subscribe(() => {
            this.taskService.complete(this.task)
                .then(() => {
                    this.loader.hide();
                    this.swal.success('แยก PO ตามผู้ผลิตสำเร็จ');
                    this.leave(true);
                });
        });
    }

    public noticePurchaseDate(): void {
        for (let i = 0; i < this.items.length; i++) {
            const item: any = this.items[i];
            if (item && item.id && !item.dateTime) {
                if (item.modelable && item.modelable.mat_name) {
                    this.swal.danger('รายการ "' + item.modelable.mat_name + '" ไม่ได้ระบุวันที่จะได้รับวัสดุ');
                } else {
                    this.swal.danger('รายการจัดซื้อไม่ได้ระบุวันที่จะได้รับวัสดุ');
                }
                return;
            }
        }
        this.swal.confirm('ยืนยันการแจ้งวันที่จะได้รับวัสดุต่อผู้ร้องขอใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    /*this._save()
                        .then(() => {
                            this.storeService.noticePurchaseDate(this.pricing_inquiry.id, this.pricing_inquiry.items)
                                .then(response => {
                                    let role: string;
                                    role = (this.job.current_role === 'pd-planning') ? 'pd-planning-2' : this.job.current_role;
                                    this.taskService.setStatus(this.task, this.task.status, 'purchase_date_time', role, this.job.process_slug)
                                        .then(() => {
                                            const items: any[] = this.get_items();
                                            this.storeService.purchase(
                                                null,
                                                this.job.id,
                                                this.job.product_id,
                                                null,
                                                items,
                                                this.job.customer_id,
                                                null,
                                                this.pricing_inquiry.objective,
                                                true,
                                                null,
                                                this.task.id,
                                                this.pricing_inquiry.id,
                                                'po-purchase',
                                                'create_po_purchase'
                                            ).then(purchase_response => {
                                                this.swal.success('ออกใบจัดซื้อจัดจ้างสำเร็จ');
                                                this.leave(true);
                                                this.loader.hide();
                                            }, error => {
                                                this.swal.danger(error);
                                                this.loader.hide();
                                            });
                                        });
                                });
                        }, error => {
                            this.swal.danger(error);
                            this.loader.hide();
                        });*/
                } else {
                    //
                }
            });
    }

    private get_items(): any[] {
        let items: any[];
        items = [];
        for (let i = 0; i < this.items.length; i++) {
            const material: any = this.items[i];
            if (material && material.id/* && material.checked && material.job_id === this.job.id*/) {
                items.push({
                    // modelable_type: material.item.modelable.modelable_type,
                    // modelable_id: material.item.modelable.id,
                    modelable_type: 'App\\Item',
                    modelable_id: material.item.id,
                    amount: material.amount,
                    dateTime: material.dateTime,
                    prepared_status: material.prepared_status,
                    price: material.price,
                    remark: material.remark,
                    is_modelable: true
                });
            }
            if (material.children) {
                for (let j = 0; j < material.children.length; j++) {
                    const mat_child: any = material.children[j];
                    if (mat_child && mat_child.id/* && mat_child.checked && material.job_id === this.job.id*/) {
                        items.push({
                            // modelable_type: mat_child.item.modelable.modelable_type,
                            // modelable_id: mat_child.item.modelable.id,
                            modelable_type: 'App\\Item',
                            modelable_id: mat_child.item.id,
                            amount: mat_child.amount,
                            dateTime: mat_child.dateTime,
                            prepared_status: mat_child.prepared_status,
                            price: mat_child.price,
                            remark: mat_child.remark,
                            is_modelable: true
                        });
                    }
                }
            }
        }
        return items;
    }

    public addRemark(purchase: PurchaseModel): void {
        this.modal.show(RemarkModalComponent, {
            description: purchase.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                purchase.remark = content.description;
                // this.save(true);
            } else {
                //
            }
        });
    }

    public onSaveApiCallingBack(e: any): void {
        // this.save(true);
    }

}
