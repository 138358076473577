import {AfterViewInit, Component} from '@angular/core';

@Component({
    selector: 'report-page',
    templateUrl: 'report.page.html',
    styleUrls: ['report.page.scss']
})
export class ReportPage implements AfterViewInit {

    public pages: any[];

    constructor() {
        //
        this.pages = [
            {
                display: 'รายงาน',
                children: [
                    {
                        display: 'ภาพรวม',
                        link: '/report/overall'
                    },
                    {
                        display: 'Sale',
                        link: '/report/sale'
                    },
                    {
                        display: 'Planning',
                        link: '/report/planning'
                    },
                    {
                        display: 'Prod Control',
                        link: '/report/control'
                    },
                    {
                        display: 'Prod',
                        link: '/report/prod'
                    },
                    {
                        display: 'Store',
                        link: '/report/store'
                    }
                ]
            }
        ];
    }

    ngAfterViewInit(): void {
        //
    }

}
