import {FormModel} from '../now/formModel';
import {CarrierModel} from './carrier.model';
import {IncotermModel} from './incoterm.model';
import {Model} from '../now/model';

export class ShippingModel extends FormModel {

    public name: string;
    public price: number;
    public ranking: number;

    public incoterm: IncotermModel;
    public incoterm_id: string;
    public incoterm_code: string;
    public carrier_id: string;
    public carrier: CarrierModel;
    public carrier_name: string;

    constructor() {
        // super('shippings', 'App\\Shipping');
        super('shippings', 'App\\Incoterm');
        this.carrier = new CarrierModel();
        this.incoterm = new IncotermModel();
    }

    public clone(data: any, without: string[] = [], _delete?: boolean): Model {
        if (data) {
            if (data.incoterm_code) {
                this.incoterm.incoterm_code = data.incoterm_code;
                this.incoterm.price = data.price;
                delete data.incoterm_code;
            }
        }
        return super.clone(data, without, _delete);
    }

}
