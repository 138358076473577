import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ViewList} from '../../database/list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {AppService} from '../../../app/app.service';
import {ItemModel} from '../../../models/item.model';
import {WarehouseModel} from '../../../models/warehouse.model';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {StoreService} from '../../../services/store.service';
import {StoreListComponent} from '../../../components/storeList/storeList.component';
import {StoreFormComponent} from '../../../components/storeForm/storeForm.component';
import {Api} from '../../../now/api/api';
import {environment} from '../../../environments/environment';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'store-type-component',
    templateUrl: 'storeType.component.html',
    styleUrls: ['storeType.component.scss']
})
export class StoreTypeComponent extends ViewList implements AfterViewInit, OnDestroy {

    @ViewChild(StoreListComponent, { static: false }) storeListComponent: StoreListComponent;
    @ViewChild(StoreFormComponent, { static: false }) storeFormComponent: StoreFormComponent;

    public type: string;
    public current_item: ItemModel;
    public search_items: ItemModel[];
    public is_searching: boolean;

    public getting: boolean;
    public got: boolean;

    constructor(
        private route: ActivatedRoute,
        private appService: AppService,
        public userService: UserService,
        private swal: SwalService,
        private modelApi: ModelApi,
        private api: Api,
        private viewer: Viewer,
        private storeService: StoreService
    ) {
        //
        super();
        this.current_item = new ItemModel();
        this.search_items = [];
        this.is_searching = false;
        this.getting = false;
        this.got = true;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.appService.fixPage();
                    this.type = params['type'];
                    this.current_item.type = this.type;
                });
        }, 0);
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public printReport(): void {
        this.viewer.storeReport(this.type)
            .then(() => {
                //
            });
    }

    public onCancelSearching(e?: any): void {
        //
    }

    public getItem(id: string | number): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('store/items/view/' + id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSelectedItem(e?: any): void {
        this.current_item = e;
        if (this.current_item && this.current_item.id) {
            this.getItem(this.current_item.id)
                .then(data => {
                    this.current_item = new ItemModel();
                    this.current_item.clone(data);
                    if (!this.current_item.warehouses || !this.current_item.warehouses.length) {
                        this.current_item.warehouses = [new WarehouseModel()];
                    }
                });
        } else {
            this.current_item = new ItemModel();
            if (this.current_item && (!this.current_item.warehouses || this.current_item.warehouses.length === 0)) {
                this.current_item.warehouses = [];
                this.current_item.warehouses.push(new WarehouseModel());
            }
            //
            if (this.type) {
                this.current_item.type = this.type;
            }
        }
    }

    public onItemGenNO(e: any): void {
        this.storeListComponent.refresh(this.current_item);
    }

    public onItemFormSubmit(e: any): void {
        const is_new: boolean = e.isNew;
        let text: string;
        text = 'ยืนยันการเพิ่มวัสดุใหม่เข้าคลังใช่หรือไม่?';
        if (!is_new) {
            text = 'ยืนยันการแก้ไขวัสดุใช่หรือไม่?';
        }
        this.swal.confirm(text)
            .then((result: boolean): void => {
                if (result === true) {
                    this.createOrUpdate(is_new)
                        .then(() => {
                            const current_item_id = this.current_item.id;
                            this.storeFormComponent.refresh(this.current_item);
                            this.storeListComponent.refresh(this.current_item);
                            /*if (is_new === true) {
                                this.storeService.genNO(this.current_item)
                                    .then((item_no: string): void => {
                                        this.current_item.item_no = item_no;
                                        const current_item_id = this.current_item.id;
                                        this.storeListComponent.refresh(this.current_item);
                                        this.storeFormComponent.createItem();
                                        // setTimeout(() => {
                                        //     this.current_item.id = current_item_id;
                                        //     this.storeListComponent.gotoItemView(this.current_item);
                                        // }, 300);
                                    }, error => {
                                        //
                                    });
                            } else {
                                const current_item_id = this.current_item.id;
                                this.storeListComponent.refresh(this.current_item);
                                this.storeFormComponent.createItem();
                                // setTimeout(() => {
                                //     this.current_item.id = current_item_id;
                                //     this.storeListComponent.gotoItemView(this.current_item);
                                // }, 300);
                            }*/
                        });
                } else {
                    //
                }
            });
    }

    public createOrUpdate(is_new?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            let tmp_documents: any[];
            if (is_new) {
                if (this.current_item && this.current_item.modelable && this.current_item.modelable.documents) {
                    tmp_documents = this.current_item.modelable.documents;
                    delete this.current_item.modelable.documents;
                } else {
                    tmp_documents = [];
                }
            } else {
                tmp_documents = [];
            }
            this.modelApi.createOrUpdate(this.current_item, [
                'modelable', 'type', 'name', 'name_en', 'ref_no', 'type', 'amount', 'width', 'height', 'unit', 'width', 'ref_item_no',
                'length', 'weight', 'warehouse_ids', 'material_type', 'tooth', 'size', 'vendor_id', 'modelable_id',
                'price', 'remark', 'grade', 'surface', 'material', 'thickness', 'outer_diameter', 'inner_diameter', 'remark', 'status'
            ], 'store/items', null, null, false)
                .subscribe((response: any): void => {
                    let data: any;
                    data = this.current_item.data;
                    if (response && response.success === true) {
                        if (is_new === true) {
                            this.swal.success('เพิ่มรายการสำเร็จ');
                            this.current_item = new ItemModel();
                            this.current_item.id = response.data.id;
                            this.current_item.modelable = response.data.modelable;
                            this.current_item.modelable_id = response.data.modelable_id;
                            this.current_item.modelable_type = response.data.modelable_type;
                        } else {
                            this.swal.success('แก้ไขรายการสำเร็จ');
                            this.current_item.search_value = response.data.search_value;
                        }
                    }
                    if (data && is_new) {
                        this.storeFormComponent.onDocumentUploadedSuccess(data, this.current_item.modelable_id, this.current_item.modelable_type)
                            .then(() => {
                                resolve(this.current_item);
                            }, error => {
                                resolve({});
                            });
                    } else {
                        resolve(this.current_item);
                    }
                });
        });
        return promise;
    }

    public onSearching(e?: any): void {
        this.is_searching = (e === true) ? true : false;
    }

    public onFilterItems(e?: any): void {
        this.search_items = e;
    }

    public onGetting(e?: any): void {
        this.getting = true;
        this.got = false;
    }

    public onGot(e?: any): void {
        this.getting = false;
        this.got = true;
    }

}
