import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {DocumentModel} from '../../models/document.model';
import {Api} from '../../now/api/api';
import {UploaderComponent} from '../uploader/uploader.component';
import {SwalService} from '../../services/swal.service';
import {Viewer} from '../../services/viewer';
import {DebitNote} from '../../app/api/debitNote';
import {DebitNoteDetailComponent} from '../../pages/task/view/debitNoteDetail/debitNoteDetail.component';

@Component({
    selector: 'debit-note-table-component',
    templateUrl: 'debitNoteTable.component.html',
    styleUrls: ['debitNoteTable.component.scss']
})
export class DebitNoteTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() debitNotes: DebitNote[];
    @Input() model: any;
    @Input() task: TaskModel;

    public current_debitNote: DebitNote;

    constructor(
        public viewer: Viewer,
        private modal: ModalService,
        private swal: SwalService,
        private api: Api
    ) {
        //
        this.debitNotes = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewDebitNoteDetail(debitNote: DebitNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DebitNoteDetailComponent, {
            debitNote   : debitNote,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.debitNote) {
                    debitNote.status = content.debitNote.status;
                }
            });
    }

    public viewDebitNotePreview(debitNote: DebitNote): void {
        this.viewer.debitNote(debitNote);
    }

    public createDebitNote(): void {
        let debitNote: DebitNote;
        debitNote = {
            material_price: this.model.material_price,
            discount_type: this.model.discount_type,
            discount_percent: this.model.discount_percent,
            discount_price: this.model.discount_price,
            is_include_vat: this.model.is_include_vat,
            frt_sh: this.model.frt_sh,
            misc_chgs: this.model.misc_chgs,
            grand_total: this.model.grand_total,
        };
        this.modal.show(DebitNoteDetailComponent, {
            debitNote     : debitNote,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.is_create === true) {
                        debitNote = Object.assign({}, content.debitNote);
                        this.model.debit_notes.push(debitNote);
                        this.viewDebitNotePreview(debitNote);
                    }
                } else {
                    //
                }
            });
    }

    public attachFile(debitNote: DebitNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_debitNote = debitNote;
        this.uploader.trigger();
    }

    public viewFile(debitNote: DebitNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(debitNote.document_id);
    }

    public removeFile(debitNote: DebitNote, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('debit/notes/document/delete', 'POST', {}, {
                        id: debitNote.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        debitNote.document_id = null;
                        debitNote.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_debitNote && this.current_debitNote.id) {
            this.api.request('debit/notes/document', 'PUT', {}, {
                document: data,
                id: this.current_debitNote.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_debitNote.document_id = new_document.id;
                    this.current_debitNote.document = new_document;
                    this.viewFile(this.current_debitNote);
                }
                this.current_debitNote = null;
            }, error => {
                this.current_debitNote = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
