import {Model} from '../now/model';
import {DocumentModel} from './document.model';

export class InspectionSheetModel extends Model {

    public job_id: string;
    public document: DocumentModel;
    public role: string;

    constructor() {
        super('inspection/sheets', 'App\\InspectionSheet');
        this.document = new DocumentModel();
    }

}
