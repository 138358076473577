import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {

    constructor() {
        //
    }

    public store(name: string, data: any): void {
        if (typeof data === 'string') {
            //
        } else {
            data = JSON.parse(data);
        }
        console.log(data);
        localStorage.setItem(name, data);
    }

    public retrieve(name: string): any {
        let dat: any;
        dat = localStorage.getItem(name);
        if (typeof dat === 'string') {
            //
        } else {
            dat = JSON.stringify(dat);
        }
        return dat;
    }

    public clear(name: string): void {
        localStorage.removeItem(name);
    }

}
