import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {RoleModel} from '../../../../models/role.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {SchedulerHistoryModel} from '../../../../models/schedulerHistory.model';
import {FullCalendarComponent} from '../../../../components/fullCalendar/fullCalendar.component';
import {ModalService} from '../../../../services/modal.service';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../../environments/environment';
import {CustomerModel} from '../../../../models/customer.model';
import {QuotationModel} from '../../../../models/quotation.model';
import {CustomerAddressModel} from '../../../../models/customerAddress.model';
import {ContactModel} from '../../../../models/contact.model';
import {BillModel} from '../../../../models/bill.model';
import {TaskService} from '../../../../services/task.service';
import * as moment from 'moment';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'customer-view-component',
    templateUrl: 'customerView.component.html',
    styleUrls: ['customerView.component.scss']
})
export class CustomerViewComponent implements AfterViewInit {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;

    public search_quotation: string;
    public customer: CustomerModel;
    public customer_id: string;
    public current_tab: string;
    public quotations: QuotationModel[];
    public roles: RoleModel[];
    public tasks: any[];
    public scheduler_histories: SchedulerHistoryModel[];
    public today: string;
    public current_date: any;
    public tmp_current_date: any;
    public ready: boolean;
    public options: any;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        private taskService: TaskService,
        private router: Router,
        private appService: AppService,
        private modelApi: ModelApi,
        private location: Location,
        private modal: ModalService,
        private swal: SwalService,
        public viewer: Viewer,
    ) {
        //
        this.quotations = [];
        this.customer = new CustomerModel();
        this.route.params
            .subscribe(params => {
                this.customer_id = params['id'];
            });

        this.today = moment(new Date()).format('MMMM YYYY');
        this.current_tab = 'profile';
        this.ready = false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.tmp_current_date = moment(new Date());
            this.current_date = this.tmp_current_date.format('YYYY-MM-DD HH:mm:ss');
            this.getCustomer()
                .then(() => {
                    this.getQuotations(this.tmp_current_date.format('YYYY'))
                        .then(() => {
                            //
                        });
                });
        }, 0);
    }

    public onSearchQuotationChange(e?: any): void {
        //
    }

    public onDateChange(e: any): void {
        const moment_obj = moment(e, 'YYYY-MM-DD HH:mm:ss');
        this.getQuotations(moment_obj.format('YYYY'))
            .then(() => {
                //
            });
    }

    public viewQuotation(quotation: QuotationModel): void {
        this.viewer.quotation(quotation);
    }

    public cloneAddress(address: any): void {
        setTimeout(() => {
            address.name = this.customer.name;
            address.branch = this.customer.branch;
            address.telephone = this.customer.telephone;
            address.telephone2 = this.customer.telephone2;
            address.fax = this.customer.fax;
            address.email = this.customer.email;
            address.website = this.customer.website;
            address.address = this.customer.address;
            address.city = this.customer.city;
            address.postal_code = this.customer.postal_code;
            address.state = this.customer.state;
            address.country = this.customer.country;
        }, 0);
    }

    public goBack(): void {
        this.location.back();
    }

    public getQuotations(YYYY?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('customers/' + this.customer_id + '/quotations', 'GET', {
                YYYY: (YYYY) ? YYYY : ''
            }, {})
                .subscribe((response: any): void => {
                    if (this.quotations) {
                        this.quotations.splice(0, this.quotations.length);
                    } else {
                        this.quotations = [];
                    }
                    if (response && response.success === true) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            let quotation: QuotationModel;
                            quotation = new QuotationModel();
                            quotation.clone(dat);
                            this.quotations.push(quotation);
                        }
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getCustomer(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.customer_id) {
                this.api.request('customers/' + this.customer_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.customer.clone(response.data);
                        if (this.customer.addresses && this.customer.addresses.length === 0) {
                            this.addAddress();
                        }
                        if (this.customer.contacts && this.customer.contacts.length === 0) {
                            this.addContact();
                        }
                        if (this.customer.bills && this.customer.bills.length === 0) {
                            this.addBill();
                        }
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.customer);
            }
        });
        return promise;
    }

    public addContact(): void {
        this.customer.contacts.push(new ContactModel());
    }

    public addBill(): void {
        this.customer.bills.push(new BillModel());
    }

    public addAddress(): void {
        this.customer.addresses.push(new CustomerAddressModel());
    }

    public save_contact(contact: ContactModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            contact.modelable_type = 'App\\Customer';
            contact.modelable_id = this.customer.id;
            //
            this.modelApi.createOrUpdate(contact, [
                'full_name', 'telephone', 'telephone2', 'email', 'fax', 'position', 'modelable_type', 'modelable_id'
            ], null, null, null, true, true)
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save_address(address: CustomerAddressModel): Promise<Object> {
        let promise:  Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            address.customer_id = this.customer_id;
            this.modelApi.createOrUpdate(address, [
                'telephone', 'telephone2', 'fax', 'email', 'website',
                'address', 'city', 'postal_code', 'state', 'country',
                'customer_id', 'name', 'branch'
            ], null, null, null, true, true)
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save_bill(bill: BillModel): Promise<Object> {
        let promise:  Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            bill.customer_id = this.customer_id;
            const post_data: any[] = [
                'telephone', 'telephone2', 'fax', 'email', 'website',
                'address', 'city', 'postal_code', 'state', 'country',
                'customer_id', 'name', 'branch'
            ];
            if (bill && bill.id) {
                this.modelApi.update(bill, post_data, null, null, null, true, true)
                    .subscribe((response: any): void => {
                        resolve(response);
                    }, error => {
                        reject(error);
                    });
            } else {
                this.modelApi.create(bill, post_data, null, true, true)
                    .subscribe((response: any): void => {
                        resolve(response);
                    }, error => {
                        reject(error);
                    });
            }
        });
        return promise;
    }

    public save(): void {
        this.modelApi.createOrUpdate(this.customer, [
            // profile
            'avatar', 'name', 'tax_no', 'branch', 'ranking', 'credit_terms',
            'telephone', 'telephone2', 'email', 'fax', 'email', 'website', 'address',
            'city', 'state', 'postal_code', 'country', 'state', 'shipping_day'
            // customer addresses
            // 'addresses.telephone', 'addresses.telephone2', 'addresses.fax', 'addresses.email',
            // 'addresses.website', 'addresses.address', 'addresses.city', 'addresses.postal_code',
            // 'addresses.state', 'addresses.country'
        ]).subscribe((response: any): void => {
            let _promise_list: Promise<Object>[];
            _promise_list = [];
            for (let i = 0; i < this.customer.addresses.length; i++) {
                const address: CustomerAddressModel = this.customer.addresses[i];
                if (address && address.name) {
                    _promise_list.push(this.save_address(address));
                }
            }
            for (let i = 0; i < this.customer.contacts.length; i++) {
                const contact: ContactModel = this.customer.contacts[i];
                if (contact && contact.full_name) {
                    _promise_list.push(this.save_contact(contact));
                }
            }
            for (let i = 0; i < this.customer.bills.length; i++) {
                const bill: BillModel = this.customer.bills[i];
                if (bill && bill.name) {
                    _promise_list.push(this.save_bill(bill));
                }
            }
            if (_promise_list.length > 0) {
                Promise.all(_promise_list)
                    .then(() => {
                        this.swal.success('บันทึกข้อมูลลูกค้าสำเร็จ');
                    }, error => {
                        this.swal.danger(error);
                    });
            } else {
                this.swal.success('บันทึกข้อมูลลูกค้าสำเร็จ');
            }
        }, error => {
            this.swal.danger(error);
        });
    }

    public onUploadedSuccess(data: any): void {
        if (data && data.preview) {
            this.customer.avatar = data.preview;
            this.modelApi.update(this.customer, ['avatar'])
                .subscribe(() => {});
        }
    }

    public onUploadError(error: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('customers/document', 'PUT', {}, {
            document: data,
            id: this.customer.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.customer.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            user: this.customer,
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentPreview(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        window.open(environment.api_host + 'view/' + doc.id + '/user/document/', '_blank');
    }

    public onStartUploader(data: any): void {
        if (this.customer && this.customer.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
        }
    }

    public createCustomer(): void {
        this.save();
    }

}
