import {Model} from '../now/model';
import {DocumentModel} from './document.model';
import {FormModel} from '../now/formModel';

export class CarrierModel extends FormModel {

    public name: string;
    public avatar: string;
    public type: number;
    public type_note: string;
    public ranking: number;
    public tax_no: string;
    public branch: string;
    public telephone: string;
    public telephone2: string;
    public fax: string;
    public email: string;
    public website: string;
    public address: string;
    public city: string;
    public state: string;
    public country: string;
    public postal_code: string;
    public shipping_id: string;
    public num_contacts: number;

    public documents: DocumentModel[];

    constructor() {
        super('carriers');
        this.documents = [];
    }

    public get full_address(): string {
        let address: string;
        let city: string;
        let state: string;
        let postal_code: string;
        let country: string;

        address     = (this.address) ? this.address : '';
        city        = (this.city) ? this.city : '';
        state       = (this.state) ? this.state : '';
        postal_code = (this.postal_code) ? this.postal_code : '';
        country     = (this.country) ? this.country : '';

        return address + ' ' + city + ' ' + state + ' ' + postal_code + ' ' + country;
    }

}
