import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {IncotermModel} from '../../../../models/incoterm.model';
import {Location} from '@angular/common';

@Component({
    selector: 'incoterm-view-component',
    templateUrl: 'incotermView.component.html',
    styleUrls: ['incotermView.component.scss']
})
export class IncotermViewComponent implements OnInit {

    public incoterm: IncotermModel;
    public incoterm_id: string;
    public current_tab: string;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private location: Location,
                private appService: AppService,
                private modelApi: ModelApi,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.incoterm = new IncotermModel();
        this.route.params
            .subscribe(params => {
                // this.viewTaskInit();
                this.incoterm_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getIncoterm()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getIncoterm(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.incoterm_id) {
                this.api.request('incoterms/' + this.incoterm_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.incoterm.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.incoterm);
            }
        });
        return promise;
    }

    public save(): void {
        if (!this.incoterm.name) {
            this.swal.danger('กรุณากรอกชื่อการขนส่ง');
        } else if (!this.incoterm.incoterm_code) {
            this.swal.danger('กรุณากรอก Incoterm Code');
        } else if (!this.incoterm.price) {
            this.swal.danger('กรุณากรอกเรทราคาสำหรับการประเมิน');
        } else {
            this.modelApi.createOrUpdate(this.incoterm, [
                'name', 'incoterm_code', 'price'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/incoterm/' + this.incoterm.id + '/detail');
                this.swal.success('บันทึกข้อมูลการขนส่งสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createIncoterm(): void {
        this.save();
    }

}
