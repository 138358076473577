import {Model} from '../now/model';
import {UserModel} from '../now/user/user.model';
import {ContactModel} from './contact.model';
import * as moment from 'moment';

export class AppointmentModel extends Model {

    public requirement_id: string;

    public date: string;
    public start_time: string;
    public end_time: string;

    public progress_type: string;

    public place_name: string;
    public description: string;
    public summary: string;

    public quotation_no: string;
    public purchase_order_no: string;
    public drawing_no: string;
    public approve_dwg_date: string;
    public track_quotation_1_date: string;
    public track_quotation_2_date: string;
    public demo_job_no: string;
    public load_job_no: string;
    public other_document: string;

    public meeting_start_time: string;
    public meeting_end_time: string;

    public man_id: string;
    public sale_id: string;
    public contact_id: string;

    public sale: UserModel;
    public contact: ContactModel;
    public man: UserModel;

    public todo: string[];
    public todo_other: string;

    constructor() {
        super('appointments', 'App\\Appointment');
        //
        this.sale = new UserModel();
        this.contact = new ContactModel();
        this.man = new UserModel();
        this.todo = [];
    }

    public clone(data: any): Model {
        if (data && data.sale) {
            this.sale.clone(data.sale);
            delete data.sale;
        }
        if (data && data.contact) {
            this.contact.clone(data.contact);
            delete data.contact;
        }
        super.clone(data);
        return this;
    }

    public get minutes(): number {
        if (this.date && this.start_time && this.end_time) {
            const start_time: any = moment(this.start_time);
            const end_time: any = moment(this.end_time);
            const asMinutes: number = moment.duration(end_time.diff(start_time)).asMinutes();
            return asMinutes;
        }
        return 0;
    }

    public get meeting_minutes(): number {
        if (this.date && this.meeting_start_time && this.meeting_end_time) {
            const meeting_start_time: any = moment(this.meeting_start_time + ':00');
            const meeting_end_time: any = moment(this.meeting_end_time + ':00');
            const asMinutes: number = moment.duration(meeting_end_time.diff(meeting_start_time)).asMinutes();
            return asMinutes;
        }
        return 0;
    }

    public get overtime_minutes(): number {
        return Math.max(this.meeting_minutes - this.minutes);
    }

}
