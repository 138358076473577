import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {LoaderState} from './loaderState';

@Injectable()
export class LoaderService {

    private loaderSubject: Subject<LoaderState>;
    public loaderState: Observable<Object>;

    constructor() {
        this.loaderSubject = new Subject<LoaderState>();
        this.loaderState = this.loaderSubject.asObservable();
    }

    public show(): void {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }

    public hide(): void {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }

}
