import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {UserModel} from '../../../../now/user/user.model';
import {SwalService} from '../../../../services/swal.service';
import {Api} from '../../../../now/api/api';

import * as moment from 'moment';

@Component({
    selector: 'absence-modal-component',
    templateUrl: 'absenceModal.component.html',
    styleUrls: ['absenceModal.component.scss']
})
export class AbsenceModalComponent implements AfterViewInit {

    public absence_num: number;
    public updated_at: string;
    public absence_date: string;
    public user: UserModel;
    public month_name: string;

    constructor(public bsModalRef: BsModalRef, private swal: SwalService, private api: Api) {
        this.month_name = moment(new Date()).format('MMMM');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.user) {
                this.user = this.bsModalRef.content.user;
                if (this.user.activities && this.user.activities.absence && this.user.activities.absence.value > 0) {
                    this.absence_num = this.user.activities.absence.value;
                    this.updated_at = this.user.activities.absence.activity_date;
                }
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (this.absence_date) {
            const absence_date: string = moment(this.absence_date).format('DD MMMM YYYY');
            this.swal.confirm('ผู้ใช้งาน ' + this.user.full_name + ' แจ้งขาดงานเมื่อวันที่ ' + absence_date + ' ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('users/absence', 'POST', {}, {
                            id: this.user.id,
                            start: this.absence_date
                        }).subscribe((response: any): void => {
                            if (response && response.data) {
                                //
                            }
                            this.swal.success('แจ้งขาดงานกับผู้ใช้งาน ' + this.user.full_name + ' สำเร็จ');
                            this.bsModalRef.hide();
                        }, error => {
                            this.swal.danger(error);
                        });
                    }
                });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลวันที่แจ้งขาดงาน');
        }
    }

}
