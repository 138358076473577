import {AfterViewInit, Component} from '@angular/core';
import {Api} from '../../../../now/api/api';
import {TaskModel} from '../../../../models/task.model';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {SwalService} from '../../../../services/swal.service';
import {JobModel} from '../../../../models/job.model';

@Component({
    selector: 'task-jobs-component',
    templateUrl: 'taskJobs.component.html',
    styleUrls: ['taskJobs.component.scss']
})
export class TaskJobsComponent implements AfterViewInit {

    public tasks: TaskModel[];
    public current_task: TaskModel;
    public customer_id: string;
    public process_slug: string;
    public task_status: number;
    public submit_text: string;
    public job: JobModel;

    constructor(private api: Api, public bsModalRef: BsModalRef, private swal: SwalService) {
        this.tasks = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.process_slug   = 'accounting';
            this.task_status    = 10;
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.current_task = this.bsModalRef.content.task;
            }
            if (this.bsModalRef && this.bsModalRef.content) {
                if (this.bsModalRef.content.process_slug) {
                    this.process_slug = this.bsModalRef.content.process_slug;
                } else {
                    //
                }
                if (this.bsModalRef.content.task_status) {
                    this.task_status = this.bsModalRef.content.task_status;
                }
                if (this.bsModalRef.content.submit_text) {
                    this.submit_text = this.bsModalRef.content.submit_text;
                }
                if (this.bsModalRef.content.customer_id) {
                    this.customer_id = this.bsModalRef.content.customer_id;
                }
                if (this.bsModalRef.content.job) {
                    this.job = this.bsModalRef.content.job;
                }
            }
            this.getTaskProducts()
                .then(() => {
                    //
                });
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        this.swal.confirm(this.submit_text)
            .then((result: boolean): void => {
                if (result === true) {
                    let _tasks: TaskModel[];
                    _tasks = [];
                    for (let i = 0; i < this.tasks.length; i++) {
                        if (this.tasks[i]._checked) {
                            _tasks.push(this.tasks[i]);
                        }
                    }
                    if (_tasks.length > 0) {
                        this.bsModalRef.content.tasks = _tasks;
                        this.bsModalRef.hide();
                    }
                } else {
                    //
                }
            });
    }

    public getTaskProducts(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks', 'GET', {
                process_slug    : this.process_slug,
                status          : this.task_status,
                customer_id     : (this.job && this.job.customer_id) ? this.job.customer_id : ''
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat = response.data[i];
                        if (dat) {
                            if (dat.id === this.current_task.id) {
                                this.current_task._checked = true;
                            } else {
                                let task: TaskModel;
                                task = new TaskModel();
                                task.clone(dat);
                                this.tasks.push(task);
                            }
                        }
                    }
                }
                this.tasks = ([this.current_task]).concat(this.tasks);
                resolve(this.tasks);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public get num_tasks(): number {
        let total: number;
        total = 0;
        for (let i = 0; i < this.tasks.length; i ++) {
            if (this.tasks[i]._checked) {
                total++;
            }
        }
        return total;
    }

}
