import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {TaskListComponent} from '../../pages/task/list/taskList.component';
import {SwalService} from '../../services/swal.service';

@Component({
    selector: 'shipping-document-modal',
    templateUrl: 'shippingDocument.modal.html',
    styleUrls: ['shippingDocument.modal.scss']
})
export class ShippingDocumentModal implements AfterViewInit {

    @ViewChild('modal', { static: true }) smartModal: NgxSmartModalComponent;

    @Output('submitting') onSubmitting: EventEmitter<any> = new EventEmitter<any>();
    @Output('submit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output('uploaded') onUploaded: EventEmitter<any> = new EventEmitter<any>();

    public customer: any;
    public tasks: any[];
    public parent: TaskListComponent;
    public _all: boolean;

    constructor(
        private swal: SwalService
    ) {
        //
        this.customer = {};
        this.tasks = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._all = false;
        }, 0);
    }

    public onClose(e?: any): void {
        // this.removeRemark();
    }

    public onDismiss(e?: any): void {
        //
    }

    public onOpen(e?: any): void {
        this._all = false;
        for (const task of this.tasks) {
            task._checked = false;
        }
    }

    public onAllChange(e: any): void {
        if (e) {
            for (const task of this.tasks) {
                task._checked = true;
            }
        } else {
            for (const task of this.tasks) {
                task._checked = false;
            }
        }
    }

    public open(customer: any, parent?: any): void {
        this.customer = customer;
        this.tasks = this.customer.children;
        this.parent = parent;
        this.smartModal.open();
    }

    public close(): void {
        this.smartModal.close();
    }

    public submit(): void {
        let tasks: any[];
        tasks = [];
        for (const task of this.tasks) {
            if (task && task._checked) {
                tasks.push(task);
            }
        }
        if (tasks.length) {
            this.swal.confirm('ยืนยันการรวมงานของลูกค้า "' + this.customer.customer_name + '" เป็นจำนวน ' + tasks.length + ' งาน ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.onSubmit.emit({
                            tasks: tasks,
                            customer: this.customer
                        });
                        this.close();
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('เลือกรายการอย่างน้อย 1 รายการ');
        }
    }

}
