import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {MaterialModel} from '../models/material.model';

@Injectable()
export class MaterialService {

    private materials: MaterialModel[];

    constructor(private api: Api) {
        this.reset_material_list();
    }

    public set(list: MaterialModel[]): void {
        this.reset_material_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const material: MaterialModel = new MaterialModel();
                    material.clone(dat);
                    this.materials.push(material);
                }
            }
        }
    }

    public addMaterial(material: MaterialModel): void {
        if (this.materials) {
            let index: number;
            index = this.materials.findIndex(item => item.id === material.id);
            if (index > -1) {
                //
            } else {
                this.materials.push(material);
            }
        }
    }

    public getMaterials(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.materials && this.materials.length > 0) {
                for (let i = 0; i < this.materials.length; i++) {
                    this.materials[i].checked = false;
                }
                resolve(this.materials);
            } else {
                this.api.request('materials', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_material_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.materials.push(dat);
                                }
                            }
                            resolve(this.materials);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_material_list(): void {
        if (this.materials) {
            this.materials.splice(0, this.materials.length);
        } else {
            this.materials = [];
        }
    }

}
