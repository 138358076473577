import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'timer-component',
    templateUrl: 'timer.component.html',
    styleUrls: ['timer.component.scss']
})
export class TimerComponent implements AfterViewInit, OnDestroy {

    @Input('hide') hide: boolean;

    public hours: number;
    public minutes: number;
    public seconds: number;
    private timer_interval: any;

    constructor() {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.start();
        }, 0);
    }

    ngOnDestroy(): void {
        this.stop();
    }

    public start(): void {
        this.time();
        this.timer_interval = setInterval(() => {
            this.time();
        }, 1000);
    }

    public stop(): void {
        if (this.timer_interval) {
            clearInterval(this.timer_interval);
            this.timer_interval = null;
        }
        this.time();
    }

    private time(): void {
        let moment_object: any;
        moment_object = moment(new Date());
        this.hours = moment_object.format('HH');
        this.minutes = moment_object.format('mm');
        this.seconds = moment_object.format('ss');
    }

}
