import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {CADModel} from '../../../../models/cad.model';
import {Api} from '../../../../now/api/api';
import {ProductModel} from '../../../../models/product.model';
import {DocumentModel} from '../../../../models/document.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import {DrawingModel} from '../../../../models/drawing.model';

@Component({
    selector: 'app-cad-detail-component',
    templateUrl: 'cadDetail.component.html',
    styleUrls: ['cadDetail.component.scss']
})
export class CadDetailComponent implements OnInit {

    public cad: CADModel;
    public product: ProductModel;
    public tmp_cad: CADModel;
    public task: TaskModel;
    public drawings: DrawingModel[];
    public tmp_drawing: DrawingModel;
    public cad_index: number;

    constructor(public bsModalRef: BsModalRef, private api: Api, private modelApi: ModelApi, private swal: SwalService) {
        this.task = new TaskModel();
        this.tmp_cad = new CADModel();
        this.tmp_cad.document = new DocumentModel();
        this.drawings = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.cad) {
                this.tmp_cad.clone(this.bsModalRef.content.cad);
                this.cad = this.bsModalRef.content.cad;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.product) {
                this.product = this.bsModalRef.content.product;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.cad_index > -1) {
                this.cad_index = this.bsModalRef.content.cad_index;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                //
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.drawings) {
                this.drawings = this.bsModalRef.content.drawings;
            }
            if (this.drawings && this.cad && this.cad.drawing_no && this.drawings.length > 0) {
                let index: number;
                index = this.drawings.findIndex(i => i.drawing_no === this.cad.drawing_no);
                if (index > -1) {
                    this.tmp_drawing = this.drawings[index];
                }
            }
        }, 0);
    }

    public delete(): void {
        this.swal.confirm('คุณต้องการลบเอกสาร "' + this.tmp_cad.document.name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.api.request('products/cad/delete', 'POST', {}, {
                        id: this.cad.id
                    }).subscribe((response: any): void => {
                        if (this.product && this.cad_index > -1) {
                            this.product.cads.splice(this.cad_index, 1);
                        }
                        this.bsModalRef.hide();
                    }, error => {
                        //
                    });
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (form.valid && this.tmp_drawing && this.tmp_drawing.drawing_no) {
            this.tmp_cad.drawing_no = this.tmp_drawing.drawing_no;
            this.modelApi.update(this.tmp_cad, ['drawing_no', 'name'])
                .subscribe((response: any): void => {
                    if (this.cad) {
                        this.cad.drawing_no = this.tmp_drawing.drawing_no;
                        // this.cad.clone(this.tmp_cad);
                    }
                    this.bsModalRef.hide();
                }, error => {
                    //
                });
        } else {
            //
        }
    }

}
