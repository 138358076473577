import {AfterViewInit, Component, NgZone} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {WarehouseModel} from '../../../../../models/warehouse.model';
import {ShelfModel} from '../../../../../models/shelf.model';
import {Api} from '../../../../../now/api/api';
import {SwalService} from '../../../../../services/swal.service';
import {LoaderService} from '../../../../../components/loader/loader.service';

@Component({
    selector: 'store-warehouse-component',
    templateUrl: 'storeWarehouse.component.html',
    styleUrls: ['storeWarehouse.component.scss']
})
export class StoreWarehouseComponent implements AfterViewInit {

    public item: any;
    public warehouse: WarehouseModel;
    public shelf: ShelfModel;
    public requisition_id: any;

    constructor(
        public bsModalRef: BsModalRef,
        private ngZone: NgZone,
        private api: Api,
        private swal: SwalService,
        private loader: LoaderService
    ) {
        //
        this.warehouse = new WarehouseModel();
        this.shelf = new ShelfModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.item) {
                this.item = this.bsModalRef.content.item;
                this.getWarehouse()
                    .then(() => {
                        //
                    });
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.requisition_id) {
                this.requisition_id = this.bsModalRef.content.requisition_id;
            }
        }, 0);
    }

    public getWarehouse(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('store/items/view/' + this.item.id, 'GET', {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (response.data && response.data.warehouses && response.data.warehouses[0]) {
                            this.warehouse = new WarehouseModel();
                            this.warehouse.clone(response.data.warehouses[0]);
                        }
                        if (response.data && response.data.shelves && response.data.shelves[0]) {
                            this.shelf = new ShelfModel();
                            this.shelf.clone(response.data.shelves[0]);
                        }
                        resolve(response.data);
                    } else {
                        resolve({});
                    }
                }, error => {
                    resolve({});
                });
        });
        return promise;
    }

    public onSelectWarehouse(e: any): void {
        this.warehouse.id = (e && e.id) ? e.id : null;
        this.shelf = new ShelfModel();
    }

    public onShelfChange(e: any): void {
        this.shelf.id = (e && e.id) ? e.id : null;
    }

    public onSubmit(form: NgForm): void {
        if (!this.warehouse || !this.warehouse.id) {
            this.swal.danger('โปรดระบุคลังเก็บ');
        } else if (!this.shelf || !this.shelf.id) {
            this.swal.danger('โปรดระบุชั้นวางสินค้า');
        } else {
            this.loader.show();
            this.api.request('store/return', 'POST', {}, {
                id              : this.item.id,
                warehouse_id    : this.warehouse.id,
                shelf_id        : this.shelf.id,
                amount          : this.item.pivot.amount,
                requisition_id  : this.requisition_id
            }).subscribe((response: any): void => {
                this.loader.hide();
                this.bsModalRef.content.submit = true;
                this.bsModalRef.hide();
            });
        }
    }

}
