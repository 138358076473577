import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {AuthService} from '../../../now/user/auth.service';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {CarrierService} from '../../../services/carrier.service';
import {PusherService} from '../../../services/pusher.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {UserService} from '../../../services/user.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {SwalService} from '../../../services/swal.service';
import {DOCUMENT, Location} from '@angular/common';
import {TaskModel} from '../../../models/task.model';
import {environment} from '../../../environments/environment';
import {ViewTask} from '../view/viewTask';
import {RequisitionModel} from '../../../models/requisition.model';
import {StoreService} from '../../../services/store.service';
import {MaterialModel} from '../../../models/material.model';
import {MaterialCreateModal} from '../view/product/materialCreate/materialCreate.modal';
import {ProductMaterial} from '../../../models/productMaterial';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {JobService} from '../../../services/job.service';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'prepare-store-task-component',
    templateUrl: 'prepareStoreTask.component.html',
    styleUrls: ['prepareStoreTask.component.scss']
})
export class PrepareStoreTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;

    public current_job_document_path: string;
    public pdfInfo: any;
    public numPages: number[];
    public job_id: string;

    public product: ProductModel;
    public product_id: string;
    public task_id: string;
    public current_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public job: JobModel;
    public requisition_id: string;
    public requisition: RequisitionModel;
    public job_no: string;
    public materials: any[];

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        public router: Router,
        private storeService: StoreService,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private jobService: JobService,
        private ngZone: NgZone,
        public api: Api,
        public modal: ModalService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });
        this.materials = [];
    }

    private init(): void {
        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.requisition = new RequisitionModel();
    }

    public onTypeaheadDelete(model: any, type: string, e?: any): void {
        this.ngZone.run(() => {
            model.s_width = 0;
            model.s_length = 0;
            model.s_height = 0;
            model.s_depth = 0;
            model.s_weight = 0;
            model.s_outer_diameter = 0;
            model.s_inner_diameter = 0;
            model.s_diameter = 0;
            model.price = 0;
            model.pivot = {
                amount: 0
            };
            model.item_type = null;
            model.material_type = null;
            model.modelable_id = null;
            model.modelable_type = null;
        });
    }

    public showMaterialDetail(material: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : true,
            editable        : false,
            type            : 'frw',
            item_id         : material.item.id,
            item_name       : material.item.search_value,
            material_model  : material.item,
            current_item    : material.item
        }, {
            //
        }).then((data: any): void => {
            //
        });
    }

    private getCheckedMaterials(materials: ProductMaterial[]): any[] {
        let checked_materials: any[];
        checked_materials = [];
        if (this.materials) {
            for (let i = 0; i < materials.length; i++) {
                const product_material: ProductMaterial = materials[i];
                checked_materials.push({
                    id: product_material.id,
                    prepared_status: product_material.prepared_status,
                    cutting_status: product_material.cutting_status,
                    guid: product_material.guid,
                    price: product_material.price,
                    amount: product_material.amount,
                    parent_id: product_material.parent_id,
                    shipping_price: product_material.shipping_price,
                    profit_percentage: product_material.profit_percentage,
                    current_role: product_material.current_role,
                    checked: product_material.checked,
                    job_id: product_material.job_id,
                    remark: product_material.remark,
                    // material_id is item_id field in items table
                    material_id: (product_material && product_material.item) ? product_material.item.id : ''
                });
                if (product_material && product_material.children && product_material.children.length) {
                    checked_materials = checked_materials.concat(this.getCheckedMaterials(product_material.children));
                }
            }
        }
        return checked_materials;
    }

    public onMaterialTypeaheadSelect(material: MaterialModel, e?: any): void {
        //
    }

    public onClickCreateMaterial(product_material: ProductMaterial, e?: any): void {
        this.modal.show(MaterialCreateModal, {
            item_name       : product_material.item.search_value,
            material_model  : product_material.item
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e) {
                    e.select(product_material);
                    product_material.material_id = data.item.id;
                    product_material.item = data.item;
                }
            } else {
                //
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.getTask()
                            .then(() => {
                                this.onSuccess(this.task);
                            });
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public onSuccess(data: any): void {
        this.current_tab = '#manufacture';
        if (data.modelable_type === 'App\\Requisition') {
            this.requisition_id = data.modelable_id;
            this.getRequisition(this.requisition_id)
                .then(() => {
                    if (this.job_id) {
                        this.materials = this.requisition.materials;
                        this.getJob(this.job_id)
                            .then(() => {
                                this.product_id = this.job.product_id;
                                this.getProductDetail()
                                    .then(() => {
                                        this.viewer.manufacture(this.job, false, this.job.revision)
                                            .then(path => {
                                                this.current_job_document_path = path;
                                                this.doReady();
                                            });
                                    });
                            });
                    } else {
                        //
                    }
                });
        } else if (data.modelable_type === 'App\\Job') {
            this.job_id = data.modelable_id;
            this.getJob(this.job_id)
                .then(() => {
                    this.product_id = this.job.product_id;
                    this.getProductDetail()
                        .then(() => {
                            this.viewer.manufacture(this.job, false, this.job.revision)
                                .then(path => {
                                    this.current_job_document_path = path;
                                    this.materials = this.product.materials;
                                    this.initMaterials();
                                    this.doReady();
                                });
                        });
                });
        }
    }

    public initMaterials(): void {
        for (let i = 0; i < this.materials.length; i++) {
            const material: MaterialModel = this.materials[i];
            if (material && material.id && material.replacement_id) {
                material.replacement = this.materials.find(j => j.id === material.replacement_id);
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public getRequisition(requisition_id): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('requisitions/' + requisition_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.requisition.clone(response.data);
                        this.job_id = this.requisition.job_id;
                        this.job_no = this.requisition.job_no;
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onReportRemarkModalSubmit(e: any): void {
        this.jobService.reportJob(this.job, e.remark_message)
            .then(() => {
                this.taskService.cancel(this.task)
                    .then(() => {
                        this.taskService.setStatus(this.job.task_id, null, 'create_ncr', 'dummy', 'dummy', false)
                            .then((task: TaskModel): void => {
                                this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                                    .then(() => {
                                        super.leave(true);
                                    });
                            }, error => {
                                //
                            });
                    });
            });
    }

    public onRemarkModalSubmit(e: any): void {
        this.swal.confirm('ยืนยันการเตรียมวัสดุให้กับ Cutting ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.storeService.prepareMaterial(this.job, e.remark_message)
                        .then(() => {
                            this.swal.success('เตรียมวัสดุให้กับ Cutting สำเร็จ')
                                .then(() => {
                                    this.taskService.complete(this.task)
                                        .then(() => {
                                            this.leave(true);
                                        });
                                });
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public complete(): void {
        for (let i = 0; i < this.materials.length; i++) {
            const material: any = this.materials[i];
            if (material && material.id && material.checked && material.cutting_status === 1 && (material.prepared_status === 1 || material.prepared_status === 4 || material.prepared_status === 0 || !material.prepared_status)) {
                this.swal.danger('กรุณาเตรียมการวัสดุให้สำเร็จก่อนส่งดำเนินการต่อไป');
                return;
            }
            if (material.children && material.children.length) {
                for (let j = 0; j < material.children.length; j++) {
                    const child: any = material.children[j];
                    if (child && child.id && child.checked && child.cutting_status === 1 && (child.prepared_status === 1 || child.prepared_status === 4 || child.prepared_status === 0 || child.prepared_status === 0 || !child.prepared_status)) {
                        this.swal.danger('กรุณาเตรียมการวัสดุให้สำเร็จก่อนส่งดำเนินการต่อไป');
                        return;
                    }
                }
            }
        }
        this.remarkModal.open();
    }

    public onPdfLoadCompleted(e: any): void {
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    private reset_products(): void {
        if (this.products) {
            this.products.splice(0, this.products.length);
        } else {
            this.products = [];
        }
    }

    public onSaveApiCallingBack(e: any): void {
        // this.save(true);
    }

}
