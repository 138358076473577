import {Model} from '../now/model';
import {CustomerModel} from './customer.model';

export class ContactModel extends Model {

    public customer_id: string;

    public full_name: string;
    public avatar: string;
    public position: string;
    public telephone: string;
    public telephone2: string;
    public fax: string;
    public email: string;
    public email2: string;
    public lineID: string;
    public address: string;
    public city: string;
    public state: string;
    public country: string;
    public postal_code: string;
    public note: string;

    public department: string;

    public modelable_type: string;
    public modelable_id: string;

    // public customer: CustomerModel;

    constructor() {
        super('contacts');
    }

    public clone(data: any): Model {
        // if (data && data.customer) {
        //     let customer: CustomerModel;
        //     customer = new CustomerModel();
        //     customer.clone(data.customer);
        //     delete data.customer;
        // }
        super.clone(data);
        return this;
    }

}
