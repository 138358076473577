import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {MachineModel} from '../../../../models/machine.model';

@Component({
    selector: 'method-modal-component',
    templateUrl: 'methodModal.component.html',
    styleUrls: ['methodModal.component.scss']
})
export class MethodModalComponent implements AfterViewInit {

    public machine: MachineModel;
    public method_name: string;

    constructor(public bsModalRef: BsModalRef) {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.machine) {
                this.method_name = null;
                this.machine = this.bsModalRef.content.machine;
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid && this.method_name) {
            this.method_name = this.method_name.toLowerCase();
            this.machine.methods['_' + this.method_name] = true;
            this.bsModalRef.hide();
        } else {
            //
        }
    }

}
