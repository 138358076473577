import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'machine-type-modal',
    templateUrl: 'machineType.modal.html',
    styleUrls: ['machineType.modal.scss']
})
export class MachineTypeModal implements OnInit, OnDestroy {

    @Input() type: string;

    public current_item;
    public is_getting: boolean;
    public is_got: boolean;
    public canBack: boolean;

    public pages = [
        {
            key         : 'atl',
            display     : 'วัสดุอุปกรณ์โรงงาน'
        },
        {
            key         : 'sn',
            display     : 'สินค้าสำเร็จรูป'
        },
        {
            key         : 'fct',
            display     : 'Cutting Tools'
        },
        {
            key         : 'ost',
            display     : 'ค่าอุปกรณ์ เครื่องเขียน แบบพิมพ์'
        },
        {
            key         : 'mc',
            display     : 'เครื่องจักร'
        }
    ];

    constructor(
        public bsModalRef: BsModalRef
    ) {
        this.canBack = false;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    public onCancelSearching(e?: any): void {
        //
    }

    public onSelectedItem(e?: any): void {
        this.current_item = e;
        this.bsModalRef.content.submit = true;
        this.bsModalRef.content.item = e;
        this.bsModalRef.content.type = this.type;
        this.bsModalRef.hide();
    }

    public back() {
        this.type = undefined;
    }

    public chooseType(page) {
        this.canBack = true;
        this.type = page.key;
    }

}
