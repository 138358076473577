import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {RoleModel} from '../../../../models/role.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import * as moment from 'moment';
import {AppService} from '../../../../app/app.service';
import {SchedulerHistoryModel} from '../../../../models/schedulerHistory.model';
import {FullCalendarComponent} from '../../../../components/fullCalendar/fullCalendar.component';
import {ModalService} from '../../../../services/modal.service';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../../environments/environment';
import {CarrierModel} from '../../../../models/carrier.model';
import {QuotationModel} from '../../../../models/quotation.model';
import {IncotermModel} from '../../../../models/incoterm.model';
import {IncotermService} from '../../../../services/incoterm.service';
import {Location} from '@angular/common';

@Component({
    selector: 'carrier-view-component',
    templateUrl: 'carrierView.component.html',
    styleUrls: ['carrierView.component.scss']
})
export class CarrierViewComponent implements OnInit {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    public all_role: boolean;

    public incoterms: IncotermModel[];

    public carrier: CarrierModel;
    public carrier_id: string;
    public current_tab: string;

    public roles: RoleModel[];
    public tasks: any[];

    public scheduler_histories: SchedulerHistoryModel[];

    public today: string;
    public absence_num: number;
    public leave_num: number;
    public late_num: number;

    public carrier_events: any[];
    public ready: boolean;
    public options: any;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private appService: AppService,
                private modelApi: ModelApi,
                private incotermService: IncotermService,
                private location: Location,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.carrier = new CarrierModel();
        this.route.params
            .subscribe(params => {
                this.carrier_id = params['id'];
            });

        this.incoterms = [];
        this.today = moment(new Date()).format('MMMM YYYY');
        this.current_tab = 'profile';

        this.ready = false;
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getIncoterms();
            this.getCarrier()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                //
                this.incoterms = incoterms;
            });
    }

    public getCarrier(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.carrier_id) {
                console.log(this.carrier_id);
                this.api.request('carriers/' + this.carrier_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.carrier.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.carrier);
            }
        });
        return promise;
    }
    
    public save(): void {
        if (!this.carrier.name) {
            this.swal.danger('กรุณากรอกข้อมูลชื่อผู้ขนส่ง');
        } else if (!this.carrier.ranking) {
            this.swal.danger('กรุณาระบุ RANKING ของผู้ขนส่ง');
        } else {
            this.modelApi.update(this.carrier, [
                // profile
                'avatar', 'name', 'tax_no', 'branch', 'ranking', 'credit_terms',
                'telephone', 'telephone2', 'email', 'fax', 'email', 'website', 'address',
                'city', 'state', 'postal_code', 'country', 'state', 'shipping_day',
                'shipping_id'
            ]).subscribe((response: any): void => {
                this.swal.success('บันทึกข้อมูลผู้ขนส่งสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public onUploadedSuccess(data: any): void {
        if (data && data.preview) {
            this.carrier.avatar = data.preview;
            this.modelApi.update(this.carrier, ['avatar'])
                .subscribe(() => {});
        }
    }

    public onUploadError(error: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('carriers/document', 'PUT', {}, {
            document: data,
            id: this.carrier.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.carrier.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            user: this.carrier,
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentPreview(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        window.open(environment.api_host + 'view/' + doc.id + '/carrier/document/', '_blank');
    }

    public onStartUploader(data: any): void {
        if (this.carrier && this.carrier.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
        }
    }

    public createCarrier(): void {
        if (!this.carrier.name) {
            this.swal.danger('กรุณากรอกข้อมูลชื่อผู้ขนส่ง');
        } else if (!this.carrier.ranking) {
            this.swal.danger('กรุณาระบุ RANKING ของผู้ขนส่ง');
        } else {
            this.modelApi.createOrUpdate(this.carrier, [
                'avatar', 'name', 'tax_no', 'branch', 'ranking', 'credit_terms',
                'telephone', 'telephone2', 'email', 'fax', 'email', 'website', 'address',
                'city', 'state', 'postal_code', 'country', 'state', 'shipping_day',
                'shipping_id'
            ]).subscribe((response: any): void => {
                this.swal.success('บันทึกข้อมูลผู้ขนส่งสำเร็จ');
                this.location.replaceState('/database/carrier/' + this.carrier.id + '/detail');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

}
