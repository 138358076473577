import {AfterViewInit, Component, Inject, NgZone, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import * as moment from 'moment';
import {TaskService} from '../../../services/task.service';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {TypeaheadComponent} from '../../../components/typeahead/typeahead.component';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {ContactModel} from '../../../models/contact.model';
import {BillModel} from '../../../models/bill.model';
import {DocumentModel} from '../../../models/document.model';
import {LoaderService} from '../../../components/loader/loader.service';
import {JobService} from '../../../services/job.service';
import {TaxInvoiceModel} from '../../../models/taxInvoice.model';
import {CustomerAddressModel} from '../../../models/customerAddress.model';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ShippingDocument} from '../../../app/api/shippingDocument';
import {JobModel} from '../../../models/job.model';
import {InvoiceModel} from '../../../models/invoice.model';
import {DepositModel} from '../../../models/deposit.model';
import {DeliveryNoteModel} from '../../../models/deliveryNote.model';
import {ReceiptModel} from '../../../models/receipt.model';
import {PackingSlipModel} from '../../../models/packingSlip.model';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'payment-task-component',
    templateUrl: 'paymentTask.component.html',
    styleUrls: ['paymentTask.component.scss'],
    providers: [DecimalPipe]
})
export class PaymentTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChildren(TypeaheadComponent) typeaheadComponents: QueryList<TypeaheadComponent>;

    public tax_invoice_id: string;
    public tax_invoice: TaxInvoiceModel;
    public products: any[];
    public customer: CustomerModel;
    public customer_address: CustomerAddressModel;
    public bill: BillModel;
    public carrier: CarrierModel;
    public purchase_order_id: string;
    public purchase_order: PurchaseOrderModel;

    public tax_invoices: TaxInvoiceModel[];
    public sale: UserModel;
    public tmp_customer: CustomerModel;
    public tmp_product: any;
    public tmp_customer_address: any;
    public tmp_contact: any;
    public tmp_sale: any;
    public current_tab: string;
    public incoterms: string[];
    public carriers: CarrierModel[];
    public tmp_incoterm: any;
    public tmp_carrier: any;
    public tmp_currency: any;
    public currency_list: any[];
    public tmp_contact_ref: any;
    public new_tasks: TaskModel[];
    public shipping_document: ShippingDocument;
    public shipping_documnet_id: any;
    public models = [];

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private loader: LoaderService,
        private swal: SwalService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private ngZone: NgZone,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        public location: Location,
        private route: ActivatedRoute,
        private jobService: JobService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.current_tab = '#information';
        this.tmp_customer = new CustomerModel();
        // this.customer = new CustomerModel();
        this.tax_invoice = new TaxInvoiceModel();
        this.purchase_order = new PurchaseOrderModel();
        this.sale = new UserModel();
        this.tmp_product = {};
        this.tmp_customer_address = {};
        this.tmp_sale = new UserModel();
        this.incoterms = [];
        this.carriers = [];
        this.tax_invoices = [];
        this.tmp_incoterm = {};
        this.tmp_carrier = {};
        this.tmp_currency = {};
        this.tmp_contact_ref = {};

        this.shipping_document = {
            tax_invoices: [],
            deposits: [],
            packing_lists: [],
            receipts: [],
            delivery_notes: [],
            invoices: [],
            documents: [],
            debit_notes: [],
            credit_notes: []
        };

        this.customer = new CustomerModel();
        this.customer_address = new CustomerAddressModel();
        this.products = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                if (this.task.modelable_type === 'App\\ShippingDocument') {
                                    this.shipping_documnet_id = this.task.modelable_id;
                                    this.getShippingDocument()
                                        .then(() => {
                                            if (!this.shipping_document.currency) {
                                                this.shipping_document.currency = 'THB';
                                                this.shipping_document.currency_rate = 1;
                                            }
                                            this.tmp_currency = {
                                                id: this.shipping_document.currency,
                                            };
                                            if (this.shipping_document.jobs && this.shipping_document.jobs.length) {
                                                for (const job of this.shipping_document.jobs) {
                                                    this.models.push(Object.assign({ type: 'job' }, job));
                                                }
                                            } else if (this.shipping_document.products && this.shipping_document.products.length) {
                                                for (const product of this.shipping_document.products) {
                                                    this.models.push(Object.assign({
                                                        type: 'product',
                                                        purchase_order_no: product.pivot.purchase_order_no,
                                                        payment_id: product.pivot.payment_id,
                                                    }, product));
                                                }
                                            }
                                            this.cal_total_price();
                                            this.doReady();
                                        });
                                } else {
                                    console.error(this.task.modelable_type + ' is unknow.');
                                }
                                /*if (this.task.modelable_type === 'App\\TaxInvoice') {
                                    this.tax_invoice_id = this.task.modelable_id;
                                    this.getTaxInvoice()
                                        .then(() => {
                                            this.doReady();
                                        });
                                } else if (this.task.modelable_type === 'App\\PurchaseOrder') {
                                    this.purchase_order_id = this.task.modelable_id;
                                    this.getPurchaseOrder()
                                        .then(() => {
                                            this.doReady();
                                        });
                                }*/
                            });
                    }
                    // this.shipping_document.task_id = this.task_id;
                });

            this.getIncoterms();
            this.getCarriers();
            this.getCurrencies();
        }, 0);
    }

    public getShippingDocument(): Promise<ShippingDocument> {
        let promise: Promise<ShippingDocument>;
        promise = new Promise<ShippingDocument>((resolve, reject) => {
            this.api.request('shipping/documents/' + this.shipping_documnet_id, 'GET', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.shipping_document = response.data;
                        // this.shipping_document.jobs = [];
                        let jobs: JobModel[];
                        jobs = [];
                        if (response.data.jobs) {
                            for (const dat_job of response.data.jobs) {
                                if (dat_job && dat_job.id) {
                                    let job: JobModel;
                                    job = new JobModel();
                                    let product: ProductModel;
                                    product = new ProductModel();
                                    product.clone(dat_job.product);
                                    job.clone(dat_job);
                                    job.product = product;
                                    jobs.push(job);
                                }
                            }
                        }

                        this.shipping_document.jobs = jobs;

                        this.customer = new CustomerModel();
                        this.bill = new BillModel();
                        this.customer_address = new CustomerAddressModel();
                        this.carrier = new CarrierModel();

                        if (this.shipping_document.customer && this.shipping_document.customer.id) {
                            this.customer.clone(response.data.customer);
                            this.shipping_document.customer = this.customer;
                        }
                        if (this.shipping_document.bill && this.shipping_document.bill.id) {
                            this.bill.clone(response.data.bill);
                            this.shipping_document.bill = this.bill;
                        }
                        if (this.shipping_document.customer_address && this.shipping_document.customer_address.id) {
                            this.customer_address.clone(response.data.customer_address);
                            this.shipping_document.customer_address = this.customer_address;
                        }
                        if (this.shipping_document.carrier && this.shipping_document.carrier.id) {
                            this.carrier.clone(response.data.carrier);
                            this.shipping_document.carrier = this.carrier;
                        }

                        let tax_invoices: TaxInvoiceModel[];
                        tax_invoices = [];
                        if (response.data.tax_invoices) {
                            for (const dat_tax_invoice of response.data.tax_invoices) {
                                if (dat_tax_invoice && dat_tax_invoice.id) {
                                    let tax_invoice: TaxInvoiceModel;
                                    tax_invoice = new TaxInvoiceModel();
                                    tax_invoice.clone(dat_tax_invoice);
                                    tax_invoices.push(tax_invoice);
                                }
                            }
                        }
                        this.shipping_document.tax_invoices = tax_invoices;

                        let invoices: InvoiceModel[];
                        invoices = [];
                        if (response.data.invoices) {
                            for (const dat_invoice of response.data.invoices) {
                                if (dat_invoice && dat_invoice.id) {
                                    let invoice: InvoiceModel;
                                    invoice = new InvoiceModel();
                                    invoice.clone(dat_invoice);
                                    invoices.push(invoice);
                                }
                            }
                        }
                        this.shipping_document.invoices = invoices;

                        let deposits: DepositModel[];
                        deposits = [];
                        if (response.data.deposits) {
                            for (const dat_deposit of response.data.deposits) {
                                if (dat_deposit && dat_deposit.id) {
                                    let deposit: DepositModel;
                                    deposit = new DepositModel();
                                    deposit.clone(dat_deposit);
                                    deposits.push(deposit);
                                }
                            }
                        }
                        this.shipping_document.deposits = deposits;

                        let delivery_notes: DeliveryNoteModel[];
                        delivery_notes = [];
                        if (response.data.delivery_notes) {
                            for (const dat_delivery_note of response.data.delivery_notes) {
                                if (dat_delivery_note && dat_delivery_note.id) {
                                    let delivery_note: DeliveryNoteModel;
                                    delivery_note = new DeliveryNoteModel();
                                    delivery_note.clone(dat_delivery_note);
                                    delivery_notes.push(delivery_note);
                                }
                            }
                        }
                        this.shipping_document.delivery_notes = delivery_notes;

                        let receipts: ReceiptModel[];
                        receipts = [];
                        if (response.data.receipts) {
                            for (const dat_receipt of response.data.receipts) {
                                if (dat_receipt && dat_receipt.id) {
                                    let receipt: ReceiptModel;
                                    receipt = new ReceiptModel();
                                    receipt.clone(dat_receipt);
                                    receipts.push(receipt);
                                }
                            }
                        }
                        this.shipping_document.receipts = receipts;

                        let packing_lists: PackingSlipModel[];
                        packing_lists = [];
                        if (response.data.packing_lists) {
                            for (const dat_packing_list of response.data.packing_lists) {
                                if (dat_packing_list && dat_packing_list.id) {
                                    let packing_list: PackingSlipModel;
                                    packing_list = new PackingSlipModel();
                                    packing_list.clone(dat_packing_list);
                                    packing_lists.push(packing_list);
                                }
                            }
                        }
                        this.shipping_document.packing_lists = packing_lists;

                        let documents: DocumentModel[];
                        documents = [];
                        if (response.data.documents) {
                            for (const dat_document of response.data.documents) {
                                if (dat_document && dat_document.id) {
                                    let document: DocumentModel;
                                    document = new DocumentModel();
                                    document.clone(dat_document);
                                    documents.push(document);
                                }
                            }
                        }
                        this.shipping_document.documents = documents;

                        if (response.data.debit_notes) {
                            this.shipping_document.debit_notes = response.data.debit_notes;
                        } else {
                            this.shipping_document.debit_notes = [];
                        }

                        if (response.data.credit_notes) {
                            this.shipping_document.credit_notes = response.data.credit_notes;
                        } else {
                            this.shipping_document.credit_notes = [];
                        }

                        resolve(this.shipping_document);
                    } else {
                        resolve({});
                    }
                }, error => {
                    resolve({});
                });
        });
        return promise;
    }

    ngOnDestroy(): void {
        //
    }

    public viewAllLabel(): void {
        if (this.shipping_document && this.shipping_document.id) {
            this.viewer.labelByShippingDocument(this.shipping_document);
        } else {
            //
        }
    }

    public viewPO(): void {
        if (this.shipping_document && this.shipping_document.id) {
            this.viewer.paymentByShippingDocument(this.shipping_document);
        } else {
            //
        }
    }

    public onDiscountPercentChange(e?: any): void {
        setTimeout(() => {
            this.cal_total_price();
        }, 0);
    }

    public onDiscountPriceChange(e?: any): void {
        setTimeout(() => {
            this.cal_total_price();
        }, 0);
    }

    /*public getTaxInvoice(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.shipping_document.products) {
                this.shipping_document.products.splice(0, this.shipping_document.products.length);
            } else {
                this.shipping_document.products = [];
            }
            if (this.tax_invoice_id) {
                this.api.request('tax/invoices/' + this.tax_invoice_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            this.shipping_document.clone(response.data);
                            this.tax_invoices = [this.tax_invoice];
                        }
                        // this.shipping_document.cal_total_price();
                        this.customer = this.shipping_document.customer;
                        this.customer_address = this.shipping_document.customer_address;
                        this.products = this.shipping_document.products;
                        resolve(this.tax_invoice);
                    }, error => {
                        reject(error);
                    });
            } else {
                //
            }
        });
        return promise;
    }*/

    /*public getPurchaseOrder(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.purchase_order_id) {
                this.api.request('purchase/orders/' + this.purchase_order_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            this.purchase_order.clone(response.data);
                        }
                        this.customer = this.purchase_order.customer;
                        this.customer_address = this.purchase_order.customer_address;
                        this.products = this.purchase_order.purchases;
                        resolve(this.purchase_order);
                    }, error => {
                        reject(error);
                    });
            } else {
                //
            }
        });
        return promise;
    }*/

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json', 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    public onCustomerChange(e: any): void {
        this.shipping_document.customer_id = (e && e.id) ? e.id : null;
        if (e && e.addresses && e.addresses.length) {
            this.shipping_document.customer_address.clone(e.addresses[0]);
            this.shipping_document.customer_address_id = e.addresses[0].id;
        } else {
            //
        }
        if (e && e.bills && e.bills.length) {
            this.shipping_document.bill.clone(e.bills[0]);
            this.shipping_document.bill_id = e.bills[0].id;
        } else {
            //
        }
    }

    public onCustomerBillChange(e: any): void {
        this.shipping_document.bill_id = (e && e.id) ? e.id : null;
    }

    public onContactChange(e: any): void {
        this.shipping_document.contact_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressChange(e: any): void {
        this.shipping_document.customer_address_id = (e && e.id) ? e.id : null;
    }

    public onCarrierChange(e: any): void {
        this.shipping_document.carrier_id = (e && e.id) ? e.id : null;
    }

    public onSelectIncotermCode(e: any): void {
        //
    }

    private getCurrencyRate(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const current_currency_code: string = this.shipping_document.currency;
            this.api.request('currency/covert', 'GET', {
                from: 'THB',
                to: current_currency_code
            }).subscribe((response: any): void => {
                if (response && response.data && response.data['THB_' + current_currency_code]) {
                    resolve(response.data['THB_' + current_currency_code].val);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = [];
                for (const incoterm of incoterms) {
                    this.incoterms.push(incoterm.name);
                }
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    /*public onProductDelete(product: ProductModel, e?: any): void {
        if (product) {
            product.name = '';
            product.market_price = 0;
            product.price = 0;
            product.customer_product_amount = 0;
            product.description = '';
        } else {
            //
        }
    }*/

    /*public onProductSelect(product: ProductModel, data: any): void {
        if (!product.wh) {
            product.wh = 'TH';
        }
    }*/

    public addRemark(product: ProductModel): void {
        this.modal.show(RemarkModalComponent, {
            description: product.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                product.description = content.description;
                this.save(true);
            } else {
                //
            }
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('tax/invoices/documents', 'PUT', {}, {
            document    : data,
            id          : this.shipping_document.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                if (!this.shipping_document.documents) {
                    this.shipping_document.documents = [];
                }
                this.shipping_document.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    private send_quotation(document_ids?: string[], drawing_ids?: string[], requirement_ids?: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let to_role: string;
            let to_process: string;
            console.log('CREDIT TERMS [' + this.shipping_document.customer.name + '] : ' + this.shipping_document.customer.credit_terms);
            if (this.shipping_document.customer && this.shipping_document.customer.credit_terms > 0) {
                to_role = 'quotation-2';
                to_process = this.task.process_slug;
            } else {
                to_role = 'payment';
                to_process = 'deposit';
            }
            this.taskService.setStatus(this.task, 2, 'send_quotation', to_role, to_process)
                .then((task_response: any): void => {
                    this.api.request('mail/quotation', 'POST', null, {
                        task_id         : this.task.id,
                        quotation_id    : this.shipping_document.id,
                        document_ids    : document_ids,
                        drawing_ids     : drawing_ids,
                        requirement_ids : requirement_ids
                    }).subscribe((response: any): void => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
                });
        });
        return promise;
    }

    public onSelectTypeaheadShipping(model: any, data: any, field1: string, field2: string): void {
        if (model && field1 && field2 && data) {
            model[field1] = data[field2];
        }
    }

    public onSelectTypeaheadCurrency(data: any): void {
        this.shipping_document.currency_symbol = data.currencySymbol;
        this.shipping_document.currency = data.id;
        if (this.shipping_document && this.shipping_document.currency) {
            this.getCurrencyRate()
                .then((response: any): void => {
                    this.shipping_document.currency_rated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    this.shipping_document.currency_rate = response;
                });
        }
    }

    /*public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name) {
            model_parent[field_name] = data.id;
        }
    }

    public onSelectTypeaheadProduct(model: any, data: any, model_parent?: any, field_name?: string): void {
        this.onSelectTypeahead(model, data, model_parent, field_name);
        if (model) {
            model.amount = 1;
            model.price = Math.max(model.market_price, model.price, model.cost);
        }
        this.shipping_document.cal_total_price();
    }

    public onSelectCustomerTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.tmp_customer_address = data.addresses[0];
        }
        this.onSelectTypeahead(model, data, model_parent, field_name);
    }*/

    public onBillTypeaheadDeleted(event?: any): void {
        this.shipping_document.bill = new BillModel();
    }

    public onSaleTypeaheadDeleted(event?: any): void {
        // this.shipping_document.sale = new UserModel();
    }

    public onCustomerTypeaheadDeleted(event?: any): void {
        this.shipping_document.customer = new CustomerModel();
        this.shipping_document.customer.credit_terms = 0;

        this.shipping_document.contact = new ContactModel();
        this.shipping_document.customer_address = new CustomerAddressModel();
    }

    public onCustomerAddressTypeaheadDeleted(event?: any): void {
        this.shipping_document.customer_address = new CustomerAddressModel();
    }

    public onBlur(e: any): void {
        this.cal_total_price();
    }

    public productPriceChange(product: ProductModel, e?: any): void {
        //
    }

    public onProductPriceBlur(e?: any): void {
        this.vatUpdate();
    }

    public onModelChanged(e?: any): void {
        this.ngZone.run(() => {
            this.cal_total_price();
        });
    }

    public archive(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public save(skip?: boolean): void {
        if (skip === true) {
            //
        } else {
            this.loader.show();
        }
        this._save(skip)
            .then(() => {
                if (skip === true) {
                    //
                } else {
                    this.swal.success('ทำการบันทึกข้อมูลสำเร็จ', null, 2000);
                    this.loader.hide();
                }
            }, error => {
                if (skip === true) {
                    //
                } else {
                    this.swal.danger(error);
                    this.loader.hide();
                }
            });
    }

    private getModels() {
        const model_ids = {};
        for (const model of this.models) {
            if (model && model.id) {
                model_ids[model.id] = {
                    type: model.type,
                    amount: model.pivot.amount,
                    price_per_unit: model.pivot.price_per_unit
                };
            }
        }
        console.log(model_ids);
        return model_ids;
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        for (const job of this.shipping_document.jobs) {
            if (job && job.id) {
                job_ids[job.id] = {
                    amount: job.pivot.amount,
                    price_per_unit: job.pivot.price_per_unit
                };
            }
        }
        return job_ids;
    }

    private _save(skip?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('shipping/documents', 'POST', {}, {
                id: this.shipping_documnet_id,
                contact_id: this.shipping_document.contact_id,
                customer_id: this.shipping_document.customer_id,
                customer_address_id: this.shipping_document.customer_address_id,
                bill_id: this.shipping_document.bill_id,
                carrier_id: this.shipping_document.carrier_id,
                incoterm_code: this.shipping_document.incoterm_code,
                currency: this.shipping_document.currency,
                currency_symbol: this.shipping_document.currency_symbol,
                currency_rate: this.shipping_document.currency_rate,
                currency_rated_at: this.shipping_document.currency_rated_at,
                is_include_vat: this.shipping_document.is_include_vat,
                discount_type: this.shipping_document.discount_type,
                material_price: this.shipping_document.material_price,
                frt_sh: this.shipping_document.frt_sh,
                misc_chgs: this.shipping_document.misc_chgs,
                sub_total: this.shipping_document.sub_total,
                gst_tax: this.shipping_document.gst_tax,
                grand_total: this.shipping_document.grand_total,
                discount_percent: this.shipping_document.discount_percent,
                discount_price: this.shipping_document.discount_price,
                vat_percent: this.shipping_document.vat_percent,
                vat_price: this.shipping_document.vat_price,
                models: this.getModels()
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
            /*if (this.task.modelable_type === 'App\\TaxInvoice') {
                this.shipping_document.product_ids = this.getCheckedProducts();
                const put_or_post_data: string[] = [
                    'shipping_id', 'customer_id', 'contact_id', 'task_id', 'product_ids',
                    'customer_address_id', 'sale_id', 'currency', 'currency_symbol',
                    'currency_rate', 'currency_rated_at', 'delivery_date', 'bill_id',
                    'shipping', 'incoterm_code', 'carrier_id', 'discount_percent', 'vat_percent', 'frt_sh', 'misc_chgs',
                    'contact_ref_id', 'wh', 'requirement_id', 'ref_no', 'is_include_vat',
                    'processing_times', 'discount_type', 'discount_price'
                ];
                this.modelApi.update(this.tax_invoice, put_or_post_data, null, null, null, false)
                    .subscribe((tax_invoice_response: any): void => {
                        if (tax_invoice_response && tax_invoice_response.success === true) {
                            resolve();
                        } else {
                            reject(tax_invoice_response.message);
                        }
                    }, error => {
                        reject(error);
                    });
            } else if (this.task.modelable_type === 'App\\PurchaseOrder') {
                //
            }*/
            resolve({});
        });
        return promise;
    }

    public vatUpdate(e?: any): void {
        setTimeout(() => {
            this.cal_total_price();
        }, 0);
    }

    private valid_all_documents(): boolean {
        if (this.task.current_role === 'shipping-document') {
            let delivery_notes_length;
            let invoices_length;
            delivery_notes_length = 0;
            invoices_length = 0;
            for (let i = 0; i < this.shipping_document.delivery_notes.length; i++) {
                const delivery_note = this.shipping_document.delivery_notes[i];
                if (!delivery_note.document_id && delivery_note.status === 1) {
                    this.swal.danger('"' + delivery_note.delivery_note_no + '" ยังไม่ได้แนบหลักฐานการส่งสินค้า');
                    return false;
                } else if (delivery_note.status !== 2) {
                    delivery_notes_length++;
                }
            }
            // for (let i = 0; i < this.shipping_document.invoices.length; i++) {
            //     const invoice = this.shipping_document.invoices[i];
            //     if (invoice.status !== 2) {
            //         invoices_length++;
            //     }
            // }
            for (let i = 0; i < this.shipping_document.packing_lists.length; i++) {
                const packing_list = this.shipping_document.packing_lists[i];
                if (!packing_list.document_id && packing_list.status === 1) {
                    this.swal.danger('"' + packing_list.packing_slip_no + '" ยังไม่ได้แนบหลักฐานการมอบใบแพ็กสินค้า');
                    return false;
                }
            }
            // if (delivery_notes_length === 0 || invoices_length === 0) {
            //     this.swal.danger('ต้องออกเอกสาร (1) ใบส่งสินค้า และ (2) ใบแจ้งหนี้ เป็นอย่างน้อย ก่อนการส่งต่องานให้กับแผนกบัญชี');
            //     return false;
            // }
            if (delivery_notes_length === 0) {
                this.swal.danger('ต้องออกเอกสาร "ใบส่งสินค้า" เป็นอย่างน้อย ก่อนการส่งต่องานให้กับแผนกบัญชี');
                return false;
            }
        } else if (this.task.current_role === 'finish') {
            for (let i = 0; i < this.shipping_document.tax_invoices.length; i++) {
                const tax_invoice = this.shipping_document.tax_invoices[i];
                if (!tax_invoice.document_id && tax_invoice.status === 1) {
                    this.swal.danger('"' + tax_invoice.tax_invoice_no + '" ยังไม่ได้แนบหลักฐานการส่งมอบใบกำกับภาษี');
                    return false;
                }
            }
            for (let i = 0; i < this.shipping_document.receipts.length; i++) {
                const receipt = this.shipping_document.receipts[i];
                if (!receipt.document_id && receipt.status === 1) {
                    this.swal.danger('"' + receipt.receipt_no + '" ยังไม่ได้แนบหลักฐานการมอบใบเสร็จรับเงิน');
                    return false;
                }
            }
        }
        /*for (let i = 0; i < this.shipping_document.deposits.length; i++) {
            const deposit = this.shipping_document.deposits[i];
            if (!deposit.document_id && deposit.status === 1) {
                this.swal.danger('"' + deposit.deposit_no + '" ยังไม่ได้แนบหลักฐานการส่งมอบใบวางเงินมัดจำ');
                return false;
            }
        }*/
        /*if (!this.shipping_document.delivery_notes || !this.shipping_document.delivery_notes.length) {
            this.swal.danger('ยังไม่ได้แนบหลักฐานการส่งสินค้าจึงไม่สามารถส่งต่อให้แผนกบัญชีได้');
            return false;
        }*/
        /*for (let i = 0; i < this.shipping_document.invoices.length; i++) {
            const invoice = this.shipping_document.invoices[i];
            if (!invoice.document_id && invoice.status === 1) {
                this.swal.danger('"' + invoice.invoice_no + '" ยังไม่ได้แนบหลักฐานการมอบใบแจ้งหนี้');
                return false;
            }
        }*/
        return true;
    }

    public sendToAccount(): void {
        if (this.valid_all_documents()) {
            this.swal.confirm('ยืนยันการส่งต่องานให้กับแผนกบัญชีใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.taskService.setStatus(this.task, this.task.status, 'finish', 'finish', 'finish')
                            .then(() => {
                                this.leave(true);
                                this.swal.success('ส่งต่อให้กับแผนกบัญชีสำเร็จ');
                            }, error => {
                                this.swal.danger(error);
                            });
                    }
                });
        } else {
            // this.swal.danger('ยังไม่ได้แนบเอกสารหลักฐานการส่งสินค้าจึงไม่สามารถส่งต่อให้แผนกบัญชีได้');
        }
    }

    /*public nextProcess(): void {
        if (!this.shipping_document.payments || this.shipping_document.payments.length === 0) {
            this.swal.danger('ยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ');
        } else if (!this.shipping_document.validPayment()) {
            this.swal.danger('หลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง');
        } else {
            this.loader.show();
            this._save()
                .then(() => {
                    this.loader.hide();
                    this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order', 'proforma', 'proforma')
                        .then(() => {
                            this.leave(true);
                            this.swal.success('ส่งต่อให้กับแผนก Sale Admin สำเร็จ');
                        }, error => {
                            this.swal.danger(error);
                        });
                });
        }
    }*/

    /*private getCheckedProducts(): any {
        let checked_products: any[];
        checked_products = [];
        let product_length: number;
        product_length = 0;
        for (let i = 0; i < this.shipping_document.products.length; i++) {
            const _product: ProductModel = this.shipping_document.products[i];
            if (_product && _product.id) {
                product_length++;
                checked_products.push({
                    customer_product_amount : _product.pivot.customer_product_amount,
                    // amount                  : _product.amount,
                    // price                   : _product.price,
                    price_per_unit          : _product.pivot.price_per_unit,
                    // description             : _product.description,
                    item_description        : _product.pivot.item_description,
                    wh                      : _product.pivot.wh,
                    product_id              : _product.id
                });
            }
        }
        return checked_products;
    }*/

    public get total_price(): number {
        if (this.tax_invoice) {
            return this.shipping_document.grand_total;
        }
        return 0;
    }

    public onSaveApiCallingBack(e: any): void {
        this._save(true);
    }

    public cal_total_price(): number {
        const _vat_percent = (this.shipping_document.vat_percent) ? this.shipping_document.vat_percent : 0;

        let material_price_with_vat: number;
        material_price_with_vat = 0;
        this.shipping_document.material_price = 0;
        if (this.models && this.models.length) {
            for (let i = 0; i < this.models.length; i++) {
                const model = this.models[i];
                if (model && model.pivot.price_per_unit && model.pivot.amount) {
                    if (this.shipping_document.is_include_vat === false) {
                        this.shipping_document.material_price += model.pivot.price_per_unit * model.pivot.amount;
                    } else {
                        this.shipping_document.material_price += (model.pivot.price_per_unit * model.pivot.amount) / 1.07;
                        material_price_with_vat += model.pivot.price_per_unit * model.pivot.amount;
                    }
                }
            }
        }
        // if (this.shipping_document.jobs && this.shipping_document.jobs.length) {
        //     for (let i = 0; i < this.shipping_document.jobs.length; i++) {
        //         const job: JobModel = this.shipping_document.jobs[i];
        //         if (job && job.pivot.price_per_unit && job.pivot.amount) {
        //             if (this.shipping_document.is_include_vat === false) {
        //                 this.shipping_document.material_price += job.pivot.price_per_unit * job.pivot.amount;
        //             } else {
        //                 this.shipping_document.material_price += (job.pivot.price_per_unit * job.pivot.amount) / 1.07;
        //                 material_price_with_vat += job.pivot.price_per_unit * job.pivot.amount;
        //             }
        //         }
        //     }
        // }

        const material_price: number = (this.shipping_document.material_price > 0) ? this.shipping_document.material_price : 0;
        const frt_sh: number = (this.shipping_document.frt_sh > 0) ? (this.shipping_document.is_include_vat === false) ? this.shipping_document.frt_sh : this.shipping_document.frt_sh / (1 + (this.shipping_document.vat_percent / 100)) : 0;
        const misc_chgs: number = (this.shipping_document.misc_chgs > 0) ? (this.shipping_document.is_include_vat === false) ? this.shipping_document.misc_chgs : this.shipping_document.misc_chgs / (1 + (this.shipping_document.vat_percent / 100)) : 0;
        const _discount_percent = (this.shipping_document.discount_percent) ? this.shipping_document.discount_percent : 0;
        const sub_total = material_price + frt_sh + misc_chgs;

        if (this.shipping_document.discount_type === 1) {
            this.shipping_document.discount_price = Math.round(((material_price * _discount_percent) / 100) * 100) / 100;
        } else {
            //
        }

        const discount_price_with_vat = Math.round(((material_price_with_vat * _discount_percent) / 100) * 100) / 100;

        if (this.shipping_document.is_include_vat === false) {
            this.shipping_document.sub_total = sub_total - this.shipping_document.discount_price;
            this.shipping_document.vat_price = Math.round(((this.shipping_document.sub_total * _vat_percent) / 100) * 100) / 100;
        } else {
            this.shipping_document.sub_total = Math.round((((material_price_with_vat + ((this.shipping_document.frt_sh) ? this.shipping_document.frt_sh : 0) + ((this.shipping_document.misc_chgs) ? this.shipping_document.misc_chgs : 0)) - discount_price_with_vat) / 1.07) * 100) / 100;
            this.shipping_document.vat_price = ((material_price_with_vat + ((this.shipping_document.frt_sh) ? this.shipping_document.frt_sh : 0) + ((this.shipping_document.misc_chgs) ? this.shipping_document.misc_chgs : 0)) - discount_price_with_vat) - this.shipping_document.sub_total;
        }
        this.shipping_document.grand_total = this.shipping_document.vat_price + this.shipping_document.sub_total;

        return this.shipping_document.grand_total;
    }

}
