import {AfterViewInit, Component, Inject, NgZone, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ProductModel} from '../../../models/product.model';
import {TaskModel} from '../../../models/task.model';
import {UserService} from '../../../services/user.service';
import {CustomerModel} from '../../../models/customer.model';
import {CustomerAddressModel} from '../../../models/customerAddress.model';
import {Api} from '../../../now/api/api';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {environment} from '../../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../now/user/user.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {IncotermService} from '../../../services/incoterm.service';
import {CarrierService} from '../../../services/carrier.service';
import * as moment from 'moment';
import {TaskService} from '../../../services/task.service';
import {PurchaseOrderDetailComponent} from '../view/purchaseOrderDetail/purchaseOrderDetail.component';
import {ModalService} from '../../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../../now/user/auth.service';
import {ViewTask} from '../view/viewTask';
import {DocumentComponent} from '../../../modals/document/document.component';
import {DrawingModel} from '../../../models/drawing.model';
import {PaymentModel} from '../../../models/payment.model';
import {TypeaheadComponent} from '../../../components/typeahead/typeahead.component';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {ReportModalComponent} from '../../../modals/reportModal/reportModal.component';
import {DepositModel} from '../../../models/deposit.model';
import {DepositDetailComponent} from '../view/depositDetail/depositDetail.component';
import {ContactModel} from '../../../models/contact.model';
import {BillModel} from '../../../models/bill.model';
import {RequirementModel} from '../../../models/requirement.model';
import {AddRemarkComponent} from '../view/addRemark/addRemark.component';
import {DocumentModel} from '../../../models/document.model';
import {LoaderService} from '../../../components/loader/loader.service';
import {JobService} from '../../../services/job.service';
import {PackingSlipModel} from '../../../models/packingSlip.model';
import {Viewer} from '../../../services/viewer';

const MAX_PRODUCT = 10;
const DEFAULT_PRICE_VALIDITY = 30;

@Component({
    selector: 'packing-slip-task-component',
    templateUrl: 'packingSlipTask.component.html',
    styleUrls: ['packingSlipTask.component.scss'],
    providers: [DecimalPipe]
})
export class PackingSlipTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChildren(TypeaheadComponent) typeaheadComponents: QueryList<TypeaheadComponent>;

    public packingSlip: PackingSlipModel;
    public packingSlip_id: string;
    public sale: UserModel;
    public customerAddress: CustomerAddressModel;
    public tmp_customer: CustomerModel;
    public customer: CustomerModel;
    public tmp_product: any;
    public tmp_customer_address: any;
    public tmp_contact: any;
    public tmp_sale: any;
    public current_tab: string;
    public incoterms: IncotermModel[];
    public carriers: CarrierModel[];
    public tmp_incoterm: any;
    public tmp_carrier: any;
    public tmp_currency: any;
    public currency_list: any[];
    public tmp_contact_ref: any;
    public new_tasks: TaskModel[];
    public products: ProductModel[];

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private loader: LoaderService,
        private swal: SwalService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private ngZone: NgZone,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        public location: Location,
        private route: ActivatedRoute,
        private jobService: JobService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.current_tab = '#information';
        this.tmp_customer = new CustomerModel();
        // this.customer = new CustomerModel();
        this.sale = new UserModel();
        this.customerAddress = new CustomerAddressModel();
        this.tmp_product = {};
        this.tmp_customer_address = {};
        this.tmp_sale = new UserModel();
        this.incoterms = [];
        this.carriers = [];
        this.tmp_incoterm = {};
        this.tmp_carrier = {};
        this.tmp_currency = {};
        this.tmp_contact_ref = {};
        this.products = [];

        this.new_tasks = [];

        this.getNewTasks()
            .then(() => {});
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.packingSlip = new PackingSlipModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                this.packingSlip_id = this.task.modelable_id;
                                this.getPackingSlip()
                                    .then(() => {
                                        this.tmp_currency = {
                                            id: this.packingSlip.currency,
                                        };
                                        this.customerAddress = this.packingSlip.customer_address;
                                    });
                            });
                    }
                    this.packingSlip.task_id = this.task_id;
                });

            this.getIncoterms();
            this.getCarriers();
            this.getCurrencies();
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public createDeposit(): void {
        const deposit: DepositModel = new DepositModel();
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            packingSlip   : this.packingSlip,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    // this.packingSlip.deposits.push(deposit);
                } else {
                    //
                }
            });
    }

    public onDiscountPercentChange(e?: any): void {
        setTimeout(() => {
            // this.packingSlip.discount_price = Math.round(this.packingSlip.material_price * (this.packingSlip.discount_percent / 100) * 100) / 100;
            this.packingSlip.cal_total_price();
        }, 0);
    }

    public onDiscountPriceChange(e?: any): void {
        setTimeout(() => {
            // this.packingSlip.discount_percent = Math.round(this.packingSlip.discount_price * (100 / this.packingSlip.material_price) * 100) / 100;
            this.packingSlip.cal_total_price();
        }, 0);
    }

    public getPackingSlip(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.packingSlip.products) {
                this.packingSlip.products.splice(0, this.packingSlip.products.length);
            } else {
                this.packingSlip.products = [];
            }
            if (this.packingSlip_id) {
                this.api.request('packing/slips/' + this.packingSlip_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            this.packingSlip.clone(response.data);
                        }
                        for (let i = 0; i < Math.max(MAX_PRODUCT, this.packingSlip.products.length); i++) {
                            let product: ProductModel;
                            if (this.packingSlip.products[i] && this.packingSlip.products[i].id) {
                                product = this.packingSlip.products[i];
                                product.index = i;
                                product.price_per_unit = (product.pivot.price_per_unit)
                                    ? product.pivot.price_per_unit : product.price / Math.min(product.customer_product_amount, product.amount);
                                product.processing_times = (product.pivot.processing_times)
                                    ? product.pivot.processing_times : product.processing_times;
                            } else {
                                product = new ProductModel();
                                product.index = i;
                                product.pivot.wh = 'TH';
                                this.packingSlip.products.push(product);
                            }
                            // product.cal_price();
                        }
                        if (!this.packingSlip.sale || !this.packingSlip.sale.id) {
                            this.packingSlip.sale = new UserModel();
                            this.packingSlip.sale.clone(this.authService.user);
                            this.packingSlip.sale_id = this.authService.user.id;
                        }
                        if (this.packingSlip && !this.packingSlip.shipping) {
                            this.packingSlip.shipping = {};
                        }
                        this.packingSlip.cal_total_price();
                        resolve(this.packingSlip);
                    }, error => {
                        reject(error);
                    });
            } else {
                this.packingSlip = new PackingSlipModel();
                this.packingSlip.currency = 'THB';
                this.packingSlip.currency_rate = 1;
                for (let i = 0; i < MAX_PRODUCT; i++) {
                    let product: ProductModel;
                    product = new ProductModel();
                    product.index = i;
                    product.pivot.wh = 'TH';
                    this.packingSlip.products.push(product);
                }
                this.packingSlip.price_validity = DEFAULT_PRICE_VALIDITY;
                resolve(this.packingSlip);
            }
        });
        return promise;
    }

    public getNewTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks', 'GET', {
                process_slug: 'estimate',
                status: 9
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat = response.data[i];
                        if (dat) {
                            let task: TaskModel;
                            task = new TaskModel();
                            task.clone(dat);
                            this.new_tasks.push(task);
                        }
                    }
                }
                resolve(this.new_tasks);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json', 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    private getCurrencyRate(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const current_currency_code: string = this.packingSlip.currency;
            this.api.request('currency/covert', 'GET', {
                from: 'THB',
                to: current_currency_code
            }).subscribe((response: any): void => {
                if (response && response.data && response.data['THB_' + current_currency_code]) {
                    resolve(response.data['THB_' + current_currency_code].val);
                }
            }, error => {
                reject(error);
            });
            /*this.api.request('convert?q=THB_' + current_currency_code + '&compact=y',
            'GET', null, null, null, 'http://free.currencyconverterapi.com/', 'api/v5/')
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });*/
        });
        return promise;
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = incoterms;
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    public onProductDelete(product: ProductModel, e?: any): void {
        if (product) {
            product.name = '';
            product.market_price = 0;
            product.price = 0;
            product.customer_product_amount = 0;
            product.description = '';
        } else {
            //
        }
    }

    public onProductSelect(product: ProductModel, data: any): void {
        if (!product.wh) {
            product.wh = 'TH';
        }
        // if (!product.placeholder_price) {
        //     product.placeholder_price = Math.max(product.total_estimated_price, product.total_market_price);
        // }
    }

    private task_packingSlip_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {}, {
                action: 'edit_packingSlip',
                id: this.task.id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private generate_no(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (!this.packingSlip.packing_slip_no) {
                this.api.request('packing/slip/no', 'POST', {
                    task_id: this.task.id,
                    id: this.packingSlip.id
                }).subscribe((response: any): void => {
                    if (response && response.data && response.data.packing_slip_no) {
                        this.packingSlip.packing_slip_no = response.data.packing_slip_no;
                        resolve(this.packingSlip);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            } else {
                resolve(this.packingSlip);
            }
        });
        return promise;
    }

    public editPackingSlip(): void {
        if (this.packingSlip.packing_slip_no) {
            this.swal.confirm('คุณต้องการแก้ไขใบกำกับภาษี "' + this.packingSlip.packing_slip_no + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.task_packingSlip_clone()
                            .then((response: any): void => {
                                if (response && response.data && response.data.id) {
                                    this.taskService.hire(response.data, null, true)
                                        .then((hire_response: any): void => {
                                            // Go Go!
                                        }, error => {
                                            //
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public createPackingSlip(): void {
        if (this.packingSlip) {
            if (!this.packingSlip.customer_address_id && this.tmp_customer_address && this.tmp_customer_address.id) {
                this.packingSlip.customer_address_id = this.tmp_customer_address.id;
            }
            // if (!this.packingSlip.sale_id && this.tmp_sale && this.tmp_sale.id) {
            //     this.packingSlip.sale_id = this.tmp_sale.id;
            // }
            if (!this.packingSlip.customer || !this.packingSlip.customer.id) {
                if (this.tmp_customer && this.tmp_customer.id) {
                    this.packingSlip.customer = new CustomerModel();
                    this.packingSlip.customer.clone(this.tmp_customer);
                }
            }
        }
        if (!this.packingSlip.customer || !this.packingSlip.customer.id) {
            this.swal.danger('ไม่สามารถออกใบกำกับภาษีได้เนื่องจากไม่ได้ระบุข้อมูลลูกค้า');
            this.current_tab = '#information';
        // } else if (!this.packingSlip.customer_address_id) {
        //     this.swal.danger('ไม่สามารถออกใบกำกับภาษีได้เนื่องจากไม่ได้ระบุข้อมูลการส่งสินค้า');
        //     this.current_tab = '#shipping';
        } else if (!this.packingSlip.sale_id) {
            this.swal.danger('ไม่สามารถออกใบกำกับภาษีได้เนื่องจากไม่ได้ระบุข้อมูลผู้ดำเนินการ');
            this.current_tab = '#information';
        } else {
            this.viewPackingSlip(this.packingSlip);
        }
    }

    public onSelectSaleContactRef(event: any): void {
        if (this.packingSlip.requirement
            && this.packingSlip.requirement.id
            && this.packingSlip.requirement.customer
            && this.packingSlip.requirement.customer.id) {
            //
            this.packingSlip.customer_id = this.packingSlip.requirement.customer.id;
            this.packingSlip.customer.clone(this.packingSlip.requirement.customer);
            this.task.customer_id = this.packingSlip.customer_id;
            if (this.packingSlip.customer.addresses && this.packingSlip.customer.addresses[0]
                && this.packingSlip.customer.addresses[0].id) {
                //
                this.packingSlip.customer_address.clone(this.packingSlip.customer.addresses[0]);
                this.packingSlip.customer_address_id = this.packingSlip.customer.addresses[0].id;
            }
        }
    }

    public viewPurchaseOrder(product: ProductModel): void {
        if (product && product.purchase_order_no) {
            window.open(environment.api_host + 'view/' + product.purchase_order_no + '/payment/no', '_blank');
        } else {
            this.swal.danger('สินค้ายังไม่มีรายการ P/O');
        }
    }

    public addProduct(): void {
        let product: ProductModel;
        product = new ProductModel();
        product.index = this.packingSlip.products.push(product) - 1;
        product.pivot.wh = 'TH';
        product.cal_price();
        this.packingSlip.cal_total_price();
    }

    public addRemark(product: ProductModel): void {
        this.modal.show(RemarkModalComponent, {
            description: product.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                product.description = content.description;
                this.save(true);
            } else {
                //
            }
        });
    }

    private send_job_no(task_ids: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (task_ids && task_ids.length > 0) {
                this.api.request('mail/job', 'POST', {}, {
                    task_ids: task_ids
                }).subscribe(() => {
                    resolve();
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    /*private create_planning(product_ids: any, payment?: PaymentModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/products', 'PUT', null, {
                payment_id      : (payment) ? payment.id : null,
                id              : this.task.id,
                product_ids     : product_ids,
                current_role    : 'planning',
                process_slug    : 'planning',
                previous_role   : 'estimate',
                customer_id     : this.task.customer_id,
                action          : 'create_planning',
                parent_id       : this.task.id,

                // payment
                // required_at     : payment.required_at,
                // started_at      : payment.started_at
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    resolve(response.data);
                } else {
                    resolve(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }*/

    /*public complete(): void {
        if (!this.packingSlip.payments || this.packingSlip.payments.length === 0) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ')
                .then(() => {
                    this.current_tab = '#purchase_order';
                    this.pageScrollService.start(PageScrollInstance.simpleInstance(this.document, '#purchase_order'));
                });
        } else if (!this.packingSlip.validPayment()) {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากหลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง')
                .then(() => {
                    this.current_tab = '#purchase_order';
                    this.pageScrollService.start(PageScrollInstance.simpleInstance(this.document, '#purchase_order'));
                });
        } else {
            this.modal.show(SelectPaymentComponent, {
                payments: this.packingSlip.payments
            }, { class: 'modal-lg' }).then((content: any): void => {
                if (content && content.checked_payments && content.is_submit === true) {
                    this.create_planning(content.checked_products)
                        .then((planning_response: any): void => {
                            this.send_job_no(planning_response)
                                .then(() => {
                                    this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                                        .then(() => {
                                            //
                                        });
                                });
                        });
                } else {
                    //
                }
            });
        }
    }*/

    public warning(): void { // task status is 12
        this.modal.show(ReportModalComponent, {
            task: this.task
        }).then((content: any): void => {
            if (content && content.is_submit === true) {
                this.taskService.setStatus(this.task, this.task.status, 'feedback_packingSlip')
                    .then(() => {
                        this.swal.success('รอการตอบกลับจากลูกค้าภายหลัง');
                    }, error => {
                        this.swal.danger(error);
                    });
            } else {
                //
            }
        });
    }

    public rejectPackingSlip(): void {
        /*let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ยืนยันใบกำกับภาษีไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;*/
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.taskService.reject(this.task)
                        .then(() => {
                            this.swal.success('แจ้งใบกำกับภาษีไม่ผ่านการอนุมัติสำเร็จ');
                            this.leave(true);
                        }, error => {
                            //
                        });
                    return;
                }
                //
            });
    }

    public approvePackingSlip(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this._save()
                        .then(() => {
                            this.taskService.approve(this.task)
                                .then(() => {
                                    this.swal.success('ผ่านการอนุมัติใบกำกับภาษีสำเร็จ');
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                        });
                } else {
                    //
                }
            });
    }

    public viewDepositDetail(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            packingSlip   : this.packingSlip,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PurchaseOrderDetailComponent, {
            payment     : payment,
            packingSlip   : this.packingSlip,
            task        : this.task,
            products    : this.packingSlip.products
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.checked_products && content.is_submit === true) {
                    if (payment.verified === true) {
                        this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order')
                            .then(() => {
                                //
                            });
                        //
                        payment.status = 1;
                        this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                            .then(() => {
                                //
                            });
                    } else {
                        //
                    }
                } else {
                    //
                }
            });
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('packingSlips/payment', 'PUT', {}, {
            task_id: this.task.id,
            document: data,
            id: this.packingSlip.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.packingSlip.payments.push(new_payment);
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public onPaymentSelect(product: ProductModel, e?: any): void {
        //
    }

    public onPaymentDelete(product: ProductModel, e?: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('packingSlips/document', 'PUT', {}, {
            document    : data,
            id          : this.packingSlip.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.packingSlip.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public createProduct(product_id?: string, force?: boolean): void {
        const task: TaskModel = new TaskModel();
        task.process_slug = 'estimate';
        task.current_role = 'estimate';
        task.action = 'create_product';
        task.modelable_type = 'App\\Product';
        if (product_id) {
            task.modelable_id = product_id;
        } else {
            //
        }
        this.taskService.hire(task, null, ((!force) ? false : true), task.action, task.action)
            .then(() => {
                // Go Go!
            });
    }

    private getDrawings(): DrawingModel[] {
        this.packingSlip.document.checked = true;
        let drawings: DrawingModel[];
        drawings = [];
        for (let i = 0; i < this.packingSlip.products.length; i++) {
            const product: ProductModel = this.packingSlip.products[i];
            /*if (product && product.documents && product.documents.length > 0) {
                documents = documents.concat(this.packingSlip.products[i].documents);
            }*/
            if (product && product.drawings && product.drawings.length > 0) {
                for (let j = 0; j < product.drawings.length; j++) {
                    const drawing: DrawingModel = product.drawings[j];
                    if (drawing && drawing.document) {
                        drawing.checked = true;
                        drawings.push(drawing);
                    }
                }
            }
        }
        return drawings;
    }

    public verifyPackingSlip(): void {
        if (this.packingSlip.packing_slip_no && this.packingSlip.customer) {
            super.issueTask()
                .then(() => {
                    this.leave(true);
                });
        } else {
            //
        }
    }

    public sendPackingSlip(): void {
        if (this.packingSlip.packing_slip_no && this.packingSlip.customer) {
            this.modal.show(DocumentComponent, {
                customer    : this.packingSlip.customer,
                packingSlip   : this.packingSlip,
                drawings    : this.getDrawings(),
                requirement : this.packingSlip.requirement,
                documents   : this.packingSlip.documents
            }, {
                class: 'modal-lg'
            }).then((content: any): void => {
                if (content && content.submit === true) {
                    let document_ids: string[];
                    let drawing_ids: string[];
                    let requirement_ids: string[];
                    document_ids = [];
                    drawing_ids = [];
                    requirement_ids = [];
                    if (content.documents) {
                        for (let i = 0; i < content.documents.length; i++) {
                            if (content.documents[i].checked === true) {
                                document_ids.push(content.documents[i].id);
                            }
                        }
                    }
                    if (content.drawings) {
                        for (let i = 0; i < content.drawings.length; i++) {
                            if (content.drawings[i].checked === true) {
                                drawing_ids.push(content.drawings[i].id);
                            }
                        }
                    }
                    if (content.requirement && content.requirement.checked === true) {
                        requirement_ids.push(content.requirement.id);
                    }
                    if (this.packingSlip.customer.email) {
                        this.swal.confirm('คุณต้องการส่งใบกำกับภาษี <span>"' + this.packingSlip.packing_slip_no + '"</span><br/>' +
                            ' กับลูกค้า <i>' + this.packingSlip.customer.name + '</i><br/>' +
                            ' ผ่านทางอีเมล <strong class="text-primary">"' + this.packingSlip.customer.email + '"</strong>' +
                            ' ใช่หรือไม่?', true)
                            .then((result: boolean): void => {
                                if (result === true) {
                                    this.loader.show();
                                    this.send_packingSlip(document_ids, drawing_ids, requirement_ids)
                                        .then(() => {
                                            this.swal.success('ส่งใบกำกับภาษี "' + this.packingSlip.packing_slip_no + '"' +
                                                ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                                .then(() => {
                                                    this.leave(true);
                                                });
                                            this.loader.hide();
                                        }, error => {
                                            this.swal.danger('ไม่สามารถส่งใบกำกับภาษีได้ กรุณาลองใหม่อีกครั้ง');
                                            this.loader.hide();
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        this.swal.input('กรุณากรอกอีเมลลูกค้าสำหรับการส่งใบกำกับภาษี', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบกำกับภาษี')
                            .then((result: any): void => {
                                if (result) {
                                    this.api.request('customers/email', 'POST', {}, {
                                        id: this.task.customer_id,
                                        email: result
                                    }).subscribe((customer_response: any): void => {
                                        if (customer_response && customer_response.success === false) {
                                            this.swal.danger(customer_response.message);
                                        } else {
                                            this.loader.show();
                                            this.send_packingSlip(document_ids, drawing_ids, requirement_ids)
                                                .then(() => {
                                                    this.swal.success('ส่งใบกำกับภาษี "' + this.packingSlip.packing_slip_no + '"' +
                                                        ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                                        .then(() => {
                                                            this.leave(true);
                                                        });
                                                    this.loader.hide();
                                                }, error => {
                                                    this.swal.danger('ไม่สามารถส่งใบกำกับภาษีได้ กรุณาลองใหม่อีกครั้ง');
                                                    this.loader.hide();
                                                });
                                        }
                                    }, error => {
                                        this.swal.danger(error);
                                    });
                                } else {
                                    //
                                }
                            }, error => {
                                //
                            });
                    }
                }
            });
        }
    }

    private send_packingSlip(document_ids?: string[], drawing_ids?: string[], requirement_ids?: string[]): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let to_role: string;
            let to_process: string;
            console.log('CREDIT TERMS [' + this.packingSlip.customer.name + '] : ' + this.packingSlip.customer.credit_terms);
            if (this.packingSlip.customer && this.packingSlip.customer.credit_terms > 0) {
                to_role = 'quotation-2';
                to_process = this.task.process_slug;
            } else {
                to_role = 'payment';
                to_process = 'deposit';
            }
            this.taskService.setStatus(this.task, 2, 'send_packingSlip', to_role, to_process)
                .then((task_response: any): void => {
                    this.api.request('mail/packingSlip', 'POST', null, {
                        task_id         : this.task.id,
                        packingSlip_id    : this.packingSlip.id,
                        document_ids    : document_ids,
                        drawing_ids     : drawing_ids,
                        requirement_ids : requirement_ids
                    }).subscribe((response: any): void => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
                });
        });
        return promise;
    }

    public onSelectTypeaheadCurrency(data: any): void {
        this.packingSlip.currency_symbol = data.currencySymbol;
        this.packingSlip.currency = data.id;
        if (this.packingSlip && this.packingSlip.currency) {
            this.getCurrencyRate()
                .then((response: any): void => {
                    this.packingSlip.currency_rated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    this.packingSlip.currency_rate = response;
                });
        }
    }

    /*public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name) {
            model_parent[field_name] = data.id;
        }
    }

    public onSelectTypeaheadProduct(model: any, data: any, model_parent?: any, field_name?: string): void {
        this.onSelectTypeahead(model, data, model_parent, field_name);
        if (model) {
            model.amount = 1;
            model.price = Math.max(model.market_price, model.price, model.cost);
        }
        this.packingSlip.cal_total_price();
    }

    public onSelectCustomerTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.tmp_customer_address = data.addresses[0];
        }
        this.onSelectTypeahead(model, data, model_parent, field_name);
    }*/

    public onBillTypeaheadDeleted(event?: any): void {
        this.packingSlip.bill = new BillModel();
    }

    public onSaleTypeaheadDeleted(event?: any): void {
        this.packingSlip.sale = new UserModel();
    }

    public onCustomerTypeaheadDeleted(event?: any): void {
        this.packingSlip.customer = new CustomerModel();
        this.packingSlip.customer.credit_terms = 0;

        this.packingSlip.contact = new ContactModel();
        this.packingSlip.customer_address = new CustomerAddressModel();
    }

    public onCustomerAddressTypeaheadDeleted(event?: any): void {
        this.packingSlip.customer_address = new CustomerAddressModel();
    }

    public onCustomerSelect(data: any): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.packingSlip.customer_address.clone(data.addresses[0]);
            this.packingSlip.customer_address_id = data.addresses[0].id;
        } else {
            //
        }
        if (data && data.bills && data.bills.length > 0) {
            this.packingSlip.bill.clone(data.bills[0]);
            this.packingSlip.bill_id = data.bills[0].id;
        } else {
            //
        }
    }

    public onBlur(e: any): void {
        if (this.packingSlip) {
            this.packingSlip.cal_total_price();
        }
    }

    public productPriceChange(product: ProductModel, e?: any): void {
        //
    }

    public onProductPriceBlur(e?: any): void {
        // this.vatUpdate();
    }

    public onModelChanged(e?: any): void {
        this.ngZone.run(() => {
            if (this.packingSlip) {
                this.packingSlip.cal_total_price();
            }
        });
    }

    private getCheckedProducts(): any {
        let checked_products: any[];
        checked_products = [];
        let product_length: number;
        product_length = 0;
        for (let i = 0; i < this.packingSlip.products.length; i++) {
            const _product: ProductModel = this.packingSlip.products[i];
            if (_product && _product.id) {
                product_length++;
                checked_products.push({
                    customer_product_amount : _product.customer_product_amount,
                    purchase_order_no       : _product.purchase_order_no,
                    amount                  : _product.amount,
                    price                   : _product.price,
                    price_per_unit          : _product.price_per_unit,
                    description             : _product.description,
                    // processing_times        : _product.processing_times,
                    wh                      : _product.wh,
                    product_id              : _product.id
                });
            }
        }
        return checked_products;
    }

    public archive(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public onSelectIncotermCode(e: any): void {
        if (e && e.name) {
            this.packingSlip.incoterm_code = e.name;
        } else {
            this.packingSlip.incoterm_code = e;
        }
    }

    public save(skip?: boolean): void {
        if (skip === true) {
            //
        } else {
            this.loader.show();
        }
        this._save(skip)
            .then(() => {
                if (skip === true) {
                    //
                } else {
                    this.swal.success('ทำการบันทึกข้อมูลสำเร็จ', null, 2000);
                    this.loader.hide();
                }
            }, error => {
                if (skip === true) {
                    //
                } else {
                    this.swal.danger(error);
                    this.loader.hide();
                }
            });
    }

    private create_or_update_sale(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.packingSlip.sale && this.packingSlip.sale.id) {
                this.modelApi.update(this.packingSlip.sale, ['full_name', 'telephone', 'email'], 'users/contact')
                    .subscribe((result: any): void => {
                        resolve(this.packingSlip.sale);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private create_or_update_contact(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.tmp_contact && this.tmp_contact.id) {
                resolve(this.tmp_contact);
            } else if (this.tmp_contact && this.tmp_contact.full_name) {
                this.tmp_contact.customer_id = this.task.customer_id;
                this.modelApi.create(this.tmp_contact, ['full_name', 'position', 'telephone', 'email', 'customer_id'], 'customers/contact')
                    .subscribe((result: any): void => {
                        resolve(this.tmp_contact);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private _save(skip?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.packingSlip.product_ids = this.getCheckedProducts();
            const put_or_post_data: string[] = [
                'shipping_id', 'customer_id', 'contact_id', 'task_id', 'product_ids',
                'customer_address_id', 'sale_id', 'currency', 'currency_symbol',
                'currency_rate', 'currency_rated_at', 'delivery_date', 'bill_id',
                'shipping', 'incoterm_code', 'carrier_id', 'discount_percent', 'vat_percent', 'frt_sh', 'misc_chgs',
                'contact_ref_id', 'wh', 'requirement_id', 'ref_no', 'is_include_vat',
                'price_validity', 'processing_times', 'discount_type', 'discount_price'
            ];
            if (this.packingSlip.isNew) {
                /*this.modelApi.create(this.packingSlip, put_or_post_data, 'packing/slips')
                    .subscribe((packingSlip_response: any): void => {
                        if (packingSlip_response && packingSlip_response.success === true) {
                            resolve();
                        } else {
                            reject(packingSlip_response.message);
                        }
                    }, error => {
                        reject(error);
                    });*/
                resolve({});
            } else {
                this.modelApi.update(this.packingSlip, put_or_post_data, null, null, null, false)
                    .subscribe((packingSlip_response: any): void => {
                        if (packingSlip_response && packingSlip_response.success === true) {
                            resolve();
                        } else {
                            reject(packingSlip_response.message);
                        }
                    }, error => {
                        reject(error);
                    });
            }
        });
        return promise;
    }

    public vatUpdate(e?: any): void {
        setTimeout(() => {
            const vat_percent: number = (this.packingSlip.vat_percent) ? this.packingSlip.vat_percent : 0;
            for (let i = 0; i < this.packingSlip.products.length; i++) {
                const product: ProductModel = this.packingSlip.products[i];
                /*if (product && product.id) {
                    if (this.packingSlip.is_include_vat === true) {
                        product.price_per_unit = Math.round(product.price / (1 + (vat_percent / 100)));
                        product.vat_price = Math.round(product.price - product.price_per_unit);
                    } else {
                        product.price_per_unit = Math.round(product.price * (1 + (vat_percent / 100)));
                        product.vat_price = Math.round(product.price * (vat_percent / 100));
                    }
                } else {
                    //
                }*/
            }
        }, 0);
    }

    public nextProcess(): void {
        if (!this.packingSlip.payments || this.packingSlip.payments.length === 0) {
            this.swal.danger('ยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ');
        } else if (!this.packingSlip.validPayment()) {
            this.swal.danger('หลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง');
        } else {
            this.loader.show();
            this._save()
                .then(() => {
                    this.loader.hide();
                    this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order', 'proforma', 'proforma')
                        .then(() => {
                            this.leave(true);
                            this.swal.success('ส่งต่อให้กับแผนก Sale Admin สำเร็จ');
                        }, error => {
                            this.swal.danger(error);
                        });
                });
        }
    }

    public get total_price(): number {
        if (this.packingSlip) {
            this.packingSlip.cal_total_price();
            return this.packingSlip.grand_total;
        }
        return 0;
    }

    public onSaveApiCallingBack(e: any): void {
        if (this.task.approved) {
            //
        } else {
            this.save(true);
        }
    }

}
