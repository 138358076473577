import {FormModel} from '../now/formModel';

export class RoleModel extends FormModel {

    public name: string;
    public display_name: string;
    public description: string;
    public parent_id: string;

    public children: any[];

    constructor() {
        super('roles');
        this.children = [];
    }

}
