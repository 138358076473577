import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {AuthService} from '../../../now/user/auth.service';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {CarrierService} from '../../../services/carrier.service';
import {PusherService} from '../../../services/pusher.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {UserService} from '../../../services/user.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {SwalService} from '../../../services/swal.service';
import {DOCUMENT, Location} from '@angular/common';
import {TaskModel} from '../../../models/task.model';
import {environment} from '../../../environments/environment';
import {ViewTask} from '../view/viewTask';
import {DrawingModel} from '../../../models/drawing.model';
import {StoreService} from '../../../services/store.service';
import {JobService} from '../../../services/job.service';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {FVDService} from '../../../services/fvd.service';
import {NCR} from '../../../app/api/ncr';
import {NcrModel} from '../../../models/ncr.model';
import {CustomerModel} from '../../../models/customer.model';
import {DeliveryNoteModel} from '../../../models/deliveryNote.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {CustomerAddressModel} from '../../../models/customerAddress.model';
import {ContactModel} from '../../../models/contact.model';
import {BillModel} from '../../../models/bill.model';
import {DocumentModel} from '../../../models/document.model';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'trial-task-component',
    templateUrl: 'trialTask.component.html',
    styleUrls: ['trialTask.component.scss']
})
export class TrialTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;

    public ready: boolean;
    public response_shipping_status: number;
    public ncr_id: string;
    public ncr_pdf_path: string;
    public incoterms: string[];
    public delivery_note_id: any;
    public current_job_document_path: string;
    public pdfInfo: any;
    public numPages: number[];
    public job_id: string;
    public ncr: NcrModel;
    public customer: CustomerModel;
    public delivery_note: DeliveryNoteModel;

    public current_product_process: ProductProcessModel;
    public product: ProductModel;
    public product_id: string;
    public task_id: string;
    public current_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public job: JobModel;
    public job_no: string;
    public carriers: CarrierModel[];
    public currency_list: any[];
    public ncrs: NCR[];

    public document_pdf_path: string;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        public router: Router,
        private storeService: StoreService,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private jobService: JobService,
        private ngZone: NgZone,
        public api: Api,
        private fvdService: FVDService,
        public modal: ModalService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.response_shipping_status = 0;
        // this.fvd = {};
        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.ncr = new NcrModel();
        this.delivery_note = new DeliveryNoteModel();
        this.ready = false;
        this.incoterms = [];
        this.carriers = [];
        this.currency_list = [];

        this.getIncoterms();
        this.getCarriers();
        this.getCurrencies();
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = [];
                for (const incoterm of incoterms) {
                    this.incoterms.push(incoterm.name);
                }
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json', 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('delivery/notes/documents', 'PUT', {}, {
            document    : data,
            id          : this.delivery_note.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.delivery_note.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public onContactChange(e: any): void {
        this.delivery_note.contact_id = (e && e.id) ? e.id : null;
    }

    public onCustomerBillChange(e: any): void {
        this.delivery_note.bill_id = (e && e.id) ? e.id : null;
    }

    public onCustomerChange(e: any): void {
        this.delivery_note.customer_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressChange(e: any): void {
        this.delivery_note.customer_address_id = (e && e.id) ? e.id : null;
    }

    private init(): void {
        this.task = new TaskModel();
        this.job = new JobModel();
    }

    public save(skip?: boolean): void {
        this.api.request('delivery/notes', 'POST', {}, {
            id: this.delivery_note.id,
            bill_id: this.delivery_note.bill_id,
            customer_id: this.delivery_note.customer_id,
            contact_id: this.delivery_note.contact_id,
            carrier_id: this.delivery_note.carrier_id,
            customer_address_id: this.delivery_note.customer_address_id,
            incoterm_code: this.delivery_note.incoterm_code
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                //
            } else {
                //
            }
        }, error => {
            //
        });
    }

    public submit(): void {
        if (!this.delivery_note.documents || !this.delivery_note.documents.length) {
            this.swal.danger('โปรดแนบหลักฐานการส่งสินค้า');
            return;
        }
        this.swal.confirm('ยืนยันการส่งสินค้าให้กับลูกค้าสำเร็จใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.api.request('ncrs/delivery/notes', 'POST', {}, {
                        id: this.ncr.id,
                        task_id: this.task_id
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.swal.success('ดำเนินการแจ้ง QA สำเร็จ');
                            this.leave(true);
                        } else {
                            console.error(response);
                        }
                    }, error => {
                        console.error(error);
                        this.swal.danger('เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง');
                    });
                } else {
                    //
                }
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.getTask()
                            .then(() => {
                                this.onSuccess(this.task);
                            });
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public getDeliveryNote(id: any): Promise<DeliveryNoteModel> {
        let promise: Promise<DeliveryNoteModel>;
        promise = new Promise<DeliveryNoteModel>((resolve, reject) => {
            this.api.request('delivery/notes/' + id, 'GET', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.delivery_note = new DeliveryNoteModel();
                        const carrier = new CarrierModel();
                        carrier.clone(response.data.carrier);
                        const customer = new CustomerModel();
                        customer.clone(response.data.customer);
                        const customer_address = new CustomerAddressModel();
                        customer_address.clone(response.data.customer_address);
                        const contact = new ContactModel();
                        contact.clone(response.data.contact);
                        const bill = new BillModel();
                        bill.clone(response.data.bill);
                        this.delivery_note.clone(response.data);
                        this.delivery_note.customer = customer;
                        this.delivery_note.customer_address = customer_address;
                        this.delivery_note.contact = contact;
                        this.delivery_note.carrier = carrier;
                        this.delivery_note.bill = bill;
                        resolve(this.delivery_note);
                    } else {
                        reject({});
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getNCRDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('ncrs/' + this.ncr_id)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.ncr.clone(response.data);
                        if (this.ncr && this.ncr.equipments && Array.isArray(this.ncr.equipments)) {
                            for (let i = 0; i < this.ncr.equipments.length; i++) {
                                if (this.ncr.equipments[i]) {
                                    //
                                } else {
                                    this.ncr.equipments[i] = '';
                                }
                            }
                        } else {
                            this.ncr.equipments = ['', '', '', '', ''];
                        }
                        this.job.pivot = {
                            amount          : this.ncr.amount,
                            // price_per_unit  : this.job.price_per_unit,
                        };
                        // this.customer.clone(this.job.customer);
                        this.ncr.jobs = [this.job]; // for delivery note table
                        this.ncr_pdf_path = environment.api_host + 'view/' + this.ncr_id + '/ncr';
                        this.document_pdf_path = environment.api_host + 'view/' + this.ncr.id + '/ncr';
                        resolve();
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onSuccess(data: any): void {
        this.current_tab = '#information';
        this.job_id = data.job_id;
        if (data.modelable_type === 'App\\DeliveryNote') {
            this.delivery_note_id = data.modelable_id;
            this.getDeliveryNote(this.delivery_note_id)
                .then(() => {
                    this.ncr_id = this.delivery_note.modelable_id;
                    this.getNCRDetail()
                        .then(() => {
                            this.getJob(this.job_id)
                                .then(() => {
                                    this.product_id = this.job.product_id;
                                    this.product = new ProductModel();
                                    this.getProductDetail()
                                        .then(() => {
                                            //
                                        });
                                    this.getNCRsByJob(this.job)
                                        .then(ncrs => {
                                            this.ncrs = ncrs;
                                        });
                                });
                            this.viewer.manufacture(this.job, false, this.job.revision)
                                .then(path => {
                                    this.current_job_document_path = path;
                                    this.doReady();
                                });
                        });
                });
        } else {
            //
        }
    }

    public onError(e: any): void {
        //
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public onSelectIncotermCode(e: any): void {
        //
    }

    public onCarrierChange(e: any): void {
        this.delivery_note.carrier_id = (e && e.id) ? e.id : null;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        // this.product.clone(response.data.product);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getNCRsByJob(job: JobModel): Promise<NCR[]> {
        let promise: Promise<NCR[]>;
        promise = new Promise<NCR[]>((resolve, reject) => {
            this.jobService.getNCRs(job)
                .then(ncrs => {
                    resolve(ncrs);
                }, error => {
                    resolve([]);
                });
        });
        return promise;
    }

    public onPdfLoadCompleted(e: any): void {
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    public onSaveApiCallingBack(e: any): void {
        this.save(true);
    }

}
