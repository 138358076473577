import {Injectable} from '@angular/core';

@Injectable()
export class DivApiConfig {

    public error_template_url: string;
    public getting_template_url: string;
    public got_template_url: string;
    public zero_template_url: string;

}