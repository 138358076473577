import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {DocumentModel} from '../../../../models/document.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {Api} from '../../../../now/api/api';
import {UserService} from '../../../../services/user.service';
import {ItemRequirementDocumentModel} from '../../../../models/itemRequirementDocument.model';
import {ItemRequirementModel} from '../../../../models/itemRequirement.model';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'document-type-modal',
    templateUrl: 'documentType.modal.html',
    styleUrls: ['documentType.modal.scss']
})
export class DocumentTypeModal implements OnInit {

    public document: DocumentModel;
    public data_document: any;
    public item_requirement: ItemRequirementModel;
    public item_requirement_document: ItemRequirementDocumentModel;

    constructor(public bsModalRef: BsModalRef,
                private modelApi: ModelApi,
                private swal: SwalService,
                private userService: UserService,
                private api: Api) {
        //
        this.item_requirement_document = new ItemRequirementDocumentModel();
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef) {
                if (this.bsModalRef.content && this.bsModalRef.content.document) {
                    this.data_document = this.bsModalRef.content.document;
                    this.item_requirement_document.name = this.bsModalRef.content.document.name;
                }
                if (this.bsModalRef.content && this.bsModalRef.content.item_requirement_document) {
                    this.item_requirement_document.clone(this.bsModalRef.content.item_requirement_document);
                }
                if (this.bsModalRef.content && this.bsModalRef.content.item_requirement) {
                    this.item_requirement = this.bsModalRef.content.item_requirement;
                    this.item_requirement_document.item_requirement_id = this.bsModalRef.content.item_requirement.id;
                }
                if (this.item_requirement_document.modelable_type === 'App\\Drawing'
                    && (!this.item_requirement_document.modelable_data
                        || !this.item_requirement_document.modelable_data.ranking)) {
                    //
                    this.item_requirement_document.modelable_data = {
                        ranking: ''
                    };
                }
                /*if (this.item_requirement_document.name) {
                    let name: string;
                    name = this.item_requirement_document.name.toUpperCase();
                    name = name.split('.').slice(0, -1).join('.');
                    this.item_requirement_document.name = name;
                }*/
                if (!this.item_requirement_document.modelable_type) {
                    this.item_requirement_document.modelable_type = 'App\\Drawing';
                }
            }
        }, 0);
    }

    public viewDocument(): void {
        if (this.item_requirement_document && this.item_requirement_document.document_id) {
            window.open(environment.api_host + 'view/' + this.item_requirement_document.document_id + '/document/', '_blank');
        }
    }

    public delete(): void {
        this.swal.confirm('คุณต้องการลบเอกสารนี้ใช่หรือไม่')
            .then(result => {
                if (result === true) {
                    if (this.item_requirement_document.id) {
                        this.api.request('item/requirements/documents/delete', 'POST', {}, {
                            id: this.item_requirement_document.id
                        }).subscribe((response: any): void => {
                            for (let i = 0; i < this.item_requirement.item_requirement_documents.length; i++) {
                                if (this.item_requirement.item_requirement_documents[i].id === this.item_requirement_document.id) {
                                    this.item_requirement.item_requirement_documents.splice(i, 1);
                                    break;
                                }
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    } else {
                        this.bsModalRef.hide();
                    }
                }
            });
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            if (!this.item_requirement_document.modelable_type) {
                this.swal.danger('กรุณาเลือกประเภทเอกสาร');
                return;
            }
            // this.item_requirement_document.document_id = this.document.id;
            if (this.item_requirement_document.isNew) {
                this.item_requirement_document.document = this.data_document;
            } else {
                //
            }
            this.item_requirement_document.item_requirement_id = this.item_requirement.id;
            this.modelApi.createOrUpdate(this.item_requirement_document, [
                'modelable_data', 'name', 'modelable_type', 'requirement_id', 'document_id', 'document', 'item_requirement_id'
            ], 'item/requirements/documents', null, null, false)
                .subscribe((response: any): void => {
                    this.item_requirement_document.id = response.data.id;
                    this.bsModalRef.content.item_requirement_document = this.item_requirement_document;
                    this.bsModalRef.content.submit = true;
                    this.bsModalRef.hide();
                }, error => {
                    //
                });
        }
    }

}
