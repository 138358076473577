import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {CuttingToolModel} from '../models/cuttingTool.model';

@Injectable()
export class CuttingToolService {

    public cutting_tools: CuttingToolModel[];

    constructor(private api: Api) {
        this.reset_cutting_tool_list();
    }

    public set(list: CuttingToolModel[]): void {
        this.reset_cutting_tool_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const cutting_tool: CuttingToolModel = new CuttingToolModel();
                    cutting_tool.clone(dat);
                    this.cutting_tools.push(cutting_tool);
                }
            }
        }
    }

    public getCuttingTools(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.cutting_tools && this.cutting_tools.length > 0) {
                for (let i = 0; i < this.cutting_tools.length; i++) {
                    this.cutting_tools[i].checked = false;
                }
                resolve(this.cutting_tools);
            } else {
                this.api.request('cuttingtools', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_cutting_tool_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.cutting_tools.push(dat);
                                }
                            }
                            resolve(this.cutting_tools);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_cutting_tool_list(): void {
        if (this.cutting_tools) {
            this.cutting_tools.splice(0, this.cutting_tools.length);
        } else {
            this.cutting_tools = [];
        }
    }

}
