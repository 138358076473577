import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {StoreModel} from '../../../../models/store.model';
import {RequisitionModel} from '../../../../models/requisition.model';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {TaskService} from '../../../../services/task.service';
import {AuthService} from '../../../../now/user/auth.service';
import {ModalService} from '../../../../services/modal.service';
import {TaskModel} from '../../../../models/task.model';
import {ViewTask} from '../../view/viewTask';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../../../now/user/user.model';
import {RemarkModal} from '../../../../modals/remark/remark.modal';
import {Remark} from '../../../../app/api/remark';
import {ItemModel} from '../../../../models/item.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'prepared-store-component',
    templateUrl: 'preparedStore.component.html',
    styleUrls: ['preparedStore.component.scss']
})
export class PreparedStoreComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild('pickupItemRemark', { static: true }) pickupItemRemark: RemarkModal;
    @ViewChild('returnItemRemark', { static: true }) returnItemRemark: RemarkModal;

    public store: StoreModel;
    public requisition: RequisitionModel;
    public remarks: Remark[];
    public current_item: ItemModel;

    constructor(
        public viewer: Viewer,
        public location: Location,
        public api: Api,
        public modelApi: ModelApi,
        public swal: SwalService,
        public taskService: TaskService,
        private route: ActivatedRoute,
        public authService: AuthService,
        public modal: ModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.remarks = [];
        this.task = new TaskModel();
        this.requisition = new RequisitionModel();
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.onSuccess(this.task);
                        });
                }
            });
    }

    ngOnDestroy() {
        //
    }

    public accept(): void {
        //
    }

    public onPickupItemRemarkSubmit(e: any): void {
        this.api.request('requisitions/pickup', 'POST', {}, {
            id: this.requisition.id,
            item_id: this.current_item.id,
            task_id: this.task.id,
            remark: e.remark_message
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                this.current_item.pivot.status = 3;
                this.current_item.pivot.picked_at = response.data.picked_at;
                this.swal.success('ดำเนินการรับวัตถุดิบ/วัสดุ "' + this.current_item.search_value + '" สำเร็จ');
            } else {
                console.error(response);
                this.swal.danger(response);
            }
        }, error => {
            console.error(error);
            this.swal.danger(error);
        });
    }

    public onReturnItemRemarkSubmit(e: any): void {
        this.api.request('requisitions/return', 'POST', {}, {
            id: this.requisition.id,
            item_id: this.current_item.id,
            task_id: this.task.id,
            remark: e.remark_message
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                this.current_item.pivot.status = 4;
                this.current_item.pivot.returned_at = response.data.returned_at;
                this.swal.success('ดำเนินการคืนวัตถุดิบ/วัสดุ "' + this.current_item.search_value + '" สำเร็จ');
            } else {
                console.error(response);
                this.swal.danger(response);
            }
        }, error => {
            console.error(error);
            this.swal.danger(error);
        });
    }

    public returnItem(item: ItemModel): void {
        this.swal.confirm('ยืนยันการคืนวัตถุดิบ/วัสดุ "' + item.search_value + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.current_item = item;
                    this.returnItemRemark.open();
                }
            });
    }

    public pickupItem(item: ItemModel): void {
        this.swal.confirm('ยืนยันการรับวัตถุดิบ/วัสดุ "' + item.search_value + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.current_item = item;
                    this.pickupItemRemark.open();
                }
            });
    }

    public archiveTask(): void {
        for (const mat of this.requisition.materials) {
            if (mat && mat.id && ((mat.pivot.status !== 4 && mat.pivot.return_action === 1) || mat.pivot.status === 2)) {
                if (!mat.pivot.status || mat.pivot.status === 1) {
                    this.swal.danger('"' + mat.search_value + '" กำลังเตรียมเพื่อเบิกจึงไม่สามารถจัดเก็บได้');
                    return;
                } else if (mat.pivot.status === 2) {
                    this.swal.danger('"' + mat.search_value + '" รอดำเนินการรับวัตถุดิบ/วัสดุจึงไม่สามารถจัดเก็บได้');
                    return;
                } else if (mat.pivot.status === 3) {
                    this.swal.danger('"' + mat.search_value + '" รอคืนวัตถุดิบ/วัสดุกับ Store จึงไม่สามารถจัดเก็บได้');
                    return;
                }
                this.swal.danger('เกิดข้อผิดพลาด โปรดแจ้งทางผู้ดูแลระบบ');
                return;
            }
        }
        this.swal.confirm('ยืนยันการจัดเก็บการร้องขอวัตถุดิบ/วัสดุ ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    /*this.api.request('requisitions/complete', 'POST', {}, {
                        id: this.requisition.id
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.taskService.complete(this.task)
                                .then(() => {
                                    this.swal.success('จัดเก็บการร้องขอวัตถุดิบ/วัสดุสำเร็จ');
                                    this.leave(true);
                                });
                        } else {
                            console.error(response);
                            this.swal.danger(response);
                        }
                    }, error => {
                        console.error(error);
                        this.swal.danger('เกิดข้อผิดพลาด โปรดแจ้งทางผู้ดูแลระบบ');
                    });*/
                    this.taskService.complete(this.task)
                        .then(() => {
                            this.swal.success('จัดเก็บการร้องขอวัตถุดิบ/วัสดุสำเร็จ');
                            this.leave(true);
                        });
                }
            });
    }

    public onSuccess(task: TaskModel): void {
        this.requisition.id = task.modelable_id;
        this.getRequisition()
            .then(requisition => {
                this.requisition = requisition;
                this.remarks = this.requisition.remarks;
                if (!this.remarks) {
                    this.remarks = [];
                }
            });
    }

    public getRequisition(): Promise<RequisitionModel> {
        let promise: Promise<RequisitionModel>;
        promise = new Promise<RequisitionModel>((resolve, reject) => {
            this.api.request('requisitions/' + this.requisition.id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let requisition: RequisitionModel;
                    requisition = new RequisitionModel();
                    requisition.clone(response.data);
                    if (response.data.man && response.data.man.id) {
                        let man: UserModel;
                        man = new UserModel();
                        man.clone(response.data.man);
                        requisition.man = man;
                    } else {
                        requisition.man = new UserModel();
                    }
                    if (response.data.prepare_user && response.data.prepare_user.id) {
                        let prepare_user: UserModel;
                        prepare_user = new UserModel();
                        prepare_user.clone(response.data.prepare_user);
                        requisition.prepare_user = prepare_user;
                    } else {
                        requisition.prepare_user = new UserModel();
                    }
                    resolve(requisition);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

}
