import {FormModel} from '../now/formModel';

export class PackagingModel extends FormModel {

    public name: string;
    public width: number;
    public height: number;
    public length: number;
    public weight: number;
    public amount: number;
    public price: number;
    public unit: string;
    public description: string;

    constructor() {
        super('packagings', 'App\\Packaging');
    }

    public get total_price(): number {
        return this.price * this.amount;
    }

}
