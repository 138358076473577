import {AfterViewInit, Component} from '@angular/core';
import {Api} from '../../now/api/api';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'store-component',
    templateUrl: 'store.component.html',
    styleUrls: ['store.component.scss']
})
export class StoreComponent implements AfterViewInit {

    public pages: any[];
    public store_database: any;

    constructor(
        private api: Api,
        private viewer: Viewer
    ) {
        //
        this.store_database = {};
        this.pages = [
            {
                key         : 'atl',
                display     : 'วัสดุอุปกรณ์โรงงาน',
                link        : '/store/view/atl'
            },
            {
                key         : 'frw',
                display     : 'วัตถุดิบ',
                link        : '/store/view/frw'
            },
            {
                key         : 'sn',
                display     : 'สินค้าสำเร็จรูป',
                link        : '/store/view/sn'
            },
            {
                key         : 'fes',
                display     : 'อุปกรณ์สิ้นเปลืองโรงงาน',
                link        : '/store/view/fes'
            },
            {
                key         : 'fct',
                display     : 'Cutting Tools',
                link        : '/store/view/fct'
            },
            {
                key         : 'fas',
                display     : 'วัสดุประกอบ',
                link        : '/store/view/fas'
            },
            {
                key         : 'ost',
                display     : 'ค่าอุปกรณ์ เครื่องเขียน แบบพิมพ์',
                link        : '/store/view/ost'
            }
        ];
    }

    ngAfterViewInit(): void {
        this.getStoreDatabase()
            .then(() => {});
    }

    public printReport(): void {
        this.viewer.storeReport()
            .then(() => {
                //
            });
    }

    public getStoreDatabase(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('database/store')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.store_database = response.data;
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

}
