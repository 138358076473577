import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {FileItem, FileUploader, FileUploadModule} from 'ng2-file-upload';
import {Api} from '../../now/api/api';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'uploader-component',
    templateUrl: 'uploader.component.html',
    styleUrls: ['uploader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => UploaderComponent),
        }
    ]
})
export class UploaderComponent implements ControlValueAccessor, AfterViewInit {

    @ViewChild('uploaderSelect', { static: false }) uploaderSelect: ElementRef;
    @ViewChild('uploaderDrop', { static: false }) uploaderDrop: ElementRef;

    @Input() url: string;
    @Input() type: string;

    @Output() startEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() successEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() progressEvent: EventEmitter<any> = new EventEmitter<any>();

    public disabled: boolean;

    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean;

    public tag_id: string;
    public target: any;

    private the_model: any;
    private propagateChange: any = (_: any): void => {};

    public trigger(): void {
        if (this.uploaderSelect && this.uploaderSelect.nativeElement) {
            this.uploaderSelect.nativeElement.click();
        }
    }

    constructor(private api: Api) {
        this.target = {};
        this.tag_id = 'uploader-' + this.randomID(10);
        this.hasBaseDropZoneOver = false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.type = (this.type) ? this.type : '';
            this.uploader = new FileUploader({
                autoUpload  : true,
                method      : 'POST',
                headers     : [
                    {
                        name    : 'Authorization',
                        value   : this.api.getAuthorization()
                    }
                ],
                url         : ((this.url) ? this.url : environment.api_host + environment.prefix + 'upload') + '?type=' + this.type
            });
            this.uploader.onBeforeUploadItem = (file) => { this.startEvent.emit({ file: file, uploader: this }); };
            this.uploader.onAfterAddingFile = (file) => {
                file.withCredentials = false;
                if (this.uploaderSelect && this.uploaderSelect.nativeElement) {
                    this.uploaderSelect.nativeElement.value = '';
                }
                if (this.uploaderDrop && this.uploaderDrop.nativeElement) {
                    this.uploaderDrop.nativeElement.value = '';
                }
            };
            this.uploader.onCompleteItem = (item: FileItem, response: string) => this.onUploaded(item, response);
            this.uploader.onProgressAll = (progress: any) => this.onProgressAll(progress);
            this.disabled = false;
        });
        /*setTimeout(() => {
            this.uploader = new FileUploader({
                autoUpload  : true,
                method      : 'POST',
                headers     : [
                    {
                        name    : 'Authorization',
                        value   : this.api.getAuthorization()
                    }
                ],
                url         : (this.url) ? this.url : environment.host + environment.prefix + 'upload'
            });
            this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
            this.uploader.onCompleteItem = (item: FileItem, response: string) => this.onUploaded(item, response);
        }, 1);*/
    }

    /*public onChange(e: any): void {
        this.target = event.target || event.srcElement;
    }*/

    public cancel(): void {
        if (this.uploader) {
            this.uploader.cancelAll();
        }
    }

    private onProgressAll(progress: any): void {
        this.progressEvent.emit(progress);
    }

    private onUploaded(item: FileItem, response: string): void {
        if (response) {
            try {
                let response_json: any;
                response_json = JSON.parse(response);
                this.the_model = {
                    name: (item.file.name.split('.').slice(0, -1).join('.')).toUpperCase(),
                    thumbnail: response_json.thumbnail,
                    native_path: response_json.native_path,
                    preview: response_json.preview,
                    path: response_json.path,
                    mime_type: response_json.mime_type
                };

                if (this.uploader.queue) {
                    for (let i = 0; i < this.uploader.queue.length; i++) {
                        if (this.uploader.queue[i].progress === 100) {
                            setTimeout(() => {
                                this.uploader.queue[i].remove();
                            }, 750);
                        }
                    }
                }

                this.propagateChange(this.the_model);
                this.successEvent.emit(this.the_model);
                return;
            } catch (e) {
                console.error(e);
            }
        }
        this.errorEvent.emit(response);
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    writeValue(obj: string): void {
        if (obj) {
            this.the_model = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

    private randomID(length): string {
        let chars: string[];
        chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

        if (!length) {
            length = Math.floor(Math.random() * chars.length);
        }

        let str: string;
        str = '';
        for (let i = 0; i < length; i++) {
            str += chars[Math.floor(Math.random() * chars.length)];
        }
        return str;
    }

    public get queue(): FileItem[] {
        return this.uploader.queue;
    }

}
