import {Directive, ElementRef, OnInit, OnDestroy} from '@angular/core';

@Directive({
    selector: '[nowDivApiGetting]'
})
export class DivApiGettingDirective implements OnInit, OnDestroy {

    constructor(public el: ElementRef) {
        //
    }

    ngOnInit(): void {
        if (this.el.nativeElement) {
            this.el.nativeElement.remove();
        }
    }

    ngOnDestroy(): void {

    }

}