import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {FormsModule} from '@angular/forms';
import {ERPComponentModule} from '../components/erpComponent.module';
import {ERPPipeModule} from '../pipes/erpPipe.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxSmartModalModule.forChild(),
        ERPComponentModule,
        ERPPipeModule,
    ],
    exports: [

    ],
    declarations: [

    ],
    entryComponents: [
        //
    ],
    providers: [
        //
    ]
})
export class ERPModalModule {

    public static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: ERPModalModule,
            providers: [
                //
            ]
        };
    }

}
