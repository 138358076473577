import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {HardeningModel} from '../../../../models/hardening.model';
import {Location} from '@angular/common';

@Component({
    selector: 'hardening-view-component',
    templateUrl: 'hardeningView.component.html',
    styleUrls: ['hardeningView.component.scss']
})
export class HardeningViewComponent implements OnInit {

    public hardening: HardeningModel;
    public hardening_id: string;
    public current_tab: string;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private location: Location,
                private appService: AppService,
                private modelApi: ModelApi,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.hardening = new HardeningModel();
        this.route.params
            .subscribe(params => {
                this.hardening_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getHardening()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getHardening(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.hardening_id) {
                this.api.request('hardenings/' + this.hardening_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.hardening.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.hardening);
            }
        });
        return promise;
    }

    public save(): void {
        if (!this.hardening.name) {
            this.swal.danger('กรุณากรอกชื่อชุบแข็ง');
        } else if (!this.hardening.price) {
            this.swal.danger('กรุณากรอกราคาชุบแข็งสำหรับการประเมินการผลิต');
        } else if (!this.hardening.ranking) {
            this.swal.danger('กรุณาระบุRANK');
        } else {
            this.modelApi.createOrUpdate(this.hardening, [
                // profile
                'name', 'price', 'ranking'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/hardening/' + this.hardening.id + '/detail');
                this.swal.success('บันทึกข้อมูลชุบแข็งสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createHardening(): void {
        this.save();
    }

}
