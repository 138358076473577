import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {ProcessModel} from '../../../../models/process.model';
import {Location} from '@angular/common';

@Component({
    selector: 'process-view-component',
    templateUrl: 'processView.component.html',
    styleUrls: ['processView.component.scss']
})
export class ProcessViewComponent implements OnInit {

    public process: ProcessModel;
    public process_id: string;
    public current_tab: string;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        private location: Location,
        private appService: AppService,
        private modelApi: ModelApi,
        private modal: ModalService,
        private swal: SwalService,
        private router: Router
    ) {
        //
        this.process = new ProcessModel();
        this.route.params
            .subscribe(params => {
                this.process_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getProcess()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getProcess(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.process_id) {
                this.api.request('processes/' + this.process_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.process.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.process);
            }
        });
        return promise;
    }

    public deleteProcess(): void {
        if (this.process && this.process.id) {
            this.swal.confirm('ยืนยันการลบกระบวนการ "' + this.process.name + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('processes/delete', 'POST', {}, {
                            id: this.process.id,
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.swal.success('ลบกระบวนการสำเร็จ');
                                this.router.navigateByUrl('/database/process');
                            } else {
                                this.swal.danger('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
                            }
                        }, error => {
                            this.swal.danger('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
                        });
                    }
                });
        }
    }

    public save(): void {
        if (!this.process.name) {
            this.swal.danger('กรุณากรอกชื่อกระบวนการ');
        } else {
            this.modelApi.createOrUpdate(this.process, [
                'name', 'slug', 'color', 'text_color'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/process/' + this.process.id + '/detail');
                this.swal.success('บันทึกข้อมูลกระบวนการสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createProcess(): void {
        this.save();
    }

}
