import {Model} from '../now/model';
import {CriticalPointModel} from './criticalPoint.model';
import {SpecialToolingModel} from './specialTooling.model';
import {MaterialModel} from './material.model';
import {ProductProcessModel} from './productProcess.model';
import {DrawingModel} from './drawing.model';
import {CADModel} from './cad.model';
import {CuttingToolModel} from './cuttingTool.model';
import {FormModel} from '../now/formModel';
import {HardeningModel} from './hardening.model';
import {ProcessModel} from './process.model';
import {PackagingModel} from './packaging.model';
import {ShippingModel} from './shipping.model';
import {CosmeticModel} from './cosmetic.model';
import {IncotermModel} from './incoterm.model';
import {DocumentModel} from './document.model';
import {RequirementModel} from './requirement.model';
import {UserModel} from '../now/user/user.model';
import {ProductMaterial} from './productMaterial';

export class ProductModel extends FormModel {

    public processing_times: number;
    public purchase_order_no: any;

    public store_amount: number;
    public item_description: string;
    public boi: boolean;
    public material_type: number;
    public requirement: any;
    public requirement_id: string;
    public parent: ProductModel;
    public parent_id: string;
    public contact_ref: string;
    public avatar: string;
    public task_id: string;
    public ref_no: string;
    public name: string;
    public amount: number;
    public customer_product_amount: number;
    public unit: string;
    public note: string;
    public product_no: string;
    public ranking: number;
    public customer_id: string;
    public delivery_date: string;
    public wh: string;
    public remark: string;
    public other_price: any;
    public tmp_job_no: string;
    public drawing_no: any[];
    public total_price_with_part: number;
    public total_market_price_with_part: number;
    public vat_price: number;
    public documents: DocumentModel[];
    public width: number;
    public height: number;
    public length: number;
    public weight: number;
    public assembly: boolean;
    public eng_user_id: string;
    public qc_user_id: string;
    public eng_user: UserModel;
    public qc_user: UserModel;
    public price: number;
    public price_per_unit: number;
    public cost: number;
    public estimated_price: number;
    public process_price: number;
    public process_estimated_price: number;
    public market_price: number;
    public critical_points: CriticalPointModel[];
    public special_toolings: SpecialToolingModel[];
    public materials: ProductMaterial[];
    public processes: ProcessModel[];
    public cutting_tools: CuttingToolModel[];
    public hardenings: HardeningModel[];
    public packagings: PackagingModel[];
    public shippings: ShippingModel[];
    public cosmetics: CosmeticModel[];
    public incoterms: IncotermModel[];
    public product_processes: ProductProcessModel[];
    public products: ProductModel[];
    public drawings: any[];
    public cads: any[];
    public bo: number;
    public schedulers: any[];
    public incoterm: IncotermModel;
    public description: string;
    public todo: string;

    constructor() {
        super('products', 'App\\Product');

        this.other_price = {
            tool        : 15,
            management  : 15,
            risk        : 50,
        };

        // this.eng_user = new UserModel();
        // this.qc_user = new UserModel();

        this.schedulers         = [];

        this.wh                 = 'TH';
        this.critical_points    = [];
        this.special_toolings   = [];
        this.cutting_tools      = [];
        this.hardenings         = [];
        this.packagings         = [];
        this.materials          = [];
        this.processes          = [];
        this.drawings           = [];
        this.cads               = [];
        this.shippings          = [];
        this.cosmetics          = [];
        this.incoterms          = [];

        this.product_processes  = [];
        this.products           = [];

        this.documents          = [];
        this.drawing_no         = [];

        // this.requirement        = new RequirementModel();
        this.process_price      = 0;
    }

    public clone(data: any): Model {
        if (!this.critical_points) {
            this.critical_points = [];
        } else {
            this.critical_points.splice(0, this.critical_points.length);
        }
        if (!this.special_toolings) {
            this.special_toolings = [];
        } else {
            this.special_toolings.splice(0, this.special_toolings.length);
        }
        if (!this.cutting_tools) {
            this.cutting_tools = [];
        } else {
            this.cutting_tools.splice(0, this.cutting_tools.length);
        }
        if (!this.hardenings) {
            this.hardenings = [];
        } else {
            this.hardenings.splice(0, this.hardenings.length);
        }
        if (!this.packagings) {
            this.packagings = [];
        } else {
            this.packagings.splice(0, this.packagings.length);
        }
        if (!this.shippings) {
            this.shippings = [];
        } else {
            this.shippings.splice(0, this.shippings.length);
        }
        if (!this.materials) {
            this.materials = [];
        } else {
            this.materials.splice(0, this.materials.length);
        }
        if (!this.cosmetics) {
            this.cosmetics = [];
        } else {
            this.cosmetics.splice(0, this.cosmetics.length);
        }
        if (!this.product_processes) {
            this.product_processes = [];
        } else {
            this.product_processes.splice(0, this.product_processes.length);
        }
        if (!this.drawings) {
            this.drawings = [];
        } else {
            this.drawings.splice(0, this.drawings.length);
        }
        if (!this.cads) {
            this.cads = [];
        } else {
            this.cads.splice(0, this.cads.length);
        }
        if (!this.products) {
            this.products = [];
        } else {
            this.products.splice(0, this.products.length);
        }
        if (!this.documents) {
            this.documents = [];
        } else {
            this.documents.splice(0, this.documents.length);
        }
        if (data.critical_points) {
            for (let i = 0; i < data.critical_points.length; i++) {
                const dat: any = data.critical_points[i];
                if (dat && dat.id) {
                    let critical_point: CriticalPointModel;
                    critical_point = new CriticalPointModel();
                    critical_point.clone(dat);
                    // critical_point.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    // critical_point.amount = (dat.pivot && dat.pivot.amount) ? dat.pivot.amount : dat.amount;
                    this.critical_points.push(critical_point);
                }
            }
            delete data.critical_points;
        }
        if (data.special_toolings) {
            for (let i = 0; i < data.special_toolings.length; i++) {
                const dat: any = data.special_toolings[i];
                if (dat && dat.id) {
                    let special_tooling: SpecialToolingModel;
                    special_tooling = new SpecialToolingModel();
                    special_tooling.clone(dat);
                    // special_tooling.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    // special_tooling.amount = (dat.pivot && dat.pivot.amount) ? dat.pivot.amount : dat.amount;
                    this.special_toolings.push(special_tooling);
                }
            }
            delete data.special_toolings;
        }
        if (data.cutting_tools) {
            for (let i = 0; i < data.cutting_tools.length; i++) {
                const dat: any = data.cutting_tools[i];
                if (dat && dat.id) {
                    let cutting_tool: CuttingToolModel;
                    cutting_tool = new CuttingToolModel();
                    cutting_tool.clone(dat);
                    cutting_tool.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    this.cutting_tools.push(cutting_tool);
                }
            }
            delete data.cutting_tools;
        }
        if (data.hardenings) {
            for (let i = 0; i < data.hardenings.length; i++) {
                const dat: any = data.hardenings[i];
                if (dat && dat.id) {
                    let hardening: HardeningModel;
                    hardening = new HardeningModel();
                    hardening.clone(dat);
                    hardening.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    this.hardenings.push(hardening);
                }
            }
            delete data.hardenings;
        }
        if (data.packagings) {
            for (let i = 0; i < data.packagings.length; i++) {
                const dat: any = data.packagings[i];
                if (dat && dat.id) {
                    let packaging: PackagingModel;
                    packaging = new PackagingModel();
                    packaging.clone(dat);
                    packaging.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    packaging.amount = (dat.pivot && dat.pivot.amount) ? dat.pivot.amount : dat.amount;
                    this.packagings.push(packaging);
                }
            }
            delete data.packagings;
        }
        if (data.shippings) {
            for (let i = 0; i < data.shippings.length; i++) {
                const dat: any = data.shippings[i];
                if (dat && dat.id) {
                    let shipping: ShippingModel;
                    shipping = new ShippingModel();
                    shipping.clone(dat);
                    shipping.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    // shipping.price = (dat.pivot && dat.pivot.price) ? dat.pivot.price : 0;
                    this.shippings.push(shipping);
                }
            }
            delete data.shippings;
        }
        if (data.materials) {
            for (let i = 0; i < data.materials.length; i++) {
                const dat: any = data.materials[i];
                if (dat && dat.id) {
                    let material: ProductMaterial;
                    material = new ProductMaterial();
                    material.clone(dat);
                    material.checked = (dat.checked === 0) ? false : true;
                    let cutting_material: ProductMaterial;
                    cutting_material = new ProductMaterial();
                    if (dat && dat.cutting) {
                        cutting_material.clone(dat.cutting);
                    }
                    material.cutting = cutting_material;
                    this.materials.push(material);
                }
            }
            delete data.materials;
        }
        if (data.cosmetics) {
            for (let i = 0; i < data.cosmetics.length; i++) {
                const dat: any = data.cosmetics[i];
                if (dat && dat.id) {
                    let cosmetic: CosmeticModel;
                    cosmetic = new CosmeticModel();
                    cosmetic.clone(dat);
                    cosmetic.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    this.cosmetics.push(cosmetic);
                }
            }
            delete data.cosmetics;
        }
        //
        if (data.product_processes) {
            for (let i = 0; i < data.product_processes.length; i++) {
                const dat: any = data.product_processes[i];
                if (dat && dat.id) {
                    let product_processe: ProductProcessModel;
                    product_processe = new ProductProcessModel();
                    product_processe.clone(dat);
                    // product_processe.checked = (dat.pivot && dat.pivot.checked === 0) ? false : true;
                    this.addProductProcess(product_processe);
                }
            }
            delete data.product_processes;
        }
        if (data.drawings) {
            for (let i = 0; i < data.drawings.length; i++) {
                const dat: any = data.drawings[i];
                if (dat && dat.id) {
                    let drawing: DrawingModel;
                    drawing = new DrawingModel();
                    drawing.clone(dat);
                    this.drawings.push(drawing);
                }
            }
            delete data.drawings;
        }
        if (data.cads) {
            for (let i = 0; i < data.cads.length; i++) {
                const dat: any = data.cads[i];
                if (dat && dat.id) {
                    let cad: CADModel;
                    cad = new CADModel();
                    cad.clone(dat);
                    this.cads.push(cad);
                }
            }
            delete data.cads;
        }
        if (data.products) {
            for (let i = 0; i < data.products.length; i++) {
                const dat: any = data.products[i];
                if (dat && dat.id) {
                    let product: ProductModel;
                    product = new ProductModel();
                    if (dat.pivot && dat.pivot.amount) {
                        product.amount = dat.pivot.amount;
                    } else {
                        product.amount = dat.amount;
                    }
                    product.clone(dat);
                    this.products.push(product);
                }
            }
            delete data.products;
        }
        if (data.documents) {
            for (let i = 0; i < data.documents.length; i++) {
                const dat: any = data.documents[i];
                if (dat) {
                    let doc: DocumentModel;
                    doc = new DocumentModel();
                    doc.clone(dat);
                    this.documents.push(doc);
                }
            }
            delete data.documents;
        }
        /*if (data.requirement) {
            // this.requirement = new RequirementModel();
            this.requirement.clone(data.requirement);
            delete data.requirement;
        }*/
        /*if (data.eng_user) {
            // this.eng_user = new UserModel();
            this.eng_user.clone(data.eng_user);
            delete data.eng_user;
        }
        if (data.qc_user) {
            // this.qc_user = new UserModel();
            this.qc_user.clone(data.qc_user);
            delete data.qc_user;
        }*/
        if (data.pivot) {
            if (data.pivot.amount) {
                this.amount = data.pivot.amount;
                delete data.amount;
            }
            if (data.pivot.customer_product_amount) {
                this.customer_product_amount = data.pivot.customer_product_amount;
            }
            if (data.pivot.description) {
                this.description = data.pivot.description;
            }
        }
        return super.clone(data);
    }

    public addPart(part_product: ProductModel): number {
        let length: number;
        part_product.parent_id = this.id;
        length = this.products.push(part_product);
        return length - 1;
    }

    public addProductProcess(product_process: ProductProcessModel): void {
        product_process.product_id = this.id;
        // let index: number;
        // index = this.product_processes.findIndex(item => item.process_id === product_process.process_id &&
        //     (product_process.model_name === item.model_name && product_process.model_id === item.model_id &&
        //      (product_process.child_id === item.child_id)));
        // if (index > -1) {
        //     return;
        // } else {

        // }
        // product_process.checked = true;
        if (!this.product_processes) {
            this.product_processes = [];
        }
        this.product_processes.push(product_process);
    }

    public get total_cosmetic(): number {
        let total: number;
        total = 0;
        if (this.cosmetics) {
            for (let i = 0; i < this.cosmetics.length; i++) {
                let cosmetic: CosmeticModel;
                cosmetic = this.cosmetics[i];
                if (cosmetic.price && cosmetic.checked) {
                    total += cosmetic.price;
                }
            }
        }
        return total;
    }

    public get total_critical_point(): number {
        let total: number;
        total = 0;
        if (this.critical_points) {
            for (let i = 0; i < this.critical_points.length; i++) {
                let critical_point: CriticalPointModel;
                critical_point = this.critical_points[i];
                if (critical_point.amount && critical_point.critical_point.price && critical_point.checked) {
                    total += critical_point.amount * critical_point.critical_point.price;
                }
            }
        }
        return total;
    }

    public get total_special_tooling(): number {
        let total: number;
        total = 0;
        if (this.special_toolings) {
            for (let i = 0; i < this.special_toolings.length; i++) {
                let special_tooling: SpecialToolingModel;
                special_tooling = this.special_toolings[i];
                if (special_tooling.price && special_tooling.checked) {
                    total += special_tooling.total_price;
                }
            }
        }
        return total;
    }

    public total_material_by_role(role: string): number {
        let total: number;
        total = 0;
        if (this.materials) {
            for (let i = 0; i < this.materials.length; i++) {
                let material: MaterialModel;
                material = this.materials[i];
                let amount: number;
                amount = (material.pivot && material.pivot.amount) ? material.pivot.amount : 0;
                if (amount && material.pivot.price && material.checked && material.current_role === role) {
                    total += (amount * material.pivot.price);
                }
            }
        }
        return total;
    }

    public get total_material(): number {
        let total: number;
        total = 0;
        if (this.materials) {
            for (let i = 0; i < this.materials.length; i++) {
                let product_material: ProductMaterial;
                product_material = this.materials[i];
                if (product_material && product_material.status !== 2) {
                    let amount: number;
                    amount = (product_material && product_material.amount > 0) ? product_material.amount : 0;
                    if (amount && product_material.item && product_material.item.id && product_material.checked) {
                        // total += (amount * material.price);
                        total += (product_material.total_price > 0) ? product_material.total_price : 0;
                    }
                } else {
                    //
                }
            }
        }
        return total;
    }

    public get total_product_process(): number {
        let total: number;
        total = 0;
        if (this.product_processes) {
            for (let i = 0; i < this.product_processes.length; i++) {
                let product_process: ProductProcessModel;
                product_process = this.product_processes[i];
                if (product_process.checked) {
                    // product_process.cal_price();
                    if (product_process.is_fvd) {
                        total += (product_process.fvd_price > 0) ? product_process.fvd_price : 0;
                    } else {
                        total += (product_process.price > 0) ? product_process.price : 0;
                    }
                }
            }
        }
        return total;
    }

    public get total_cutting_tool(): number {
        let total: number;
        total = 0;
        if (this.cutting_tools) {
            for (let i = 0; i < this.cutting_tools.length; i++) {
                let cutting_tool: CuttingToolModel;
                cutting_tool = this.cutting_tools[i];
                if (cutting_tool.price && cutting_tool.checked) {
                    total += cutting_tool.price;
                }
            }
        }
        return total;
    }

    public get total_hardening(): number {
        let total: number;
        total = 0;
        if (this.hardenings) {
            for (let i = 0; i < this.hardenings.length; i++) {
                let hardening: HardeningModel;
                hardening = this.hardenings[i];
                if (hardening.price && hardening.checked) {
                    total += hardening.price;
                }
            }
        }
        return total;
    }

    public get total_packaging(): number {
        let total: number;
        total = 0;
        if (this.packagings) {
            for (let i = 0; i < this.packagings.length; i++) {
                let packaging: PackagingModel;
                packaging = this.packagings[i];
                if (packaging.amount && packaging.price && packaging.checked) {
                    total += packaging.amount * packaging.price;
                }
            }
        }
        return total;
    }

    public get total_shipping(): number {
        let total: number;
        total = 0;
        if (this.shippings) {
            for (let i = 0; i < this.shippings.length; i++) {
                let shipping: ShippingModel;
                shipping = this.shippings[i];
                if ((shipping.incoterm && shipping.incoterm.price) && shipping.checked) {
                    total += shipping.incoterm.price;
                }
            }
        }
        return total;
    }

    public cal_price(): void {
        this.process_estimated_price = this.total_material + this.total_critical_point + this.total_product_process + this.total_special_tooling;
        this.estimated_price = this.process_estimated_price;

        // this.process_estimated_price = this.estimated_price = this.total_material + this.total_product_process;

        // let base_price: number;
        // base_price = this.estimated_price;
        if (this.other_price) {
            for (const other_price_key in this.other_price) {
                if (this.other_price[other_price_key] > 0) {
                    this.estimated_price += this.estimated_price * (this.other_price[other_price_key] / 100);
                }
            }
        }

        let part_total_price: number;
        part_total_price = 0;
        let part_total_market_price: number;
        part_total_market_price = 0;
        if (this.products.length > 0) {
            for (let i = 0; i < this.products.length; i++) {
                const part_product: ProductModel = this.products[i];
                if (part_product) {
                    // part_product.cal_price();
                    part_total_price += part_product.estimated_price;
                    part_total_market_price += part_product.market_price;
                }
            }
        }
        this.total_price_with_part = this.estimated_price + part_total_price;
        this.total_market_price_with_part = this.market_price + part_total_market_price;
        this.price = Math.max(this.total_price_with_part, this.total_market_price_with_part, this.market_price, this.estimated_price);
    }

    public getMaxPrice(): number {
        this.cost = Math.max(this.total_price_with_part, this.total_market_price_with_part, this.cost);
        return this.cost;
    }

    public get valid(): boolean {
        if (this.name &&
            (this.amount > 0 || (this.pivot && this.pivot.amount > 0)) &&
            this.unit &&
            // this.materials.length > 0 &&
            this.product_processes.length > 0 &&
            this.drawings.length > 0) {
            //
            return true;
        }
        return false;
    }

}
