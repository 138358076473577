import {
    Optional, Input, Output, EventEmitter, ElementRef, Directive,
    ContentChild, AfterContentInit, ViewContainerRef, NgZone
} from '@angular/core';
import {DivApiConfig} from './divApi.config';
import {Api} from '../api/api';
import {DivApiGettingDirective} from './divApiGetting.directive';
import {DivApiGotDirective} from './divApiGot.directive';
import {DivApiErrorDirective} from './divApiError.directive';
import {DivApiZeroDirective} from './divApiZero.directive';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DivApiTemplateService} from './divApiTemplate.service';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';

export const enum DivApiStatus {
    None    = 0,
    Getting = 1,
    Got     = 2,
    Error   = 3,
    Zero    = 4
}

@Directive({
    selector: '[nowDivApi]',
    exportAs: 'divApiDirective'
})
export class DivApiDirective implements AfterContentInit {

    @Input('servicePath') servicePath: string;
    @Input('method') method: string;
    @Input('params') params: any;
    @Input('request') request: any;
    @Input('auto') auto: any;

    @Output() successEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();

    @ContentChild(DivApiGettingDirective, { static: true }) divApiGetting: DivApiGettingDirective;
    @ContentChild(DivApiGotDirective, { static: true }) divApiGot: DivApiGotDirective;
    @ContentChild(DivApiErrorDirective, { static: true }) divApiError: DivApiErrorDirective;
    @ContentChild(DivApiZeroDirective, { static: true }) divApiZero: DivApiZeroDirective;

    private dom_error: any;
    private dom_getting: any;
    private dom_got: any;
    private dom_zero: any;

    private headers: HttpHeaders;

    protected _errors: any;
    protected _error_message: string;

    private current_native_element: any;
    private current_status: DivApiStatus;

    constructor(private viewRef: ViewContainerRef,
                private el: ElementRef,
                private http: HttpClient,
                private api: Api,
                private ngZone: NgZone,
                private templateService: DivApiTemplateService,
                @Optional() config: DivApiConfig) {
        //
        this.current_status = DivApiStatus.None;

        this.headers = new HttpHeaders()
            .set('Content-Type', 'text/plain');

        this.templateService.setupTemplate(config);
        this.templateService.onLoaded
            .subscribe(response => {
                this.dom_getting    = response.dom_getting;
                this.dom_zero       = response.dom_zero;
                this.dom_error      = response.dom_error;
                this.dom_got        = response.dom_got;
            }, error => {
                //
            });
    }

    ngAfterContentInit(): void {
        if (this.auto === false || this.auto === 'false') {
            //
        } else {
            this.getApi()
                .subscribe(() => {
                    //
                }, error => {
                    //
                }, () => {
                    //
                });
        }
    }

    protected onGetApiSuccessHandler(response: any): void {
        this._error_message = '';
        this._errors = null;

        const data = response.data;
        if (data instanceof Array) {
            if (data.length > 0) {
                this.show_got();
                this.current_status = DivApiStatus.Got;
            } else {
                this.show_zero();
                this.current_status = DivApiStatus.Zero;
            }
        } else {
            this.show_got();
            this.current_status = DivApiStatus.Got;
        }

        this.successEvent.emit(response);
    }

    protected onGetApiErrorHandler(error: any): void {
        this.current_status = DivApiStatus.Error;
        if (error) {
            if (error.message) {
                this._error_message = error.message;
            }
            if (error.data) {
                this._errors = error.data;
            }
        }

        this.show_error();
        this.errorEvent.emit(error);

    }

    public getApi(): Observable<any> {
        this.current_status = DivApiStatus.Getting;
        console.log('get api : ' + this.servicePath);
        return this.api.request(this.servicePath, this.method, this.params, this.request)
            .pipe(
                map((response: any) => {
                    console.log(response);
                    if (response && (response.success === true || response.data)) {
                        return this.onGetApiSuccessHandler(response);
                    } else {
                        return this.onGetApiErrorHandler(response);
                    }
                })
            );
    }

    private show_getting(): void {
        this.ngZone.run(() => {
            this.clearElement();

            let nativeElement: any;
            if (this.divApiGetting) {
                nativeElement = this.divApiGetting.el.nativeElement;
            } else {
                nativeElement = this.dom_getting;
            }

            if (nativeElement) {
                this.nativeElement.appendChild(nativeElement);
                this.current_native_element = nativeElement;
            }
        });
    }

    private show_got(): void {
        this.ngZone.run(() => {
            this.clearElement();

            let nativeElement: any;
            if (this.divApiGot) {
                nativeElement = this.divApiGot.el.nativeElement;
            } else {
                nativeElement = this.dom_got;
            }

            if (nativeElement) {
                this.nativeElement.appendChild(nativeElement);
                this.current_native_element = nativeElement;
            }
        });
    }

    private show_error(): void {
        this.ngZone.run(() => {
            this.clearElement();

            let nativeElement: any;
            if (this.divApiError) {
                nativeElement = this.divApiError.el.nativeElement;
            } else {
                nativeElement = this.dom_error;
            }

            if (nativeElement) {
                this.nativeElement.appendChild(nativeElement);
                this.current_native_element = nativeElement;
            }
        });
    }

    private show_zero(): void {
        this.ngZone.run(() => {
            this.clearElement();

            let nativeElement: any;
            if (this.divApiZero) {
                nativeElement = this.divApiZero.el.nativeElement;
            } else {
                nativeElement = this.dom_zero;
            }

            if (nativeElement) {
                this.nativeElement.appendChild(nativeElement);
                this.current_native_element = nativeElement;
            }
        });
    }

    private clearElement(): void {
        if (this.current_native_element) {
            this.current_native_element.remove();
            this.current_native_element = null;
        }
    }

    public refresh(params?: any): Observable<any> {
        if (this.servicePath) {
            if (params) {
                this.params = Object.assign(this.params, params);
            } else {
                //
            }
        }
        return this.getApi();
    }

    public get errors(): any {
        return this._errors;
    }

    public get error_message(): string {
        return this._error_message;
    }

    public get status(): DivApiStatus {
        return this.current_status;
    }

    public get nativeElement(): any {
        return this.el.nativeElement;
    }

}
