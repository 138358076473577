import {FormModel} from '../now/formModel';

export class IncotermModel extends FormModel {

    public name: string;
    public price: number;
    public ranking: number;
    public incoterm_code: string;

    constructor() {
        super('incoterms', 'App\\Incoterm');
    }

}