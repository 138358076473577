import * as uuid4 from 'uuid/v4';

export class Model {

    public task_id: string;
    public guid: string;
    public id: any;
    public model_name: string;
    public table_name: string;
    public created_at: any;
    public updated_at: any;
    public deleted_at: any;
    public cloned_at: any;
    public pivot: any;
    public reports: any;
    public is_delete: boolean;
    public raw_data: any;
    public changed_data: any;
    private is_error: boolean;
    private is_processing: boolean;
    private process_message: string;
    private error_message: string;
    private status_code: number;
    private is_new: boolean;

    constructor(table_name?: string, model_name?: string) {
        this.table_name = table_name;
        this.model_name = model_name;
        this.is_new = true;
        this.is_error = false;
        this.is_processing = false;
        this.raw_data = {};
        this.changed_data = {};
        this.id = '';
        this.status_code = null;
        this.process_message = '';
        this.error_message = '';
        this.is_delete = false;
        this.guid = uuid4();
        this.pivot = {};
    }

    public clear(): void {
        //
    }

    public clone(data: any, without: string[] = [], _delete?: boolean): Model {
        if (data) {
            for (const key in data) {
                if (typeof (<any>this)[key] !== 'function' && !(without.indexOf(key) > -1)) {
                    try {
                        if ((<any>this[key]) && (<any>this[key]).constructor() instanceof Model) {
                            (<any>this[key]).constructor().clone(data[key]);
                        } else if (<any>this[key] && (<any>this[key]).clone) {
                            (<any>this[key]).clone(data[key]);
                        } else {
                            let c: any;
                            c = this.clone_object(data[key]);
                            (<any>this.changed_data)[key] = (<any>this)[key];
                            (<any>this.raw_data)[key] = c;
                            (<any>this)[key] = c;
                        }
                    } catch (e) {
                        //
                    }
                }
            }
        } else {
            //
        }
        this.is_new = false;
        // this.cloned_at = new Date();
        return this;
    }

    private clone_object(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        let temp: any;
        temp = obj.constructor(); // give temp the original obj's constructor
        if (temp) {
            for (const key in obj) {
                if (key) {
                    try {
                        temp[key] = this.clone_object(obj[key]);
                    } catch (e) {
                        //
                    }
                }
            }
            return temp;
        } else {
            return null;
        }
    }

    public error(error: any): void {
        this.is_error = true;

        this.is_processing = false;
        this.process_message = undefined;

        if (error && error.message) {
            this.error_message = error.message;
        }

        if (error && error.status) {
            this.status_code = error.status;
        }
    }

    // public process(message: string = ''): void {
    //     this.is_processing = true;
    //     this.process_message = message;
    // }

    public get isNew(): boolean {
        return this.is_new;
    }

    public reset(): void {
        this.is_error = false;
        this.error_message = undefined;

        this.is_processing = false;
        this.process_message = undefined;

        this.raw_data = {};
        try {
            for (const key in this.changed_data) {
                if (key) {
                    const value = (<any>this.changed_data)[key];
                    (<any>this[key]) = value;
                }
            }
        } catch (e) {
            //
        }
    }

    public getErrorMessage(): string {
        return this.error_message;
    }

    public getProcessMessage(): string {
        return this.process_message;
    }

}
