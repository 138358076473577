import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';

@Component({
    selector: 'sale-order-modal',
    templateUrl: 'saleOrder.modal.html',
    styleUrls: ['saleOrder.modal.scss']
})
export class SaleOrderModal implements OnInit, OnDestroy {

    @ViewChild('modal', { static: false }) smartModal: NgxSmartModalComponent;
    public tmp_sale_order;

    constructor() {
        //
    }

    ngOnInit() {
        this.tmp_sale_order = {};
    }

    ngOnDestroy() {
    }

    public onSubmit(form) {
        //
    }

    public open() {
        this.smartModal.open();
        this.tmp_sale_order = {};
    }

    public onOpen(event) {
        //
    }

    public onDismiss(event) {
        //
    }

    public onClose(event) {
        //
    }

}
