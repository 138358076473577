import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'documentStatus'
})
export class DocumentStatusPipe implements PipeTransform {

    transform(status: any): string {
        const status_int: number = parseInt(status);
        if (status_int === 1) {
            return 'ข้อมูลถูกต้อง';
        } else if (status_int === 2) {
            return 'ยกเลิกเอกสาร';
        }
        return status;
    }

}
