import {AfterViewInit, Directive, EventEmitter, Input, Output, Self} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserService} from '../services/user.service';
import {TaskModel} from '../models/task.model';
import {debounceTime} from 'rxjs/operators';

const DEFAULT_DELAY = 800;

@Directive({
    selector: '[autoSave]',
    exportAs: 'autoSaveDirective'
})
export class AutoSaveDirective implements AfterViewInit {

    @Output('save-api') saveApiCallback: EventEmitter<any> = new EventEmitter<any>();

    @Input() delay: number;
    @Input() task: TaskModel;
    @Input() ready: boolean;

    private ngForm: NgForm;
    public rev: number;

    constructor(private userService: UserService, @Self() ngForm: NgForm) {
        this.ngForm = ngForm;
    }

    ngAfterViewInit(): void {
        this.rev = 0;
        this.ngForm.valueChanges
            .pipe(debounceTime(DEFAULT_DELAY))
            .subscribe(_ => this.onValueChange(_));
    }

    public onValueChange(data: any): void {
        if (this.ready === true) {
            if (this.task && this.task.id && this.task.current_user_id === this.userService.getID() && this.task.current_user_id) {
                this.rev++;
                this.saveApiCallback.emit({
                    data: data,
                    rev: this.rev
                });
            } else {
                //
            }
        }
    }

}
