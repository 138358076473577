import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {TaskListComponent} from '../../taskList.component';
import {SwalService} from '../../../../../services/swal.service';
import {Api} from '../../../../../now/api/api';
import {InvoiceModel} from '../../../../../models/invoice.model';
import {Viewer} from '../../../../../services/viewer';
import {ReceiptModel} from '../../../../../models/receipt.model';
import {ReceiptDetailComponent} from '../../../view/receiptDetail/receiptDetail.component';
import {ModalService} from '../../../../../services/modal.service';

@Component({
    selector: 'receive-task-list-modal',
    templateUrl: 'receiveTaskList.modal.html',
    styleUrls: ['receiveTaskList.modal.scss']
})
export class ReceiveTaskListModal implements AfterViewInit {

    @ViewChild('modal', { static: true }) smartModal: NgxSmartModalComponent;

    @Output('submitting') onSubmitting: EventEmitter<any> = new EventEmitter<any>();
    @Output('submit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output('uploaded') onUploaded: EventEmitter<any> = new EventEmitter<any>();

    public invoices: InvoiceModel[];
    public tmp_keyword: string;
    public customer: any;
    public tasks: any[];
    public parent: TaskListComponent;
    public _all: boolean;

    constructor(
        private swal: SwalService,
        private api: Api,
        private modal: ModalService,
        private viewer: Viewer
    ) {
        //
        this.customer = {};
        this.tasks = [];
        this.invoices = [];
        this.tmp_keyword = '';
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._all = false;
        }, 0);
    }

    public createReceipt(invoice) {
        const customerName = this.customer.name;
        const invoiceNO = invoice.invoice_no;
        this.swal.confirm('ยืนยันการออกใบเสร็จรับเงินให้กับลูกค้า "' + customerName + '", ใบแจ้งหนี้เลขที่ "' + invoiceNO + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    let receipt: ReceiptModel;
                    receipt = new ReceiptModel();
                    receipt.status = 1;
                    receipt.grand_total = invoice.grand_total;
                    receipt.total_price = invoice.grand_total;
                    this.modal.show(ReceiptDetailComponent, {
                        receipt     : receipt,
                        model       : invoice,
                        // invoice     : invoice
                        // quotation   : this.quotation,
                        // task        : this.task,
                        // job         : this.job,
                    }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
                        .then((content: any): void => {
                            if (content && content.submit === true) {
                                receipt = content.receipt;
                                this.viewer.receipt(receipt);
                                // this.quotation.receipts.push(receipt);
                            } else {
                                //
                            }
                        });
                }
            });
    }

    public viewReceipt(receipt) {
        this.viewer.receipt(receipt);
    }

    public onClose(e?: any): void {
        // this.removeRemark();
    }

    public onDismiss(e?: any): void {
        //
    }

    public onOpen(e?: any): void {
        this._all = false;
        for (const task of this.tasks) {
            task._checked = false;
        }
    }

    public onAllChange(e: any): void {
        if (e) {
            for (const task of this.tasks) {
                task._checked = true;
            }
        } else {
            for (const task of this.tasks) {
                task._checked = false;
            }
        }
    }

    public open(customer: any, parent?: any): void {
        this.customer = customer;
        // this.tasks = this.customer.children;
        // this.parent = parent;
        this.getTasks()
            .then(invoices => {
                this.invoices = invoices;
            });
        this.smartModal.open();
    }

    public close(): void {
        this.smartModal.close();
    }

    public submit(): void {
        let tasks: any[];
        tasks = [];
        for (const task of this.tasks) {
            if (task && task._checked) {
                tasks.push(task);
            }
        }
        if (tasks.length) {
            this.swal.confirm('ยืนยันการรวมงานของลูกค้า "' + this.customer.customer_name + '" เป็นจำนวน ' + tasks.length + ' งาน ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.onSubmit.emit({
                            tasks: tasks,
                            customer: this.customer
                        });
                        this.close();
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('เลือกรายการอย่างน้อย 1 รายการ');
        }
    }

    public viewProduct(product: any): void {
        this.viewer.product(product);
    }

    public viewJob(job: any): void {
        this.viewer.manufacture(job);
    }

    public viewInvoice(invoice: any): void {
        this.viewer.invoice(invoice);
    }

    public getTasks(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('tasks/receive/' + this.customer.id, 'GET', {
                //
            }).subscribe((response: any): void => {
                const invoices = [];
                if (response && response.success) {
                    for (const dat of response.data) {
                        if (dat && dat.id) {
                            const invoice = new InvoiceModel();
                            invoice.clone(dat);
                            invoice.job_no_list = (dat.job_no) ? dat.job_no.split(',') : [];
                            // invoice.product_no_list = (dat.product_no) ? dat.product_no.split(',') : [];
                            invoice.drawing_no_list = (dat.drawing_no) ? dat.drawing_no.split(',') : [];
                            invoice.product_name_list = (dat.product_name) ? dat.product_name.split(',') : [];
                            invoice.purchase_order_no_list = (dat.purchase_order_no) ? dat.purchase_order_no.split(',') : [];
                            invoices.push(invoice);
                        }
                    }
                }
                resolve(invoices);
            });
        });
        return promise;
    }

}
