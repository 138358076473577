import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TaskModel} from '../../../../models/task.model';
import {Api} from '../../../../now/api/api';

@Component({
    selector: 'viewRemark-component',
    templateUrl: 'viewRemark.component.html',
    styleUrls: ['./viewRemark.component.scss']
})
export class ViewRemarkComponent implements OnInit {

    public task: TaskModel;

    constructor(public bsModalRef: BsModalRef, private api: Api) {
        //
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task) {
                this.task = this.bsModalRef.content.task;
            }
            if (this.task && (!this.task.remarks || this.task.remarks.length === 0)) {
                this.getRemarks()
                    .then(() => {
                        //
                    });
            } else {
                //
            }
        }, 0);
    }

    private getRemarks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/remarks', 'POST', {}, {
                id: this.task.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    this.task.remarks = response.data;
                }
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public hide(): void {
        this.bsModalRef.hide();
    }

}
