import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {CriticalPointModel} from '../../../../models/criticalPoint.model';
import {Location} from '@angular/common';

@Component({
    selector: 'critical-point-view-component',
    templateUrl: 'criticalPointView.component.html',
    styleUrls: ['criticalPointView.component.scss']
})
export class CriticalPointViewComponent implements OnInit {

    public critical_point: CriticalPointModel;
    public critical_point_id: string;
    public current_tab: string;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private location: Location,
                private appService: AppService,
                private modelApi: ModelApi,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.critical_point = new CriticalPointModel();
        this.route.params
            .subscribe(params => {
                this.critical_point_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getCriticalPoint()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getCriticalPoint(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.critical_point_id) {
                this.api.request('critical/points/' + this.critical_point_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.critical_point.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.critical_point);
            }
        });
        return promise;
    }

    public save(): void {
        if (!this.critical_point.name) {
            this.swal.danger('กรุณากรอกชื่อจุดวิกฤต');
        } else if (!this.critical_point.price) {
            this.swal.danger('กรุณากรอกราคาจุดวิกฤตสำหรับการประเมินการผลิต');
        } else if (!this.critical_point.ranking) {
            this.swal.danger('กรุณาระบุRANK');
        } else {
            this.modelApi.createOrUpdate(this.critical_point, [
                // profile
                'name', 'price', 'ranking'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/criticalpoint/' + this.critical_point.id + '/detail');
                this.swal.success('บันทึกข้อมูลจุดวิกฤตสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createCriticalPoint(): void {
        this.save();
    }

}
