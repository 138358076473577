import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {CosmeticModel} from '../models/cosmetic.model';

@Injectable()
export class CosmeticService {

    private cosmetics: CosmeticModel[];

    constructor(private api: Api) {
        this.reset_cosmetic_list();
    }

    public set(list: CosmeticModel[]): void {
        this.reset_cosmetic_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const cosmetic: CosmeticModel = new CosmeticModel();
                    cosmetic.clone(dat);
                    this.cosmetics.push(cosmetic);
                }
            }
        }
    }

    public getCosmetics(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('cosmetics', 'GET', {}, {})
                .subscribe((response: any): void => {
                    this.reset_cosmetic_list();
                    if (response && response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            let dat: any;
                            dat = response.data[i];
                            if (dat) {
                                this.cosmetics.push(dat);
                            }
                        }
                        resolve(this.cosmetics);
                    } else {
                        reject([]);
                    }
                }, error => {
                    reject([]);
                });
        });
        return promise;
    }

    private reset_cosmetic_list(): void {
        if (this.cosmetics) {
            this.cosmetics.splice(0, this.cosmetics.length);
        } else {
            this.cosmetics = [];
        }
    }

}
