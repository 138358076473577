import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserModel} from '../../../../now/user/user.model';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {RoleModel} from '../../../../models/role.model';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import * as moment from 'moment';
import {AppService} from '../../../../app/app.service';
import {NgForm} from '@angular/forms';
import {SchedulerHistoryModel} from '../../../../models/schedulerHistory.model';
import {FullCalendarComponent} from '../../../../components/fullCalendar/fullCalendar.component';
import {ModalService} from '../../../../services/modal.service';
import {AbsenceModalComponent} from '../absence/absenceModal.component';
import {LeaveModalComponent} from '../leave/leaveModal.component';
import {LateModalComponent} from '../late/lateModal.component';
import {FireModalComponent} from '../fire/fireModal.component';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../../environments/environment';
import {ProcessModel} from '../../../../models/process.model';
import {ProcessService} from '../../../../services/process.service';

@Component({
    selector: 'user-view-component',
    templateUrl: 'userView.component.html',
    styleUrls: ['userView.component.scss']
})
export class UserViewComponent implements OnInit {

    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    public all_role: boolean;
    public all_process: boolean;

    public database_pages: any[];

    public user: UserModel;
    public user_id: string;
    public current_tab: string;

    public processes: ProcessModel[];
    public roles: RoleModel[];
    public tasks: any[];

    public leaveHistories = [];
    public product_processes: any[];
    public scheduler_histories: SchedulerHistoryModel[];

    public today: string;
    public absence_num: number;
    public leave_num: number;
    public late_num: number;

    public user_events: any[];
    public ready: boolean;
    public options: any;

    public user_username: string;
    public user_password: string;
    public user_password_confirmation: string;

    public currentYear;
    public currentDate;
    public activities;
    public sum_vacation = 0;
    public sum_personal = 0;
    public sum_sick = 0;
    public sum_maternity = 0;
    public sum_force = 0;
    public sum_other = 0;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        private processService: ProcessService,
        private appService: AppService,
        private modelApi: ModelApi,
        private modal: ModalService,
        private swal: SwalService
    ) {
        //
        this.currentDate = moment(new Date());
        this.currentYear = this.currentDate.format('YYYY');
        this.database_pages = [];
        this.user = new UserModel();
        this.roles = [];
        this.processes = [];
        this.route.params
            .subscribe(params => {
                this.user_id = params['id'];
            });

        this.today = moment(new Date()).format('MMMM YYYY');
        this.absence_num = 0;
        this.leave_num = 0;
        this.late_num = 0;

        this.tasks = [];
        this.current_tab = 'profile';

        this.ready = false;
        this.user_events = [];
        this.scheduler_histories = [];
        this.options = {
            resources: []
        };
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getUserProfile()
                .then(() => {
                    this.user_username = this.user.username;
                    this.getRoles();
                    this.getProcesses();
                    this.getSchedulerHistoriesByUserId();
                    this.getSchedulersByUsers()
                        .then(() => {
                            this.ready = true;
                            setTimeout(() => {
                                if (this.fullCalendarComponent) {
                                    this.fullCalendarComponent.addEventSource(this.user_events);
                                } else {
                                    //
                                }
                            }, 250);
                        });
                    this.getLeaveHistories();
                    this.getDatabasePages();
                });
        }, 0);
    }

    public getLeaveHistories(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('users/' + this.user_id + '/leaves', 'GET')
                .subscribe((res: any): void => {
                    this.leaveHistories = [];
                    if (res && res.data) {
                        for (const dat of res.data) {
                            this.leaveHistories.push(dat);
                        }
                    }
                    if (this.leaveHistories && this.leaveHistories.length) {
                        for (const activity of this.leaveHistories) {
                            if (activity) {
                                const subject = activity.subject;
                                if (subject === 'VACATION') {
                                    this.sum_vacation += (activity.days) ? activity.days : 0;
                                } else if (subject === 'PERSONAL') {
                                    this.sum_personal += (activity.days) ? activity.days : 0;
                                } else if (subject === 'SICK') {
                                    this.sum_sick += (activity.days) ? activity.days : 0;
                                } else if (subject === 'MATERNITY') {
                                    this.sum_maternity += (activity.days) ? activity.days : 0;
                                } else if (subject === 'FORCE') {
                                    this.sum_force += (activity.days) ? activity.days : 0;
                                } else if (subject === 'OTHER') {
                                    this.sum_other += (activity.days) ? activity.days : 0;
                                }
                            }
                        }
                    }
                    resolve([]);
                });
        });
        return promise;
    }

    public getDatabasePages(): void {
        if (this.database_pages) {
            this.database_pages.splice(0, this.database_pages.length);
        } else {
            this.database_pages = [];
        }
        for (let i = 0; i < this.appService.pages.length; i++) {
            const p = this.appService.pages[i];
            if (p && p.children) {
                this.database_pages.push({
                    display: p.display,
                    children: []
                });
                for (let j = 0; j < p.children.length; j++) {
                    if (this.user.databases) {
                        const index_exists: number = this.user.databases.indexOf(p.children[j].key);
                        if (index_exists > -1) {
                            p.children[j].checked = true;
                        } else {
                            p.children[j].checked = false;
                        }
                        this.database_pages[i].children.push(p.children[j]);
                    } else {
                        p.checked = false;
                        this.database_pages[i].children.push(p.children[j]);
                    }
                }
            }
        }
    }

    public getUserProfile(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.user_id) {
                this.api.request('users/' + this.user_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.onSuccess(response.data);
                        resolve(response.data);
                    }, error => {
                        this.onError(error);
                        reject(error);
                    });
            } else {
                resolve(this.user);
            }
        });
        return promise;
    }

    public onDatabasePageValueChange(page: any, e?: any): void {
        //
    }

    private getSchedulersByUsers(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('schedulers/' + this.user_id + '/user', 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat: any = response.data[i];
                        if (dat) {
                            this.user_events.push({
                                index: 0,
                                start: dat.start,
                                end: dat.end,
                                title: (dat.job) ? dat.job.job_no : 'UNKNOW',
                                editable: false,
                                color: '#03A9F4'
                            });
                        }
                    }
                }
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private getSchedulerHistoriesByUserId(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('users/' + this.user_id + '/histories', 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                this.product_processes = [];
                if (response && response.data) {
                    this.product_processes = response.data;
                    for (const product_process of this.product_processes) {
                        product_process.sum_minutes = 0;
                        product_process.sum_gap_time_minutes = 0;
                        if (product_process.scheduler_histories) {
                            for (const scheduler_history of product_process.scheduler_histories) {
                                product_process.sum_minutes += scheduler_history.minutes;
                                product_process.sum_gap_time_minutes += scheduler_history.gap_time_minutes;
                            }
                        }
                    }
                    // for (let i = 0; i < response.data.length; i++) {
                    //     const dat: any = response.data[i];
                    //     if (dat) {
                    //         let scheduler_history: SchedulerHistoryModel;
                    //         scheduler_history = new SchedulerHistoryModel();
                    //         scheduler_history.clone(dat);
                    //         this.scheduler_histories.push(scheduler_history);
                    //     }
                    // }
                }
                resolve(this.product_processes);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private setup_roles(): void {
        let is_all_role: boolean;
        is_all_role = true;
        if (this.roles && this.roles.length > 0 && this.user && this.user.roles && this.user.roles.length > 0) {
            for (let i = 0; i < this.roles.length; i++) {
                if (this.user.roles.indexOf(this.roles[i].name) > -1) {
                    this.roles[i].checked = true;
                } else {
                    is_all_role = false;
                }
            }
        }
        if (is_all_role) {
            this.all_role = true;
        }
    }

    private setup_processes(): void {
        let is_all_process: boolean;
        is_all_process = true;
        if (this.processes && this.processes.length > 0 && this.user && this.user.processes && this.user.processes.length > 0) {
            for (let i = 0; i < this.processes.length; i++) {
                if (this.user.processes.indexOf(this.processes[i].slug) > -1) {
                    this.processes[i].checked = true;
                } else {
                    is_all_process = false;
                }
            }
        }
        if (is_all_process) {
            this.all_process = true;
        }
    }

    private getProcesses(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.processService.getProcesses()
                .then((processes: ProcessModel[]): void => {
                    this.processes = processes;
                    this.setup_processes();
                    resolve(processes);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getRoles(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('roles')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat) {
                                let role: RoleModel;
                                role = new RoleModel();
                                role.clone(dat);
                                this.roles.push(role);
                            }
                        }
                    }
                    this.setup_roles();
                }, error => {
                    //
                });
        });
        return promise;
    }

    public onSuccess(data: any): void {
        if (data) {
            this.user.clone(data);
            if (this.user.activities && this.user.activities.absence && this.user.activities.absence.value > 0) {
                this.absence_num = this.user.activities.absence.value;
            }
            if (this.user.activities && this.user.activities.late && this.user.activities.late.value > 0) {
                this.late_num = this.user.activities.late.value;
            }
            if (this.user.activities && this.user.activities.leave && this.user.activities.leave.value > 0) {
                this.leave_num = this.user.activities.leave.value;
            }
        }
        this.setup_roles();
        this.setup_processes();
    }

    public onError(e: any): void {
        //
    }

    public createMan(): void {
        //
    }

    public save(): void {
        this.modelApi.update(this.user, [
            // Profile
            'avatar', 'prefix', 'full_name', 'nick_name', 'email', 'telephone', 'telephone2', 'fax', 'position', 'ranking', 'price',
            'prefix_en', 'full_name_en', 'nick_name_en', 'gender', 'profile', 'benefits',
            // Work
            'position', 'salary', 'price', 'ranking', 'level'
        ]).subscribe((response: any): void => {
            let role_ids: string[];
            role_ids = this.get_checked_role_ids();
            this.api.request('users/roles', 'POST', {}, {
                id: this.user_id,
                role_ids: role_ids
            }).subscribe((): void => {
                let process_ids: string[];
                process_ids = this.get_checked_process_ids();
                this.api.request('users/processes', 'POST', {}, {
                    id: this.user_id,
                    process_ids: process_ids
                }).subscribe((): void => {
                    let databases: any;
                    databases = this.get_databases();
                    this.api.request('users/databases', 'POST', {}, {
                        id: this.user_id,
                        databases: databases
                    }).subscribe(() => {
                        this.swal.success('บันทึกข้อมูลพนักงานสำเร็จ');
                    });
                });
            }, error => {
                //
            });
        }, error => {
            this.swal.danger(error);
        });
    }

    private get_databases(): any {
        let _: string[];
        _ = [];
        for (let i = 0; i < this.database_pages.length; i++) {
            const database_page: any = this.database_pages[i];
            if (database_page && database_page.children) {
                for (let j = 0; j < database_page.children.length; j++) {
                    if (database_page && database_page.children[j].checked) {
                        _.push(database_page.children[j].key);
                    }
                }
            }
        }
        return _;
    }

    public onUploadedSuccess(data: any): void {
        if (data && data.preview) {
            this.user.avatar = data.preview;
            this.modelApi.update(this.user, ['avatar'])
                .subscribe(() => {});
        }
    }

    public onUploadError(error: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('users/document', 'PUT', {}, {
            document: data,
            id: this.user.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.user.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            user: this.user,
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentPreview(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        window.open(environment.api_host + 'view/' + doc.id + '/user/document/', '_blank');
    }

    private onProcessValueChange(process: ProcessModel, e?: any): void {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (process.checked) {
            for (let i = 0; i < this.processes.length; i++) {
                if (!this.processes[i].checked) {
                    this.all_process = false;
                    return;
                }
            }
            this.all_process = true;
        } else if (!process.checked) {
            this.all_process = false;
        }
    }

    public onAllProcessValueChange(e?: any): void {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        for (let i = 0; i < this.processes.length; i++) {
            this.processes[i].checked = (this.all_process) ? true : false;
        }
    }

    public onAllValueChange(e?: any): void {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        for (let i = 0; i < this.roles.length; i++) {
            this.roles[i].checked = (this.all_role) ? true : false;
        }
    }

    private onValueChange(role: RoleModel, e?: any): void {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (role.checked) {
            for (let i = 0; i < this.roles.length; i++) {
                if (!this.roles[i].checked) {
                    this.all_role = false;
                    return;
                }
            }
            this.all_role = true;
        } else if (!role.checked) {
            this.all_role = false;
        }
    }

    public onProfileSubmit(form: NgForm): void {
        if (form.valid) {
            this.modelApi.update(this.user, [
                'avatar', 'prefix', 'full_name', 'nick_name', 'email', 'telephone', 'telephone2', 'fax', 'position', 'ranking', 'price',
                'prefix_en', 'full_name_en', 'nick_name_en', 'gender', 'profile'
            ], 'users/profile').subscribe((response: any): void => {
                this.swal.success('บันทึกข้อมูลพนักงานสำเร็จ', null, 2000);
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

    public onWorkSubmit(form: NgForm): void {
        if (form.valid) {
            this.modelApi.update(this.user, [
                'position', 'salary', 'price', 'ranking'
            ], 'users/profile').subscribe((response: any): void => {
                this.swal.success('บันทึกข้อมูลพนักงานสำเร็จ', null, 2000);
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

    public onProcessSubmit(form: NgForm): void {
        //
    }

    public onRoleSubmit(form: NgForm): void {
        if (form.valid) {
            let role_ids: string[];
            role_ids = this.get_checked_role_ids();
            this.api.request('users/roles', 'POST', {}, {
                id: this.user_id,
                role_ids: role_ids
            }).subscribe((response: any): void => {
                this.swal.success('บันทึกการเข้าถึงข้อมูลสำเร็จ', null, 2000);
            }, error => {
                //
            });
        } else {
            this.modelApi.update(this.user, [
                'avatar', 'prefix', 'full_name', 'nick_name', 'email', 'telephone', 'telephone2', 'fax', 'position', 'ranking', 'price'
            ], 'users/profile').subscribe((response: any): void => {
                this.swal.success('บันทึกข้อมูลพนักงานสำเร็จ');
            });
        }
    }

    public onDatabaseForm(form: NgForm): void {
        if (form.valid) {
            //
        } else {
            //
        }
    }

    public onPasswordSubmit(form: NgForm): void {
        if (form.valid) {
            this.api.request('users/password', 'POST', {}, {
                id: this.user_id,
                password: this.user_password,
                password_confirmation: this.user_password_confirmation
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.swal.success('บันทึกรหัสผ่านใหม่สำเร็จ', null, 2000);
                } else if (response && response.message) {
                    this.swal.danger(response.message);
                }
            }, error => {
                this.swal.danger(error);
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

    public onUsernameSubmit(form: NgForm): void {
        if (form.valid) {
            this.api.request('users/username', 'POST', {}, {
                id: this.user_id,
                username: this.user_username
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.swal.success('แก้ไขชื่อเข้าใช้งานสำเร็จ', null, 2000);
                } else if (response && response.message) {
                    this.swal.danger(response.message);
                }
            }, error => {
                this.swal.danger(error);
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

    public onBenefitSubmit(form: NgForm): void {
        if (form.valid) {
            this.api.request('users/benefits', 'POST', {}, {
                id: this.user_id,
                benefits: this.user.benefits
            }).subscribe((response: any): void => {
                this.swal.success('บันทึกสวัสดิการขั้นพื้นฐานสำเร็จ', null, 2000);
            }, error => {
                //
            });
        } else {
            //
        }
    }

    public onStartUploader(data: any): void {
        if (this.user && this.user.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
        }
    }

    private get_checked_process_ids(): string[] {
        let checked_process_ids: string[];
        checked_process_ids = [];
        for (let i = 0; i < this.processes.length; i++) {
            if (this.processes[i].checked) {
                checked_process_ids.push(this.processes[i].id);
            }
        }
        return checked_process_ids;
    }

    private get_checked_role_ids(): string[] {
        let checked_role_ids: string[];
        checked_role_ids = [];
        for (let i = 0; i < this.roles.length; i++) {
            if (this.roles[i].checked) {
                checked_role_ids.push(this.roles[i].id);
            }
        }
        return checked_role_ids;
    }

    public doAbsence(): void {
        this.modal.show(AbsenceModalComponent, {
            user: this.user
        }).then((content: any): void => {
            if (content) {
                //
            }
        });
    }

    public doLeave(): void {
        this.modal.show(LeaveModalComponent, {
            user: this.user
        }).then((content: any): void => {
            if (content) {
                //
            }
        });
    }

    public doLate(): void {
        this.modal.show(LateModalComponent, {
            user: this.user
        }).then((content: any): void => {
            if (content) {
                //
            }
        });
    }

    public doFire(): void {
        this.modal.show(FireModalComponent, {
            user: this.user
        }).then((content: any): void => {
            if (content) {
                //
            }
        });
    }

    public create(): void {
        //
    }

}
