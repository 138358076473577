import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {TaskModel} from '../../../models/task.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Api} from '../../../now/api/api';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {SchedulerModel} from '../../../models/scheduler.model';
import {TaskService} from '../../../services/task.service';
import {SwalService} from '../../../services/swal.service';
import {JobModel} from '../../../models/job.model';
import {UserService} from '../../../services/user.service';
import {ModalService} from '../../../services/modal.service';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {DocumentModel} from '../../../models/document.model';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {ProcessModel} from '../../../models/process.model';
import {ViewTask} from '../view/viewTask';
import {HardeningModel} from '../../../models/hardening.model';
import {CosmeticModel} from '../../../models/cosmetic.model';
import {CuttingToolModel} from '../../../models/cuttingTool.model';
import {MaterialModel} from '../../../models/material.model';
import {PackagingModel} from '../../../models/packaging.model';
import {IncotermModel} from '../../../models/incoterm.model';
import {CarrierModel} from '../../../models/carrier.model';
import {CriticalPointModel} from '../../../models/criticalPoint.model';
import {SpecialToolingModel} from '../../../models/specialTooling.model';
import {FormModel} from '../../../now/formModel';
import {ViewRemarkComponent} from '../view/viewRemark/viewRemark.component';
import {AddRemarkComponent} from '../view/addRemark/addRemark.component';
import {DOCUMENT, Location} from '@angular/common';
import {MachineService} from '../../../services/machine.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {StoreService} from '../../../services/store.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {JobService} from '../../../services/job.service';
import {PlannerComponent} from './planner/planner.component';
import {RemarkModal} from '../../../modals/remark/remark.modal';
import {ProductMaterial} from '../../../models/productMaterial';
import {MaterialTableComponent} from '../../../components/materialTable/materialTable.component';
import {JobRemark} from '../../../app/api/job_remark';
import {LoaderService} from '../../../components/loader/loader.service';
import {NCR} from '../../../app/api/ncr';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'app-planning-task-component',
    templateUrl: 'planningTask.component.html',
    styleUrls: ['planningTask.component.scss']
})
export class PlanningTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(PlannerComponent, { static: false }) plannerComponent: PlannerComponent;
    @ViewChild('planningRemarkModal', { static: true }) planningRemarkModal: RemarkModal;
    @ViewChild('rejectRemarkModal', { static: true }) rejectRemarkModal: RemarkModal;
    @ViewChild('noticeRemarkModal1', { static: true }) noticeRemarkModal1: RemarkModal;
    @ViewChild('noticeRemarkModal2', { static: true }) noticeRemarkModal2: RemarkModal;

    public job_ids: any[];
    public job_remark_id: string;
    public job_remark: JobRemark;
    public ncr_pdf_path: string;
    public task_id: string;
    public product: ProductModel;
    public product_id: string;
    public task: TaskModel;
    public job: JobModel;
    public product_processes: ProductProcessModel[];
    public hardenings: HardeningModel[];
    public critical_points: CriticalPointModel[];
    public cosmetics: CosmeticModel[];
    public cutting_tools: CuttingToolModel[];
    public materials: MaterialModel[];
    public packagings: PackagingModel[];
    public incoterms: IncotermModel[];
    public product_process: ProductProcessModel;
    public carriers: CarrierModel[];
    public special_toolings: SpecialToolingModel[];
    public total_material_sale: number;
    public total_special_tooling_sale: number;
    public total_critical_point_price: number;
    public material_invalid: boolean;
    public children: any[];
    public new_tasks: TaskModel[];
    public processes: ProcessModel[];
    public parent_job: any;
    public delivery_date: string;
    public schedulers: SchedulerModel[];
    public calendar_schedulers: SchedulerModel[];
    public events: any[];
    public event_options: any[];
    public current_tab: string;
    public options: any;
    public ready: boolean;
    public filter: string[];
    public men: UserModel[];
    public machineEvent: boolean;
    public manEvent: boolean;
    public now_date: string;
    public is_calendar: boolean;
    public pdfInfo: any;
    public numPages: number[];
    public job_id: string;
    public ncrs: NCR[];
    public timeout: any;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private router: Router,
        public api: Api,
        public taskService: TaskService,
        private swal: SwalService,
        private modelApi: ModelApi,
        public modal: ModalService,
        private storeService: StoreService,
        public location: Location,
        private pageScrollService: PageScrollService,
        private ngZone: NgZone,
        private machineService: MachineService,
        public userService: UserService,
        private loader: LoaderService,
        private smartModalService: NgxSmartModalService,
        private jobService: JobService,
        @Inject(DOCUMENT) private document: any,
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.material_invalid = false;
        this.carriers = [];
        this.processes = [];
        this.hardenings = [];
        this.critical_points = [];
        this.cosmetics = [];
        this.cutting_tools = [];
        this.materials = [];
        this.packagings = [];
        this.incoterms = [];
        this.special_toolings = [];
        this.job_ids = [];
    }

    private init(): void {
        this.machineEvent = true;
        this.manEvent = true;

        this.new_tasks = [];
        this.filter = [];
        this.current_tab = '#calendar';
        this.product = new ProductModel();
        this.task = new TaskModel();
        this.job = new JobModel();

        this.calendar_schedulers = [];
        this.events = [];
        this.men = [];
        // this.tmp_product = {};
        this.ready = false;
        this.options = {
            resources: []
        };
        this.event_options = [{
            display: 'แสดงรายการทั้งหมด',
            line: '',
            value: -1
        }];

        this.children = [];

        this.is_calendar = false;
        this.now_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }

    public gotoParent(): void {
        if (this.parent_job) {
            this.router.navigateByUrl('/task/' + this.task.current_role + '/planning/' + this.parent_job.task_id);
        } else {
            //
        }
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('jobs/document', 'PUT', {}, {
            document    : data,
            id          : this.job.id,
            // role        : this.task.current_role
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.job.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public onRejectRemarkModalSubmit(e: any): void {
        let to_role: string;
        let to_process: string;
        let to_status: number;
        if (this.task.current_role === 'planning' || this.task.current_role === 'planning') {
            to_role = 'rejected';
            to_process = 'rejected';
            to_status = 1;
        } else if (this.task.current_role === 'control') {
            to_role = 'planning';
            to_process = 'planning';
            to_status = 9;
        }
        this.api.request('jobs/reject', 'POST', {}, {
            id: this.job.id,
            remark: e.remark_message,
        }).subscribe((response: any): void => {
            if (response && response.success === true) {
                this.taskService.setStatus(this.task, to_status, 'planning_reject', to_role, to_process)
                    .then((task: TaskModel): void => {
                        this.task.status = to_status;
                        this.swal.success('ปฏิเสธงานสำเร็จ');
                        this.leave(true);
                    });
            } else {
                this.swal.danger(response.message);
            }
        }, error => {
            this.swal.danger(error);
        });
    }

    public onNoticeRemarkModal1Submit(e: any): void {
        this.api.request('job/remarks', 'PUT', {}, {
            message: e.remark_message,
            tag: 'confirm_shipping_date',
            job_id: this.job.id,
        }).subscribe((response: any): void => {
            this.setTaskStatus(this.task.status, 'notice', 'notice', 'notice', false)
                .then(() => {
                    this.swal.success('ส่งคำร้องขอให้ Sale Admin สำเร็จ');
                    this.leave(true);
                });
        });
    }

    public onNoticeRemarkModal2Submit(e: any): void {
        this.api.request('job/remarks', 'PUT', {}, {
            message: e.remark_message,
            tag: 'confirm_shipping_date',
            job_id: this.job.id,
        }).subscribe((response: any): void => {
            this.setTaskStatus(this.task.status, 'notice', 'notice', 'notice', false)
                .then(() => {
                    this.swal.success('ส่งคำร้องขอให้ Sale Admin สำเร็จ');
                    this.leave(true);
                });
        });
    }

    public onPurchaseNoticeRemarkModalSubmit(e: any): void {
        this.api.request('job/remarks', 'PUT', {}, {
            message: e.remark_message,
            tag: 'planning_purchase_notice',
            job_id: this.job.id,
        }).subscribe((response: any): void => {
            this.setTaskStatus(this.task.status, 'create_pricing_inquiry', 'request-purchase', 'create_pricing_inquiry', false)
                .then(() => {
                    this.swal.success('แจ้งกับแผนก PR สำเร็จ')
                        .then(() => {
                            this.leave(true);
                        });
                });
        });
    }

    public onRemarkModalSubmit(e: any): void {
        this.jobService.reportJob(this.job, e.remark_message)
            .then(() => {
                this.setTaskStatus(this.task.status, 'creating_ncr', 'dummy', 'dummy', false)
                    .then(() => {
                        this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                            .then(() => {
                                this.leave(true);
                            });
                    });
            });
    }

    public getNCRsByJob(job: JobModel): Promise<NCR[]> {
        let promise: Promise<NCR[]>;
        promise = new Promise<NCR[]>((resolve, reject) => {
            this.jobService.getNCRs(job)
                .then(ncrs => {
                    resolve(ncrs);
                }, error => {
                    resolve([]);
                });
        });
        return promise;
    }

    private getAllData(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('database/consts')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        if (response.data.critical_points) {
                            this.critical_points = response.data.critical_points;
                        }
                        if (response.data.packagings) {
                            this.packagings = response.data.packagings;
                        }
                        if (response.data.incoterms) {
                            this.incoterms = response.data.incoterms;
                        }
                        if (response.data.carriers) {
                            this.carriers = response.data.carriers;
                        }
                        if (response.data.cosmetics) {
                            this.cosmetics = response.data.cosmetics;
                        }
                        if (response.data.hardenings) {
                            this.hardenings = response.data.hardenings;
                        }
                        if (response.data.special_toolings) {
                            this.special_toolings = response.data.special_toolings;
                        }
                        if (response.data.processes) {
                            this.processes = response.data.processes;
                        }
                    }
                    resolve();
                });
        });
        return promise;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.loader.show();
                    this.viewTaskInit();
                    this.init();
                    this.job_id = params['job_id'];
                    this.task_id = params['id'];
                    this.getTaskDetails()
                        .then(() => {
                            this.getAllData()
                                .then(() => {
                                    //
                                });
                        });
                });
        }, 500);
    }

    ngOnDestroy(): void {
        //
    }

    public onCriticalPointChange(critical_point: any, i: number, e: any): void {
        if (e && e.id) {
            //
        } else {
            this.deleteEstimate(this.product.critical_points, critical_point, i, 'critical/points', true);
        }
    }

    public onSpecialToolingChange(special_tooling: any, i: number, e: any): void {
        if (e && e.id) {
            //
        } else {
            this.deleteEstimate(this.product.special_toolings, special_tooling, i, 'special/toolings', true);
        }
    }

    public onEngChange(e: any): void {
        this.job.eng_user_id = (e && e.id) ? e.id : null;
    }

    public onQCChange(e: any): void {
        this.job.qc_user_id = (e && e.id) ? e.id : null;
    }

    public onChange(dat: any, e?: any): void {
        if (dat) {
            const index: number = this.filter.indexOf(dat.model_id);
            if (dat.checked) {
                if (index === -1) {
                    this.filter.push(dat.model_id);
                } else {
                    //
                }
            } else if (index > -1) {
                this.filter.splice(index, 1);
            }
            // this.fullCalendarComponent.filterEvents(this.filter);
        }
    }

    public getProductStoreAmount(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('store/products', 'POST', {}, {
                product_ids: [this.product_id]
            }).subscribe((response: any): void => {
                const dat = response.data.find(_ => _.modelable_id === this.product_id);
                if (dat) {
                    this.product.store_amount = dat.amount;
                }
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private get_materials(): any[] {
        return this.product.materials;
    }

    public create_store_check(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const materials: any[] = this.get_materials();
            this.storeService.checkItems(this.task, this.product_id, materials)
                .then(response => {
                    this.swal.success('ส่งงานไปยังแผนก STORE สำเร็จ');
                    this.leave(true);
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public printAllSchedulers(): void {
        if (this.task && this.task.id && this.task.current_user_id === this.userService.getID() && this.task.current_user_id) {
            this._save()
                .then(() => {
                    this.viewManufacture(this.job, true);
                }, error => {
                    //
                });
        }
    }

    public printSchedulers(): void {
        if (this.task && this.task.id && this.task.current_user_id === this.userService.getID() && this.task.current_user_id) {
            this._save()
                .then(() => {
                    this.viewManufacture(this.job, false);
                }, error => {
                    //
                });
        }
    }

    public approve(): void {
        let to_role: string;
        let to_status: number;
        let to_process_slug: string;
        if (this.task.current_role === 'product') {
            if (this.task.status === 7) {
                to_status = 8;
                to_role = 'control';
                to_process_slug = 'control';
            } else {
                to_status = 3;
                to_role = 'planning';
                to_process_slug = 'planning';
            }
        } else if (this.task.current_role === 'planning' || this.task.current_role === 'planning') {
            if (this.task.status === 5) {
                to_status = 7;
                to_role = 'product';
                to_process_slug = 'control';
            } else {
                to_status = 4;
                to_role = 'product';
                to_process_slug = 'control';
            }
        }
        this.modal.show(AddRemarkComponent, {
            task: this.task,
            to_role: to_role
        }, { class: 'modal-md', backdrop: true, ignoreBackdropClick: true })
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.setTaskStatus(to_status, 'approved', to_role, to_process_slug, false)
                        .then(() => {
                            this.swal.success('ผ่านการตรวจสอบข้อมูลสำเร็จ');
                            this.leave(true);
                        });
                }
            });
    }

    public fail_check(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    let to_task_status: number;
                    let to_role: string;
                    if (this.task.current_role === 'planning' || this.task.current_role === 'planning') {
                        to_task_status = 6;
                        to_role = 'control';
                    } else if (this.task.current_role === 'product') {
                        if (this.task.status === 7) {
                            to_task_status = 5;
                            to_role = 'planning';
                        } else {
                            to_task_status = 1;
                            to_role = 'planning';
                        }
                    }
                    this.taskService.setStatus(this.task, to_task_status, 'fail', to_role)
                        .then((task: TaskModel): void => {
                            this.task.status = 1;
                            this.leave(true);
                        });
                } else {
                    //
                }
            }, error => {
                //
            });
    }

    public viewRemark(): void {
        this.modal.show(ViewRemarkComponent, {
            task: this.task
        }, { class: 'modal-lg' }).then(() => {
            //
        });
    }

    public check_job_children(job_ids: string[]): Promise<boolean> {
        let promise: Promise<boolean>;
        promise = new Promise<boolean>((resolve, reject) => {
            if (job_ids && job_ids.length) {
                this.jobService.materialCheck(job_ids)
                    .then((material_result: any): void => {
                        if (material_result && material_result.material) {
                            /*this.swal.danger(
                                material_result.product_name +
                                ' (' + material_result.job_no + ') ' +
                                material_result.material.mat_name +
                                ' ไม่พร้อมทำการผลิต'
                            );*/
                            this.swal.confirm(material_result.product_name +
                                ' (' + material_result.job_no + ') ' +
                                material_result.material.mat_name +
                                ' ไม่พร้อมทำการผลิต คุณต้องการยืนยันการเตรียมวัตถุดิบก่อนใช่หรือไม่?')
                                .then((result: boolean): void => {
                                    if (result === true) {
                                        this.create_store_check();
                                    } else {
                                        //
                                    }
                                });
                            resolve(false);
                        } else {
                            this.jobService.productProcessCheck(job_ids)
                                .then((product_process_result: any): void => {
                                    if (product_process_result && (product_process_result.process || product_process_result.process === false || product_process_result.is_assembly === true)) {
                                        if (product_process_result.process === false) {
                                            this.swal.danger(
                                                product_process_result.product_name +
                                                ' (' + product_process_result.job_no + ') ' +
                                                ' มีบางกระบวนการที่ไม่ได้ถูกวางแผน'
                                            );
                                        } else if (product_process_result.is_assembly === true) {
                                            this.swal.danger(
                                                product_process_result.product_name +
                                                ' (' + product_process_result.job_no + ') ' +
                                                ' จำเป็นต้องมีกระบวนการ Assembly ในแผนงาน'
                                            );
                                        } else {
                                            this.swal.danger(
                                                product_process_result.product_name +
                                                ' (' + product_process_result.job_no + ') ' +
                                                product_process_result.process.name +
                                                ' ไม่ได้วางแผนงาน'
                                            );
                                        }
                                        resolve(false);
                                    } else {
                                        this.jobService.manAndMachineCheck(job_ids, this.task.current_role)
                                            .then((man_machine_result: any): void => {
                                                if (man_machine_result && man_machine_result.process) {
                                                    this.swal.danger(
                                                        man_machine_result.product_name +
                                                        ' (' + man_machine_result.job_no + ') ' +
                                                        man_machine_result.process.name +
                                                        ' ไม่ได้วางแผนเครื่องจักร/คนทำงาน'
                                                    );
                                                    resolve(false);
                                                } else {
                                                    resolve(true);
                                                }
                                            }, error => {
                                                console.error(error);
                                                this.swal.danger('เกิดข้อผิดพลาด โปรดแจ้งผู้ดูแลระบบ');
                                                this.loader.hide();
                                            });
                                    }
                                }, error => {
                                    console.error(error);
                                    this.swal.danger('เกิดข้อผิดพลาด โปรดแจ้งผู้ดูแลระบบ');
                                    this.loader.hide();
                                });
                        }
                    }, error => {
                        console.error(error);
                        this.swal.danger('เกิดข้อผิดพลาด โปรดแจ้งผู้ดูแลระบบ');
                        this.loader.hide();
                    });
            } else {
                resolve(true);
            }
        });
        return promise;
    }

    public check_machine_man(product_processes: ProductProcessModel[]): ProductProcessModel {
        for (let i = 0; i < product_processes.length; i++) {
            const product_process: ProductProcessModel = product_processes[i];
            if (product_process.checked && product_process.current_role === this.task.current_role) {
                if (product_process.is_fvd && (!product_process.fvd || !product_process.fvd.id)) {
                    return product_process;
                } else if (!product_process.is_fvd  && (!product_process.machine_id || !product_process.user_id)) {
                    const slug: string = product_process.process.slug;
                    if (slug !== 'shipping' && slug !== 'packing' && slug !== 'finish-good') {
                        return product_process;
                    }
                }
            }
            if (product_process.latest_child && product_process.latest_child.checked
                && product_process.latest_child.current_role === this.task.current_role) {
                //
                if (product_process.latest_child.is_fvd && (!product_process.latest_child.fvd || !product_process.latest_child.fvd.id)) {
                    return product_process.latest_child;
                } else if (!product_process.latest_child.is_fvd
                    && (!product_process.latest_child.machine_id || !product_process.latest_child.user_id)) {
                    const slug: string = product_process.latest_child.process.slug;
                    if (slug !== 'shipping' && slug !== 'packing' && slug !== 'finish-good') {
                        return product_process.latest_child;
                    }
                }
            }
        }
        return null;
    }

    public check_product_process(product_processes: ProductProcessModel[]): ProductProcessModel {
        for (let i = 0; i < product_processes.length; i++) {
            const product_process: ProductProcessModel = product_processes[i];
            if (product_process.latest_scheduler && product_process.checked
                && (!product_process.latest_scheduler.start_date || !product_process.latest_scheduler.start_time)) {
                return product_process;
            }
            if (product_process.checked && !product_process.process_id) {
                return product_process;
            }
            if (product_process.latest_child && product_process.latest_child.checked) {
                if (!product_process.latest_child.latest_scheduler.start_date || !product_process.latest_child.latest_scheduler.start_time) {
                    return product_process.latest_child;
                }
                if (!product_process.latest_child.process_id) {
                    return product_process;
                }
            }
        }
        return null;
    }

    public rejectTask(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            try {
                super.rejectTask()
                    .then(() => {
                        this.swal.success('ยืนยันรายการสินค้าไม่ผ่านการอนุมัติสำเร็จ');
                        this.leave(true);
                        resolve();
                    }, error => {
                        reject();
                    });
            } catch (e) {
                console.warn(e);
            }
        });
        return promise;
    }

    public archiveTask(): void {
        this.taskService.setStatus(this.task, this.task.status, 'archive', 'archive')
            .then((task: TaskModel): void => {
                // this.task.status = 2;
                this.swal.success('ส่งให้แผนก Store สำหรับการจัดเก็บเรียบร้อย');
                this.leave(true);
            });
    }

    public rejectPlan(): void {
        if (this.job && !this.job.planning_started_at) {
            this.rejectRemarkModal.open();
        } else {
            //
        }
    }

    public startScheduler(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            let productName = this.product.name;
            if (this.job && this.job.sale_order && this.job.sale_order.document_no) {
                productName = this.job.sale_order.document_no;
            }
            this.swal.confirm('คุณต้องการเริ่มแผนงานการผลิตสินค้า "' + productName + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.loader.show();
                        this.start_job_scheduler()
                            .then((job_result: any): void => {
                                this.job.revision = job_result.job_revision;
                                this.swal.success('เริ่มแผนการผลิตสำเร็จ');
                                this.viewManufacture(this.job, this.job.revision);
                                this.leave(true);
                                this.loader.hide();
                                resolve();
                            }, error => {
                                this.swal.danger(error);
                                this.loader.hide();
                                resolve();
                            });
                    } else {
                        resolve();
                    }
                });
        });
        return promise;
    }

    private start_job_scheduler(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.jobService.startJobScheduler(this.job_id, this.task_id)
                .then(result => {
                    resolve(result);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private get_shipping_date_by_process(product_processes: ProductProcessModel[]): any {
        for (let i = 0; i < product_processes.length; i++) {
            const product_process: ProductProcessModel = product_processes[i];
            if (product_process && product_process.checked && product_process.process.slug === 'shipping' && product_process.latest_scheduler) {
                // return product_process.latest_scheduler.start_date + ' 00:00:00';
                return product_process.latest_scheduler.start_date;
            }
            if (product_process && product_process.latest_child && product_process.latest_child.checked && product_process.latest_child.process.slug === 'shipping' && product_process.latest_child.latest_scheduler) {
                // return product_process.latest_child.latest_scheduler.start_date + ' 00:00:00';
                return product_process.latest_child.latest_scheduler.start_date;
            }
        }
        return false;
    }

    public completePlan(): void {
        let job_ids: string[];
        job_ids = [];
        if (this.children && this.children.length) {
            for (let i = 0; i < this.children.length; i++) {
                const child: any = this.children[i];
                if (child && child.job_id && child.job_id !== this.parent_job.id) {
                    job_ids.push(child.job_id);
                }
            }
        }
        this.loader.show();
        this.check_job_children(job_ids)
            .then((assembly_result: boolean): void => {
                if (assembly_result === true) {
                    if (!this.plannerComponent) {
                        this.swal.danger('กรุณาลองใหม่อีกครั้ง');
                        this.loader.hide();
                        return;
                    }
                    const delivery_date: any = moment(this.job.delivery_date, 'YYYY-MM-DD HH:mm:ss');
                    if (!this.job.planning_started_at && !this.job.ignore_required_date) {
                        if (this.job && this.job.required_at && this.job.delivery_date) {
                            const required_at: any = moment(this.job.required_at, 'YYYY-MM-DD HH:mm:ss');
                            if (delivery_date.isAfter(required_at) && delivery_date.format('YYYYMMDD') !== required_at.format('YYYYMMDD')) {
                                this.swal.confirm('วันที่ส่งมอบ ' + delivery_date.format('DD/MM/YY') + ' เลยกำหนด, วันที่ลูกค้าต้องการ ' + required_at.format('DD/MM/YYYY') + ' ระบบจะทำการแจ้ง Sale Admin เพื่อร้องขอการยืนยัน')
                                    .then((result: boolean): void => {
                                        if (result === true) {
                                            this.noticeRemarkModal1.open();
                                        }
                                    });
                                this.loader.hide();
                                return;
                            }
                        }
                        if (this.job && this.job.required_at && this.job.confirmed_delivery_date) {
                            const confirmed_delivery_date: any = moment(this.job.confirmed_delivery_date, 'YYYY-MM-DD HH:mm:ss');
                            if (delivery_date.isAfter(confirmed_delivery_date)) {
                                this.swal.danger('วันที่ส่งมอบ ' + delivery_date.format('DD/MM/YY') + ' เลยกำหนด, วันที่แจ้งลูกค้า ' + confirmed_delivery_date.format('DD/MM/YYYY') + ' ระบบจะทำการแจ้ง Sale Admin เพื่อร้องขอการยืนยัน')
                                    .then((result: boolean): void => {
                                        if (result === true) {
                                            this.noticeRemarkModal2.open();
                                        }
                                    });
                                this.loader.hide();
                                return;
                            }
                        }
                    } else if (this.job.delivery_date) {
                        const shipping_date: any = this.get_shipping_date_by_process(this.product.product_processes);
                        if (shipping_date !== false) {
                            const _shipping_date: any = moment(shipping_date, 'YYYY-MM-DD HH:mm:ss');
                            if (_shipping_date.isAfter(delivery_date) && !this.job.ignore_required_date) {
                                const find_notice: any = this.job.notices.find(n => n.revision === this.job.revision && n.new_date && moment(n.new_date, 'YYYY-MM-DD HH:mm:ss').isAfter(_shipping_date));
                                if (find_notice && find_notice.id) {
                                    //
                                } else {
                                    this.swal.confirm('วันที่ส่งมอบ ' + delivery_date.format('DD/MM/YY') + ' มีการเปลี่ยนแปลงเป็น ' + _shipping_date.format('DD/MM/YYYY') + ' ระบบจะทำการแจ้ง Sale Admin เพื่อร้องขอการยืนยัน')
                                        .then((result: boolean): void => {
                                            if (result === true) {
                                                this.noticeRemarkModal2.open();
                                            } else {
                                                //
                                            }
                                        });
                                    this.loader.hide();
                                    return;
                                }
                            }
                        }
                    }
                    if (this.job && this.job.delivery_date) {
                        let fail_product_process: ProductProcessModel;
                        fail_product_process = this.check_product_process(this.product.product_processes);
                        if (fail_product_process) {
                            if (fail_product_process.process && fail_product_process.process.id) {
                                this.swal.danger('กระบวนการ ' + fail_product_process.process.name + ' ไม่ได้ถูกวางแผนงาน');
                            } else if (!fail_product_process.process_id) {
                                this.swal.danger('มีบางกระบวนการที่ไม่ถูกเลือก, โปรดตรวจสอบอีกครั้ง');
                            } else {
                                this.swal.danger('โปรดเลือกกระบวนการในการวางแผนงาน');
                            }
                            this.loader.hide();
                        } else if (this.plannerComponent.check_scheduler_date(this.job_ids)) {
                            this.swal.danger('ไม่สามารถเริ่มการผลิตได้เนื่องจากวางแผนกระบวนการไม่ถูกต้อง');
                            this.loader.hide();
                        } else {
                            this.loader.hide();
                            if (!this.job.planning_started_at) {
                                let _delivery_date: any;
                                _delivery_date = moment(this.job.delivery_date, 'YYYY-MM-DD HH:mm:ss').format('D/MM/YY');
                                this.swal.confirm('ยืนยันการส่งมอบแผนการผลิตให้กับ CONTROL ต่อไป และแจ้งวันส่งมอบสินค้า ' +
                                    '' + _delivery_date + ' ให้กับแผนก SALE ADMIN ใช่หรือไม่?')
                                    .then(response => {
                                        if (response === true) {
                                            this.loader.show();
                                            this._save(true)
                                                .then(() => {
                                                    let promise_list2: Promise<Object>[];
                                                    promise_list2 = [];
                                                    promise_list2.push(
                                                        this.startJob(),
                                                        this.setTaskStatus(4, 'control', 'control', 'planning', false)
                                                    );
                                                    if (this.children && this.children.length) {
                                                        for (let i = 0; i < this.children.length; i++) {
                                                            const job: JobModel = this.children[i];
                                                            if (job && job.task_id) {
                                                                promise_list2.push(this.setTaskStatus(4, 'control', 'control', 'planning', false, job.task_id));
                                                            }
                                                        }
                                                    }
                                                    Promise.all(promise_list2)
                                                        .then(() => {
                                                            if (this.parent_job && this.parent_job.id) {
                                                                this.taskService.createNewTask({
                                                                    process_slug: 'delivery',
                                                                    current_role: 'delivery',
                                                                    action: 'sent_delivery_date',
                                                                    modelable_type: 'App\\Job',
                                                                    modelable_id: this.parent_job.id,
                                                                    relate_task_id: this.task_id,
                                                                    product_id: this.parent_job.product_id,
                                                                    customer_id: this.parent_job.customer_id,
                                                                    job_id: this.parent_job.id,
                                                                    parent_id: this.task.id
                                                                }).then(() => {
                                                                    this.loader.hide();
                                                                    this.swal.success('ส่งมอบแผนการผลิตสำเร็จ');
                                                                    this.leave(true);
                                                                }, error => {
                                                                    console.log(error);
                                                                    this.swal.danger(JSON.stringify(error));
                                                                    this.loader.hide();
                                                                });
                                                            } else {
                                                                this.loader.hide();
                                                                this.swal.success('ส่งมอบแผนการผลิตสำเร็จ');
                                                                this.leave(true);
                                                            }
                                                        }, error => {
                                                            console.log(error);
                                                            this.swal.danger(JSON.stringify(error));
                                                            this.loader.hide();
                                                        });
                                                });
                                        } else {
                                            this.loader.hide();
                                        }
                                    });
                            } else if (this.job.planning_started_at) {
                                if (!this.job.confirmed_delivery_date /*&& !this.job.started_at*/) { // TODO: check assembly part's
                                    this.swal.danger('SALE ADMIN ยังไม่ได้แจ้งวันที่ส่งงานกับลูกค้า จึงไม่สามารถดำเนินการต่อไปได้');
                                    this.loader.hide();
                                    return;
                                }
                                this._save()
                                    .then(() => {
                                        this.startScheduler()
                                            .then(() => {
                                                //
                                            });
                                        this.loader.hide();
                                    });
                            } else {
                                this.loader.hide();
                            }
                        }
                    } else {
                        this.swal.danger('กรุณาเลือกวันส่งมอบสินค้าให้กับลูกค้า');
                        this.loader.hide();
                    }
                } else {
                    this.loader.hide();
                }
            });
    }

    public createDeliveryTask(): void {
        this.loader.show();
        this.taskService.createNewTask({
            process_slug: 'delivery',
            current_role: 'delivery',
            action: 'sent_delivery_date',
            modelable_type: 'App\\Job',
            modelable_id: this.parent_job.id,
            relate_task_id: this.task_id,
            product_id: this.parent_job.product_id,
            customer_id: this.parent_job.customer_id,
            job_id: this.parent_job.id,
            parent_id: this.task.id
        }).then(() => {
            this.loader.hide();
            this.swal.success('ส่งวันแจ้งลูกค้ากับ Sale Admin สำเร็จ');
        }, error => {
            console.error(error);
            this.loader.hide();
            this.swal.danger('เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง');
        });
    }

    public startJob(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.jobService.startJob(this.job_id)
                .then(result => {
                    resolve(result);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public check_material_quantity(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            if (this.product.materials && this.product.materials.length) {
                for (const material of this.product.materials) {
                    if (material && material.amount > this.job.product_amount) {
                        this.loader.hide();
                        this.swal.confirm('จำนวนวัสดุ "' + material.mat_name + '" ' + material.amount + ' มีจำนวนมากกว่าที่สั่งผลิต ' + this.job.product_amount + ' ยืนยันการสั่งผลิตใช่หรือไม่?')
                            .then((result: boolean): void => {
                                if (result === true) {
                                    resolve({});
                                } else {
                                    reject({});
                                }
                            });
                    } else {
                        this.loader.hide();
                        resolve({});
                    }
                }
            } else {
                this.loader.hide();
                resolve({});
            }
        });
        return promise;
    }

    /*public checkAll(pp_or_schedulers: any[]): void {
        for (let i = 0; i < pp_or_schedulers.length; i++) {
            const pp_or_scheduler: any = pp_or_schedulers[i];
            if (pp_or_scheduler) {
                if (pp_or_scheduler.children && pp_or_scheduler.children.length) {
                    this.checkAll(pp_or_scheduler.children);
                }
                pp_or_scheduler.checked = true;
                if (pp_or_scheduler && pp_or_scheduler.schedulers && pp_or_scheduler.schedulers.length) {
                    this.checkAll(pp_or_scheduler.schedulers);
                }
            }
        }
    }*/

    /*public uncheckAll(pp_or_schedulers: any[]): void {
        for (let i = 0; i < pp_or_schedulers.length; i++) {
            const pp_or_scheduler: any = pp_or_schedulers[i];
            if (pp_or_scheduler) {
                if (pp_or_scheduler.children && pp_or_scheduler.children.length) {
                    this.uncheckAll(pp_or_scheduler.children);
                }
                pp_or_scheduler.checked = false;
                if (pp_or_scheduler && pp_or_scheduler.schedulers && pp_or_scheduler.schedulers.length) {
                    this.uncheckAll(pp_or_scheduler.schedulers);
                }
            }
        }
        this.save(true);
    }*/

    private setTaskStatus(task_status: number,
                          action?: string,
                          to_role?: string,
                          to_process?: string,
                          completed?: boolean,
                          task_id?: string): Promise<Object> {
        //
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.taskService.setStatus((task_id) ? task_id : this.task, task_status, action, to_role, to_process, completed)
                .then((task: TaskModel): void => {
                    if (this.task) {
                        this.task.status = task_status;
                    } else {
                        //
                    }
                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    public onClearTypeahead(model: any, model_field_name: string, field_name: string): void {
        if (model && field_name) {
            delete model[field_name];
        }
    }

    private getTaskDetails(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + this.task_id + '/detail')
                .subscribe((response: any): void => {
                    this.onSuccess(response);
                    resolve();
                }, error => {
                    this.onError(error);
                    reject(error);
                });
        });
        return promise;
    }

    public addMoreSpecialTooling(): void {
        let special_tooling: SpecialToolingModel;
        special_tooling = new SpecialToolingModel();
        special_tooling.current_role = this.task.current_role;
        special_tooling.checked = true;
        special_tooling.job_id = this.job_id;
        this.product.special_toolings.push(special_tooling);
    }

    public addMoreCriticalPoint(): void {
        let critical_point: CriticalPointModel;
        critical_point = new CriticalPointModel();
        critical_point.current_role = this.task.current_role;
        critical_point.checked = true;
        critical_point.job_id = this.job_id;
        this.product.critical_points.push(critical_point);
    }

    public deleteEstimate(models: FormModel[], model: FormModel, index: number, name: string, force?: boolean): void {
        if (models && model && index > -1) {
            if (force === true) {
                if (model.id) {
                    this.api.request('product/' + name + '/delete', 'POST', {}, {
                        id: model.id
                    }).subscribe((response: any): void => {
                        models.splice(index, 1);
                        model.is_delete = true;
                    });
                } else {
                    models.splice(index, 1);
                    model.is_delete = true;
                }
            } else {
                this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            if (model.id) {
                                this.api.request('product/' + name + '/delete', 'POST', {}, {
                                    id: model.id
                                }).subscribe((response: any): void => {
                                    models.splice(index, 1);
                                    model.is_delete = true;
                                });
                            } else {
                                models.splice(index, 1);
                                model.is_delete = true;
                            }
                        } else {
                            //
                        }
                    });
            }
        } else {
            //
        }
    }

    public toggleEstimate(models: FormModel[], model: FormModel, index: number, model_name?: string): void {
        if (models && index > -1) {
            model.checked = !model.checked;
        } else {
            //
        }
        this.save(true);
    }

    public onFVDSubmit(e?: any): void {
        this.save(true);
    }

    public onPlannerChange(e?: any): void {
        this.save(true);
    }

    private getCheckedProductProcesses(product_processes: ProductProcessModel[], parent_id?: string, index?: number): any[] {
        let checked_product_processes: any[];
        checked_product_processes = [];
        if (product_processes) {
            for (let i = 0; i < product_processes.length; i++) {
                if (product_processes[i]) {
                    const pp: ProductProcessModel = product_processes[i];
                    if (pp) {
                        const c_pp: any = {
                            id                  : (pp.id) ? pp.id : null,
                            parent_id           : parent_id,
                            ranking             : pp.ranking,
                            process_id          : (pp.process && pp.process.id) ? pp.process.id : null,
                            machine_id          : (pp.machine && pp.machine.id) ? pp.machine.id : ((pp.machine_id + '') === '-1') ? -1 : null,
                            machine_time        : pp.machine_time,
                            user_id             : (pp.user && pp.user.id) ? pp.user.id : ((pp.user_id + '') === '-1') ? -1 : null,
                            user_time           : pp.user_time,
                            current_role        : (pp.current_role) ? pp.current_role : this.task.current_role,
                            checked             : pp.checked,
                            guid                : pp.guid,
                            is_delete           : pp.is_delete,
                            is_fvd              : pp.is_fvd,
                            fvd_id              : pp.fvd_id,
                            // supplier_id         : (pp.is_fvd === true) ? pp.supplier_id : null,
                            fvd_price           : (pp.is_fvd === true) ? pp.fvd_price : null,
                            price               : pp.price,
                            modelable_id        : pp.modelable_id,
                            modelable_type      : pp.modelable_type,
                            job_id              : (pp.job_id) ? pp.job_id : this.job.id,
                            order_index         : (index > -1) ? index : i,
                            modelable_data      : {
                                current_role    : pp.current_role,
                                checked         : pp.checked
                            },
                            description         : pp.description
                        };
                        if (pp.modelable_data && pp.modelable_data.amount) {
                            c_pp.modelable_data.amount = pp.modelable_data.amount;
                        }
                        checked_product_processes.push(c_pp);
                        if (pp.children && pp.children.length > 0) {
                            checked_product_processes = checked_product_processes
                                .concat(this.getCheckedProductProcesses(pp.children, pp.id, i));
                        }
                    }
                }
            }
        }
        return checked_product_processes;
    }

    private getCheckedCriticalPoints(): any {
        let checked_critical_points: any[];
        checked_critical_points = [];
        if (this.product.critical_points) {
            for (let i = 0; i < this.product.critical_points.length; i++) {
                if (this.product.critical_points[i]
                    && this.product.critical_points[i].critical_point
                    && this.product.critical_points[i].critical_point.id) {
                    //
                    const critical_point: CriticalPointModel = this.product.critical_points[i];
                    checked_critical_points.push({
                        critical_point_id   : (critical_point.critical_point) ? critical_point.critical_point.id : null,
                        guid            : critical_point.guid,
                        price           : critical_point.critical_point.price,
                        amount          : critical_point.amount,
                        current_role    : critical_point.current_role,
                        checked         : critical_point.checked,
                        job_id          : critical_point.job_id
                    });
                }
            }
        }
        return checked_critical_points;
    }

    private getCheckedMaterials(materials: ProductMaterial[]): any[] {
        let checked_materials: any[];
        checked_materials = [];
        if (materials) {
            for (let i = 0; i < materials.length; i++) {
                const product_material: ProductMaterial = materials[i];
                if (product_material && product_material.item && product_material.item.id) {
                    checked_materials.push({
                        id: product_material.id,
                        prepared_status: product_material.prepared_status,
                        status: product_material.status,
                        required_at: product_material.required_at,
                        // cutting_id: product_material.cutting_id,
                        // cutting_amount: product_material.cutting_amount,
                        guid: product_material.guid,
                        price: product_material.price,
                        amount: product_material.amount,
                        parent_id: product_material.parent_id,
                        shipping_price: product_material.shipping_price,
                        profit_percentage: product_material.profit_percentage,
                        current_role: product_material.current_role,
                        checked: product_material.checked,
                        job_id: product_material.job_id,
                        remark: product_material.remark,
                        // material_id is item_id field in items table
                        material_id: (product_material && product_material.item) ? product_material.item.id : ''
                    });
                    if (product_material && product_material.children && product_material.children.length) {
                        checked_materials = checked_materials.concat(this.getCheckedMaterials(product_material.children));
                    }
                } else {
                    //
                }
            }
        }
        return checked_materials;
    }

    private getSpecialToolings(): any[] {
        let checked_special_toolings: any[];
        checked_special_toolings = [];
        if (this.product.special_toolings) {
            for (let i = 0; i < this.product.special_toolings.length; i++) {
                const special_tooling: SpecialToolingModel = this.product.special_toolings[i];
                if (special_tooling && special_tooling.special_tooling && special_tooling.special_tooling.id) {
                    checked_special_toolings.push({
                        special_tooling_id  : (special_tooling.special_tooling) ? special_tooling.special_tooling.id : null,
                        guid            : special_tooling.guid,
                        price           : special_tooling.price,
                        amount          : special_tooling.amount,
                        current_role    : special_tooling.current_role,
                        checked         : special_tooling.checked,
                        job_id          : special_tooling.job_id
                    });
                }
            }
        }
        return checked_special_toolings;
    }

    public onGotoPurchase(e: any): void {
        if (this.task && this.task.id && this.task.current_user_id === this.userService.getID() && this.task.current_user_id) {
            this._save(true)
                .then(() => {
                    this.create_store_check();
                });
        } else {
            this.create_store_check();
        }
    }

    public onMaterialInvalid(e: any): void {
        this.material_invalid = (e === false) ? false : true;
    }

    public onMaterialChange(e: any): void {
        if (this.task && this.task.id && this.task.current_user_id === this.userService.getID() && this.task.current_user_id) {
            this._save(true, 'material')
                .then(() => {
                    //
                });
        }
    }

    public estimated(product: ProductModel, product_processes: ProductProcessModel[], type_change?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const checked_product_processes: any[]  = this.getCheckedProductProcesses(product_processes);
            const checked_critical_points: any      = this.getCheckedCriticalPoints();
            const checked_materials: any            = this.getCheckedMaterials(this.product.materials);
            const checked_special_toolings: any     = this.getSpecialToolings();

            this.api.request('products/sync', 'PUT', {}, {
                job_id              : this.job_id,
                product_id          : product.id,
                product_processes   : checked_product_processes,
                critical_points     : checked_critical_points,
                special_toolings    : checked_special_toolings,
                materials           : checked_materials,
                type_change         : type_change
                // schedulers          : schedulers
            }).subscribe((response: any): void => {
                if (response && response.data && response.data.product_processes) {
                    for (let i = 0; i < response.data.product_processes.length; i++) {
                        const r_pp: any = response.data.product_processes[i];
                        if (r_pp && r_pp.id) {
                            for (let j = 0; j < product_processes.length; j++) {
                                const pp: any = product_processes[j];
                                if (pp.guid === r_pp.guid) {
                                    pp.id = r_pp.id;
                                    for (let n = 0; n < pp.schedulers.length; n++) {
                                        pp.schedulers[n].product_process_id = pp.id;
                                    }
                                }
                                if (pp.children && pp.children.length > 0) {
                                    for (let k = 0; k < pp.children.length; k++) {
                                        const child: any = pp.children[k];
                                        if (child && child.guid === r_pp.guid) {
                                            child.id = r_pp.id;
                                            for (let n = 0; n < child.schedulers.length; n++) {
                                                child.schedulers[n].product_process_id = child.id;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (response.data.materials) {
                    for (let i = 0; i < this.product.materials.length; i++) {
                        const material = this.product.materials[i];
                        if (material && material.children) {
                            for (let j = 0; j < material.children.length; j++) {
                                const child = material.children[j];
                                if (child) {
                                    const exist_material1 = response.data.materials.find(k => k.guid === child.guid);
                                    if (exist_material1) {
                                        child.id = exist_material1.id;
                                    } else {
                                        //
                                    }
                                }
                            }
                        }
                        const exist_material2 = response.data.materials.find(k => k.guid === material.guid);
                        if (exist_material2) {
                            material.id = exist_material2.id;
                        } else {
                            //
                        }
                    }
                }
                if (response.data.special_toolings) {
                    for (let i = 0; i < response.data.special_toolings.length; i++) {
                        const special_tooling: any = response.data.special_toolings[i];
                        if (special_tooling.guid) {
                            const exist_special_tooling = this.product.special_toolings.find(j => j.guid === special_tooling.guid);
                            if (exist_special_tooling) {
                                exist_special_tooling.id = special_tooling.id;
                            } else {
                                //
                            }
                        }
                    }
                }
                if (response.data.critical_points) {
                    for (let i = 0; i < response.data.critical_points.length; i++) {
                        const critical_point: any = response.data.critical_points[i];
                        if (critical_point.guid) {
                            const exist_critical_point = this.product.critical_points.find(j => j.guid === critical_point.guid);
                            if (exist_critical_point) {
                                exist_critical_point.id = critical_point.id;
                            } else {
                                //
                            }
                        }
                    }
                }
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSuccess(response: any): void {
        if (response && response.data) {
            const data: any = response.data;
            if (!this.job_id) {
                this.job_id = data.modelable_id;
            } else {
                //
            }

            this.task.clone(data);
            this.task.modelable_id = this.job_id;

            this.getJob(this.job_id)
                .then(() => {
                    if (!this.job.started_at) {
                        this.getJobChildren(this.job_id)
                            .then((children_response: any): void => {
                                //
                            }, error => {
                                //
                            });
                    } else {
                        //
                    }

                    this.getNCRsByJob(this.job)
                        .then((ncrs: NCR[]) => {
                            console.log(ncrs);
                            this.ncrs = ncrs;
                        });

                    if (this.job && this.job.product_id) {
                        this.product_id = this.job.product_id;
                        this.getProductDetail()
                            .then(() => {
                                this.getProductStoreAmount();
                                this.init_product();
                            });
                    } else {
                        //
                    }
                    this.doReady();
                    setTimeout(() => {
                        this.loader.hide();
                    }, 1500);
                });
        }
    }

    public onError(error?: any): void {
        //
    }

    public getJobChildren(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id + '/children')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (response.data.parent) {
                            const parent_job: any = response.data.parent;
                            this.children.push({
                                task: parent_job.task,
                                task_id: (parent_job.task) ? parent_job.task.id : null,
                                job_id: parent_job.id,
                                job_current_role: parent_job.current_role,
                                job_process_slug: parent_job.process_slug,
                                product_name: (parent_job.product) ? parent_job.product.name : null
                            });
                            this.parent_job = parent_job;
                        }
                        if (response.data.children) {
                            for (let i = 0; i < response.data.children.length; i++) {
                                const dat: any = response.data.children[i];
                                if (dat) {
                                    this.children.push({
                                        task: dat.task,
                                        task_id: (dat.task) ? dat.task.id : null,
                                        job_id: dat.id,
                                        job_current_role: dat.current_role,
                                        job_process_slug: dat.process_slug,
                                        product_name: (dat.product) ? dat.product.name : null
                                    });
                                }
                            }
                        }
                        resolve(this.children);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        this.ncr_pdf_path = environment.api_host + 'view/' + this.job.id + '/job/ncrs';
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    /*private start_job(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.job && this.job.planning_started_at) {
                resolve();
            } else {
                this.api.request('jobs/start', 'POST', {}, {
                    id: this.job.id
                }).subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            }
        });
        return promise;
    }*/

    public verify(force?: boolean): void {
        if (this.product && this.product.id) {
            let to_task_status: number;
            let action: string;
            let to_role: string;
            if (this.task.current_role === 'planning' || this.task.current_role === 'planning') {
                to_task_status = 2;
                action = 'planning_check';
                to_role = 'product';
            } else if (this.task.current_role === 'control') {
                to_task_status = 5;
                action = 'control_check';
                to_role = 'planning';
            }
            if (force === true) {
                this.taskService.setStatus(this.task, to_task_status, action, to_role)
                    .then((task: TaskModel): void => {
                        this.task.status = to_task_status;
                        this.swal.success('กรุณารอการอนุมัติในขั้นตอนต่อไป');
                        this.leave(true);
                    });
            } else {
                let productName = this.product.name;
                if (this.job && this.job.sale_order && this.job.sale_order.document_no) {
                    productName = this.job.sale_order.document_no;
                }
                this.swal.confirm('คุณต้องการส่งตรวจสอบแผนงาน "' + productName + '" ใช่หรือไม่?', null, null
                    , 'ส่งตรวจสอบ', 'ปิด')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.taskService.setStatus(this.task, 2, 'planning_check', 'product')
                                .then((task: TaskModel): void => {
                                    this.task.status = 2;
                                    this.swal.success('กรุณารอการอนุมัติในขั้นตอนต่อไป');
                                    this.leave(true);
                                });
                        } else {
                            //
                        }
                    });
            }
        } else {
            this.swal.danger('กรุณาเลือกสินค้าสำหรับการวาแบบแผนงานผลิตสินค้า');
        }
    }

    private get_schedulers(product_process: ProductProcessModel, index?: number): any {
        let schedulers: any[];
        schedulers = [];
        let model_schedulers: SchedulerModel[];
        model_schedulers = [];
        for (let i = 0; i < product_process.schedulers.length; i++) {
            const scheduler: SchedulerModel = product_process.schedulers[i];
            if (scheduler) {
                const start = (moment.isMoment(scheduler.start)) ? scheduler.start.format('YYYY-MM-DD HH:mm:ss') : scheduler.start;
                const end = (moment.isMoment(scheduler.end)) ? scheduler.end.format('YYYY-MM-DD HH:mm:ss') : scheduler.end;
                schedulers.push({
                    id: (scheduler.id) ? scheduler.id : null,
                    machine_id: product_process.machine_id,
                    user_id: product_process.user_id,
                    current_role: scheduler.current_role,
                    start: start,
                    end: end,
                    description: scheduler.description,
                    guid: scheduler.guid,
                    minutes: scheduler.minutes,
                    checked: scheduler.checked,
                    order_index: scheduler.order_index,
                    product_process_id: (scheduler.product_process_id) ? scheduler.product_process_id : null
                });
                model_schedulers.push(scheduler);
            }
        }
        return {
            schedulers  : schedulers,
            models      : model_schedulers
        };
    }

    public onTypeaheadDelete(model: any, type: string, e?: any, i?: number): void {
        this.ngZone.run(() => {
            if (type === 'critical_point') {
                model.amount = 0;
                model.price = 0;
                this.deleteEstimate(this.product.critical_points, model, i, 'critical/points', true);
            } else if (type === 'special_tooling') {
                model.price = 0;
                model.pivot = {
                    amount: 0
                };
                this.deleteEstimate(this.product.special_toolings, model, i, 'special/toolings', true);
            } else if (type === 'material') {
                model.s_width = 0;
                model.s_length = 0;
                model.s_height = 0;
                model.s_depth = 0;
                model.s_weight = 0;
                model.s_outer_diameter = 0;
                model.s_inner_diameter = 0;
                model.s_diameter = 0;
                model.price = 0;
                model.pivot = {
                    amount: 0
                };
                model.item_type = null;
                model.material_type = null;
                model.modelable_id = null;
                model.modelable_type = null;
                this.deleteEstimate(this.product.materials, model, i, 'materials', true);
            }
        });
    }

    private _save(skip?: boolean, type_change?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.task) {
                if (!this.product || !this.product.id) {
                    if (skip === true) {
                        //
                    } else {
                        this.swal.danger('กรุณาเลือกสินค้าสำหรับการวาแบบแผนงานผลิตสินค้า');
                    }
                    reject();
                } else {
                    this.job.planner_id = this.userService.getID();
                    this.modelApi.update(this.job, [
                        'delivery_date', 'qc_data', 'eng_user_id', 'qc_user_id',
                        'product_id', 'customer_id', 'product_amount', 'planner_id',
                        'planning_remark', 'task_id',
                    ], null, null, null, false)
                        .subscribe((response: any): void => {
                            this.estimated(this.product, this.product.product_processes, type_change)
                                .then((estimated_response: any): void => {
                                    if (estimated_response && estimated_response.success === true) {
                                        this.task.product_id = this.product.id;
                                        if (this.product.customer_id) {
                                            this.task.customer_id = this.product.customer_id;
                                        }
                                        let model_schedulers: SchedulerModel[];
                                        model_schedulers = [];
                                        let schedulers: any[];
                                        schedulers = [];
                                        for (let i = 0; i < this.product.product_processes.length; i++) {
                                            const product_process: ProductProcessModel = this.product.product_processes[i];
                                            const _get_schedulers: any = this.get_schedulers(product_process, i);
                                            schedulers = schedulers.concat(_get_schedulers.schedulers);
                                            model_schedulers = model_schedulers.concat(_get_schedulers.models);
                                            if (product_process.children && product_process.children.length > 0) {
                                                for (let j = 0; j < product_process.children.length; j++) {
                                                    const child: ProductProcessModel = product_process.children[j];
                                                    if (child) {
                                                        const _get_scheduler: any = this.get_schedulers(child, j);
                                                        schedulers = schedulers.concat(_get_scheduler.schedulers);
                                                        model_schedulers = model_schedulers.concat(_get_scheduler.models);
                                                    }
                                                }
                                            } else {
                                                //
                                            }
                                        }
                                        this.api.request('jobs/schedulers', 'PUT', null, {
                                            id: this.job_id,
                                            schedulers: schedulers
                                        }).subscribe((scheduler_response: any): void => {
                                            if (scheduler_response && scheduler_response.data) {
                                                for (let i = 0; i < model_schedulers.length; i++) {
                                                    const _scheduler: any = scheduler_response.data
                                                        .find(d => d.guid === model_schedulers[i].guid);
                                                    if (_scheduler && _scheduler.id) {
                                                        model_schedulers[i].id = scheduler_response.data[i].id;
                                                    } else {
                                                        //
                                                    }
                                                }
                                            }
                                            if (skip === true) {
                                                //
                                            } else {
                                                this.swal.success('ทำการบันทึกข้อมูลสำเร็จ', null, 2000);
                                            }
                                            resolve();
                                        }, error => {
                                            if (skip === true) {
                                                //
                                            } else {
                                                this.swal.danger(error);
                                            }
                                            resolve();
                                        });
                                    }
                                }, error => {
                                    if (skip === true) {
                                        //
                                    } else {
                                        this.swal.danger(error);
                                    }
                                    reject(error);
                                });
                        });
                }
            }
        });
        return promise;
    }

    public save(skip?: boolean): void {
        this._save(skip)
            .then((estimated_response: any) => {
                //
            }, error => {
                //
            });
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private init_product(): void {
        if (this.product && this.product.id) {
            this.total_special_tooling_sale = 0;
            if (this.product.special_toolings && this.product.special_toolings.length > 0) {
                for (let i = 0; i < this.product.special_toolings.length; i++) {
                    const special_tooling: SpecialToolingModel = this.product.special_toolings[i];
                    if (special_tooling && special_tooling.current_role === 'product' && special_tooling.price > 0) {
                        // special_tooling.price = special_tooling.price;
                        this.total_special_tooling_sale += special_tooling.total_price;
                    }
                }
            }

            this.total_critical_point_price = 0;
            if (this.product.critical_points && this.product.critical_points.length > 0) {
                for (let i = 0; i < this.product.critical_points.length; i++) {
                    const critical_point: CriticalPointModel = this.product.critical_points[i];
                    if (critical_point && critical_point.current_role === 'product' && critical_point.price > 0) {
                        this.total_critical_point_price += critical_point.critical_point.price * critical_point.amount;
                    }
                }
            }

            this.total_material_sale = 0;
            if (this.product.materials && this.product.materials.length > 0) {
                for (let i = 0; i < this.product.materials.length; i++) {
                    const material: ProductMaterial = this.product.materials[i];
                    if (material && material.pivot && material.pivot.price > 0) {
                        material.price = material.pivot.price;
                    }
                    let amount: number;
                    amount = (material.pivot && material.pivot.amount) ? material.pivot.amount : material.amount;
                    if (amount && material.price) {
                        if (material.current_role === 'product') {
                            this.total_material_sale += material.total_price;
                        }
                    }
                }
            }

            if (this.calendar_schedulers) {
                this.calendar_schedulers.splice(0, this.calendar_schedulers.length);
            } else {
                this.calendar_schedulers = [];
            }
        }
    }

    public get total_product_process_price(): number {
        let total: number;
        total = 0;
        if (this.product.product_processes) {
            for (let i = 0; i < this.product.product_processes.length; i++) {
                const product_process: ProductProcessModel = this.product.product_processes[i];
                if (product_process && product_process.id && product_process.current_role === 'product') {
                    if (product_process.is_fvd) {
                        total += product_process.fvd_price;
                    } else {
                        total += product_process.price;
                    }
                }
            }
        }
        return total * this.job.customer_product_amount;
    }

    public get total_scheduler_price(): number {
        let total: number;
        total = 0;
        if (this.product.product_processes) {
            for (let i = 0; i < this.product.product_processes.length; i++) {
                const product_process: ProductProcessModel = this.product.product_processes[i];
                if (product_process.checked) {
                    if (product_process.is_fvd) {
                        total += (product_process.fvd_price > 0) ? product_process.fvd_price : 0;
                    } else {
                        if (product_process.current_role === 'product') {
                            // total += product_process.price;
                        }
                        if (product_process.schedulers && product_process.schedulers.length > 0) {
                            for (let j = 0; j < product_process.schedulers.length; j++) {
                                const scheduler: SchedulerModel = product_process.schedulers[j];
                                if (scheduler && scheduler.price > 0 && scheduler.checked) {
                                    total += (scheduler.price > 0) ? scheduler.price : 0;
                                }
                            }
                        }
                    }
                }
                if (product_process.children && product_process.children.length > 0) {
                    for (let k = 0; k < product_process.children.length; k++) {
                        const child: ProductProcessModel = product_process.children[k];
                        if (child && child.checked) {
                            if (child.is_fvd) {
                                total += (child.fvd_price > 0) ? child.fvd_price : 0;
                            } else {
                                if (child.current_role === 'product') {
                                    // total += child.price;
                                }
                                if (child.schedulers && child.schedulers.length > 0) {
                                    for (let j = 0; j < child.schedulers.length; j++) {
                                        const scheduler: SchedulerModel = child.schedulers[j];
                                        if (scheduler && scheduler.price > 0 && scheduler.checked) {
                                            total += (scheduler.price > 0) ? scheduler.price : 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        const product_amount: number = (this.job && this.job.product_amount) ? this.job.product_amount : 0;
        return total;
    }

    public getQuotation(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.job && this.job.quotation_no) {
                this.api.request('quotations/' + this.job.quotation_no + '/no', 'GET')
                    .subscribe((response: any): void => {
                        if (response && response.success === true) {
                            resolve(response.data);
                        } else {
                            reject(response);
                        }
                    }, error => {
                        reject(error);
                    });
            } else {
                reject();
            }
        });
        return promise;
    }

    public get have_approved(): boolean {
        if (this.task) {
            for (let i = 0; i < this.product.product_processes.length; i++) {
                const product_process: ProductProcessModel = this.product.product_processes[i];
                if (product_process && product_process.children && product_process.children.length > 0) {
                    for (let j = 0; j < product_process.children.length; j++) {
                        const child: ProductProcessModel = product_process.children[j];
                        if (child) {
                            for (let k = 0; k < child.schedulers.length; k++) {
                                const scheduler: SchedulerModel = child.schedulers[k];
                                if (scheduler && scheduler.current_role !== this.task.current_role) {
                                    return true;
                                }
                            }
                            if (child.current_role !== this.task.current_role && child.checked) {
                                return true;
                            }
                        }
                    }
                }
                if (product_process && product_process.current_role !== this.task.current_role && product_process.checked) {
                    return true;
                }
            }
            for (let i = 0; i < this.product.special_toolings.length; i++) {
                const special_tooling: SpecialToolingModel = this.product.special_toolings[i];
                if (special_tooling && (special_tooling.current_role === this.task.current_role
                    || (special_tooling.current_role !== this.task.current_role && !special_tooling.checked))) {
                    return true;
                }
            }
            for (let i = 0; i < this.product.critical_points.length; i++) {
                const critical_point: CriticalPointModel = this.product.critical_points[i];
                if (critical_point && (critical_point.current_role === this.task.current_role
                    || (critical_point.current_role !== this.task.current_role && !critical_point.checked))) {
                    return true;
                }
            }
            for (let i = 0; i < this.product.materials.length; i++) {
                const material: any = this.product.materials[i];
                if (material && (material.current_role === this.task.current_role
                    || (material.current_role !== this.task.current_role && !material.checked))) {
                    return true;
                }
            }
        }
        return false;
    }

    public get total_price_sale(): number {
        return this.total_material_sale
            + this.total_special_tooling_sale
            + this.total_critical_point_price
            + this.total_product_process_price;
    }

    public get total_price_planning(): number {
        return this.product.total_critical_point
            + this.product.total_special_tooling
            + this.product.total_material
            + this.total_scheduler_price;
    }

    public onSaveApiCallingBack(e: any): void {
        this.save(true);
    }

}
