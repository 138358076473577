import {AfterViewInit, Component, Input} from '@angular/core';
import {UserModel} from '../../now/user/user.model';

@Component({
    selector: 'media-component',
    templateUrl: 'media.component.html',
    styleUrls: ['media.component.scss']
})
export class MediaComponent implements AfterViewInit {

    @Input() public description: string;

    @Input('label')
    public _label: boolean;

    @Input('url')
    public _url: boolean;

    @Input('man')
    public man: UserModel | string;

    constructor() {
        this.man = new UserModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._label = (this._label === false) ? false : true;
            this._url = (!this._url) ? false : true;
        }, 1000);
    }

}
