import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {DocumentModel} from '../../models/document.model';
import {Api} from '../../now/api/api';
import {Model} from '../../now/model';
import {ModelApi} from '../../now/modelApi/modelApi';

@Component({
    selector: 'remark-modal',
    templateUrl: 'remark.modal.html',
    styleUrls: ['remark.modal.scss']
})
export class RemarkModal implements AfterViewInit {

    @ViewChild('remarkModal', { static: true }) smartModal: NgxSmartModalComponent;

    @Output('submitting') onSubmitting: EventEmitter<any> = new EventEmitter<any>();
    @Output('submit') onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output('uploaded') onUploaded: EventEmitter<any> = new EventEmitter<any>();

    @Input() public title: string;
    @Input() public title2: string;
    @Input() public solve: boolean;
    @Input() public uploadable: boolean;
    @Input() public model: Model;
    @Input() public createPath: string;
    @Input() public createRequestData: any;
    @Input() public modelableType: string;
    @Input() public modelableId: string;
    @Input() public tag: string;
    @Input('message') set message(val: any) { this.remark_message = val; }
    @Input('solve_message') set solveMessage(val: any) { this.solve_message = val; }

    public solve_message: string;
    public current_remark_message: string;
    public remark_message: string;
    public documents: DocumentModel[];

    constructor(
        private api: Api,
        private modelApi: ModelApi,
    ) {
        //
        this.createRequestData = {};
        this.documents = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.solve === true) {
                this.title = (this.title) ? this.title : 'วิธีการแก้ไข';
                this.title2 = (this.title2) ? this.title2 : 'วิธีการป้องกัน';
            } else {
                this.title = (this.title) ? this.title : 'หมายเหตุ';
            }
            this.reset();
        }, 0);
    }

    public removeRemark(): void {
        if (this.model && this.model.id) {
            this.api.request(this.createPath + '/delete', 'POST', {}, {
                id: this.model.id
            }).subscribe((response: any): void => {
                //
            }, error => {
                //
            });
        }
    }

    public onClose(e?: any): void {
        // this.removeRemark();
    }

    public onDismiss(e?: any): void {
        //
    }

    public onOpen(e?: any): void {
        this.reset();
        if (this.current_remark_message) {
            this.remark_message = this.current_remark_message;
            this.current_remark_message = '';
        }
    }

    public onUploadedSuccess(e?: any): void {
        this.api.request('documents', 'PUT', {}, e)
            .subscribe((response: any): void => {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.documents.push(new_document);
                this.onUploaded.emit(new_document);
            });
        /*let data: any;
        data = Object.assign(e);
        if (this.model && this.model.id) {
            data['modelable_id'] = this.model.id;
            // data['id'] = this.model.id;
        }
        if (this.modelableType) {
            data['modelable_type'] = this.modelableType;
        } else if (this.model) {
            data['modelable_type'] = this.model.model_name;
        }
        if (this.tag) {
            data['tag'] = this.tag;
        }
        this.createOrUpdateOrNothing(data, this.remark_message)
            .then(() => {
                if (this.model && this.model.id) {
                    data['modelable_id'] = this.model.id;
                    // data['id'] = this.model.id;
                }
                e['modelable_type'] = data['modelable_type'];
                e['modelable_id'] = data['modelable_id'];
                this.api.request('documents', 'PUT', {}, e)
                    .subscribe((response: any): void => {
                        let new_document: DocumentModel;
                        new_document = new DocumentModel();
                        new_document.clone(response.data);
                        this.documents.push(new_document);
                        this.onUploaded.emit(new_document);
                    });
            });*/
    }

    public onUploadedError(e?: any): void {
        //
    }

    public createOrUpdateOrNothing(data: any, message?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            let _method: string;
            if (!this.createPath) {
                resolve(data);
                return;
            } else if (data && data['modelable_id']) {
                _method = 'POST';
            } else if (data && !data['modelable_id']) {
                _method = 'PUT';
            }
            if (message || (this.documents && this.documents.length)) {
                const request_data: any = Object.assign({
                    message: message,
                    id: data['modelable_id'],
                }, this.createRequestData);
                this.api.request(this.createPath, _method, {}, request_data)
                    .subscribe((response: any): void => {
                        this.model = response.data;
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve({});
            }
        });
        return promise;
    }

    public open(remark?: string): void {
        if (remark) {
            this.current_remark_message = remark;
        } else {
            //
        }
        this.smartModal.open();
    }

    public close(): void {
        this.smartModal.close();
        this.reset();
    }

    public submit(): void {
        let data: any;
        data = {};
        if (this.model && this.model.id) {
            data['modelable_id'] = this.model.id;
            // data['id'] = this.model.id;
        }
        if (this.modelableType) {
            data['modelable_type'] = this.modelableType;
        } else if (this.model) {
            data['modelable_type'] = this.model.model_name;
        }
        if (this.tag) {
            data['tag'] = this.tag;
        }
        this.createOrUpdateOrNothing(data, this.remark_message)
            .then(remark => {
                if (this.documents && this.documents.length) {
                    for (let i = 0; i < this.documents.length; i++) {
                        const doc: any = this.documents[i];
                        if (doc && doc.id) {
                            doc.modelable_id = remark.id;
                            doc.modelable_type = 'App\\JobRemark';
                            this.modelApi.update(doc, ['modelable_id', 'modelable_type'], null, null, null, false, true)
                                .subscribe((response: any): void => {
                                    //
                                }, error => {
                                    //
                                });
                        }
                    }
                }
                this.onSubmit.emit({
                    remark_message: this.remark_message,
                    solve_message: this.solve_message
                });
                this.close();
            });
    }

    public reset(): void {
        this.remark_message = '';
        this.solve_message = '';
        // this.current_remark_message = '';
        if (this.documents) {
            this.documents.splice(0, this.documents.length);
        }
    }

}
