import {AfterViewInit, Component, Input} from '@angular/core';

@Component({
    selector: 'app-page-error-component',
    templateUrl: 'pageError.component.html'
})
export class PageErrorComponent implements AfterViewInit {

    @Input('code') code: string;
    @Input('message') message: string;
    @Input('title') title: string;

    public _code: string;
    public _message: string;
    public _title: string;

    constructor() {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._code = (this.code) ? this.code : '404';
            this._message = (this.message) ? this.message : 'Oops, an error has occurred. Page not found!';
            this._title = (this.title) ? this.title : this.code;
        }, 0);
    }

}
