import {Location} from '@angular/common';
import {ViewTask} from '../../task/view/viewTask';
import {Viewer} from '../../../services/viewer';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';

export class ViewRequest extends ViewTask {

    constructor(
        injectable: {
            viewer?: Viewer,
            taskService?: TaskService,
            api?: Api,
            modal?: ModalService,
            location?: Location
        }
    ) {
        //
        super(injectable);
    }

    public navBack(): void {
        if (this.location) {
            this.location.back();
        } else {
            ///
        }
    }

}
