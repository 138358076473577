import {Model} from '../now/model';
import {CustomerModel} from './customer.model';
import {ContactModel} from './contact.model';
import {UserModel} from '../now/user/user.model';
import {AppointmentModel} from './appointment.model';
import {DocumentModel} from './document.model';
import {FormModel} from '../now/formModel';
import {ItemRequirementModel} from './itemRequirement.model';

export class RequirementModel extends FormModel {

    public task_id: string;
    public type: string;
    public name: string;
    public drawing_no: string;
    public material_name: string;
    public remark: string;
    public minutes: number;
    public other: string;
    public description: string;
    public todo: string;
    public customer_status: number;
    public distance: number;
    public user: UserModel;
    public documents: DocumentModel[];
    public sale: UserModel;
    public customer: CustomerModel;
    public contact: ContactModel;
    public appointments: AppointmentModel[];
    public sale_id: string;
    public customer_id: string;
    public contact_id: string;
    public contact_ref_no: string;
    public ref_no: string;
    public purchase_order_no: string;
    public quotation_no: string;
    public other_document: string;
    public summary_drawing_no: string;
    public summary_drawing_no_date: string;
    public summary_quotation_no: string;
    public summary_quotation_date: string;
    public summary_quotation_1: string;
    public summary_quotation_2: string;
    public summary_job_no: string;
    public summary_job_no_date: string;
    public summary_purchase_no: string;
    public summary_purchase_no_date: string;
    public summary_load_job_no: string;
    public summary_load_job_no_date: string;
    public current_appointment_id: number;

    public date: string;
    public start_time: string;
    public end_time: string;
    public place_name: string;
    public item_requirements: ItemRequirementModel[];

    public item_requirement_ids: any[];

    constructor() {
        super('requirements', 'App\\Requirement');
        //
        this.customer = new CustomerModel();
        this.sale = new UserModel();
        this.contact = new ContactModel();
        this.user = new UserModel();
        this.appointments = [];
        this.documents = [];
        this.item_requirements = [];
        this.item_requirement_ids = [];
    }

    public clone(data: any): Model {
        if (data && data.customer) {
            let customer: CustomerModel;
            customer = new CustomerModel();
            customer.clone(data.customer);
            this.customer = customer;
            delete data.customer;
        }
        if (data && data.contact) {
            let contact: ContactModel;
            contact = new ContactModel();
            contact.clone(data.contact);
            this.contact = contact;
            delete data.contact;
        }
        if (data && data.sale) {
            let sale: UserModel;
            sale = new UserModel();
            sale.clone(data.sale);
            this.sale = sale;
            delete data.sale;
        }
        if (data && data.item_requirements) {
            for (let i = 0; i < data.item_requirements.length; i++) {
                const dat: any = data.item_requirements[i];
                if (dat) {
                    let item: ItemRequirementModel;
                    item = new ItemRequirementModel();
                    item.clone(dat);
                    this.item_requirements.push(item);
                }
            }
            delete data.item_requirements;
        }
        if (!this.appointments) {
            this.appointments = [];
        } else {
            this.appointments.splice(0, this.appointments.length);
        }
        if (data && data.appointments) {
            for (let i = 0; i < data.appointments.length; i++) {
                const dat: any = data.appointments[i];
                if (dat) {
                    let appointment: AppointmentModel;
                    appointment = new AppointmentModel();
                    appointment.clone(dat);
                    this.appointments.push(appointment);
                }
            }
            delete data.appointments;
        }
        if (data && data.documents) {
            for (let i = 0; i < data.documents.length; i++) {
                const dat: any = data.documents[i];
                if (dat) {
                    let document: DocumentModel;
                    document = new DocumentModel();
                    document.clone(dat);
                    this.documents.push(document);
                }
            }
            delete data.documents;
        }
        super.clone(data);
        return this;
    }

    public get latest_appointment(): AppointmentModel {
        if (this.appointments && this.appointments.length > 0) {
            return this.appointments[this.appointments.length - 1];
        }
        return null;
    }

}
