import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {AuthService} from '../../../now/user/auth.service';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {CarrierService} from '../../../services/carrier.service';
import {PusherService} from '../../../services/pusher.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {UserService} from '../../../services/user.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {SwalService} from '../../../services/swal.service';
import {DOCUMENT, Location} from '@angular/common';
import {TaskModel} from '../../../models/task.model';
import {environment} from '../../../environments/environment';
import {ViewTask} from '../view/viewTask';
import {DrawingModel} from '../../../models/drawing.model';
import {RequisitionModel} from '../../../models/requisition.model';
import {PrepareModalComponent} from './prepareModal/prepareModal.component';
import {StoreService} from '../../../services/store.service';
import {MaterialModel} from '../../../models/material.model';
import {MaterialCreateModal} from '../view/product/materialCreate/materialCreate.modal';
import {Model} from '../../../now/model';
import {ProductMaterial} from '../../../models/productMaterial';
import {ItemModel} from '../../../models/item.model';
import {ProcessPipe} from '../../../pipes/process.pipe';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'prepare-task-component',
    templateUrl: 'prepareTask.component.html',
    styleUrls: ['prepareTask.component.scss'],
    providers: [
        ProcessPipe
    ]
})
export class PrepareTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;

    public current_job_document_path: string;
    public pdfInfo: any;
    public numPages: number[];
    public job_id: string;

    public product: ProductModel;
    public product_id: string;
    public task_id: string;
    public current_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public job: JobModel;
    public requisition_id: string;
    public requisition: RequisitionModel;
    public job_no: string;
    public materials: any[];

    public children: any[];
    public parent_job: any;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        public router: Router,
        private storeService: StoreService,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        private processPipe: ProcessPipe,
        public api: Api,
        public modal: ModalService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.materials = [];

        this.route.params
            .subscribe(params => {
                this.viewTaskInit();
                this.init();
                this.job_id = params['job_id'];
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.onSuccess(this.task);
                        });
                }
            });
    }

    private init(): void {
        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.requisition = new RequisitionModel();
    }

    public showMaterialDetail(product_material: any): void {
        if (product_material && product_material.id) {
            this.modal.show(MaterialCreateModal, {
                viewable: true,
                editable: false,
                type: 'frw',
                submit_text: 'เลือกเพื่อใช้งานแทน',
                item_name: product_material.item.search_value,
                material_model: product_material.item,
                current_item: product_material.item
            }, {
                //
            }).then((data: any): void => {
                //
            });
        } else {
            //
        }
    }

    private getCheckedMaterials(materials: ProductMaterial[]): any[] {
        let checked_materials: any[];
        checked_materials = [];
        if (this.materials) {
            for (let i = 0; i < materials.length; i++) {
                const product_material: ProductMaterial = materials[i];
                if (product_material && product_material.item && product_material.item.id) {
                    checked_materials.push({
                        /*id              : material.id,
                        guid            : material.guid,
                        material_id     : (material.material_id) ? material.material_id : material.item.id,
                        price           : material.price,
                        amount          : material.amount,
                        modelable_type  : (material.modelable_type) ? material.modelable_type : material.item.modelable_type,
                        modelable_id    : (material.modelable_id) ? material.modelable_id : material.item.modelable_id,
                        remark          : material.remark,
                        replacement_id  : material.replacement_id,
                        current_role    : material.current_role,
                        checked         : material.checked,
                        prepared_status : material.prepared_status,
                        job_id          : material.job_id,
                        parent_id       : material.parent_id,
                        shipping_price      : material.shipping_price,
                        profit_percentage   : material.profit_percentage,*/
                        id: product_material.id,
                        prepared_status: product_material.prepared_status,
                        status: product_material.status,
                        guid: product_material.guid,
                        price: product_material.price,
                        amount: product_material.amount,
                        parent_id: product_material.parent_id,
                        shipping_price: product_material.shipping_price,
                        profit_percentage: product_material.profit_percentage,
                        current_role: product_material.current_role,
                        checked: product_material.checked,
                        job_id: product_material.job_id,
                        remark: product_material.remark,
                        // material_id is item_id field in items table
                        material_id: (product_material && product_material.item) ? product_material.item.id : ''
                    });
                }
                if (product_material && product_material.children && product_material.children.length) {
                    checked_materials = checked_materials.concat(this.getCheckedMaterials(product_material.children));
                }
            }
        }
        return checked_materials;
    }

    public save(skip?: boolean): void {
        this.estimated(this.product_id)
            .then(() => {
                if (skip === true) {
                    //
                } else {
                    this.swal.success('ทำการบันทึกข้อมูลสำเร็จ', null, 2000);
                }
            }, error => {
                if (skip === true) {

                } else {
                    this.swal.danger('กรุณาเลือกรายการวัสดุทดแทน');
                }
            });
    }

    public estimated(product_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const checked_materials: any = this.getCheckedMaterials(this.materials);
            if (checked_materials === false) {
                reject();
            } else {
                this.api.request('products/estimated/materials', 'PUT', {}, {
                    product_id: product_id,
                    materials: checked_materials
                }).subscribe((response: any): void => {
                    if (response && response.data) {
                        for (let i = 0; i < this.materials.length; i++) {
                            const material = this.materials[i];
                            if (material && material.children) {
                                for (let j = 0; j < material.children.length; j++) {
                                    const child = material.children[j];
                                    if (child) {
                                        const exist_material1 = response.data.find(k => k.guid === child.guid);
                                        if (exist_material1) {
                                            child.id = exist_material1.id;
                                        } else {
                                            //
                                        }
                                    }
                                }
                            }
                            const exist_material2 = response.data.find(k => k.guid === material.guid);
                            if (exist_material2) {
                                material.id = exist_material2.id;
                            } else {
                                //
                            }
                        }
                    }
                    resolve(response);
                }, error => {
                    reject(error);
                });
            }
        });
        return promise;
    }

    public deleteEstimate(models: Model[], model: Model, index: number, parent?: MaterialModel, child_index?: number, force?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            if (models && model && index > -1) {
                if (force === true) {
                    if (model.id) {
                        this.api.request('product/materials/delete', 'POST', {}, {
                            id: model.id
                        }).subscribe((response: any): void => {
                            this._delete(models, index, model, parent, child_index);
                            resolve();
                        });
                    } else {
                        this._delete(models, index, model, parent, child_index);
                        resolve();
                    }
                } else {
                    this.swal.confirm('ยืนยันการยกเลิกรายการใช่หรือไม่?')
                        .then((result: boolean): void => {
                            if (result === true) {
                                if (model.id) {
                                    this.api.request('product/materials/delete', 'POST', {}, {
                                        id: model.id
                                    }).subscribe((response: any): void => {
                                        this._delete(models, index, model, parent, child_index);
                                        resolve();
                                    });
                                } else {
                                    this._delete(models, index, model, parent, child_index);
                                    resolve();
                                }
                            } else {
                                resolve();
                            }
                        });
                }
            } else {
                //
            }
        });
        return promise;
    }

    private _delete(models: any[], index: number, model: any, parent?: MaterialModel, child_index?: number): void {
        // if (model && model.replacement && model.replacement.id) {
        //     model.replacement_id = undefined;
        //     model.replacement.checked = true;
        //     model.replacement.prepared_status = undefined;
        // }
        // models.splice(index, 1);
        // model.is_delete = true;
        if (parent && child_index > -1) {
            parent.children.splice(child_index, 1);
            parent.checked = true;
            parent.prepared_status = undefined;
        } else {
            models.splice(index, 1);
        }
    }

    public onPrepareChange(item: any, e?: any): void {
        this.modal.show(PrepareModalComponent, {
            item: item
        }).then((data: any): void => {
            if (data && data.submit === true) {
                this.save(true);
            } else {
                //
            }
        });
    }

    public getPrearedItem(): any {
        let prepared_item: any;
        prepared_item = {};
        for (let i = 0; i < this.materials.length; i++) {
            const material: any = this.materials[i];
            if (material) {
                prepared_item[material.id] = {
                    remark: material.pivot.remark,
                    prepared_status: material.prepared_status,
                    prepared_at: material.pivot.prepared_at
                };
            }
        }
        return prepared_item;
    }

    public addMoreMaterial(parent_material: MaterialModel, index?: number): ProductMaterial {
        let product_material: ProductMaterial;
        product_material = new ProductMaterial();
        product_material.parent_id = (parent_material.id) ? parent_material.id : '';
        product_material.current_role = (this.task.current_role) ? this.task.current_role : 'product';
        product_material.checked = true;
        product_material.product_id = this.product.id;
        product_material.job_id = this.job_id;
        product_material.status = 1;
        if (parent_material && parent_material.children) {
            parent_material.children.push(product_material);
        } else {
            //
        }
        return product_material;
    }

    public onMaterialMoreHandler(material: MaterialModel, e: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : false,
            editable        : false,
            type            : 'frw',
            submit_text     : 'ยืนยันใช้วัสดุ',
            item_id         : material.item.id,
            item_name       : material.item.search_value,
            material_model  : material.item,
            current_item    : material.item
        }, {
            class: 'modal-xl'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                    //
                } else {
                    //
                }
            }
        });
    }

    public onMaterialCreating(material: MaterialModel, e: any): void {
        setTimeout(() => { // add delay when model to select
            this.onClickCreateCuttingMaterial(material.item, e);
        }, 0);
    }

    public onMaterialTypeaheadSelect(material: MaterialModel, i: number, e?: any): void {
        if (e && e.id) {
            //
        } else {
            this.deleteEstimate(this.product.materials, material, i, null, -1, true);
        }
        // material.pivot.price = (material.price) ? material.price : material.modelable.price;
    }

    public onClickCreateCuttingMaterial(item: ItemModel, e: any): void {
        // create a item into store
        this.modal.show(MaterialCreateModal, {
            // editable        : (this.editable === true) ? true : false,
            warehouse       : false,
            editable        : true,
            viewable        : true,
            type            : 'frw',
            submit_text     : 'ยืนยันใช้วัสดุ',
            item_name       : (item.search_value) ? item.search_value : item.name,
            current_item    : item,
            material_model  : item
        }, {
            class: 'modal-md'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                } else {
                    //
                }
            }
        });
    }

    public onChange(material: MaterialModel, i: number, e?: any): void {
        if (material.children && material.children.length) {
            this.deleteEstimate(this.materials, material.children[0], i, material, 0, true)
                .then(() => {
                    if (e && e === 1) {
                        material.prepared_status = 1;
                    } else if (e && e === 2) {
                        material.prepared_status = 2;
                    } else if (e && e === 3) {
                        material.checked = false;
                        material.prepared_status = 3;
                        this.addMoreMaterial(material, i);
                    } else if (e && e === 4) {
                        material.checked = true;
                        material.prepared_status = 4;
                        const _material = this.addMoreMaterial(material, i);
                        _material.prepared_status = 5;
                    }
                });
        } else {
            if (e && e === 1) {
                //
            } else if (e && e === 2) {
                //
            } else if (e && e === 3) {
                material.checked = false;
                this.addMoreMaterial(material, i);
            } else if (e && e === 4) {
                material.checked = true;
                const _material = this.addMoreMaterial(material, i);
                _material.prepared_status = 5;
            }
        }
    }

    private get_checked_items(): any[] {
        let items: any[];
        items = [];
        for (let i = 0; i < this.materials.length; i++) {
            const material: any = this.materials[i];
            if ((material.prepared_status === 1 || material.prepared_status === 5) && material.job_id === this.job_id) {
                items.push({
                    modelable_type: material.model_name,
                    modelable_id: material.id,
                    item_id: material.item.id,
                    amount: material.amount,
                    remark: material.remark,
                    dateTime: material.dateTime,
                    prepared_status: material.prepared_status,
                    price: material.price,
                    vendor_id: material.vendor_id
                });
            }
            if (material.children) {
                for (let j = 0; j < material.children.length; j++) {
                    const mat_child: any = material.children[j];
                    if ((mat_child.prepared_status === 1 || mat_child.prepared_status === 5) && mat_child.job_id === this.job_id) {
                        items.push({
                            modelable_type: mat_child.model_name,
                            modelable_id: mat_child.id,
                            item_id: mat_child.item.id,
                            amount: mat_child.amount,
                            remark: mat_child.remark,
                            dateTime: mat_child.dateTime,
                            prepared_status: mat_child.prepared_status,
                            price: mat_child.price,
                            vendor_id: mat_child.vendor_id
                        });
                    }
                }
            }
        }
        return items;
    }

    private get_job_children(): string[] {
        let job_ids: string[];
        job_ids = [];
        if (this.children && this.children.length) {
            for (let i = 0; i < this.children.length; i++) {
                job_ids.push(this.children[i].job_id);
            }
        }
        return job_ids;
    }

    public createPricingInquiry(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const job_ids: string[] = this.get_job_children();
            const items: any[] = this.get_checked_items();
            this.storeService.pricingInquiry(
                this.task,
                this.task.modelable_id,
                this.parent_job.product_id,
                null,
                items,
                this.parent_job.customer_id,
                null,
                null,
                null,
                null,
                job_ids
            ).then(response => {
                this.taskService.setStatus(
                    this.task,
                    this.task.status,
                    'create_pricing_inquiry',
                    'request-purchase',
                    'create_pricing_inquiry'
                ).then(() => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public prepared_to_planning(role: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.estimated(this.product_id)
                .then(() => {
                    let role_slug: string;
                    role_slug = (this.job.current_role === 'planning') ? 'planning' : this.job.current_role;
                    this.storeService.preparedItems(this.task, this.product_id, this.product.materials, role_slug, this.job.process_slug)
                        .then(response => {
                            this.swal.success('ส่งงานให้กับแผนก ' + role + ' สำเร็จ');
                            this.leave(true);
                            resolve(response);
                        }, error => {
                            reject(error);
                        });
                }, error => {
                    this.swal.danger('กรุณาเลือกรายการวัสดุทดแทน');
                });
        });
        return promise;
    }

    public check_prepare_item(materials: ProductMaterial[]): any {
        let returned_data: number;
        returned_data = 0;
        for (let i = 0; i < materials.length; i++) {
            const material: any = materials[i];
            if (material && material.children && material.children.length) {
                if (this.check_prepare_item(material.children) === false) {
                    return false;
                }
            }
            if (material.prepared_status) {
                const prepared_status: number = +material.prepared_status;
                if (prepared_status === 1) {
                    returned_data = 1;
                    break;
                } else if (prepared_status === 2) {
                    returned_data = 2;
                    break;
                } else if (prepared_status === 3) {
                    returned_data = 3;
                    break;
                } else if (prepared_status === 4) {
                    returned_data = 4;
                    break;
                } else if (prepared_status === 6) {
                    returned_data = 6;
                    break;
                } else if (prepared_status === -1) {
                    returned_data = -1;
                    break;
                }
            } else if (!material.amount && material.checked && material.current_role === this.task.current_role) {
                return false;
            } else {
                //
            }
        }
        return returned_data;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    private material_children(): void {
        for (const material of this.materials) {
            if (material && material.prepared_status === 3) {
                if (!material.children || !material.children.length) {
                    material.checked = false;
                    this.addMoreMaterial(material);
                }
            }
        }
    }

    public onSuccess(data: any): void {
        this.current_tab = '#manufacture';
        this.job_id = (this.job_id) ? this.job_id : data.modelable_id;
        this.getJob(this.job_id)
            .then(() => {
                if (this.job && this.job.started_at) {
                    this.parent_job = this.job;
                } else {
                    this.getJobChildren(this.job_id)
                        .then((children_response: any): void => {
                            //
                        }, error => {
                            //
                        });
                }
                this.product_id = this.job.product_id;
                this.getProductDetail()
                    .then(() => {
                        this.viewer.manufacture(this.job, false, this.job.revision)
                            .then(path => {
                                this.current_job_document_path = path;
                                this.materials = this.product.materials;
                                this.initMaterials();
                                this.material_children();
                                this.doReady();
                            });
                    });
            });
    }

    public getJobChildren(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id + '/children')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (this.children) {
                            this.children.splice(0, this.children.length);
                        } else {
                            this.children = [];
                        }
                        if (response.data.parent) {
                            const parent_job: any = response.data.parent;
                            this.children.push({
                                task: parent_job.task,
                                task_id: (parent_job.task) ? parent_job.task.id : null,
                                job_id: parent_job.id,
                                product_name: (parent_job.product) ? parent_job.product.name : null
                            });
                            this.parent_job = parent_job;
                        }
                        if (response.data.children) {
                            for (let i = 0; i < response.data.children.length; i++) {
                                const dat: any = response.data.children[i];
                                if (dat) {
                                    this.children.push({
                                        task: dat.task,
                                        task_id: (dat.task) ? dat.task.id : null,
                                        job_id: dat.id,
                                        product_name: (dat.product) ? dat.product.name : null
                                    });
                                }
                            }
                        }
                        resolve(this.children);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public initMaterials(): void {
        for (let i = 0; i < this.materials.length; i++) {
            const material: MaterialModel = this.materials[i];
            if (material && material.id && material.replacement_id) {
                material.replacement = this.materials.find(j => j.id === material.replacement_id);
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public getRequisition(requisition_id): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('requisitions/' + requisition_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.requisition.clone(response.data);
                        this.job_id = this.requisition.job_id;
                        this.job_no = this.requisition.job_no;
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public getPreparedStatus(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/prepare/status', 'POST', {}, {
                id: this.task.modelable_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat: any = response.data[i];
                        if (dat.prepared_status) {
                            const prepared_status: number = +dat.prepared_status;
                            if (prepared_status === 1) {
                                resolve(1);
                                return;
                            } else if (prepared_status === 2) {
                                resolve(2);
                                return;
                            } else if (prepared_status === 3) {
                                resolve(3);
                                return;
                            } else if (prepared_status === 4) {
                                resolve(4);
                                return;
                            } else if (prepared_status === 6) {
                                resolve(6);
                                return;
                            } else if (prepared_status === -1) {
                                resolve(-1);
                                return;
                            }
                        }
                    }
                    resolve(0);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public checkPreparedStatus(): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.api.request('jobs/prepare/check', 'POST', {}, {
                id: this.task.modelable_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    if (response.data && response.data.material_name) {
                        resolve({
                            product_name    : response.data.product_name,
                            product_no      : response.data.product_no,
                            job_no          : response.data.job_no,
                            material_name   : response.data.material_name,
                            prepared_status : response.data.prepared_status,
                            result          : false
                        });
                    } else {
                        resolve({
                            product_name    : null,
                            product_no      : null,
                            job_no          : null,
                            material_name   : null,
                            prepared_status : null,
                            result          : true
                        });
                    }
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public complete(): void {
        const prepared_status: any = this.check_prepare_item(this.materials);
        if (prepared_status === false) {
            this.swal.danger('กรุณาระบุจำนวนที่ต้องการใช้งาน');
            return;
        }
        if (prepared_status === -1) {
            this.swal.danger('กรุณาระบุการจัดการเพื่อดำเนินการต่อไป');
            return;
        }
        this.checkPreparedStatus()
            .then((response: any): void => {
                if (response.result === true) {
                    this.getPreparedStatus()
                        .then(_prepared_status => {
                            let role: string;
                            role = this.processPipe.transform(this.job.current_role);
                            if (prepared_status === 1 || prepared_status === 4 || _prepared_status === 1 || _prepared_status === 4) {
                                this.swal.confirm('ส่งต่องานให้กับแผนก PR สำหรับการสั่งซื้อ และกำหนดวันได้รับวัสดุ ใช่หรือไม่?')
                                    .then((result: boolean): void => {
                                        if (result === true) {
                                            this.estimated(this.product_id)
                                                .then(() => {
                                                    this.createPricingInquiry()
                                                        .then(() => {
                                                            this.swal.success('ส่งงานให้กับแผนก Purchase สำเร็จ');
                                                            this.leave(true);
                                                        });
                                                }, error => {
                                                    this.swal.danger('กรุณาเลือกรายการวัสดุทดแทน');
                                                });
                                        } else {
                                            //
                                        }
                                    });
                            } else if (prepared_status === 2 || _prepared_status === 2) {
                                //
                            } else if (prepared_status === 3 || _prepared_status === 3) {
                                this.swal.confirm('ยืนยันการส่งต่องานให้กับ ' + role + ' เพื่อใช้วัสดุอื่นทดแทน ใช่หรือไม่?')
                                    .then((result: boolean): void => {
                                        if (result === true) {
                                            this.prepared_to_planning(role)
                                                .then(() => {
                                                    //
                                                });
                                        } else {
                                            //
                                        }
                                    });
                            } else if (prepared_status === 0 || _prepared_status === 0) {
                                this.swal.confirm('ยืนยันการส่งต่องานให้กับ ' + role + ' ใช่หรือไม่?')
                                    .then((result: boolean): void => {
                                        if (result === true) {
                                            this.prepared_to_planning(role)
                                                .then(() => {
                                                    //
                                                });
                                        } else {
                                            //
                                        }
                                    });
                            } else {
                                this.swal.danger('กรุณาเลือกการเตรียมความพร้อมของวัสดุให้ครบ');
                            }
                        });
                } else {
                    this.swal.danger(response.product_name +
                        ' (' + response.job_no + ') ' +
                        response.material_name +
                        ' กรุณาระบุการจัดการเพื่อดำเนินการต่อไป');
                }
            });
    }

    public gotoParent(): void {
        if (this.parent_job) {
            this.router.navigateByUrl('/task/' + this.task.current_role + '/store/' + this.parent_job.task_id);
        } else {
            //
        }
    }

    public onPdfLoadCompleted(e: any): void {
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    private reset_products(): void {
        if (this.products) {
            this.products.splice(0, this.products.length);
        } else {
            this.products = [];
        }
    }

    public onSaveApiCallingBack(e: any): void {
        this.save(true);
    }

}
