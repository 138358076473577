import {environment} from '../environments/environment';
import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';

@Injectable()
export class Viewer {

    constructor(
        private api: Api
    ) {
        //
    }

    /*public static shipping(model: any): void {
        if (model && model.id) {
            window.open(environment.api_host + 'view/' + this.model.id + '/shipping', '_blank');
        } else if (model && +model > 0) {
            window.open(environment.api_host + 'view/' + model + '/shipping', '_blank');
        } else {
            console.warn('Shipping not found', model);
        }
    }*/

    public coverSheet(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/cover_sheet?token=' + data.token;
                    } else if (model && model.job_no) {
                        path = environment.api_host + 'view/' + model.job_no + '/cover_sheet/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/cover_sheet?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/cover_sheet/no?token=' + data.token;
                    } else {
                        console.warn('Job (Cover Sheet) not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public hrReport(options, new_window?): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    const date = (options.date) ? options.date : '';
                    const view = (options.view) ? options.view : '';
                    const startDate = (options.startDate) ? options.startDate : '';
                    const endDate = (options.endDate) ? options.endDate : '';
                    const man_id = (options.man_id) ? options.man_id : '';
                    const path = environment.api_host + 'view/hr/report/?token=' + data.token + '&date=' + date + '&view=' + view + '&man_id=' + man_id + '&startDate=' + startDate + '&endDate=' + endDate;
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public readRequest(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            this.api.request('read/request', 'POST', {}, {})
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public storeReport(type?: string, all?, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    type = (type) ? type : '';
                    all = (all) ? 1 : 0;
                    const path = environment.api_host + 'view/store/report/' + type + '?all=' + all + '&token=' + data.token;
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public itemNO(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/item?token=' + data.token;
                    } else if (model && model.document_no) {
                        path = environment.api_host + 'view/' + model.document_no + '/item/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/item?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/item/no?&token=' + data.token;
                    } else {
                        console.warn('Item not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public debitNote(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/debit/note?token=' + data.token;
                    } else if (model && model.document_no) {
                        path = environment.api_host + 'view/' + model.document_no + '/debit/note/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/debit/note?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/debit/note/no?&token=' + data.token;
                    } else {
                        console.warn('Debit Note not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public purchaseRequisition(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/pr?token=' + data.token;
                    } else if (model && model.document_no) {
                        path = environment.api_host + 'view/' + model.document_no + '/pr/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/pr?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/pr/no?&token=' + data.token;
                    } else {
                        console.warn('PR not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public creditNote(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/credit/note?token=' + data.token;
                    } else if (model && model.document_no) {
                        path = environment.api_host + 'view/' + model.document_no + '/credit/note/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/credit/note?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/credit/note/no?&token=' + data.token;
                    } else {
                        console.warn('Credit Note not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public preventiveMA(dateOrPMAId: any, machineId?, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    if (dateOrPMAId && machineId) {
                        const path = environment.api_host + 'view/' + dateOrPMAId + '/' + machineId + '/preventive_ma?token=' + data.token;
                        if (path && new_window !== false) {
                            window.open(path, '_blank');
                        }
                        resolve(path);
                    } else if (dateOrPMAId) {
                        const path = environment.api_host + 'view/' + dateOrPMAId + '/preventive_ma/id?token=' + data.token;
                        if (path && new_window !== false) {
                            window.open(path, '_blank');
                        }
                        resolve(path);
                    }
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public ncrs(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/job/ncrs?token=' + data.token;
                    } else if (model && model.job_no) {
                        path = environment.api_host + 'view/' + model.job_no + '/job/ncrs/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/job/ncrs?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/job/ncrs/no?&token=' + data.token;
                    } else {
                        console.warn('Job [NCRs] not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public fmt(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/fmt?token=' + data.token;
                    } else if (model && model.document_no) {
                        path = environment.api_host + 'view/' + model.document_no + '/fmt/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/fmt?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/fmt/no?token=' + data.token;
                    } else {
                        console.warn('FMT not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public leave(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/leave?token=' + data.token;
                    } else if (model && model.document_no) {
                        path = environment.api_host + 'view/' + model.document_no + '/leave/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/leave?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/leave/no?token=' + data.token;
                    } else {
                        console.warn('Leave not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public ncr(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/ncr?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/ncr?token=' + data.token;
                    } else {
                        console.warn('NCR not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public car(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/car?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/car?token=' + data.token;
                    } else {
                        console.warn('CAR not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public waiver(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/waiver?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/waiver?token=' + data.token;
                    } else {
                        console.warn('NCR Waiver not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public document(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/document?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/document?token=' + data.token;
                    } else {
                        console.warn('Document not found', model);
                        path = environment.api_host + 'view/public/document?token=' + data.token + '&path=' + model.native_path + '&mime_type=' + model.mime_type;
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public manufacture(model: any, new_window?: boolean, revision_all_draft?: any): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            revision_all_draft = (revision_all_draft === true) ? 'all=1' : (revision_all_draft === false) ? 'draft=1' : (revision_all_draft > -1) ? 'revision=' + revision_all_draft : 'revision=0';
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/manufacture?' + revision_all_draft + '&token=' + data.token;
                    } else if (model && model.job_no) {
                        path = environment.api_host + 'view/' + model.job_no + '/manufacture/no?' + revision_all_draft + '&token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/manufacture?' + revision_all_draft + '&token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/manufacture/no?' + revision_all_draft + '&token=' + data.token;
                    } else {
                        console.warn('Job not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public labelByShippingDocument(shippingDocument, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    const shippingDocumentId = shippingDocument.id;
                    path = environment.api_host + 'view/' + shippingDocumentId + '/shipping/label?token=' + data.token;
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public saleOrder(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/sale_order?token=' + data.token;
                    } else if (model && model.quotation_no) {
                        path = environment.api_host + 'view/' + model.quotation_no + '/sale_order/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/sale_order?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/sale_order/no?token=' + data.token;
                    } else {
                        console.warn('Sale Order not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public label(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/label?token=' + data.token;
                    } else if (model && model.job_no) {
                        path = environment.api_host + 'view/' + model.job_no + '/label/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/label?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/label/no?token=' + data.token;
                    } else {
                        console.warn('Job (label) not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public paymentByShippingDocument(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/shipping/payment?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/shipping/payment?token=' + data.token;
                    } else {
                        console.warn('Shipping Document [Payment] not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public payment(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/payment?token=' + data.token;
                    } else if (model && model.purchase_order_no) {
                        path = environment.api_host + 'view/' + model.purchase_order_no + '/payment/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/payment?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/payment/no?token=' + data.token;
                    } else {
                        console.warn('Payment not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public contactLog(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/contact?token=' + data.token;
                    } else if (model && model.contact_ref_no) {
                        path = environment.api_host + 'view/' + model.contact_ref_no + '/contact/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/contact?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/contact/no?token=' + data.token;
                    } else {
                        console.warn('Contact Log not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public pricingInquiryByFVD(model: any, new_window?: boolean): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/pricing/inquiries/fvd?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/pricing/inquiries/fvd?token=' + data.token;
                    } else {
                        console.warn('Pricing Inquiry [FVD] not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public pricingInquiry(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/pricing/inquiry?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/pricing/inquiry?token=' + data.token;
                    } else {
                        console.warn('Pricing Inquiry not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public product(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/product?token=' + data.token;
                    } else if (model && model.product_no) {
                        path = environment.api_host + 'view/' + model.product_no + '/product/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/product?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/product/no?token=' + data.token;
                    } else {
                        console.warn('Product not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public fvd(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/fvd?token=' + data.token;
                    } else if (model && model.product_no) {
                        path = environment.api_host + 'view/' + model.fvd_no + '/fvd/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/fvd?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/fvd/no?token=' + data.token;
                    } else {
                        console.warn('FVD not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public invoice(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/invoice?token=' + data.token;
                    } else if (model && model.invoice_no) {
                        path = environment.api_host + 'view/' + model.invoice_no + '/invoice/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/invoice?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/invoice/no?token=' + data.token;
                    } else {
                        console.warn('Invoice not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public taxInvoice(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/tax/invoice?token=' + data.token;
                    } else if (model && model.tax_invoice_no) {
                        path = environment.api_host + 'view/' + model.tax_invoice_no + '/tax/invoice/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/tax/invoice?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/tax/invoice/no?token=' + data.token;
                    } else {
                        console.warn('Tax Invoice not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public commercialInvoice(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/commercial/invoice?token=' + data.token;
                    } else if (model && model.tax_invoice_no) {
                        path = environment.api_host + 'view/' + model.tax_invoice_no + '/commercial/invoice/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/commercial/invoice?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/commercial/invoice/no?token=' + data.token;
                    } else {
                        console.warn('Commercial Invoice not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public account(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/account?token=' + data.token;
                    } else if (model && model.receipt_no) {
                        path = environment.api_host + 'view/' + model.receipt_no + '/account/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/account?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/account/no?token=' + data.token;
                    } else {
                        console.warn('Receipt not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public receipt(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/receipt?token=' + data.token;
                    } else if (model && model.receipt_no) {
                        path = environment.api_host + 'view/' + model.receipt_no + '/receipt/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/receipt?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/receipt/no?token=' + data.token;
                    } else {
                        console.warn('Receipt not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public deposit(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/deposit?token=' + data.token;
                    } else if (model && model.deposit_no) {
                        path = environment.api_host + 'view/' + model.deposit_no + '/deposit/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/deposit?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/deposit/no?token=' + data.token;
                    } else {
                        console.warn('Receipt not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public deliveryNote(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/delivery/note?token=' + data.token;
                    } else if (model && model.delivery_note_no) {
                        path = environment.api_host + 'view/' + model.delivery_note_no + '/delivery/note/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/delivery/note?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/delivery/note/no?token=' + data.token;
                    } else {
                        console.warn('Receipt not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public packingSlip(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/packing/slip?token=' + data.token;
                    } else if (model && model.packing_slip_no) {
                        path = environment.api_host + 'view/' + model.packing_slip_no + '/packing/slip/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/packing/slip?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/packing/slip/no?token=' + data.token;
                    } else {
                        console.warn('Receipt not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public packing(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/packing?token=' + data.token;
                    } else if (model && model.job_no) {
                        path = environment.api_host + 'view/' + model.job_no + '/packing/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/packing?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/packing/no?token=' + data.token;
                    } else {
                        console.warn('Job (Packing) not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public qc(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.job_no) {
                        path = environment.api_host + 'view/' + model.job_no + '/qc_label?token=' + data.token;
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public quotation(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/quotation?token=' + data.token;
                    } else if (model && model.quotation_no) {
                        path = environment.api_host + 'view/' + model.quotation_no + '/quotation/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/quotation?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/quotation/no?token=' + data.token;
                    } else {
                        console.warn('Quotation not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public requisition(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/requisition?token=' + data.token;
                    } else if (model && model.quotation_no) {
                        path = environment.api_host + 'view/' + model.quotation_no + '/requisition/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/requisition?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/requisition/no?token=' + data.token;
                    } else {
                        console.warn('Requisition not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public purchaseOrder(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/po?token=' + data.token;
                    } else if (model && model.purchase_order_no) {
                        path = environment.api_host + 'view/' + model.purchase_order_no + '/po/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/po?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/po/no?token=' + data.token;
                    } else {
                        console.warn('Purchase Order not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public cad(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/cad?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/cad?token=' + data.token;
                    } else {
                        console.warn('CAD not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public drawingWithProductNO(model: any, product_no: string, new_window?: boolean): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/drawing?token=' + data.token + '&product=' + product_no;
                    } else if (model && model.drawing_no) {
                        path = environment.api_host + 'view/' + model.drawing_no + '/drawing/no?token=' + data.token + '&product=' + product_no;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/drawing?token=' + data.token + '&product=' + product_no;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/drawing/no?token=' + data.token + '&product=' + product_no;
                    } else {
                        console.warn('Drawing not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

    public drawing(model: any, new_window?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>(resolve => {
            this.readRequest()
                .then((data: any): void => {
                    let path: string;
                    if (model && model.id) {
                        path = environment.api_host + 'view/' + model.id + '/drawing?token=' + data.token;
                    } else if (model && model.drawing_no) {
                        path = environment.api_host + 'view/' + model.drawing_no + '/drawing/no?token=' + data.token;
                    } else if (model && +model > 0) {
                        path = environment.api_host + 'view/' + model + '/drawing?token=' + data.token;
                    } else if (model) {
                        path = environment.api_host + 'view/' + model + '/drawing/no?token=' + data.token;
                    } else {
                        console.warn('Drawing not found', model);
                    }
                    if (path && new_window !== false) {
                        window.open(path, '_blank');
                    }
                    resolve(path);
                }, error => {
                    console.error('Token do not exists.', error);
                    resolve(null);
                });
        });
        return promise;
    }

}
