import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {ShippingModel} from '../models/shipping.model';

@Injectable()
export class ShippingService {

    private shippings: ShippingModel[];

    constructor(private api: Api) {
        this.reset_shipping_list();
    }

    public sendToAccounting(task_id: any): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('shipping/done', 'POST', {}, {
                task_id: task_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject({});
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public getShippings(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.shippings && this.shippings.length > 0) {
                for (let i = 0; i < this.shippings.length; i++) {
                    this.shippings[i].checked = false;
                }
                resolve(this.shippings);
            } else {
                this.api.request('shippings', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_shipping_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.shippings.push(dat);
                                }
                            }
                            resolve(this.shippings);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_shipping_list(): void {
        if (this.shippings) {
            this.shippings.splice(0, this.shippings.length);
        } else {
            this.shippings = [];
        }
    }

}
