import {Model} from '../now/model';
import {UserModel} from '../now/user/user.model';
import {RoleModel} from './role.model';
import {DocumentModel} from './document.model';

export class EngModel extends Model {

    public dateTime: string;
    public required_at: any;
    public issued_user: any;
    public issued_date: any;
    public issued_description: any;
    public document_no: string;
    public user_id: string;
    public user: UserModel;
    public role: string;
    public ref: string;
    public who: string;
    public what: string;
    public where: string;
    public when: string;
    public how: string;
    public request: string;
    public requested_at: string;
    public old_processes: string[];
    public old_performances: string[];
    public job_id: string;
    public job_no: string;
    public action: string;
    public days: number;
    public estimated_at: string;
    public cost: number;
    public requester_id: string;
    public requester: UserModel;
    public follow_up: string;
    public new_processes: string[];
    public new_performances: string[];
    public verified_1: boolean;
    public manager_id: string;
    public manager: UserModel;
    public verified_2: boolean;
    public remark: string;
    public documents: DocumentModel[];
    public started_at: string;
    public ended_at: string;

    constructor() {
        super('engs', 'App\\Eng');
        //
        this.user = new UserModel();
        this.requester = new UserModel();
        this.manager = new UserModel();
        this.documents = [];
        this.old_processes = [];
        this.old_performances = [];
        this.new_processes = [];
        this.new_performances = [];

        for (let i = 0; i < 5; i++) {
            this.old_performances.push('');
        }
        for (let i = 0; i < 5; i++) {
            this.old_processes.push('');
        }
        for (let i = 0; i < 5; i++) {
            this.new_performances.push('');
        }
        for (let i = 0; i < 5; i++) {
            this.new_processes.push('');
        }
    }

}
