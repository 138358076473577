import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskModel} from '../../../models/task.model';
import {CustomerModel} from '../../../models/customer.model';
import {ContactModel} from '../../../models/contact.model';
import {ProductModel} from '../../../models/product.model';
import {DocumentModel} from '../../../models/document.model';
import {DivApiDirective} from '../../../now/divApi';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {UserModel} from '../../../now/user/user.model';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {DrawingModel} from '../../../models/drawing.model';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../../now/user/auth.service';
import {QuotationModel} from '../../../models/quotation.model';
import {SwalService} from '../../../services/swal.service';
import {UserService} from '../../../services/user.service';
import {PurchaseOrderModel} from '../../../models/purchaseOrder.model';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {PusherService} from '../../../services/pusher.service';
import {ModalService} from '../../../services/modal.service';
import {CarrierModel} from '../../../models/carrier.model';
import {CarrierService} from '../../../services/carrier.service';
import {DocumentDetailComponent} from '../view/documentDetail/documentDetail.component';
import {JobModel} from '../../../models/job.model';
import {SchedulerModel} from '../../../models/scheduler.model';
import * as moment from 'moment';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
// import {CompleteModalComponent} from './complete/completeModal.component';
import {ViewTask} from '../view/viewTask';
import {DOCUMENT, Location} from '@angular/common';
import {PageScrollService, PageScrollInstance} from 'ngx-page-scroll-core';
import {PackingSlipModel} from '../../../models/packingSlip.model';
import {InspectionSheetModel} from '../../../models/inspectionSheet.model';
import {DeliveryOrderModel} from '../../../models/deliveryOrder.model';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {ProductService} from '../../../services/product.service';
import {MaterialCreateModal} from '../view/product/materialCreate/materialCreate.modal';
import {StartModalComponent} from './start/startModal.component';
import {EndModalComponent} from './end/endModal.component';
import {InvoiceModel} from '../../../models/invoice.model';
import {JobService} from '../../../services/job.service';
import {MaterialModel} from '../../../models/material.model';
import {PackagingModel} from '../../../models/packaging.model';
import {NCR} from '../../../app/api/ncr';
import {Viewer} from '../../../services/viewer';
import {LoaderService} from '../../../components/loader/loader.service';
import {CompleteModalComponent} from './complete/completeModal.component';

declare var window: any;

@Component({
    selector: 'prod-task-component',
    templateUrl: 'prodTask.component.html',
    styleUrls: ['prodTask.component.scss']
})
export class ProdTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;
    @ViewChild('completeModal', { static: false }) completeModal: CompleteModalComponent;

    public children: any[];
    public parent_job: any;
    public product_id: any;
    public materials: MaterialModel[];
    public task_id: string;
    public assemblies: ProductModel[];
    public customer: CustomerModel;
    public contact: ContactModel;
    public current_user: UserModel;
    public from_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public documents: DocumentModel[];
    public quotations: QuotationModel[];
    public purchase_orders: PurchaseOrderModel[];
    public proforma_invoices: any[];
    public tax_invoices: any[];
    public packing_slips: any[];
    public carriers: CarrierModel[];
    public current_role: string;
    public schedulers: SchedulerModel[];
    public job_id: string;

    public product_processes: ProductProcessModel[];

    public pdfInfo: any;
    public numPages: number[];

    public is_error: boolean;
    public is_loading: boolean;

    public packagings: PackagingModel[];

    public scheduler_histories: SchedulerHistoryModel[];
    public current_job_document_path: string;

    public job: JobModel;
    public planning_events: any[];

    public current_tab: string;
    public product: ProductModel;
    public current_part_product_index: number;
    public ncr_pdf_path: string;

    public options: any;

    public today_start: any;
    public today_end: any;

    public total_gap_time_minutes: number;
    public total_minutes: number;

    public is_pd_control: boolean;
    public ncrs: NCR[];

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        public router: Router,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private ngZone: NgZone,
        public api: Api,
        private loader: LoaderService,
        private productService: ProductService,
        public modal: ModalService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        private jobService: JobService,
        public pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.today_start = moment(new Date()).set('hours', 8).set('minutes', 0);
        this.today_end = moment(new Date()).set('hours', 20).set('minutes', 0);
        this.scheduler_histories = [];
        this.schedulers = [];
        this.product_processes = [];
        this.assemblies = [];
        this.materials = [];

        this.total_gap_time_minutes = 0;
        this.total_minutes = 0;
        this.is_pd_control = false;
        this.is_error = false;
        this.is_loading = false;

        this.packagings = [];
    }

    private init(): void {
        this.task = new TaskModel();
        this.task.next_role = 'dummy';

        this.contact = new ContactModel();
        this.product = new ProductModel();
        this.job = new JobModel();
        this.planning_events = [];
        this.documents = [];

        this.options = {
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'month'
            },
            events: []
        };

        this.numPages = [];
    }

    public changeScheduler(): void {
        if (this.is_pd_control === true) {
            this.swal.confirm('คุณต้องการปรับแผนงานนี้ใช่หรือไม่?', null, null
                , 'ปรับแผน', 'ปิด')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.jobService.changePlan(this.task, this.job)
                            .then(() => {
                                // TODO: change job toggle history to back-end process
                                this.taskService.hire(this.task, null, null, 'change_plan', null, null, 'ปรับแผน (R' + this.job.revision + ')');
                                /*this.jobService.history(this.job, null, null, 'ปรับแผน', 'control')
                                    .then(() => {
                                        this.taskService.hire(this.task);
                                    });*/
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public getNCRsByJob(job: JobModel): Promise<NCR[]> {
        let promise: Promise<NCR[]>;
        promise = new Promise<NCR[]>((resolve, reject) => {
            this.jobService.getNCRs(job)
                .then(ncrs => {
                    resolve(ncrs);
                }, error => {
                    resolve([]);
                });
        });
        return promise;
    }

    public initControlRole(): void {
        const user: UserModel = this.authService.user;
        if (user && user.roles && user.roles.indexOf('control') > -1) {
            this.is_pd_control = true;
        } else {
            this.is_pd_control = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.loader.show();
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                this.onSuccess(this.task);
                                this.initControlRole();
                            });
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public viewDocumentDetail(doc: DocumentModel, documents?: any[], index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            job: this.job,
            deletable: (documents && index > -1 && this.task.current_role === documents[index].role) ? true : false
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((content: any) => {
                if (content && content.submit === true) {
                  if (documents && documents[index]) {
                    documents.splice(index, 1);
                  }
                } else {
                  //
                }
            });
    }

    public showMaterialDetail(material: any): void {
        this.modal.show(MaterialCreateModal, {
            item_id         : material.item.id,
            item_name       : material.search_value,
            material_model  : material,
            editabled       : false
        }).then((data: any): void => {
            //
        });
    }

    public onDocumentUploadedSuccess(data: any, role?: string): void {
        this.api.request('jobs/document', 'PUT', {}, {
            document    : data,
            id          : this.job.id,
            role        : role
        }).subscribe((response: any): void => {
            if (response && response.data) {
                if (role === 'shipping') {
                    let delivery_order: DeliveryOrderModel;
                    delivery_order = new DeliveryOrderModel();
                    delivery_order.document.clone(response.data);
                    delivery_order.role = role;
                    this.job.delivery_orders.push(delivery_order);
                } else if (role === 'qc-process') {
                    let process_inspection_sheet: InspectionSheetModel;
                    process_inspection_sheet = new InspectionSheetModel();
                    process_inspection_sheet.document.clone(response.data);
                    // process_inspection_sheet.clone(response.data);
                    process_inspection_sheet.role = role;
                    this.job.process_inspection_sheets.push(process_inspection_sheet);
                } else if (role === 'qc-final') {
                    let final_inspection_sheet: InspectionSheetModel;
                    final_inspection_sheet = new InspectionSheetModel();
                    final_inspection_sheet.document.clone(response.data);
                    // final_inspection_sheet.clone(response.data);
                    final_inspection_sheet.role = role;
                    this.job.final_inspection_sheets.push(final_inspection_sheet);
                } else {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.job.documents.push(new_document);
                }
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public onSuccess(data: any): void {
        this.current_tab = '#product';
        this.job.id = this.task.modelable_id;
        this.getJobDetail()
            .then(() => {
                this.ncr_pdf_path = environment.api_host + 'view/' + this.job.id + '/job/ncrs';
                this.init_product_processes();
                this.product_id = this.job.product_id;
                this.job_id = this.job.id;

                this.getNCRsByJob(this.job)
                    .then(ncrs => {
                        this.ncrs = ncrs;
                    });

                this.getProductDetail()
                    .then(() => {
                        this.getJobChildren(this.job_id)
                            .then((children_response: any): void => {
                                //
                            }, error => {
                                //
                            });

                        if (this.schedulers) {
                            this.schedulers.splice(0, this.schedulers.length);
                        } else {
                            this.schedulers = [];
                        }
                        for (let i = 0; i < this.product.product_processes.length; i++) {
                            const product_process = this.product.product_processes[i];
                            if (product_process && product_process.checked && product_process.revision === this.job.revision) {
                                for (let j = 0; j < product_process.schedulers.length; j++) {
                                    const scheduler = product_process.schedulers[j];
                                    if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                                        const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                                        const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                                        const event_scheduler: any = {
                                            title: ((product_process.is_fvd) ? '(FVD)' : '') + product_process.process.name,
                                            start: start,
                                            end: end,
                                        };
                                        this.schedulers.push(event_scheduler);
                                        this.options.events.push(event_scheduler);
                                    }
                                }
                            }
                            if (product_process && product_process.children && product_process.children.length) {
                                for (let j = 0; j < product_process.children.length; j++) {
                                    const child: any = product_process.children[j];
                                    if (child && child.checked && child.schedulers && child.revision === this.job.revision) {
                                        for (let n = 0; n < child.schedulers.length; n++) {
                                            const scheduler = child.schedulers[n];
                                            if (scheduler && scheduler.checked && scheduler.revision === this.job.revision) {
                                                const start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
                                                const end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
                                                const event_scheduler: any = {
                                                    title: child.process.name + ((child.is_fvd) ? '(FVD)' : ''),
                                                    start: start,
                                                    end: end,
                                                };
                                                this.schedulers.push(event_scheduler);
                                                this.options.events.push(event_scheduler);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.getJobSchedulerHistories()
                            .then(() => {
                                this.viewer.manufacture(this.job, false, this.job.revision)
                                    .then(path => {
                                        this.current_job_document_path = path;
                                        this.current_tab = '#manufacture';
                                        this.doReady();
                                        this.loader.hide();
                                    });
                            });
                    });
            });
    }

    public onError(e: any): void {
        //
    }

    private getProductDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.product = new ProductModel();
            if (this.product && this.product_id) {
                this.modelApi.sync(this.product, this.product_id, null, {
                    job_id: this.job_id
                }).subscribe((response: any): void => {
                    resolve(this.product);
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    private init_product_processes(): void {
        for (let i = 0; i < this.job.product_processes.length; i++) {
            const product_process_any: any = this.job.product_processes[i];
            if (product_process_any) {
                let product_process: ProductProcessModel;
                product_process = new ProductProcessModel();
                product_process.clone(product_process_any);
                if (product_process.modelable && product_process.modelable_type === 'App\\Packaging' && product_process.modelable_data) {
                    let packaging: PackagingModel;
                    packaging = new PackagingModel();
                    packaging.clone(product_process.modelable);
                    packaging.amount = product_process.modelable_data.amount;
                    packaging.description = product_process.description;
                    this.packagings.push(packaging);
                }
                this.product_process_update(product_process);
                this.product_processes.push(product_process);
            }
        }
    }

    public product_process_update(product_process: ProductProcessModel): void {
        if (product_process) {
            if (product_process.schedulers) {
                for (let i = 0; i < product_process.schedulers.length; i++) {
                    const scheduler: SchedulerModel = product_process.schedulers[i];
                    if (scheduler) {
                        scheduler.update();
                        scheduler.calMinutes();
                    } else {
                        //
                    }
                }
            } else {
                //
            }
            product_process.update();
        }
    }

    public onPdfProcess(e: any): void {
        this.is_error = false;
        this.is_loading = true;
    }

    public onPdfLoadCompleted(e: any): void {
        this.is_error = false;
        this.is_loading = false;
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    private getJobDetail(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.modelApi.sync(this.job)
                .subscribe((response: any): void => {
                    this.product.id = this.job.product_id;
                    resolve(this.job);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private getJobSchedulerHistories(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.task && this.job && this.job.id) {
                if (this.scheduler_histories) {
                    this.scheduler_histories.splice(0, this.scheduler_histories.length);
                } else {
                    this.scheduler_histories = [];
                }
                this.api.request('jobs/' + this.job.id + '/scheduler/histories', 'GET', {}, {
                    id: this.job.id
                }).subscribe((response: any): void => {
                    if (response && response.data) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat) {
                                let scheduler_history: SchedulerHistoryModel;
                                scheduler_history = new SchedulerHistoryModel();
                                scheduler_history.clone(dat);
                                this.scheduler_histories.push(scheduler_history);

                                this.total_gap_time_minutes += scheduler_history.gap_time_minutes;
                                this.total_minutes += scheduler_history.minutes;
                            }
                        }
                    }
                    resolve(this.scheduler_histories);
                }, error => {
                    reject(error);
                });
            }
        });
        return promise;
    }

    private is_overlap(start1: any, start2: any, end1?: any, end2?: any): boolean {
        if (start1 && start2 && moment.isMoment(start1) && moment.isMoment(start2)) {
            const _start1: number = +start1.format('YYYYMMDDHHmm');
            const _end1: number = (end1) ? +end1.format('YYYYMMDDHHmm') : +start1.add(1, 'hours').format('YYYYMMDDHHmm');
            const _start2: number = +start2.format('YYYYMMDDHHmm');
            const _end2: number = (end2) ? +end2.format('YYYYMMDDHHmm') : +start2.add(1, 'hours').format('YYYYMMDDHHmm');
            if (Math.max(_start1, _start2) < Math.min(_end1, _end2)) {
                return true;
            }
        }
        return false;
    }

    public addPlanningScheduler(scheduler: SchedulerModel): void {
        let className: string;
        className = 'fc-planning ';
        const _start: any = moment(scheduler.start, 'YYYY-MM-DD HH:mm:ss');
        const _end: any = moment(scheduler.end, 'YYYY-MM-DD HH:mm:ss');
        const is_overlap: boolean = this.is_overlap(this.today_start, _start, this.today_end, _end);
        if (is_overlap) {
            className += 'fc-do';
        } else {
            if (this.today_start >= _start) {
                className += 'fc-passed';
            } else {
                className += 'fc-feature';
            }
        }
        this.planning_events.push({
            scheduler: scheduler,
            index: 0,
            id: scheduler.id,
            model_id: scheduler.id,
            className: className,
            start: scheduler.start,
            end: scheduler.end,
            title: scheduler.process.name,
            editable: false,
            checked: true,
            resourceId: scheduler.process.slug
        });
    }

    public completeTask(): void {
        if (this.job.current_role === 'qc-process'
            && (!this.job.process_inspection_sheets || this.job.process_inspection_sheets.length === 0)) {
            this.swal.danger('กรุณาแนบเอกสาร QC');
            return;
        } else if (this.job.current_role === 'qc-final'
            && (!this.job.final_inspection_sheets || this.job.final_inspection_sheets.length === 0)) {
            this.swal.danger('กรุณาแนบเอกสาร QC');
            return;
        } else if (this.job.current_role === 'shipping') {
            if (!this.job.delivery_notes || !this.job.delivery_notes.length) {
                this.swal.danger('กรุณาออกใบส่งสินค้า');
                return;
            } else if (this.job.delivery_notes) {
                for (const delivery_note of this.job.delivery_notes) {
                    if (delivery_note && delivery_note.status === 1 && !delivery_note.document_id) {
                        this.swal.danger('กรุณาแนบหลักฐานการส่งสินค้า');
                        return;
                    }
                }
            }
        }
        if (this.job.current_role === 'packing') {
            this.swal.confirm('พิมพ์เอกสารสำหรับ Packing สำเร็จแล้วใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.confirmedModal();
                    }
                });
        } else if (false && this.job.current_role === 'shipping') {
            this.swal.confirm('พิมพ์เอกสารสำหรับ Shipping สำเร็จแล้วใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.confirmedModal();
                    }
                });
        } else if (this.job.current_product_process && this.job.current_product_process.process && this.job.current_product_process.process.id) {
            if (this.job.current_product_process.is_fvd === true
                && this.job.current_product_process.fvd
                && !this.job.current_product_process.fvd.prepared_status) {
                //
                this.swal.danger('สินค้ายังไม่ได้รับจากผู้รับจ้าง FVD, โปรดตรวจสอบจากแผนก Store');
                return;
            } else if (this.job.current_role === 'assembly') {
                if (this.children && this.children.length) {
                    for (let i = 0; i < this.children.length; i++) {
                        const child: any = this.children[i];
                        if (child && child.job_process_slug !== 'assembly' && child.job_id !== this.parent_job.id) {
                            this.swal.danger(child.product_name + ' ยังไม่อยู่ในกระบวนการ Assembly');
                            return;
                        }
                    }
                }
                this.confirmedModal();
            } else {
                this.confirmedModal();
            }
        } else {
            this.confirmedModal();
        }
    }

    private confirmedModal(): void {
        this.completeModal.open();
    }

    public onCompleteModalSubmit(e: any): void {
        this.swal.success('เสร็จสิ้นกระบวนการ');
        super.leave(true, true, e.remark);
    }

    public onRemarkModalSubmit(e: any): void {
        this.jobService.reportJob(this.job, e.remark_message)
            .then(() => {
                this.reportJobSetTaskStatus(this.task.status, 'creating_ncr', 'dummy', 'dummy', false)
                    .then(() => {
                        this.swal.success('แจ้งปัญหากับแผนกสำเร็จ')
                            .then(() => {
                                super.leave(true);
                            });
                    });
            });
    }

    private reportJobSetTaskStatus(
        task_status: number,
        action?: string,
        to_role?: string,
        to_process?: string,
        completed?: boolean,
        task_id?: string
    ): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.taskService.setStatus((task_id) ? task_id : this.task, task_status, action, to_role, to_process, completed)
                .then((task: TaskModel): void => {
                    if (this.task) {
                        this.task.status = task_status;
                    } else {
                        //
                    }
                    resolve();
                }, error => {
                    reject();
                });
        });
        return promise;
    }

    public requestNCR(): void {
        this.swal.confirm('ยืนยันการบันทึกรายงานข้อผิดพลาดของสินค้านี้ ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.taskService.setStatus(this.task, this.task.status, 'create_ncr', 'qa', 'ncr')
                        .then((response: any): void => {
                            this.api.request('jobs/ncr', 'POST', {}, {
                                id: this.job.id,
                                ncr_amount: this.job.product_amount
                            }).subscribe((ncr_response: any): void => {
                                if (ncr_response && ncr_response.success === true) {
                                    this.router.navigateByUrl('/task/qa/ncr/' + this.task.id);
                                } else {
                                    this.swal.danger(response);
                                }
                            }, error => {
                                this.swal.danger(error);
                            });
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public endAndLeave(): void {
        this.modal.show(EndModalComponent, {
            task                : this.task,
            product             : this.product,
            job                 : this.job,
            product_processes   : this.product_processes
        }).then((content: any): void => {
            if (content && content.submit === true) {
                this.jobService.push(this.job, content.product_push_amount, content.remainder, content.remark)
                    .then(() => {
                        super.leave(true, true, content.remark);
                    }, error => {
                        console.error(error);
                    });
            } else {
                //
            }
        });
    }

    public getJobChildren(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id + '/children')
                .subscribe((response: any): void => {
                    if (!this.children) {
                        this.children = [];
                    } else {
                        this.children.splice(0, this.children.length);
                    }
                    if (response && response.success === true) {
                        if (response.data.parent) {
                            const parent_job: any = response.data.parent;
                            this.children.push({
                                task: parent_job.task,
                                current_user: parent_job.current_user,
                                task_id: (parent_job.task) ? parent_job.task.id : null,
                                job_id: parent_job.id,
                                job_no: parent_job.job_no,
                                job_current_role: parent_job.current_role,
                                job_process_slug: parent_job.process_slug,
                                task_process_slug: parent_job.task.process_slug,
                                completed_at: parent_job.completed_at,
                                product_name: (parent_job.product) ? parent_job.product.name : null,
                                product_no: (parent_job.product) ? parent_job.product.product_no : null
                            });
                            this.parent_job = parent_job;
                        }
                        if (response.data.children) {
                            for (let i = 0; i < response.data.children.length; i++) {
                                const dat: any = response.data.children[i];
                                if (dat) {
                                    this.children.push({
                                        task: dat.task,
                                        current_user: dat.current_user,
                                        task_id: (dat.task) ? dat.task.id : null,
                                        job_id: dat.id,
                                        job_no: dat.job_no,
                                        job_current_role: dat.current_role,
                                        job_process_slug: dat.process_slug,
                                        task_process_slug: dat.task.process_slug,
                                        completed_at: dat.completed_at,
                                        product_name: (dat.product) ? dat.product.name : null,
                                        product_no: (dat.product) ? dat.product.product_no : null
                                    });
                                }
                            }
                        }
                        resolve(this.children);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    private valid_prepare_material(): boolean {
        for (let i = 0; i < this.product.materials.length; i++) {
            const material: any = this.product.materials[i];
            if (material && material.id && material.checked && material.cutting_status === 1 && (/*material.prepared_status === 1 || */material.prepared_status === 4 || material.prepared_status === 0 || !material.prepared_status)) {
                console.log(material);
                return false;
            }
            if (material.children && material.children.length) {
                for (let j = 0; j < material.children.length; j++) {
                    const child: any = material.children[j];
                    if (child && child.id && child.checked && child.cutting_status === 1 && (/*child.prepared_status === 1 || */child.prepared_status === 4 || child.prepared_status === 0 || child.prepared_status === 0 || !child.prepared_status)) {
                        console.log(child);
                        return false;
                    }
                }
            }
        }
        return true;
    }

    public hire(): void {
        if (this.job.current_product_process && this.job.current_product_process.process && this.job.current_product_process.process.slug === 'shipping') {
            this.swal.danger('แผนก Shipping ไม่สามารถเข้าทำงานได้จากห้องนี้');
            return;
        }
        if (this.job.current_product_process && this.job.current_product_process.process && this.job.current_product_process.process.id) {
            if (this.job.current_product_process.process.slug === 'cutting' && !this.valid_prepare_material()) {
                this.swal.danger('วัสดุยังไม่ถูกเตรียมพร้อมจึงไม่สามารถเข้าทำงานได้');
                return;
            }
        }
        this.modal.show(StartModalComponent, {
            task                : this.task,
            product             : this.product,
            job                 : this.job,
            product_processes   : this.product_processes
        }).then((content: any): void => {
            if (content && content.submit === true) {
                this.taskService.hire(this.task, null, null, null, null, null, content.remark)
                    .then((response: any): void => {
                        this.jobService.pull(this.job, content.product_pull_amount, content.remainder, content.remark)
                            .then(() => {
                                setTimeout(() => {
                                    this.getJobSchedulerHistories()
                                        .then(() => {
                                            if (this.task && this.task.current_role === 'dummy') {
                                                //
                                            } else {
                                                this.swal.success('เข้าทำงาน "' + this.job.job_no + '" สำเร็จ');
                                            }
                                        });
                                }, 300);
                            }, error => {
                                console.error(error);
                            });
                    }, error => {
                        //
                    });
            } else {
                //
            }
        });
    }

}
