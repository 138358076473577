import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {PackingSlipModel} from '../../models/packingSlip.model';
import {PackingListDetailComponent} from '../../pages/task/view/packingListDetail/packingListDetail.component';
import {DocumentModel} from '../../models/document.model';
import {UploaderComponent} from '../uploader/uploader.component';
import {Api} from '../../now/api/api';
import {SwalService} from '../../services/swal.service';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'packing-list-table-component',
    templateUrl: 'packingListTable.component.html',
    styleUrls: ['packingListTable.component.scss']
})
export class PackingListTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() packingLists: PackingSlipModel[];
    @Input() model: any;
    @Input() task: TaskModel;

    public current_packing_list: PackingSlipModel;

    constructor(
        public viewer: Viewer,
        private modal: ModalService,
        private api: Api,
        private swal: SwalService
    ) {
        this.packingLists = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewPackingListDetail(packing_list: PackingSlipModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PackingListDetailComponent, {
            packing_list    : packing_list,
            model           : this.model,
            task            : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.packing_list) {
                    packing_list.status = content.packing_list.status;
                    // packing_list.remark = content.packing_list.remark;
                    // packing_list.started_at = content.packing_list.started_at;
                }
            });
    }

    public viewPackingListPreview(packing_list: PackingSlipModel, e?: any): void {
        this.viewer.packingSlip(packing_list);
    }

    public createPackingList(): void {
        const packing_list: PackingSlipModel = new PackingSlipModel();
        this.modal.show(PackingListDetailComponent, {
            packing_list    : packing_list,
            model           : this.model,
            products        : this.model.products,
            task            : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content.is_create === true) {
                        packing_list.clone(content.packing_list);
                        // this.packingLists.push(packing_list);
                        this.model.packing_lists.push(packing_list);
                        this.viewPackingListPreview(packing_list);
                    }
                } else {
                    //
                }
            });
    }

    public attachFile(packing_list: PackingSlipModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_packing_list = packing_list;
        this.uploader.trigger();
    }

    public viewFile(packing_list: PackingSlipModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(packing_list.document_id);
    }

    public removeFile(packing_list: PackingSlipModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('packing/slips/document/delete', 'POST', {}, {
                        id: packing_list.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        packing_list.document_id = null;
                        packing_list.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_packing_list && this.current_packing_list.id) {
            this.api.request('packing/slips/document', 'PUT', {}, {
                document: data,
                id: this.current_packing_list.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_packing_list.document_id = new_document.id;
                    this.current_packing_list.document = new_document;
                    this.viewFile(this.current_packing_list);
                }
                this.current_packing_list = null;
            }, error => {
                this.current_packing_list = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
