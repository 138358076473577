import {MaterialModel} from './material.model';
import {Model} from '../now/model';

export class ProductMaterial extends MaterialModel {

    public product_id: string;
    public children: ProductMaterial[];
    public required_at: any;
    public cutting_id: any;
    public cutting: any;
    public cutting_amount: number;
    public cutting_status: number;
    public mat_name: string;

    constructor() {
        super('product/materials', 'App\\ProductMaterial');
        this.status = 1;
        this.prepared_status = 0;
        this.cutting = {
            id: '',
            search_value: '',
            item: {
                id: null,
                search_value: null
            },
            current_role: '',
            prepared_status: '',
            dateTime: '',
            amount: ''
        };
        // this.required_at = null;
    }

    public clone(data: any): Model {
        if (data) {
            this.children = [];
            if (data.children) {
                for (let i = 0; i < data.children.length; i++) {
                    const child: any = data.children[i];
                    if (child) {
                        let child_product_material: ProductMaterial;
                        child_product_material = new ProductMaterial();
                        child_product_material.clone(child);
                        this.children.push(child_product_material);
                    }
                }
                delete data.children;
            }
        }
        super.clone(data);

        if (!this.prepared_status) {
            this.prepared_status = 0;
        }

        return this;
    }

}
