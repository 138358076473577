import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {AuthService} from '../../../now/user/auth.service';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {CarrierService} from '../../../services/carrier.service';
import {PusherService} from '../../../services/pusher.service';
import {CriticalPointService} from '../../../services/criticalPoint.service';
import {ProcessService} from '../../../services/process.service';
import {MachineService} from '../../../services/machine.service';
import {UserService} from '../../../services/user.service';
import {HardeningService} from '../../../services/hardening.service';
import {CosmeticService} from '../../../services/cosmetic.service';
import {CuttingToolService} from '../../../services/cuttingTool.service';
import {MaterialService} from '../../../services/material.service';
import {PackagingService} from '../../../services/packaging.service';
import {IncotermService} from '../../../services/incoterm.service';
import {SwalService} from '../../../services/swal.service';
import {DOCUMENT, Location} from '@angular/common';
import {TaskModel} from '../../../models/task.model';
import {environment} from '../../../environments/environment';
import {ViewTask} from '../view/viewTask';

import {StoreService} from '../../../services/store.service';
import {JobService} from '../../../services/job.service';
import {ProductProcessModel} from '../../../models/productProcess.model';
import {FVDService} from '../../../services/fvd.service';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'notice-shipping-task-component',
    templateUrl: 'noticeShippingTask.component.html',
    styleUrls: ['noticeShippingTask.component.scss']
})
export class NoticeShippingTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;

    public ready: boolean;
    public response_shipping_status: number;

    public current_job_document_path: string;
    public pdfInfo: any;
    public numPages: number[];
    public job_id: string;

    public current_product_process: ProductProcessModel;
    public product: ProductModel;
    public product_id: string;
    public task_id: string;
    public current_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public job: JobModel;
    public job_no: string;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        public router: Router,
        private storeService: StoreService,
        private modelApi: ModelApi,
        private authService: AuthService,
        public taskService: TaskService,
        private jobService: JobService,
        private ngZone: NgZone,
        public api: Api,
        private fvdService: FVDService,
        public modal: ModalService,
        private carrierService: CarrierService,
        private pusherService: PusherService,
        private criticalPointService: CriticalPointService,
        private processService: ProcessService,
        private machineService: MachineService,
        public userService: UserService,
        private hardeningService: HardeningService,
        private cosmeticService: CosmeticService,
        private cuttingToolService: CuttingToolService,
        private materialService: MaterialService,
        private packagingService: PackagingService,
        private incotermService: IncotermService,
        private swal: SwalService,
        public location: Location,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.response_shipping_status = 0;
        // this.fvd = {};
        this.task = new TaskModel();
        this.job = new JobModel();
        this.product = new ProductModel();
        this.ready = false;
    }

    private init(): void {
        this.task = new TaskModel();
        this.job = new JobModel();
    }

    public save(skip?: boolean): void {
        //
    }

    public submit(): void {
        this.swal.confirm('ยืนยันการรับทราบการแจ้งเลื่อนใช่หรือไม่?')
            .then(() => {
                this.api.request('tasks/archive', 'POST', null, {
                    id: this.task.id
                }).subscribe((response: any): void => {
                    this.swal.success('ยืนยันรายการสำเร็จ');
                    this.leave(true);
                }, error => {
                    //
                });
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.getTask()
                            .then(() => {
                                this.onSuccess(this.task);
                            });
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public onSuccess(data: any): void {
        this.current_tab = '#information';
        this.job_id = data.job_id;
        this.getJob(this.job_id)
            .then(() => {
                this.viewer.manufacture(this.job, false, this.job.revision)
                    .then(path => {
                        this.current_job_document_path = path;
                        this.doReady();
                    });
            });
    }

    public onError(e: any): void {
        //
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onPdfLoadCompleted(e: any): void {
        this.pdfInfo = e.pdfInfo;
        if (this.pdfInfo && this.pdfInfo.numPages > 0) {
            this.numPages = Array(this.pdfInfo.numPages).fill(1);
        }
    }

    public onSaveApiCallingBack(e: any): void {
        this.save(true);
    }

}
