import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'repair-modal-component',
    templateUrl: 'repairModal.component.html',
    styleUrls: ['repairModal.component.scss']
})
export class RepairModalComponent {

    public equipment: any[];

    public tmp_user_informer: any;
    public informed_date: any;
    public user_informer: any;
    public tmp_user_informant: any;
    public tmp_machine: any;
    public response_date: any;
    public estimate_description: any;
    public type: string;
    public before_description: string;
    public before_reason: string;
    public tmp_user_request: any;
    public can_repaired: any;
    public request_date: any;
    public tmp_user_approved: any;
    public approved_date: any;
    public after_description: string;
    public finished_date: any;
    public finished_time: any;
    public gap_time: number;
    public summary_results: any;
    public tmp_user: any;
    public sumamry_results: any;
    public machine: any;
    public user_informant: any;
    public user: any;

    constructor(public bsModalRef: BsModalRef) {
        this.equipment = [];
        for (let i = 0; i < 5; i++) {
            this.equipment.push({});
        }
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            //
        } else {
            //
        }
    }

    public onSelectTypeahead(model: any, data: any, model_parent?: any, field_name?: string): void {
        if (model && data) {
            model.clone(data);
        }
        if (model_parent && field_name) {
            model_parent[field_name] = data.id;
        }
    }

}
