import {EventEmitter, Injectable, Output} from '@angular/core';
import {DivApiConfig} from './divApi.config';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DivApiTemplateService {

    @Output('onLoaded') onLoaded: EventEmitter<any>;

    private error_template_url: string;
    private getting_template_url: string;
    private got_template_url: string;
    private zero_template_url: string;

    private error_template: string;
    private getting_template: string;
    private got_template: string;
    private zero_template: string;

    private dom_error: any;
    private dom_getting: any;
    private dom_got: any;
    private dom_zero: any;

    private is_ready: boolean;

    constructor(private http: HttpClient) {
        this.onLoaded = new EventEmitter<any>();

        this.error_template_url     = './assets/templates/divApi.error.html';
        this.getting_template_url   = './assets/templates/divApi.getting.html';
        this.got_template_url       = './assets/templates/divApi.got.html';
        this.zero_template_url      = './assets/templates/divApi.zero.html';
    }

    public setupTemplate(config: DivApiConfig): void {
        if (config) {
            if (config.error_template_url) {
                this.error_template_url = config.error_template_url;
                this.load_error_template();
            }
            if (config.getting_template_url) {
                this.getting_template_url = config.getting_template_url;
                this.load_getting_template();
            }
            if (config.got_template_url) {
                this.got_template_url = config.got_template_url;
                this.load_got_template();
            }
            if (config.zero_template_url) {
                this.zero_template_url = config.zero_template_url;
                this.load_zero_template();
            }
        } else {
            this.load_error_template();
            this.load_getting_template();
            this.load_got_template();
            this.load_zero_template();
        }
    }

    private load_getting_template(): void {
        if (this.getting_template && this.dom_getting) {
            return;
        }
        if (this.getting_template) {
            this.dom_getting = document.createElement('div-api-getting');
            this.dom_getting.innerHTML = this.getting_template;
            this.loaded();
        } else {
            this.http.get(this.getting_template_url, {
                responseType: 'text'
            }).subscribe(response => {
                this.getting_template = response;
                this.dom_getting = document.createElement('div-api-getting');
                this.dom_getting.innerHTML = this.getting_template;
                this.loaded();
            });
        }
    }

    private load_got_template(): void {
        if (this.got_template && this.dom_got) {
            return;
        }
        if (this.got_template) {
            this.dom_got = document.createElement('div-api-got');
            this.dom_got.innerHTML = this.got_template;
            this.loaded();
        } else {
            this.http.get(this.got_template_url, {
                responseType: 'text'
            }).subscribe(response => {
                this.got_template = response;
                this.dom_got = document.createElement('div-api-got');
                this.dom_got.innerHTML = this.got_template;
                this.loaded();
            });
        }
    }

    private load_error_template(): void {
        if (this.error_template && this.dom_error) {
            return;
        }
        if (this.error_template) {
            this.dom_error = document.createElement('div-api-error');
            this.dom_error.innerHTML = this.error_template;
            this.loaded();
        } else {
            this.http.get(this.error_template_url, {
                responseType    : 'text'
            }).subscribe(response => {
                this.error_template = response;
                this.dom_error = document.createElement('div-api-error');
                this.dom_error.innerHTML = this.error_template;
                this.loaded();
            });
        }
    }

    private load_zero_template(): void {
        if (this.zero_template && this.dom_zero) {
            return;
        }
        if (this.zero_template) {
            this.dom_error = document.createElement('div-api-zero');
            this.dom_error.innerHTML = this.zero_template;
            this.loaded();
        } else {
            this.http.get(this.zero_template_url, {
                responseType: 'text'
            }).subscribe(response => {
                this.zero_template = response;
                this.dom_zero = document.createElement('div-api-zero');
                this.dom_zero.innerHTML = this.zero_template;
                this.loaded();
            });
        }
    }

    private loaded(): void {
        if (this.dom_zero && this.dom_got && this.dom_getting && this.dom_error) {
            this.is_ready = true;
            this.onLoaded.emit({
                dom_zero       : this.dom_zero,
                dom_got        : this.dom_got,
                dom_getting    : this.dom_getting,
                dom_error      : this.dom_error
            });
            return;
        }

        this.is_ready = false;
    }

}
