import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DocumentModel} from '../../models/document.model';
import {environment} from '../../environments/environment';
import {NgForm} from '@angular/forms';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {ModelApi} from '../../now/modelApi/modelApi';
import {SwalService} from '../../services/swal.service';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'document-table-component',
    templateUrl: 'documentTable.component.html',
    styleUrls: ['documentTable.component.scss']
})
export class DocumentTableComponent implements AfterViewInit {

    @ViewChild('documentDetailModal', { static: true }) smartModal: NgxSmartModalComponent;

    @Output('success') onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output('error') onError: EventEmitter<any> = new EventEmitter<any>();

    @Input('documents') documents: any[];
    @Input('uploadable') uploadable: boolean;
    @Input('modelableType') modelable_type: string;
    @Input('modelableId') modelable_id: string;

    public tmp_document: DocumentModel;
    public current_document: DocumentModel;
    public current_document_index: number;

    constructor(
        private modelApi: ModelApi,
        private swal: SwalService,
        private viewer: Viewer
    ) {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
        }, 0);
    }

    public submit(form: NgForm): void {
        this.onSubmit(form);
    }

    public onSubmit(form: NgForm): void {
        this.swal.confirm('ยืนยันการแก้ไขเอกสาร')
            .then((result: boolean): void => {
                if (result === true) {
                    this.modelApi.update(this.tmp_document, ['name'])
                        .subscribe((response: any): void => {
                            console.log('=== Updated Document ===');
                            if (this.current_document) {
                                this.current_document.name = this.tmp_document.name;
                            }
                            this.swal.success('แก้ไขเอกสารสำเร็จ');
                            this.smartModal.close();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public delete(): void {
        this.swal.confirm('ยืนยันการลบเอกสาร')
            .then((result: boolean): void => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_document)
                        .subscribe((response: any): void => {
                            this.swal.success('ลบเอกสารสำเร็จ');
                            this.documents.splice(this.current_document_index, 1);
                            this.smartModal.close();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public viewDocument(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(doc);
    }

    public viewDocumentDetail(doc: DocumentModel, index: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.tmp_document = new DocumentModel();
        this.tmp_document.clone(doc);
        this.current_document = doc;
        this.current_document_index = index;
        setTimeout(() => {
            this.smartModal.open();
        }, 300);
    }

    public onDocumentUploadedSuccess(e: any): void {
        this.onSuccess.emit(e);
    }

    public onDocumentUploadError(e?: any): void {
        this.onError.emit(e);
    }

}
