import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {UserModel} from '../../../../now/user/user.model';
import {SwalService} from '../../../../services/swal.service';

@Component({
    selector: 'app-create-user-component',
    templateUrl: 'createUser.component.html',
    styleUrls: ['createUser.component.scss']
})
export class CreateUserComponent implements OnInit {

    public user: UserModel;

    constructor(private modelApi: ModelApi,
                private swal: SwalService,
                public bsModalRef: BsModalRef) {
        //
        this.user = new UserModel();
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.user) {
                this.user = this.bsModalRef.content.user;
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (form.valid) {
            this.modelApi.create(this.user, [
                'full_name', 'prefix'
            ]).subscribe((response: any): void => {
                if (response.data) {
                    this.user.id = response.data.id;
                    this.bsModalRef.content.user = this.user;
                    this.bsModalRef.hide();
                }
                this.swal.success('เพิ่มพนักงาน "' + this.user.full_name + '" สำเร็จ');
            }, error => {
                if (error && error.message) {
                    this.swal.danger(error.message);
                } else {
                    this.swal.danger(JSON.stringify(error));
                }
            });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
    }

}
