import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {PackagingModel} from '../../../../models/packaging.model';
import {Location} from '@angular/common';

@Component({
    selector: 'packaging-view-component',
    templateUrl: 'packagingView.component.html',
    styleUrls: ['packagingView.component.scss']
})
export class PackagingViewComponent implements OnInit {

    public packaging: PackagingModel;
    public packaging_id: string;
    public current_tab: string;

    constructor(private route: ActivatedRoute,
                private api: Api,
                private location: Location,
                private appService: AppService,
                private modelApi: ModelApi,
                private modal: ModalService,
                private swal: SwalService) {
        //
        this.packaging = new PackagingModel();
        this.route.params
            .subscribe(params => {
                this.packaging_id = params['id'];
            });

        this.current_tab = 'information';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getPackaging()
                .then(() => {
                    //
                });
        }, 0);
    }

    public getPackaging(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.packaging_id) {
                this.api.request('packagings/' + this.packaging_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.packaging.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.packaging);
            }
        });
        return promise;
    }

    public save(): void {
        if (!this.packaging.name) {
            this.swal.danger('กรุณากรอกชื่อแพ็คเกจจิ้ง');
        } else if (!this.packaging.price) {
            this.swal.danger('กรุณากรอกราคาแพ็คเกจจิ้งสำหรับการประเมินการผลิต');
        } else {
            this.modelApi.createOrUpdate(this.packaging, [
                'name', 'price', 'width', 'height', 'length', 'weight', 'amount'
            ]).subscribe((response: any): void => {
                this.location.replaceState('/database/packaging/' + this.packaging.id + '/detail');
                this.swal.success('บันทึกข้อมูลแพ็คเกจจิ้งสำเร็จ');
            }, error => {
                this.swal.danger(error);
            });
        }
    }

    public createPackaging(): void {
        this.save();
    }

}
