import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CosmeticModel} from '../../../models/cosmetic.model';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';

@Component({
    selector: 'cosmetic-list-component',
    templateUrl: 'cosmeticList.component.html',
    styleUrls: ['cosmeticList.component.scss']
})
export class CosmeticListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public timeout: any;
    public cosmetics: CosmeticModel[];
    public keyword: string;

    public modalSubscription: any;

    constructor(private router: Router) {
        super();
        this.cosmetics = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: e
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }, 1000);
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_cosmetics();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let cosmetic: CosmeticModel;
                    cosmetic = new CosmeticModel();
                    cosmetic.clone(dat);
                    this.cosmetics.push(cosmetic);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoCosmeticView(cosmetic: CosmeticModel, index?: number): void {
        this.router.navigateByUrl('/database/cosmetic/' + cosmetic.id + '/detail');
    }

    private clear_cosmetics(): void {
        if (this.cosmetics) {
            this.cosmetics.splice(0, this.cosmetics.length);
        } else {
            this.cosmetics = [];
        }
    }

    public createCosmetic(): void {
        this.router.navigateByUrl('/database/cosmetic/create');
    }

}
