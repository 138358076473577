import { AfterViewInit, Component} from '@angular/core';

@Component({
    selector: 'payment-form-page',
    templateUrl: 'paymentForm.page.html',
})
export class PaymentFormPage implements AfterViewInit {

    constructor() {
        //
    }

    ngAfterViewInit(): void {
        //
    }

}
