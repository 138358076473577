import {Component} from '@angular/core';

@Component({
    selector: 'request-task-component',
    templateUrl: 'requestTask.component.html',
    styleUrls: ['requestTask.component.scss']
})
export class RequestTaskComponent {

    constructor() {
        //
    }

}
