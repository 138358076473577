import {NgModule} from '@angular/core';
import {SaleOrderTaskComponent} from './saleOrderTask/saleOrderTask.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ERPComponentModule} from '../components/erpComponent.module';
import {ERPModalModule} from '../modals/erpModal.module';
import {ERPServiceModule} from '../services/erpService.module';
import {ERPPipeModule} from '../pipes/erpPipe.module';
import {ERPDirectiveModule} from '../directives/erpDirective.module';
import {DragulaModule} from 'ng2-dragula';
import {easingLogic} from '../app/app.module';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        DragulaModule,
        ERPDirectiveModule,
        ERPModalModule,
        ERPServiceModule,
        ERPComponentModule,
        ERPPipeModule,
        NgxPageScrollCoreModule.forRoot({
            duration: 250,
            scrollOffset: 200,
            easingLogic: easingLogic
        }),
        NgxPageScrollModule,
    ],
    declarations: [
        SaleOrderTaskComponent,
    ],
    providers: [
        //
    ]
})
export class SaleAdminModule {}
