import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import {Api} from '../../../now/api/api';
import * as moment from 'moment';
import {LoaderService} from '../../../components/loader/loader.service';
import {Viewer} from '../../../services/viewer';
import {RequirementModel} from '../../../models/requirement.model';

@Component({
    selector: 'product-list-component',
    templateUrl: 'productList.component.html',
    styleUrls: ['productList.component.scss']
})
export class ProductListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sum_grand_total: number;
    public total_items: number;
    public products: RequirementModel[];
    public keyword: string;

    public timeout: any;
    public sub: any;

    public date: any;
    public tmp_date: string;
    public current_view: string;
    public tmp_customer: any;

    public customer_id: any;
    public customer_name: string;
    public created_at: string;

    constructor(
        public viewer: Viewer,
        private api: Api,
        private loader: LoaderService
    ) {
        //
        super();
        this.products = [];
        this.total_items = 0;
        this.current_view = 'MONTHLY';
        this.date = moment(new Date());
        this.tmp_date = this.date.format('MM/YYYY');
        this.created_at = this.tmp_date;
        this.sum_grand_total = 0;
        this.customer_name = '';
        this.customer_id = '';
        this.tmp_customer = {
            name: ''
        };
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public viewContactLog(contactLog: any): void {
        this.viewer.contactLog(contactLog);
    }

    public viewProduct(product: any): void {
        this.viewer.product(product);
    }

    public onCustomerChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e) {
            this.loader.show();
            // this.customer_name = (e && e.id && e.name) ? e.name : '';
            this.customer_id = (e && e.id) ? e.id : '';
            this.divApi.refresh({
                // find: this.customer_name,
                customer_id: this.customer_id,
                created_at: this.created_at,
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }
    }

    public onDateChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e) {
            this.loader.show();
            this.keyword = '';
            let created_at: string;
            if (this.current_view === 'DAILY') {
                created_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
            } else if (this.current_view === 'MONTHLY') {
                created_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
            } else if (this.current_view === 'YEARLY') {
                created_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
            }
            this.created_at = created_at;
            this.divApi.refresh({
                // find: this.customer_name,
                customer_id: this.customer_id,
                created_at: created_at,
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        } else {
            //
        }
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.sub = this.divApi.refresh({
                limit   : this.item_per_page,
                page    : this.current_page,
                find    : this.keyword
            }).pipe(
                // debounceTime(1000)
            ).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_products();
            this.sum_grand_total = response.sum_grand_total;
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    const contactLog = new RequirementModel();
                    contactLog.clone(dat);
                    this.products.push(contactLog);
                }
            }
            this.loader.hide();
        }
    }

    public onError(e: any): void {
        this.loader.hide();
    }

    private clear_products(): void {
        if (this.products) {
            this.products.splice(0, this.products.length);
        } else {
            this.products = [];
        }
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

}
