import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {Remark} from '../../app/api/remark';
import {NgxSmartModalComponent} from 'ngx-smart-modal';

@Component({
    selector: 'remark-viewer-component',
    templateUrl: 'remarkViewer.component.html',
    styleUrls: ['remarkViewer.component.scss']
})
export class RemarkViewerComponent implements AfterViewInit {

    @ViewChild('remarksModal', { static: true }) smartModal: NgxSmartModalComponent;

    @Input('remarks')
    public remarks: Remark[];

    constructor() {
        this.remarks = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewRemarksModal(): void {
        this.smartModal.open();
    }

}
