import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {ModalService} from '../../services/modal.service';
import {TaskModel} from '../../models/task.model';
import {InvoiceModel} from '../../models/invoice.model';
import {InvoiceDetailComponent} from '../../pages/task/view/invoiceDetail/invoiceDetail.component';
import {DocumentModel} from '../../models/document.model';
import {UploaderComponent} from '../uploader/uploader.component';
import {SwalService} from '../../services/swal.service';
import {Api} from '../../now/api/api';
import {Viewer} from '../../services/viewer';

@Component({
    selector: 'invoice-table-component',
    templateUrl: 'invoiceTable.component.html',
    styleUrls: ['invoiceTable.component.scss']
})
export class InvoiceTableComponent implements AfterViewInit {

    @ViewChild(UploaderComponent, { static: true }) uploader: UploaderComponent;
    @Input() invoices: InvoiceModel[];
    @Input() model: any;
    @Input() task: TaskModel;

    public current_invoice: InvoiceModel;

    constructor(
        private modal: ModalService,
        private swal: SwalService,
        private api: Api,
        public viewer: Viewer,
    ) {
        this.invoices = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public viewInvoiceDetail(invoice: InvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(InvoiceDetailComponent, {
            invoice     : invoice,
            model       : this.model,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.invoice) {
                    // invoice.remark = content.invoice.remark;
                    // invoice.started_at = content.invoice.started_at;
                    // invoice.grand_total = content.invoice.grand_total;
                    invoice.status = content.invoice.status;
                }
            });
    }

    public viewInvoicePreview(invoice: any): void {
        this.viewer.invoice(invoice);
    }

    public createInvoice(): void {
        const invoice: InvoiceModel = new InvoiceModel();
        this.modal.show(InvoiceDetailComponent, {
            invoice     : invoice,
            model       : this.model,
            products    : this.model.products,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.submit === true) {
                    if (content && content.is_create === true) {
                        invoice.clone(content.invoice);
                        // this.invoices.push(invoice);
                        this.model.invoices.push(invoice);
                        this.viewInvoicePreview(invoice);
                    }
                } else {
                    //
                }
            });
    }

    public attachFile(invoice: InvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.current_invoice = invoice;
        this.uploader.trigger();
    }

    public viewFile(invoice: InvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.viewer.document(invoice.document_id);
    }

    public removeFile(invoice: InvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.swal.confirm('คุณต้องการลบเอกสารแนบใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.api.request('invoices/document/delete', 'POST', {}, {
                        id: invoice.id
                    }).subscribe((response: any): void => {
                        this.swal.success('ลบเอกสารแนบสำเร็จ');
                        invoice.document_id = null;
                        invoice.document = null;
                    });
                }
            });
    }

    public onDocumentUploadedSuccess(data: any): void {
        if (this.current_invoice && this.current_invoice.id) {
            this.api.request('invoices/document', 'PUT', {}, {
                document: data,
                id: this.current_invoice.id
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    let new_document: DocumentModel;
                    new_document = new DocumentModel();
                    new_document.clone(response.data);
                    this.current_invoice.document_id = new_document.id;
                    this.current_invoice.document = new_document;
                    this.viewFile(this.current_invoice);
                }
                this.current_invoice = null;
            }, error => {
                this.current_invoice = null;
            });
        }
    }

    public onDocumentUploadError(data: any): void {
        //
    }

}
