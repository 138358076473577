import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, NgZone, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {DOCUMENT} from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as uuid4 from 'uuid/v4';
import {of} from 'rxjs/internal/observable/of';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {SwalService} from '../../../services/swal.service';
import {ModelApi} from '../../../now/modelApi/modelApi';

export const STATE_IDLE = 1;
export const STATE_LOADING = 2;
export const STATE_SELECTED = 3;
export const STATE_SAVE = 4;

@Component({
    selector: 'typeahead-basic-component',
    templateUrl: 'typeaheadBasic.component.html',
    styleUrls: ['typeaheadBasic.component.scss'],
    providers: [
        {
            provide     : NG_VALUE_ACCESSOR,
            multi       : true,
            useExisting : forwardRef(() => TypeaheadBasicComponent),
        }
    ]
})
export class TypeaheadBasicComponent implements ControlValueAccessor, AfterViewInit {

    @ViewChild(TypeaheadDirective, { static: true }) input: TypeaheadDirective;

    @Input() placeholder: string;
    @Input() itemTemplate: any;
    @Input() modalFields: any;
    @Input() none: boolean;
    @Input() display_not_found: string;
    @Input() field: string;
    public typeaheadOptionfield: string;
    @Input() servicePath: string;
    @Input() createPath: string;
    @Input() params: any;
    @Input() modalOpts: any;
    @Input() sources: any[];

    @Output('onInput') onInput: EventEmitter<any> = new EventEmitter<any>();
    @Output('onDelete') onDelete: EventEmitter<any> = new EventEmitter<any>();

    public tmp: string;
    public state: number;
    public the_model: ModelInterface;
    public guid: string;
    public typeaheadAsync: boolean;
    public dataSource: any;
    public is_selected: boolean;
    public typeaheadLoading: boolean;
    public noResult: boolean;
    private data: any[];
    private timeout: any;
    private subscription: any;
    private keyup_timeout: any;

    private propagateChange: any = (_: any): void => {};

    constructor(
        private api: Api,
        private elementRef: ElementRef,
        private modal: ModalService,
        private swal: SwalService,
        private modelApi: ModelApi,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        this.state = STATE_IDLE;
        this.modalOpts = {};
        this.is_selected = false;
    }

    writeValue(obj: ModelInterface): void {
        this.guid = uuid4();
        this.the_model = obj;
        this.tmp = this.defaultInput;
        this.is_selected = (this.field && this.the_model && this.the_model[this.field] && this.the_model.id) ? true : false;
        this.update();
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        //
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.params = (this.params) ? this.params : {};
            this.placeholder = (this.placeholder) ? this.placeholder : '';
            this.typeaheadOptionfield = (this.field) ? this.field : 'search_value';
            this.modalOpts = (this.modalOpts) ? this.modalOpts : {};
            this.init();
        }, 0);
    }

    public remove(force?: boolean): void {
        if (!force) {
            this.swal.confirm('ยกเลิกรายการ "' + this.tmp + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.remove(true);
                    }
                });
        } else {
            this.tmp = '';
            if (this.the_model) {
                // this.the_model.reset();
                this.the_model = Object.assign({});
            }
            this.noResult = false;
            this.is_selected = false;
            this.typeaheadLoading = false;
            this.onDelete.emit({});
            this.update();
            // this.propagateChange(this.modelObject);
        }
    }

    public select(item: any): void {
        if (this.field && item[this.field]) {
            this.tmp = item[this.field];
        } else {
            this.tmp = JSON.stringify(item);
        }
        this.is_selected = true;
        // this.propagateChange(this.the_model.clone(item, null, null));
        this.propagateChange(Object.assign({}, item));
    }

    private init(): void {
        this.typeaheadAsync = (this.servicePath) ? true : false;
        if (this.servicePath) {
            this.dataSource = new Observable((observer: any) => {
                // Runs on every search
                this._clearTimeout();
                this.getData()
                    .then((response: any): void => {
                        observer.next(this.tmp);
                    }, error => {
                        //
                    });
            }).pipe(mergeMap((token: string) => this.getDataAsObservable(token)));
        } else {
            if (this.none === true) {
                this.initData();
            }
            this.dataSource = this.data.concat(this.sources);
        }
    }

    private getData(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.timeout = setTimeout(() => {
                this.params['find'] = this.tmp;
                this.subscription = this.api.request(this.servicePath, 'GET', this.params)
                    .subscribe((response: any): void => {
                        this.clearData();
                        if (response && response.data) {
                            this.initData();
                            this.data = this.data.concat(response.data);
                        }
                        this._clearTimeout();
                        if (!this.data || !this.data.length) {
                            if (this.tmp) {
                                this.noResult = true;
                            }
                        } else {
                            setTimeout(() => {
                                if (this.input && this.data && this.data.length && this.tmp) {
                                    this.input.show();
                                }
                            }, 500);
                        }
                        resolve(this.data);
                    }, error => {
                        this._clearTimeout();
                        reject(error);
                    });
            }, 800);
        });
        return promise;
    }

    private clearData(): void {
        if (this.data) {
            this.data.splice(0, this.data.length);
        } else {
            this.data = [];
        }
    }

    public initData(): void {
        if (this.none === true) {
            let dat: any;
            dat = { id: -1 };
            if (this.field) {
                dat[this.field] = 'N/A';
            }
            this.data.push(dat);
            return dat;
        } else {
            this.data = [];
        }
    }

    public typeaheadNoResults(event: any): void {
        this.noResult = event;
        this.update();
    }

    public onChange(e: any): void {
        this.onInput.emit(this.tmp);
        this.update();
    }

    public onBlur(e?: any): void {
        this.update();
    }

    public onKeyUp(e?: any): void {
        this.onInput.emit(this.tmp);
        this.update();
    }

    private getDataAsObservable(token: string): Observable<any> {
        return of(
            this.data.filter((dat: any) => {
                if (this.field && dat[this.field]) {
                    this.typeaheadOptionfield = this.field;
                    const _token: string = (token + '').toLowerCase();
                    const _finding: string = ((dat[this.field]).toString()).toLowerCase();
                    if (_token && _finding) {
                        if (_finding.indexOf(_token) > -1) {
                            return true;
                        }
                    } else {
                        //
                    }
                }
                for (const [key, value] of Object.entries(dat)) {
                    if (key && value) {
                        const _token: string = (token + '').toLowerCase();
                        const _finding: string = value.toString().toLowerCase();
                        if (_finding.indexOf(_token) > -1) {
                            this.typeaheadOptionfield = key;
                            return true;
                        }
                    }
                }
                this.typeaheadOptionfield = this.field;
                return false;
            })
        );
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
        this.update();
    }

    public update(): void {
        if (!this.noResult && !this.is_selected && !this.typeaheadLoading) {
            this.state = STATE_IDLE;
        } else if (this.typeaheadLoading === true) {
            this.state = STATE_LOADING;
        } else if (this.is_selected && !this.typeaheadLoading) {
            this.state = STATE_SELECTED;
        } else if (!this.is_selected && !this.typeaheadLoading && this.noResult) {
            this.state = STATE_SAVE;
        } else {
            this.state = STATE_IDLE;
        }
        if (!this.tmp) {
            this.noResult = false;
        }
    }

    public typeaheadOnSelect(e: TypeaheadMatch | any): void {
        this.select(e.item);
        this.update();
    }

    public onValueChange(e: any): void {
        this.update();
    }

    private _clearTimeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public get defaultInput(): string {
        if (this.field && this.the_model && this.the_model[this.field]) {
            return this.the_model[this.field];
        }
        return '';
    }

}
