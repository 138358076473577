import {ProductModel} from './product.model';
import {FormModel} from '../now/formModel';

export class PaymentModel extends FormModel {

    public purchase_order_no: string;
    public quotation_no: string;
    public quotation_id: any;
    public document: any;
    public started_at: string;
    public task_id: string;
    public customer_id: string;
    public product_ids: any;
    public verified: boolean;
    public products: ProductModel[];
    public status: number;
    public remark: string;
    public customer_required_at: string;
    public required_at: string;
    public sale_admin_required_at: string;
    public grand_total: number;

    constructor() {
        super('payments', 'App\\Payment');
        this.product_ids = {};
        this.products = [];
    }

    /*public clone(data: any): Model {
        if (data && data.products) {
            for (let i = 0; i < data.products.length; i++) {
                const dat: any = data.products[i];
                let product: ProductModel;
                product = new ProductModel();
                product.clone(dat);
                this.products.push(product);
            }
        }
        return super.clone(data);
    }*/

}
