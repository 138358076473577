import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GroupByPipe} from './groupBy.pipe';
import {ItemTypePipe} from './itemType.pipe';
import {MachineStatusPipe} from './machineStatus.pipe';
import {MaterialPipe} from './material.pipe';
import {MaterialStatusPipe} from './materialStatus.pipe';
import {MaterialTypePipe} from './materialType.pipe';
import {PackagingStatusPipe} from './packagingStatus.pipe';
import {ProcessPipe} from './process.pipe';
import {RankingPipe} from './ranking.pipe';
import {SchedulerOrderByPipe} from './schedulerOrderBy.pipe';
import {SearchFilterPipe} from './searchFilter.pipe';
import {TaskStatusPipe} from './taskStatus.pipe';
import {UserStatusPipe} from './userStatus.pipe';
import {DateTimePipe} from '../now/pipes/dateTime.pipe';
import {DocumentStatusPipe} from './documentStatus.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        GroupByPipe,
        ItemTypePipe,
        MachineStatusPipe,
        MaterialPipe,
        MaterialStatusPipe,
        MaterialTypePipe,
        PackagingStatusPipe,
        ProcessPipe,
        RankingPipe,
        SchedulerOrderByPipe,
        SearchFilterPipe,
        TaskStatusPipe,
        UserStatusPipe,
        DateTimePipe,
        DocumentStatusPipe
    ],
    declarations: [
        GroupByPipe,
        ItemTypePipe,
        MachineStatusPipe,
        MaterialPipe,
        MaterialStatusPipe,
        MaterialTypePipe,
        PackagingStatusPipe,
        ProcessPipe,
        RankingPipe,
        SchedulerOrderByPipe,
        SearchFilterPipe,
        TaskStatusPipe,
        UserStatusPipe,
        DateTimePipe,
        DocumentStatusPipe
    ],
    providers: [
        SchedulerOrderByPipe
    ]
})
export class ERPPipeModule {

    public static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: ERPPipeModule,
            providers: [
                //
            ]
        };
    }

}
