import {FormModel} from '../now/formModel';
import {UserModel} from '../now/user/user.model';
import {MachineModel} from './machine.model';
import {Model} from '../now/model';

export class ProcessModel extends FormModel {

    public name: string;
    public slug: string;
    public color: string;
    public text_color: string;

    public users: UserModel[];
    public machines: MachineModel[];

    constructor() {
        super('processes');
        this.users      = [];
        this.machines   = [];
    }

    public clone(data: any): Model {
        if (data.users) {
            for (let i = 0; i < data.users; i++) {
                let dat: any;
                dat = data.users[i];
                if (dat) {
                    let user: UserModel;
                    user = new UserModel();
                    user.clone(dat);
                    this.users.push(user);
                }
            }
            delete data.users;
        }
        super.clone(data);
        return this;
    }

    public removeUser(user: UserModel): void {
        let index: number;
        index = this.users.findIndex(i => i.id === user.id);
        if (index > -1) {
            this.users[index].checked = false;
            this.users[index].time = null;
            this.users.splice(index, 1);
        }
    }

    public removeMachine(machine: MachineModel): void {
        let index: number;
        index = this.machines.findIndex(i => i.id === machine.id);
        if (index > -1) {
            this.machines[index].checked = false;
            this.machines.splice(index, 1);
        }
    }

    public addUser(user: UserModel): void {
        if (!this.users) {
            this.users = [];
        }
        // let index: number;
        // index = this.users.findIndex(i => i.id === user.id);
        // if (index < 0) {
            this.users.push(user);
        // } else {
        //     this.users[index] = user;
        // }
    }

    public addMachine(machine: MachineModel, user: UserModel | UserModel[]): void {
        if (!this.machines) {
            this.machines = [];
        }
        let index: number;
        index = this.machines.findIndex(i => i.id === machine.id);
        if (user) {
            machine.addUser(user);
        }
        if (index < 0) {
            this.machines.push(machine);
        } else {
            this.machines[index] = machine;
        }
    }

    public get total_user_price(): number {
        let total: number;
        total = 0;
        for (let i = 0; i < this.users.length; i++) {
            let user: UserModel;
            user = this.users[i];
            total += user.total_price;
        }
        return total;
    }

    public get total_machine_price(): number {
        let total: number;
        total = 0;
        for (let i = 0; i < this.machines.length; i++) {
            let machine: MachineModel;
            machine = this.machines[i];
            total += machine.total_price;
        }
        return total;
    }

    public get total_price(): number {
        let total_user_price: number;
        let total_machine_price: number;
        total_user_price = this.total_user_price;
        total_machine_price = this.total_machine_price;
        return total_machine_price + total_user_price;
    }

}
