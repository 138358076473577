import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {TaskModel} from '../../../models/task.model';

@Component({
    selector: 'task-display-component',
    templateUrl: 'taskDisplay.component.html',
    styleUrls: ['taskDisplay.component.scss']
})
export class TaskDisplayComponent implements AfterViewInit {

    @Output('view') onView: EventEmitter<TaskModel> = new EventEmitter<TaskModel>();

    @Input('newline') newline: boolean;
    @Input('task') task: TaskModel;
    @Input('avatar') avatar: boolean;

    constructor() {
        this.avatar = true;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.newline = (this.newline === false) ? false : true;
        }, 0);
    }

    public viewTask(task: TaskModel): void {
        this.onView.emit(task);
    }

}
