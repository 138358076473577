import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'material'
})
export class MaterialPipe implements PipeTransform {

    transform(material: string): string {
        if (material === 'END_MILLS') {
            return 'End Mills';
        } else if (material === 'DRILL') {
            return 'Drill';
        } else if (material === 'INSERT') {
            return 'Insert';
        } else if (material === 'REMER') {
            return 'Remer';
        } else if (material === 'TAP') {
            return 'Tap';
        } else if (material === 'CUTTER') {
            return 'Cutter';
        } else if (material === 'SAW') {
            return 'ใบเลื่อย';
        } else if (material === 'GRINDING_STONE') {
            return 'หินเจียร';
        }
        return material;
    }

}
