import {FormModel} from '../now/formModel';
import {RSpecialToolingModel} from './rSpecialTooling.model';

export class SpecialToolingModel extends FormModel {

    public special_tooling_no: string;
    public name: string;
    public description: string;
    public price: number;
    public ranking: number;
    public amount: number;
    public status: number;
    public unit: string;
    public purchase_status: number;
    public required_at: string;
    public job_id: string;
    public special_tooling: any;

    constructor() {
        super('specialtoolings', 'App\\SpecialTooling');
        // this.special_tooling = new RSpecialToolingModel();
    }

    public get total_price() {
        return this.price * this.amount;
    }

}
