import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {Api} from '../now/api/api';

@Injectable()
export class ConstService {

    private state_list: string[];
    private country_list: string[];

    constructor(private api: Api) {
        this.reset_state_list();
        this.reset_country_list();
    }

    public getStateList(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.state_list && this.state_list.length > 0) {
                resolve(this.state_list);
            } else {
                this.api.request('assets/const/thai-tambons/changwats/json/th.json',
                    'GET', null, null, null, environment.host, '')
                    .subscribe((response: any): void => {
                        this.reset_state_list();
                        if (response && response.th && response.th.changwats) {
                            for (let i = 0; i < response.th.changwats.length; i++) {
                                let dat: any;
                                dat = response.th.changwats[i];
                                if (dat && dat.name) {
                                    this.state_list.push(dat.name);
                                }
                            }
                            resolve(this.state_list);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    public getCountryList(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.country_list && this.country_list.length > 0) {
                resolve(this.country_list);
            } else {
                this.api.request('assets/const/countries.json',
                    'GET', null, null, null, environment.host, '')
                    .subscribe((response: any): void => {
                        this.reset_country_list();
                        if (response && response.countries) {
                            for (let i = 0; i < response.countries.length; i++) {
                                let dat: any;
                                dat = response.countries[i];
                                if (dat && dat.name) {
                                    this.country_list.push(dat.name);
                                }
                            }
                            resolve(this.country_list);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_state_list(): void {
        if (this.state_list) {
            this.state_list.splice(0, this.state_list.length);
        } else {
            this.state_list = [];
        }
    }

    private reset_country_list(): void {
        if (this.country_list) {
            this.country_list.splice(0, this.country_list.length);
        } else {
            this.country_list = [];
        }
    }

}
