import {Injectable} from '@angular/core';
declare var swal: any;

@Injectable()
export class SwalService {

    constructor() {
        //
    }

    public input(text: string, title?: string, placeholder?: string, confirm_text?: string, cancel_text?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            swal({
                content: {
                    element: 'input',
                    attributes: {
                        placeholder: (placeholder) ? placeholder : 'กรอกข้อมูล',
                        type: 'text'
                    }
                },
                title: (title) ? title : 'กรอกข้อมูล',
                text: text,
                icon: 'info',
                buttons: {
                    cancel: (cancel_text) ? cancel_text : 'ปิด',
                    confirm: {
                        text: (confirm_text) ? confirm_text : 'ยืนยัน',
                        color: '#EF5350'
                    }
                }
            }).then((result: any): void => {
                resolve(result);
            });
        });
        return promise;
    }

    public success(text: string | any, title?: string, timer?: number, button_text?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let option: any;
            option = {
                title: (title) ? title : 'สำเร็จ',
                text: '',
                icon: 'success',
                timer: (timer) ? timer : false,
                button: {
                    text: (button_text) ? button_text : 'ปิด'
                },
                showConfirmButton: (timer) ? false : true,
                showCancelButton: false
            };
            if (typeof text === 'string') {
                option['text'] = text;
            } else if (text.is_html === true) {
                let span: any;
                span = document.createElement('span');
                span.innerHTML = text.text;
                option['content'] = span;
            }
            swal(option).then((result: any): void => {
                resolve(result);
            });
        });
        return promise;
    }

    public danger(text: string | any, is_html?: boolean, title?: string, timer?: number, button_text?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (typeof text === 'string') {
                //
            } else {
                for (const key in text) {
                    if (key) {
                        const value: any = text[key];
                        if (Array.isArray(value)) {
                            text = value[0];
                        } else {
                            text = value;
                        }
                        break;
                    }
                }
            }
            let option: any;
            option = {
                title: (title) ? title : 'เกิดข้อผิดพลาด',
                icon: 'error',
                timer: (timer) ? timer : false,
                button: {
                    text: (button_text) ? button_text : 'ปิด'
                },
                showConfirmButton: (timer) ? false : true,
                showCancelButton: false,
            };
            if (is_html === true) {
                let span: any;
                span = document.createElement('span');
                span.innerHTML = text;
                option['content'] = span;
            } else {
                option['text'] = text;
            }
            swal(option)
                .then((result: any): void => {
                    resolve(result);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public confirm(text: string, is_html?: boolean, title?: string, confirm_text?: string, cancel_text?: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let option: any;
            option = {
                closeOnClickOutside: false,
                closeOnEsc: false,
                title: (title) ? title : 'ยืนยันการตัดสินใจ',
                icon: 'warning',
                buttons: {
                    cancel: (cancel_text) ? cancel_text : 'ปิด',
                    confirm: {
                        text: (confirm_text) ? confirm_text : 'ยืนยัน',
                        color: '#EF5350'
                    }
                }
            };
            if (is_html === true) {
                let span: any;
                span = document.createElement('span');
                span.innerHTML = text;
                option['content'] = span;
            } else {
                option['text'] = text;
            }
            swal(option).then((result: any): void => {
                resolve(result);
            });
        });
        return promise;
    }

}
