import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {DivApiDirective} from './divApi.directive';
import {DivApiErrorDirective} from './divApiError.directive';
import {DivApiGettingDirective} from './divApiGetting.directive';
import {DivApiGotDirective} from './divApiGot.directive';
import {DivApiZeroDirective} from './divApiZero.directive';
import {DivApiConfig} from './divApi.config';
import {HttpClientModule} from '@angular/common/http';
import {DivApiTemplateService} from './divApiTemplate.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule
    ],
    exports: [
        DivApiDirective,
        DivApiErrorDirective,
        DivApiGettingDirective,
        DivApiGotDirective,
        DivApiZeroDirective
    ],
    declarations: [
        DivApiDirective,
        DivApiErrorDirective,
        DivApiGettingDirective,
        DivApiGotDirective,
        DivApiZeroDirective
    ],
    providers: [
        DivApiTemplateService
    ]
})
export class DivApiModule {

    public static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: DivApiModule,
            providers: [
                {
                    provide: DivApiConfig,
                    useValue: config.divApiConfig
                }
            ]
        };
    }

}
