import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {ProductModel} from '../../models/product.model';
import {TaskService} from '../../services/task.service';
import {Api} from '../../now/api/api';
import {TaskModel} from '../../models/task.model';
import {SwalService} from '../../services/swal.service';

@Component({
    selector: 'find-product-component',
    templateUrl: 'findProduct.component.html',
    styleUrls: ['findProduct.component.scss']
})
export class FindProductComponent implements AfterViewInit {

    public product: ProductModel;
    public timeout: any;

    constructor(
        public bsModalRef: BsModalRef,
        public taskService: TaskService,
        private api: Api,
        private swal: SwalService
    ) {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    public getTasksByProduct(product_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/' + product_id + '/product', 'POST', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.data && response.data[0]) {
                    let task: TaskModel;
                    task = new TaskModel();
                    if (response.data[0].parent_id && !response.data[0].parent.hidden) {
                        task.clone(response.data[0].parent);
                    } else {
                        task.clone(response.data[0]);
                    }
                    resolve(task);
                } else {
                    resolve();
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSelect(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.getTasksByProduct(e.id)
                .then((task: TaskModel): void => {
                    if (task) {
                        this.bsModalRef.content.task = task;
                        this.bsModalRef.content.submit = true;
                    } else {
                        this.bsModalRef.content.task = null;
                        this.bsModalRef.content.product_id = e.id;
                        this.bsModalRef.content.submit = true;
                    }
                    this.bsModalRef.hide();
                }, error => {
                    this.swal.danger(error);
                });
        }, 1250);
    }

    private clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSubmit(form: NgForm): void {
        //
    }

}
