import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TaskModel} from '../../../models/task.model';
import {FMTModel} from '../../../models/fmt.model';
import {Api} from '../../../now/api/api';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../components/loader/loader.service';
import {ViewTask} from '../view/viewTask';
import {TaskService} from '../../../services/task.service';
import {ModalService} from '../../../services/modal.service';
import {Viewer} from '../../../services/viewer';
import {DOCUMENT, Location} from '@angular/common';
import {MachineModel} from '../../../models/machine.model';
import {UserModel} from '../../../now/user/user.model';
import {SupplierModel} from '../../../models/supplier.model';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../now/user/auth.service';
import * as moment from 'moment';
import {SwalService} from '../../../services/swal.service';
import {RemarkModal} from '../../../modals/remark/remark.modal';

@Component({
    selector: 'fmt-task-component',
    templateUrl: 'FMTTask.component.html',
    styleUrls: ['FMTTask.component.scss']
})
export class FMTTaskComponent extends ViewTask implements AfterViewInit {

    @ViewChild('remarkModal', { static: true }) remarkModal: RemarkModal;

    public ready: boolean;
    public task: TaskModel;
    public fmt: FMTModel;
    public task_id: string;
    public fmt_id: string;

    constructor(
        public userService: UserService,
        public taskService: TaskService,
        public modal: ModalService,
        public location: Location,
        public viewer: Viewer,
        public api: Api,
        public swal: SwalService,
        public auth: AuthService,
        public route: ActivatedRoute,
        public loader: LoaderService,
    ) {
        //
        super({ taskService, api, modal, location, viewer });
        this.task = new TaskModel();
        this.fmt = new FMTModel();
        this.ready = false;
    }

    ngAfterViewInit(): void {
        this.route.params
            .subscribe(params => {
                this.loader.show();
                this.task_id = params['id'];
                if (this.task_id) {
                    this.getTask()
                        .then(() => {
                            this.fmt_id = this.task.modelable_id;
                            this.getFMT()
                                .then(() => {
                                    this.ready = true;
                                    this.loader.hide();
                                }, error => {
                                    console.error(error);
                                    this.loader.hide();
                                });
                        }, error => {
                            console.error(error);
                            this.loader.hide();
                        });
                } else {
                    this.ready = true;
                    this.loader.hide();
                }
            });
    }

    public accept(type?: number): void {
        if (this.task.current_role === 'fmt-progress') {
            if (this.valid_fmt_progress()) {
                this.swal.confirm('ยืนยันการส่งดำเนินการต่อไปใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.api.request('fmts/fix', 'POST', {}, {
                                id: this.fmt.id,
                                task_id: this.task_id,
                                after_description: this.fmt.after_description,
                                warranty: this.fmt.warranty,
                                fixed_at: this.fmt.fixed_at,
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    this.taskService.setStatus(
                                        this.task,
                                        this.task.status,
                                        'fmt-fixed',
                                        'fmt-fixed',
                                        'fmt-fixed',
                                        false,
                                        this.fmt.user_informer.id
                                    ).then(() => {
                                        this.swal.success('ดำเนินการสำเร็จ');
                                        this.leave(true);
                                    });
                                }
                            });
                        } else {
                            //
                        }
                    });
            } else {
                //
            }
        } else if (this.task.current_role === 'fmt-fixed') {
            if (this.valid_fmt_fixed()) {
                this.swal.confirm('ยืนยันการส่งดำเนินการต่อไปใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.api.request('fmts/complete', 'POST', {}, {
                                id: this.fmt.id,
                                task_id: this.task_id,
                                fixed_description: this.fmt.fixed_description,
                                fixed_summary: type,
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    this.taskService.setStatus(
                                        this.task,
                                        this.task.status,
                                        'fmt-complete',
                                        'fmt-complete',
                                        'fmt-complete',
                                        false
                                    ).then(() => {
                                        this.swal.success('ดำเนินการสำเร็จ');
                                        this.leave(true);
                                    });
                                }
                            });
                        } else {
                            //
                        }
                    });
            } else {
                //
            }
        } else {
            if (this.valid()) {
                this.swal.confirm('ยืนยันการส่งดำเนินการต่อไปใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            const estimated_repaired_at = (this.fmt.estimated_repaired_at) ? moment(this.fmt.estimated_repaired_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD 17:00:00') : null;
                            this.api.request('fmts/progress', 'POST', {}, {
                                id: this.fmt.id,
                                task_id: this.task_id,
                                user_checked_id: this.fmt.user_checked_id,
                                checked_date: this.fmt.checked_date,
                                supplier_id: this.fmt.supplier_id,
                                estimate_description: this.fmt.estimate_description,
                                can_repaired: this.fmt.can_repaired,
                                estimated_repaired_at,
                                equipments: this.fmt.equipments,
                            }).subscribe((response: any): void => {
                                if (response && response.success === true) {
                                    this.taskService.setStatus(
                                        this.task,
                                        this.task.status,
                                        'fmt-progress',
                                        'fmt-progress',
                                        'fmt-progress',
                                        false
                                    ).then(() => {
                                        this.swal.success('ดำเนินการสำเร็จ');
                                        this.leave(true);
                                    });
                                }
                            });
                        } else {
                            //
                        }
                    });
            } else {
                //
            }
        }
    }

    public applyFirst(): void {
        this.swal.confirm('ยืนยันการนำไปใช้งานก่อน ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.taskService.setStatus(
                        this.task,
                        this.task.status,
                        'fmt-apply',
                        'fmt-apply',
                        'fmt-apply',
                        false,
                    ).then(() => {
                        this.swal.success('แจ้งการนำเครื่องจักร/อุปกรณ์ไปใช้งานก่อนสำเร็จ');
                        this.leave(true);
                    });
                } else {
                    //
                }
            });
    }

    public fixAgain(): void {
        this.swal.confirm('ยืนยันการส่งซ่อมอีกครั้งใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.loader.show();
                    this.api.request('fmts/re_fix', 'POST', {}, {
                        id: this.fmt_id,
                        task_id: this.task_id,
                    }).subscribe((response: any) => {
                        this.taskService.createNewTask({
                            process_slug: 'request-fmt',
                            current_role: 'fmt',
                            action: 'request-fmt',
                            modelable_type: 'App\\FMT',
                            modelable_id: response.data.id
                        }).then(() => {
                            this.loader.hide();
                            this.swal.success('แจ้งการนำเครื่องจักร/อุปกรณ์ซ่อมอีกครั้งสำเร็จ');
                            this.leave(true);
                        }, error => {
                            console.error(error);
                            this.swal.danger(error);
                            this.loader.hide();
                        });
                    });
                } else {
                    //
                }
            });
    }

    public reject(): void {
        this.swal.confirm('ยืนยันปฏิเสธการร้องขอแจ้งซ่อม/สร้างเครื่องจักรและอุปกรณ์เลขที่ "' + this.fmt.document_no + '" ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.remarkModal.open();
                }
            });
    }

    public valid_fmt_progress(): boolean {
        if (!this.fmt.after_description) {
            this.swal.danger('โปรดระบุรายละเอียดหลังการซ่อม');
            return false;
        } else if (!this.fmt.fixed_at) {
            this.swal.danger('โปรดระบุวันที่แล้วเสร็จ');
            return false;
        }
        return true;
    }

    public valid_fmt_fixed(): boolean {
        if (!this.fmt.fixed_description) {
            this.swal.danger('โปรดระบุรายละเอียดผลการตรวจรับงาน');
            return false;
        }
        return true;
    }

    public archiveTask(): void {
        if (this.fmt && this.fmt.document_no) {
            this.swal.confirm('ยืนยันการจัดเก็บเอกสาร "' + this.fmt.document_no + '" ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('fmts/archive', 'POST', {}, {
                            id: this.fmt.id,
                            task_id: this.task_id
                        }).subscribe((response: any): void => {
                            if (response && response.success === true) {
                                this.taskService.complete(this.task)
                                    .then(() => {
                                        this.swal.success('จัดเก็บเอกสารสำเร็จ');
                                        this.leave(true);
                                    });
                            }
                        });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public valid(): boolean {
        if (!this.fmt.user_checked_id) {
            this.swal.danger('โปรดระบุผู้รับเรื่อง');
            return false;
        } else if (!this.fmt.estimate_description) {
            this.swal.danger('โปรดระบุผลการตรวจเช็ค/ประเมินความเสียหาย');
            return false;
        } else if (this.fmt.can_repaired !== true && this.fmt.can_repaired !== false) {
            this.swal.danger('โปรดระบุผลการประเมินความเสียหาย');
            return false;
        }
        return true;
    }

    public getFMT(): Promise<FMTModel> {
        const promise = new Promise<FMTModel>((resolve, reject) => {
            this.api.request('fmts/' + this.fmt_id + '', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        const machine = new MachineModel();
                        const user_informer = new UserModel();
                        const user_checked = new UserModel();
                        const supplier = new SupplierModel();
                        if (response.data.machine) {
                            machine.clone(response.data.machine);
                        }
                        if (response.data.user_informer) {
                            user_informer.clone(response.data.user_informer);
                        }
                        if (response.data.user_checked) {
                            user_checked.clone(response.data.user_checked);
                        }
                        if (response.data.supplier) {
                            supplier.clone(response.data.supplier);
                        }
                        this.fmt.clone(response.data);
                        this.fmt.machine = machine;
                        this.fmt.user_informer = user_informer;
                        this.fmt.user_checked = user_checked;
                        this.fmt.supplier = supplier;

                        console.log(this.fmt.supplier);
                        console.log(response.data);

                        if (!this.fmt.user_checked_id) {
                            const user = this.auth.user;
                            this.fmt.user_checked = new UserModel();
                            this.fmt.user_checked_id = user.id;
                            this.fmt.user_checked.clone(user);
                        }
                        if (!this.fmt.checked_date) {
                            this.fmt.checked_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                        }

                        resolve(this.fmt);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onUserCheckedChange(e: any): void {
        this.fmt.user_checked_id = (e && e.id) ? e.id : null;
    }

    public onSupplierChange(e: any): void {
        this.fmt.supplier_id = (e && e.id) ? e.id : null;
    }

    public getTask(): Promise<TaskModel> {
        const promise = new Promise<TaskModel>((resolve, reject) => {
            this.api.request('tasks/' + this.task_id + '/detail', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.task.clone(response.data);
                        resolve(this.task);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public onRemarkModalSubmit(e: any): void {
        if (this.fmt.user_informer && this.fmt.user_informer.id) {
            this.api.request('fmts/reject', 'POST', {}, {
                id: this.fmt.id,
                remark: e.remark_message,
                task_id: this.task_id
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    this.taskService.setStatus(
                        this.task,
                        this.task.status,
                        'reject_request_fmt',
                        'request-fmt',
                        'request-fmt',
                        false,
                        this.fmt.user_informer.id,
                        e.remark_message
                    ).then(() => {
                        this.swal.success('ปฏิเสธสำเร็จ');
                        this.leave(true);
                    });
                }
            });
        } else {
            this.swal.danger('เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ');
        }
    }

}
