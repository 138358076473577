import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {MachineModel} from '../models/machine.model';
import {SchedulerModel} from '../models/scheduler.model';

@Injectable()
export class MachineService {

    private machines: MachineModel[];
    private machines_by_slug: any;

    constructor(private api: Api) {
        this.reset_machine_list();
        this.reset_machine_by_slug_list();
    }

    public getFMTs(machine): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('machines/fmts', 'GET', {
                id: machine.id
            }).subscribe((response: any): void => {
                const schedulers = [];
                if (response && response.success === true) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat: any = response.data[i];
                        if (dat) {
                            schedulers.push(dat);
                        }
                    }
                    resolve(schedulers);
                } else {
                    resolve([]);
                }
            }, error => {
                resolve([]);
            });
        });
        return promise;
    }

    public machineMaintainPlans(machine: MachineModel): Promise<any> {
        const promise = new Promise<any>(resolve => {
            this.api.request('machines/maintain_plans', 'GET', {
                id: machine.id
            }).subscribe((response: any): void => {
                const schedulers = [];
                if (response && response.success === true) {
                    for (let i = 0; i < response.data.length; i++) {
                        const dat: any = response.data[i];
                        if (dat) {
                            schedulers.push(dat);
                        }
                    }
                    resolve(schedulers);
                } else {
                    resolve([]);
                }
            }, error => {
                resolve([]);
            });
        });
        return promise;
    }

    public machineSchedulers(machine: MachineModel, job_id?: string): Promise<SchedulerModel[]> {
        let promise: Promise<SchedulerModel[]>;
        promise = new Promise<SchedulerModel[]>((resolve, reject) => {
            let schedulers: SchedulerModel[];
            schedulers = [];
            this.api.request('machines/schedulers', 'POST', {
                job_id: job_id
            }, {
                id: machine.id
            }, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        for (let i = 0; i < response.data.length; i++) {
                            const dat: any = response.data[i];
                            if (dat) {
                                if (!job_id || dat.job_id !== job_id) {
                                    let scheduler: SchedulerModel;
                                    scheduler = new SchedulerModel();
                                    scheduler.clone(dat);
                                    schedulers.push(scheduler);
                                }
                            }
                        }
                        resolve(schedulers);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public getMachinesByProcess(process_slug: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (process_slug) {
                let machines_by_slug: MachineModel[];
                machines_by_slug = this.machines_by_slug[process_slug];
                if (machines_by_slug && Array.isArray(machines_by_slug) && machines_by_slug.length > 0) {
                    resolve(machines_by_slug);
                } else {
                    this.machines_by_slug[process_slug] = [];
                    machines_by_slug = this.machines_by_slug[process_slug];
                    this.api.request('machines/' + process_slug + '/process')
                        .subscribe((response: any): void => {
                            if (response && response.data) {
                                for (let i = 0; i < response.data.length; i++) {
                                    let dat: any;
                                    dat = response.data[i];
                                    if (dat) {
                                        let machine: MachineModel;
                                        machine = new MachineModel();
                                        machine.clone(dat);
                                        machines_by_slug.push(machine);
                                    }
                                }
                            }
                            resolve(machines_by_slug);
                        }, (error: any): void => {
                            //
                        });
                }
            } else {
                reject();
            }
        });
        return promise;
    }

    public getMachines(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.machines && this.machines.length > 0) {
                resolve(this.machines);
            } else {
                this.api.request('machines', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_machine_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.machines.push(dat);
                                }
                            }
                            resolve(this.machines);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_machine_by_slug_list(): void {
        this.machines_by_slug = {};
    }

    private reset_machine_list(): void {
        if (this.machines) {
            this.machines.splice(0, this.machines.length);
        } else {
            this.machines = [];
        }
    }

}
