import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'userStatus'
})
export class UserStatusPipe implements PipeTransform {

    transform(status: number): string {
        if (!status) {
            return 'พนักงานประจำ';
        } else if (status === 1) {
            return 'ขาดทำงาน';
        } else if (status === 2) {
            return 'ลาป่วย';
        } else if (status === 3) {
            return 'ลาคลอดบุตร';
        } else if (status === 4) {
            return 'ลาไปช่วยเหลือภริยาที่คลอดบุตร';
        } else if (status === 5) {
            return 'ลากิจส่วนตัว';
        } else if (status === 6) {
            return 'ลาพักร้อน';
        } else if (status === 7) {
            return 'ลาอุปสมบท';
        } else if (status === 8) {
            return 'ลาเข้ารับการเตรียมพล';
        } else if (status === 9) {
            return 'ลาฝึกอบรม';
        } else if (status === 10) {
            return 'ลาไปปฏิบัติงานระหว่างประเทศ';
        } else if (status === 11) {
            return 'ลาติดตามคู่สมรส';
        } else if (status === 12) {
            return 'ลาไปฟื้นฟูสมรรถภาพด้านอาชีพ';
        }
        return 'UNKNOW ' + status;
    }

}
