import {Model} from '../now/model';
import {DocumentModel} from './document.model';
import {ContactModel} from './contact.model';
import {Bank} from '../app/api/bank';

export class VendorModel extends Model {

    public name: string;
    public avatar: string;
    public type: number;
    public ranking: number;
    public tax_no: string;
    public branch: string;
    public telephone: string;
    public telephone2: string;
    public fax: string;
    public email: string;
    public website: string;
    public address: string;
    public city: string;
    public state: string;
    public country: string;
    public postal_code: string;
    public credit_terms: number;
    public banks: Bank[];
    public contacts: ContactModel[];
    public documents: DocumentModel[];

    public document_id: any;
    public document_name: any;
    public document: any;

    public price: number;
    public days: number;
    public remark: string;
    public checked: boolean;

    constructor() {
        super('vendors', 'App\\Vendor');
        this.documents = [];
        this.contacts = [];
        this.banks = [];
    }

    public clone(data: any): Model {
        if (data) {
            if (data.contacts) {
                if (!this.contacts) {
                    this.contacts = [];
                } else {
                    this.contacts.splice(0, this.contacts.length);
                }
                for (let i = 0; i < data.contacts.length; i++) {
                    const dat: any = data.contacts[i];
                    if (dat && dat.id) {
                        let contact: ContactModel;
                        contact = new ContactModel();
                        contact.clone(dat);
                        this.contacts.push(contact);
                    }
                }
                delete data.contacts;
            }
        }
        super.clone(data);
        return this;
    }

}
