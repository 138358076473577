import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CarrierModel} from '../../../models/carrier.model';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import {ModalService} from '../../../services/modal.service';

@Component({
    selector: 'carrier-list-component',
    templateUrl: 'carrierList.component.html',
    styleUrls: ['carrierList.component.scss']
})
export class CarrierListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sub: any;
    public timeout: any;
    public carriers: CarrierModel[];
    public keyword: string;

    constructor(private router: Router, private modalService: BsModalService, private modal: ModalService) {
        super();
        this.carriers = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_carriers();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let carrier: CarrierModel;
                    carrier = new CarrierModel();
                    carrier.clone(dat);
                    this.carriers.push(carrier);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public createCarrier(): void {
        this.router.navigateByUrl('/database/carrier/create');
    }

    public gotoCarrierView(carrier: CarrierModel): void {
        this.router.navigateByUrl('/database/carrier/' + carrier.id + '/detail');
    }

    private clear_carriers(): void {
        if (this.carriers) {
            this.carriers.splice(0, this.carriers.length);
        } else {
            this.carriers = [];
        }
    }

}
