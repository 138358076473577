import {AfterViewInit, Component} from '@angular/core';
import {ViewRequest} from '../view/viewRequest';
import {Location} from '@angular/common';
import {SwalService} from '../../../services/swal.service';
import {ModalService} from '../../../services/modal.service';
import {TaskModel} from '../../../models/task.model';
import {CrdModel} from '../../../models/crd.model';
import {DocumentModel} from '../../../models/document.model';
import {DocumentDetailComponent} from '../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../environments/environment';
import {Api} from '../../../now/api/api';
import {Viewer} from '../../../services/viewer';
import {TaskService} from '../../../services/task.service';

const MAX_STEPS = 10;
declare var $: any;

@Component({
    selector: 'request-crd-task-component',
    templateUrl: 'requestCRDTask.component.html',
    styleUrls: ['requestCRDTask.component.scss']
})
export class RequestCRDTaskComponent extends ViewRequest implements AfterViewInit {

    public crd: CrdModel;
    public task: TaskModel;

    constructor(
        public taskService: TaskService,
        public location: Location,
        private swal: SwalService,
        public modal: ModalService,
        public api: Api,
        public viewer: Viewer
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.task = new TaskModel();
        this.crd = new CrdModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            for (let i = 0; i < MAX_STEPS; i++) {
                this.crd.steps.push({
                    detail: '',
                    remark: ''
                });
            }
        }, 0);
    }

    public onUploadedSuccess(data: any): void {
        this.api.request('products/document', 'PUT', {}, {
            document: data,
            // id: this.current_product.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
            }
        }, error => {
            //
        });
    }

    public onUploadError(data: any): void {
        //
    }

    public onStartUploader(data: any): void {
        if (data && data.uploader) {
            data.uploader.cancel();
        }
        this.swal.danger('กรุณาบันทึกข้อมูลสินค้าก่อนทำการอัพโหลดเอกสาร');
    }

    public onProgress(models: any[], percent: number): void {
        //
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            task: this.task
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentPreview(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        window.open(environment.api_host + 'view/' + doc.id + '/document/', '_blank');
    }

    public sendRequest(): void {
        //
    }

    public save(): void {
        //
    }

}
