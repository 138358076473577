import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Injectable()
export class ModalService {

    private modalSubscription: any;

    constructor(private modalService: BsModalService) {
        this.modalSubscription = {};
    }

    public show(component: any, content?: any, options?: any): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let modalContent: BsModalRef;
            let _options: any;
            _options = Object.assign({ class: 'modal-md', backdrop: true, ignoreBackdropClick: true }, options);
            modalContent = this.modalService.show(component, _options);
            if (content) {
                modalContent.content = content;
            } else {
                modalContent.content = {};
            }
            this.modalSubscription[component.name] = this.modalService.onHide
                .subscribe(() => {
                    this.modalSubscription[component.name].unsubscribe();
                    delete this.modalSubscription[component.name];
                    resolve(Object.assign(modalContent.content));
                });
        });
        return promise;
    }

    public dismiss(): void {
        //
    }

}
