import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../../../now/api/api';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {SwalService} from '../../../../services/swal.service';
import {AppService} from '../../../../app/app.service';
import {ModalService} from '../../../../services/modal.service';
import {DocumentModel} from '../../../../models/document.model';
import {DocumentDetailComponent} from '../../../task/view/documentDetail/documentDetail.component';
import {environment} from '../../../../environments/environment';
import {SupplierModel} from '../../../../models/supplier.model';
import {QuotationModel} from '../../../../models/quotation.model';
import {Location} from '@angular/common';
import {ContactModel} from '../../../../models/contact.model';
import {Bank} from '../../../../app/api/bank';

@Component({
    selector: 'supplier-view-component',
    templateUrl: 'supplierView.component.html',
    styleUrls: ['supplierView.component.scss']
})
export class SupplierViewComponent implements OnInit {

    public supplier: SupplierModel;
    public supplier_id: string;
    public current_tab: string;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        private location: Location,
        private appService: AppService,
        private modelApi: ModelApi,
        private modal: ModalService,
        private swal: SwalService
    ) {
        //
        this.supplier = new SupplierModel();
        this.route.params
            .subscribe(params => {
                this.supplier_id = params['id'];
            });
        
        this.current_tab = 'profile';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getSupplier()
                .then(() => {
                    if (this.supplier.contacts && this.supplier.contacts.length === 0) {
                        this.supplier.contacts.push(new ContactModel());
                    }
                    if (this.supplier.banks && this.supplier.banks.length === 0) {
                        this.supplier.banks.push({});
                    }
                });
        }, 0);
    }

    public getSupplier(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.supplier_id) {
                this.api.request('suppliers/' + this.supplier_id + '/detail', 'GET')
                    .subscribe((response: any): void => {
                        this.supplier.clone(response.data);
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve(this.supplier);
            }
        });
        return promise;
    }

    public addContact(): void {
        this.supplier.contacts.push(new ContactModel());
    }

    public addBank(): void {
        this.supplier.banks.push({});
    }

    public save_contact(contact: ContactModel): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            contact.modelable_type = 'App\\Supplier';
            contact.modelable_id = this.supplier.id;
            //
            this.modelApi.createOrUpdate(contact, [
                'full_name', 'telephone', 'telephone2', 'email', 'fax', 'position', 'modelable_type', 'modelable_id'
            ], null, null, null, true, true)
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save_bank(bank: Bank): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            bank.modelable_id = this.supplier.id;
            bank.modelable_type = 'App\\Supplier';
            this.api.request('banks', 'POST', {}, bank, null, null, null, null, true)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public save(): void {
        if (!this.supplier.name) {
            this.swal.danger('กรุณากรอกชื่อผู้ขาย');
        } else if (!this.supplier.branch && false) {
            this.swal.danger('กรุณากรอกสาขาผู้ขาย');
        } else if (!this.supplier.email && false) {
            this.swal.danger('กรุณากรอกอีเมลผู้ขาย');
        } else if (!this.supplier.telephone && false) {
            this.swal.danger('กรุณากรอกโทรศัพท์ติดต่อ');
        } else {
            this.modelApi.createOrUpdate(this.supplier, [
                // profile
                'avatar', 'name', 'tax_no', 'branch', 'ranking',
                'telephone', 'telephone2', 'email', 'fax', 'email', 'website', 'address',
                'city', 'state', 'postal_code', 'country', 'credit_terms'
            ], null, null, null, false)
                .subscribe((response: any): void => {
                    this.supplier.id = response.data.id;
                    let _promise_list: Promise<any>[];
                    _promise_list = [];
                    for (let i = 0; i < this.supplier.banks.length; i++) {
                        const bank: Bank = this.supplier.banks[i];
                        _promise_list.push(this.save_bank(bank));
                    }
                    for (let i = 0; i < this.supplier.contacts.length; i++) {
                        const contact: ContactModel = this.supplier.contacts[i];
                        if (contact && (contact.full_name || contact.id)) {
                            _promise_list.push(this.save_contact(contact));
                        }
                    }
                    Promise.all(_promise_list)
                        .then(() => {
                            this.location.replaceState('/database/supplier/' + this.supplier.id + '/detail');
                            this.swal.success('บันทึกข้อมูลผู้ขายสำเร็จ');
                        }, error => {
                            this.swal.danger(error);
                        });
                }, error => {
                    this.swal.danger(error);
                });
        }
    }

    public onUploadedSuccess(data: any): void {
        if (data && data.preview) {
            this.supplier.avatar = data.preview;
            this.modelApi.update(this.supplier, ['avatar'])
                .subscribe(() => {});
        }
    }

    public onUploadError(error: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('suppliers/document', 'PUT', {}, {
            document: data,
            id: this.supplier.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.supplier.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public viewDocumentDetail(doc: DocumentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            user: this.supplier,
            document: doc
        }, { backdrop: true, ignoreBackdropClick: true })
            .then(() => {
                //
            });
    }

    public viewDocumentPreview(doc: DocumentModel): void {
        window.open(environment.api_host + 'view/' + doc.id + '/supplier/document/', '_blank');
    }

    public onStartUploader(data: any): void {
        if (this.supplier && this.supplier.id) {
            //
        } else {
            if (data && data.uploader) {
                data.uploader.cancel();
            }
            this.swal.danger('กรุณาบันทึกข้อมูลผู้ขายก่อนทำการอัพโหลดเอกสาร');
        }
    }

    public createSupplier(): void {
        this.save();
    }

}
