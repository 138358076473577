import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PackagingModel} from '../../../models/packaging.model';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';

@Component({
    selector: 'packaging-list-component',
    templateUrl: 'packagingList.component.html',
    styleUrls: ['packagingList.component.scss']
})
export class PackagingListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sub: any;
    public timeout: any;
    public packagings: PackagingModel[];
    public keyword: string;

    constructor(private router: Router) {
        super();
        this.packagings = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_packagings();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let packaging: PackagingModel;
                    packaging = new PackagingModel();
                    packaging.clone(dat);
                    this.packagings.push(packaging);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    public gotoPackagingView(packaging: PackagingModel, index?: number): void {
        this.router.navigateByUrl('/database/packaging/' + packaging.id + '/detail');
    }

    private clear_packagings(): void {
        if (this.packagings) {
            this.packagings.splice(0, this.packagings.length);
        } else {
            this.packagings = [];
        }
    }

    public createPackaging(): void {
        this.router.navigateByUrl('/database/packaging/create');
    }

}
