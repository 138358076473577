import {AfterViewInit, Component} from '@angular/core';
import {ViewRequest} from '../view/viewRequest';
import {Location} from '@angular/common';
import {SwalService} from '../../../services/swal.service';
import {ModalService} from '../../../services/modal.service';
import {TaskModel} from '../../../models/task.model';
import {StoreModel} from '../../../models/store.model';
import {Api} from '../../../now/api/api';
import {RemarkModalComponent} from '../../../modals/remarkModal/remarkModal.component';
import {AuthService} from '../../../now/user/auth.service';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {RequisitionModel} from '../../../models/requisition.model';
import {TaskService} from '../../../services/task.service';
import {ItemModel} from '../../../models/item.model';
import {MaterialModel} from '../../../models/material.model';
import {MaterialCreateModal} from '../../task/view/product/materialCreate/materialCreate.modal';
import {UserModel} from '../../../now/user/user.model';
import {SchedulerHistoryModel} from '../../../models/schedulerHistory.model';
import {Remark} from '../../../app/api/remark';
import {Viewer} from '../../../services/viewer';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../../components/loader/loader.service';
import {JobModel} from '../../../models/job.model';

const MAX_STORE_ITEMS = 10;

@Component({
    selector: 'request-store-task-component',
    templateUrl: 'requestStoreTask.component.html',
    styleUrls: ['requestStoreTask.component.scss']
})
export class RequestStoreTaskComponent extends ViewRequest implements AfterViewInit {

    public store: StoreModel;
    public requisition: RequisitionModel;
    public scheduler_histories: SchedulerHistoryModel[];
    public remarks: Remark[];

    constructor(
        public viewer: Viewer,
        public location: Location,
        public api: Api,
        private loader: LoaderService,
        public route: ActivatedRoute,
        public modelApi: ModelApi,
        public swal: SwalService,
        public taskService: TaskService,
        public authService: AuthService,
        public modal: ModalService
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.task = new TaskModel();
        this.scheduler_histories = [];
        this.remarks = [];
        this.requisition = new RequisitionModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.getTask()
                            .then(() => {
                                this.requisition.id = this.task.modelable_id;
                                this.getRequisition()
                                    .then(requisition => {
                                        this.requisition = requisition;
                                        this.remarks = this.requisition.remarks;
                                        if (!this.remarks) {
                                            this.remarks = [];
                                        }
                                        this.init();
                                    });
                            });
                    } else {
                        this.init();
                    }
                });
        });
    }

    public init(): void {
        for (let i = 0; i < MAX_STORE_ITEMS; i++) {
            let store_item: ItemModel;
            if (this.requisition && this.requisition.materials && this.requisition.materials[i] && this.requisition.materials[i].id) {
                store_item = new ItemModel();
                store_item.clone(this.requisition.materials[i]);
                store_item.index = i;
                store_item.pivot_amount = this.requisition.materials[i].pivot.amount;
                this.requisition.materials[i] = store_item;
            } else {
                store_item = new ItemModel();
                store_item.index = i;
                this.requisition.materials.push(store_item);
            }
        }
        if (this.requisition && (!this.requisition.man || !this.requisition.man.id)) {
            this.requisition.man = this.authService.user;
            this.requisition.man_id = this.authService.user.id;
        }
    }

    public getRequisition(): Promise<RequisitionModel> {
        let promise: Promise<RequisitionModel>;
        promise = new Promise<RequisitionModel>((resolve, reject) => {
            this.api.request('requisitions/' + this.requisition.id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    let requisition: RequisitionModel;
                    requisition = new RequisitionModel();
                    requisition.clone(response.data);
                    if (response.data.man && response.data.man.id) {
                        let man: UserModel;
                        man = new UserModel();
                        man.clone(response.data.man);
                        requisition.man = man;
                    } else {
                        requisition.man = new UserModel();
                    }
                    if (response.data.job && response.data.job.id) {
                        const job = new JobModel();
                        job.clone(response.data.job);
                        requisition.job = job;
                    } else {
                        requisition.job = new JobModel();
                    }
                    resolve(requisition);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onJobChange(e: any): void {
        this.requisition.job_id = (e && e.id) ? e.id : null;
    }

    public onManChange(e: any): void {
        this.requisition.man_id = (e && e.id) ? e.id : null;
    }

    public showMaterialDetail(material: any): void {
        if (material && material.id) {
            this.modal.show(MaterialCreateModal, {
                viewable        : true,
                editable        : false,
                type            : material.type,
                item_id         : material.id,
                item_name       : material.search_value,
                material_model  : material,
                current_item    : material
            }).then((data: any): void => {
                //
            });
        } else {
            //
        }
    }

    public onMaterialSelect(material: any, e: any): void {
        if (e && e.id) {
            material.id = e.id;
        } else {
            material = new ItemModel();
        }
    }

    public addRemark(storeItem: ItemModel): void {
        this.modal.show(RemarkModalComponent, {
            description: storeItem.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                storeItem.description = content.description;
                // this.save();
            } else {
                //
            }
        });
    }

    public valid_materials(): boolean {
        for (let i = 0; i < this.requisition.materials.length; i++) {
            const material = this.requisition.materials[i];
            if (material && material.id && !material.pivot_amount) {
                this.swal.danger('โปรดระบุจำนวน "' + material.search_value + '" ที่ต้องการจะเบิก');
                return false;
            }
        }
        let found: boolean;
        found = false;
        for (let i = 0; i < this.requisition.materials.length; i++) {
            const material = this.requisition.materials[i];
            if (material && material.id) {
                found = true;
            }
        }
        if (found === false) {
            this.swal.danger('โปรดเลือกรายการวัตถุดิบ/วัสดุที่ต้องการจะเบิก');
            return false;
        }
        return true;
    }

    public sendRequest(): void {
        if (this.valid_materials()) {
            this.swal.confirm('ยืนยันการส่งคำร้องขอการเบิกวัสดุใช่หรือไม่?')
                .then((result: boolean): void => {
                    this.loader.show();
                    if (result === true) {
                        this.requisition.material_ids = this.getCheckedMaterials();
                        if (this.requisition && this.requisition.id && this.task.id) {
                            this.modelApi.update(this.requisition, [
                                'ref_no', 'job_id', 'man_id', 'objective', 'objective_other', 'material_ids'
                            ]).subscribe((response: any): void => {
                                this.taskService.setStatus(this.task, this.task.status, 'request-requisition', 'requisition', 'request-requisition', false)
                                    .then(() => {
                                        this.loader.hide();
                                        this.swal.success('ส่งคำร้องขอการเบิกวัสดุสำเร็จ');
                                        this.navBack();
                                    }, error => {
                                        console.error(error);
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                            }, error => {
                                console.error(error);
                                this.swal.danger(error);
                                this.loader.hide();
                            });
                        } else {
                            this.modelApi.create(this.requisition, [
                                'ref_no', 'job_id', 'man_id', 'objective', 'objective_other', 'material_ids'
                            ], null, false)
                                .subscribe((response: any): void => {
                                    this.requisition.id = response.data.id;
                                    this.taskService.createNewTask({
                                        process_slug: 'request-requisition',
                                        current_role: 'requisition',
                                        action: 'request-requisition',
                                        modelable_type: 'App\\Requisition',
                                        modelable_id: this.requisition.id
                                    }).then(() => {
                                        this.loader.hide();
                                        this.swal.success('ส่งคำร้องขอการเบิกวัสดุสำเร็จ');
                                        this.navBack();
                                    }, error => {
                                        console.error(error);
                                        this.swal.danger(error);
                                        this.loader.hide();
                                    });
                                }, error => {
                                    console.error(error);
                                    this.swal.danger(error);
                                    this.loader.hide();
                                });
                        }
                    } else {
                        this.loader.hide();
                    }
                });
        } else {
            //
        }
    }

    public onMaterialMoreHandler(material: any, e: any): void {
        this.modal.show(MaterialCreateModal, {
            viewable        : false,
            editable        : false,
            // type            : 'frw',
            submit_text     : 'เลือกเพื่อเบิก',
            item_id         : material.id,
            item_name       : material.search_value,
            material_model  : material,
            current_item    : material
        }, {
            class: 'modal-xl'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                } else {
                    //
                }
            }
        });
    }

    public onMaterialCreating(material, e: any): void {
        setTimeout(() => { // add delay when model to select
            this.onClickCreateCuttingMaterial(material.item, e);
        }, 0);
    }

    public onClickCreateCuttingMaterial(item: ItemModel, e: any): void {
        // create a item into store
        console.log(item);
        this.modal.show(MaterialCreateModal, {
            // editable        : (this.editable === true) ? true : false,
            warehouse       : false,
            editable        : true,
            viewable        : true,
            type            : 'frw',
            submit_text     : 'เลือกเพื่อเบิก',
            item_name       : (item.search_value) ? item.search_value : item.name,
            current_item    : item,
            material_model  : item
        }, {
            class: 'modal-md'
        }).then((data: any): void => {
            if (data && data.submit === true && data.item && data.item.id) {
                if (e && e.event && e.event.select) {
                    e.event.select(data.item);
                } else {
                    //
                }
            }
        });
    }

    public purchase(store_item: any): void {
        //
    }

    public getCheckedMaterials(): any[] {
        let returned_data: any[];
        returned_data = [];
        for (let i = 0; i < this.requisition.materials.length; i++) {
            const material: any = this.requisition.materials[i];
            if (material && material.id) {
                returned_data.push({
                    item_id         : material.id,
                    amount          : material.pivot_amount,
                    remark          : material.description
                });
            }
        }
        return returned_data;
    }

}
