import {AfterContentInit, AfterViewInit, Component, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {ItemModel} from '../../models/item.model';
import {WarehouseModel} from '../../models/warehouse.model';
import {ShelfModel} from '../../models/shelf.model';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../now/api/api';
import {ModelApi} from '../../now/modelApi/modelApi';
import {SwalService} from '../../services/swal.service';
import {UserService} from '../../services/user.service';
import {DocumentModel} from '../../models/document.model';
import {DocumentDetailComponent} from '../../pages/task/view/documentDetail/documentDetail.component';
import {ModalService} from '../../services/modal.service';
import {
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import {Viewer} from '../../services/viewer';
import {StoreService} from '../../services/store.service';

@Component({
    selector: 'store-form-component',
    templateUrl: 'storeForm.component.html',
    styleUrls: ['storeForm.component.scss']
})
export class StoreFormComponent implements AfterViewInit, AfterContentInit {

    public psConfig: PerfectScrollbarConfigInterface;
    public timeout: any;

    @Input() public type: string;
    @Input() public submit_text: string;
    @Input() public create_text: string;
    @Input() public current_item: ItemModel;
    @Input() public editable: boolean;
    @Input() public viewable: boolean;
    @Input() public warehouse: any;
    @Input() public buttonSubmit: boolean;

    @Output() public onItemFormSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onItemGenNO: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onFilterItems: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onSearching: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onGetting: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onGot: EventEmitter<any> = new EventEmitter<any>();

    public created_at: any;
    public tmp_current_item: ItemModel;
    public items: ItemModel[];
    public keyword: string;
    public sub: Subscription;
    public search_fields: string[];
    public search_values: string[];
    public search_items: ItemModel[];
    public shelf: ShelfModel;

    constructor(
        public viewer: Viewer,
        private route: ActivatedRoute,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        public userService: UserService,
        private ngZone: NgZone,
        public modal: ModalService,
        private storeService: StoreService,
    ) {
        //
        this.created_at = new Date();
        this.items = [];
        this.search_items = [];
        this.search_fields = [];
        this.search_values = [];
        this.current_item = new ItemModel();
        this.psConfig = {
            //
        };
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.type = (this.type) ? this.type : '';
            this.buttonSubmit = (this.buttonSubmit === false) ? false : true;
            this.submit_text = (this.submit_text) ? this.submit_text : 'เลือกเพื่อเพิ่มเข้าคลัง';
            if (!this.current_item) {
                this.createItem();
            } else if (!this.current_item.warehouses || this.current_item.warehouses.length === 0) {
                this.current_item.warehouses = [];
                const index = this.current_item.warehouses.push(new WarehouseModel()) - 1;
                this.shelf = new ShelfModel();
                this.current_item.warehouses[index].shelves = [this.shelf];
            }
            setTimeout(() => {
                // this.ps.update();
            }, 5000);
        }, 0);
    }

    ngAfterContentInit(): void {
        // setTimeout(() => {
        //     this.ps.update();
        // }, 0);
    }

    public createItem(): void {
        this.current_item = new ItemModel();
        if (this.current_item && (!this.current_item.warehouses || this.current_item.warehouses.length === 0)) {
            this.current_item.warehouses = [];
            this.current_item.warehouses.push(new WarehouseModel());
            this.current_item.shelves.push(new ShelfModel());
        }
        //
        if (this.type) {
            this.current_item.type = this.type;
        }
        if (!this.current_item.warehouses || !this.current_item.warehouses.length) {
            this.current_item.warehouses = [new WarehouseModel()];
            this.current_item.shelves = [new ShelfModel()];
        }
        this.shelf = new ShelfModel();
    }

    public onDocumentUploadedSuccess(data: any, modelable_id?: string, modelable_type?: string): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            modelable_id = (modelable_id) ? modelable_id : (this.current_item && this.current_item.modelable && this.current_item.modelable.id) ? this.current_item.modelable.id : '';
            modelable_type = (modelable_type) ? modelable_type : (this.current_item && this.current_item.modelable && this.current_item.modelable_type) ? this.current_item.modelable_type : '';
            if (modelable_id) {
                this.api.request('items/document', 'PUT', {}, {
                    document: data,
                    modelable_id: modelable_id,
                    modelable_type: modelable_type,
                    id: this.current_item.id
                }).subscribe((response: any): void => {
                    if (response && response.data) {
                        let new_document: DocumentModel;
                        new_document = new DocumentModel();
                        new_document.clone(response.data);
                        if (!this.current_item.modelable.documents) {
                            this.current_item.modelable.documents = [];
                        }
                        this.current_item.modelable.documents.push(new_document);
                    } else {
                        //
                    }
                    resolve();
                }, error => {
                    reject();
                });
            } else {
                this.current_item.data = data;
                console.log(this.current_item.data);
                resolve();
            }
        });
        return promise;
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    public deletePhotoData(item: any): void {
        if (item && item.data) {
            this.swal.confirm('คุณต้องการลบรูปภาพสินค้าใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        delete item.data;
                    }
                });
        }
    }

    public deletePhoto(document: DocumentModel): void {
        if (document && document.id) {
            this.swal.confirm('คุณต้องการลบรูปภาพสินค้าใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('items/document/delete', 'POST', {}, {
                            id: document.id
                        }).subscribe((response: any): void => {
                            if (this.current_item && this.current_item.modelable) {
                                this.current_item.modelable.documents.splice(0, this.current_item.modelable.documents.length);
                            }
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            //
        }
    }

    public viewDocumentDetail(doc: DocumentModel, index?: number, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DocumentDetailComponent, {
            document: doc,
            deletable: true,
            // product: this.current_product,
            // task: this.task,
            document_index: index
        }, { backdrop: true, ignoreBackdropClick: true })
            .then((data: any): void => {
                if (data && data.deleted === true) {
                    this.current_item.documents.splice(index, 1);
                }
            });
    }

    public viewDocument(doc: DocumentModel): void {
        this.viewer.document(doc);
    }

    public onKeywordInput(e: any): void {
        //
    }

    public viewNO(): void {
        if (this.current_item && this.current_item.id) {
            this.viewer.itemNO(this.current_item);
        } else {
            console.warn(this.current_item);
        }
    }

    public generateNO(): void {
        if (this.is_store) {
            if (this.current_item && this.current_item.id) {
                this.swal.confirm('ยืนยันการออกรหัสในคลังเก็บวัสดุ "' + this.current_item.name + '" ใช่หรือไม่?')
                    .then((result: boolean): void => {
                        if (result === true) {
                            this.storeService.genNO(this.current_item)
                                .then((item_no: string): void => {
                                    this.swal.success('ออกรหัสในคลังเก็บวัสดุ "' + item_no + '" สำเร็จ');
                                    this.onItemGenNO.emit(item_no);
                                    this.refresh();
                                });
                        }
                    });
            } else {
                console.warn(this.current_item);
            }
        }
    }

    public refresh(item?: any): void {
        if (item && item.id) {
            this.current_item.id = item.id;
        }
        if (this.current_item && this.current_item.id) {
            this.getItem(this.current_item.id)
                .then((data: any): void => {
                    this.current_item.clone(data);
                });
        } else {
            console.warn(this.current_item);
        }
    }

    public getItem(id: string | number): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('store/items/view/' + id, 'GET', {}, {
                //
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public submit(form: NgForm): void {
        form.ngSubmit.emit();
    }

    public parse_warehouses_item(item: ItemModel): any[] {
        let data: any[];
        data = [];
        for (let i = 0; i < item.warehouses.length; i++) {
            const warehouse = item.warehouses[i];
            if (warehouse.id) {
                data.push({
                    warehouse_id    : warehouse.id,
                    shelf_id        : (item && item.shelves && item.shelves[0] && item.shelves[0].id) ? item.shelves[0].id : (this.shelf && this.shelf.id) ? this.shelf.id : null,
                    amount          : item.amount
                });
            }
        }
        return data;
    }

    private unsub(): void {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        } else {
            //
        }
    }

    public clearSearchItems(): void {
        if (this.search_items) {
            this.search_items.splice(0, this.search_items.length);
        } else {
            this.search_items = [];
        }
    }

    public onModelChange(field: string, e: any): void {
        this.unsub();
        const exists_index: number = this.search_fields.indexOf(field);
        if (e) {
            if (exists_index > -1) {
                this.search_values[exists_index] = e;
            } else {
                this.search_fields.push(field);
                this.search_values.push(e);
            }
        } else {
            if (exists_index > -1) {
                this.search_values.splice(exists_index, 1);
                this.search_fields.splice(exists_index, 1);
            } else {
                //
            }
        }
        if (this.search_fields && this.search_fields.length) {
            this.onGetting.emit();
            this.onSearching.emit(true);
            this.clearSearchItems();
            this.clear_timeout();
            this.timeout = setTimeout(() => {
                this.sub = this.searchStoreItems()
                    .subscribe((response: any): void => {
                        let total_items: number;
                        if (response.meta) {
                            total_items = response.meta.total_items;
                        } else {
                            total_items = response.total_items;
                        }
                        if (total_items > 0) {
                            for (let i = 0; i < response.data.length; i++) {
                                const dat: any = response.data[i];
                                if (dat) {
                                    let item: ItemModel;
                                    item = new ItemModel();
                                    item.clone(dat);
                                    this.search_items.push(item);
                                }
                            }
                            this.clear_timeout();
                            this.onGot.emit();
                            this.onFilterItems.emit(this.search_items);
                            this.unsub();
                        } else {
                            this.clear_timeout();
                            this.onGot.emit();
                        }
                    }, error => {
                        this.clear_timeout();
                        this.onGot.emit();
                    });
            }, 300);
        } else {
            this.onSearching.emit(false);
        }
    }

    public searchStoreItems(): Observable<any> {
        return this.api.request('store/items/' + this.type, 'GET', {
            find: this.search_values.join(','),
            field: this.search_fields.join(',')
        });
    }

    public cancelSearch(): void {
        this.createItem();
        this.tmp_current_item = null;
        this.search_values.splice(0, this.search_values.length);
        this.search_fields.splice(0, this.search_fields.length);
        this.unsub();
        setTimeout(() => {
            this.onSearching.emit(false);
        }, 300);
    }

    public itemFormSubmit(form: NgForm): void {
        if (!this.current_item.name) {
            this.swal.danger('กรุณากรอกชื่อสินค้า');
        } else {
            // const is_new: boolean = this.current_item.isNew;
            if (this.current_item.name && this.current_item.modelable && !this.current_item.modelable.name) {
                this.current_item.modelable.name = this.current_item.name;
            }
            this.current_item.warehouse_ids = this.parse_warehouses_item(this.current_item);
            this.onItemFormSubmit.emit(this.current_item);
        }
    }

    public onVendorChange(modelable: any, e?: any): void {
        if (modelable) {
            modelable.vendor_id = (e && e.id) ? e.id : null;
        }
    }

    public onCustomerChange(modelable: any, e?: any): void {
        if (modelable) {
            modelable.customer_id = (e && e.id) ? e.id : null;
        }
    }

    /*public createOrUpdate(is_new?: boolean): Promise<any> {
        let promise: Promise<any>;
        promise = new Promise<any>((resolve, reject) => {
            this.modelApi.createOrUpdate(this.current_item, [
                'modelable', 'type', 'name', 'name_en', 'ref_no', 'type', 'amount', 'width', 'height', 'unit', 'width', 'ref_item_no',
                'length', 'weight', 'warehouse_ids', 'material_type', 'tooth', 'size', 'vendor_id',
                'price', 'remark', 'grade', 'surface', 'material', 'thickness', 'outer_diameter', 'inner_diameter'
            ], 'store/items', null, null, false)
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        if (is_new === true) {
                            this.swal.success('เพิ่มรายการสำเร็จ');
                            this.current_item = new ItemModel();
                            this.current_item.id = response.data.id;
                            // this.current_item.type = this.type;
                            this.tmp_current_item = null;
                        } else {
                            this.swal.success('แก้ไขรายการสำเร็จ');
                            this.current_item.search_value = response.data.search_value;
                        }
                        setTimeout(() => {
                            this.cancelSearch();
                        }, 300);
                    }
                    resolve();
                });
        });
        return promise;
    }*/

    public onSelect(e?: any): void {
        //
    }

    public onDelete(e?: any): void {
        this.createItem();
    }

    public onSelectWarehouse(warehouse: WarehouseModel, i: number, e: any): void {
        this.ngZone.run(() => {
            setTimeout(() => {
                this.current_item.warehouses[i].id = (e && e.id) ? e.id : null;
                if (this.current_item.warehouses[i].id) {
                    if (!this.current_item.shelves || !this.current_item.shelves.length) {
                        this.current_item.shelves = [new ShelfModel()];
                    }
                } else {
                    //
                }
            }, 300);
        });
    }

    public onShelfChange(e: any): void {
        this.current_item.shelves[0].id = (e && e.id) ? e.id : null;
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public get is_store(): boolean {
        if (this.userService.roles && this.userService.roles.indexOf('store') > -1) {
            return true;
        }
        return false;
    }

}
