import {Injectable} from '@angular/core';
import {Api} from '../now/api/api';
import {IncotermModel} from '../models/incoterm.model';

@Injectable()
export class IncotermService {

    private incoterms: IncotermModel[];

    constructor(private api: Api) {
        this.reset_incoterm_list();
    }

    public set(list: IncotermModel[]): void {
        this.reset_incoterm_list();
        if (list) {
            for (let i = 0; i < list.length; i++) {
                let dat: any;
                dat = list[i];
                if (dat) {
                    const incoterm: IncotermModel = new IncotermModel();
                    incoterm.clone(dat);
                    this.incoterms.push(incoterm);
                }
            }
        }
    }

    public getIncoterms(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.incoterms && this.incoterms.length > 0) {
                for (let i = 0; i < this.incoterms.length; i++) {
                    this.incoterms[i].checked = false;
                }
                resolve(this.incoterms);
            } else {
                this.api.request('incoterms', 'GET', {}, {})
                    .subscribe((response: any): void => {
                        this.reset_incoterm_list();
                        if (response && response.data) {
                            for (let i = 0; i < response.data.length; i++) {
                                let dat: any;
                                dat = response.data[i];
                                if (dat) {
                                    this.incoterms.push(dat);
                                }
                            }
                            resolve(this.incoterms);
                        } else {
                            reject([]);
                        }
                    }, error => {
                        reject([]);
                    });
            }
        });
        return promise;
    }

    private reset_incoterm_list(): void {
        if (this.incoterms) {
            this.incoterms.splice(0, this.incoterms.length);
        } else {
            this.incoterms = [];
        }
    }

}
