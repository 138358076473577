import {FormModel} from '../now/formModel';

export class CuttingToolModel extends FormModel {

    public cutting_tool_no: string;
    public name: string;
    public description: string;
    public price: number;
    public ranking: number;

    constructor() {
        super('cuttingtools');
    }

}
