import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {InvoiceModel} from '../../../models/invoice.model';
import {environment} from '../../../environments/environment';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import {Api} from '../../../now/api/api';
import * as moment from 'moment';
import {LoaderService} from '../../../components/loader/loader.service';
import {Viewer} from '../../../services/viewer';
import {CustomerModel} from '../../../models/customer.model';

@Component({
    selector: 'invoice-list-component',
    templateUrl: 'invoiceList.component.html',
    styleUrls: ['invoiceList.component.scss']
})
export class InvoiceListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public timeout: any;
    public sub: any;

    public sum_grand_total: number;
    public total_items: number;
    public invoices: InvoiceModel[];
    public keyword: string;

    public date: any;
    public tmp_date: string;
    public current_view: string;
    public tmp_customer: any;

    public customer_id: any;
    public customer_name: string;
    public started_at: string;

    constructor(
        public viewer: Viewer,
        private api: Api,
        private loader: LoaderService
    ) {
        //
        super();
        this.invoices = [];
        this.total_items = 0;
        this.current_view = 'MONTHLY';
        this.date = moment(new Date());
        this.tmp_date = this.date.format('MM/YYYY');
        this.started_at = this.tmp_date;
        this.sum_grand_total = 0;
        this.customer_name = '';
        this.customer_id = '';
        this.tmp_customer = {
            name: ''
        };
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public viewInvoice(invoice: any): void {
        this.viewer.invoice(invoice);
    }

    public viewProduct(product: any): void {
        this.viewer.product(product);
    }

    public onCustomerChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e) {
            this.loader.show();
            // this.customer_name = (e && e.id && e.name) ? e.name : '';
            this.customer_id = (e && e.id) ? e.id : '';
            this.divApi.refresh({
                // find: this.customer_name,
                customer_id: this.customer_id,
                started_at: this.started_at,
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }
    }

    public onDateChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e) {
            this.loader.show();
            this.keyword = '';
            let started_at: string;
            if (this.current_view === 'DAILY') {
                started_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
            } else if (this.current_view === 'MONTHLY') {
                started_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
            } else if (this.current_view === 'YEARLY') {
                started_at = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
            }
            this.started_at = started_at;
            this.divApi.refresh({
                // find: this.customer_name,
                customer_id: this.customer_id,
                started_at: started_at,
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        } else {
            //
        }
    }

    public viewContactRef(invoice: InvoiceModel): void {
        const path: string = environment.api_host + 'view/' + invoice.requirement_id + '/contact';
        window.open(path, '_blank');
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }, 1000);
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_invoices();
            this.sum_grand_total = response.sum_grand_total;
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let invoice: InvoiceModel;
                    invoice = new InvoiceModel();
                    const customer = new CustomerModel();
                    if (dat && dat.customer) {
                        customer.clone(dat.customer);
                    }
                    invoice.clone(dat);
                    invoice.customer = customer;
                    this.invoices.push(invoice);
                }
            }
            this.loader.hide();
        }
    }

    public onError(e: any): void {
        this.loader.hide();
    }

    private clear_invoices(): void {
        if (this.invoices) {
            this.invoices.splice(0, this.invoices.length);
        } else {
            this.invoices = [];
        }
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

}
