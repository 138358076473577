import {AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {DivApiDirective} from '../../../now/divApi';
import {FullCalendarComponent} from '../../../components/fullCalendar/fullCalendar.component';
import {UserModel} from '../../../now/user/user.model';
import {ProductModel} from '../../../models/product.model';
import {JobModel} from '../../../models/job.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../now/user/auth.service';
import {TaskService} from '../../../services/task.service';
import {Api} from '../../../now/api/api';
import {ModalService} from '../../../services/modal.service';
import {UserService} from '../../../services/user.service';
import {SwalService} from '../../../services/swal.service';
import {DOCUMENT, Location} from '@angular/common';
import {TaskModel} from '../../../models/task.model';
import {environment} from '../../../environments/environment';
import {ViewTask} from '../view/viewTask';
import {PageScrollService} from 'ngx-page-scroll-core';
import {QuotationModel} from '../../../models/quotation.model';
import {DepositModel} from '../../../models/deposit.model';
import {DepositDetailComponent} from '../view/depositDetail/depositDetail.component';
import {PaymentModel} from '../../../models/payment.model';
import {PurchaseOrderDetailComponent} from '../view/purchaseOrderDetail/purchaseOrderDetail.component';
import {InvoiceModel} from '../../../models/invoice.model';
import {InvoiceDetailComponent} from '../view/invoiceDetail/invoiceDetail.component';
import {TaxInvoiceModel} from '../../../models/taxInvoice.model';
import {ModelApi} from '../../../now/modelApi/modelApi';
import {TaxInvoiceDetailComponent} from '../view/taxInvoiceDetail/taxInvoiceDetail.component';
import {ReceiptModel} from '../../../models/receipt.model';
import {ReceiptDetailComponent} from '../view/receiptDetail/receiptDetail.component';
import {Viewer} from '../../../services/viewer';

@Component({
    selector: 'accounting-task-component',
    templateUrl: 'accountingTask.component.html',
    styleUrls: ['accountingTask.component.scss']
})
export class AccountingTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: false }) divApi: DivApiDirective;
    @ViewChild(FullCalendarComponent, { static: false }) fullCalendarComponent: FullCalendarComponent;

    public pdfInfo: any;
    public numPages: number[];
    public quotation_path: string;
    public quotation_id: string;
    public quotation: QuotationModel;
    public is_error: boolean;
    public is_loading: boolean;
    public task_id: string;
    public current_user: UserModel;
    public user: UserModel;
    public products: ProductModel[];
    public job_id: string;
    public job: JobModel;
    public pdf_path: string;
    public pdf_title: string;

    constructor(
        public viewer: Viewer,
        public route: ActivatedRoute,
        public router: Router,
        public authService: AuthService,
        public taskService: TaskService,
        public ngZone: NgZone,
        public api: Api,
        public modelApi: ModelApi,
        public pageScrollService: PageScrollService,
        public userService: UserService,
        public swal: SwalService,
        public location: Location,
        public modal: ModalService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });
    }

    private init(): void {
        this.task = new TaskModel();
        this.quotation = new QuotationModel();
        this.job = new JobModel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.viewTaskInit();
                    this.init();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                if (this.task.modelable_type === 'App\\Quotation') {
                                    this.quotation_id = this.task.modelable_id;
                                    this.getQuotation()
                                        .then(() => {
                                            this.quotation_path = environment.api_host + 'view/' + this.quotation_id + '/quotation';
                                            this.quotation_path += '?q=true';
                                            this.current_tab = '#quotation';
                                        });
                                } else if (this.task.modelable_type === 'App\\Job') {
                                    this.job_id = this.task.modelable_id;
                                    this.getJob(this.job_id)
                                        .then(() => {
                                            this.current_tab = '#quotation';
                                        });
                                }
                            });
                    }
                });
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public getJob(job_id: string): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('jobs/' + job_id, 'GET')
                .subscribe((response: any): void => {
                    if (response && response.success === true) {
                        this.job.clone(response.data);
                        if (this.job.ncr_equipments) {
                            //
                        } else {
                            this.job.ncr_equipments = [
                                '', '', '', '', ''
                            ];
                        }
                        resolve();
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PurchaseOrderDetailComponent, {
            payment     : payment,
            quotation   : this.quotation,
            task        : this.task,
            products    : this.quotation.products
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.checked_products && content.is_submit === true) {
                    if (payment.verified === true) {
                        this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order')
                            .then(() => {
                                //
                            });
                    } else {
                        //
                    }
                } else {
                    //
                }
            });
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('quotations/payment', 'PUT', {}, {
            document: data,
            id: this.quotation.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.quotation.payments.push(new_payment);
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public sendToSalAdmin(): void {
        if (this.quotation
            && this.quotation.payments
            && this.quotation.payments.length > 0
            && (this.quotation.deposits.length > 0 || this.quotation.tax_invoices.length > 0)) {
            //
            this.swal.confirm('ยืนยันการดำเนินการให้ขั้นตอนต่อไปใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.taskService.setStatus(this.task, 2, 'send_quotation', 'proforma', 'quotation')
                            .then((task_response: any): void => {
                                this.leave(true);
                            });
                    } else {
                        //
                    }
                });
        } else {
            this.swal.danger('ไม่สามารถดำเนินการได้ เนื่องจากยังไม่ได้รับหลักฐานการสั่งซื้อ, ' +
                'ยังไม่ได้ออกใบวางเงินมัดจำ หรือใบเสร็จรับเงิน กรุณาตรวจสอบอีกครั้ง');
        }
    }

    public viewInvoiceDetail(invoice: InvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(InvoiceDetailComponent, {
            invoice     : invoice,
            quotation   : this.quotation,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewDepositDetail(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            quotation   : this.quotation,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewReceiptDetail(receipt: ReceiptModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(ReceiptDetailComponent, {
            receipt     : receipt,
            quotation   : this.quotation,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewTaxInvoiceDetail(tax_invoice: TaxInvoiceModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(TaxInvoiceDetailComponent, {
            tax_invoice : tax_invoice,
            quotation   : this.quotation,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public getQuotation(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.quotation_id) {
                this.api.request('quotations/' + this.quotation_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            this.quotation.clone(response.data);
                        }
                        resolve(this.quotation);
                    }, error => {
                        reject(error);
                    });
            } else {
                //
                resolve(this.quotation);
            }
        });
        return promise;
    }

    public onError(e: any): void {
        //
    }

    public onPdfProgress(e: any): void {
        this.is_error = false;
        this.is_loading = true;
    }

    public onPdfLoadCompleted(e: any): void {
        this.is_error = false;
        this.is_loading = false;
    }

    // ใบแจ้งหนี้
    public createInvoice(): void {
        let invoice: InvoiceModel;
        invoice = new InvoiceModel();
        this.modal.show(InvoiceDetailComponent, {
            invoice     : invoice,
            quotation   : this.quotation,
            task        : this.task,
            job         : this.job,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    invoice = content.invoice;
                    this.quotation.invoices.push(invoice);
                } else {
                    //
                }
            });
    }

    // ใบเสร็จรับเงิน
    public createReceipt(): void {
        let receipt: ReceiptModel;
        receipt = new ReceiptModel();
        this.modal.show(ReceiptDetailComponent, {
            receipt     : receipt,
            quotation   : this.quotation,
            task        : this.task,
            job         : this.job,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    receipt = content.receipt;
                    this.quotation.receipts.push(receipt);
                } else {
                    //
                }
            });
    }

    // ใบมัดจำ
    public createDeposit(): void {
        let deposit: DepositModel;
        deposit = new DepositModel();
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            quotation   : this.quotation,
            task        : this.task,
            job         : this.job,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    deposit = content.deposit;
                    this.quotation.deposits.push(deposit);
                } else {
                    //
                }
            });
    }

    // ใบกำกับภาษี
    public createTaxInvoice(): void {
        let tax_invoice: TaxInvoiceModel;
        tax_invoice = new TaxInvoiceModel();
        this.modal.show(TaxInvoiceDetailComponent, {
            tax_invoice : tax_invoice,
            quotation   : this.quotation,
            task        : this.task,
            job         : this.job,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    tax_invoice = content.tax_invoice;
                    this.quotation.tax_invoices.push(tax_invoice);
                } else {
                    //
                }
            });
    }

}
