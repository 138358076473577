import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {Api} from '../../../../now/api/api';
import {QuotationModel} from '../../../../models/quotation.model';
import {SwalService} from '../../../../services/swal.service';
import {TaskModel} from '../../../../models/task.model';
import * as moment from 'moment';
import {InvoiceModel} from '../../../../models/invoice.model';
import {CustomerModel} from '../../../../models/customer.model';
import {JobModel} from '../../../../models/job.model';
import {ProductModel} from '../../../../models/product.model';
import {Viewer} from '../../../../services/viewer';

@Component({
    selector: 'app-invoice-detail-component',
    templateUrl: 'invoiceDetail.component.html',
    styleUrls: ['invoiceDetail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {

    public job: JobModel;
    public invoice: InvoiceModel;
    public tmp_invoice: InvoiceModel;
    public credit_terms: number;
    public products: ProductModel[];
    public model: any;
    public percent: number;
    public task: TaskModel;
    public quotation: QuotationModel;
    public customer: CustomerModel;

    public is_new: boolean;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService,
        public viewer: Viewer
    ) {
        //
        this.invoice = new InvoiceModel();
        this.tmp_invoice = new InvoiceModel();
        this.quotation = new QuotationModel();
        // this.customer = this.quotation.customer;
        this.products = [];
        if (this.model && this.model.customer) {
            this.customer = this.model.customer;
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.model) {
                this.model = this.bsModalRef.content.model;
                if (this.model && this.model.jobs) {
                    for (const job of this.model.jobs) {
                        if (job && job.id) {
                            job._checked = true;
                        }
                    }
                }
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.invoice) {
                // if (!this.bsModalRef.content.invoice.started_at) {
                //     this.bsModalRef.content.invoice.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                // }
                this.invoice = this.bsModalRef.content.invoice;
                this.tmp_invoice.started_at = this.invoice.started_at;
                this.tmp_invoice.id = this.invoice.id;
                this.tmp_invoice.invoice_no = this.invoice.invoice_no;
                this.tmp_invoice.remark = this.invoice.remark;
                this.tmp_invoice.status = (this.invoice.status) ? this.invoice.status : 2;
                this.tmp_invoice.grand_total = this.invoice.grand_total;

                if (!this.tmp_invoice.started_at) {
                    this.tmp_invoice.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }

                this.is_new = this.invoice.isNew;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.job) {
                this.job = this.bsModalRef.content.job;
            }
        }, 0);
    }

    public onStartedChanged(e: any): void {
        if (e) {
            const started_moment_object: any = moment(e, 'YYYY-MM-DD HH:mm:ss');
            const ended_moment_object: any = moment(this.tmp_invoice.ended_at, 'YYYY-MM-DD HH:mm:ss');
            this.credit_terms = moment.duration(ended_moment_object.diff(started_moment_object)).asDays();
        }
    }

    public delete(): void {
        if (this.invoice.status === 1) {
            return;
        }
        const name: string = (this.tmp_invoice.invoice_no)
            ? this.tmp_invoice.invoice_no : 'ใบวางเงินมัดจำ';
        this.swal.confirm('คุณต้องการลบเอกสารใบสั่งซื้อ "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.modelApi.delete(this.tmp_invoice)
                        .subscribe((response: any): void => {
                            const index: number = this.model.invoices.findIndex(i => i.id === this.tmp_invoice.id);
                            if (index > -1) {
                                this.model.invoices.splice(index, 1);
                            }
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                } else {
                    //
                }
            });
    }

    public complete(): void {
        this.swal.confirm('ยืนยันการออกใบแจ้งหนี้ใช่หรือไม่?')
            .then((result: boolean): void => {
                if (result === true) {
                    this.save()
                        .then(() => {
                            this.bsModalRef.content.invoice = this.invoice;
                            this.bsModalRef.content.is_submit = this.is_new;
                            this.bsModalRef.hide();
                        });
                } else {
                    //
                }
            });
    }

    public update(): void {
        if (this.tmp_invoice && !this.tmp_invoice.id) {
            this.swal.confirm('ยืนยันการออกใบแจ้งหนี้ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('invoices', 'PUT', {}, {
                            customer_id: this.model.customer_id,
                            model_id: this.model.id,
                            // tax_invoice_id: this.model.id,
                            grand_total: this.tmp_invoice.grand_total,
                            bill_id: this.model.bill_id,
                            customer_address_id: this.model.customer_address_id,
                            contact_id: this.model.contact_id,
                            incoterm_code: this.model.incoterm_code,
                            status: this.tmp_invoice.status,
                            started_at: this.tmp_invoice.started_at,
                            total_price: this.tmp_invoice.total_price,
                            remark: this.tmp_invoice.remark,
                            jobs: this.get_jobs()
                        }).subscribe((response: any): void => {
                            this.swal.success('ออกใบแจ้งหนี้สำเร็จ');
                            this.bsModalRef.content.invoice = response.data;
                            this.bsModalRef.content.is_create = true;
                            this.bsModalRef.content.submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        } else {
            this.swal.confirm('ยืนยันการแก้ไขรายละเอียดเอกสารใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('invoices', 'POST', {}, {
                            id: this.tmp_invoice.id,
                            status: this.tmp_invoice.status,
                        }).subscribe((response: any): void => {
                            this.swal.success('แก้ไขรายละเอียดเอกสารสำเร็จ');
                            this.bsModalRef.content.invoice.status = this.tmp_invoice.status;
                            this.bsModalRef.content.is_submit = true;
                            this.bsModalRef.hide();
                        }, error => {
                            //
                        });
                    }
                });
        }
    }

    public viewInvoice(): void {
        this.viewer.invoice(this.tmp_invoice);
    }

    private get_jobs(): any {
        let job_ids: any;
        job_ids = {};
        if (this.model && this.model.jobs) {
            for (const job of this.model.jobs) {
                if (job && job._checked) {
                    job_ids[job.id] = {
                        amount: job.pivot.amount,
                        price_per_unit: job.pivot.price_per_unit
                    };
                }
            }
        }
        return job_ids;
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const product_ids: any[] = this.get_product_ids();
            this.tmp_invoice.product_ids = product_ids;
            if (this.model && this.model.model_name === 'App\\Quotation') {
                this.tmp_invoice.quotation_id = this.model.id;
            } else if (this.model && this.model.model_name === 'App\\TaxInvoice') {
                this.tmp_invoice.tax_invoice_id = this.model.id;
            }
            this.tmp_invoice.customer_id = this.model.customer_id;
            this.modelApi.createOrUpdate(this.tmp_invoice, [
                'job_id', 'total_price', 'started_at', 'ended_at', 'verified', 'quotation_id', 'tax_invoice_id',
                'credit_terms', 'currency_rate', 'currency', 'customer_id', 'remark', 'status'
            ]).subscribe((response: any): void => {
                this.invoice.id = this.tmp_invoice.id;
                this.invoice.remark = this.tmp_invoice.remark;
                this.invoice.total_price = this.tmp_invoice.total_price;
                this.invoice.invoice_no = this.tmp_invoice.invoice_no;
                this.invoice.started_at = this.tmp_invoice.started_at;
                this.invoice.ended_at = this.tmp_invoice.ended_at;
                this.invoice.verified = this.tmp_invoice.verified;
                this.invoice.user = this.tmp_invoice.user;
                this.invoice.status = this.tmp_invoice.status;
                resolve();
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private get_product_ids(): any[] {
        let product_ids: any[];
        product_ids = [];
        for (let i = 0; i < this.products.length; i++) {
            product_ids.push(this.products[i].id);
        }
        return product_ids;
    }

    public onSubmit(form: NgForm): void {
        if (this.invoice.status === 1) {
            return;
        } else if (form.valid) {
            this.save()
                .then(() => {
                    this.swal.success('บันทักข้อมูลใบวางเงินมัดจำสำเร็จ');
                    this.bsModalRef.content.is_submit = this.is_new;
                    this.bsModalRef.hide();
                });
        } else {
            //
        }
    }

}
