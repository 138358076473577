import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MachineModel} from '../../../models/machine.model';
import {Router} from '@angular/router';
import {AuthService} from '../../../now/user/auth.service';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';

@Component({
    selector: 'machine-list-component',
    templateUrl: 'machineList.component.html',
    styleUrls: ['machineList.component.scss']
})
export class MachineListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sub: any;
    public timeout: any;
    public machines: MachineModel[];
    public keyword: string;
    public total_machines;

    constructor(private router: Router, private authService: AuthService) {
        super();
        this.machines = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(response => {
                this.clear_timeout();
            }, error => {
                console.error(error);
                this.clear_timeout();
            }, () => {
                console.log('completed');
            });
        }, 1000);
    }

    private clear_timeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_machines = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_machines();
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let machine: MachineModel;
                    machine = new MachineModel();
                    machine.clone(dat);
                    this.machines.push(machine);
                }
            }
        }
        console.log(this.machines);
        console.log(response);
    }

    public onError(e: any): void {
        //
    }

    public gotoMachineView(machine: MachineModel): void {
        this.router.navigateByUrl('/database/machine/' + machine.id + '/detail');
    }

    private clear_machines(): void {
        if (this.machines) {
            this.machines.splice(0, this.machines.length);
        } else {
            this.machines = [];
        }
    }

}
