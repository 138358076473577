import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'materialStatus'
})
export class MaterialStatusPipe implements PipeTransform {

    transform(status: number): string {
        if (status === 1) {
            return 'เครื่องว่าง';
        } else if (status === 2) {
            return 'ส่งซ่อม';
        } else if (status === 3) {
            return 'กำลังปรับปรุง';
        } else if (status === 4) {
            return 'เสีย';
        }
        return 'UNKNOW ' + status;
    }

}
