import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'machineStatus'
})
export class MachineStatusPipe implements PipeTransform {

    transform(status: number): string {
        if (status === 1) {
            return 'พร้อมใช้งาน';
        } else if (status === 2) {
            return 'ส่งซ่อม';
        } else if (status === 3) {
            return 'กำลังปรับปรุง';
        } else if (status === 4) {
            return 'เสีย';
        } else {
            return 'เครื่องจักรใหม่';
        }
    }

}
