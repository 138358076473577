import {AfterViewInit, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../app/app.service';
import {Api} from '../../now/api/api';
import {PusherService} from '../../services/pusher.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

export const MAX_MAN = 5;

@Component({
    selector: 'app-home-component',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

    private apiSubscription: Subscription;
    public parent_role: any;
    public user_roles: any[];
    public max_man: number;
    public slug: string;

    constructor(
        private appService: AppService,
        private api: Api,
        private route: ActivatedRoute,
        private location: Location,
        private pusherService: PusherService,
        private userService: UserService,
        private ngZone: NgZone
    ) {
        //
        this.max_man = MAX_MAN;
        this.user_roles = [];
        this.parent_role = {};

        this.route.params.subscribe(params => {
            this.slug = params['slug'];
            const promise_list: Promise<Object>[] = [
                this.getUserRoles(),
                this.getUserTasks()
            ];
            Promise.all(promise_list)
                .then((response: any[]): void => {
                    if (response && response[1]) {
                        this.render(response[1]);
                    }
                }, error => {
                    //
                });
        });
    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    public getRole(slug: string, data: any[]): any {
        let find_role: any;
        find_role = data.find(i => i.name === slug);
        if (find_role) {
            return find_role;
        } else if (data && data.length) {
            for (let i = 0; i < data.length; i++) {
                const dat: any = data[i];
                if (dat && dat.children && dat.children.length > 0) {
                    return this.getRole(slug, dat.children);
                } else {
                    //
                }
            }
        }
        return null;
    }

    private getUserRoles(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('users/roles', 'GET', {
                parent: (this.slug) ? this.slug : ''
            }).subscribe((response: any): void => {
                if (response && response.data) {
                    if (this.slug) {
                        const role: any = this.getRole(this.slug, response.data);
                        if (role) {
                            this.parent_role = role;
                            this.user_roles = role.children;
                        } else {
                            this.parent_role = {
                                display_name: 'หน้าหลัก'
                            };
                            this.user_roles = response.data;
                        }
                    } else {
                        this.parent_role = {
                            display_name: 'หน้าหลัก'
                        };
                        this.user_roles = response.data;
                    }
                }
                resolve();
            }, error => {
                reject();
            });
        });
        return promise;
    }

    ngOnDestroy(): void {
        // this.pusherService.unsubscribe('erpst.user_tasks');
    }

    private unsubscribe(): void {
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
            this.apiSubscription = null;
        }
    }

    private getUserTasks(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.unsubscribe();
            this.apiSubscription = this.api.request('tasks/users', 'GET')
                .subscribe((response: any): void => {
                    if (response && response.data) {
                        this.unsubscribe();
                        resolve(response.data);
                    } else {
                        reject(response);
                    }
                }, error => {
                    this.unsubscribe();
                    reject(error);
                });
        });
        return promise;
    }

    public setupNewest(roles: any[]): void {
        const datetime_now: any = moment(new Date());
        for (let i = 0; i < roles.length; i++) {
            const role: any = roles[i];
            const tasks: any[] = role.tasks;
            for (let k = 0; k < tasks.length; k++) {
                if (tasks[k] && tasks[k].status_date) {
                    const status_date: any = moment(tasks[k].status_date);
                    const asMinutes: number = moment.duration(datetime_now.diff(status_date)).asMinutes();
                    if (role && !role.newest) {
                        role.newest = (asMinutes > 0 && asMinutes < 8) ? true : false;
                        if (role.newest === true) {
                            break;
                        }
                    }
                } else if (role && !role.newest) {
                    role.newest = false;
                }
            }
        }
    }

    public setupRooms(roles: any[], data: any, parent?: any): void {
        for (let i = 0; i < roles.length; i++) {
            const user_role: any = roles[i];
            if (user_role && user_role.name) {
                let dat: any;
                let dat_handles: any;
                dat = (data && data.tasks && data.tasks[user_role.name]) ? data.tasks[user_role.name] : [];
                dat_handles = (data && data.task_handles && data.task_handles[user_role.name]) ? data.task_handles[user_role.name] : [];
                user_role.tasks = (user_role.tasks && user_role.tasks.length > 0)
                    ? user_role.tasks.concat((dat) ? dat : []) : (dat) ? dat : [];
                user_role.task_handles = (user_role.task_handles && user_role.task_handles.length > 0)
                    ? user_role.task_handles.concat((dat_handles) ? dat_handles : []) : (dat_handles) ? dat_handles : [];
                user_role.num_tasks = user_role.tasks.length;
                if (parent) {
                    parent.num_tasks += user_role.num_tasks;
                    parent.tasks = parent.tasks.concat(user_role.tasks);
                }
                if (user_role.children && user_role.children.length > 0) {
                    this.setupRooms(user_role.children, data, (parent) ? parent : user_role);
                }
            }
        }
    }

    private render(data: any): void {
        this.ngZone.run(() => {
            this.setupRooms(this.user_roles, data);
            this.setupNewest(this.user_roles);
        });
    }

}
