import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FVD} from '../../app/api/fvd';
import {ProductModel} from '../../models/product.model';
import {JobModel} from '../../models/job.model';
import {ProcessModel} from '../../models/process.model';
import {ProductProcessModel} from '../../models/productProcess.model';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {TaskModel} from '../../models/task.model';
import {UserModel} from '../../now/user/user.model';
import {MachineModel} from '../../models/machine.model';

@Component({
    selector: 'fvd-modal',
    templateUrl: 'fvd.modal.html',
    styleUrls: ['fvd.modal.scss']
})
export class FVDModal implements AfterViewInit {

    @Output('onSubmit') onFVDModalSubmit: EventEmitter<FVD> = new EventEmitter<FVD>();
    @Output('onCancel') onFVDModalCancel: EventEmitter<any> = new EventEmitter<any>();

    @Input('job') job: JobModel;
    @Input('product') product: ProductModel;
    @Input('processes') processes: ProcessModel[];
    @Input('product-process') product_process: ProductProcessModel;
    @Input('fvd') fvd: FVD;
    @Input('task') task: TaskModel;
    @Input('editable') editable: boolean;

    constructor(
        private smartModal: NgxSmartModalService
    ) {
        //
    }

    ngAfterViewInit(): void {
        //
    }

    public onFVDCancel(fvd?: FVD): void {
        if (this.product_process && this.product_process.id) {
            this.product_process.is_fvd = false;
            this.product_process.fvd = null;
            this.product_process.fvd_id = null;
        }
        this.smartModal.close('fvdModal');
        this.onFVDModalCancel.emit();
    }

    public onFVDSubmit(fvd: FVD): void {
        if (fvd && fvd.id && this.product_process) {
            this.product_process.is_fvd = true;
            this.product_process.fvd    = fvd;
            this.product_process.fvd_id = fvd.id;

            this.product_process.user_id    = null;
            this.product_process.user       = new UserModel();
            this.product_process.machine_id = null;
            this.product_process.machine    = new MachineModel();

            this.product_process.process    = fvd.process;
            this.product_process.process_id = fvd.process_id;

            this.product_process.modelable      = fvd.modelable;
            this.product_process.modelable_id   = fvd.modelable_id;
            this.product_process.modelable_type = fvd.modelable_type;

            this.smartModal.close('fvdModal');
            this.onFVDModalSubmit.emit(this.fvd);
        } else {
            //
        }
    }

}
