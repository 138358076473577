import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {DivApiDirective} from '../../../now/divApi';
import {LoaderService} from '../../../components/loader/loader.service';
import {Api} from '../../../now/api/api';
import {map} from 'rxjs/operators';
import {AppService} from '../../../app/app.service';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);
import HC_solidgauge from 'highcharts/modules/solid-gauge';
import {DecimalPipe} from '@angular/common';
import {CustomerModel} from '../../../models/customer.model';
HC_solidgauge(Highcharts);

@Component({
    selector: 'store-report-page',
    templateUrl: 'storeReport.page.html',
    styleUrls: ['storeReport.page.scss'],
    providers: [DecimalPipe]
})
export class StoreReportPage implements AfterViewInit, OnDestroy {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;
    public divApi: DivApiDirective;

    public date1: any;
    public date2: any;
    public customer_id: any;
    public getting: boolean;

    public rowData1: any;
    public rowData2: any;
    public rowData3: any;
    public rowData4: any;
    public sum_data1: number;
    public sum_data2: number;
    public sum_data3: number;
    public sum_data4: number;
    public sum_data5: number;
    public sum_data6: number;
    public chartData2: any[];
    public tmp_customer: CustomerModel;

    public keyword: string;
    public date: any;
    public current_view: string;
    public ready: boolean;
    public current_zoom: number;
    public columnDefs: any;
    public jobs: any[];
    public cellStyle: any;
    public cellStyle_keep_promise: any;
    public cellStyle_keep_request: any;
    public cellStyle_within_3days: any;
    public cellStyle_diff_estimated_price: any;
    public num_jobs: number;
    public num_keep_request: number;
    public num_keep_promise: number;
    public num_within_3_days: number;
    public num_jobs_within_3_days: number;
    public num_jobs_diff_price: number;
    public sum_diff_price: number;
    public num_expected: number;
    public num_expected2: number;
    public num_unexpected: number;
    public num_unexpected2: number;
    public unexpected: number;
    public current_date: any;
    public chartOptions: any;
    public chartOptions2: any;
    public chartOptions3: any;
    public chartOptions4: any;
    public item_data: any[];
    public Highcharts: typeof Highcharts = Highcharts;

    constructor(
        private loader: LoaderService,
        private api: Api,
        private appService: AppService,
        private decimalPipe: DecimalPipe
    ) {
        //
        this.current_view = 'MONTHLY';
        this.date1 = moment(new Date());
        this.date2 = moment(new Date()).add(-1, 'year');

        this.current_zoom = 100;
        this.rowData1 = [];
        this.rowData2 = [];
        this.rowData3 = [];
        this.rowData4 = [];
        this.columnDefs = [];
        this.jobs = [];

        this.tmp_customer = new CustomerModel();

        this.cellStyle_keep_promise = (params) => {
            if (params && params.data && params.data.keep_promise === false) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else {
                return {
                    'background-color': '#ffffff'
                };
            }
        };

        this.cellStyle = (params) => {
            if (params && params.data && params.data.expected !== true) {
                return {
                    'background-color': '#f44336',
                    'color': '#ffffff'
                };
            } else if (params && params.data && params.data.expected === true) {
                return {
                    'background-color': '#81C784',
                    'color': '#ffffff'
                };
            }
        };

        this.item_data = [];
        this.columnDefs = [
            {
                editable: false,
                headerName: '#',
                field: 'index',
                width: 40
            },
            {
                editable: false,
                headerName: 'JOB NO',
                field: 'job_no',
                width: 120,
                /*cellRenderer: (value) => {
                    const job_no = value.data.job_no;
                    // return '<a href="">' + job_no + '</a>';
                    return job_no;
                }*/
            },
            {
                editable: false,
                headerName: 'NCR NO',
                field: 'ncr_no',
                width: 120,
                /*cellRenderer: (value) => {
                    const job_no = value.data.job_no;
                    // return '<a href="">' + job_no + '</a>';
                    return job_no;
                }*/
            },
            {
                editable: false,
                headerName: 'Customer',
                field: 'customer_name',
                minWidth: 240,
                suppressSizeToFit: true,
                cellStyle: {
                    // 'justify-content': 'flex-end'
                }
            },
            {
                editable: false,
                headerName: 'Loaded Date',
                field: 'created_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'Planned Date',
                field: 'planning_started_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'วันที่เตรียมวัสถุดิบ',
                field: 'prepared_at',
                width: 120
            },
            {
                editable: false,
                headerName: 'สถานะ',
                field: 'status',
                width: 120,
                cellStyle: this.cellStyle
            }
        ];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            //
        });
    }

    ngOnDestroy(): void {
        this.appService.unfixPage();
    }

    public getData(): Promise<any> {
        const promise = new Promise<any>(resolve => {
            if (this.jobs) {
                this.jobs.splice(0, this.jobs.length);
            } else {
                this.jobs = [];
            }
            this.ready = false;
            this.api.request('report/store', 'POST', {}, {
                date1       : (moment.isMoment(this.date1)) ? this.date1.format('YYYY-MM-DD') : this.date1,
                date2       : (moment.isMoment(this.date2)) ? this.date2.format('YYYY-MM-DD') : this.date2,
                view        : this.current_view,
                customer_id : (this.customer_id) ? this.customer_id : '',
            })
                .pipe(
                    map((response: any): void => {
                        if (response && response.success === true) {
                            return response.data;
                        }
                        return null;
                    })
                )
                .toPromise()
                .then((data: any): void => {
                    this.rowData1 = [];
                    this.num_jobs = this.num_expected = this.num_unexpected = this.num_unexpected2 = this.num_expected2 = this.num_keep_request = this.num_keep_promise = 0;
                    if (data) {
                        let n: number;
                        n = 0;
                        for (const dat of data) { // kpi1
                            const prepared_at_moment = moment(dat.prepared_at, 'YYYY-MM-DD HH:mm:ss');
                            const planning_started_at_moment = moment(dat.planning_started_at, 'YYYY-MM-DD HH:mm:ss');
                            const created_at_moment = moment(dat.created_at, 'YYYY-MM-DD HH:mm:ss');
                            const created_at = (dat.created_at) ? dat.created_at.split(' ')[0] : '';
                            const planning_started_at = (dat.planning_started_at) ? dat.planning_started_at.split(' ')[0] : '';
                            const prepared_at = (dat.prepared_at) ? dat.prepared_at.split(' ')[0] : '';
                            const expected = (dat.ncr_no) ? false : true;
                            if (expected !== null) {
                                if (expected === true) {
                                    this.num_expected++;
                                }
                                this.num_jobs++;
                                n++;
                            }
                            this.rowData1.push({
                                index               : n,
                                job_no              : dat.job_no,
                                planning_started_at : planning_started_at,
                                prepared_at         : prepared_at,
                                created_at          : created_at,
                                ncr_no              : (dat.ncr_no) ? dat.ncr_no : '-',
                                customer_name       : (dat && dat.customer_name) ? dat.customer_name : 'UNKNOW',
                                status              : (expected === true) ? 'PASS' : 'FAIL',
                                expected            : expected
                            });
                        }
                    }

                    this.chartOptions = {
                        chart: {
                            type: 'solidgauge',
                            backgroundColor: '#ffffff',
                            width: '280'
                        },
                        tooltip: {
                            enabled: false,
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false
                        },
                        pane: {
                            startAngle: 0,
                            endAngle: 360,
                            background: [{ // Track for Move
                                outerRadius: '112%',
                                innerRadius: '100%',
                                backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0
                            }]
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            lineWidth: 0,
                            tickPositions: []
                        },
                        plotOptions: {
                            solidgauge: {
                                dataLabels: {
                                    enabled: false
                                },
                                linecap: 'round',
                                stickyTracking: false,
                                rounded: true
                            }
                        },
                        series: [{
                            name: '% ที่ทำได้',
                            data: [{
                                dataLabels: {
                                    useHTML: true,
                                    enabled: true,
                                    inside: false,
                                    overflow: 'none',
                                    crop: true,
                                    color: Highcharts.getOptions().colors[0],
                                    borderWidth: 0,
                                    y: -60,
                                    style: {
                                        fontFamily: 'Helvetica Neue, Roboto, sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'normal'
                                    },
                                    formatter: function() {
                                        return '<table><tr><td style="text-align: center;color:' + this.color + ';">' + this.series.name + '</td></tr><tr><td style="text-align: center;font-size:2em;color:' + this.color + ';font-weight: bold;">' + Highcharts.numberFormat(this.y,2) + '%</td></tr></table>';
                                    }
                                },
                                color: Highcharts.getOptions().colors[0],
                                radius: '112%',
                                innerRadius: '100%',
                                y: (this.num_expected * 100) / this.num_jobs
                            }]
                        }]
                    };
                    this.ready = true;
                    resolve();
                }, error => {
                    //
                });
        });
        return promise;
    }

    public get_date(): string {
        if (this.current_view === 'YEARLY') {
            return this.date.format('YYYY');
        } else if (this.current_view === 'MONTHLY') {
            return this.date.format('MM/YYYY');
        } else if (this.current_view === 'DAILY') {
            return this.date.format('DD/MM/YYYY');
        }
        return '';
    }

    public onSuccess(e: any): void {
        this.ready = true;
    }

    public onError(e: any): void {
        //
    }

    public onViewChange(e: any): void {
        if (e === 'DAILY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'day');
        } else if (e === 'MONTHLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'month');
        } else if (e === 'QUARTERLY') {
            // this.date1 = moment(new Date());
            // this.date2 = moment(new Date()).add(-1, 'year');
        } else if (e === 'YEARLY') {
            this.date1 = moment(new Date());
            this.date2 = moment(new Date()).add(-1, 'year');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public onDateChange(e: any, date: any): void {
        if (this.current_view === 'DAILY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        } else if (this.current_view === 'MONTHLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('MM/YYYY');
        } else if (this.current_view === 'YEARLY') {
            date = moment(e, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        }
        setTimeout(() => {
            this.refresh();
        }, 500);
    }

    public refresh(): void {
        if (this.getting) {
            //
        } else {
            this.loader.show();
            this.getting = true;
            this.getData()
                .then(() => {
                    this.loader.hide();
                    this.getting = false;
                });
        }
    }

    public onCustomerChange(e: any): void {
        this.customer_id = (e && e.id) ? e.id : '';
        this.refresh();
    }

    public get contentHeight(): string {
        const window_height = window.innerHeight;
        const _height = '' + (((window_height * (100 / this.current_zoom)) - 0 - 0) * .5) + 'px';
        return _height;
    }

}
