import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {JobService} from './job.service';
import {FVDService} from './fvd.service';
import {CarrierService} from './carrier.service';
import {ConstService} from './const.service';
import {CosmeticService} from './cosmetic.service';
import {CriticalPointService} from './criticalPoint.service';
import {CustomerService} from './customer.service';
import {CuttingToolService} from './cuttingTool.service';
import {HardeningService} from './hardening.service';
import {IncotermService} from './incoterm.service';
import {MachineService} from './machine.service';
import {MaterialService} from './material.service';
import {PackagingService} from './packaging.service';
import {PricingInquiryService} from './pricingInquiry.service';
import {ProcessService} from './process.service';
import {ProductService} from './product.service';
import {PusherService} from './pusher.service';
import {RoleService} from './role.service';
import {ShippingService} from './shipping.service';
import {SpecialToolingService} from './specialTooling.service';
import {StoreService} from './store.service';
import {SwalService} from './swal.service';
import {TaskService} from './task.service';
import {UserService} from './user.service';
import {LocalStorageService} from './localStorageService';
import {PurchaseService} from './purchase.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        //
    ],
    declarations: [
        //
    ],
    providers: [
        JobService,
        FVDService,
        CarrierService,
        ConstService,
        CosmeticService,
        CriticalPointService,
        CustomerService,
        CuttingToolService,
        HardeningService,
        IncotermService,
        MachineService,
        MaterialService,
        PackagingService,
        PricingInquiryService,
        ProcessService,
        ProductService,
        PusherService,
        RoleService,
        ShippingService,
        SpecialToolingService,
        StoreService,
        SwalService,
        TaskService,
        UserService,
        PurchaseService,
        LocalStorageService
    ]
})
export class ERPServiceModule {

    public static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: ERPServiceModule,
            providers: [
                //
            ]
        };
    }

}
