import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {LoaderState} from './loaderState';
import {LoaderService} from './loader.service';

@Component({
    selector: 'loader-component',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

    public show: boolean;
    private sub: Subscription;

    constructor(private loaderService: LoaderService) {
        this.show = false;
    }

    ngOnInit(): void {
        this.sub = this.loaderService.loaderState
            .subscribe((state: LoaderState): void => {
                this.show = state.show;
            });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
