import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {LocalStorageService} from '../../../../services/localStorageService';
import {SwalService} from '../../../../services/swal.service';

@Component({
    selector: 'column-list-modal',
    templateUrl: 'columnList.modal.html',
    styleUrls: ['columnList.modal.scss']
})
export class ColumnListModal implements AfterViewInit {

    @ViewChild('columnListModal', { static: true }) smartModal: NgxSmartModalComponent;
    @Input() list: any[];
    @Output('onSubmitForm') onSubmitForm: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private swal: SwalService,
        private storage: LocalStorageService
    ) {
        //
        this.list = [];
    }

    ngAfterViewInit(): void {
        //
    }

    public onClose(e?: any): void {
        // this.removeRemark();
    }

    public onDismiss(e?: any): void {
        //
    }

    public onFormSubmit(): void {
        let found: boolean;
        found = false;
        let column_store: any;
        column_store = [];
        for (const col of this.list) {
            if (col._checked === true) {
                found = true;
                column_store.push(col.field);
            }
        }
        if (found === false) {
            this.swal.danger('โปรดระบุอย่างน้อย 1 หัวข้อ');
        } else {
            column_store = column_store.join(',');
            this.storage.store('columnList', column_store);
            this.onSubmitForm.emit();
            this.smartModal.close();
        }
    }

    public onOpen(e?: any): void {
        let current_column_list: any;
        current_column_list = this.storage.retrieve('columnList');
        current_column_list = (current_column_list && current_column_list !== 'null') ? current_column_list.split(',') : [];
        for (const col of this.list) {
            if (current_column_list && Array.isArray(current_column_list) && current_column_list.length > 0) {
                const exist_field = current_column_list.find(i => i === col.field)
                if (col && exist_field) {
                    col._checked = true;
                } else if (col) {
                    col._checked = false;
                }
            } else {
                col._checked = true;
            }
        }
    }

    public open(): void {
        this.smartModal.open();
    }

    public close(): void {
        this.smartModal.close();
    }

}
