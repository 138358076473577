import {FormModel} from '../now/formModel';

export class CosmeticModel extends FormModel {

    public name: string;
    public price: number;
    public ranking: number;

    constructor() {
        super('cosmetics', 'App\\Cosmetic');
    }

}
