import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild} from '@angular/core';
import {ItemModel} from '../../models/item.model';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute} from '@angular/router';
import {Api} from '../../now/api/api';
import {UserService} from '../../services/user.service';
import {WarehouseModel} from '../../models/warehouse.model';
import {ShelfModel} from '../../models/shelf.model';
import {ViewList} from '../../pages/database/list/viewList';
import {DivApiDirective} from '../../now/divApi';

declare var $: any;

@Component({
    selector: 'store-list-component',
    templateUrl: 'storeList.component.html',
    styleUrls: ['storeList.component.scss']
})
export class StoreListComponent extends ViewList implements AfterViewInit, OnDestroy {

    @Input() public type: string;
    @Input() public search_items: ItemModel[];
    @Input() public is_searching: boolean;
    @Input() getting: boolean;
    @Input() got: boolean;

    @ViewChild(DivApiDirective, { static: false }) _divApi: DivApiDirective;

    @Output() public onSelectedItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() public onCancelSearching: EventEmitter<any> = new EventEmitter<any>();

    public items: ItemModel[];
    public keyword: string;
    public editable: boolean;

    public sub: Subscription;
    public search_fields: string[];
    public search_values: string[];

    public timeout: any;

    public current_item: ItemModel;
    public tmp_current_item: ItemModel;

    constructor(
        private route: ActivatedRoute,
        private api: Api,
        public userService: UserService,
        private ngZone: NgZone,
    ) {
        //
        super();

        this.items = [];
        this.search_items = [];
        this.search_fields = [];
        this.search_values = [];
        this.is_searching = false;
        this.current_item = new ItemModel();
        this.got = false;
        this.getting = false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.type = (this.type) ? this.type : '';
            this.getStoreItems()
                .then(() => {
                    //
                });

            $(window).bind('keydown', e => {
                if (e.keyCode === 38 || e.keyCode === 40) {
                    if (this.items && this.items.length) {
                        const current_item_index: number = this.items.findIndex(i => i.id === this.current_item.id);
                        if (current_item_index < 0) {
                            this.gotoItemView(this.items[0]);
                        } else {
                            if (e.keyCode === 38) { // up
                                if (current_item_index - 1 > -1) {
                                    this.gotoItemView(this.items[current_item_index - 1]);
                                } else {
                                    //
                                }
                            } else { // down
                                if (current_item_index + 1 < this.items.length) {
                                    this.gotoItemView(this.items[current_item_index + 1]);
                                } else {
                                    //
                                }
                            }
                        }
                    }
                    e.stopPropagation();
                    e.preventDefault();
                }
            });
        }, 0);
    }

    ngOnDestroy(): void {
        $(window).unbind('keydown');
    }

    public refresh(updated_item?: any): void {
        this.cancelSearch()
            .then(() => {
                if (this.items && updated_item && updated_item.id) {
                    const finding_item = this.items.find(i => i.id === updated_item.id);
                    if (finding_item && finding_item.id) {
                        finding_item.clone(updated_item);
                    }
                }
            });
    }

    public getStoreItems(): Promise<ItemModel[]> {
        let promise: Promise<ItemModel[]>;
        promise = new Promise<ItemModel[]>((resolve, reject) => {
            this.getting = true;
            this.got = false;
            this.api.request('store/items/' + this.type, 'GET', {
                limit: this.item_per_page,
                page: this.current_page,
                find: (this.keyword) ? this.keyword : ''
            }).subscribe((response: any): void => {
                this.ngZone.run(() => {
                    this.getting = false;
                    this.got = true;
                    if (response.meta) {
                        this.total_items = response.meta.total_items;
                    } else {
                        this.total_items = response.total_items;
                    }
                    if (response && response.data && Array.isArray(response.data)) {
                        this.clear_items();
                        for (let i = 0; i < response.data.length; i++) {
                            let dat: any;
                            dat = response.data[i];
                            if (dat) {
                                let item: ItemModel;
                                item = new ItemModel();
                                item.clone(dat);
                                this.items.push(item);
                            }
                        }
                    }
                    resolve(this.items);
                });
            }, error => {
                this.ngZone.run(() => {
                    this.getting = false;
                    this.got = true;
                });
                reject(error);
            });
        });
        return promise;
    }

    public gotoItemView(item: ItemModel): void {
        if (!this.current_item) {
            this.current_item = new ItemModel();
            this.current_item.clone(item);
            this.current_item.modelable = item.modelable;
            this.tmp_current_item = item;
        } else if (this.current_item.id === item.id) {
            this.current_item = new ItemModel();
            if (this.current_item && (!this.current_item.warehouses || this.current_item.warehouses.length === 0)) {
                this.current_item.warehouses = [];
                this.current_item.warehouses.push(new WarehouseModel());
            }
            if (this.type) {
                this.current_item.type = this.type;
            }
            this.tmp_current_item = null;
        } else {
            this.current_item = new ItemModel();
            this.current_item.clone(item);
            this.current_item.modelable = item.modelable;
            this.tmp_current_item = item;
        }

        if (this.current_item && (!this.current_item.warehouses || this.current_item.warehouses.length === 0)) {
            this.current_item.warehouses = [];
            this.current_item.warehouses.push(new WarehouseModel());
        }

        if (this.current_item
            && this.current_item.warehouses
            && this.current_item.warehouses[0]
            && this.current_item.warehouses[0].shelves
            && !this.current_item.warehouses[0].shelves[0]) {
            //
            this.current_item.warehouses[0].shelves[0] = new ShelfModel();
        }

        if (this.type) {
            this.current_item.type = this.type;
        }

        if (!this.current_item.modelable) {
            this.current_item.modelable = {};
        }

        this.onSelectedItem.emit(this.current_item);
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        if (this.keyword) {
            this.getting = true;
            this.got = false;
            this.timeout = setTimeout(() => {
                this.getStoreItems()
                    .then(() => {
                        this.clear_timeout();
                    });
            }, 300);
        }
    }

    public clear_items(): void {
        if (this.items) {
            this.items.splice(0, this.items.length);
        } else {
            this.items = [];
        }
    }

    private clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = 0;
        }
    }

    public onError(e: any): void {
        //
    }

    private unsub(): void {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        } else {
            //
        }
    }

    public cancelSearch(): Promise<any> {
        const promise: Promise<any> = new Promise<any>((resolve) => {
            this.got = false;
            this.getting = false;
            this.tmp_current_item = null;
            this.search_values.splice(0, this.search_values.length);
            this.search_fields.splice(0, this.search_fields.length);
            this.unsub();
            this.is_searching = false;
            setTimeout(() => {
                this.getStoreItems()
                    .then(() => {
                        resolve();
                    });
            }, 150);
            this.onCancelSearching.emit();
        });
        return promise;
    }

    public pageChange(page: number): void {
        this.current_page = page;
        this.getStoreItems()
            .then(() => {});
    }

}
