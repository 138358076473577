import {NgModule} from '@angular/core';
import {AutoSaveDirective} from './autoSave.directive';
import {UpperCaseDirective} from './upperCase.directive';
import {NavBackDirective} from './navBack.directive';
import {DrawingDirective} from './drawing.directive';

@NgModule({
    imports: [
        //
    ],
    exports: [
        UpperCaseDirective,
        NavBackDirective,
        DrawingDirective,
        AutoSaveDirective,
    ],
    declarations: [
        UpperCaseDirective,
        NavBackDirective,
        DrawingDirective,
        AutoSaveDirective,
    ],
    providers: [
        //
    ]
})
export class ERPDirectiveModule {}
