import {Model} from '../now/model';
import {DocumentModel} from './document.model';

export class ItemRequirementDocumentModel extends Model {

    public item_requirement_id: string;
    public name: string;
    public document_id: string;
    public document: DocumentModel;
    public modelable_data: any;
    public modelable_type: string;

    constructor() {
        super('item/requirement/documents', 'App\\ItemRequirementDocument');
        this.document = new DocumentModel();
        this.modelable_data = {
            ranking: ''
        };
    }

}
