import {Model} from '../now/model';
import {DocumentModel} from './document.model';
import {CustomerModel} from './customer.model';

export class CrdModel extends Model {

    public steps: any[];
    public estimate_cost: number;
    public name: string;
    public request_type: string;
    public request_other: string;
    public description: string;
    public started_at: string;

    public customer: CustomerModel;
    public documents: DocumentModel[];

    constructor() {
        super('crds', 'App\\Crd');
        //
        this.steps = [];
        this.documents = [];
        this.customer = new CustomerModel();
    }

}
