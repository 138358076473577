import {AfterViewInit, Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {UserModel} from '../../../../now/user/user.model';
import {SwalService} from '../../../../services/swal.service';
import {Api} from '../../../../now/api/api';

import * as moment from 'moment';

@Component({
    selector: 'late-modal-component',
    templateUrl: 'lateModal.component.html',
    styleUrls: ['lateModal.component.scss']
})
export class LateModalComponent implements AfterViewInit {

    public late_num: number;
    public updated_at: string;
    public late_date: string;
    public late_time: number;
    public late_minutes: number;
    public user: UserModel;
    public start_time: string;
    public end_time: string;

    constructor(public bsModalRef: BsModalRef, private swal: SwalService, private api: Api) {
        this.late_minutes = 0;
        this.late_num = 0;
        this.late_time = 1;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.user) {
                this.user = this.bsModalRef.content.user;
                if (this.user.activities && this.user.activities.absence && this.user.activities.late.value > 0) {
                    this.late_num = this.user.activities.late.value;
                    this.updated_at = this.user.activities.late.activity_date;
                }
            }
        }, 0);
    }

    public onSubmit(form: NgForm): void {
        if (this.late_date && this.start_time && this.end_time) {
            const late_date_string: string = moment(this.late_date).format('DD MMMM YYYY');
            const late_date: string = moment(this.late_date).format('YYYY-MM-DD');
            let late_date_start: string;
            let late_date_end: string;
            late_date_start = late_date + ' ' + this.start_time + ':00';
            late_date_end = late_date + ' ' + this.end_time + ':00';
            this.swal.confirm('ผู้ใช้งาน ' + this.user.full_name + ' แจ้งมาสายเมื่อวันที่ ' + late_date_string + ' ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.api.request('users/late', 'POST', {}, {
                            id: this.user.id,
                            start: late_date_start,
                            end: late_date_end
                        }).subscribe((response: any): void => {
                            if (response && response.data) {
                                //
                            }
                            this.swal.success('แจ้งมาสายกับผู้ใช้งาน ' + this.user.full_name + ' สำเร็จ');
                            this.bsModalRef.hide();
                        }, error => {
                            this.swal.danger(error);
                        });
                    }
                });
        } else {
            this.swal.danger('กรุณากรอกข้อมูลวันและเวลาที่แจ้งมาสาย');
        }
    }

}
