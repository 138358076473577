import {Model} from '../now/model';
import {ProductProcessModel} from './productProcess.model';
import {UserModel} from '../now/user/user.model';
import {ProcessModel} from './process.model';
import {MachineModel} from './machine.model';
import {FormModel} from '../now/formModel';
import {JobModel} from './job.model';
import * as moment from 'moment';
import * as uuid4 from 'uuid/v4';

export class SchedulerModel extends FormModel {

    public process_id: string;
    public user_id: string;
    public machine_id: string;
    public order_index: number;
    public process_slug: string;
    public schedulers: SchedulerModel[];
    public revision: number;
    public parent_id: string;
    public job_id: string;
    public product_process_id: string;
    public product_process: any;
    public current_user_id: string;
    public started_at: string;
    public ended_at: string;
    public model_id: string;
    public model_name: string;
    public model: any;
    public ranking: number;
    public minutes: number;
    public description: string;
    public date_range: any;
    public job_no: string;
    public histories: any[];
    public start_date: string;
    public start_time: string;
    public end_date: string;
    public end_time: string;
    public status_date: string;
    public start: string;
    public end: string;
    public title: string;
    public calendar_title: string;
    public timeline_title: string;
    public status: number; // 1 = starting, 2 = UNKNON ??, 3 = NCR
    public process: ProcessModel;
    public current_role: string;
    public current_user: UserModel;
    public user: UserModel;
    public machine: MachineModel;
    public planner: UserModel;
    public manId: string;
    public machineId: string;
    public resourceId: string;
    public color: string;
    public calEvent: any;
    public guid: string;
    public className: string;
    public editable: boolean;
    public date_error: boolean;
    public busy_date_error: boolean;
    public filter_value: string[];
    public price: number;
    public timeSegments: any[];
    public priority: string; // primary, normal or empty, warning, danger

    constructor() {
        super('schedulers');
        this.histories = [];
        // this.process = new ProcessModel();
        // this.user = new UserModel();
        // this.machine = new MachineModel();
        // this.product_process = {};
        this.guid = uuid4();
        this.date_range = {
            start       : {},
            end         : {},
            start_date  : {},
            start_time  : {},
            end_date    : {},
            end_time    : {}
        };
        this.filter_value = [];
        this.schedulers = [];
        this.date_error = false;
        this.busy_date_error = false;
        this.timeSegments = [
            ['12:00', '13:00'], // 60
            ['17:00', '17:30'], // 30
            ['00:00', '01:00'], // 60
            ['05:00', '05:30'], // 30
        ];
    }

    public clone(data: any, without: string[] = [], _delete?: boolean): Model {
        if (data.current_user) {
            this.current_user = new UserModel();
            this.current_user.clone(data.current_user);
            if (_delete !== false) {
                delete data.current_user;
            }
        }
        if (data.planner) {
            this.planner = new UserModel();
            this.planner.clone(data.planner);
            if (_delete !== false) {
                delete data.planner;
            }
        }
        if (data.process) {
            this.process = new ProcessModel();
            this.process.clone(data.process);
            if (_delete !== false) {
                delete data.process;
            }
        }
        if (data.user) {
            this.user = new UserModel();
            this.user.clone(data.user);
            if (_delete !== false) {
                delete data.user;
            }
        }
        if (data.machine) {
            this.machine = new MachineModel();
            this.machine.clone(data.machine);
            if (_delete !== false) {
                delete data.machine;
            }
        }
        if (data.start && data.start.date) {
            const toDate = moment(data.start.date, 'YYYY-MM-DD HH:mm:ss.000000').format('YYYY-MM-DD HH:mm:ss');
            this.start = toDate;
            delete data.start;
        }
        if (data.end && data.end.date) {
            const toDate = moment(data.end.date, 'YYYY-MM-DD HH:mm:ss.000000').format('YYYY-MM-DD HH:mm:ss');
            this.end = toDate;
            delete data.end;
        }

        super.clone(data, [], _delete);
        this.update();

        this.guid = uuid4();

        return this;
    }

    public update(product_process?: ProductProcessModel, show_job_no?: boolean): void {
        if (product_process) {
            let _resourceId: string;
            let _machineId: string;
            let _manId: string;
            let user: UserModel;
            let machine: MachineModel;
            let job: JobModel;
            user = product_process.user;
            machine = product_process.machine;
            job = product_process.job;
            if (user && user.id) {
                _resourceId = 'man_' + user.id;
                _manId = _resourceId;
              this.filter_value.push(_resourceId, user.full_name, user.nick_name);
            }
            if (machine && machine.id) {
                _machineId = 'machine_' + machine.id;
              this.filter_value.push(_machineId, machine.machine_name, machine.machine_no);
            }
            if (job && job.id) {
              this.job_no = job.job_no;
              this.filter_value.push(this.job_no);
            }
            if (user && user.id && machine && machine.id) {
                this.title = user.full_name + ' (' + machine.machine_name + ')';
            } else if (user && user.id) {
                this.title = user.full_name;
            } else if (machine && machine.id) {
                this.title = machine.machine_name;
            } else if (product_process && product_process.process) {
                this.title = product_process.process.name;
            } else {
                this.title = 'UNKNOW';
            }
            this.resourceId = _resourceId;
            this.machineId = _machineId;
            this.manId = _manId;
        } else {
            this.title = 'UNKNOW';
        }
        if (this.start && this.start.length === 16) {
            this.start += ':00';
        }
        if (this.end && this.end.length === 16) {
            this.end += ':00';
        }
        if (this.start) {
            if (moment.isMoment(this.start)) {
                this.start_date = this.start.format('YYYY-MM-DD 00:00:00');
                this.start_time = this.start.format('HH:mm');
            } else if (typeof this.start === 'string') {
                this.start_date = this.start.slice(0, 10) + ' 00:00:00';
                this.start_time = this.start.slice(11, 16);
            }
        }
        if (this.end) {
            if (moment.isMoment(this.end)) {
                this.end_date = this.end.format('YYYY-MM-DD 00:00:00');
                this.end_time = this.end.format('HH:mm');
            } else if (typeof this.end === 'string') {
                this.end_date = this.end.slice(0, 10) + ' 00:00:00';
                this.end_time = this.end.slice(11, 16);
            }
        }
        /*if (this.product_process) {
            this.product_process.cal_price();
        }*/

        if (show_job_no === true && this.job_no) {
          this.title = this.title + ' <br/><strong>' + this.job_no + '</strong>';
        }

        this.timeline_title = (this.job_no && this.job_no) ? this.job_no : this.title;
        this.calendar_title = this.title;

        this.update_priority();
        this.calMinutes();
        this.cal_price();
    }

    private update_priority(): void {
        if (this.status_date && this.status !== 3) {
            if (this.status === 1 || this.status === 2) {
                let now: any;
                now = moment(new Date());
                const status_date: any = moment(this.status_date);
                const asMinutes: number = moment.duration(now.diff(status_date)).asMinutes();
                /*if (asMinutes > 2880) { // 2days
                    this.priority = 'danger';
                    this.className = 'event-danger';
                } else if (asMinutes > 1440) { // 1day
                    this.priority = 'warning';
                    this.className = 'event-warning';
                } else if (asMinutes > 10) { // 10 mins
                    this.priority = 'orange';
                    this.className = 'event-orange';
                } else { // less than 30 mins
                    this.priority = 'primary';
                    this.className = 'event-primary';
                }*/
                if (asMinutes > 2880) { // 2days
                    this.priority = 'danger';
                    this.className = 'event-danger';
                } else if (asMinutes > 1440) { // 1day
                    this.priority = 'warning';
                    this.className = 'event-warning';
                } else if (asMinutes > 0) { // 10 mins
                    this.priority = 'orange';
                    this.className = 'event-orange';
                } else { // less than 30 mins
                    this.priority = 'primary';
                    this.className = 'event-primary';
                }
            } else {
                this.priority = '';
                this.className = 'event-done';
            }
        } else {
            this.priority = '';
            this.className = 'event-book';
        }
    }

    public cal_price(): number {
        if (this.process) {
            if (this.process.slug === 'hardening') {
                this.price = 0;
                return 0;
            } else if (this.process.slug === 'cosmetic') {
                this.price = 0;
                return 0;
            } else if (this.process.slug === 'shipping') {
                this.price = 0;
                return 0;
            } else if (this.process.slug === 'packing') {
                this.price = 0;
                return 0;
            } else if (this.process.slug === 'finish-good') {
                this.price = 0;
                return 0;
            } else if (this.minutes > 0) {
                let machine_price: number;
                let user_price: number;
                machine_price = 0;
                user_price = 0;
                if (this.machine && this.machine.id) {
                    machine_price = ((this.machine.price > 0) ? this.machine.price : 0) * this.minutes;
                }
                if (this.user && this.user.id) {
                    user_price = ((this.user.price > 0) ? this.user.price : 0) * this.minutes;
                }
                this.price = machine_price + user_price;
                return this.price;
            } else {
                this.price = 0;
                return 0;
            }
        }
        this.price = 0;
        return 0;
    }

    public calMinutes(): number {
        if (this.start && this.end) {
            const start_moment = moment(this.start, 'YYYY-MM-DD HH:mm:ss');
            const end_moment = moment(this.end, 'YYYY-MM-DD HH:mm:ss');
            if (start_moment.isValid() && end_moment.isValid()) {
                let minutes: number;
                const _ = this.cal_business(start_moment, end_moment);
                minutes = moment.duration(end_moment.diff(start_moment)).asMinutes() - _;
                this.minutes = minutes;
                return this.minutes;
            }
        }
        return null;
    }

    private cal_business(start1: any, end1: any): number {
        let total: number;
        total = 0;
        let tmp_start1: any;
        tmp_start1 = start1;
        let tmp_end1: any;
        tmp_end1 = end1;
        for (const timeSegment of this.timeSegments) {
            const start2 = moment(start1.format('YYYY-MM-DD') + ' ' + timeSegment[0], 'YYYY-MM-DD HH:mm');
            const end2 = moment(start1.format('YYYY-MM-DD') + ' ' + timeSegment[1], 'YYYY-MM-DD HH:mm');
            if (tmp_start1 && start2 && moment.isMoment(tmp_start1) && moment.isMoment(start2)) {
                const _start1: number = +tmp_start1.format('YYYYMMDDHHmm');
                const _end1: number = (tmp_end1) ? +tmp_end1.format('YYYYMMDDHHmm') : +tmp_start1.add(1, 'hours')
                    .format('YYYYMMDDHHmm');
                const _start2: number = +start2.format('YYYYMMDDHHmm');
                const _end2: number = (end2) ? +end2.format('YYYYMMDDHHmm') : +start2.add(1, 'hours')
                    .format('YYYYMMDDHHmm');
                if (Math.max(_start1, _start2) < Math.min(_end1, _end2)) {
                    const s = moment(Math.max(_start1, _start2), 'YYYYMMDDHHmm');
                    const e = moment(Math.min(_end1, _end2), 'YYYYMMDDHHmm');
                    total += moment.duration(e.diff(s)).asMinutes();
                }
            }
        }
        return total;
    }

    public get last_scheduler(): SchedulerModel {
        if (this.schedulers && this.schedulers.length > 0) {
            return this.schedulers[this.schedulers.length - 1];
        }
        return null;
    }

    public get start_time_hours(): number {
        if (this.start_time) {
            const start_time_array: string[] = this.start_time.split(':');
            if (start_time_array.length > 1) {
                return +start_time_array[0];
            }
        }
        return 8;
    }

    public get start_time_minutes(): number {
        if (this.start_time) {
            const start_time_array: string[] = this.start_time.split(':');
            if (start_time_array.length > 1) {
                return +start_time_array[1];
            }
        }
        return 0;
    }

}
