// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    api_host            : 'https://api.siamiris.com/',
    prefix              : 'api/',
    host                : 'https://siamiris.com/',
    production          : true,
    pusher_key          : '',
    pusher_cluster      : 'ap1',
    schedulerLicenseKey : '0272464799-fcs-1530869183',
    sale_email          : ''
    /*api_host            : 'http://localhost:8000/',
    prefix              : 'api/',
    host                : 'http://localhost:4200/',
    production          : false,
    pusher_key          : '',
    pusher_cluster      : 'ap1',
    schedulerLicenseKey : '0272464799-fcs-1530869183',
    sale_email          : ''*/
};
