import {Injectable} from '@angular/core';

@Injectable()
export class ApiConfig {

    public host: string;
    public prefix: string;
    public mockup: boolean;
    public timeout: number;

}
