import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ModelApi} from '../../../../now/modelApi/modelApi';
import {ProductModel} from '../../../../models/product.model';
import {Api} from '../../../../now/api/api';
import {SwalService} from '../../../../services/swal.service';
import * as moment from 'moment';
import {PaymentDocument} from '../../../../app/api/paymentDocument';

@Component({
    selector: 'app-payment-detail-component',
    templateUrl: 'paymentDetail.component.html',
    styleUrls: ['paymentDetail.component.scss']
})
export class PaymentDetailComponent implements OnInit {

    public modelable_type: string;
    public payment: PaymentDocument;
    public tmp_payment: PaymentDocument;
    public product: ProductModel;
    public options: any[];
    public task_id: any;

    constructor(
        public bsModalRef: BsModalRef,
        private api: Api,
        private modelApi: ModelApi,
        private swal: SwalService
    ) {
        //
        this.payment = {};
        this.tmp_payment = {};
        this.options = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.payment) {
                if (!this.bsModalRef.content.payment.started_at) {
                    this.bsModalRef.content.payment.started_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                }
                this.payment = this.bsModalRef.content.payment;
                this.tmp_payment.id = this.payment.id;
                this.tmp_payment.started_at = this.payment.started_at;
                this.tmp_payment.purchase_order_no = this.payment.purchase_order_no;
                this.tmp_payment.remark = this.payment.remark;
                this.tmp_payment.verified = this.payment.verified;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.task_id) {
                this.task_id = this.bsModalRef.content.task_id;
            }
            if (this.bsModalRef && this.bsModalRef.content && this.bsModalRef.content.modelable_type) {
                this.modelable_type = this.bsModalRef.content.modelable_type;
            }
        }, 0);
    }

    public delete(): void {
        if (this.payment.status === 1) {
            return;
        }
        const name: string = (this.tmp_payment.purchase_order_no)
            ? this.tmp_payment.purchase_order_no : this.tmp_payment.document.name;
        this.swal.confirm('คุณต้องการลบหลักฐานการรับเงิน "' + name + '" ใช่หรือไม่?')
            .then(result => {
                if (result === true) {
                    this.api.request('tax/invoices/payments/delete', 'POST', {}, {
                        id: this.tmp_payment.id
                    }).subscribe((response: any): void => {
                        if (response && response.success === true) {
                            this.bsModalRef.content.deleted = true;
                            this.bsModalRef.hide();
                        } else {
                            //
                        }
                    }, error => {
                        //
                    });
                } else {
                    //
                }
            });
    }

    public save(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('payments', 'POST', {}, {
                task_id             : this.task_id,
                id                  : this.tmp_payment.id,
                verified            : this.tmp_payment.verified,
                purchase_order_no   : this.tmp_payment.purchase_order_no,
                started_at          : this.tmp_payment.started_at,
                remark              : this.tmp_payment.remark
            }).subscribe((response: any): void => {
                if (response && response.success === true) {
                    resolve({});
                } else {
                    reject(response);
                }
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    public onSubmit(form: NgForm): void {
        this.save()
            .then(() => {
                this.swal.success('บันทึกข้อมูลหลักฐานการรับเงินสำเร็จ');
                // this.payment = Object.assign({}, this.tmp_payment);
                this.bsModalRef.content.updated_payment = this.tmp_payment;
                this.bsModalRef.content.is_submit = true;
                this.bsModalRef.hide();
            });
    }

}
