import {Injectable, Optional} from '@angular/core';
import {ApiConfig} from './api.config';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, timeout} from 'rxjs/operators';
import {throwError} from 'rxjs';

export const API_AUDIT_TIME = 1000;
export const API_TIMEOUT = 60000;

@Injectable()
export class Api {

    protected host: string;
    protected prefix: string;

    private authorization: string;
    private timeout: number;
    private mockup: boolean;

    public errorStatusMethods: any;

    // public api_calling_list: any[];

    constructor(private http: HttpClient, @Optional() config: ApiConfig) {
        if (config) {
            if (config.host) {
                this.host = config.host;
            }
            if (config.prefix) {
                this.prefix = config.prefix;
            }
            if (config.mockup) {
                this.mockup = config.mockup;
            }
            this.timeout = (config.timeout) ? config.timeout : 20000;
        }
        // this.api_calling_list = [];
    }

    private parseServicePath(service_path: string, data: any): string {
        if (typeof data === 'object') {
            for (const key in data) {
                if (key) {
                    const patt: string = '{' + key + '}';
                    if (service_path.indexOf(patt) > -1) {
                        service_path = service_path.replace(patt, data[key]);
                    }
                }
            }
            service_path = service_path.replace(/\/?\{[^\?\}]+\?\}/, '');
        }

        return service_path;
    }

    private parseQueryString(params_object: any): string {
        let query_string: string;
        let query_array: string[];
        query_array = [];

        // const timestamp = (new Date()).valueOf();
        if (params_object) {
            // params_object['timestamp'] = timestamp;
            for (const key in params_object) {
                if (key) {
                    const value = params_object[key];
                    query_array.push(key + '=' + value);
                }
            }
            if (query_array && query_array.length > 0) {
                query_string = '?' + query_array.join('&');
            }
        } else {
            // query_string = '?timestamp=' + timestamp;
        }

        return (query_string) ? query_string : '';
    }

    private generateMessageId(): string {
        let messageId: string;
        messageId = '1234567890';

        return messageId;
    }

    public setAuthorization(token: string): void {
        this.authorization = token;
    }

    public getAuthorization(): string {
        return 'Bearer ' + this.authorization;
    }

    public getHeaders(): HttpHeaders {
        let headers: HttpHeaders;
        headers = new HttpHeaders({
            //
        })
            .set('Authorization', this.getAuthorization())
            .set('Content-Type', 'application/json');

        return headers;
    }

    public buildPostOrPutData(model: any, put_or_post_data: string[]): any {
        let data: any;
        data = {
            id: model.id
        };
        for (let i = 0; i < put_or_post_data.length; i++) {
            let key: string;
            let value: any;
            key = put_or_post_data[i];
            value = (<any>model)[key];
            (<any>data)[key] = value;
        }
        return data;
    }

    public request(service_path: string, method?: string, params?: any, request_data?: any, key_name?: string, host_url?: string, prefix?: string, headers?: HttpHeaders, double?: boolean): Observable<Object> {
        let host: string;
        let _prefix: string;
        let url: string;
        let _headers: HttpHeaders;
        let query_string: string;

        host            = (host_url) ? host_url : (this.host) ? this.host : '';
        _prefix         = (prefix || prefix === '') ? prefix : (this.prefix) ? this.prefix : '';
        url             = host + _prefix + service_path;
        _headers         = (headers) ? headers : this.getHeaders();
        query_string    = this.parseQueryString(params);

        let body: any;
        body = {};

        if (key_name) {
            body[key_name] = request_data;
        } else {
            body = request_data;
        }

        let options: any;
        options = {
            headers: _headers,
        };

        let request: Observable<Object>;
        if (method && method.toLowerCase() === 'post') {
            request = this.http.post(url + query_string, body, options);
        } else if (method && method.toLowerCase() === 'put') {
            request = this.http.put(url + query_string, body, options);
        } else if (method && method.toLowerCase() === 'delete') {
            request = this.http.delete(url + query_string, options);
        } else {
            request = this.http.get(url + query_string, options);
        }

        return request.pipe(
            timeout(API_TIMEOUT),
            catchError(error => {
                if (error && error.status && this.errorStatusMethods && this.errorStatusMethods[error.status]) {
                    this.errorStatusMethods[error.status]();
                    return throwError(error);
                } else {
                    return throwError(error);
                }
            }),
            map(response => response)
        );
    }

}
