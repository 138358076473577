import {AfterViewInit, Component, Inject, NgZone, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {ProductModel} from '../../models/product.model';
import {TaskModel} from '../../models/task.model';
import {UserService} from '../../services/user.service';
import {CustomerModel} from '../../models/customer.model';
import {CustomerAddressModel} from '../../models/customerAddress.model';
import {Api} from '../../now/api/api';
import {SwalService} from '../../services/swal.service';
import {ModelApi} from '../../now/modelApi/modelApi';
import {environment} from '../../environments/environment';
import {DecimalPipe, DOCUMENT, Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserModel} from '../../now/user/user.model';
import {IncotermModel} from '../../models/incoterm.model';
import {CarrierModel} from '../../models/carrier.model';
import {IncotermService} from '../../services/incoterm.service';
import {CarrierService} from '../../services/carrier.service';
import * as moment from 'moment';
import {TaskService} from '../../services/task.service';
import {ModalService} from '../../services/modal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {AuthService} from '../../now/user/auth.service';
import {DocumentComponent} from '../../modals/document/document.component';
import {PaymentModel} from '../../models/payment.model';
import {TypeaheadComponent} from '../../components/typeahead/typeahead.component';
import {RemarkModalComponent} from '../../modals/remarkModal/remarkModal.component';
import {ReportModalComponent} from '../../modals/reportModal/reportModal.component';
import {ContactModel} from '../../models/contact.model';
import {BillModel} from '../../models/bill.model';
import {DocumentModel} from '../../models/document.model';
import {LoaderService} from '../../components/loader/loader.service';
import {JobService} from '../../services/job.service';
import {LocalStorageService} from '../../services/localStorageService';
import {Viewer} from '../../services/viewer';
import {ViewTask} from '../../pages/task/view/viewTask';
import {PurchaseOrderDetailComponent} from '../../pages/task/view/purchaseOrderDetail/purchaseOrderDetail.component';
import {DepositModel} from '../../models/deposit.model';
import {DepositDetailComponent} from '../../pages/task/view/depositDetail/depositDetail.component';
import {ReceiptModel} from '../../models/receipt.model';
import {ReceiptDetailComponent} from '../../pages/task/view/receiptDetail/receiptDetail.component';
import {AddRemarkComponent} from '../../pages/task/view/addRemark/addRemark.component';
import {SaleOrderModel} from '../../models/saleOrder.model';

const MAX_PRODUCT = 10;
const DEFAULT_PRICE_VALIDITY = 30;

@Component({
    selector: 'sale-order-task-component',
    templateUrl: 'saleOrderTask.component.html',
    styleUrls: ['saleOrderTask.component.scss'],
    providers: [
        DecimalPipe
    ]
})
export class SaleOrderTaskComponent extends ViewTask implements AfterViewInit, OnDestroy {

    @ViewChildren(TypeaheadComponent) typeaheadComponents: QueryList<TypeaheadComponent>;

    public saleOrder;
    public saleOrder_id;
    public sale: UserModel;
    public customerAddress: CustomerAddressModel;
    public tmp_customer: CustomerModel;
    public customer: CustomerModel;
    public tmp_product: any;
    public tmp_customer_address: any;
    public tmp_contact: any;
    public tmp_sale: any;
    public current_tab: string;
    public incoterms: string[];
    public carriers: CarrierModel[];
    public tmp_incoterm: any;
    public tmp_carrier: any;
    public tmp_currency: any;
    public currency_list: any[];
    public tmp_contact_ref: any;
    public products: ProductModel[];

    public test_text_1: string;
    public test_text_2: string;
    public test_text_3: string;
    public test_text_4: string;
    public test_text_5: string;
    public test_text_6: string;
    public test_text_7: string;
    public test_text_8: string;
    public test_text_9: string;
    public test_text_10: string;

    constructor(
        public viewer: Viewer,
        public userService: UserService,
        public api: Api,
        private loader: LoaderService,
        private swal: SwalService,
        private storage: LocalStorageService,
        public taskService: TaskService,
        private incotermService: IncotermService,
        private carrierService: CarrierService,
        private authService: AuthService,
        private ngZone: NgZone,
        private pageScrollService: PageScrollService,
        private modelApi: ModelApi,
        public modal: ModalService,
        private decimalPipe: DecimalPipe,
        public location: Location,
        private route: ActivatedRoute,
        private jobService: JobService,
        @Inject(DOCUMENT) private document: any
    ) {
        //
        super({ taskService, api, modal, location, viewer });

        this.current_tab = '#information';
        this.tmp_customer = new CustomerModel();
        // this.customer = new CustomerModel();
        this.sale = new UserModel();
        this.customerAddress = new CustomerAddressModel();
        this.saleOrder = this.createNewSaleOrder();
        this.tmp_product = {};
        this.tmp_customer_address = {};
        this.tmp_sale = new UserModel();
        this.incoterms = [];
        this.carriers = [];
        this.tmp_incoterm = {};
        this.tmp_carrier = {};
        this.tmp_currency = {};
        this.tmp_contact_ref = {};
        this.products = [];
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.route.params
                .subscribe(params => {
                    this.loader.show();
                    this.saleOrder = this.createNewSaleOrder();
                    this.viewTaskInit();
                    this.task = new TaskModel();
                    this.task_id = params['id'];
                    if (this.task_id) {
                        this.task.id = this.task_id;
                        this.getTask()
                            .then(() => {
                                this.saleOrder_id = this.task.modelable_id;
                                this.getSaleOrder()
                                    .then(() => {
                                        this.tmp_currency = {
                                            id: this.saleOrder.currency,
                                        };
                                        this.customerAddress = this.saleOrder.customer_address;
                                        this.doReady();
                                        this.loader.hide();
                                    });
                            });
                    } else {
                        this.loader.hide();
                    }
                    this.saleOrder.task_id = this.task_id;
                });

            this.getIncoterms();
            this.getCarriers();
            this.getCurrencies();
        }, 0);
    }

    ngOnDestroy(): void {
        //
    }

    public onDiscountPercentChange(e?: any): void {
        setTimeout(() => {
            // this.saleOrder.discount_price = Math.round(this.saleOrder.material_price * (this.saleOrder.discount_percent / 100) * 100) / 100;
            this.saleOrder.cal_total_price();
        }, 0);
    }

    public onDiscountPriceChange(e?: any): void {
        setTimeout(() => {
            // this.saleOrder.discount_percent = Math.round(this.saleOrder.discount_price * (100 / this.saleOrder.material_price) * 100) / 100;
            this.saleOrder.cal_total_price();
        }, 0);
    }

    public createNewSaleOrder() {
        return new SaleOrderModel();
    }

    public getSaleOrder(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (this.saleOrder.products) {
                this.saleOrder.products.splice(0, this.saleOrder.products.length);
            } else {
                this.saleOrder.products = [];
            }
            if (this.saleOrder_id) {
                this.api.request('sale_admin/sale_orders/' + this.saleOrder_id, 'GET')
                    .subscribe((response: any): void => {
                        if (response.data) {
                            this.saleOrder = this.createNewSaleOrder();
                            this.saleOrder.clone(response.data);
                        }
                        for (let i = 0; i < Math.max(MAX_PRODUCT, this.saleOrder.products.length); i++) {
                            let product: ProductModel;
                            if (this.saleOrder.products[i] && this.saleOrder.products[i].id) {
                                product = this.saleOrder.products[i];
                                product.index = i;
                                product.price_per_unit = (product.pivot.price_per_unit)
                                    ? product.pivot.price_per_unit : product.price / Math.min(product.customer_product_amount, product.amount);
                                product.processing_times = (product.pivot.processing_times)
                                    ? product.pivot.processing_times : product.processing_times;
                            } else {
                                product = new ProductModel();
                                product.index = i;
                                product.pivot.wh = 'TH';
                                this.saleOrder.products.push(product);
                            }
                            // product.cal_price();
                        }
                        if (!this.saleOrder.sale || !this.saleOrder.sale.id) {
                            this.saleOrder.sale = new UserModel();
                            this.saleOrder.sale.clone(this.authService.user);
                            this.saleOrder.sale_id = this.authService.user.id;
                        }
                        if (this.saleOrder && !this.saleOrder.shipping) {
                            this.saleOrder.shipping = {};
                        }
                        this.saleOrder.cal_total_price();
                        resolve(this.saleOrder);
                    }, error => {
                        reject(error);
                    });
            } else {
                this.saleOrder = this.createNewSaleOrder();
                this.saleOrder.currency = 'THB';
                this.saleOrder.currency_rate = 1;
                for (let i = 0; i < MAX_PRODUCT; i++) {
                    let product: ProductModel;
                    product = new ProductModel();
                    product.index = i;
                    product.pivot.wh = 'TH';
                    this.saleOrder.products.push(product);
                }
                this.saleOrder.price_validity = DEFAULT_PRICE_VALIDITY;
                resolve(this.saleOrder);
            }
        });
        return promise;
    }

    private getCurrencies(): void {
        this.api.request('assets/json/currencies.json', 'GET', null, null, null, environment.host, '')
            .subscribe((response: any): void => {
                this.currency_list = [];
                if (response && response.results) {
                    for (const key in response.results) {
                        if (key) {
                            const value: any = response.results[key];
                            this.currency_list.push(value);
                        }
                    }
                }
            }, error => {
                //
            });
    }

    private getCurrencyRate(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            const current_currency_code: string = this.saleOrder.currency;
            console.log(this.saleOrder.currency);
            this.api.request('currency/covert', 'GET', {
                from: 'THB',
                to: current_currency_code
            }).subscribe((response: any): void => {
                if (response && response.data && response.data['THB_' + current_currency_code]) {
                    resolve(response.data['THB_' + current_currency_code].val);
                }
            }, error => {
                reject(error);
            });
            /*this.api.request('convert?q=THB_' + current_currency_code + '&compact=y',
            'GET', null, null, null, 'http://free.currencyconverterapi.com/', 'api/v5/')
                .subscribe((response: any): void => {
                    resolve(response);
                }, error => {
                    reject(error);
                });*/
        });
        return promise;
    }

    private getIncoterms(): void {
        this.incotermService.getIncoterms()
            .then((incoterms: IncotermModel[]): void => {
                this.incoterms = [];
                for (const incoterm of incoterms) {
                    this.incoterms.push(incoterm.name);
                }
            });
    }

    private getCarriers(): void {
        this.carrierService.getCarriers()
            .then((carriers: CarrierModel[]): void => {
                this.carriers = carriers;
            });
    }

    public onProductDelete(product: ProductModel, e?: any): void {
        if (product) {
            product.name = '';
            product.market_price = 0;
            product.price = 0;
            product.customer_product_amount = 0;
            product.description = '';
        } else {
            //
        }
    }

    public onProductSelect(product: ProductModel, e: any): void {
        if (e && e.id) {
            product.wh = 'TH';
            product.id = e.id;
        } else {
            product.wh = '';
            product.id = null;
        }
    }

    private task_quotation_clone(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.api.request('tasks/clone', 'POST', {}, {
                action: 'edit_quotation',
                id: this.task.id
            }).subscribe((response: any): void => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        return promise;
    }

    private generate_no(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (!this.saleOrder.document_no) {
                this.api.request('sale_admin/sale_orders/no', 'POST', {
                    task_id: this.task.id,
                    id: this.saleOrder.id
                }).subscribe((response: any): void => {
                    if (response && response.data && response.data.document_no) {
                        this.saleOrder.document_no = response.data.document_no;
                        this.getSaleOrder()
                            .then(() => {
                                // this.tmp_currency = {
                                //     id: this.saleOrder.currency,
                                // };
                                // this.customerAddress = this.saleOrder.customer_address;
                                // this.doReady();
                                resolve(this.saleOrder);
                            });
                    } else {
                        reject(response);
                    }
                }, error => {
                    reject(error);
                });
            } else {
                resolve(this.saleOrder);
            }
        });
        return promise;
    }

    public editSaleOrder(): void {
        if (this.saleOrder.document_no) {
            this.swal.confirm('คุณต้องการแก้ไขใบสั่งขาย "' + this.saleOrder.document_no + '" ใช่หรือไม่?')
                .then(result => {
                    if (result === true) {
                        this.task_quotation_clone()
                            .then((response: any): void => {
                                if (response && response.data && response.data.id) {
                                    this.taskService.hire(response.data, null, true)
                                        .then((hire_response: any): void => {
                                            // Go Go!
                                        }, error => {
                                            //
                                        });
                                } else {
                                    //
                                }
                            });
                    } else {
                        //
                    }
                });
        } else {
            //
        }
    }

    public createSaleOrder(): void {
        if (this.saleOrder) {
            if (!this.saleOrder.customer_address_id && this.tmp_customer_address && this.tmp_customer_address.id) {
                this.saleOrder.customer_address_id = this.tmp_customer_address.id;
            }
            if (!this.saleOrder.customer || !this.saleOrder.customer.id) {
                if (this.tmp_customer && this.tmp_customer.id) {
                    this.saleOrder.customer = new CustomerModel();
                    this.saleOrder.customer.clone(this.tmp_customer);
                }
            }
        }
        if (!this.saleOrder.customer || !this.saleOrder.customer.id) {
            this.swal.danger('ไม่สามารถออกใบสั่งขายได้เนื่องจากไม่ได้ระบุข้อมูลลูกค้า');
            this.current_tab = '#information';
        } else if (!this.saleOrder.customer_address_id) {
            this.swal.danger('ไม่สามารถออกใบสั่งขายได้เนื่องจากไม่ได้ระบุข้อมูลการส่งสินค้า');
            this.current_tab = '#shipping';
        } else if (!this.saleOrder.sale_id) {
            this.swal.danger('ไม่สามารถออกใบสั่งขายได้เนื่องจากไม่ได้ระบุข้อมูลผู้ดำเนินการ');
            this.current_tab = '#information';
        } else {
            if (this.saleOrder.document_no) {
                this.loader.show();
                this.save()
                    .then(() => {
                        this.loader.hide();
                        this.viewSaleOrder(this.saleOrder);
                    });
            } else {
                let product_length: number;
                product_length = 0;
                let products_checked: any;
                products_checked = {
                    product_ids: {},
                    products: []
                };
                for (let i = 0; i < this.saleOrder.products.length; i++) {
                    const _product: ProductModel = this.saleOrder.products[i];
                    if (_product && _product.customer_product_amount > 0) {
                        product_length++;
                        products_checked.product_ids[_product.id] = {
                            amount                  : _product.amount,
                            customer_product_amount : _product.customer_product_amount
                        };
                        products_checked.products.push(_product);
                    }
                }
                if (products_checked.products.length === 0) {
                    this.swal.danger('กรุณาเลือกรายการสินค้าอย่างน้อย 1 รายการ');
                } else {
                    const total_price: string = this.decimalPipe.transform(this.total_price);
                    this.swal.confirm('การออกใบสั่งขายคุณจะไม่สามารถกลับมาแก้ไขได้ <br/>สรุปรายการสินค้าทั้งหมด ' +
                        '<strong class="text-primary">' + product_length + ' รายการ</strong><br/>สรุปยอดราคา ' +
                        '<strong class="text-primary">' + total_price + ' บาท</strong>', true)
                        .then(result => {
                            if (result === true) {
                                this.loader.show();
                                this.save()
                                    .then(() => {
                                        this.generate_no()
                                            .then(() => {
                                                this.loader.hide();
                                                setTimeout(() => {
                                                    this.viewSaleOrder(this.saleOrder);
                                                }, 750);
                                            });
                                    }, error => {
                                        this.swal.danger(error);
                                    });
                            }
                        });
                }
            }
        }
    }

    public onCarrierChange(e: any): void {
        this.saleOrder.carrier_id = (e && e.id) ? e.id : null;
    }

    public onSaleChange(e: any): void {
        this.saleOrder.sale_id = (e && e.id) ? e.id : null;
    }

    public onCustomerChange(e: any): void {
        this.saleOrder.customer_id = (e && e.id) ? e.id : null;
    }

    public onCustomerBillChange(e: any): void {
        this.saleOrder.bill_id = (e && e.id) ? e.id : null;
    }

    public onCustomerAddressChange(e: any): void {
        this.saleOrder.customer_address_id = (e && e.id) ? e.id : null;
    }

    public onContactChange(e: any): void {
        this.saleOrder.contact_id = (e && e.id) ? e.id : null;
    }

    public onSelectSaleContactRef(e: any): void {
        /*if (this.saleOrder.requirement
            && this.saleOrder.requirement.id
            && this.saleOrder.requirement.customer
            && this.saleOrder.requirement.customer.id) {
            //
            this.saleOrder.customer_id = this.saleOrder.requirement.customer.id;
            this.saleOrder.customer.clone(this.saleOrder.requirement.customer);
            this.task.customer_id = this.saleOrder.customer_id;
            if (this.saleOrder.customer.addresses && this.saleOrder.customer.addresses[0]
                && this.saleOrder.customer.addresses[0].id) {
                //
                this.saleOrder.customer_address.clone(this.saleOrder.customer.addresses[0]);
                this.saleOrder.customer_address_id = this.saleOrder.customer.addresses[0].id;
            }
        }*/
        this.saleOrder.requirement_id = (e && e.id) ? e.id : null;
        this.saleOrder.customer_id = (e && e.customer_id) ? e.customer_id : null;
        this.saleOrder.ref_no = (e && e.ref_no) ? e.ref_no : null;
    }

    public addProduct(): void {
        let product: ProductModel;
        product = new ProductModel();
        product.index = this.saleOrder.products.push(product) - 1;
        product.pivot.wh = 'TH';
        product.wh = 'TH';
        product.cal_price();
        this.saleOrder.cal_total_price();
    }

    public createDeposit(): void {
        let deposit: DepositModel;
        deposit = new DepositModel();
        deposit.grand_total = this.saleOrder.grand_total;
        deposit.total_price = this.saleOrder.total_price;
        this.modal.show(DepositDetailComponent, {
            model       : this.saleOrder,
            type        : 'quotation',
            deposit     : deposit,
            quotation   : this.saleOrder,
            task        : this.task,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    deposit = content.deposit;
                    this.saleOrder.deposits.push(deposit);
                } else {
                    //
                }
            });
    }

    public createReceipt(): void {
        let receipt: ReceiptModel;
        receipt = new ReceiptModel();
        receipt.grand_total = this.saleOrder.grand_total;
        receipt.total_price = this.saleOrder.total_price;
        this.modal.show(ReceiptDetailComponent, {
            model       : this.saleOrder,
            type        : 'quotation',
            receipt     : receipt,
            quotation   : this.saleOrder,
            task        : this.task,
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.is_submit === true) {
                    receipt = content.receipt;
                    this.saleOrder.receipts.push(receipt);
                } else {
                    //
                }
            });
    }

    public viewDepositDetail(deposit: DepositModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(DepositDetailComponent, {
            deposit     : deposit,
            quotation   : this.saleOrder,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public viewReceiptDetail(receipt: ReceiptModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(ReceiptDetailComponent, {
            receipt     : receipt,
            quotation   : this.saleOrder,
            task        : this.task
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                //
            });
    }

    public addRemark(product: ProductModel): void {
        this.modal.show(RemarkModalComponent, {
            description: product.description
        }).then((content: any): void => {
            if (content && content.submit === true) {
                product.description = content.description;
                this.save();
            } else {
                //
            }
        });
    }

    private send_job_no(task_ids: string[], to_customer?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            if (task_ids && task_ids.length > 0) {
                this.api.request('mail/job', 'POST', {}, {
                    task_ids: task_ids,
                    to_customer: to_customer
                }).subscribe(() => {
                    resolve();
                }, error => {
                    reject();
                });
            } else {
                resolve();
            }
        });
        return promise;
    }

    public warning(): void { // task status is 12
        this.modal.show(ReportModalComponent, {
            task: this.task
        }).then((content: any): void => {
            if (content && content.is_submit === true) {
                this.taskService.setStatus(this.task, this.task.status, 'feedback_quotation')
                    .then(() => {
                        this.swal.success('รอการตอบกลับจากลูกค้าภายหลัง');
                    }, error => {
                        this.swal.danger(error);
                    });
            } else {
                //
            }
        });
    }

    public rejectSaleOrder(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.taskService.reject(this.task)
                        .then(() => {
                            this.swal.success('แจ้งใบสั่งขายไม่ผ่านการอนุมัติสำเร็จ')
                            this.leave(true);
                        }, error => {
                            //
                        });
                    return;
                }
                //
            });
    }

    public approveSaleOrder(): void {
        this.modal.show(AddRemarkComponent, {
            task: this.task
        }, {class: 'modal-md', backdrop: true, ignoreBackdropClick: true})
            .then((content: any): void => {
                if (content && content.sent === true) {
                    this.save()
                        .then(() => {
                            this.taskService.approve(this.task)
                                .then(() => {
                                    this.swal.success('ผ่านการอนุมัติใบสั่งขายสำเร็จ')
                                    this.leave(true);
                                }, error => {
                                    //
                                });
                        });
                } else {
                    //
                }
            });
    }

    public viewPaymentDetail(payment: PaymentModel, e?: any): void {
        if (e) {
            e.stopPropagation();
        }
        this.modal.show(PurchaseOrderDetailComponent, {
            quotationTask   : this,
            payment         : payment,
            quotation       : this.saleOrder,
            task            : this.task,
            products        : this.saleOrder.products
        }, { backdrop: true, ignoreBackdropClick: true, class: 'modal-lg' })
            .then((content: any): void => {
                if (content && content.checked_products && content.is_submit === true) {
                    if (payment.verified === true) {
                        this.save()
                            .then(() => {
                                this.taskService.complete(this.task)
                                    .then(() => {
                                        //
                                    });
                                payment.status = 1;
                                this.swal.confirm('ต้องการส่งอีเมลหาลูกค้า <i>' + this.saleOrder.customer.name + '</i><br/>' +
                                    ' ผ่านทางอีเมล <strong class="text-primary">"' + this.saleOrder.customer.email + '"</strong>' +
                                    ' หรือส่งเฉพาะ "' + environment.sale_email + '" เท่านั้น', true, null, 'ส่งทั้งหมด', 'ไม่ส่งหาลูกค้า')
                                    .then((result: boolean): void => {
                                        this.send_job_no(content.jobs, result)
                                            .then(() => {
                                                this.swal.success('ส่งต่อกระบวนการการวางแผนการผลิตสินค้าสำเร็จ')
                                                    .then(() => {
                                                        this.leave(true);
                                                    });
                                            });
                                    });
                            });
                    } else {
                        this.leave(true);
                    }
                } else {
                    //
                }
            });
    }

    public onPaymentUploadedSuccess(data: any): void {
        this.api.request('sale_admin/sale_orders/payment', 'PUT', {}, {
            task_id: this.task.id,
            document: data,
            id: this.saleOrder.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_payment: PaymentModel;
                new_payment = new PaymentModel();
                new_payment.clone(response.data);
                this.saleOrder.payments.push(new_payment);
                this.viewPaymentDetail(new_payment);
            }
        }, error => {
            //
        });
    }

    public onPaymentUploadError(data: any): void {
        //
    }

    public onDocumentUploadedSuccess(data: any): void {
        this.api.request('sale_admin/sale_orders/document', 'PUT', {}, {
            document    : data,
            id          : this.saleOrder.id
        }).subscribe((response: any): void => {
            if (response && response.data) {
                let new_document: DocumentModel;
                new_document = new DocumentModel();
                new_document.clone(response.data);
                this.saleOrder.documents.push(new_document);
            }
        }, error => {
            //
        });
    }

    public onDocumentUploadError(data: any): void {
        //
    }

    // public onClickCreateProduct(event: any, product: ProductModel): void {
    //     const title: string = (product.product_no) ? 'ยืนยันการสร้างสินค้าใหม่ "' + product.product_no + '" ใช่หรือไม่?' : 'ยืนยันการสร้างสินค้าใหม่ใช่หรือไม่?';
    //     this.swal.confirm(title)
    //         .then((result: boolean): void => {
    //             if (result === true) {
    //                 const path: string = this.location.path();
    //                 this.storage.store('navigate_stack', {
    //                     path: path,
    //                     action: 'create_quotation',
    //                     data: {
    //                         id: this.task_id,
    //                         quotation_id: this.saleOrder_id,
    //                         current_role: this.task.current_role,
    //                         process_slug: this.task.process_slug,
    //                         isNew: false,
    //                         product_name: (product.product_no) ? product.product_no : ''
    //                     }
    //                 });
    //                 this.createProduct(null, true);
    //             } else {
    //                 //
    //             }
    //         });
    // }
    //
    // public createProduct(product_id?: string, force?: boolean): void {
    //     const task: TaskModel = new TaskModel();
    //     task.process_slug = 'product';
    //     task.current_role = 'product';
    //     task.action = 'create_product';
    //     task.modelable_type = 'App\\Product';
    //     if (product_id) {
    //         task.modelable_id = product_id;
    //     } else {
    //         //
    //     }
    //     this.taskService.hire(task, null, ((!force) ? false : true), task.action, task.action)
    //         .then(() => {
    //             // Go Go!
    //         });
    // }

    public verifySaleOrder(): void {
        if (this.saleOrder.document_no && this.saleOrder.customer) {
            super.issueTask()
                .then(() => {
                    this.leave(true);
                });
        } else {
            //
        }
    }

    public sendSaleOrder(): void {
        if (this.saleOrder.document_no && this.saleOrder.customer) {
            this.saleOrder.checked = true;
            this.modal.show(DocumentComponent, {
                customer    : this.saleOrder.customer,
                saleOrder   : this.saleOrder,
                documents   : this.saleOrder.documents
            }, {
                class: 'modal-lg'
            }).then((content: any): void => {
                if (content && content.submit === true) {
                    let document_ids: string[];
                    document_ids = [];
                    if (content.documents) {
                        for (let i = 0; i < content.documents.length; i++) {
                            if (content.documents[i].checked === true) {
                                document_ids.push(content.documents[i].id);
                            }
                        }
                    }
                    if (this.saleOrder.customer.email) {
                        this.swal.confirm('คุณต้องการส่งใบสั่งขาย "' + this.saleOrder.document_no + '" ใช่หรือไม่?')
                            .then((result1: boolean): void => {
                                if (result1 === true) {
                                    this.swal.confirm('ต้องการส่งอีเมลหาลูกค้า <i>' + this.saleOrder.customer.name + '</i><br/>' +
                                        ' ผ่านทางอีเมล <strong class="text-primary">"' + this.saleOrder.customer.email + '"</strong>' +
                                        ' หรือส่งเฉพาะ "' + environment.sale_email + '" เท่านั้น', true, null, 'ส่งทั้งหมด', 'ไม่ส่งหาลูกค้า')
                                        .then((result: boolean): void => {
                                            let message: string;
                                            if (result === true) {
                                                message = 'ส่งใบสั่งขาย "' + this.saleOrder.document_no + '"' + ' ผ่านทางอีเมลลูกค้าสำเร็จ';
                                            } else {
                                                message = 'ส่งใบสั่งขาย "' + this.saleOrder.document_no + '"' + ' ผ่านเฉพาะทางอีเมล "' + environment.sale_email + '" สำเร็จ';
                                            }
                                            this.loader.show();
                                            this.sendSaleOrderToCustomer(document_ids, result)
                                                .then(() => {
                                                    this.swal.success(message)
                                                        .then(() => {
                                                            this.leave(true);
                                                        });
                                                    this.loader.hide();
                                                }, error => {
                                                    this.swal.danger('ไม่สามารถส่งใบสั่งขายได้ กรุณาลองใหม่อีกครั้ง');
                                                    this.loader.hide();
                                                });
                                        });
                                }
                            });
                    } else {
                        this.swal.input('กรุณากรอกอีเมลลูกค้าสำหรับการส่งใบสั่งขาย', 'ข้อมูลอีเมล', 'อีเมล', 'ส่งใบสั่งขาย')
                            .then((result: any): void => {
                                if (result) {
                                    this.api.request('customers/email', 'POST', {}, {
                                        id: this.task.customer_id,
                                        email: result
                                    }).subscribe((customer_response: any): void => {
                                        if (customer_response && customer_response.success === false) {
                                            this.swal.danger(customer_response.message);
                                        } else {
                                            this.loader.show();
                                            this.sendSaleOrderToCustomer(document_ids)
                                                .then(() => {
                                                    this.swal.success('ส่งใบสั่งขาย "' + this.saleOrder.document_no + '"' +
                                                        ' ผ่านทางอีเมลลูกค้าสำเร็จ')
                                                        .then(() => {
                                                            this.leave(true);
                                                        });
                                                    this.loader.hide();
                                                }, error => {
                                                    this.swal.danger('ไม่สามารถส่งใบสั่งขายได้ กรุณาลองใหม่อีกครั้ง');
                                                    this.loader.hide();
                                                });
                                        }
                                    }, error => {
                                        this.swal.danger(error);
                                    });
                                } else {
                                    //
                                }
                            }, error => {
                                //
                            });
                    }
                }
            });
        }
    }

    private sendSaleOrderToCustomer(document_ids?: string[], to_customer?: boolean): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            let to_role: string;
            let to_process: string;
            to_role = 'quotation-2';
            to_process = this.task.process_slug;
            this.taskService.setStatus(this.task, 2, 'send_sale_order', to_role, to_process)
                .then((task_response: any): void => {
                    this.api.request('mail/sale_order', 'POST', null, {
                        task_id         : this.task.id,
                        sale_order_id   : this.saleOrder.id,
                        document_ids    : document_ids,
                        to_customer     : to_customer
                    }).subscribe((response: any): void => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
                });
        });
        return promise;
    }

    public onSelectTypeaheadShipping(model: any, data: any, field1: string, field2: string): void {
        if (model && field1 && field2 && data) {
            model[field1] = data[field2];
        }
    }

    public onSelectTypeaheadCurrency(data: any): void {
        this.saleOrder.currency_symbol = data.currencySymbol;
        this.saleOrder.currency = data.id;
        if (this.saleOrder && this.saleOrder.currency) {
            this.getCurrencyRate()
                .then((response: any): void => {
                    this.saleOrder.currency_rated_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    this.saleOrder.currency_rate = response;
                });
        }
    }

    public onBillTypeaheadDeleted(event?: any): void {
        this.saleOrder.bill = new BillModel();
    }

    public onSaleTypeaheadDeleted(event?: any): void {
        this.saleOrder.sale = new UserModel();
    }

    public onCustomerTypeaheadDeleted(event?: any): void {
        this.saleOrder.customer = new CustomerModel();
        this.saleOrder.customer.credit_terms = 0;

        this.saleOrder.contact = new ContactModel();
        this.saleOrder.customer_address = new CustomerAddressModel();
    }

    public onCustomerAddressTypeaheadDeleted(event?: any): void {
        this.saleOrder.customer_address = new CustomerAddressModel();
    }

    public onCustomerSelect(data: any): void {
        if (data && data.addresses && data.addresses.length > 0) {
            this.saleOrder.customer_address.clone(data.addresses[0]);
            this.saleOrder.customer_address_id = data.addresses[0].id;
        } else {
            //
        }
        if (data && data.bills && data.bills.length > 0) {
            this.saleOrder.bill.clone(data.bills[0]);
            this.saleOrder.bill_id = data.bills[0].id;
        } else {
            //
        }
    }

    public onBlur(e: any): void {
        if (this.saleOrder) {
            this.saleOrder.cal_total_price();
        }
    }

    public onModelChanged(e?: any): void {
        this.ngZone.run(() => {
            if (this.saleOrder) {
                this.saleOrder.cal_total_price();
            }
        });
    }

    private getCheckedProducts(): any {
        let checked_products: any[];
        checked_products = [];
        let product_length: number;
        product_length = 0;
        for (let i = 0; i < this.saleOrder.products.length; i++) {
            const _product: ProductModel = this.saleOrder.products[i];
            if (_product && _product.id) {
                product_length++;
                checked_products.push({
                    customer_product_amount : _product.customer_product_amount,
                    amount                  : _product.amount,
                    price                   : _product.price,
                    price_per_unit          : _product.price_per_unit,
                    description             : _product.description,
                    wh                      : _product.wh,
                    product_id              : _product.id
                });
            }
        }
        return checked_products;
    }

    public archive(): void {
        this.taskService.archive(this.task)
            .then(() => {
                // Go Go!!
            }, error => {
                //
            });
    }

    public onSelectIncotermCode(e: any): void {
        //
    }

    public save(event?: any): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            this.saleOrder.product_ids = this.getCheckedProducts();
            this.saleOrder.task_id = this.task.id;
            const put_or_post_data: string[] = [
                'shipping_id', 'customer_id', 'contact_id', 'task_id', 'product_ids',
                'customer_address_id', 'sale_id', 'currency', 'currency_symbol',
                'currency_rate', 'currency_rated_at', 'delivery_date', 'bill_id',
                'shipping', 'incoterm_code', 'carrier_id', 'discount_percent', 'vat_percent', 'frt_sh', 'misc_chgs',
                'contact_ref_id', 'wh', 'requirement_id', 'ref_no', 'is_include_vat',
                'price_validity', 'processing_times', 'discount_type', 'discount_price'
            ];
            this.modelApi.update(this.saleOrder, put_or_post_data, 'sale_admin/sale_orders', null, {
                //
            }, false, false, (event && event.rev) ? event.rev : 0)
                .subscribe((quotation_response: any): void => {
                    if (quotation_response && quotation_response.success === true) {
                        resolve();
                    } else {
                        reject(quotation_response.message);
                    }
                }, error => {
                    reject(error);
                });
        });
        return promise;
    }

    public vatUpdate(e?: any): void {
        setTimeout(() => {
            const vat_percent: number = (this.saleOrder.vat_percent) ? this.saleOrder.vat_percent : 0;
            this.saleOrder.cal_total_price();
        }, 0);
    }

    public nextProcess(): void {
        if (!this.saleOrder.payments || this.saleOrder.payments.length === 0) {
            this.swal.danger('ยังไม่ได้รับใบสั่งซื้อสินค้าเข้าระบบ');
        } else if (!this.saleOrder.validPayment()) {
            this.swal.danger('หลักฐานการสั่งซื้อ หรือ P/O ไม่ถูกต้อง');
        } else {
            this.swal.confirm('ยืนยันการส่งต่อให้กับแผนก Sale Admin ใช่หรือไม่?')
                .then((result: boolean): void => {
                    if (result === true) {
                        this.loader.show();
                        this.save()
                            .then(() => {
                                this.loader.hide();
                                this.taskService.setStatus(this.task, this.task.status, 'got_purchase_order', 'proforma', 'proforma')
                                    .then(() => {
                                        this.leave(true);
                                        this.swal.success('ส่งต่อให้กับแผนก Sale Admin สำเร็จ');
                                    }, error => {
                                        this.swal.danger(error);
                                    });
                            });
                    } else {
                        //
                    }
                });
        }
    }

    public get total_price(): number {
        if (this.saleOrder) {
            this.saleOrder.cal_total_price();
            return this.saleOrder.grand_total;
        }
        return 0;
    }

    public onSaveApiCallingBack(event: any): void {
        if (this.task.approved) {
            //
        } else {
            this.save(event);
        }
    }

}
