import {AfterViewInit, Component, OnDestroy, Renderer2} from '@angular/core';
import {AuthService} from '../now/user/auth.service';
import {Router} from '@angular/router';
import {Api} from '../now/api/api';
import {PusherService} from '../services/pusher.service';
import {environment} from '../environments/environment';
import {AppService} from './app.service';
import {UserService} from '../services/user.service';
import {SwalService} from '../services/swal.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {Location} from '@angular/common';
import {TaskService} from '../services/task.service';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { thLocale } from 'ngx-bootstrap/locale';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

    public is_ready: boolean;
    public isLogged: boolean;

    public next_path: string;

    public updated_at: string;
    public version: string;

    constructor(
        public authService: AuthService,
        public router: Router,
        public api: Api,
        public location: Location,
        public pageScrollService: PageScrollService,
        public userService: UserService,
        public appService: AppService,
        private swal: SwalService,
        private taskService: TaskService,
        public renderer2: Renderer2,
        private pusherService: PusherService,
        // private localeService: BsLocaleService
    ) {
        //
        // moment.updateLocale('th');
        defineLocale('th', thLocale);
        // this.localeService.use('th');

        this.setupPageScroll();
        this.is_ready = false;
        this.authService.init()
            .then(() => {
                this.authService.profile()
                    .then(user => {
                        console.log(user);
                        this.userService.setUser(user);
                        this.gotoSomePage();
                        this.is_ready = true;
                        this.isLogged = true;
                    }, error => {
                        this.gotoAuth();
                        this.is_ready = true;
                    });
            }, () => {
                this.gotoAuth();
                this.is_ready = true;
            });

        this.api.errorStatusMethods = {
            '404'   : () => {
                /*this.swal.danger('กรุณาติดต่อผู้ดูแลระบบ')
                    .then(() => {
                        //
                    });*/
            },
            '401'   : () => {
                this.gotoAuth();
                // this.swal.danger('กรุณาทำการเข้าสู่ระบบใหม่อีกครั้ง')
                //     .then(() => {
                //         //
                //     });
            },
            '400'   : () => {
                this.gotoAuth();
            }
        };

        this.pusherService.init(environment.pusher_key, {
            cluster     : environment.pusher_cluster,
            encrypted   : true
        });

        this.init();
    }

    public get all_tasks(): any[] {
        //
        let tasks: any[];
        tasks = [];
        if (this.appService.tasks_handle) {
            for (const key in this.appService.tasks_handle) {
                if (key) {
                    const value = this.appService.tasks_handle[key];
                    for (let i = 0; i < value.length; i++) {
                        const val = value[i];
                        tasks.push(val);
                    }
                }
            }
        }
        if (this.appService.tasks_idle) {
            for (const key in this.appService.tasks_idle) {
                if (key) {
                    const value = this.appService.tasks_idle[key];
                    for (let i = 0; i < value.length; i++) {
                        const val = value[i];
                        tasks.push(val);
                    }
                }
            }
        }
        return tasks;
    }

    public onSearchKeydown(e: any): void {
        this.appService.dashboardFilter(e);
    }

    public onSearchSelect(e: any): void {
        if (e && e.job_id) {
            this.router.navigateByUrl('job/' + e.job_id);
        } else {
            this.swal.danger(JSON.stringify(e));
        }
    }

    private setupPageScroll(): void {
        /*PageScrollConfig.defaultScrollOffset = SCROLL_OFFSET;
        PageScrollConfig.defaultEasingLogic = {
            ease: (t: number, b: number, c: number, d: number): number => {
                // easeInOutExpo easing
                if (t === 0) {
                    return b;
                }
                if (t === d) {
                    return b + c;
                }
                if ((t /= d / 2) < 1) {
                    return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
                }
                return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
            }
        };
        PageScrollConfig.defaultDuration = 250;*/
    }

    private getVersion(): Promise<Object> {
        let promise: Promise<Object>;
        promise = new Promise<Object>((resolve, reject) => {
            // this.api.request('assets/version.json', 'GET', null, null, null, environment.host, '')
            //     .subscribe((response: any): void => {
            //         resolve(response);
            //     }, error => {
            //         resolve({
            //             updated_at: '05/01/34',
            //             version: 'BETA'
            //         });
            //     });
            resolve({});
        });
        return promise;
    }

    ngAfterViewInit(): void {
        /*$(window).bind('keyup keydown', e => {
            console.log((e.ctrlKey || e.metaKey) && e.keyCode === 70);
            if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) { // 70 is 'f'
                this.toggleFullscreen();
                e.preventDefault();
            } else {
                //
            }
        });*/
    }

    ngOnDestroy(): void {
        // $(window).unbind('keyup keydown');
    }

    private toggleFullscreen(): void {
        console.log(document.fullscreenEnabled);
        document.documentElement.requestFullscreen();
    }

    private init(): void {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false
            },
        });

        this.authService.onAuthHandler = () => {
            this.isLogged = true;
            this.userService.setUser(this.authService.user);
            this.gotoSomePage();
        };
        this.authService.onLogoutHandler = () => {
            this.gotoAuth();
        };
    }

    private gotoSomePage(): void {
        if (this.next_path !== '/auth' && this.next_path) {
            this.router.navigateByUrl(this.next_path);
            this.next_path = null;
        } else if (this.router.url === '/auth') {
            this.router.navigateByUrl('/');
        } else {
            //
        }
        this.appService.showNavbar();
    }

    private gotoAuth(): void {
        this.next_path = this.location.path();
        this.isLogged = false;
        this.appService.hideNavbar();
        this.router.navigateByUrl('/auth');
    }

}
