export const PROBLEM_TYPES: any[] = [
    'N/A',
    'การสื่อสาร/ระบบเอกสารผิดพลาด',
    'ค่าความลึกเกลียว,ความลึกรูเจาะ',
    'ค่าความลึกของชิ้นงาน (Height)',
    'ค่าเชิงมุมองศาและส่วนโค้ง (CURVE,ANGEL)',
    'ค่าพิกัดงานเพลา H7 g6 etc. (+/-0.02)',
    'ค่าพิกัดงานรู H7 g6 etc. (+/-0.02)',
    'ค่าพิกัดงานหลังชุบ Thickness (+/-0.03)',
    'ค่าระยะงานพับประกอบ (Bending)',
    'ค่าระยะรู Hole,Tap (+-0.3)',
    'ช่วงพิกัดความเผื่อ Tolerance : <0.02 (S)',
    'ช่วงพิกัดความเผื่อ Tolerance : <0.05 (A)',
    'ช่วงพิกัดความเผื่อ Tolerance : <0.2 (B)',
    'ช่วงพิกัดความเผื่อ Tolerance : >0.2 ( C)',
    'ชิ้นงานทดลองใช้/ขอแก้ไข',
    'ชิ้นงานผิดรูป',
    'ใช้วัตถุดิบผิดประเภท Material',
    'ตรวจสอบไม่ได้',
    'ผลิตผิด Drawing',
    'ผิวงานในรูไม่เรียบรู H7 (Bari in Hole)',
    'ผิวหน้าราบไม่เรียบขุขระ (Bari on Surface)',
    'มาตรฐานสากล GD&T ค่าความขนาน',
    'มาตรฐานสากล GD&T ค่าความตั้งฉาก',
    'มาตรฐานสากล GD&T ค่าความร่วมศูนย์',
    'มาตรฐานสากล GD&T ค่าความราบผิว',
    'มาตรฐานสากล GD&T ค่าความสมมาตร',
    'ไม่ได้ทำการชุบแข็ง (Hardness)',
    'ไม่ได้ทำเกลียว,เกลียวที่ไม่สมบูรณ์',
    'ไม่ได้ทำผิวตามแบบกำหนด (Surface Treatment)',
    'ไม่ได้ทำมุมชิ้นงาน (Radial/Chamfer)',
    'สั่งตัดวัตถุดิบผิดพลาด',
    'เตรียมวัสดุดิบผิดพลาด'
];

export const PROBLEM_DETAILS: string[] = [
    'N/A',
    'ขอบงานแตกปริ',
    'ความเรียบผิวงานชุบ',
    'ความหยาบผิว',
    'ค่าความโก่ง',
    'ค่าความขนาน',
    'ค่าความตั้งฉาก',
    'ค่าความร่วมศูนย์',
    'ค่าความราบผิว',
    'ค่างานเคลือบผิว',
    'ค่าพิกัดควบคุม',
    'ค่าพิกัดความโตนอก',
    'ค่าพิกัดความโตรูใน',
    'ค่าพิกัดความเผื่อ',
    'ค่าพิกัดความเผื่อกำหนด',
    'ค่าพิกัดความเผื่อปกติ',
    'ค่าพิกัดเพลา H7',
    'ค่าพิกัดมุม R,C',
    'ค่าพิกัดระยะขอบถึงรู',
    'ค่าพิกัดรูคว้าน H7',
    'ค่ามุมองศา',
    'ค่าระยะขอบถึงขอบ',
    'ชิ้นงานผิดรูป',
    'ชิ้นงานไม่ครบตามจำนวน',
    'ผิดกระบวนการ',
    'ไม่ได้ตรวจสอบจำนวน MAL\'T นำเข้า',
    'วาง PROCESS ผิดพลาด',
];

export const TOOLS: string[] = [
    'N/A',
    'Visual Check',
    'Visual',
    'Vernier',
    'Shim Gauge',
    'Screw Test',
    'Ring Gauge',
    'Plug Gauge',
    'Non-Contact',
    'Micro meter',
    'Height Master',
    'Gauge Check',
    'Filler Gauge',
    'Dial Gauge',
    'CMM Ziess',
];
